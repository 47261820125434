import { Component, Input, OnInit } from '@angular/core';
import { EmailCheckResponse } from '../../models/EmailCheckModel';
import { InputAccessData, InputData } from '../../models/InputDataModel';

@Component({
  selector: 'app-email-check',
  templateUrl: './email-check.component.html',
  styleUrls: ['./email-check.component.css']
})
export class EmailCheckComponent implements OnInit {

  @Input() public inputData: InputData = new InputData();
  @Input() public accessData: InputAccessData = new InputAccessData();
  @Input() public email: EmailCheckResponse = new EmailCheckResponse();

  // Constant Message
  public emailRequiredMessage: string = "Email is required.";
  public NoSearchSelectShort = "Not selected.";
  public SearchFail = "Search failed.";
  public NoRelevantDataMessageShort = "No records.";

  constructor() { }

  ngOnInit(): void {
  }

}
