import { Component, Input, OnInit } from '@angular/core';
import { FinancialResponseModel } from '../../models/FinancialModel';
import { InputAccessData, InputData } from '../../models/InputDataModel';

@Component({
  selector: 'app-financial-check',
  templateUrl: './financial-check.component.html',
  styleUrls: ['./financial-check.component.css']
})
export class FinancialCheckComponent implements OnInit {

  @Input() public inputData: InputData = new InputData();
  @Input() public accessData: InputAccessData = new InputAccessData();
  @Input() public financial: FinancialResponseModel = new FinancialResponseModel();

  // Constant Message
  public NoRelevantDataMessageShort = "No records.";
  public NoSearchSelectShort = "Not selected.";
  public SearchFail = "Search failed.";
  public postCodeRequiredMessage: string = "Postcode is required.";

  constructor() { }

  ngOnInit(): void {
  }

}
