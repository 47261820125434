<div class="container">
  <div class="logo-div d-flex justify-content-center">
    <img class="logo" src="../../../../../assets/Images/new/HUT-icon.v3-mobile-RO.png" />
  </div>
  <form>
    <div class="form-group row form-top-margin">
      <div class="col-md-8 offset-md-2 form-data-background form-borderdesign  shadow-lg p-3">
        <div class="row padding-form-design">
          <div class="col-md-12 z-index-formdata">
            <div class="row">
              <div class="input-group">
                <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                  <b>Reference:</b>
                </div>
                <div class="col-md-8 pt-3">
                  <div class="form-group">
                    <input type="text" placeholder="" class="form-control" value="{{requestData.profileData.referenceNumber}}" autocomplete="off" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 z-index-formdata">
            <div class="row">
              <div class="input-group">
                <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                  <b>Mobile:</b>
                </div>
                <div class="col-md-8 pt-3">
                  <div class="form-group">
                    <input type="text" placeholder="" class="form-control" value="{{requestData.profileData.phoneNumber}}" autocomplete="off" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="pt-1"></div>
</div>
