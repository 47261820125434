import { Component, Input, OnInit } from '@angular/core';
import { BusinessResultModel } from '../../../../../models/BusinessResultModel';

@Component({
  selector: 'app-pdf-ltd-accounts-business',
  templateUrl: './pdf-ltd-accounts-business.component.html',
  styleUrls: ['./pdf-ltd-accounts-business.component.css']
})
export class PdfLtdAccountsBusinessComponent implements OnInit {

  @Input() public business: BusinessResultModel = new BusinessResultModel();

  constructor() { }

  ngOnInit(): void {
  }

  public yearEndExpand() {
    const thisRef = this;
    this.business?.responseData?.creditsafeResult?.report?.financialStatements?.forEach(x => {
      thisRef[x.yearEndDate] = true;
    });
    return thisRef;
  }

}
