  <div class="col-md-12" *ngIf="!accessData.CounterFraud && prospectResultData?.isProspectHitAccess"><i class="color-grey float-right">{{NoSearchSelect}}</i></div>
  <div class="col-md-12" *ngIf="accessData.CounterFraud && prospectResultData?.isProspectHitAccess && !prospectResultData?.errorMessage && (prospectResultData?.searchFail || !prospectResultData?.prospectHitResultData || (prospectResultData?.prospectHitResultData?.errormessages?.length > 0))"><i class="color-grey float-right">{{SearchFail}}</i></div>
  <div class="col-md-12" *ngIf="accessData.CounterFraud && prospectResultData?.isProspectHitAccess && !prospectResultData?.searchFail && !prospectResultData?.errorMessage && ((prospectResultData?.prospectHitResultData?.hits == null || prospectResultData?.prospectHitResultData?.hits?.length == 0))"><i class="color-grey float-right">{{NoRelevantDataMessage}}</i></div>
  <div class="col-md-12" *ngIf="accessData.CounterFraud && prospectResultData?.isProspectHitAccess && !prospectResultData?.searchFail && prospectResultData?.errorMessage"><i class="color-grey float-right">{{OtherFieldRequired}}</i></div>
 
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-12">
        <div class="table-responsive">
          <div class="my-3">
            <!--<button class="btn colorRed reset-btn" [disabled]="prospectProfileButtonDisable" (click)="RequestProspectProfileData()">{{prospectProfileButtonText}}</button>-->
            <div *ngIf="prospectResultData?.prospectProfileResultData?.reference">
              Reference Number : {{prospectResultData?.prospectProfileResultData.reference}}
            </div>
          </div>
          <table class="table table-bordered table-striped">
            <thead>
              <tr>
                <th>Details</th>
                <th>I2 Match</th>
                <th>Previous Search</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let searchData of prospectResultData?.prospectHitResultData?.hits">
                <td>
                  <div *ngIf="searchData?.searchType == 'Person'">
                    <p class="mb-1">Person name: {{inputData.searchName}}</p>
                    <p class="mb-1">Date of birth: {{inputData.dateOfBirth}}</p>
                  </div>
                  <div *ngIf="searchData?.searchType == 'Telephone'">
                    <p class="mb-1">Telephone: {{inputData.phoneNumber}}</p>
                  </div>
                  <div *ngIf="searchData?.searchType == 'Email'">
                    <p class="mb-1">Email: {{inputData.email}}</p>
                  </div>
                  <div *ngIf="searchData?.searchType == 'Vehicle'">
                    <p class="mb-1">Vehicle number: {{inputData.vrn}}</p>
                  </div>
                  <div *ngIf="searchData?.searchType == 'Address'">
                    <p class="mb-1">Address: {{inputData.addressLine1}}</p>
                  </div>
                </td>
                <td>
                  <div *ngIf="searchData?.i2Hit == true">
                    <span><i class="fa fa-check-circle color-green"></i></span>
                  </div>
                  <div *ngIf="searchData?.i2Hit == false">
                    <span><i class="fa fa-times-circle color-grey"></i></span>
                  </div>
                </td>
                <td>
                  <div *ngIf="searchData?.previousHit == true">
                    <span><i class="fa fa-check-circle color-green"></i></span>
                  </div>
                  <div *ngIf="searchData?.previousHit == false">
                    <span><i class="fa fa-times-circle color-grey"></i></span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
