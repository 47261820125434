import { Component, Input, OnInit } from '@angular/core';
import { AVAResultModel } from '../../../../../models/AVAResultModel';
import { ProspectResultModel } from '../../../../../models/CounterFraudResultModel';
import { DVLAResultModel, MotHistoryResultModel } from '../../../../../models/DVLAResultModel';
import { ExperianResultModel } from '../../../../../models/ExperianResultModel';
import { RequestData } from '../../../../../models/SearchRequestModel';

@Component({
  selector: 'app-export-pdf-motor',
  templateUrl: './export-pdf-motor.component.html',
  styleUrls: ['./export-pdf-motor.component.css', '../../../../shared/shared.searchpage.css']
})
export class ExportPdfMotorComponent implements OnInit {

  @Input() public requestData: RequestData = new RequestData();
  @Input() public ava: AVAResultModel = new AVAResultModel();
  @Input() public dvla: DVLAResultModel = new DVLAResultModel();
  @Input() public prospect: ProspectResultModel = new ProspectResultModel();
  @Input() public experian: ExperianResultModel = new ExperianResultModel();
  @Input() public motHistory: MotHistoryResultModel = new MotHistoryResultModel();
  @Input() public isExperianTabShow: boolean = false;
  @Input() public motHistoryExpandedRows: any[] = [];

  constructor() { }

  ngOnInit(): void {
  }

}
