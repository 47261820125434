import { Component, Input, OnInit } from '@angular/core';
import { MessagingService } from '../../../../../../services/messaging.service';
import { ExperianResultModel } from '../../../../../models/ExperianResultModel';

@Component({
  selector: 'app-hutmotor-result-motor',
  templateUrl: './hutmotor-result-motor.component.html',
  styleUrls: ['./hutmotor-result-motor.component.css', '../../../../shared/shared.searchpage.css']
})
export class HutmotorResultMotorComponent implements OnInit {

  @Input() experian: ExperianResultModel = new ExperianResultModel();

  constructor(public messageService: MessagingService) { }

  ngOnInit(): void {
  }

}
