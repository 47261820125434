import { Items } from "./Items";
import { ProfileData } from "./SearchRequestModel";

export class BusinessOpenSourceResultModel {
  public profileData: ProfileData;
  public isLoading: boolean;
  public noSearchSelect: boolean;
  public responseData: Items[];
  public searchFail: boolean;

  public openSourceResultCount: number = 0;
  public openSourceResultCombine: Items[] = [];
}

export class BusinessResultModel {
  public isLoading: boolean;
  public noSearchSelect: boolean;
  public responseData: any;
  public noRelevantData: boolean;
  public searchFail: boolean;

  public businessTargeterResult: any;
  public businessTargeterResultMatch: any;
  public creditsafeResult: any;
  public businessNonRegisteredProfileResult: any;
}
