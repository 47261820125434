<section id="search-top-navigation">
  <div class="container">
    <nav class="search-top-nav">
      <div class="search-top-nav-menu">
        <div class="exit mr-5" *ngIf="disableExit">
          <img src="../../../../../../assets/Images/new/Background/HUT-elem-logout-icon.png" />
          <span>Exit</span>
        </div>
        <div class="exit exit-enabled mr-5" routerLink="/v3" *ngIf="!disableExit">
          <img src="../../../../../../assets/Images/new/Background/HUT-elem-logout-icon.png" />
          <span>Exit</span>
        </div>
      </div>
    </nav>
  </div>
</section>
