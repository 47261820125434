import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-export-pdf-personal-au',
  templateUrl: './export-pdf-personal-au.component.html',
  styleUrls: ['./export-pdf-personal-au.component.css']
})
export class ExportPdfPersonalAuComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
