import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CSVRecords, ScreenInputData } from '../Models/CSVRecords';

@Injectable({
  providedIn: 'root'
})
export class ScreenSearchDataService {

  constructor(private http: HttpClient) { }


  GetScreenSearchData(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    };
    return this.http.get('api/screensearch', httpOptions)
      .pipe(
        map(response => {
          let body = response;
          return body
            || {};
        }
        )
      );
  }


  ProcessScreenSearch(screenInputData: ScreenInputData): Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    };
    return this.http.post('api/screensearch', screenInputData, httpOptions);
  }

 

}
