export class AVAResultModel {
  public isLoading: boolean;
  public noSearchSelect: boolean;
  public responseData: AVAResultData;
  public noRelevantData: boolean;
  public searchFail: boolean;
}

export class AVAResultData {
  vehicleData: any;
  mot: any;
  advert: Advert;
}

export class Advert {
  PartitionKey: string;
  Id: string;
  Vrn: string;
  VehicleMake: string;
  VehicleTransmission: string;
  VehicleModel: string;
  VehicleRegistrationYear: string;
  VehicleColour: string;
  VehicleEngineSize: string;
  VehicleBodyType: string;
  VehicleFuelType: string;
  CreatedDate: Date;
  ModifiedDate: Date;
  AdvertSourceData: AdvertSourceData[];
}

export class AdvertSourceData {
  AdvertSource: string;
  AdvertId: string;
  AdvertTitle: string;
  Seller?: any;
  VehiclePrice: string;
  VehicleMileage: string;
  VehiclePostedOn: Date;
  AdvertLink: string;
  VehicleDescription: string;
  VehicleImages: string[];
  vehicleBase64: string;
  DetailedData: DetailedData;
}

export class DetailedData {
  SellerDetails?: any;
  VehicleHistory?: any;
  Info: Info;
  OtherInfo: OtherInfo;
}

export class Info {
  Vrn: string;
  VehicleMake: string;
  VehicleTransmission: string;
  VehicleModel: string;
  VehicleRegistrationYear: string;
  VehicleColour: string;
  VehicleEngineSize: string;
  VehicleBodyType: string;
  VehicleFuelType: string;
  CreatedDate: Date;
}

export class OtherInfo {
  Power: string;
  Seats: string;
  DriveSide: string;
  PreviousOwners?: any;
  SafetyFeatures: string;
  Doors: string;
  RegisteredDate?: any;
  InteriorOptions: string;
  InCarAudio: string;
  ManufacturerWarranty?: any;
  Exterior?: any;
  MotExpiry: string;
  Condition: string;
  MetallicPaint?: any;
  IndependentVehicleInspection?: any;
  V5Document: string;
  ServiceHistory: string;
  ItemLocation: string;
  ConsumptionEmissions?: any;
}
