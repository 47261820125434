<section id="personal-search" class="search">
    <div class="container">
      <div class="logo-div d-flex justify-content-center">
        <img class="logo" src="assets/Images/new/HUT-icon.v3-personal-RO.png" />
      </div>
      <form role="form" [formGroup]="personalForm">
        <div class="form-group row form-top-margin">
          <div class="offset-md-2 col-md-8 form-data-background form-borderdesign  shadow-lg p-3" formGroupName="profile">
            <div class="row padding-form-design">
              <div class="col-md-12 z-index-formdata">
                <div class="row">
                  <div class="input-group">
                    <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                      <b>Reference:</b>
                    </div>
                    <div class="col-md-8 pt-3">
                      <div class="form-group">
                        <input type="text" placeholder="" class="form-control" formControlName="reference" autocomplete="off" />
                        <p class="text-danger" *ngIf="personalForm.get('profile.reference').errors?.required">
                          <strong><small>{{messagingService.ReferenceRequiredMessage}}</small></strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 z-index-formdata">
                <div class="row">
                  <div class="input-group">
                    <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                      <b>Name:</b>
                    </div>
                    <div class="col-md-8 pt-3">
                      <div class="form-group">
                        <input type="text" placeholder="" class="form-control" formControlName="name" autocomplete="off" />
                        <p class="text-danger" *ngIf="personalForm.get('profile.name').errors?.required">
                          <strong><small>{{messagingService.NameRequiredMessage}}</small></strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 z-index-formdata">
                <div class="row">
                  <div class="input-group">
                    <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                      <b>DOB:</b>
                    </div>
                    <div class="col-md-8 pt-3">
                      <div class="form-group">
                        <input type="date" placeholder="" class="form-control datepicker" formControlName="dob" autocomplete="off" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 z-index-formdata">
                <div class="row">
                  <div class="input-group">
                    <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                      <b>Phone:</b>
                    </div>
                    <div class="col-md-8 pt-3">
                      <div class="form-group">
                        <input type="text" placeholder="" class="form-control" formControlName="phone" autocomplete="off" />
                        <p class="text-danger" *ngIf="personalForm.get('profile.phone').errors?.pattern">
                          <strong><small>{{messagingService.PhoneNumberNotValidMessage}}</small></strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 z-index-formdata">
                <div class="row">
                  <div class="input-group">
                    <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                      <b>Email:</b>
                    </div>
                    <div class="col-md-8 pt-3">
                      <div class="form-group">
                        <input type="email" placeholder="" class="form-control" formControlName="email" autocomplete="off" />
                        <p class="text-danger" *ngIf="personalForm.get('profile.email').errors?.pattern">
                          <strong><small>{{messagingService.EmailNotValidMessage}}</small></strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div class="col-md-12 z-index-formdata">
                <div class="row">
                  <div class="input-group">
                    <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                      <b>Street Address:</b>
                    </div>
                    <div class="col-md-8 pt-3">
                      <div class="form-group">
                        <input type="text" placeholder="" class="form-control" formControlName="streetaddress" autocomplete="off" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 z-index-formdata">
                <div class="row">
                  <div class="input-group">
                    <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                      <b>Suburb:</b>
                    </div>
                    <div class="col-md-8 pt-3">
                      <div class="form-group">
                        <input type="text" placeholder="" class="form-control" formControlName="suburb" autocomplete="off" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 z-index-formdata">
                <div class="row">
                  <div class="input-group">
                    <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                      <b>State:</b>
                    </div>
                    <div class="col-md-8 pt-3">
                      <div class="form-group">
                        <input type="text" placeholder="" class="form-control" formControlName="state" autocomplete="off" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 z-index-formdata">
                <div class="row">
                  <div class="input-group">
                    <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                      <b>Post Code:</b>
                    </div>
                    <div class="col-md-8 pt-3">
                      <div class="form-group">
                        <input type="text" placeholder="" class="form-control" formControlName="postcode" autocomplete="off" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-12 padding-form-design pb-2">
                <div class="row">
                  <div class="input-group">
                    <div class="col-md-12 offset-md-3">
                      <div class="form-group">
                        <button type="submit" class="btn colorRed mx-2"
                            (click)="personalSearch()"
                            [disabled]="personHistoryResultModel?.isLoading || enhancedRecordPlanResultModel?.isLoading ||
                            emailValidationResultModel?.isLoading || emailageResultModel?.isLoading || mobileResultModel?.isLoading
                            || opensourceResult?.isLoading || piplResultModel?.isLoading || socialMediaResultModel?.isLoading">
                          Search <i class="fa fa-search" aria-hidden="true"></i>
                        </button>
  
                        <button type="button" class="btn colorRed btn-pdf mx-2"
                            *ngIf="saveAsPDFButtonVisibility"
                            [disabled]="personHistoryResultModel?.isLoading || enhancedRecordPlanResultModel?.isLoading ||
                            emailValidationResultModel?.isLoading || emailageResultModel?.isLoading || mobileResultModel?.isLoading
                            || opensourceResult?.isLoading || piplResultModel?.isLoading || socialMediaResultModel?.isLoading">
                          Save as PDF
                        </button>
  
                        <button class="btn colorRed reset-btn mx-2"
                            *ngIf="clearSearchButtonVisibility"
                            (click)="personalClearSearch()"
                            [disabled]="personHistoryResultModel?.isLoading || enhancedRecordPlanResultModel?.isLoading ||
                            emailValidationResultModel?.isLoading || emailageResultModel?.isLoading || mobileResultModel?.isLoading
                            || opensourceResult?.isLoading || piplResultModel?.isLoading || socialMediaResultModel?.isLoading">
                          Clear Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  
    <div>
      <div class="container" *ngIf="showResultTab">
        <div class="mb-5">
          <div class="background-white result-display-padding form-borderdesign result-view-align" style="border: 1px solid rgba(0,0,0,.125);">

            <ul class="nav nav-tabs" id="searchResultTab" role="tablist">
              <li class="nav-item">
                <a class="nav-link active" id="personhistory-search-tab" data-toggle="tab" href="#personHistorySearch" role="tab"
                   aria-controls="profile" aria-selected="false"><b>Person History</b></a>
              </li>
              <li class="nav-item">
                <a class="nav-link" id="enhancedrecordplus-search-tab" data-toggle="tab" href="#enhancedRecordPlusSearch" role="tab"
                   aria-controls="profile" aria-selected="false"><b>Court Records (CDA)</b></a>
              </li>
              <li class="nav-item">
                <a class="nav-link" id="email-search-tab" data-toggle="tab" href="#emailSearch" role="tab"
                   aria-controls="profile" aria-selected="false"><b>Email</b></a>
              </li>
              <li class="nav-item">
                <a class="nav-link" id="mobile-search-tab" data-toggle="tab" href="#mobileSearch" role="tab"
                   aria-controls="profile" aria-selected="false"><b>Mobile</b></a>
              </li>
              <li class="nav-item">
                <a class="nav-link" id="opensource-search-tab" data-toggle="tab" href="#opensourceSearch" role="tab"
                   aria-controls="profile" aria-selected="false"><b>Opensource</b></a>
              </li>
              <li class="nav-item">
                <a class="nav-link" id="socialmedia-search-tab" data-toggle="tab" href="#socialmediaSearch" role="tab"
                   aria-controls="profile" aria-selected="false"><b>Social Media</b></a>
              </li>
            </ul>
  
            <div class="tab-content" id="searchResultTabContent">
              <br />
    
              <!-- Person History Results -->
              <div class="tab-pane fade show tab-height active" id="personHistorySearch" role="tabpanel" aria-labelledby="personhistory-search-tab">
                <div>
                  <div class="col-md-12" *ngIf="!personHistoryResultModel?.isLoading && personHistoryResultModel.validation && !personHistoryResultModel.searchFail"><i class="color-grey float-right">{{messagingService.OtherFieldRequired}}</i></div>
                  <div class="col-md-12" *ngIf="!personHistoryResultModel?.isLoading && !personHistoryResultModel.validation && personHistoryResultModel.searchFail"><i class="color-grey float-right">{{messagingService.SearchFail}}</i></div>
                  <div class="col-md-12" *ngIf="!personHistoryResultModel?.isLoading && !personHistoryResultModel.validation && !personHistoryResultModel.searchFail && (!personHistoryResultModel?.responseData?.data)"><i class="color-grey float-right">{{messagingService.NoRelevantDataMessage}}</i></div>
                </div>
                <div>
                  <span class="loadingImg" *ngIf="personHistoryResultModel?.isLoading">
                    <img src="{{messagingService.loadingAnimation}}" />
                  </span>
                </div>
                <div *ngIf="!personHistoryResultModel?.isLoading && !personHistoryResultModel.validation && !personHistoryResultModel.searchFail && personHistoryResultModel?.responseData && personHistoryResultModel?.responseData?.data">
                  <!-- <pre>{{personHistoryResultModel?.responseData?.data | json}}</pre> -->
                  <br />
                  <div class="container">
                    <div class="row">
                      <div class="mb-4" *ngFor="let people of personHistoryResultModel?.responseData?.data?.matches; let first = first" [ngClass]="{ 'col-md-12': first, 'col-md-5': !first }">
                        <div class="shadow-sm bg-white keep-together" [ngClass]="{ 'fit-content': first }">
                          <div class="address-box-design border-bottom p-4 border border-primary shadow">
                            <div>
                              <h5 class="mb-2"><span>{{ (people?.first_name +' '+ people?.middle_name + ' ' +people?.last_name) | titlecase }}</span></h5>
                              <div class="d-flex justify-content-between">
                                <p class="mb-1" *ngIf="people?.birth_date"> Birth Date: {{ (people?.birth_date | date: 'dd-MM-yyyy') }}</p>
                                <p class="mb-1" *ngIf="people?.deceased"> Deceased : {{ people.deceased }}</p>
                              </div>
                              <p class="mb-1" *ngIf="people?.address"> Address:</p>
                              <p class="mb-1 pl-4" *ngIf="people?.address">{{people?.address | titlecase}}</p>
                              <p class="mb-1 pl-4" *ngIf="people?.suburb">{{people?.suburb | titlecase}}</p>
                              <p class="mb-1 pl-4" *ngIf="people?.state">{{people?.state | titlecase}}</p>
                              <p class="mb-1 pl-4" *ngIf="people?.postcode">{{people?.postcode | titlecase}}</p>
                              <p class="mb-1" *ngIf="people?.date_start && people?.date_end"> Occupancy Period : {{ (people?.date_start | date: 'dd-MM-yyyy') + ' to ' + (people?.date_end | date: 'dd-MM-yyyy') }} </p>
                              <p class="mb-1" *ngIf="people?.phones?.length > 0"> Mobile Numbers:</p>
                              <p class="mb-1 pl-4" *ngFor="let mobile of people?.phones">
                                {{ mobile.phone + ' Period: ' + (mobile?.date_start | date: 'dd-MM-yyyy') + ' to ' + (mobile?.date_end | date: 'dd-MM-yyyy') }}
                              </p>                              
                              <p class="mb-1" *ngIf="people?.emails?.length > 0"> Emails:</p>
                              <p class="mb-1 pl-4" *ngFor="let email of people?.emails"> 
                                {{ email.email + ' Period: ' + (email?.date_start | date: 'dd-MM-yyyy') + ' to ' + (email?.date_end | date: 'dd-MM-yyyy') }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Enhanced Record Plus Results -->
              <div class="tab-pane fade show tab-height" id="enhancedRecordPlusSearch" role="tabpanel" aria-labelledby="enhancedrecordplus-search-tab">
                <div>
                  <div class="col-md-12" *ngIf="!enhancedRecordPlanResultModel?.isLoading && enhancedRecordPlanResultModel.validation && !enhancedRecordPlanResultModel.searchFail"><i class="color-grey float-right">{{messagingService.OtherFieldRequired}}</i></div>
                  <div class="col-md-12" *ngIf="!enhancedRecordPlanResultModel?.isLoading && !enhancedRecordPlanResultModel.validation && enhancedRecordPlanResultModel.searchFail"><i class="color-grey float-right">{{messagingService.SearchFail}}</i></div>
                  <div class="col-md-12" *ngIf="!enhancedRecordPlanResultModel?.isLoading && !enhancedRecordPlanResultModel.validation && !enhancedRecordPlanResultModel.searchFail && (!enhancedRecordPlanResultModel?.responseData?.data)"><i class="color-grey float-right">{{messagingService.NoRelevantDataMessage}}</i></div>
                </div>
                <div>
                  <span class="loadingImg" *ngIf="enhancedRecordPlanResultModel?.isLoading">
                    <img src="{{messagingService.loadingAnimation}}" />
                  </span>
                </div>
                <div *ngIf="!enhancedRecordPlanResultModel?.isLoading && !enhancedRecordPlanResultModel.validation && !enhancedRecordPlanResultModel.searchFail && enhancedRecordPlanResultModel?.responseData && enhancedRecordPlanResultModel?.responseData?.data">
                  <!-- <pre>{{enhancedRecordPlanResultModel?.responseData?.data | json}}</pre> -->
                  <div class="container">
                    <div class="row">
                      <div class="col-md-6 mb-4" *ngFor="let court of enhancedRecordPlanResultModel?.responseData?.data?.court_records">
                        <div class="shadow-sm bg-white h-100 keep-together">
                          <div class="address-box-design border-bottom p-4 border border-primary shadow">
                            <div class="row">
                              <!-- Right-aligned labels column -->
                              <div class="col-md-6 p-1 text-right">
                                <p class="mb-1" *ngIf="court?.fullname">Full Name:</p>
                                <p class="mb-1" *ngIf="court?.date">Date:</p>
                                <p class="mb-1" *ngIf="court?.court">Court:</p>
                                <p class="mb-1" *ngIf="court?.location">Location:</p>
                                <p class="mb-1" *ngIf="court?.state">State:</p>
                                <p class="mb-1" *ngIf="court?.case_no">Case Number:</p>
                                <p class="mb-1" *ngIf="court?.case_title">Case Title:</p>
                                <p class="mb-1" *ngIf="court?.listing">Listing:</p>
                                <p class="mb-1" *ngIf="court?.additional_info">Additional Information:</p>
                              </div>
                              <!-- Left-aligned values column -->
                              <div class="col-md-6 p-1 text-left">
                                <p class="mb-1" *ngIf="court?.fullname">{{ court?.fullname }}</p>
                                <p class="mb-1" *ngIf="court?.date">{{ court?.date | date: 'dd-MM-yyyy' }}</p>
                                <p class="mb-1" *ngIf="court?.court">{{ court?.court }}</p>
                                <p class="mb-1" *ngIf="court?.location">{{ court?.location }}</p>
                                <p class="mb-1" *ngIf="court?.state">{{ court?.state }}</p>
                                <p class="mb-1" *ngIf="court?.case_no">{{ court?.case_no }}</p>
                                <p class="mb-1" *ngIf="court?.case_title">{{ court?.case_title }}</p>
                                <p class="mb-1" *ngIf="court?.listing">{{ court?.listing }}</p>
                                <p class="mb-1" *ngIf="court?.additional_info">{{ court?.additional_info }}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>               
                  </div>
                </div>
              </div>

              <!-- Email Result -->
              <div class="tab-pane fade show tab-height" id="emailSearch" role="tabpanel" aria-labelledby="email-search-tab">
                <app-emailvalidation-result-email [emailValidation]="emailValidationResultModel"></app-emailvalidation-result-email>
                <br />
                <app-emailage-result-email [emailage]="emailageResultModel"></app-emailage-result-email>
              </div>

              <!-- Mobile Result -->
              <div class="tab-pane fade show tab-height" id="mobileSearch" role="tabpanel" aria-labelledby="mobile-search-tab">
                <app-mobile-result-mobile [mobile]="mobileResultModel"></app-mobile-result-mobile>
              </div>

              <!-- Opensource Result -->
              <div class="tab-pane fade show tab-height" id="opensourceSearch" role="tabpanel" aria-labelledby="opensource-search-tab">
                <app-opensource-result-mobile [opensource]="opensourceResult"></app-opensource-result-mobile>
              </div>

              <!-- Social Media Result -->
              <div class="tab-pane fade show tab-height" id="socialmediaSearch" role="tabpanel" aria-labelledby="socialmedia-search-tab">
                <app-pipl-result [pipl]="piplResultModel"></app-pipl-result>
                <br />
                <app-socialmedia-result-personal [socialMedia]="socialMediaResultModel"></app-socialmedia-result-personal>
              </div>

            </div>
          </div>
        </div>
        <div class="pt-1"></div>
      </div>
    </div>
  </section>
  
  <div style="position: absolute; top: 0; right: 120%;">
    <kendo-pdf-export #pdf>
      <app-export-pdf-personal-au></app-export-pdf-personal-au>
    </kendo-pdf-export>
  </div>
  