<table class="table table-striped table-hover">
  <thead>
    <tr>
      <th>Date</th>
      <th>Currency</th>
      <th>Value</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let credit of business?.responseData?.creditsafeResult?.report?.additionalInformation?.creditLimitHistory">
      <td>{{credit?.date | date:'dd-MM-yyyy'}}</td>
      <td>{{credit?.companyValue?.currency}}</td>
      <td>{{credit?.companyValue?.value}}</td>
    </tr>
  </tbody>
</table>
