import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessagingService } from 'src/app/demo/V3/services/messaging.service';
import { SearchService } from 'src/app/demo/V3/services/search.service';
import { AccessData, ProfileData, RequestData } from '../../../models/SearchRequestModel';
import { Shared } from 'src/app/demo/V3/services/shared';
import { SearchType } from '../../../models/SearchType';
import { PDFExportComponent } from '@progress/kendo-angular-pdf-export';
import { PersonHistoryResultModel } from '../../../models/AustraliaResultModel/PersonHistoryResultModel';
import { AustraliaSearchService } from 'src/app/demo/V3/services/australia.search.service';
import { EnhancedRecordPlusResultModel } from '../../../models/AustraliaResultModel/EnhancedRecordPlusResultModel';
import { EmailageCheckResultModel, EmailCheckResultModel } from '../../../models/EmailResultModel';
import { MobileResultModel, OpenSourceResultModel } from '../../../models/MobileResultModel';
import { SearchDomain } from 'src/app/home/models/SearchDomain';
import { Item, Token } from '../../../models/Items';
import { PIPLResultModel } from '../../../models/PIPLResultModel';
import { SocialMediaResultModel } from '../../../models/SocialMediaResultModel';
import { Items } from 'src/app/home/models/Items';
import { isPossiblePhoneNumber, isValidPhoneNumber, parsePhoneNumber, validatePhoneNumberLength } from 'libphonenumber-js';
declare var $: any;

@Component({
  selector: 'app-au-personal',
  templateUrl: './au-personal.component.html',
  styleUrls: ['./au-personal.component.css', '../../shared/shared.searchpage.css']
})
export class AuPersonalComponent implements OnInit {
  @ViewChild('pdf') pdf: PDFExportComponent;

  public personalForm: FormGroup;
  public validationType: any = {
    'reference': [Validators.required],
    'name': [Validators.required],
    'email': [Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$')],
    'phone': [Validators.pattern('^[0-9]{10}$')]
  };

  public accessData: AccessData = new AccessData();
  public showResultTab: boolean = false;
  public saveAsPDFButtonVisibility: boolean = true;
  public clearSearchButtonVisibility: boolean = true;
  public loggedInUsername: string = "";
  public emailageOrgId: string;
  public activeSearchDomains: SearchDomain[] = [];
  public searchDomains: SearchDomain[] = [];
  public refreshtoken = new Token(null);

  // Result Model
  public personHistoryResultModel: PersonHistoryResultModel = new PersonHistoryResultModel();
  public enhancedRecordPlanResultModel: EnhancedRecordPlusResultModel = new EnhancedRecordPlusResultModel();
  public emailValidationResultModel: EmailCheckResultModel = new EmailCheckResultModel()
  public emailageResultModel: EmailageCheckResultModel = new EmailageCheckResultModel();
  public mobileResultModel: MobileResultModel = new MobileResultModel();
  public opensourceResult: OpenSourceResultModel = new OpenSourceResultModel();
  public piplResultModel: PIPLResultModel = new PIPLResultModel();
  public socialMediaResultModel: SocialMediaResultModel = new SocialMediaResultModel();

  // PDF
  public requestDataModel: RequestData = new RequestData();

  constructor(
    private elementRef: ElementRef,
    private formBuilder: FormBuilder,
    private router: Router,
    private australiaSearchService: AustraliaSearchService,
    private searchService: SearchService,
    private messagingService: MessagingService
  ) { }

  ngOnInit(): void {
    let isLoggedIn = localStorage.getItem("access_token") ? true : false;
    if (!isLoggedIn)
      this.router.navigate(['/Login']);

    this.saveAsPDFButtonVisibility = false;
    this.clearSearchButtonVisibility = false;
    this.personalForm = this.formBuilder.group({
      profile: this.formBuilder.group({
        audit: [""],
        reference: [""],
        name: [""],
        dob: [""],
        phone: [""],
        email: [""],
        streetaddress: [""],
        suburb: [""],
        state: [""],
        postcode: [""],
      }),
    });
    this.getUserDetails();
    this.getEmailageOrgId();
    this.getLicenceItems();
    this.GetSearchDomainJson();
  }

  ngAfterViewInit() {
    this.elementRef.nativeElement.ownerDocument.body.style.background = 'url(/assets/Images/new/Background/HUT-elem-bg-1.png)';
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundAttachment = 'fixed';
  }

  public getEmailageOrgId() {
    this.searchService.GetEmailageOrdId().subscribe(data => {
      this.emailageOrgId = data;
    });
  }

  // Search button click event
  public personalSearch(): void {
    this.addValidators();
    this.personalForm.updateValueAndValidity();

    if (this.personalForm.valid) {
      Promise.resolve(this.getRandomString(10)).then(
        (data) => {

          this.saveAsPDFButtonVisibility = true;
          this.clearSearchButtonVisibility = true;

          this.resetResultObjects();

          this.showResultTab = true;
          var requestObj: RequestData = this.prepareRequestData();

          console.log(requestObj);

          this.setActiveResultTab();

          // Person History
          this.GetPersonHistory(requestObj);

          // Enhanced Record Plus
          this.GetEnhanceRecordPlus(requestObj);

          // // Email Validation
          this.emailValidationCheck(requestObj);

          // // // Telisign Check
          this.telisignCheck(requestObj);

          // Opensource search
          this.openSourceSearch(requestObj);

          // // PIPL API
          this.GetPIPLData(requestObj);

          // Social media Check
          this.socialMediaSearch(requestObj);
        }
      );
    }
  }


  // Getting search domain json for scraping
  public GetSearchDomainJson() {
    this.searchService.getDomainJson()
      .subscribe((data) => {
        this.searchDomains = data;
        this.activeSearchDomains = data.filter(x => x.IsActive == true);
      }, error => {
        console.log(error);
      });
  }

  public getLicenceItems(): void {
    this.searchService.AllowedLicence().subscribe(data => {
      this.getSecondAccess(data);
    });
  }

  public getSecondAccess(Items: Item[]) {
    Items.forEach(item => {
      if (item.licenceType.toLowerCase() == "search") {
        switch ((item.key)) {
          case "HUTDB": {
            this.accessData.isHUTDatabaseAccess = item.defaultValue;
            this.accessData.isHUTDatabaseToggle = item.toggle;
            break;
          }
          case "MOBILE": {
            this.accessData.isMobileAccess = item.defaultValue;
            this.accessData.isMobileToggle = item.toggle;
            break;
          }
          case "EMAIL": {
            this.accessData.isEmailAccess = item.defaultValue;
            this.accessData.isEmailToggle = item.toggle;
            break;
          }
          case "IDENTITY": {
            this.accessData.isIdentityAccess = item.defaultValue;
            this.accessData.isIdentityToggle = item.toggle;
            break;
          }
          case "FINANCIAL": {
            this.accessData.isFinancialAccess = item.defaultValue;
            this.accessData.isFinancialToggle = item.toggle;
            break;
          }
          case "ADDRESS": {
            this.accessData.isAddressAccess = item.defaultValue;
            this.accessData.isAddressToggle = item.toggle;
            break;
          }
          case "CRIMINALITY": {
            this.accessData.isCriminalityAccess = item.defaultValue;
            this.accessData.isCriminalityToggle = item.toggle;
            break;
          }
          case "SOCIAL": {
            this.accessData.isSocialMediaAccess = item.defaultValue;
            this.accessData.isSocialMediaToggle = item.toggle;
            break;
          }
          case "COUNTER_FRAUD": {
            this.accessData.isCounterFraudAccess = item.defaultValue;
            this.accessData.isCounterFraudToggle = item.toggle;
            break;
          }
          case "SPORTS": {
            this.accessData.isSportsAccess = item.defaultValue;
            this.accessData.isSportsToggle = item.toggle;
            break;
          }
          case "ADVERT": {
            this.accessData.isAdvertsAccess = item.defaultValue;
            this.accessData.isAdvertsToggle = item.toggle;
            break;
          }
          case "COMPANY_HOUSE": {
            this.accessData.isCompaniesHouseAccess = item.defaultValue;
            this.accessData.isCompaniesHouseToggle = item.toggle;
            break;
          }
          case "AVA": {
            this.accessData.isAVAAccess = item.defaultValue;
            this.accessData.isAVAToggle = item.toggle;
            break;
          }
          case "AML": {
            this.accessData.isAMLAccess = item.defaultValue;
            this.accessData.isAMLToggle = item.toggle;
            break;
          }
          case "CCJ_Detail": {
            this.accessData.isCCJDetailAccess = item.defaultValue;
            this.accessData.isCCJDetailToggle = item.toggle;
            break;
          }
          case "Insolvency_Detail": {
            this.accessData.isInsolvencyDetailAccess = item.defaultValue;
            this.accessData.isInsolvencyDetailToggle = item.toggle;
            break;
          }
          case "RTW": {
            this.accessData.isRTWAccess = item.defaultValue;
            this.accessData.isRTWToggle = item.toggle;
            break;
          }
          case "IDCHECK": {
            this.accessData.isIDCheckAccess = item.defaultValue;
            this.accessData.isIDCheckToggle = item.toggle;
            break;
          }
          case "LANDREGISTRY": {
            this.accessData.isLandRegistryAccess = item.defaultValue;
            this.accessData.isLandRegistryToggle = item.toggle;
            break;
          }
        }
      }
    });
  }

  public GetPersonHistory(requestData: RequestData) {
    this.personHistoryResultModel.isLoading = false;
    this.personHistoryResultModel.responseData = null;
    this.personHistoryResultModel.searchFail = false;
    this.personHistoryResultModel.validation = false;

    if (requestData.profileData.firstName && requestData.profileData.lastName &&
      (requestData.profileData.dateOfBirth || requestData.profileData.phoneNumber || requestData.profileData.email)) {
      this.personHistoryResultModel.isLoading = true;
      this.australiaSearchService.GetPersonHistory(requestData).subscribe(data => {
        this.personHistoryResultModel.isLoading = false;
        data["data"] = JSON.parse(data["data"]);
        this.personHistoryResultModel.responseData = data;
      }, error => {
        this.personHistoryResultModel.searchFail = true;
        this.personHistoryResultModel.isLoading = false;
        console.log("Person History Error: ", error);
      })
    } else {
      this.personHistoryResultModel.validation = true;
    }
  }


  public GetEnhanceRecordPlus(requestData: RequestData) {
    this.enhancedRecordPlanResultModel.isLoading = false;
    this.enhancedRecordPlanResultModel.responseData = null;
    this.enhancedRecordPlanResultModel.searchFail = false;
    this.enhancedRecordPlanResultModel.validation = false;

    console.log("Request Data: ", requestData.profileData);
    if (requestData.profileData.firstName && requestData.profileData.lastName && requestData.profileData.phoneNumber &&
      requestData.profileData.email && (requestData.profileData.streetAddress || requestData.profileData.dateOfBirth)
    ) {
      this.enhancedRecordPlanResultModel.isLoading = true;
      this.australiaSearchService.GetEnhancedRecordPlus(requestData).subscribe(data => {
        this.enhancedRecordPlanResultModel.isLoading = false;
        data["data"] = JSON.parse(data["data"]);
        this.enhancedRecordPlanResultModel.responseData = data;
      }, error => {
        this.enhancedRecordPlanResultModel.searchFail = true;
        this.enhancedRecordPlanResultModel.isLoading = false;
        console.log("Enhanced Record Plan Error: ", error);
      })
    } else {
      this.enhancedRecordPlanResultModel.validation = true;
    }
  }

  public personalClearSearch(): void {
    this.personalForm.controls['profile'].reset();
    this.clearValidators();
    this.resetResultObjects();
    this.showResultTab = false;
  }


  // Email validation check API call
  public emailValidationCheck(requestObj: RequestData): void {
    this.emailValidationResultModel.isLoading = false;
    this.emailValidationResultModel.noRelevantData = false;
    this.emailValidationResultModel.responseData = null;
    this.emailValidationResultModel.searchFail = false;
    this.emailValidationResultModel.validation = false;
    this.emailValidationResultModel.noSearchSelect = false;

    if (requestObj.accessData.isEmailAccess) {
      if (requestObj.profileData.email) {
        this.emailValidationResultModel.isLoading = true;
        this.searchService.EmailValidationCheck(requestObj).subscribe((data) => {

          var debounceReason = data["data"].debounce.reason;

          // Email Age
          this.emailAgeCheck(requestObj, debounceReason);

          this.emailValidationResultModel.isLoading = false;
          this.emailValidationResultModel.responseData = data["data"];
          if (this.emailValidationResultModel?.responseData == null) {
            this.emailValidationResultModel.noRelevantData = true;
          }
        }, (error) => {

          // Email Age
          this.emailAgeCheck(requestObj, "");

          console.log(error);
          this.emailValidationResultModel.isLoading = false;
          if (error.status === 403) {
            this.emailValidationResultModel.searchFail = true;
          }
        });
      } else {
        this.emailValidationResultModel.validation = true;
      }
    } else {
      this.emailValidationResultModel.noSearchSelect = true;
    }
  }


  // Email age API call
  public emailAgeCheck(requestObj: RequestData, debounceReason: string): void {
    this.emailageResultModel.isLoading = false;
    this.emailageResultModel.responseData = null;
    this.emailageResultModel.searchFail = false;
    this.emailageResultModel.noSearchSelect = false;
    this.emailageResultModel.validation = false;

    if (requestObj.profileData.email) {
      if (requestObj.accessData.isEmailAccess) {
        this.emailageResultModel.isLoading = true;
        this.searchService.EmailAge(requestObj, debounceReason).subscribe((data) => {
          this.emailageResultModel.isLoading = false;
          try {
            let completeJson = JSON.parse(data["data"]["completeResponse"]);
            let rawJson: string = completeJson["integration_hub_results"][this.emailageOrgId + ":emailage"]["Emailage"]["tps_vendor_raw_response"];
            completeJson["integration_hub_results"][this.emailageOrgId + ":emailage"]["Emailage"]["tps_vendor_raw_response"] = JSON.parse(rawJson.trim());
            data["data"]["completeResponse"] = completeJson;
          } catch { }
          this.emailageResultModel.responseData = data;
          var rawResult = Array.from(this.emailageResultModel.responseData["data"]["completeResponse"]["integration_hub_results"][this.emailageOrgId + ":emailage"]["Emailage"]["tps_vendor_raw_response"]["query"]["results"]);
          this.emailageResultModel.urls = [];
          rawResult.forEach(x => {
            Array.from(x["smlinks"]).forEach(y => {
              this.emailageResultModel.urls.push(y);
            });
          });
        }, (error) => {
          console.log(error);
          this.emailageResultModel.searchFail = true;
          this.emailageResultModel.isLoading = false;
        });
      } else {
        this.emailageResultModel.noSearchSelect = true;
      }
    } else {
      this.emailageResultModel.validation = true;
    }
  }

   // Telisign check API call
   public telisignCheck(requestObj: RequestData): void {
    const formatPhoneNumber = requestObj.profileData.phoneNumber;
    const phoneNumber = parsePhoneNumber(formatPhoneNumber, 'AU')
    if(phoneNumber.isValid() === true && isPossiblePhoneNumber(formatPhoneNumber, 'AU') === true
      && isValidPhoneNumber(formatPhoneNumber, 'AU') && validatePhoneNumberLength(formatPhoneNumber, 'AU') === undefined){
        requestObj.profileData.phoneNumber = phoneNumber.formatInternational();
    }
    this.mobileResultModel.isLoading = false;
    this.mobileResultModel.responseData = null;
    this.mobileResultModel.searchFail = false;
    this.mobileResultModel.validationError = false;
    this.mobileResultModel.noSearchSelect = false;

    if (requestObj.accessData.isMobileAccess) {
      if (requestObj.profileData.phoneNumber) {
        this.mobileResultModel.isLoading = true;
        this.searchService.GetMobileData(requestObj).subscribe((data) => {
          this.mobileResultModel.isLoading = false;
          console.log(data);
          this.mobileResultModel.responseData = data;
        }, (error) => {
          console.log(error);
          if (error.status === 403) {
            this.mobileResultModel.searchFail = true;
          }
          this.mobileResultModel.isLoading = false;
        });
      } else {
        this.mobileResultModel.validationError = true;
      }
    } else {
      this.mobileResultModel.noSearchSelect = true;
    }

  }

  public openSourceSearch(requestObj: RequestData): void {
    this.opensourceResult.isLoading = false;
    this.opensourceResult.noSearchSelect = false;
    this.opensourceResult.responseData = [];
    this.opensourceResult.tempResponseData = [];
    this.opensourceResult.searchFail = false;
    this.opensourceResult.validationError = false;
    this.opensourceResult.openSourceResultCount = 0;

    this.opensourceResult.isLoading = true;

    this.activeSearchDomains.forEach((searchDomain: SearchDomain) => {
      let index: number = 0;
      this.eachOpenSourceSearch(searchDomain, requestObj, index);
    });
  }

  public eachOpenSourceSearch(domain: SearchDomain, requestObj: RequestData, index: number) {
    this.searchService.OpenSourceSearch(requestObj, domain.UniqueCode).subscribe((data) => {
      this.opensourceResult.searchFail = false;

      if (data["newsitems"] != null) {
        this.opensourceResult.tempResponseData = this.opensourceResult.tempResponseData.concat(data["newsitems"]);
      }
      this.opensourceResult.openSourceResultCount++;

      if (this.opensourceResult.openSourceResultCount == this.activeSearchDomains.length) {
        this.opensourceResult.isLoading = false;
        if (this.opensourceResult.tempResponseData.length > 0) {
          this.opensourceResult.responseData = this.getUniqueResults(this.opensourceResult.tempResponseData, 'link');
        }
        //this.opensourceResult.responseData = this.opensourceResult.responseData;
      }
    }, (error) => {
      console.log(error);
      if (error.status == 401 && error.headers.has('Token-Expired')) {
        this.refreshtoken.token = localStorage.getItem("access_token");
        this.searchService.refreshtoken(this.refreshtoken)
          .subscribe(
            data => {
              localStorage.setItem("access_token", data["access_token"]);
              localStorage.setItem("expires_in", Shared.getExpiryDate(Shared.getAccessTokenExpiryDateTime(data["access_token"]).toUTCString()));
              localStorage.setItem("token_type", data["token_type"]);
            },
            error => {
              if (error.status == 400) {
                this.logout();
              }
            });
      }
      else if (error.status == 502 || error.status == 504) {
        index++;
        if (index < 2) {
          this.eachOpenSourceSearch(domain, requestObj, index);
        }
        else {
          this.opensourceResult.isLoading = false;
          this.opensourceResult.searchFail = true;
        }
      }
      else {
        this.opensourceResult.isLoading = false;
        this.opensourceResult.searchFail = true;
      }
    });
  }

    // Social media (PIPL) API call
    public GetPIPLData(requestObj: RequestData): void {
      this.piplResultModel.isLoading = false;
      this.piplResultModel.noRelevantData = false;
      this.piplResultModel.searchFail = false;
      this.piplResultModel.responseData = null;
      this.piplResultModel.noSearchSelect = false;
      this.piplResultModel.validation = false;
  
      if (requestObj.accessData.isSocialMediaAccess) {
        if (requestObj.profileData.fullName && (requestObj.profileData.email || requestObj.profileData.phoneNumber)) {
          this.piplResultModel.isLoading = true;
          this.searchService.SearchPIPL(requestObj).subscribe((data) => {
            this.piplResultModel.isLoading = false;
            let response = data.data;
            if (response) {
              this.piplResultModel.responseData = response;
            }
            else {
              this.piplResultModel.noRelevantData = true;
            }
          }, (error) => {
            this.piplResultModel.searchFail = true;
            this.piplResultModel.isLoading = false;
            console.log(error);
          })
        } else {
          this.piplResultModel.validation = true;
        }
      } else {
        this.piplResultModel.noSearchSelect = true;
      }
  
    }

    public socialMediaSearch(requestObj: RequestData) {
      this.socialMediaResultModel.noSearchSelect = false;
      this.socialMediaResultModel.isLoading = false;
  
      if (requestObj.accessData.isSocialMediaAccess) {
        this.socialMediaResultModel.isLoading = true;
        this.socialMediaResultModel.socialMediaStatus =
          [
            { name: "facebook", displayname: "Facebook", status: "Searching . . .", searchloading: true, statusCount: 0 },
            { name: "instagram", displayname: "Instagram", status: "Searching . . .", searchloading: true, statusCount: 0 },
            { name: "linkedin", displayname: "LinkedIn", status: "Searching . . .", searchloading: true, statusCount: 0 },
            { name: "twitter", displayname: "Twitter", status: "Searching . . .", searchloading: true, statusCount: 0 },
            { name: "youtube", displayname: "YouTube", status: "Searching . . .", searchloading: true, statusCount: 0 },
            { name: "tiktok", displayname: "TikTok", status: "Searching . . .", searchloading: true, statusCount: 0 },
          ];
  
        this.socialMediaResultModel.collapseSocialMediaClass = "show";
        this.socialMediaResultModel.collapseSocialMediaStatus = "Hide Status";
        this.socialMediaResultModel.collapseSocialMediaIcon = "fa-chevron-up";
  
        this.socialMediaResultModel.socialMediaCategory.forEach((item: string) => {
          this.activeSearchDomains.forEach((searchDomain: SearchDomain) => {
            let socialMediaIndex = 0;
            this.getsocialMediaData(item, searchDomain.UniqueCode, socialMediaIndex, requestObj);
          });
        });
      } else {
        this.socialMediaResultModel.noSearchSelect = true;
      }
    }
  
    public getsocialMediaData(socialMediaCategoryItem: string, searchDomain: string, socialMediaIndex: number, requestObj: RequestData) {
      this.socialMediaResultModel.socialMediaResults = [];
      this.socialMediaResultModel.compareSocialMediaCategory = [];
  
      this.searchService.SocialMedia(socialMediaCategoryItem, searchDomain, requestObj)
        .subscribe(
          data => {
            if (data["newsitems"] != null) {
              data["newsitems"].forEach((item: Items) => {
                item.lexiconcategory = data["key"];
              });
              this.socialMediaResultModel.socialMediaResults = this.socialMediaResultModel.socialMediaResults.concat(data["newsitems"]);
            }
            this.socialMediaResultModel.filtersocialMediaStatus = this.socialMediaResultModel.socialMediaStatus.filter(x => x.name == socialMediaCategoryItem)[0];
            this.socialMediaResultModel.filtersocialMediaStatus.statusCount++
  
            if (this.socialMediaResultModel.filtersocialMediaStatus.statusCount == this.activeSearchDomains.length) {
              this.socialMediaResultModel.compareSocialMediaCategory.push(socialMediaCategoryItem);
              this.socialMediaResultModel.socialMediaSuccessCount++;
              this.socialMediaResultModel.filtersocialMediaStatus.status = "Search completed";
              this.socialMediaResultModel.filtersocialMediaStatus.searchloading = false;
            }
            if (this.socialMediaResultModel.socialMediaSuccessCount == this.socialMediaResultModel.socialMediaStatus.length) {
              this.searchService.updateAudit(requestObj.profileData.searchType, requestObj.profileData.auditKey, 8, this.socialMediaResultModel.compareSocialMediaCategory).subscribe();
            }
  
            if (this.socialMediaResultModel.socialMediaCategory.length == this.socialMediaResultModel.compareSocialMediaCategory.length) {
              this.socialMediaResultModel.collapseSocialMediaClass = "hide";
              this.socialMediaResultModel.collapseSocialMediaStatus = "Show Status";
              this.socialMediaResultModel.collapseSocialMediaIcon = "fa-chevron-down";
  
              this.socialMediaResultModel.isLoading = false;
              if (this.socialMediaResultModel.socialMediaResults.length > 0) {
                this.socialMediaResultModel.socialMediaResults = this.getUniqueResults(this.socialMediaResultModel.socialMediaResults, 'link');
              }
  
              this.socialMediaResultModel.SocialMediaResultCount = this.socialMediaResultModel.socialMediaResults.length;
              this.socialMediaResultModel.finalSocialMediaResults = this.socialMediaResultModel.socialMediaResults;
  
              this.socialMediaResultModel.finalSocialMediaResults.sort((a, b) => {
                if (a.lexiconcategory < b.lexiconcategory) { return -1; }
                return 0;
              });
  
            }
          },
          error => {
            console.log(error);
            if (error.status == 401 && error.headers.has('Token-Expired')) {
              this.refreshtoken.token = localStorage.getItem("access_token");
              this.searchService.refreshtoken(this.refreshtoken)
                .subscribe(
                  data => {
                    localStorage.setItem("access_token", data["access_token"]);
                    localStorage.setItem("expires_in", Shared.getExpiryDate(Shared.getAccessTokenExpiryDateTime(data["access_token"]).toUTCString()));
                    localStorage.setItem("token_type", data["token_type"]);
                    this.socialMediaSearch(requestObj);
                  },
                  error => {
                    if (error.status == 400) {
                      this.logout();
                    }
                  });
            }
            else if (error.status == 502 || error.status == 504) {
              socialMediaIndex++;
              if (socialMediaIndex < 2) {
                this.getsocialMediaData(socialMediaCategoryItem, searchDomain, socialMediaIndex, requestObj);
              }
              else {
                //this.IsSocialMediaValue = true;
              }
            }
            else {
              //this.IsSocialMediaValue = true;
            }
          });
    }

    // Getting unique results from scraper
    public getUniqueResults(arr, link) {
      const unique = arr
        .map(e => e[link])
        // store the keys of the unique objects
        .map((e, i, final) => final.indexOf(e) === i && i)
        // eliminate the dead keys & store unique objects
        .filter(e => arr[e]).map(e => arr[e]);
      return unique;
    }

    public logout() {
      localStorage.removeItem("access_token");
      localStorage.removeItem("expires_in");
      localStorage.removeItem("token_type");
      localStorage.removeItem("userId");
      localStorage.removeItem("subscription");
      this.router.navigate(['/Login']);
    }

  public setActiveResultTab() {
    setTimeout(() => {
      // Tab Header
      var navLine = $('.nav-link');
      $.each(navLine, function (index, x) {
        if ($(x).hasClass("active")) {
          $(x).removeClass("active")
        }
      })

      // Tab Result
      var tabPane = $('.tab-pane');
      $.each(tabPane, function (index, x) {
        if ($(x).hasClass("active")) {
          $(x).removeClass("active")
        }
      })

      // Show first tab as active
      $('#personhistory-search-tab').addClass("active");
      $('#personHistorySearch').addClass("active");
    }, 2000);
  }

  // Prepare the request object for API call
  public prepareRequestData(): RequestData {
    // Profile object framing
    var profileData = new ProfileData();
    profileData.auditKey = this.personalForm.get('profile.audit').value;
    profileData.referenceNumber = this.personalForm.get('profile.reference').value;
    profileData.fullName = this.personalForm.get('profile.name').value.trim();

    if (this.personalForm.get('profile.name').value) {
      let searchtextarray = this.personalForm.get('profile.name').value.trim().split(" ");
      profileData.firstName = searchtextarray[0];
      profileData.lastName = searchtextarray.length > 1 ? searchtextarray[searchtextarray.length - 1] : searchtextarray[1];
      profileData.midleName = searchtextarray.length > 2 ? searchtextarray[1] : null;
    } else {
      profileData.firstName = null;
      profileData.lastName = null;
      profileData.midleName = null;
    }

    profileData.dateOfBirth = this.personalForm.get('profile.dob').value;
    profileData.phoneNumber = this.personalForm.get('profile.phone').value;
    profileData.email = this.personalForm.get('profile.email').value;
    profileData.streetAddress = this.personalForm.get('profile.streetaddress').value;
    profileData.suburb = this.personalForm.get('profile.suburb').value;
    profileData.state = this.personalForm.get('profile.state').value;
    profileData.postalCode = this.personalForm.get('profile.postcode').value;

    profileData.searchDate = Shared.getCurrentDate();
    profileData.searchTime = Shared.getCurrentTime();
    profileData.searchType = SearchType.Personal.toString();
    profileData.searchUsername = this.loggedInUsername;

    // RequestData object framing
    var requestObj = new RequestData();
    requestObj.accessData = this.accessData;
    requestObj.profileData = profileData;
    return requestObj;
  }

  public getUserDetails() {
    this.searchService.getUserDetails().subscribe(data => {
      this.loggedInUsername = data.username;
    }, error => {
      console.log(error);
    })
  }

  public generatePDF() {
    var requestObj: RequestData = this.prepareRequestData();
    this.requestDataModel = requestObj;

    if (this.requestDataModel.profileData.phoneNumber) {
      this.requestDataModel.profileData.phoneNumber = this.personalForm.get('profile.phone').value;
    }

    setTimeout(() => {
      this.pdf.forcePageBreak = ".page-break"
      this.pdf.keepTogether = ".keep-together";
      this.pdf.paperSize = "A4";
      const margin = {
        top: '2cm',
        bottom: '3cm',
        left: '1cm',
        right: '1cm'
      }
      this.pdf.margin = margin;
      this.pdf.scale = 0.5;
      this.pdf.saveAs('export.pdf');
    }, 2000);

  }

  public resetResultObjects(): void {
    this.personHistoryResultModel = new PersonHistoryResultModel();
    this.enhancedRecordPlanResultModel = new EnhancedRecordPlusResultModel();
    this.mobileResultModel = new MobileResultModel();
    this.emailageResultModel = new EmailageCheckResultModel();
    this.piplResultModel = new PIPLResultModel();
    this.socialMediaResultModel = new SocialMediaResultModel();
  }

  public async getRandomString(length) {
    await this.searchService.GetAuditKey().then(
      data => {
        this.personalForm.patchValue({
          profile: {
            audit: data
          }
        });
      });
  }

  // Add the validations during the 'search' button click
  public addValidators(): void {
    for (const topControls in this.personalForm.controls) {
      if (topControls == 'profile') {
        let childControls = this.personalForm.controls[topControls] as FormGroup
        for (const child in childControls.controls) {
          try {
            this.personalForm.get(topControls + "." + child).clearValidators();
            this.personalForm.get(topControls + "." + child).setValidators(this.validationType[child]);
            this.personalForm.get(topControls + "." + child).updateValueAndValidity();
          } catch (err) { }
        }
      }
    }
  }

  // Clear the validations during the 'clear' button click
  // This'll remove all the error message
  public clearValidators(): void {
    this.personalForm.patchValue({
      audit: "",
      reference: "",
      name: "",
      dob: "",
      phone: "",
      email: "",
      streetaddress: "",
      suburb: "",
      state: "",
      postcode: "",
    });
    this.saveAsPDFButtonVisibility = false;
    this.clearSearchButtonVisibility = false;

    for (const topControls in this.personalForm.controls) {
      if (topControls == 'profile') {
        let childControls = this.personalForm.controls[topControls] as FormGroup
        for (const child in childControls.controls) {
          try {
            this.personalForm.get(topControls + "." + child).clearValidators();
            this.personalForm.get(topControls + "." + child).updateValueAndValidity();
          } catch (err) { }
        }
      }
    }
  }

}
