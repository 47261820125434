import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LandingComponent } from './landing/landing.component';
import { BusinessComponent } from './search-pages/components/business/business.component';
import { DocumentvalidationComponent } from './search-pages/components/documentvalidation/documentvalidation.component';
import { EmailComponent } from './search-pages/components/email/email.component';
import { IdcheckComponent } from './search-pages/components/idcheck/idcheck.component';
import { MobileComponent } from './search-pages/components/mobile/mobile.component';
import { MotorComponent } from './search-pages/components/motor/motor.component';
import { PersonalComponent } from './search-pages/components/personal/personal.component';
import { PhotoanalysisComponent } from './search-pages/components/photoanalysis/photoanalysis.component';
import { ProfessionalComponent } from './search-pages/components/professional/professional.component';
import { RtwComponent } from './search-pages/components/rtw/rtw.component';
import { ScreenComponent } from './search-pages/components/screen/screen.component';
import { V3Component } from './v3.component';
import { AuthGuard } from 'src/app/shared/guards/auth.guard';

const routes: Routes = [
  {
    path: 'v3', component: V3Component,
    children: [
      { path: '', component: LandingComponent , canActivate: [AuthGuard]},
      { path: 'personal', component: PersonalComponent, canActivate: [AuthGuard] },
      { path: 'business', component: BusinessComponent, canActivate: [AuthGuard] },
      { path: 'mobile', component: MobileComponent, canActivate: [AuthGuard] },
      { path: 'email', component: EmailComponent, canActivate: [AuthGuard] },
      { path: 'screen', component: ScreenComponent, canActivate: [AuthGuard] },
      { path: 'motor', component: MotorComponent, canActivate: [AuthGuard] },
      { path: 'righttowork', component: RtwComponent, canActivate: [AuthGuard] },
      { path: 'photoanalysis', component: PhotoanalysisComponent, canActivate: [AuthGuard] },
      { path: 'idcheck', component: IdcheckComponent, canActivate: [AuthGuard] },
      { path: 'documentvalidation', component: DocumentvalidationComponent, canActivate: [AuthGuard] },
      { path: 'professional', component: ProfessionalComponent, canActivate: [AuthGuard] }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class V3RoutingModule { }
