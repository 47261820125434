import { Item } from '../../home/models/AccessData';

export class LicenceTemplate {
  public licenceId: Number;
  public tenantId: Number;
  public licenceName: string;
  public allowedLicenseItems: ItemAccess[];
  public description: string;
  public isDeleted: boolean;
  public isActive: boolean;
  public modifiedBy: string;
  public modifiedOn: Date;
  public createdBy: string;
  public createdOn: Date;
}
            
export class ItemAccess{
  public key: string;
  public itemId: number;
  public itemName: string;
  public licenceType: string;
  public defaultValue: boolean;
  public toggle: boolean;
  public imageUrl: string;
  public ParentId: number;
  public disabled?: boolean = true;
}
        
       
