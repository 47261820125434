import { Component, Input, OnInit } from '@angular/core';
import { InputAccessData, InputData } from '../../models/InputDataModel';
import { MobileResponse } from '../../models/MobileResponseModel';

@Component({
  selector: 'app-mobile-enquiry',
  templateUrl: './mobile-enquiry.component.html',
  styleUrls: ['./mobile-enquiry.component.css']
})
export class MobileEnquiryComponent implements OnInit {

  @Input() public inputData: InputData = new InputData();
  @Input() public accessData: InputAccessData = new InputAccessData();
  @Input() public mobile: MobileResponse = new MobileResponse();

  // Constant Messages
  public NoRelevantDataMessageShort = "No records.";
  public NoSearchSelectShort = "Not selected.";
  public SearchFail = "Search failed.";
  public mobileRequiredMessage: string = "Mobile number is required.";
  public RequiredPropertyMissingMessage = "Required properties are missing.";

  constructor() { }

  ngOnInit(): void {
  }

}
