import { Component, Input, OnInit } from '@angular/core';
import { IdCheckResultModel } from '../../../../../../search-pages/models/EmailResultModel';
import { MessagingService } from '../../../../../../services/messaging.service';

@Component({
  selector: 'app-idcheck-result-personal',
  templateUrl: './idcheck-result.component.html',
  styleUrls: ['./idcheck-result.component.css', '../../../../shared/shared.searchpage.css']
})
export class IdcheckResultPersonalComponent implements OnInit {

  @Input() idCheck: IdCheckResultModel = new IdCheckResultModel();

  constructor(private messageService: MessagingService) { }

  ngOnInit(): void {
  }

}
