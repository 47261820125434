import { Injectable, NgZone } from '@angular/core';
import { Token } from '../models/token';
import { SearchService } from '../../demo/V3/services/search.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { Shared } from 'src/app/demo/V3/services/shared';

@Injectable({
  providedIn: 'root'
})
export class IdleService {

  //private idleTimeoutMillis = 3600000; // 1 hour in milliseconds
  private minutesToIdle: number = 60;
  private idleTimeoutMillis: number = this.minutesToIdle * 60 * 1000;
  private lastActionTime: number;
  private idleTimeoutId: any;
  private userActivityEvents$ = new Subject<void>();
  private isRefreshAPICalling: boolean = false;

  constructor(private router: Router, private searchService: SearchService) {
    this.resetIdleTimer();
    this.startWatching();
  }

  startWatching() {
    ['keydown', 'click'].forEach(event => {
      window.addEventListener(event, () => this.resetIdleTimer());
    });
    this.userActivityEvents$.subscribe(() => this.resetIdleTimer());
  }

  resetIdleTimer() {
    this.checkIdleTime();
    this.lastActionTime = Date.now();
    if (this.idleTimeoutId) {
      clearTimeout(this.idleTimeoutId);
    }

    this.idleTimeoutId = setTimeout(() => this.checkIdleTime(), this.idleTimeoutMillis);
  }

  checkIdleTime() {
    const currentTime = Date.now();
    const timeDiff = currentTime - this.lastActionTime;
    const minutes = (timeDiff / 1000) / 60;
    if (minutes > this.minutesToIdle) {
      this.logout();
    } else {
      this.refreshToken();
    }
  }

  private logout() {
    // Perform logout operation
    localStorage.removeItem("access_token");
    localStorage.removeItem("expires_in");
    localStorage.removeItem("token_type");
    localStorage.removeItem("userId");
    localStorage.removeItem("username");
    // Clear local storage
    // Redirect to login page or other action
    this.router.navigate(['/Login']);
  }

  public parseDate(dateStr): Date {
    return new Date(dateStr);
  }

  private refreshToken() {
    let expiryDate = Shared.getAccessTokenExpiryDateTime(null);
    if (expiryDate && this.isRefreshAPICalling == false) {
      var today = new Date();
      if (expiryDate >= today) {
        this.isRefreshAPICalling = true;
        var token = new Token(localStorage.getItem("access_token"));
        this.searchService.refreshtoken(token).subscribe(
          (data) => {
            this.isRefreshAPICalling = false;
            localStorage.setItem("access_token", data["access_token"]);
            localStorage.setItem("expires_in", Shared.getExpiryDate(Shared.getAccessTokenExpiryDateTime(data["access_token"]).toUTCString()));
            localStorage.setItem("token_type", data["token_type"]);
          },
          (error) => {
            this.isRefreshAPICalling = false;
            if (error.status == 400) {
              this.logout();
            }
          }
        );
      }
    }
  }
}
