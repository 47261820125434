import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SearchService } from '../../home/services/search.service';

@Component({
  selector: 'app-usidcheckdemo',
  templateUrl: './usidcheckdemo.component.html',
  styleUrls: ['./usidcheckdemo.component.css']
})
export class UsidcheckdemoComponent implements OnInit {

  public reference2: string = "";
  public name: string = "";
  public email: string = "";
  public hasRearPage: boolean = false;
  public File: File[];
  public FileNames: string[] = [];
  public FileNameLabel: string;

  public USIdCheckUploadResult: any;
  public USIdCheckResult: any;

  @ViewChild('file') fileInput: ElementRef;

  constructor(private elementRef: ElementRef, private searchService: SearchService) { }

  ngAfterViewInit() {
    this.elementRef.nativeElement.ownerDocument.body.style.background = 'url(/assets/Images/Background.svg)';
  }

  ngOnInit(): void {
  }

  public UploadImageDemo() {
    if (this.File.length == 0) {
      alert("Please upload atlease 1 file.");
      return;
    }

    if (this.File.length > 2) {
      alert("Please upload 2 files only.");
      return;
    }

    const formData = new FormData();
    if (this.File.length > 0) {
      if (this.File.length == 2) {
        formData.append('frontfile', this.File[0], this.File[0].name);
        formData.append('rearfile', this.File[1], this.File[1].name);
      } else if (this.File.length == 1) {
        formData.append('frontfile', this.File[0], this.File[0].name);
      }
    }
    if (this.File.length > 0) {
      this.searchService.USIDCheck("", "", this.reference2, formData).subscribe(
        data => {
          this.USIdCheckUploadResult = data;
        },
        error => {
          console.log(error);
        }
      );
    }
  }

  public SearchUSIDCheckDemo() {
    if ((!this.name || !this.email)) {
      alert("Please enter name and email.");
      return;
    }

    const formData = new FormData();

    this.searchService.USIDCheck(this.name, this.email, this.reference2, formData).subscribe(
      data => {
        this.USIdCheckResult = data;
      },
      error => {
        console.log(error);
      }
    );
  }

  onFileSelected(event) {
    if (event.target.files.length > 0) {
      if (event.target.files.length <= 2) {
        this.File = Array.from<File>(event.srcElement.files);
        this.FileNames = this.File.map(x => { return x.name });
        this.FileNameLabel = this.FileNames.join(', ');
      } else {
        alert("select only 2 files");
      }
    }
  }

  public ClearButtonClick1() {
    this.name = "";
    this.email = "";
    this.USIdCheckResult = null;
  }

  public ClearButtonClick2() {
    this.USIdCheckUploadResult = null;
    this.reference2 = "";
    this.File = [];
    this.FileNames = [];
    this.FileNameLabel = "";
    this.fileInput.nativeElement.value = "";
  }

}
