<table class="table table-striped table-hover">
  <tbody>
    <tr>
      <th>Filing Date Of Recent Accounts</th>
      <td>{{business?.responseData?.creditsafeResult?.report?.additionalInformation?.miscellaneous?.filingDateOfAccounts | date:'MMM-yyyy'}}</td>
    </tr>
    <tr>
      <th>Date Accounts Due</th>
      <td>{{business?.responseData?.creditsafeResult?.report?.additionalInformation?.miscellaneous?.accountsDueDate | date:'MMM-yyyy'}}</td>
    </tr>
    <tr>
      <td colspan="2">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>Type</th>
              <th>Year End Date</th>
              <th>Number Of Weeks</th>
              <th>Currency</th>
              <th>Consolidated Account</th>
              <th>Audit Summary</th>
              <th>Accounts Stats</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let financial of business?.responseData?.creditsafeResult?.report?.financialStatements">
              <td>{{financial?.type}}</td>
              <td>{{financial?.yearEndDate | date: 'dd-MM-yyyy'}}</td>
              <td>{{financial?.numberOfWeeks}}</td>
              <td>{{financial?.currency}}</td>
              <td>{{financial?.consolidatedAccounts}}</td>
              <td>Not found</td>
              <td>Not found</td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  </tbody>
</table>

<h3>Profit & Loss</h3>
<p-table [value]="business?.responseData?.creditsafeResult?.report?.financialStatements" dataKey="yearEndDate" [expandedRowKeys]="yearEndExpand()">
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 3rem"></th>
      <th pSortableColumn="yearEndDate">Year End Date <p-sortIcon field="yearEndDate"></p-sortIcon></th>
      <th pSortableColumn="revenue">Revenue <p-sortIcon field="revenue"></p-sortIcon></th>
      <th pSortableColumn="exports">Exports <p-sortIcon field="exports"></p-sortIcon></th>
      <th pSortableColumn="costOfSales">Cost Of Sales <p-sortIcon field="costOfSales"></p-sortIcon></th>
      <th pSortableColumn="auditFees">Audit Fees <p-sortIcon field="auditFees"></p-sortIcon></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-financial let-expanded="expanded">
    <tr>
      <td>
        <button type="button" pButton pRipple [pRowToggler]="financial" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
      </td>
      <td>{{financial?.yearEndDate | date:'dd-MMM-yyyy'}}</td>
      <td>{{financial?.profitAndLoss?.revenue}}</td>
      <td>{{financial?.profitAndLoss?.exports}}</td>
      <td>{{financial?.profitAndLoss?.costOfSales}}</td>
      <td>{{financial?.profitAndLoss?.auditFees}}</td>
    </tr>
  </ng-template>
  <ng-template pTemplate="rowexpansion" let-financial>
    <tr>
      <td colspan="5">
        <div class="row">
          <div class="col-md-4">
            <div><b>Revenue</b></div>
            <div>{{financial?.profitAndLoss?.revenue}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Exports</b></div>
            <div>{{financial?.profitAndLoss?.exports}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Cost Of Sales</b></div>
            <div>{{financial?.profitAndLoss?.costOfSales}}</div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-4">
            <div><b>Audit Fees</b></div>
            <div>{{financial?.profitAndLoss?.auditFees}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Statutory Audit Costs</b></div>
            <div>{{financial?.profitAndLoss?.statutoryAuditCosts}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Other Audit Costs</b></div>
            <div>{{financial?.profitAndLoss?.otherAuditCosts}}</div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-4">
            <div><b>Accountant Fees</b></div>
            <div>{{financial?.profitAndLoss?.accountantFees}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Operating Cost</b></div>
            <div>{{financial?.profitAndLoss?.operatingCosts}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Operating Profit</b></div>
            <div>{{financial?.profitAndLoss?.operatingProfit}}</div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-4">
            <div><b>Director Remineration</b></div>
            <div>{{financial?.profitAndLoss?.directorsRemuneration}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Director Fees</b></div>
            <div>{{financial?.profitAndLoss?.directorFees}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Director Pensions</b></div>
            <div>{{financial?.profitAndLoss?.directorPensions}}</div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-4">
            <div><b>Director Social Security</b></div>
            <div>{{financial?.profitAndLoss?.directorSocialSecurity}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Director Other</b></div>
            <div>{{financial?.profitAndLoss?.directorOther}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Highest Paid Director</b></div>
            <div>{{financial?.profitAndLoss?.highestPaidDirector}}</div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-4">
            <div><b>Employee Costs</b></div>
            <div>{{financial?.profitAndLoss?.employeeCosts}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Other Employee Costs</b></div>
            <div>{{financial?.profitAndLoss?.otherEmployeeCosts}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Employee Fees</b></div>
            <div>{{financial?.profitAndLoss?.employeeRemuneration}}</div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-4">
            <div><b>Wages and Salaries</b></div>
            <div>{{financial?.profitAndLoss?.wagesAndSalaries}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Pensions</b></div>
            <div>{{financial?.profitAndLoss?.pensions}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Pension Costs</b></div>
            <div>{{financial?.profitAndLoss?.pensionCosts}}</div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-4">
            <div><b>Deprecitation</b></div>
            <div>{{financial?.profitAndLoss?.depreciation}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Depreciation Of Tangibles</b></div>
            <div>{{financial?.profitAndLoss?.depreciationOfTangibles}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Amortisation</b></div>
            <div>{{financial?.profitAndLoss?.amortisation}}</div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-4">
            <div><b>Amortisation Of Intangibles</b></div>
            <div>{{financial?.profitAndLoss?.amortisationOfIntangibles}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Financial Expenses</b></div>
            <div>{{financial?.profitAndLoss?.financialExpenses}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Interest Receivable</b></div>
            <div>{{financial?.profitAndLoss?.interestReceivable}}</div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-4">
            <div><b>Bank Interest Receivable</b></div>
            <div>{{financial?.profitAndLoss?.bankInterestReceivable}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Group Interest Receivable</b></div>
            <div>{{financial?.profitAndLoss?.groupInterestReceivable}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Other Interest Receivable</b></div>
            <div>{{financial?.profitAndLoss?.otherInterestReceivable}}</div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-4">
            <div><b>Interest Payable</b></div>
            <div>{{financial?.profitAndLoss?.interestPayable}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Bank Interest Payable</b></div>
            <div>{{financial?.profitAndLoss?.bankInterestPayable}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Lease Interest Payable</b></div>
            <div>{{financial?.profitAndLoss?.leaseInterestPayable}}</div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-4">
            <div><b>HP Interest Payable</b></div>
            <div>{{financial?.profitAndLoss?.hpInterestPayable}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Other Interest Payable</b></div>
            <div>{{financial?.profitAndLoss?.otherInterestPayable}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Social Security</b></div>
            <div>{{financial?.profitAndLoss?.socialSecurity}}</div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-4">
            <div><b>Gross Profit</b></div>
            <div>{{financial?.profitAndLoss?.grossProfit}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Pre Tax Profit</b></div>
            <div>{{financial?.profitAndLoss?.preTaxProfit}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Profit Before Tax</b></div>
            <div>{{financial?.profitAndLoss?.profitBeforeTax}}</div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-4">
            <div><b>Tax</b></div>
            <div>{{financial?.profitAndLoss?.tax}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Profit After Tax</b></div>
            <div>{{financial?.profitAndLoss?.profitAfterTax}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Exceptional Items</b></div>
            <div>{{financial?.profitAndLoss?.exceptionalItems}}</div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-4">
            <div><b>Exceptional Profit or Loss On Reorganisations</b></div>
            <div>{{financial?.profitAndLoss?.exceptionalProfitOrLossOnReorganisations}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Exceptional Profit or Loss On Disposal Or Acquisition</b></div>
            <div>{{financial?.profitAndLoss?.exceptionalProfitOrLossOnDisposalOrAcquisition}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Exceptional Other Items</b></div>
            <div>{{financial?.profitAndLoss?.exceptionalOtherItems}}</div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-4">
            <div><b>Dividends</b></div>
            <div>{{financial?.profitAndLoss?.dividends}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Dividends Paid</b></div>
            <div>{{financial?.profitAndLoss?.dividendsPaid}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Minority Interests</b></div>
            <div>{{financial?.profitAndLoss?.minorityInterests}}</div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-4">
            <div><b>Profit Attributable To Minority Interests</b></div>
            <div>{{financial?.profitAndLoss?.profitAttributableToMinorityInterests}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Other Appropriations</b></div>
            <div>{{financial?.profitAndLoss?.otherAppropriations}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Retained Profit</b></div>
            <div>{{financial?.profitAndLoss?.retainedProfit}}</div>
          </div>
        </div>
        <br />
      </td>
    </tr>
  </ng-template>
</p-table>

<h3>Balance Sheet</h3>
<p-table [value]="business?.responseData?.creditsafeResult?.report?.financialStatements" dataKey="yearEndDate" [expandedRowKeys]="yearEndExpand()">
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 3rem"></th>
      <th pSortableColumn="yearEndDate">Year End Date <p-sortIcon field="yearEndDate"></p-sortIcon></th>
      <th pSortableColumn="totalTangibleAssets">Tangible Assets <p-sortIcon field="totalTangibleAssets"></p-sortIcon></th>
      <th pSortableColumn="totalIntangibleAssets">Total Tangible Assets <p-sortIcon field="totalIntangibleAssets"></p-sortIcon></th>
      <th pSortableColumn="intangibleAssets">Intangible Assets <p-sortIcon field="intangibleAssets"></p-sortIcon></th>
      <th pSortableColumn="totalIntangibleAssets">Total Intangible Assets <p-sortIcon field="totalIntangibleAssets"></p-sortIcon></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-financial let-expanded="expanded">
    <tr>
      <td>
        <button type="button" pButton pRipple [pRowToggler]="financial" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
      </td>
      <td>{{financial?.yearEndDate | date:'dd-MMM-yyyy'}}</td>
      <td>{{financial?.balanceSheet?.totalTangibleAssets}}</td>
      <td>{{financial?.balanceSheet?.totalIntangibleAssets}}</td>
      <td>{{financial?.balanceSheet?.intangibleAssets}}</td>
      <td>{{financial?.balanceSheet?.totalIntangibleAssets}}</td>
    </tr>
  </ng-template>
  <ng-template pTemplate="rowexpansion" let-financial>
    <tr>
      <td colspan="5">
        <div class="row">
          <div class="col-md-4">
            <div><b>Tangible Assets</b></div>
            <div>{{financial?.balanceSheet?.totalTangibleAssets}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Total Tangible Assets</b></div>
            <div>{{financial?.balanceSheet?.totalIntangibleAssets}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Intangible Assets</b></div>
            <div>{{financial?.balanceSheet?.intangibleAssets}}</div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-4">
            <div><b>Total Intangible Assets</b></div>
            <div>{{financial?.balanceSheet?.totalIntangibleAssets}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Investment And Other Assets</b></div>
            <div>{{financial?.balanceSheet?.investmentAndOtherAssets}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Investment Assets</b></div>
            <div>{{financial?.balanceSheet?.investmentAssets}}</div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-4">
            <div><b>Financial Assets</b></div>
            <div>{{financial?.balanceSheet?.financialAssets}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Miscellaneous Current Assets</b></div>
            <div>{{financial?.balanceSheet?.miscCurrentAssets}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Other Current Assets</b></div>
            <div>{{financial?.balanceSheet?.otherCurrentAssets}}</div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-4">
            <div><b>Other Fixed Assets</b></div>
            <div>{{financial?.balanceSheet?.totalOtherFixedAssets}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Other Current Assets</b></div>
            <div>{{financial?.balanceSheet?.otherCurrentAssets}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Current Financial Assets</b></div>
            <div>{{financial?.balanceSheet?.currentFinancialAssets}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Net Assets</b></div>
            <div>{{financial?.balanceSheet?.netAssets}}</div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-4">
            <div><b>Total Current Assets</b></div>
            <div>{{financial?.balanceSheet?.totalCurrentAssets}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Total Fixed Assets</b></div>
            <div>{{financial?.balanceSheet?.totalFixedAssets}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Total Assets</b></div>
            <div>{{financial?.balanceSheet?.totalAssets}}</div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-4">
            <div><b>Trade Debtors</b></div>
            <div>{{financial?.balanceSheet?.tradeDebtors}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Other Debtors</b></div>
            <div>{{financial?.balanceSheet?.otherDebtors}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Debtors Due After 1 Year</b></div>
            <div>{{financial?.balanceSheet?.debtorsDueAfter1Year}}</div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-4">
            <div><b>Other Non Current Assets</b></div>
            <div>{{financial?.balanceSheet?.otherNonCurrentAssets}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Total Non Current Assets</b></div>
            <div>{{financial?.balanceSheet?.totalNonCurrentAssets}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Stock</b></div>
            <div>{{financial?.balanceSheet?.stock}}</div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-4">
            <div><b>Inventories</b></div>
            <div>{{financial?.balanceSheet?.inventories}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Total Inventories</b></div>
            <div>{{financial?.balanceSheet?.totalInventories}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Raw Materials</b></div>
            <div>{{financial?.balanceSheet?.rawMaterials}}</div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-4">
            <div><b>Work In Progress</b></div>
            <div>{{financial?.balanceSheet?.workInProgress}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Finished Goods</b></div>
            <div>{{financial?.balanceSheet?.finishedGoods}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Trade And Other Reveivables</b></div>
            <div>{{financial?.balanceSheet?.tradeAndOtherReceivables}}</div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-4">
            <div><b>Trade Receivables</b></div>
            <div>{{financial?.balanceSheet?.tradeReceivables}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Group Receivables</b></div>
            <div>{{financial?.balanceSheet?.groupReceivables}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Miscellaneous Receivables</b></div>
            <div>{{financial?.balanceSheet?.miscellaneousReceivables}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Total Receivables</b></div>
            <div>{{financial?.balanceSheet?.totalReceivables}}</div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-4">
            <div><b>Miscellaneous Debtors</b></div>
            <div>{{financial?.balanceSheet?.miscDebtors}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Cash</b></div>
            <div>{{financial?.balanceSheet?.cash}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Cash And Cash Equivalents</b></div>
            <div>{{financial?.balanceSheet?.cashAndCashEquivalents}}</div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-4">
            <div><b>Trade And Other Payables</b></div>
            <div>{{financial?.balanceSheet?.tradeAndOtherPayables}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Trade Payables</b></div>
            <div>{{financial?.balanceSheet?.tradePayables}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Group Payables</b></div>
            <div>{{financial?.balanceSheet?.groupPayables}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Current Tax Liabilities</b></div>
            <div>{{financial?.balanceSheet?.currentTaxLiabilities}}</div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-4">
            <div><b>Accruals And Deferred Income</b></div>
            <div>{{financial?.balanceSheet?.accrualsAndDeferredIncome}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Other Payables</b></div>
            <div>{{financial?.balanceSheet?.otherPayables}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Current Financial Liabilities</b></div>
            <div>{{financial?.balanceSheet?.currentFinancialLiabilities}}</div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-4">
            <div><b>Current Bank Borrowings</b></div>
            <div>{{financial?.balanceSheet?.bankBorrowingsCurrent}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Bank Overdraft</b></div>
            <div>{{financial?.balanceSheet?.bankOverdraft}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Bank Loan</b></div>
            <div>{{financial?.balanceSheet?.bankLoan}}</div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-4">
            <div><b>Miscellaneous Current Liabilities</b></div>
            <div>{{financial?.balanceSheet?.miscCurrentLiabilities}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Bank Liabilities Due After 1 Year</b></div>
            <div>{{financial?.balanceSheet?.bankLiabilitiesDueAfter1Year}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Bank Liabilities</b></div>
            <div>{{financial?.balanceSheet?.bankLiabilities}}</div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-4">
            <div><b>Group Accounts</b></div>
            <div>{{financial?.balanceSheet?.groupAccounts}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Directors Accounts</b></div>
            <div>{{financial?.balanceSheet?.directorsAccounts}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Other Short Term Loans</b></div>
            <div>{{financial?.balanceSheet?.otherShortTermLoans}}</div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-4">
            <div><b>Other Long Term Finance</b></div>
            <div>{{financial?.balanceSheet?.otherLongTermFinance}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Other Loans Or Finance Due After 1 Year</b></div>
            <div>{{financial?.balanceSheet?.otherLoansOrFinanceDueAfter1Year}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Other Short Term Finance</b></div>
            <div>{{financial?.balanceSheet?.otherShortTermFinance}}</div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-4">
            <div><b>Other Loans Or Finance</b></div>
            <div>{{financial?.balanceSheet?.otherLoansOrFinance}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Current Hire Purchase Commitments</b></div>
            <div>{{financial?.balanceSheet?.currentHirePurchaseCommitments}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Current Lease Commitments</b></div>
            <div>{{financial?.balanceSheet?.currentLeaseCommitments}}</div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-4">
            <div><b>Current Grants</b></div>
            <div>{{financial?.balanceSheet?.currentGrants}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Other Current Financial Liabilities</b></div>
            <div>{{financial?.balanceSheet?.otherCurrentFinancialLiabilities}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Trade Creditors</b></div>
            <div>{{financial?.balanceSheet?.tradeCreditors}}</div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-4">
            <div><b>Total Other Current Liabilities</b></div>
            <div>{{financial?.balanceSheet?.totalOtherCurrentLiabilities}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Total Long Term Liabilities</b></div>
            <div>{{financial?.balanceSheet?.totalLongTermLiabilities}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Total Liabilities</b></div>
            <div>{{financial?.balanceSheet?.totalLiabilities}}</div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-4">
            <div><b>Current Liabilities Held For Resale</b></div>
            <div>{{financial?.balanceSheet?.currentLiabilitiesHeldForResale}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Provisions And Charges</b></div>
            <div>{{financial?.balanceSheet?.provisionsAndCharges}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Other Current Liabilities</b></div>
            <div>{{financial?.balanceSheet?.otherCurrentLiabilities}}</div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-4">
            <div><b>Miscellaneous Liabilities</b></div>
            <div>{{financial?.balanceSheet?.miscellaneousLiabilities}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Miscellaneous Liabilities Due After 1 Year</b></div>
            <div>{{financial?.balanceSheet?.miscellaneousLiabilitiesDueAfter1Year}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Total Current Liabilities</b></div>
            <div>{{financial?.balanceSheet?.totalCurrentLiabilities}}</div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-4">
            <div><b>Non Current Financial Liabilities</b></div>
            <div>{{financial?.balanceSheet?.nonCurrentFinancialLiabilities}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Interest Bearing Loans</b></div>
            <div>{{financial?.balanceSheet?.interestBearingLoans}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Non Current Hire Purchase Commitments</b></div>
            <div>{{financial?.balanceSheet?.nonCurrentHirePurchaseCommitments}}</div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-4">
            <div><b>Non Current Lease Commitments</b></div>
            <div>{{financial?.balanceSheet?.nonCurrentLeaseCommitments}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Non Current Group Loans</b></div>
            <div>{{financial?.balanceSheet?.nonCurrentGroupLoans}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Non Current Directors Loans</b></div>
            <div>{{financial?.balanceSheet?.nonCurrentDirectorsLoans}}</div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-4">
            <div><b>Other Non Current Financial Liabilities</b></div>
            <div>{{financial?.balanceSheet?.otherNonCurrentFinancialLiabilities}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Non Current Grants</b></div>
            <div>{{financial?.balanceSheet?.nonCurrentGrants}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Total Other Non Current Liabilities</b></div>
            <div>{{financial?.balanceSheet?.totalOtherNonCurrentLiabilities}}</div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-4">
            <div><b>Non Current Trade Payables</b></div>
            <div>{{financial?.balanceSheet?.nonCurrentTradePayables}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Non Current Group Accounts Payable</b></div>
            <div>{{financial?.balanceSheet?.nonCurrentGroupAccountsPayable}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Non Current Liabilities Held For Resale</b></div>
            <div>{{financial?.balanceSheet?.nonCurrentLiabilitiesHeldForResale}}</div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-4">
            <div><b>Accruals And Deferred Income Due After 1 Year</b></div>
            <div>{{financial?.balanceSheet?.accrualsAndDeferredIncomeDueAfter1Year}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Non Current Other Payables</b></div>
            <div>{{financial?.balanceSheet?.nonCurrentOtherPayables}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Provisions</b></div>
            <div>{{financial?.balanceSheet?.provisions}}</div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-4">
            <div><b>Deferred Tax Liabilities</b></div>
            <div>{{financial?.balanceSheet?.deferredTaxLiabilities}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Other Provisions</b></div>
            <div>{{financial?.balanceSheet?.otherProvisions}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Pension Liabilities</b></div>
            <div>{{financial?.balanceSheet?.pensionLiabilities}}</div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-4">
            <div><b>Total Non Current Liabilities</b></div>
            <div>{{financial?.balanceSheet?.totalNonCurrentLiabilities}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Total Called Up Or Issued Capital</b></div>
            <div>{{financial?.balanceSheet?.totalCalledUpOrIssuedCapital}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Ordinary Shares</b></div>
            <div>{{financial?.balanceSheet?.ordinaryShares}}</div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-4">
            <div><b>Preference Shares</b></div>
            <div>{{financial?.balanceSheet?.preferenceShares}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Other Shares</b></div>
            <div>{{financial?.balanceSheet?.otherShares}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Revenue Reserves</b></div>
            <div>{{financial?.balanceSheet?.revenueReserves}}</div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-4">
            <div><b>Profit And Loss Or Revenue Reserve</b></div>
            <div>{{financial?.balanceSheet?.profitAndLossOrRevenueReserve}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Revaluation Reserve</b></div>
            <div>{{financial?.balanceSheet?.revaluationReserve}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Other Reserves</b></div>
            <div>{{financial?.balanceSheet?.otherReserves}}</div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-4">
            <div><b>Issued Share Capital</b></div>
            <div>{{financial?.balanceSheet?.issuedShareCapital}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Called Up Share Capital</b></div>
            <div>{{financial?.balanceSheet?.calledUpShareCapital}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Total Shareholders Equity</b></div>
            <div>{{financial?.balanceSheet?.totalShareholdersEquity}}</div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-4">
            <div><b>Total Shareholders Funds</b></div>
            <div>{{financial?.balanceSheet?.totalShareholdersFunds}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Minority Interests</b></div>
            <div>{{financial?.balanceSheet?.minorityInterests}}</div>
          </div>
        </div>
        <br />
      </td>
    </tr>
  </ng-template>
</p-table>

<br />

<h3>Cash Flow</h3>
<p-table [value]="business?.responseData?.creditsafeResult?.report?.financialStatements" dataKey="yearEndDate" [expandedRowKeys]="yearEndExpand()">
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="yearEndDate">Year End Date <p-sortIcon field="yearEndDate"></p-sortIcon></th>
      <th pSortableColumn="netCashFlowFromOperations">Net Cash Flow From Operations <p-sortIcon field="netCashFlowFromOperations"></p-sortIcon></th>
      <th pSortableColumn="netCashFlowBeforeFinancing">Net Cash Flow Before Financing <p-sortIcon field="netCashFlowBeforeFinancing"></p-sortIcon></th>
      <th pSortableColumn="netCashFlowFromFinancing">Net Cash Flow From Financing <p-sortIcon field="netCashFlowFromFinancing"></p-sortIcon></th>
      <th pSortableColumn="increaseInCash">Increase In Cash <p-sortIcon field="increaseInCash"></p-sortIcon></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-financial let-expanded="expanded">
    <tr>
      <td>{{financial?.yearEndDate | date:'dd-MMM-yyyy'}}</td>
      <td>{{financial?.cashFlow?.netCashFlowFromOperations}}</td>
      <td>{{financial?.cashFlow?.netCashFlowBeforeFinancing}}</td>
      <td>{{financial?.cashFlow?.netCashFlowFromFinancing}}</td>
      <td>{{financial?.cashFlow?.increaseInCash}}</td>
    </tr>
  </ng-template>
</p-table>

<h3>Other Financials</h3>
<p-table [value]="business?.responseData?.creditsafeResult?.report?.financialStatements" dataKey="yearEndDate" [expandedRowKeys]="yearEndExpand()">
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="yearEndDate">Year End Date <p-sortIcon field="yearEndDate"></p-sortIcon></th>
      <th pSortableColumn="contingentLiabilities">Contingent Liabilities <p-sortIcon field="contingentLiabilities"></p-sortIcon></th>
      <th pSortableColumn="bankOverdraftAndLTL">Bank Overdraft And Long Term Loans <p-sortIcon field="bankOverdraftAndLTL"></p-sortIcon></th>
      <th pSortableColumn="workingCapital">Working Capital <p-sortIcon field="workingCapital"></p-sortIcon></th>
      <th pSortableColumn="capitalEmployed">Capital Employed <p-sortIcon field="capitalEmployed"></p-sortIcon></th>
      <th pSortableColumn="netWorth">Net Worth <p-sortIcon field="netWorth"></p-sortIcon></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-financial let-expanded="expanded">
    <tr>
      <td>{{financial?.yearEndDate | date:'dd-MMM-yyyy'}}</td>
      <td>{{financial?.otherFinancials?.contingentLiabilities}}</td>
      <td>{{financial?.otherFinancials?.bankOverdraftAndLTL}}</td>
      <td>{{financial?.otherFinancials?.workingCapital}}</td>
      <td>{{financial?.otherFinancials?.capitalEmployed}}</td>
      <td>{{financial?.otherFinancials?.netWorth}}</td>
    </tr>
  </ng-template>
</p-table>

<h3>Ratios</h3>
<p-table [value]="business?.responseData?.creditsafeResult?.report?.financialStatements" dataKey="yearEndDate" [expandedRowKeys]="yearEndExpand()">
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 3rem"></th>
      <th pSortableColumn="yearEndDate">Year End Date <p-sortIcon field="yearEndDate"></p-sortIcon></th>
      <th pSortableColumn="preTaxProfitMargin">Pre Tax Profit Margin <p-sortIcon field="preTaxProfitMargin"></p-sortIcon></th>
      <th pSortableColumn="returnOnCapitalEmployed">Return On Capital Employed <p-sortIcon field="returnOnCapitalEmployed"></p-sortIcon></th>
      <th pSortableColumn="returnOnTotalAssetsEmployed">Return On Total Assets Employed <p-sortIcon field="returnOnTotalAssetsEmployed"></p-sortIcon></th>
      <th pSortableColumn="returnOnNetAssetsEmployed">Return On Net Assets Employed <p-sortIcon field="returnOnNetAssetsEmployed"></p-sortIcon></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-financial let-expanded="expanded">
    <tr>
      <td>
        <button type="button" pButton pRipple [pRowToggler]="financial" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
      </td>
      <td>{{financial?.yearEndDate | date:'dd-MMM-yyyy'}}</td>
      <td>{{financial?.ratios?.preTaxProfitMargin}}</td>
      <td>{{financial?.ratios?.returnOnCapitalEmployed}}</td>
      <td>{{financial?.ratios?.returnOnTotalAssetsEmployed}}</td>
      <td>{{financial?.ratios?.returnOnNetAssetsEmployed}}</td>
    </tr>
  </ng-template>
  <ng-template pTemplate="rowexpansion" let-financial>
    <tr>
      <td colspan="5">
        <div class="row">
          <div class="col-md-4">
            <div><b>Sales Or Net Working Capital</b></div>
            <div>{{financial?.ratios?.salesOrNetWorkingCapital}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Stock Turnover Ratio</b></div>
            <div>{{financial?.ratios?.stockTurnoverRatio}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Debtor Days</b></div>
            <div>{{financial?.ratios?.debtorDays}}</div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-4">
            <div><b>Creditor Days</b></div>
            <div>{{financial?.ratios?.creditorDays}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Current Ratio</b></div>
            <div>{{financial?.ratios?.currentRatio}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Liquidity Ratio Or Acid Test</b></div>
            <div>{{financial?.ratios?.liquidityRatioOrAcidTest}}</div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-4">
            <div><b>Current Debt Ratio</b></div>
            <div>{{financial?.ratios?.currentDebtRatio}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Gearing</b></div>
            <div>{{financial?.ratios?.gearing}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Equity In Percentage</b></div>
            <div>{{financial?.ratios?.equityInPercentage}}</div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-4">
            <div><b>Total Debt Ratio</b></div>
            <div>{{financial?.ratios?.totalDebtRatio}}</div>
          </div>
        </div>
        <br />
      </td>
    </tr>
  </ng-template>
</p-table>
