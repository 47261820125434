import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CriminalityResultModel } from '../../../../../../search-pages/models/CriminalityResultModel';
import { MessagingService } from '../../../../../../services/messaging.service';
declare var $: any;

@Component({
  selector: 'app-criminality-result-personal',
  templateUrl: './criminality-result.component.html',
  styleUrls: ['./criminality-result.component.css', '../../../../shared/shared.searchpage.css']
})
export class CriminalityResultPersonalComponent implements OnInit {

  @Input() criminality: CriminalityResultModel = new CriminalityResultModel();
  @Output() criminalityEventEmitter: EventEmitter<string> = new EventEmitter<string>();

  constructor(private messageService: MessagingService) { }

  ngOnInit(): void {
  }

  public ResultTableDisplay() {
    if (this.criminality.collapseResultClass == "show") {
      this.criminality.collapseResultClass = "hide";
      this.criminality.collapseResultStatus = "Show Status";
      this.criminality.collapseResultIcon = "fa-chevron-down";
      $('#collapseOne').collapse(this.criminality.collapseResultClass);
    }
    else {
      this.criminality.collapseResultClass = "show";
      $('#collapseOne').collapse(this.criminality.collapseResultClass);
      this.criminality.collapseResultStatus = "Hide Status";
      this.criminality.collapseResultIcon = "fa-chevron-up";
    }
  }

  public AllResults() {
    this.criminalityEventEmitter.emit("allResult");
  }

  public RelevantResults() {
    this.criminalityEventEmitter.emit("relevantResult");
  }

}
