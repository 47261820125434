<div class="app-outer-container page-background">
  <div class="container color-purple">
    <div class="row">
      <div class="col-md-7">
      </div>
      <div class="col-md-5">
        <div class="card card-body card-radius">
          <h2 class="headingalign text-center">Login</h2>
          <form name="form" (ngSubmit)="f.form.valid && Userlogin()" #f="ngForm">
            <div class="form-group row">
              <div class="col-md-12">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fa fa-envelope" aria-hidden="true"></i></span>
                  </div>
                  <input type="text" class="form-control" name="email" placeholder="Email" [(ngModel)]="login.email"
                         #email="ngModel" [ngClass]="{ 'is-invalid': f.submitted && email.invalid }" email autocomplete="off"
                         (keydown.space)="$event.preventDefault()" required />
                  <div *ngIf="f.submitted && email.invalid" class="invalid-feedback">
                    <div *ngIf="email.errors.required" class="leftpadding">Email is required</div>
                    <div *ngIf="email.errors.email" class="leftpadding">Email must be a valid email address</div>
                  </div>
                </div>
              </div>
            </div>
            <br/>
            <div class="form-group row">
              <div class="col-md-12">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fa fa-key" aria-hidden="true"></i></span>
                  </div>
                  <input type="password" class="form-control" name="password" placeholder="Password"
                         [(ngModel)]="login.password" #password="ngModel"
                         [ngClass]="{ 'is-invalid': f.submitted && password.invalid }" autocomplete="off"
                         (keydown.space)="$event.preventDefault()" required />
                  <div *ngIf="f.submitted && password.invalid" class="invalid-feedback">
                    <div *ngIf="password.errors.required" class="leftpadding">Password is required</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-check pl-4">
                  <input type="checkbox" class="form-check-input" name="termsAndConditions" [(ngModel)]="login.termsAndConditions" [ngClass]="{ 'is-invalid': f.submitted && termsAndConditions.invalid }" #termsAndConditions="ngModel" required >
                  <label class="form-check-label text-dark" for="termsAndConditions">I agree to the <a class="link-color" (click)="showTermsAndConditions()">terms & conditions</a></label>
                  <div *ngIf="f.submitted && termsAndConditions.invalid" class="invalid-feedback">
                    <div *ngIf="termsAndConditions.errors.required" class="">Please read and accept the Terms & Conditions.</div>
                  </div>
                </div>
              </div>
            </div>
            <!--<div class="form-group row">
    <div class="col-md-12">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text"><i class="fa fa-id-card" aria-hidden="true"></i></span>
        </div>
        <input type="password" class="form-control" name="membershipNo" placeholder="Membership No." />-->
            <!--<div class="invalid-feedback">
    <div  class="leftpadding">Password is required</div>
  </div>-->
            <!--</div>
    </div>
  </div>-->
            <br />
            <div class="form-group row">
              <div class="col-md-12">
                <button type="submit" class="btn round-button red-grad-button buttonMargin">Login</button>
                <!--<button type="button" class="btn round-button red-grad-button mgl-20 buttonMargin" [routerLink]='["/"]'>Register</button>-->
                <span *ngIf="loading" class="rightalign">
                  <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                </span>
              </div>
              <div class="col-md-12 buttonMargin">
                <a [routerLink]='["/ForgetPassword"]' class="link-color">Forgot password?</a>
              </div>
              <div class="col-md-12">
                <p class="alert alert-danger" *ngIf="validationerror">
                  <span><small>{{validationerror}} <br /><kbd *ngIf="resendtext"><span><a (click)="ResendLink()">or Click here to resend email verification link</a></span></kbd></small></span>
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
