<h3>Occupancy</h3>
<div class="col-md-12" *ngIf="!t2a?.isLoading && t2a.noSearchSelect"><i class="color-grey float-right">{{messageService.NoSearchSelect}}</i></div>
<div class="col-md-12" *ngIf="!t2a?.isLoading && !t2a.noSearchSelect && t2a.validation"><i class="color-grey float-right">{{messageService.T2ARequiredMessage}}</i></div>
<div class="col-md-12" *ngIf="!t2a?.isLoading && !t2a.noSearchSelect && t2a.searchFail"><i class="color-grey float-right">{{messageService.SearchFail}}</i></div>
<div class="col-md-12" *ngIf="!t2a?.isLoading && !t2a.noSearchSelect && !t2a.validation && !t2a.searchFail && (!t2a.responseData || t2a?.responseData?.person_list?.length == 0)"><i class="color-grey float-right">{{messageService.NoRelevantDataMessage}}</i></div>
<div>
  <span class="loadingImg" *ngIf="t2a?.isLoading">
    <img src="{{messageService.loadingAnimation}}" />
  </span>
</div>
<div *ngIf="t2a?.responseData?.person_list">
  <div class="container">
    <div class="row border-bottom">
      <div class="col-md-4" *ngFor="let person of t2a?.responseData?.person_list">
        <div class="shadow-sm bg-white h-100">
          <div class="address-box-design border-bottom p-4 border border-primary shadow">
            <div class="pl-4">
              <p *ngIf="person?.name_single_line" class="mb-1"><b>Resident Name:</b> {{person?.name_single_line | titlecase}}</p>
              <p *ngIf="person?.addr_single_line" class="mb-1"><b>Address:</b> {{person?.addr_single_line | titlecase}}</p>
              <p *ngIf="person?.telephone_number" class="mb-1"><b>Telephone:</b> {{person?.telephone_number | titlecase}}</p>
              <p *ngIf="person?.mobile" class="mb-1"><b>Mobile:</b> {{person?.mobile | titlecase}}</p>
              <div *ngIf="person?.years_list && person?.years_list?.length > 0" class="mb-1">
                <div><b>Years Resident:</b> </div>
                <div *ngFor="let year of person?.years_list">
                  <div class="ml-4">{{year}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<br />
