<div class="app-outer-container" (scroll)="scrollTrigger($event)">
  <div class="container" id="content" #content>

    <!---------------Submit Search Form------------------------------->
    <form id="searchForm" name="form" (ngSubmit)="f.form.valid && SearchData()" #f="ngForm" novalidate>
      <div class="form-group row form-top-margin">
        <div class="col-md-9 form-data-background form-borderdesign  shadow-lg p-3 #F7F7F7">
          <div class="row padding-form-design Header-Font-Format pt-4">
            <div class="form-group col-md-6">
              <h2 class="color-white">You Tell Us</h2>
            </div>
          </div>
          <div class="row padding-form-design">
            <div class="col-md-8 z-index-formdata">
              <div class="row">
                <div class="input-group">
                  <div class="col-md-3">
                    <b>Reference:</b>
                  </div>
                  <div class="col-md-9">
                    <div class="form-group">
                      <input type="text" placeholder="" class="form-control" [(ngModel)]="contactData.referenceNumber" name="reference" autocomplete="off" (keypress)="referenceKeyPress($event)" (input)="onReferenceChange()" #reference="ngModel" />
                      <span class="redColor" *ngIf="referenceError"><small>{{referenceRequiredMessage}}</small></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row padding-form-design">
            <div class="col-md-8 z-index-formdata">
              <div class="row">
                <div class="input-group">
                  <div class="col-md-3">
                    <b>Name<!--<span class="color-red">*</span>-->:</b>
                  </div>
                  <div class="col-md-9">
                    <div class="form-group">
                      <input [disabled]="dataloading || lifeStyledataloading || socialMediadataloading"
                             type="text" class="form-control btn-default" [(ngModel)]="searchtext" name="searchtext" placeholder=""
                             (ngModelChange)="onChange()" autocomplete="off" (input)="onNameChange($event.target.value)" #name="ngModel" />
                      <span class="redColor" *ngIf="validationerror"><small>{{nameRequiredMessage}}</small></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="input-group">
                  <div class="col-md-3">
                    <b>Post Code:</b>
                  </div>
                  <div class="col-md-9">
                    <div class="form-group">
                      <input type="text" placeholder="" class="form-control" [(ngModel)]="contactData.postalCode"
                             name="postalCode" autocomplete="off" #postalCode="ngModel"
                             pattern="([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})" />
                      <div *ngIf="postalCode.errors?.pattern && contactData.postalCode" class="redColor">
                        <small>{{postCodeNotValidMessage}}</small>
                      </div>
                      <div *ngIf="postvalidationmessage" class="redColor">
                        <small>{{postCodeRequiredMessage}}</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="input-group">
                  <div class="col-md-3">
                    <b>Mobile:</b>
                  </div>
                  <div class="col-md-9">
                    <div class="form-group">
                      <input type="text" class="form-control" [(ngModel)]="phonenumber" (input)="onPhoneChange($event.target.value)"
                             name="phone" #phone="ngModel" pattern="^((\+?[0]{0,2}44)|(0))?[0-9]{10}$" autocomplete="off" placeholder="" />
                      <div *ngIf="phone.errors?.pattern || isInvalidMobileNumber" class="redColor">
                        <small>{{mobileNotValidMessage}}</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="input-group">
                  <div class="col-md-3">
                    <b>Email:</b>
                  </div>
                  <div class="col-md-9">
                    <div class="form-group">
                      <input type="email" placeholder="" class="form-control" [(ngModel)]="contactData.email" name="email" autocomplete="off" #email="ngModel" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" />
                      <div *ngIf="email.errors?.pattern" class="redColor">
                        {{emailNotValidMessage}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="input-group">
                  <div class="col-md-3">
                    <b>VRN:</b>
                  </div>
                  <div class="col-md-9">
                    <div class="form-group">
                      <input type="text" placeholder="" class="form-control" [(ngModel)]="contactData.vRN" #vrn="ngModel" name="vRN" autocomplete="off"
                             (input)="onVRNChange($event.target.value)" />
                      <div *ngIf="!isValidVRN && contactData.vRN" class="redColor">
                        <small>{{ vrnNotValidMessage }}</small>
                      </div>
                      <div *ngIf="vrnvalidationmessage" class="redColor">
                        <small>{{ vrnRequiredMessage }}</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="input-group">
                  <div class="col-md-3">
                    <b>DOB:</b>
                  </div>
                  <div class="col-md-9">
                    <div class="form-group">
                      <input type="date" placeholder="" class="form-control datepicker" [(ngModel)]="contactData.dateOfBirth" name="dateOfBirth" #dob="ngModel" autocomplete="off" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <img class="light-direction-align" src="assets/Images/icons/Lightdirection.svg" />
            </div>
          </div>
          <div class="row padding-form-design addition-data-alignment">
            <div class="col-md-2"></div>
            <div class="col-md-10">
              <div class="row">
                <div id="accordion1" class="accordion col-md-12">
                  <div class="form-group">
                    <div class="card mb-12">
                      <div class="card-header search-card" data-toggle="collapse" href="#collapseUserData" id="headingOne1" aria-expanded="false" (click)="ShowUserDataDisplay()">
                        <span class="collapse-user-details">{{collapseUserDetails}}</span>
                        <span class="collapase-user-result"><i class="fa {{collapseUserResultIcon}}" aria-hidden="true"></i></span>
                      </div>
                      <div id="collapseUserData" class="card-body collapse border-bottom" aria-labelledby="headingOne" data-parent="#accordion1">
                        <div class="row">
                          <div class="input-group">
                            <div class="col-md-3">
                              House No/Name:
                            </div>
                            <div class="col-md-9">
                              <div class="form-group">
                                <!--<textarea name="address" rows="3" class="form-control" [(ngModel)]="contactData.address" autocomplete="off"></textarea>-->
                                <input type="text" class="form-control" [(ngModel)]="contactData.addressLine1" name="addressLine1" autocomplete="off" placeholder="" #houseNo="ngModel" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="input-group">
                            <div class="col-md-3">
                              Street:
                            </div>
                            <div class="col-md-9">
                              <div class="form-group">
                                <input type="text" class="form-control" [(ngModel)]="contactData.addressLine2" name="addressLine1" autocomplete="off" placeholder="" #street="ngModel" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="input-group">
                            <div class="col-md-3">
                              City:
                            </div>
                            <div class="col-md-9">
                              <div class="form-group">
                                <input type="text" class="form-control" [(ngModel)]="contactData.city" name="city" autocomplete="off" #city="ngModel" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="input-group">
                            <div class="col-md-3">
                              County:
                            </div>
                            <div class="col-md-9">
                              <div class="form-group">
                                <input type="text" class="form-control" [(ngModel)]="contactData.state" name="state" autocomplete="off" #county="ngModel" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="input-group">
                            <div class="col-md-3">
                              Country:
                            </div>
                            <div class="col-md-9">
                              <div class="form-group">
                                <input type="text" class="form-control" [(ngModel)]="contactData.country" name="country" autocomplete="off" #country="ngModel" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="input-group">
                            <div class="col-md-3">
                              Company:
                            </div>
                            <div class="col-md-9">
                              <div class="form-group">
                                <input type="text" placeholder="" class="form-control" [(ngModel)]="contactData.companyName" name="companyName" autocomplete="off" #company="ngModel" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="input-group">
                            <div class="col-md-12">
                              Associated Entities:
                            </div>
                            <div class="col-md-3"></div>
                            <div class="col-md-9">
                              <div class="form-group">
                                <div class="row">
                                  <div class="col-md-6" *ngFor="let user of associatedSearchUsers; let i = index">
                                    <div class="form-group">
                                      <input [(ngModel)]="user.name" class="form-control" type="text" placeholder="" (change)="associatedEntries()" name="associatedEntries" #associatedEntities="ngModel">
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row padding-form-design pb-2">
            <div class="col-md-2"></div>
            <div class="col-md-10">
              <div class="row">
                <div class="input-group">
                  <div class="col-md-12">
                    <div class="form-group">
                      <button [disabled]="postalCode.errors?.pattern || phone.errors?.pattern || email.errors?.pattern || dataloading || lifeStyledataloading || socialMediadataloading || avaloading || directorshiploading || contactloading || insurerloading || financialloading || advertloading || associationloading || emailloading" type="submit" class="btn colorRed float-left mx-2">
                        Search <i class="fa fa-search" aria-hidden="true"></i>
                      </button>
                      <button [disabled]="dataloading || lifeStyledataloading || socialMediadataloading || avaloading || directorshiploading || contactloading || insurerloading || financialloading || advertloading || associationloading || emailloading" type="button" *ngIf="followDisplay" (click)="Follow()"
                              class="btn colorRed buttonspacing  mx-2">
                        <span *ngIf="followStatus">
                          <i class="fa fa-check"
                             aria-hidden="true"></i>
                        </span> {{follow}}
                      </button>
                      <button type="button" [disabled]="dataloading || lifeStyledataloading || socialMediadataloading || avaloading || directorshiploading || contactloading || insurerloading || financialloading || advertloading || associationloading || emailloading" class="btn colorRed btn-pdf mx-2" (click)="downloadHTML2PDF()" *ngIf="saveAsPDFButtonVisibility">
                        Save as PDF
                      </button>
                      <button *ngIf="visibleResetBtn || reference.value || name.value || postalCode.value || phone.value || email.value || vrn.value || houseNo.value || street.value || city.value || county.value || country.value || dob.value || company.value" [disabled]="dataloading || lifeStyledataloading || socialMediadataloading || avaloading || directorshiploading || contactloading || insurerloading || financialloading || advertloading || associationloading || emailloading" class="btn colorRed float-right reset-btn mx-2" (click)="resetForm()">Clear Search</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row padding-form-design pb-2" *ngIf="licenceError">
            <div class="col-md-2"></div>
            <div class="col-md-8">
              <div class="alert alert-danger pt-4" role="alert">
                <h6>{{licenceError}}</h6>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-md-offset-2 form-borderdesign result-form-background shadow-lg p-3 #F7F7F7">
          <ul class="ul-toggle">
            <li class="Header-Font-Format pt-4"><h2>We Tell You</h2></li>

            <li class="result-animation" [ngClass]="searchtext ? 'result-get-list-font-design': 'checkmark-default-design'">
              <label class="switch">
                <input type="checkbox" name="isHUTDatabaseAccess" [(ngModel)]="accessData.isHUTDatabaseAccess" [disabled]="!accessData.isHUTDatabaseToggle" (change)="validateBasedOnToggle()">
                <span class="slider round"></span>
              </label>
              <img src="assets/Images/icons/HUTDatabase.svg" class="icon-size" />
              <span>HUT Database</span>
            </li>
            <li class="result-animation" [ngClass]="phonenumber ? 'result-get-list-font-design': 'checkmark-default-design'">
              <label class="switch">
                <input type="checkbox" name="isMobileAccess" [(ngModel)]="accessData.isMobileAccess" [disabled]="!accessData.isMobileToggle" (change)="validateBasedOnToggle()">
                <span class="slider round"></span>
              </label>
              <img src="assets/Images/icons/Mobile.svg" class="icon-size" />
              <span>Mobile</span>
            </li>
            <li class="result-animation" [ngClass]="contactData.email ? 'result-get-list-font-design': 'checkmark-default-design'">
              <label class="switch">
                <input type="checkbox" name="isEmailAccess" [(ngModel)]="accessData.isEmailAccess" [disabled]="!accessData.isEmailToggle" (change)="validateBasedOnToggle()">
                <span class="slider round"></span>
              </label>
              <img src="assets/Images/icons/Email.svg" class="icon-size" />
              <span>Email</span>
            </li>
            <li class="result-animation" [ngClass]="searchtext && (contactData.postalCode || phonenumber) ? 'result-get-list-font-design': 'checkmark-default-design'">
              <label class="switch">
                <input type="checkbox" name="isIdentityAccess" [(ngModel)]="accessData.isIdentityAccess" [disabled]="!accessData.isIdentityToggle" (change)="validateBasedOnToggle()">
                <span class="slider round"></span>
              </label>
              <img src="assets/Images/icons/Identity.svg" class="icon-size" />
              <span>Identity</span>
            </li>
            <li class="result-animation" [ngClass]="searchtext && contactData.postalCode  ? 'result-get-list-font-design': 'checkmark-default-design'">
              <label class="switch">
                <input type="checkbox" name="isFinancialAccess" [(ngModel)]="accessData.isFinancialAccess" [disabled]="!accessData.isFinancialToggle" (change)="validateBasedOnToggle()">
                <span class="slider round"></span>
              </label>
              <img src="assets/Images/icons/Financial.svg" class="icon-size" />
              <span>Financial</span>
            </li>
            <li class="result-animation" [ngClass]="contactData.postalCode  && searchtext ? 'result-get-list-font-design': 'checkmark-default-design'">
              <label class="switch">
                <input type="checkbox" name="isAddressAccess" [(ngModel)]="accessData.isAddressAccess" [disabled]="!accessData.isAddressToggle" (change)="validateBasedOnToggle()">
                <span class="slider round"></span>
              </label>
              <img src="assets/Images/icons/Address.svg" class="icon-size" />
              <span>Address</span>
              <div id="addressCanvas"></div>
            </li>
            <li class="result-animation" [ngClass]="searchtext ? 'result-get-list-font-design': 'checkmark-default-design'">
              <label class="switch">
                <input type="checkbox" name="isCriminalityAccess" [(ngModel)]="accessData.isCriminalityAccess" [disabled]="!accessData.isCriminalityToggle" (change)="validateBasedOnToggle()">
                <span class="slider round"></span>
              </label>
              <img src="assets/Images/icons/Criminality.svg" class="icon-size" />
              <span>Criminality</span>
            </li>
            <li class="result-animation" [ngClass]="searchtext ? 'result-get-list-font-design': 'checkmark-default-design'">
              <label class="switch">
                <input type="checkbox" name="isSocialMediaAccess" [(ngModel)]="accessData.isSocialMediaAccess" [disabled]="!accessData.isSocialMediaToggle" (change)="validateBasedOnToggle()">
                <span class="slider round"></span>
              </label>
              <img src="assets/Images/icons/Social.svg" class="icon-size" />
              <span>Social Media</span>
            </li>
            <li class="result-animation" [ngClass]="searchtext && (contactData.postalCode || contactData.dateOfBirth || contactData.email || phonenumber || contactData.vRN || contactData.companyName) ? 'result-get-list-font-design': 'checkmark-default-design'">
              <label class="switch">
                <input type="checkbox" name="isCounterFraudAccess" [(ngModel)]="accessData.isCounterFraudAccess" [disabled]="!accessData.isCounterFraudToggle" (change)="validateBasedOnToggle()">
                <span class="slider round"></span>
              </label>
              <img src="assets/Images/icons/Fraud.svg" class="icon-size" />
              <span>Counter Fraud</span>
            </li>
            <li class="result-animation" [ngClass]="searchtext ? 'result-get-list-font-design': 'checkmark-default-design'">
              <label class="switch">
                <input type="checkbox" name="isSportsAccess" [(ngModel)]="accessData.isSportsAccess" [disabled]="!accessData.isSportsToggle" (change)="validateBasedOnToggle()">
                <span class="slider round"></span>
              </label>
              <img src="assets/Images/icons/Sports.svg" class="icon-size" />
              <span>Sports</span>
            </li>
            <li class="result-animation" [ngClass]="phonenumber ? 'result-get-list-font-design': 'checkmark-default-design'">
              <label class="switch">
                <input type="checkbox" name="isAdvertsAccess" [(ngModel)]="accessData.isAdvertsAccess" [disabled]="!accessData.isAdvertsToggle" (change)="validateBasedOnToggle()">
                <span class="slider round"></span>
              </label>
              <img src="assets/Images/icons/Adverts.svg" class="icon-size" />
              <span>Adverts</span>
            </li>
            <li class="result-animation" [ngClass]="searchtext ? 'result-get-list-font-design': 'checkmark-default-design'">
              <label class="switch">
                <input type="checkbox" name="isCompaniesHouseAccess" [(ngModel)]="accessData.isCompaniesHouseAccess" [disabled]="!accessData.isCompaniesHouseToggle" (change)="validateBasedOnToggle()">
                <span class="slider round"></span>
              </label>
              <img src="assets/Images/icons/CompaniesHouse.svg" class="icon-size" />
              <span>Companies House</span>
            </li>
            <li class="result-animation" [ngClass]="contactData.vRN ? 'result-get-list-font-design': 'checkmark-default-design'">
              <label class="switch">
                <input type="checkbox" name="isAVAAccess" [(ngModel)]="accessData.isAVAAccess" [disabled]="!accessData.isAVAToggle" (change)="validateBasedOnToggle()">
                <span class="slider round"></span>
              </label>
              <img src="assets/Images/icons/AVA.svg" class="icon-size" />
              <span>AVA</span>
            </li>
            <li class="result-animation" [ngClass]="searchtext ? 'result-get-list-font-design': 'checkmark-default-design'">
              <label class="switch">
                <input type="checkbox" name="isAMLAccess" [(ngModel)]="accessData.isAMLAccess" [disabled]="!accessData.isAMLToggle" (change)="validateBasedOnToggle()">
                <span class="slider round"></span>
              </label>
              <img src="assets/Images/icons/Financial.svg" class="icon-size" />
              <span>AML</span>
            </li>
            <li class="result-animation" [ngClass]="searchtext && (contactData.dateOfBirth || (contactData.postalCode && contactData.addressLine1)) ? 'result-get-list-font-design': 'checkmark-default-design'">
              <label class="switch">
                <input type="checkbox" name="isCCJDetailAccess" [(ngModel)]="accessData.isCCJDetailAccess" [disabled]="!accessData.isCCJDetailToggle" (change)="validateBasedOnToggle()">
                <span class="slider round"></span>
              </label>
              <img src="assets/Images/icons/Financial.svg" class="icon-size" />
              <span>CCJ Detail</span>
            </li>
            <li class="result-animation" [ngClass]="searchtext && contactData.dateOfBirth ? 'result-get-list-font-design': 'checkmark-default-design'">
              <label class="switch">
                <input type="checkbox" name="isInsolvencyDetailAccess" [(ngModel)]="accessData.isInsolvencyDetailAccess" [disabled]="!accessData.isInsolvencyDetailToggle" (change)="validateBasedOnToggle()">
                <span class="slider round"></span>
              </label>
              <img src="assets/Images/icons/Financial.svg" class="icon-size" />
              <span>Insolvency Detail</span>
            </li>
            <li class="result-animation" [ngClass]="searchtext && contactData.email ? 'result-get-list-font-design': 'checkmark-default-design'">
              <span>
                <label class="switch" [pTooltip]="(!accessData.isRTWToggle && !accessData.isRTWAccess) ? RTWTooltipContent : ''" tooltipPosition="top">
                  <input type="checkbox" name="isRTWAccess" [(ngModel)]="accessData.isRTWAccess" [disabled]="!accessData.isRTWToggle" (change)="validateBasedOnToggle()">
                  <span class="slider round"></span>
                </label>
                <img src="assets/Images/icons/Identity.svg" class="icon-size" />
                <span>RTW</span>
              </span>
              <span class="pl-1">
                <label class="switch">
                  <input type="checkbox" name="isRTWNoFormsAccess" [(ngModel)]="accessData.isRTWNoFormsAccess" [disabled]="!accessData.isRTWAccess" (change)="validateBasedOnToggle()">
                  <span class="slider round"></span>
                </label>
                <span class="pl-1">NoForms</span>
              </span>
            </li>
            <li class="result-animation" [ngClass]="searchtext && contactData.email ? 'result-get-list-font-design': 'checkmark-default-design'">
              <label class="switch" [pTooltip]="(!accessData.isIDCheckToggle && !accessData.isIDCheckAccess) ? IDCheckTooltipContent : ''" tooltipPosition="top">
                <input type="checkbox" name="isIDCheckAccess" [(ngModel)]="accessData.isIDCheckAccess" [disabled]="!accessData.isIDCheckToggle" (change)="validateBasedOnToggle()">
                <span class="slider round"></span>
              </label>
              <img src="assets/Images/icons/Identity.svg" class="icon-size" />
              <span>ID Check</span>
            </li>
          </ul>
        </div>
      </div>
      <!-- Display Data -->

      <div class="row mb-3 print-contact-detail" id="contact-details">
        <div class="card col-md-5" *ngIf="isShowContactDetail">
          <div class="card-body">
            <span class="card-title Header-Font-Format"><b>Mobile Number Enquiry</b></span>
            <span class="loadingImg" *ngIf="contactloading">
              <img src="{{loadingAnimation}}" />
            </span>
            <div>
              <div class="col-md-12" *ngIf="noRelevantDataMobile"><i class="color-grey float-right">{{NoRelevantDataMessageShort}}</i></div>
              <div class="col-md-12" *ngIf="!tempAccessData.isMobileAccess"><i class="color-grey float-right">{{NoSearchSelectShort}}</i></div>
              <div class="col-md-12" *ngIf="mobileSearchFail && tempAccessData.isMobileAccess && !contactloading"><i class="color-grey float-right">{{SearchFail}}</i></div>
              <div class="col-md-12" *ngIf="mobilevalidationerror && tempAccessData.isMobileAccess"><i class="color-grey float-right">Mobile number is required.</i></div>
            </div>
            <div class="row" *ngIf="!contactloading && tempAccessData.isMobileAccess && !mobilevalidationerror && !mobileSearchFail">
              <div class="col-md-6">
                <p>Phone Type: <span>{{userContactData?.data?.Type | titlecase}}</span> </p>
                <p>Carrier: <span>{{userContactData?.data?.Carrier}}</span> </p>
                <b>Sim Swap Details</b>
                <div *ngIf="userContactData?.data?.SimSwap">
                  <p *ngIf="userContactData?.data?.SimSwap?.Status == 'red'"><i class="fa fa-exclamation-triangle color-red" aria-hidden="true">&nbsp;High risk</i></p>
                  <p *ngIf="userContactData?.data?.SimSwap?.Status == 'amber'"><i class="fa fa-exclamation-triangle color-amber" aria-hidden="true">&nbsp;Medium risk</i></p>
                  <p *ngIf="userContactData?.data?.SimSwap?.Status == 'green'"><i class="fa fa-exclamation-triangle color-green" aria-hidden="true">&nbsp;Low risk</i></p>
                  <p class="info-text-sim-swap" *ngIf="userContactData?.data?.SimSwap?.Date"><small>Swap Date: <br /><span>{{userContactData?.data?.SimSwap?.Date | date:'dd-MM-yyyy hh:mm:ss'}}</span> </small></p>
                  <!--<p class="info-text-sim-swap" *ngIf="userContactData?.PhoneContactData?.PhoneContactJSON?.sim_swap?.swap_time"><small>Swap Time: <span>{{userContactData?.PhoneContactData?.PhoneContactJSON?.sim_swap?.swap_time}}</span> </small></p>-->
                </div>
                <div *ngIf="!userContactData?.data?.SimSwap">
                  <div class="col-md-12"><i class="color-grey float-right">{{NoRelevantDataMessageShort}}</i></div>
                </div>
              </div>
              <div class="col-md-6">
                <p *ngIf="userContactData?.data?.Blocked"><i class="fa fa-ban color-red"></i> Blocked </p>
                <span><b>Name Match</b> </span>
                <p *ngIf="userContactData?.data?.MatchStatus == null"><i class="color-grey float-right">{{NoRelevantDataMessageShort}}</i></p>
                <p *ngIf="userContactData?.data?.MatchStatus">
                  <i class="fa fa-check-circle color-green"></i> Matched
                  <br /> <span *ngIf="userContactData?.data?.RegisteredUser">
                    <small>
                      Subscriber: {{userContactData?.data?.RegisteredUser | titlecase}}
                    </small>
                  </span>
                </p>
                <p *ngIf="!userContactData?.data?.MatchStatus && userContactData?.data?.MatchStatus != null">
                  <i class="fa fa-times-circle color-red"></i>
                  NOT Matched <br />
                  <span *ngIf="userContactData?.data?.RegisteredUser">
                    <small>
                      Subscriber: {{userContactData?.data?.RegisteredUser | titlecase}}
                    </small>
                  </span>
                </p>

                <b>Number Deactivation Details</b>
                <div *ngIf="userContactData?.PhoneContactData?.PhoneContactJSON?.number_deactivation?.status.code == 2800">
                  <p class="info-text-sim-swap" *ngIf="userContactData?.PhoneContactData?.PhoneContactJSON?.number_deactivation?.last_deactivated">Last Deactivated: <span>{{userContactData?.PhoneContactData?.PhoneContactJSON?.number_deactivation?.last_deactivated | date: 'dd-MM-yyyy' }}</span></p>
                  <p class="info-text-sim-swap" *ngIf="userContactData?.PhoneContactData?.PhoneContactJSON?.number_deactivation?.tracking_since">Tracking Since: <span>{{userContactData?.PhoneContactData?.PhoneContactJSON?.number_deactivation?.tracking_since | date: 'dd-MM-yyyy'}}</span></p>
                </div>
                <div *ngIf="userContactData?.PhoneContactData?.PhoneContactJSON?.number_deactivation?.status.code != 2800 || !userContactData?.PhoneContactData?.PhoneContactJSON?.number_deactivation">
                  <div class="col-md-12"><i class="color-grey float-right">{{NoRelevantDataMessageShort}}</i></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card col-md-3" *ngIf="isShowInsurerFraudDetail">
          <div class="card-body">
            <span class="card-title Header-Font-Format"><b>Counter Fraud</b></span>
            <div class="col-md-12" *ngIf="!tempAccessData.isCounterFraudAccess"><i class="color-grey float-right">{{NoSearchSelectShort}}</i></div>
            <div class="col-md-12" *ngIf="tempAccessData.isCounterFraudAccess && counterfraudValidationMessage"><i class="color-grey float-right">{{OtherFieldRequired}}</i></div>
            <div class="col-md-12" *ngIf="tempAccessData.isCounterFraudAccess && noRelevantDataCounterFraud && !insurerloading"><i class="color-grey float-right">{{NoRelevantDataMessageShort}}</i></div>
            <div class="col-md-12" *ngIf="tempAccessData.isCounterFraudAccess && counterfraudSearchFail && !insurerloading"><i class="color-grey float-right">{{SearchFail}}</i></div>
            <span class="loadingImg" *ngIf="insurerloading && tempAccessData.isCounterFraudAccess">
              <img src="{{loadingAnimation}}" />
            </span>
            <div class="row" *ngIf="!insurerloading && tempAccessData.isCounterFraudAccess && !counterfraudSearchFail && !noRelevantDataCounterFraud && !counterfraudValidationMessage">
              <rg-gauge-chart class="align-gauge-chart" [canvasWidth]="canvasWidth"
                              [needleValue]="needleValue"
                              [centralLabel]="centralLabel"
                              [options]="options"
                              [name]=""
                              [bottomLabel]="bottomLabel"></rg-gauge-chart>
              <!--<span *ngIf="counterFraudData?.ProspectData?.ProspectJSON?.ifbStatus">IFB Status: <span style="border:1px solid black;"> {{counterFraudData?.ProspectData?.ProspectJSON?.ifbStatus}}</span></span>-->
            </div>
          </div>
        </div>
        <div class="card col-md-2" *ngIf="isShowInsurerFraudDetail">
          <div class="card-body" *ngIf="false">
            <!-- this is hidden for short term -->
            <span class="card-title Header-Font-Format"><b>IFB Status</b></span>
            <div class="col-md-12" *ngIf="!tempAccessData.isCounterFraudAccess"><i class="color-grey float-right">{{NoSearchSelectShort}}</i></div>
            <div class="col-md-12" *ngIf="tempAccessData.isCounterFraudAccess && counterfraudValidationMessage"><i class="color-grey float-right">Required properties are missing.</i></div>
            <div class="col-md-12" *ngIf="counterfraudSearchFail && !insurerloading"><i class="color-grey float-right">{{SearchFail}}</i></div>
            <div class="col-md-12" *ngIf="noRelevantDataCounterFraud && !insurerloading"><i class="color-grey float-right">{{NoRelevantDataMessageShort}}</i></div>
            <span class="loadingImg" *ngIf="insurerloading && tempAccessData.isCounterFraudAccess">
              <img src="{{loadingAnimation}}" />
            </span>
            <div class="row" *ngIf="!insurerloading && tempAccessData.isCounterFraudAccess && !noRelevantDataCounterFraud">
              <span *ngIf="counterFraudData?.ProspectData?.ProspectJSON">Status: <span [ngClass]="counterFraudData?.ProspectData?.ProspectJSON?.ifbStatus ? 'status-Black':'status-Green'"> {{counterFraudData?.ProspectData?.ProspectJSON?.ifbStatus?counterFraudData?.ProspectData?.ProspectJSON?.ifbStatus:'Green'}}</span></span>
              <span class="mt-3" *ngIf="counterFraudData?.ProspectData?.ProspectJSON?.ifbRef">Reference: <i class="fa fa-copy ifbReferenceTooltip" [tooltip]="counterFraudData.ProspectData.ProspectJSON.ifbRef" placement="bottom" (click)="copyIFBReference(counterFraudData.ProspectData.ProspectJSON.ifbRef)"></i></span>
            </div>
          </div>
          <!-- Email Check -->
          <div class="card-body" *ngIf="isShowEmail">
            <span class="card-title Header-Font-Format"><b>Email Check</b></span>
            <div class="d-block">
              <div *ngIf="!noErrorEmail && !emailloading">
                <div class="col-md-12" *ngIf="tempAccessData.isEmailAccess && emailValidationMessage"><i class="color-grey float-right">Email is required.</i></div>
                <div class="col-md-12" *ngIf="!tempAccessData.isEmailAccess"><i class="color-grey float-right">{{NoSearchSelectShort}}</i></div>
                <div class="col-md-12" *ngIf="tempAccessData.isEmailAccess && emailSearchFail"><i class="color-grey float-right">{{SearchFail}}</i></div>
                <div class="col-md-12" *ngIf="tempAccessData.isEmailAccess && noRelevantDataEmail"><i class="color-grey float-right">{{NoRelevantDataMessageShort}}</i></div>
              </div>
              <div class="loadingImg" *ngIf="emailloading && tempAccessData.isEmailAccess">
                <img src="{{loadingAnimation}}" />
              </div>
              <div *ngIf="noErrorEmail && !emailSearchFail">
                <p *ngIf="emailData?.data === true"><i class="fa fa-ban color-red"></i> Blocked </p>
                <p *ngIf="emailData?.data === false"><i class="fa fa-check-circle color-green"></i> Not Blocked </p>
              </div>
            </div>
            <div class="d-block">
              <div *ngIf="!rtwloading">
                <div class="col-md-12" *ngIf="!tempAccessData.isEmailAccess"><i class="color-grey float-right">{{NoSearchSelectShort}}</i></div>
                <div class="col-md-12" *ngIf="tempAccessData.isEmailAccess && emailageErrorMessage"><i class="color-grey float-right">{{emailageErrorMessage}}</i></div>
                <div class="col-md-12" *ngIf="tempAccessData.isEmailAccess && emailageSearchFail"><i class="color-grey float-right">{{SearchFail}}</i></div>
              </div>
              <div class="loadingImg" *ngIf="emailageloading && tempAccessData.isEmailAccess">
                <img src="{{loadingAnimation}}" />
              </div>
              <div *ngIf="!emailageloading && !emailageSearchFail && emailageResult">
                <p *ngIf="emailageResult?.isValidFirstSeenDays"><i class="fa fa-check-circle color-green"></i> Not New</p>
                <p *ngIf="!emailageResult?.isValidFirstSeenDays"><i class="fa fa-ban color-red"></i> New Email</p>
              </div>
            </div>
          </div>
          <!-- RTW Check -->
          <div class="card-body" *ngIf="isShowRTW">
            <span class="card-title Header-Font-Format"><b>RTW Check</b></span>
            <div *ngIf="!rtwloading">
              <div class="col-md-12" *ngIf="!tempAccessData.isRTWAccess"><i class="color-grey float-right">{{NoSearchSelectShort}}</i></div>
              <div class="col-md-12" *ngIf="tempAccessData.isRTWAccess && rtwErrorMessage"><i class="color-grey float-right">{{rtwErrorMessage}}</i></div>
              <div class="col-md-12" *ngIf="tempAccessData.isRTWAccess && rtwSearchFail"><i class="color-grey float-right">{{SearchFail}}</i></div>
            </div>
            <span class="loadingImg" *ngIf="rtwloading && tempAccessData.isRTWAccess">
              <img src="{{loadingAnimation}}" />
            </span>
            <div *ngIf="!rtwloading && !rtwSearchFail && rtwResult">
              <p *ngIf="rtwResult?.data"><i class="fa fa-check-circle color-green"></i> Checked</p>
              <p *ngIf="!rtwResult?.data"><i class="fa fa-ban color-red"></i> Not Checked</p>
            </div>
          </div>
          <!-- ID Check -->
          <div class="card-body" *ngIf="isShowIDCheck">
            <span class="card-title Header-Font-Format"><b>ID Check</b></span>
            <div *ngIf="!rtwloading">
              <div class="col-md-12" *ngIf="!tempAccessData.isIDCheckAccess"><i class="color-grey float-right">{{NoSearchSelectShort}}</i></div>
              <div class="col-md-12" *ngIf="tempAccessData.isIDCheckAccess && idCheckErrorMessage"><i class="color-grey float-right">{{idCheckErrorMessage}}</i></div>
              <div class="col-md-12" *ngIf="tempAccessData.isIDCheckAccess && idCheckSearchFail"><i class="color-grey float-right">{{SearchFail}}</i></div>
            </div>
            <span class="loadingImg" *ngIf="idcheckloading && tempAccessData.isIDCheckAccess">
              <img src="{{loadingAnimation}}" />
            </span>
            <div *ngIf="!idcheckloading && !idCheckSearchFail && idCheckResult">
              <p *ngIf="idCheckResult?.data"><i class="fa fa-check-circle color-green"></i> Checked</p>
              <p *ngIf="!idCheckResult?.data"><i class="fa fa-ban color-red"></i> Not Checked</p>
            </div>
          </div>
        </div>
        <div class="card col-md-2" *ngIf="isShowInsurerFraudDetail">
          <div class="card-body">
            <span class="card-title Header-Font-Format"><b>Mortality</b></span>
            <div class="row" *ngIf="!financialloading && tempAccessData.isFinancialAccess">
              <span *ngIf="financialData?.data?.MortalityFlag != null">Status: <span [ngClass]="financialData?.data?.MortalityFlag ? 'status-Red':'status-Green'"> {{financialData?.data?.MortalityFlag ?'Red':'Green'}}</span></span>
            </div>
            <div class="col-md-12" *ngIf="!financialloading && tempAccessData.isFinancialAccess && !financialSearchFail && (noRelevantDataFinancial || financialData?.data?.MortalityFlag == null) && !financialVaidation"><i class="color-grey float-right">{{NoRelevantDataMessageShort}}</i></div>
            <div class="col-md-12" *ngIf="!financialloading && !tempAccessData.isFinancialAccess && !financialVaidation"><i class="color-grey float-right">{{NoSearchSelectShort}}</i></div>
            <div class="col-md-12" *ngIf="!financialloading && tempAccessData.isFinancialAccess && financialSearchFail && !financialVaidation"><i class="color-grey float-right">{{SearchFail}}</i></div>
            <div class="col-md-12" *ngIf="financialVaidation && !financialloading"><i class="color-grey float-right">{{postCodeRequiredMessage}}</i></div>
            <span class="loadingImg" *ngIf="financialloading && tempAccessData.isFinancialAccess">
              <img src="{{loadingAnimation}}" />
            </span>
          </div>
          <div class="card-body">
            <span class="card-title Header-Font-Format"><b>CCJ</b></span>
            <div class="row" *ngIf="!financialloading && tempAccessData.isFinancialAccess">
              <span *ngIf="financialData?.data?.CcjFlag != null">Status: <span [ngClass]="financialData?.data?.CcjFlag ? 'status-Red':'status-Green'"> {{financialData?.data?.CcjFlag ?'Red':'Green'}}</span></span>
            </div>
            <div class="col-md-12" *ngIf="!financialloading && tempAccessData.isFinancialAccess && !financialSearchFail && (noRelevantDataFinancial || financialData?.data?.CcjFlag == null) && !financialVaidation"><i class="color-grey float-right">{{NoRelevantDataMessageShort}}</i></div>
            <div class="col-md-12" *ngIf="!financialloading && !tempAccessData.isFinancialAccess && !financialVaidation"><i class="color-grey float-right">{{NoSearchSelectShort}}</i></div>
            <div class="col-md-12" *ngIf="!financialloading && tempAccessData.isFinancialAccess && financialSearchFail && !financialVaidation"><i class="color-grey float-right">{{SearchFail}}</i></div>
            <div class="col-md-12" *ngIf="financialVaidation && !financialloading"><i class="color-grey float-right">{{postCodeRequiredMessage}}</i></div>
            <span class="loadingImg" *ngIf="financialloading && tempAccessData.isFinancialAccess">
              <img src="{{loadingAnimation}}" />
            </span>
          </div>
          <div class="card-body">
            <span class="card-title Header-Font-Format"><b>Insolvency</b></span>
            <div class="row" *ngIf="!financialloading && tempAccessData.isFinancialAccess">
              <span *ngIf="financialData?.data?.InsolvencyFlag != null">Status: <span [ngClass]="financialData?.data?.InsolvencyFlag ? 'status-Red':'status-Green'"> {{financialData?.data?.InsolvencyFlag ?'Red':'Green'}}</span></span>
            </div>
            <div class="col-md-12" *ngIf="!financialloading && tempAccessData.isFinancialAccess && !financialSearchFail && (noRelevantDataFinancial || financialData?.data?.InsolvencyFlag == null) && !financialVaidation"><i class="color-grey float-right">{{NoRelevantDataMessageShort}}</i></div>
            <div class="col-md-12" *ngIf="!financialloading && !tempAccessData.isFinancialAccess && !financialVaidation"><i class="color-grey float-right">{{NoSearchSelectShort}}</i></div>
            <div class="col-md-12" *ngIf="!financialloading && tempAccessData.isFinancialAccess && financialSearchFail && !financialVaidation"><i class="color-grey float-right">{{SearchFail}}</i></div>
            <div class="col-md-12" *ngIf="financialVaidation && !financialloading"><i class="color-grey float-right">{{postCodeRequiredMessage}}</i></div>
            <span class="loadingImg" *ngIf="financialloading && tempAccessData.isFinancialAccess">
              <img src="{{loadingAnimation}}" />
            </span>
          </div>

        </div>
        <!--<div class="card col-md-3" *ngIf="isShowDirectorshipDetail">
          <div class="card-body">
            <span class="card-title Header-Font-Format"><b>Directorship Summary</b></span>
            <span class="loadingImg" *ngIf="directorshiploading">
              <img src="{{loadingAnimation}}" />
            </span>
          </div>
        </div>-->

      </div>
    </form>



    <!---------------Server TimeOut Error----------------->
    <div class="row" *ngIf="serverError">
      <div class="col-md-2"></div>
      <div class="col-md-8">
        <div #alert class="alert alert-danger alert-dismissible fade show" role="alert">
          <button type="button" class="close" aria-label="Close" (click)="closeAlert()">
            <span aria-hidden="true">&times;</span>
          </button>
          Server time-out error. Please repeat search in 10 minutes.
        </div>
      </div>
      <div class="col-md-2"></div>
    </div>

    <!-----------------------Different result tabs------------------------------->
    <div class="background-white result-display-padding form-borderdesign result-view-align" *ngIf="showResultTab">
      <ul class="nav nav-tabs" id="searchResultTab" role="tablist">
        <li class="nav-item" (click)="Address()">
          <a class="nav-link" id="address-search-tab" data-toggle="tab" href="#addressSearch" role="tab"
             aria-controls="profile" aria-selected="false"><b>Address</b></a>
        </li>
        <li class="nav-item" (click)="Advert()">
          <a class="nav-link" id="advert-search-tab" data-toggle="tab" href="#advertSearch" role="tab"
             aria-controls="profile" aria-selected="false"><b>Adverts</b></a>
        </li>
        <li class="nav-item" (click)="AML()">
          <a class="nav-link" id="aml-search-tab" data-toggle="tab" href="#amlSearch" role="tab"
             aria-controls="profile" aria-selected="false"><b>AML</b></a>
        </li>
        <li class="nav-item" (click)="Associations()">
          <a class="nav-link" id="associated-search-tab" data-toggle="tab" href="#associatedSearch" role="tab"
             aria-controls="profile" aria-selected="false"><b>Associations</b></a>
        </li>
        <li class="nav-item" (click)="AVA()">
          <a class="nav-link" id="ava-search-tab" data-toggle="tab" href="#avaSearch" role="tab"
             aria-controls="profile" aria-selected="false"><b>AVA</b></a>
        </li>
        <li class="nav-item" (click)="CCJDetail()">
          <a class="nav-link" id="ccj-search-tab" data-toggle="tab" href="#ccjSearch" role="tab"
             aria-controls="profile" aria-selected="false"><b>CCJ</b></a>
        </li>
        <li class="nav-item" (click)="Directorship()">
          <a class="nav-link" id="directorship-search-tab" data-toggle="tab" href="#directorshipSearch" role="tab"
             aria-controls="profile" aria-selected="false"><b>Companies House</b></a>
        </li>
        <li class="nav-item" (click)="MainResults()">
          <a class="nav-link active" id="main-tab" data-toggle="tab" href="#mainSearch" role="tab" aria-controls="home"
             aria-selected="true"><b>Crime & Legal</b></a>
        </li>
        <li class="nav-item" (click)="InsolvencyDetail()">
          <a class="nav-link" id="insolvency-search-tab" data-toggle="tab" href="#insolvencySearch" role="tab"
             aria-controls="profile" aria-selected="false"><b>Insolvency</b></a>
        </li>
        <li class="nav-item" (click)="ProspectDetail()" *ngIf="isProspectHitAccess">
          <a class="nav-link" id="prospect-search-tab" data-toggle="tab" href="#prospectSearch" role="tab"
             aria-controls="profile" aria-selected="false"><b>Prospect</b></a>
        </li>
        <li class="nav-item" (click)="SocialMedia()">
          <a class="nav-link" id="social-search-tab" data-toggle="tab" href="#socialSearch" role="tab"
             aria-controls="profile" aria-selected="false"><b>Social Media</b></a>
        </li>
        <li class="nav-item" (click)="LifeStyle()">
          <a class="nav-link" id="lifeStyle-search-tab" data-toggle="tab" href="#lifeStyleSearch" role="tab"
             aria-controls="profile" aria-selected="false"><b>Sports</b></a>
        </li>
      </ul>

      <div class="tab-content" id="searchResultTabContent">
        <br />
        <!---------------------- Category Search table for Main results --------------->

        <div id="accordion" class="accordion print-crime-accordion" *ngIf="CategorySearchTable && tempAccessData.isCriminalityAccess">
          <div class="card mb-0">
            <div class="card-header" data-toggle="collapse" href="#collapseOne" id="headingOne" aria-expanded="false" (click)="ResultTableDisplay()">
              <span>{{collapseResultStatus}} <i class="fa {{collapseResultIcon}}" aria-hidden="true"></i></span>
            </div>
            <div id="collapseOne" class="card-body collapse {{collapseResultClass}} border-bottom" aria-labelledby="headingOne" data-parent="#accordion">
              <div class="row">
                <div class="col-md-12">
                  <div class="table-responsive">
                    <table class="table table-bordered table-striped" id="criminalityStatusTable">
                      <thead>
                        <tr>
                          <th>Category</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let lexicon of lexiconStatus">
                          <td>{{lexicon.name}}</td>
                          <td>
                            <span>{{lexicon.status}}</span>
                            <span class="loadingImg" *ngIf="lexicon.searchloading">
                              <img src="{{loadingAnimation}}" />
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Insolvency Detail -->
        <h3 class="print-search-result-tab-content">Insolvency</h3>
        <div class="tab-pane fade show tab-height" id="insolvencySearch" role="tabpanel" aria-labelledby="insolvency-search-tab">
          <div class="col-md-12" *ngIf="!tempAccessData.isInsolvencyDetailAccess"><i class="color-grey float-right">{{NoSearchSelect}}</i></div>
          <div class="col-md-12" *ngIf="tempAccessData.isInsolvencyDetailAccess && !ccjloading && !CCJErrorMessage && (CCJSearchFail || CCJResult?.completeResponse?.Results?.Summary?.ResultText == 'FAIL')"><i class="color-grey float-right">{{SearchFail}}</i></div>
          <div class="col-md-12" *ngIf="tempAccessData.isInsolvencyDetailAccess && !ccjloading && !CCJSearchFail && !CCJErrorMessage && (CCJResult?.insolvencyDetails == null || CCJResult?.insolvencyDetails.length == 0)"><i class="color-grey float-right">{{NoDataFoundMessage}}</i></div>
          <div class="col-md-12" *ngIf="tempAccessData.isInsolvencyDetailAccess && !ccjloading && !CCJSearchFail && CCJErrorMessage"><i class="color-grey float-right">{{OtherFieldRequired}}</i></div>
          <div>
            <span class="loadingImg" *ngIf="tempAccessData.isInsolvencyDetailAccess && ccjloading">
              <img src="{{loadingAnimation}}" />
            </span>
          </div>
          <div *ngIf="tempAccessData.isInsolvencyDetailAccess && CCJResult && !ccjloading && !(CCJResult?.insolvencyDetails == null || CCJResult?.insolvencyDetails?.length == 0)">
            <div class="row">
              <div class="row">
                <div class="col-md-6 border-bottom" *ngFor="let insolvency of CCJResult?.insolvencyDetails">
                  <div class="shadow-sm bg-white m-2 p-4 h-100">
                    <div class="tab-content" id="myTabContent">
                      <div class="tab-pane fade active show" id="aml" role="tabpanel" aria-labelledby="aml-tab">
                        <div class="bg-white card addresses-item mb-4 border border-primary shadow">
                          <div class="gold-members pt-2 pl-2 pr-2">
                            <div class="media">
                              <div class="media-body">
                                <div class="border-bottom p-4">
                                  <div>
                                    <h5 *ngIf="insolvency?.name" class="mb-2">Name : {{insolvency?.name }}</h5>
                                    <p *ngIf="insolvency?.dob" class="mb-1">DOB : {{insolvency?.dob | date: 'dd-MM-yyyy'}}</p>
                                    <div *ngIf="insolvency?.address != null && (insolvency?.address?.address1 || insolvency?.address?.address2 || insolvency?.address?.address3 || insolvency?.address?.address4 || insolvency?.address?.address5 || insolvency?.address?.postcode || insolvency?.address?.dps)">
                                      <h4>Address</h4>
                                      <p *ngIf="insolvency?.address?.address1" class="mb-1 ml-1">Address 1 : {{insolvency?.address?.address1}}</p>
                                      <p *ngIf="insolvency?.address?.address2" class="mb-1 ml-1">Address 2 : {{insolvency?.address?.address2}}</p>
                                      <p *ngIf="insolvency?.address?.address3" class="mb-1 ml-1">Address 3 : {{insolvency?.address?.address3}}</p>
                                      <p *ngIf="insolvency?.address?.address4" class="mb-1 ml-1">Address 4 : {{insolvency?.address?.address4}}</p>
                                      <p *ngIf="insolvency?.address?.address5" class="mb-1 ml-1">Address 5 : {{insolvency?.address?.address5}}</p>
                                      <p *ngIf="insolvency?.address?.postcode" class="mb-1 ml-1">Postcode : {{insolvency?.address?.postcode}}</p>
                                      <p *ngIf="insolvency?.address?.dps" class="mb-1 ml-1">DPS : {{insolvency?.address?.dps}}</p>
                                    </div>
                                    <div *ngIf="insolvency?.previousAddress != null && (insolvency?.previousAddress?.address1 || insolvency?.previousAddress?.address2 || insolvency?.previousAddress?.address3 || insolvency?.previousAddress?.address4 || insolvency?.previousAddress?.address5 || insolvency?.previousAddress?.postcode || insolvency?.previousAddress?.dps)">
                                      <h4>Previous Address</h4>
                                      <p *ngIf="insolvency?.previousAddress?.address1" class="mb-1 ml-1">Address 1 : {{insolvency?.previousAddress?.address1}}</p>
                                      <p *ngIf="insolvency?.previousAddress?.address2" class="mb-1 ml-1">Address 2 : {{insolvency?.previousAddress?.address2}}</p>
                                      <p *ngIf="insolvency?.previousAddress?.address3" class="mb-1 ml-1">Address 3 : {{insolvency?.previousAddress?.address3}}</p>
                                      <p *ngIf="insolvency?.previousAddress?.address4" class="mb-1 ml-1">Address 4 : {{insolvency?.previousAddress?.address4}}</p>
                                      <p *ngIf="insolvency?.previousAddress?.address5" class="mb-1 ml-1">Address 5 : {{insolvency?.previousAddress?.address5}}</p>
                                      <p *ngIf="insolvency?.previousAddress?.postcode" class="mb-1 ml-1">Postcode : {{insolvency?.previousAddress?.postcode}}</p>
                                      <p *ngIf="insolvency?.previousAddress?.dps" class="mb-1 ml-1">DPS : {{insolvency?.previousAddress?.dps}}</p>
                                    </div>
                                    <p *ngIf="insolvency?.type" class="mb-1">Type : {{insolvency?.type}}</p>
                                    <p *ngIf="insolvency?.caseNo" class="mb-1">Case No : {{insolvency?.caseNo}}</p>
                                    <p *ngIf="insolvency?.caseType" class="mb-1">Case Type : {{insolvency?.caseType}}</p>
                                    <p *ngIf="insolvency?.court" class="mb-1">Court Name : {{insolvency?.court}}</p>
                                    <p *ngIf="insolvency?.startDate" class="mb-1">Start Date : {{insolvency?.startDate | date: 'dd-MM-yyyy'}}</p>
                                    <p *ngIf="insolvency?.status" class="mb-1">Status : {{insolvency?.status}}</p>
                                    <p *ngIf="insolvency?.occupation" class="mb-1">Occupation : {{insolvency?.occupation}}</p>
                                    <p *ngIf="insolvency?.aliases" class="mb-1">Aliases : {{insolvency?.aliases}}</p>
                                    <p *ngIf="insolvency?.description" class="mb-1">Description : {{insolvency?.description}}</p>
                                    <p *ngIf="insolvency?.serviceOffice" class="mb-1">Service Office : {{insolvency?.serviceOffice}}</p>
                                    <p *ngIf="insolvency?.telephoneNumber" class="mb-1">Telephone Number : {{insolvency?.telephoneNumber}}</p>
                                    <p *ngIf="insolvency?.tradingNames" class="mb-1">Trading Names : {{insolvency?.tradingNames}}</p>
                                    <p *ngIf="insolvency?.debtTotal" class="mb-1">Debt Total : {{insolvency?.debtTotal}}</p>
                                    <p *ngIf="insolvency?.assetTotal" class="mb-1">Asset Total : {{insolvency?.assetTotal}}</p>
                                    <p *ngIf="insolvency?.presentationDate" class="mb-1">Presentation Date : {{insolvency?.presentationDate | date: 'dd-MM-yyyy'}}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <!--Prospect Details-->
        <h3 class="print-search-result-tab-content">Prospect</h3>
        <div class="tab-pane fade show tab-height" id="prospectSearch" role="tabpanel" aria-labelledby="prospect-search-tab">
          <div class="col-md-12" *ngIf="!tempAccessData.isCounterFraudAccess && isProspectHitAccess"><i class="color-grey float-right">{{NoSearchSelect}}</i></div>
          <div class="col-md-12" *ngIf="tempAccessData.isCounterFraudAccess && isProspectHitAccess && !prospectloading && !prospectErrorMessage && prospectSearchFail && (!prospectHitResultData || (prospectHitResultData?.errormessages?.length > 0))"><i class="color-grey float-right">{{SearchFail}}</i></div>
          <div class="col-md-12" *ngIf="tempAccessData.isCounterFraudAccess && isProspectHitAccess && !prospectloading && !prospectSearchFail && !prospectErrorMessage && (prospectHitResultData?.hits == null || (prospectHitResultData?.hits?.length == 0 && prospectHitResultData?.errormessages?.length == 0))"><i class="color-grey float-right">{{NoRelevantDataMessage}}</i></div>
          <div class="col-md-12" *ngIf="tempAccessData.isCounterFraudAccess && isProspectHitAccess && !prospectloading && !prospectSearchFail && prospectErrorMessage"><i class="color-grey float-right">{{OtherFieldRequired}}</i></div>
          <div>
            <span class="loadingImg" *ngIf="tempAccessData.isCounterFraudAccess && prospectloading">
              <img src="{{loadingAnimation}}" />
            </span>
          </div>
          <div class="col-md-12" *ngIf="isProspectHitAccess && tempAccessData.isCounterFraudAccess && prospectHitResultData && !prospectloading && !(prospectHitResultData?.hits == null || prospectHitResultData?.hits.length == 0)">
            <div class="row">
              <div class="col-md-12">
                <div class="table-responsive">
                  <div *ngIf="isProspectProfileAccess" class="my-3">
                    <p-button [icon]="!prospectProfileLoading ? 'pi pi-check' : 'pi pi-spin pi-spinner'" [disabled]="prospectProfileButtonDisable || prospectProfileLoading" (click)="RequestProspectProfileData()" label="{{prospectProfileButtonText}}"></p-button>
                    <div *ngIf="prospectProfileResultData?.reference">
                      Reference Number : {{prospectProfileResultData.reference}}
                    </div>
                  </div>
                  <table class="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th>Details</th>
                        <th>I2 Match</th>
                        <th>Previous Search</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let searchData of prospectHitResultData?.hits">
                        <td>
                          <div *ngIf="searchData?.searchType == 'Person'">
                            <p class="mb-1">Person name: {{searchtext}}</p>
                            <p class="mb-1">Date of birth: {{contactData.dateOfBirth}}</p>
                          </div>
                          <div *ngIf="searchData?.searchType == 'Telephone'">
                            <p class="mb-1">Telephone: {{phonenumber}}</p>
                          </div>
                          <div *ngIf="searchData?.searchType == 'Email'">
                            <p class="mb-1">Email: {{contactData.email}}</p>
                          </div>
                          <div *ngIf="searchData?.searchType == 'Vehicle'">
                            <p class="mb-1">Vehicle number: {{contactData.vRN}}</p>
                          </div>
                          <div *ngIf="searchData?.searchType == 'Address'">
                            <p class="mb-1">Address: {{contactData.addressLine1}}</p>
                          </div>
                        </td>
                        <td>
                          <div *ngIf="searchData?.i2Hit == true">
                            <span><i class="fa fa-check-circle color-green"></i></span>
                          </div>
                          <div *ngIf="searchData?.i2Hit == false">
                            <span><i class="fa fa-times-circle color-grey"></i></span>
                          </div>
                        </td>
                        <td>
                          <div *ngIf="searchData?.previousHit == true">
                            <span><i class="fa fa-check-circle color-green"></i></span>
                          </div>
                          <div *ngIf="searchData?.previousHit == false">
                            <span><i class="fa fa-times-circle color-grey"></i></span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- CCJ Detail -->
        <h3 class="print-search-result-tab-content">CCJ</h3>
        <div class="tab-pane fade show tab-height" id="ccjSearch" role="tabpanel" aria-labelledby="ccj-search-tab">
          <div class="col-md-12" *ngIf="!tempAccessData.isCCJDetailAccess"><i class="color-grey float-right">{{NoSearchSelect}}</i></div>
          <div class="col-md-12" *ngIf="tempAccessData.isCCJDetailAccess && !ccjloading && !CCJErrorMessage && (CCJSearchFail || CCJResult?.Results?.Summary?.ResultText == 'FAIL')"><i class="color-grey float-right">{{SearchFail}}</i></div>
          <div class="col-md-12" *ngIf="tempAccessData.isCCJDetailAccess && !ccjloading && !CCJSearchFail && !CCJErrorMessage && (CCJResult?.ccjDetails == null || CCJResult?.ccjDetails.length == 0)"><i class="color-grey float-right">{{NoDataFoundMessage}}</i></div>
          <div class="col-md-12" *ngIf="tempAccessData.isCCJDetailAccess && !ccjloading && !CCJSearchFail && CCJErrorMessage"><i class="color-grey float-right">{{OtherFieldRequired}}</i></div>
          <div>
            <span class="loadingImg" *ngIf="tempAccessData.isCCJDetailAccess && ccjloading">
              <img src="{{loadingAnimation}}" />
            </span>
          </div>
          <div *ngIf="tempAccessData.isCCJDetailAccess && CCJResult && !ccjloading && !(CCJResult?.ccjDetails == null || CCJResult?.ccjDetails.length == 0)">
            <div class="row">
              <div class="col-md-6 border-bottom" *ngFor="let ccj of CCJResult?.ccjDetails">
                <div class="shadow-sm bg-white p-4 h-100">
                  <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade active show" id="ccjdetail" role="tabpanel" aria-labelledby="ccjdetail-tab">
                      <div class="bg-white card addresses-item mb-4 border border-primary shadow">
                        <div class="gold-members pt-2 pl-2 pr-2">
                          <div class="media">
                            <div class="media-body">
                              <div class="border-bottom p-4">
                                <div>
                                  <h6 *ngIf="ccj.courtName" class="mb-2">Court Name : {{ccj.courtName}}</h6>
                                  <p *ngIf="ccj.caseNumber" class="mb-1">Case Number : {{ccj.caseNumber}}</p>
                                  <p *ngIf="ccj.amount" class="mb-1">Amount : <span [innerHTML]="ccj.amount"></span></p>
                                  <p *ngIf="ccj.judgementType" class="mb-1">Type : {{ccj.judgementType}}</p>
                                  <p *ngIf="ccj.judgementDate" class="mb-1">Date : {{ccj.judgementDate | date: 'dd-MM-yyyy'}}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- AML Result -->
        <h3 class="print-search-result-tab-content">AML</h3>
        <div class="tab-pane fade show tab-height" [ngClass]="{'print-aml-search': tempAccessData.isAMLAccess}" id="amlSearch" role="tabpanel" aria-labelledby="aml-search-tab">
          <div class="col-md-12" *ngIf="!tempAccessData.isAMLAccess"><i class="color-grey float-right">{{NoSearchSelect}}</i></div>
          <div class="col-md-12" *ngIf="tempAccessData.isAMLAccess && !amlloading && (AMLSearchFail || AMLResult?.completeResponse?.Results?.Summary?.ResultText == 'FAIL')"><i class="color-grey float-right">{{SearchFail}}</i></div>
          <div class="col-md-12" *ngIf="tempAccessData.isAMLAccess && !amlloading && !AMLSearchFail && (AMLResult?.sanctions == null || AMLResult?.sanctions.length == 0)"><i class="color-grey float-right">{{NoRelevantDataMessage}}</i></div>
          <div>
            <span class="loadingImg" *ngIf="amlloading">
              <img src="{{loadingAnimation}}" />
            </span>
          </div>
          <div *ngIf="AMLResult?.sanctions && !amlloading && !(AMLResult?.sanctions == null || AMLResult?.sanctions?.length == 0)">
            <div class="row">
              <div class="col-md-6 border-bottom" *ngFor="let sanction of AMLResult?.sanctions">
                <div class="shadow-sm bg-white p-4 h-100">
                  <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade active show" id="aml" role="tabpanel" aria-labelledby="aml-tab">
                      <div class="bg-white card addresses-item mb-4 border border-primary shadow">
                        <div class="gold-members pt-2 pl-2 pr-2">
                          <div class="media">
                            <div class="media-body">
                              <div class="border-bottom p-4">
                                <div>
                                  <h5 *ngIf="sanction?.name" class="mb-2">Name : {{sanction?.name }}</h5>
                                  <p *ngIf="sanction?.recency" class="mb-1">Recency : {{sanction.recency}}</p>
                                  <p *ngIf="sanction?.source" class="mb-1">Source : {{sanction.source}}</p>
                                  <p *ngIf="sanction?.type" class="mb-1">Type : {{sanction?.type}}</p>
                                  <p *ngIf="sanction?.dob" class="mb-1">DOB : {{sanction?.dob}}</p>
                                  <div class="mb-1" *ngIf="(sanction?.addresses && sanction?.addresses?.length > 0 && isValidAMLAddress(sanction?.addresses))">
                                    <h6 class="mb-2">Addresses :</h6>
                                    <div class="ml-2" *ngFor="let address of sanction?.addresses">
                                      <div *ngIf="((address.address1 != null && address.address1 != '') || (address.address2 != null && address.address2 != '') || (address.address3 != null && address.address3 != '') || (address.postcode != null && address.postcode != '') || (address.city != null && address.city != '') || (address.country != null && address.country != ''))">Address:</div>
                                      <div *ngIf="(address.address1 != null && address.address1 != '')">Address line 1 : {{address.address1}}</div>
                                      <div *ngIf="(address.address2 != null && address.address2 != '')">Address line 2 : {{address.address2}}</div>
                                      <div *ngIf="(address.address3 != null && address.address3 != '')">Address line 3 : {{address.address3}}</div>
                                      <div *ngIf="(address.postcode != null && address.postcode != '')">Post code : {{address.postcode}}</div>
                                      <div *ngIf="(address.city != null && address.city != '')">City : {{address.city}}</div>
                                      <div *ngIf="(address.country != null && address.country != '')">Country : {{address.country}}</div>
                                    </div>
                                  </div>
                                  <div *ngIf="sanction?.alias?.length > 0">
                                    <h6 class="mb-2">Aliases :</h6>
                                    <p class="mb-1 ml-3" *ngFor="let alias of sanction?.alias">{{alias}}</p>
                                  </div>
                                  <div *ngIf="sanction?.positions?.length > 0">
                                    <h6 class="mb-2">Positions :</h6>
                                    <p class="mb-1 ml-3" *ngFor="let position of sanction?.positions">{{position}}</p>
                                  </div>
                                  <div *ngIf="sanction?.exceptions?.length > 0">
                                    <h6 class="mb-2">Details :</h6>
                                    <p class="mb-1 ml-3" *ngFor="let exception of sanction?.exceptions">{{exception}}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Address Results -->
        <h3 class="print-search-result-tab-content">Address</h3>
        <div class="tab-pane fade show tab-height" [ngClass]="{'print-address-search': tempAccessData.isAddressAccess && tempAccessData.isFinancialAccess && financialData?.data?.CompleteResponse?.Response}" id="addressSearch" role="tabpanel" aria-labelledby="address-search-tab">
          <div class="col-md-12" *ngIf="!financialloading && (tempAccessData.isFinancialAccess && tempAccessData.isAddressAccess) && (financialData?.data?.CompleteResponse?.Response?.People?.length == 0 || financialData?.data?.CompleteResponse?.Response?.length == 0)"><i class="color-grey float-right">{{NoRelevantDataMessage}}</i></div>
          <div class="col-md-12" *ngIf="!financialloading && !addressValidation && (!tempAccessData.isFinancialAccess && tempAccessData.isAddressAccess)"><i class="color-grey float-right">{{NoRelevantDataMessage}}</i></div>
          <div class="col-md-12" *ngIf="!tempAccessData.isAddressAccess"><i class="color-grey float-right">{{NoSearchSelect}}</i></div>
          <div class="col-md-12" *ngIf="!financialloading && (tempAccessData.isFinancialAccess && tempAccessData.isAddressAccess) && financialSearchFail"><i class="color-grey float-right">{{SearchFail}}</i></div>
          <div class="col-md-12" *ngIf="addressValidation && !financialloading && (tempAccessData.isAddressAccess)"><i class="color-grey float-right">{{postCodeRequiredMessage}}</i></div>
          <div>
            <span class="loadingImg" *ngIf="financialloading && tempAccessData.isAddressAccess">
              <img src="{{loadingAnimation}}" />
            </span>
          </div>
          <div *ngIf="tempAccessData.isAddressAccess && tempAccessData.isFinancialAccess && financialData?.data?.CompleteResponse?.Response">
            <div class="container">
              <div class="row border-bottom" *ngFor="let people of financialData?.data?.CompleteResponse?.Response?.People">
                <div class="col-md-4">
                  <div class="shadow-sm bg-white h-100">
                    <div class="address-box-design border-bottom p-4">
                      <div>
                        <h5 class="mb-2"><span>{{(people?.Person?.Name?.Prefix +'. '+people?.Person?.Name?.Fullname) | titlecase }}</span></h5>
                        <p class="mb-1" *ngIf="people?.OccupancyPeriod">Occupancy Period: {{(people?.OccupancyPeriod?.From ? people?.OccupancyPeriod?.From :'' ) + '-' + (people?.OccupancyPeriod?.To? people?.OccupancyPeriod?.To:'') }}</p>
                        <p class="mb-1" *ngIf="people?.ResidencyScore">Residency Score: {{people?.ResidencyScore | titlecase}}</p>
                        <p class="mb-1" *ngIf="people?.RecencyDate">Recency Date: {{(people?.RecencyDate?.Day ? people?.RecencyDate?.Day + '-' :'')+(people?.RecencyDate?.Month ? people?.RecencyDate?.Month +'-':'')+ (people?.RecencyDate?.Year? people?.RecencyDate?.Year :'')}}</p>
                        <p class="mb-1">DOB: {{people?.Person?.DOB?.Day + '-' + people?.Person?.DOB?.Month + '-' + people?.Person?.DOB?.Year}}</p>
                        <p class="mb-1" *ngIf="people?.LandlineNumbers?.Number.length > 0">Landline Numbers:</p>
                        <p class="mb-1 pl-4" *ngFor="let lndline of people?.LandlineNumbers?.Number">{{lndline?.Number}}<i [ngClass]="lndline?.Verified >0 ? 'fa fa-check-circle color-green' : 'not-verified'"></i></p>
                        <p class="mb-1" *ngIf="people?.MobileNumbers?.Number?.length > 0">Mobile Numbers:</p>
                        <p class="mb-1 pl-4" *ngFor="let mobile of people?.MobileNumbers?.Number">{{mobile?.Number}}<i [ngClass]="mobile?.Verified >0 ? 'fa fa-check-circle color-green' : 'not-verified'"></i></p>
                        <p class="mb-1" *ngIf="people?.Emails?.length > 0">Emails: </p>
                        <p class="mb-1 pl-4" *ngFor="let email of people?.Emails">{{email|lowercase }}</p>
                      </div>
                      <h5>Address: </h5>
                      <div class="pl-4">
                        <p *ngIf="people?.Address?.AddressLine1" class="mb-1">{{people?.Address?.AddressLine1 | titlecase}}</p>
                        <p *ngIf="people?.Address?.AddressLine2" class="mb-1">{{people?.Address?.AddressLine2 | titlecase}}</p>
                        <p *ngIf="people?.Address?.AddressLine3" class="mb-1">{{people?.Address?.AddressLine3 | titlecase}}</p>
                        <p *ngIf="people?.Address?.AddressLine4" class="mb-1">{{people?.Address?.AddressLine4 | titlecase}}</p>
                        <p *ngIf="people?.Address?.AddressLine5" class="mb-1">{{people?.Address?.AddressLine5 | titlecase}}</p>
                        <p *ngIf="people?.Address?.AddressLine6" class="mb-1">{{people?.Address?.AddressLine6 | titlecase}}</p>
                        <p *ngIf="people?.Address?.Country" class="mb-1">{{people?.Address?.Country | titlecase}}</p>
                        <p *ngIf="people?.Address?.Postcode" class="mb-1">{{people?.Address?.Postcode | uppercase}}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-8">
                  <div class="shadow-sm bg-white p-4 h-100">
                    <div class="tab-content" id="myTabContent">
                      <div class="tab-pane fade  active show" id="addresses" role="tabpanel" aria-labelledby="addresses-tab">
                        <h4 class="mt-0 mb-4">Additional Addresses</h4>
                        <div class="row" *ngIf="people?.AdditionalAddresses?.length;else noAdditionalAdressFound">
                          <div class="col-md-6" *ngFor="let additional of people?.AdditionalAddresses">
                            <div class="bg-white card addresses-item mb-4 border border-primary shadow">
                              <div class="gold-members pt-2 pl-2 pr-2">
                                <div class="media">
                                  <div class="media-body">
                                    <div class="border-bottom p-4">
                                      <div>
                                        <h5 class="mb-2"><span>{{(additional?.AdditionalAddress?.Name?.Prefix +'. '+additional?.AdditionalAddress?.Name?.Fullname) | titlecase }}</span></h5>
                                        <p class="mb-1" *ngIf="additional?.AdditionalAddress?.OccupancyPeriod">Occupancy Period: {{(additional?.AdditionalAddress?.OccupancyPeriod?.From? additional?.AdditionalAddress?.OccupancyPeriod?.From + '-':'') + (additional?.AdditionalAddress?.OccupancyPeriod?.To ? additional?.AdditionalAddress?.OccupancyPeriod?.To:'') }}</p>
                                        <p class="mb-1" *ngIf="additional?.AdditionalAddress?.ResidencyScore">Residency Score: {{additional?.AdditionalAddress?.ResidencyScore | titlecase}}</p>
                                        <p class="mb-1" *ngIf="additional?.AdditionalAddress?.RecencyDate">Recency Date: {{(additional?.AdditionalAddress?.RecencyDate?.Day ? additional?.AdditionalAddress?.RecencyDate?.Day+'-':'')+ (additional?.AdditionalAddress?.RecencyDate?.Month ?additional?.AdditionalAddress?.RecencyDate?.Month+'-':'')+(additional?.AdditionalAddress?.RecencyDate?.Year?additional?.AdditionalAddress?.RecencyDate?.Year:'')}}</p>
                                        <!--<p class="mb-1" *ngIf="additional?.AdditionalAddress?.DOB">DOB: {{additional?.AdditionalAddress?.DOB?.Day + '-' + additional?.AdditionalAddress?.DOB?.Month + '-' + additional?.AdditionalAddress?.DOB?.Year}}</p>-->
                                        <p class="mb-1" *ngIf="additional?.AdditionalAddress?.LandlineNumbers.Number?.length > 0">Landline Numbers:</p>
                                        <p class="mb-1 pl-4" *ngFor="let lndline of additional?.AdditionalAddress?.LandlineNumbers?.Number">{{lndline?.Number}}<i [ngClass]="lndline?.Verified >0 ? 'fa fa-check-circle color-green' : 'not-verified'"></i></p>
                                        <p class="mb-1" *ngIf="additional?.AdditionalAddress?.MobileNumbers.Number?.length > 0">Mobile Numbers:</p>
                                        <p class="mb-1 pl-4" *ngFor="let mobile of additional?.AdditionalAddress?.MobileNumbers?.Number">{{mobile?.Number}}<i [ngClass]="mobile?.Verified >0 ? 'fa fa-check-circle color-green' : 'not-verified'"></i></p>
                                        <p class="mb-1" *ngIf="additional?.AdditionalAddress?.Emails?.length">Emails: </p>
                                        <p class="mb-1 pl-4" *ngFor="let email of additional?.AdditionalAddress?.Emails">{{email | lowercase }}</p>
                                      </div>
                                      <h5>Address: </h5>
                                      <div class="pl-4">
                                        <p *ngIf="people?.Address?.AddressLine1" class="mb-1">{{additional?.AdditionalAddress?.Address?.AddressLine1 | titlecase}}</p>
                                        <p *ngIf="people?.Address?.AddressLine2" class="mb-1">{{additional?.AdditionalAddress?.Address?.AddressLine2 | titlecase}}</p>
                                        <p *ngIf="people?.Address?.AddressLine3" class="mb-1">{{additional?.AdditionalAddress?.Address?.AddressLine3 | titlecase}}</p>
                                        <p *ngIf="people?.Address?.AddressLine4" class="mb-1">{{additional?.AdditionalAddress?.Address?.AddressLine4 | titlecase}}</p>
                                        <p *ngIf="people?.Address?.AddressLine5" class="mb-1">{{additional?.AdditionalAddress?.Address?.AddressLine5 | titlecase}}</p>
                                        <p *ngIf="people?.Address?.AddressLine6" class="mb-1">{{additional?.AdditionalAddress?.Address?.AddressLine6 | titlecase}}</p>
                                        <p *ngIf="people?.Address?.Country" class="mb-1">{{additional?.AdditionalAddress?.Address?.Country | titlecase}}</p>
                                        <p *ngIf="people?.Address?.Postcode" class="mb-1">{{additional?.AdditionalAddress?.Address?.Postcode | uppercase}}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <ng-template #noAdditionalAdressFound>
                          <div class="col-md-12"><i class="color-grey">No additional address found.</i></div>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--{{financialData?.CompleteResponse | json}}-->
          </div>


        </div>



        <!-- AVA Results -->
        <h3 class="print-search-result-tab-content" [ngClass]="{'ava-print-heading': avaResult}">AVA</h3>
        <div class="tab-pane fade show tab-height " id="avaSearch" role="tabpanel" aria-labelledby="ava-search-tab">
          <div class="col-md-12" *ngIf="!tempAccessData.isAVAAccess"><i class="color-grey float-right">{{NoSearchSelect}}</i></div>
          <div class="col-md-12" *ngIf="tempAccessData.isAVAAccess && avaErrorMessage && !avaloading"><i class="color-grey float-right">{{avaErrorMessage}}</i></div>
          <div class="col-md-12" *ngIf="tempAccessData.isAVAAccess && avaSearchFail && !avaloading"><i class="color-grey float-right">{{SearchFail}}</i></div>
          <div>
            <span class="loadingImg" *ngIf="avaloading">
              <img src="{{loadingAnimation}}" />
            </span>
          </div>
          <div class="row" *ngIf="avaResult">
            <div class="col-md-12">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-md-12">
                    <!-- ------------------ vehicle details start ------------------ -->
                    <h4>Vehicle Details</h4>
                    <div id="avaVehicleDetailID" class="vehicle-wrapper container-fluid my-4" *ngIf="avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleRegistration;else noFoundVehicle">
                      <div class="row">
                        <div class="col-md-12">
                          <table class="table vehicle-table table-borderless">
                            <tbody>
                              <!-- first row -->
                              <tr>
                                <th scope="row">VRN :</th>
                                <td data-table-title="VRN :">
                                  {{ avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleRegistration?.VRM }}
                                </td>
                                <th>Engine Number :</th>
                                <td data-table-title="Engine Number :">
                                  {{ avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleRegistration?.EngineNumber }}
                                </td>
                              </tr>
                              <!-- second row -->
                              <tr>
                                <th scope="row">VIN :</th>
                                <td data-table-title="VIN :">
                                  {{ avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleRegistration?.VINSerialNumber }}
                                </td>
                                <th>Engine Capcity :</th>
                                <td data-table-title="Engine Capcity :">
                                  {{avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleRegistration?.EngineCapacity}}
                                </td>
                              </tr>

                              <!-- third row -->
                              <tr>
                                <th scope="row">Make :</th>
                                <td data-table-title="Make :">
                                  {{ avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleRegistration?.MakeModel }}
                                </td>
                                <th>Fuel Type :</th>
                                <td data-table-title="Fuel Type :">
                                  {{ avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleRegistration?.Fuel }}
                                </td>
                              </tr>

                              <!-- fourth row -->
                              <tr>
                                <th scope="row">Model :</th>
                                <td data-table-title="Model :">
                                  {{ avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleRegistration?.Model }}
                                </td>
                                <th>Year of Manufacture :</th>
                                <td data-table-title="Year of Manufacture :">
                                  {{avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleRegistration?.YearOfManufacture}}
                                </td>
                              </tr>

                              <!-- fifth row -->
                              <tr>
                                <th scope="row">Colour :</th>
                                <td data-table-title="Colour :">
                                  {{ avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleRegistration?.Colour }}
                                </td>
                                <th>First Registered in UK :</th>
                                <td data-table-title="First Registered in UK :">
                                  {{avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleRegistration?.DateFirstRegistered | date: 'dd-MM-yyyy'}}
                                </td>
                              </tr>

                              <!-- sixth row -->
                              <tr>
                                <th scope="row">Body Type :</th>
                                <td data-table-title="Body Type :">
                                  <div *ngIf="avaResult?.advert">{{ avaResult?.advert?.VehicleBodyType }}</div>
                                </td>
                                <th>Previous Keepers :</th>
                                <td data-table-title="Previous Keepers :">
                                  {{avaResult?.vehicleData?.ApprovedProgramCheckResult?.KeeperChangesDetails?.KeeperChangesCount}}
                                </td>
                              </tr>

                              <!-- seventh row -->
                              <tr>
                                <th scope="row">Transmission :</th>
                                <td data-table-title="Transmission :">
                                  {{ avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleRegistration?.Transmission }}
                                </td>
                                <th>Co2 Emission :</th>
                                <td data-table-title="Co2 Emission :">
                                  {{ avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleRegistration?.CO2Emissions }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <ng-template #noFoundVehicle>
                      <div class="col-md-12"><i class="color-grey">{{NoRelevantDataMessage}}</i></div>
                    </ng-template>
                    <!-- ------------------ vehicle details end ------------------ -->
                  </div>
                </div>
                <div class="row message-status-wrapper">
                  <div class="col-md-6">

                    <div class="col-md-12 mt-4 table-bordered message-wrapper">
                      <h4>Messages</h4>
                      <div id="avaMessages" *ngIf="avaResult?.vehicleData?.ApprovedProgramCheckResult?.MessageDetails && avaResult?.vehicleData?.ApprovedProgramCheckResult?.MessageDetails?.MessageCount>0;else noFoundMessage" class="">
                        <div class="border-bottom" *ngFor="let message of avaResult?.vehicleData?.ApprovedProgramCheckResult?.MessageDetails?.MessageList1">
                          <div class="text-center">
                            <p>{{message?.MsgLine1}}</p>
                            <p>{{message?.MsgLine2}}</p>
                            <p>{{message?.MsgLine3}}</p>
                            <p>{{message?.MsgLine4}}</p>
                          </div>
                        </div>
                      </div>
                      <ng-template #noFoundMessage>
                        <div class="col-md-12"><i class="color-grey">{{NoRelevantDataMessage}}</i></div>
                      </ng-template>
                    </div>
                    <div class="table-bordered col-md-12 pt-4 caution-data-wrapper">
                      <h4>Caution Data</h4>
                      <div *ngIf="avaResult?.vehicleData ;else noFoundCaution" class="my-2">
                        <div id="avaCaution" *ngIf="avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleRegistration?.GBVRM || avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleRegistration?.NIVRM">
                          <div class="table-bordered" *ngIf="avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleRegistration?.GBVRM">
                            <span class="align-left section-head">Additional DVLA Data</span>
                            <div>
                              Notified as previously registered at DVLA as&nbsp;
                              <span>{{avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleRegistration?.GBVRM}}</span>
                              <br />
                              <span>You must check the previous VRM.</span>
                            </div>
                          </div>
                          <div class="table-bordered" *ngIf="avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleRegistration?.NIVRM">
                            <span class="align-left section-head">Additional DVLA Data</span>
                            <div>
                              Notified as previously registered at DVLNI as&nbsp;
                              <span>{{avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleRegistration?.NIVRM}}</span>
                              <br />
                              <span>You must check the previous VRM.</span>
                            </div>
                          </div>
                        </div>
                        <!-- Stolen -->
                        <div id="cautionStolen" class="table-bordered" *ngIf="avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Stolen === 'WARNING' && avaResult?.vehicleData?.ApprovedProgramCheckResult?.StolenVehicleDetails">
                          <h5>Stolen</h5>
                          <div class="row border-head">
                            <div class="col">
                              <p class="fw-bold mot-history-head">Date:</p>
                            </div>
                            <div class="col">
                              <p class="fw-bold mot-history-head">Status:</p>
                            </div>
                            <div class="col">
                              <p class="fw-bold mot-history-head">Police Force:</p>
                            </div>
                            <div class="col">
                              <p class="fw-bold mot-history-head">Contact No.:</p>
                            </div>
                          </div>
                          <hr />
                          <div class="row mot-history-wrapper">
                            <div class="col">
                              <p>{{ avaResult?.vehicleData?.ApprovedProgramCheckResult?.StolenVehicleDetails?.DateOfTransaction }}</p>
                            </div>
                            <div class="col">
                              <p>{{ avaResult?.vehicleData?.ApprovedProgramCheckResult?.StolenVehicleDetails?.Status }}</p>
                            </div>
                            <div class="col">
                              <p>{{ avaResult?.vehicleData?.ApprovedProgramCheckResult?.StolenVehicleDetails?.Force }}</p>
                            </div>
                            <div class="col">
                              <p>{{ avaResult?.vehicleData?.ApprovedProgramCheckResult?.StolenVehicleDetails?.ContactNumber }}</p>
                            </div>
                          </div>
                        </div>
                        <!-- End Stolen -->
                        <!-- High Risk -->
                        <div id="cautionHighRisk" class="table-bordered" *ngIf="avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.HighRisk === 'WARNING' && avaResult?.vehicleData?.ApprovedProgramCheckResult?.HighRiskRecordDetails.HighRiskItems.Length > 0">
                          <h5>High Risk</h5>
                          <div class="row border-head" *ngFor="let highRisk of avaResult?.vehicleData?.ApprovedProgramCheckResult?.HighRiskRecordDetails.HighRiskItems">
                            <div class="col">
                              <p class="fw-bold mot-history-head">High Risk Type: {{ highRisk.HighRiskType }}</p>
                            </div>
                            <div class="col">
                              <p class="fw-bold mot-history-head">Reference No.: ---</p>
                            </div>
                            <div class="col">
                              <p class="fw-bold mot-history-head">Company Name: {{ highRisk.CompanyName }}</p>
                            </div>
                            <div class="col">
                              <p class="fw-bold mot-history-head">Date of Interest: {{ highRisk.DateOfInterest}}</p>
                            </div>
                            <div class="col">
                              <p class="fw-bold mot-history-head">Contact No.: {{ highRisk.ContactNumber }}</p>
                            </div>
                            <div class="col">
                              <p class="fw-bold mot-history-head">Period: {{ highRisk.RegistrationPeriod }}</p>
                            </div>
                            <div class="col">
                              <p class="fw-bold mot-history-head">Contact Reference: {{ highRisk.ContactReference }}</p>
                            </div>
                            <div class="col">
                              <p class="fw-bold mot-history-head">Extra Information: {{ highRisk.ExtraInfo }}</p>
                            </div>
                          </div>
                        </div>
                        <!-- End High Risk -->
                        <!-- Finance -->
                        <div id="cautionFinanca" class="table-bordered" *ngIf="(avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Finance === 'WARNING' || avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Finance === 'CAUTION') && avaResult?.vehicleData?.ApprovedProgramCheckResult?.Finance?.FinanceDataRecordCount>0">
                          <div class="col-md-12">
                            <h5>Finance</h5>
                            <div class="border-bottom" *ngFor="let Finance of avaResult?.vehicleData?.ApprovedProgramCheckResult?.Finance?.FinanceDataRecords">
                              <div class="col-sm-12">
                                <div class="col-xs-12 align-left"><b>Date of Interest:</b> {{Finance?.AgreementDate | date:'dd-MM-yyyy'}}</div>
                              </div>
                              <div class="col-sm-12">
                                <div class="col-xs-12 align-left"><b>Agreement Type:</b> {{Finance?.AgreementType}}</div>
                              </div>
                              <div class="col-md-12">
                                <div class="col-xs-12 align-left"><b>Finance Company:</b> <span>{{Finance?.FinanceCompany}}</span></div>
                              </div>
                              <div class="col-sm-12">
                                <div class="col-xs-12 align-left"><b>Agreement Term:</b> <span>{{Finance?.AgreementTerm + ' months'}}</span></div>
                              </div>
                              <div class="col-sm-12">
                                <div class="col-xs-12 align-left"><b>Contact No.:</b>  <span>{{Finance?.ContactNumber}}</span></div>
                              </div>
                              <div class="col-sm-12">
                                <div class="col-xs-12 align-left"><b>Vehicle Description:</b> <span>{{Finance?.VehicleDescription}}</span></div>
                              </div>
                              <div class="col-sm-12">
                                <div class="col-xs-5 align-left"><b>Agreement No.:</b> <span>{{Finance?.AgreementNumber}}</span></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- End Finance -->
                        <!-- Condition -->
                        <div id="cautionCondition" class="table-bordered" *ngIf="(avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Condition === 'WARNING' || avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Condition === 'CAUTION') && avaResult?.vehicleData?.ApprovedProgramCheckResult?.ConditionData?.ConditionDetailsItems.length>0">
                          <div class="col-sm-12">
                            <h5>ABI Condition</h5>
                            <div class="row border-bottom" *ngFor="let ConditionDetails of avaResult?.vehicleData?.ApprovedProgramCheckResult?.ConditionData?.ConditionDetailsItems">
                              <div class="col-sm-12">
                                <div class="col-xs-12 align-center"><strong>Loss Type:</strong> {{ConditionDetails?.Status}}</div>
                              </div>
                              <div class="col-sm-12">
                                <div class="col-xs-12 align-center"><strong>Loss Date:</strong> {{ConditionDetails?.LossDate | date:'dd-MM-yyyy'}}</div>
                              </div>
                              <div class="col-sm-12">
                                <div class="col-xs-12 align-center"><strong>MIAFTR Entry Date:</strong> {{ConditionDetails?.MIAFTRENTRYDATE | date:'dd-MM-yyyy'}}</div>
                              </div>
                              <div class="col-sm-12">
                                <div class="col-xs-12 align-center"><strong>Make and Model:</strong> {{ConditionDetails?.Make + ' ' + ConditionDetails?.Model}}</div>
                              </div>
                              <div class="col-sm-12">
                                <div class="col-xs-12 align-center"><strong>Theft indicator:</strong> {{ConditionDetails?.TheftIndicatorLiteral}}</div>
                              </div>
                              <div class="col-sm-12"></div>
                            </div>
                          </div>
                        </div>
                        <!-- End COndition -->
                        <!-- Scrapped -->
                        <div id="cautionScrapped" class="table-bordered" *ngIf="(avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Scrapped === 'WARNING' || avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Scrapped === 'CAUTION') && avaResult?.vehicleData?.ApprovedProgramCheckResult?.ConditionData.ConditionDetailsItemsCount>0">
                          <div class="col-sm-12">
                            <h5>Scrapped</h5>
                            <div *ngIf="avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleRegistration?.Scrapped === 1">
                              Recorded as scrapped on&nbsp;<span>{{avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleRegistration?.DateScrapped | date:'dd-MM-yyyy'}}</span>
                            </div>
                            <div class="container" *ngIf="avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleRegistration?.Scrapped === 2">
                              DVLA have recorded a Certificate of Destruction was issued on this vehicle on&nbsp;<span>{{avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleRegistration?.DateScrapped | date:'dd-MM-yyyy'}}</span>
                            </div>
                          </div>
                        </div>
                        <!-- End Scrapped -->
                        <!-- Plate Change -->
                        <div id="cautionPlateChange" class="table-bordered" *ngIf="(avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.PlateChange === 'WARNING' || avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.PlateChange === 'CAUTION') && avaResult?.vehicleData?.ApprovedProgramCheckResult?.PlateChangeDetails?.PlateChangeCount>0">
                          <div class="col-sm-12">
                            <h5 class="section-head">Plate Change</h5>
                            <div class="row">
                              <div class="col-sm-6">
                                <div class="col-xs-12 align-center"><strong>Previous VRM</strong></div>
                              </div>
                              <div class="col-sm-6">
                                <div class="col-xs-12 align-center"><strong>Date of Transfer</strong></div>
                              </div>
                            </div>
                            <div class="row" *ngFor="let PlateChanges of avaResult?.vehicleData?.ApprovedProgramCheckResult?.PlateChangeDetails?.PlateChanges1">
                              <div class="col-sm-6">
                                <div class="col-xs-12 align-center"><span>{{PlateChanges?.PreviousVRM}}</span></div>
                              </div>
                              <div class="col-sm-6">
                                <div class="col-xs-12 align-center"><span>{{PlateChanges?.DateOfReceipt | date:'dd-MM-yyyy'}}</span></div>
                              </div>
                            </div>
                            <div class="row pt-4" *ngIf="(avaResult?.vehicleData?.ApprovedProgramCheckResult?.MessageDetails?.MessageList1).length > 0">
                              <div class="col-sm-12">
                                <span>
                                  Current VRM&nbsp;<strong>
                                    <span>{{avaResult?.vehicleData?.ApprovedProgramCheckResult?.PlateChangeDetails?.PlateChanges1[0].CurrentVRM}}</span>
                                  </strong>&nbsp;please check the plate for further details.
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- End Plate Change -->
                        <!-- Exported -->
                        <div id="cautionExported" class="table-bordered" *ngIf="(avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Exported === 'WARNING' || avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Exported === 'CAUTION') && vaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleRegistration?.Exported === 1">
                          <div class="col-sm-12">
                            <h5>Exported</h5>
                            <div class="row">
                              <div class="col-sm-6">
                                <div class="col-xs-12 align-center"><strong>Date</strong></div>
                              </div>
                              <div class="col-sm-6">
                                <div class="col-xs-12 align-center"><strong>Status</strong></div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-sm-6">
                                <div class="col-xs-12 align-center"><span>{{avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleRegistration?.DateExported | date:'dd-MM-yyyy'}}</span></div>
                              </div>
                              <div class="col-sm-6">
                                <div class="col-xs-12 align-center"><span>Exported</span></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- End Exported -->
                        <!-- Imported -->
                        <div id="cautionImported" class="table-bordered" *ngIf="(avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Imported === 'CAUTION' || avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Imported === 'WARNING') && avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleRegistration?.Imported === 1">
                          <div class="col-sm-12">
                            <h3>Potential Import</h3>
                            <div class="pt-4">
                              Previously registered and/or used, declared manufactured in&nbsp;<span>{{avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleRegistration?.YearOfManufacture}}</span>
                            </div>
                          </div>
                        </div>
                        <!-- End Imported -->
                        <!-- Colour Change -->
                        <div id="cautionColourChange" class="table-bordered" *ngIf="avaResult?.vehicleData?.ApprovedProgramCheckResult?.ColourChangesDetails?.ColourChanges1 && avaResult?.vehicleData?.ApprovedProgramCheckResult?.ColourChangesDetails?.ColourChangeCount>0">
                          <h5>Colour Change</h5>
                          <div class="row">
                            <div class="col-sm-2">
                              <div class="col-xs-12 align-center"><strong>Colours</strong></div>
                            </div>
                            <div class="col-sm-5">
                              <div class="col-xs-12 align-center"><strong>Previous colour</strong></div>
                            </div>
                            <div class="col-sm-5">
                              <div class="col-xs-12 align-center"><strong>Date changed</strong></div>
                            </div>
                          </div>
                          <div class="row" *ngFor="let ColourChange of avaResult?.vehicleData?.ApprovedProgramCheckResult?.ColourChangesDetails?.ColourChanges1">
                            <div class="col-sm-2">
                              <div class="col-xs-12 align-center"><span>{{ColourChange?.NumberOfPreviousColours}}</span></div>
                            </div>
                            <div class="col-sm-5">
                              <div class="col-xs-12 align-center"><span>{{ColourChange?.LastColour}}</span></div>
                            </div>
                            <div class="col-sm-5">
                              <div class="col-xs-12 align-center"><span>{{ColourChange?.DateOfLastColourChange | date:'dd-MM-yyyy'}}</span></div>
                            </div>
                          </div>
                        </div>
                        <!-- End Colour Change -->
                        <!-- Vehicle Identity Check -->
                        <div id="cautionVehicleIdentityCheck" class="table-bordered" *ngIf="avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleIDCheckData?.VehicleIDCheck1 && avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleIDCheckData?.VICCount>0">
                          <h5>Vehicle Identity Check</h5>
                          <div class="row">
                            <div class="col-sm-6">
                              <div class="col-xs-12 align-center"><strong>Result</strong></div>
                            </div>
                            <div class="col-sm-6">
                              <div class="col-xs-12 align-center"><strong>VIC Date</strong></div>
                            </div>
                          </div>
                          <div class="row" *ngFor="let VehicleIDCheck of avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleIDCheckData?.VehicleIDCheck1">
                            <div class="col-sm-6">
                              <div class="col-xs-12 align-center"><span>{{(VehicleIDCheck?.VICResult === 1)?'PASS':'FAIL'}}</span></div>
                            </div>
                            <div class="col-sm-6">
                              <div class="col-xs-12 align-center"><span>{{VehicleIDCheck?.VICDate | date:'dd-MM-yyyy'}}</span></div>
                            </div>
                          </div>
                        </div>
                        <!-- End Vehicle Identity Check -->
                      </div>
                      <ng-template #noFoundCaution>
                        <div class="col-md-12"><i class="color-grey">{{NoRelevantDataMessage}}</i></div>
                      </ng-template>
                    </div>
                    <div class="col-md-12 pt-4 table-bordered keeper-changes-wrapper">
                      <h4>Keeper Changes</h4>
                      <table id="keeperChangesID" class="table keeper-table my-4 table-borderless" *ngIf="avaResult?.vehicleData?.ApprovedProgramCheckResult?.KeeperChangesDetails;else noFoundKeepers">
                        <!-- keepers -->
                        <thead>
                          <tr>
                            <th scope="col">Keeper</th>
                            <th scope="col">Last Changed</th>
                            <th scope="col">Transaction</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let keeper of avaResult?.vehicleData?.ApprovedProgramCheckResult?.KeeperChangesDetails?.KeeperChanges1">
                            <td data-table-title="Keeper">
                              {{ keeper?.NumberOfPreviousKeepers }}
                            </td>
                            <td data-table-title="Last Changed">
                              {{ keeper?.DateOfLastKeeperChange | date:'dd-MM-yyyy'}}
                            </td>
                            <td data-table-title="Transaction">
                              {{ keeper?.DateOfTransaction | date:'dd-MM-yyyy'}}
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <ng-template #noFoundKeepers>
                        <div class="col-md-12"><i class="color-grey">{{NoRelevantDataMessage}}</i></div>
                      </ng-template>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="col-md-12 pt-4 status-wrapper">
                      <!-- status start -->
                      <h4>Status</h4>
                      <div id="avaStatusID" *ngIf="avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatus || avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail;else noFoundStatus"
                           class="container-fluid"
                           [ngClass]="{
                        borderGreen: avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatus === 'CLEAR',
                        borderAmber: avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatus === 'CAUTION',
                        borderRed: avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatus === 'WARNING'
                      }">
                        <div class="row">
                          <div class="col-md-12 p-0">
                            <div class="status-heading"
                                 [ngClass]="{
                              colorGreen: avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatus === 'CLEAR',
                              colorAmber: avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatus === 'CAUTION',
                              colorRed: avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatus === 'WARNING'
                            }">
                              <p>{{ avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatus }}</p>
                            </div>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-md-4 p-2">
                            <div class="status"
                                 [ngClass]="{
                              colorGreen:
                                avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Stolen === 'CLEAR',
                              colorAmber:
                                avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Stolen === 'CAUTION',
                              colorRed:
                                avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Stolen === 'WARNING'
                            }">
                              <p *ngIf="avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Stolen === 'CLEAR'">NOT STOLEN</p>
                              <p *ngIf="avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Stolen !== 'CLEAR'">STOLEN</p>
                            </div>
                          </div>
                          <div class="col-md-4 p-2">
                            <div class="status"
                                 [ngClass]="{
                              colorGreen:
                                avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.HighRisk === 'CLEAR',
                              colorAmber:
                                avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.HighRisk === 'CAUTION',
                              colorRed:
                                avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.HighRisk === 'WARNING'
                            }">
                              <p *ngIf="avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.HighRisk === 'CLEAR'">NO HIGH RISK</p>
                              <p *ngIf="avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.HighRisk !== 'CLEAR'">HIGH RISK</p>
                            </div>
                          </div>
                          <div class="col-md-4 p-2">
                            <div class="status"
                                 [ngClass]="{
                              colorGreen:
                                avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Finance === 'CLEAR',
                              colorAmber:
                                avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Finance === 'CAUTION',
                              colorRed: avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Finance === 'WARNING'
                            }">
                              <p *ngIf="avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Finance === 'CLEAR'">NO FINANCE</p>
                              <p *ngIf="avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Finance !== 'CLEAR'">FINANCE</p>

                            </div>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-md-4 p-2">
                            <div class="status"
                                 [ngClass]="{
                              colorGreen:
                                avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Condition === 'CLEAR',
                              colorAmber:
                                avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Condition === 'CAUTION',
                              colorRed:
                                avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Condition === 'WARNING'
                            }">
                              <p *ngIf="avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Condition === 'CLEAR'">NO ABI CONDITION</p>
                              <p *ngIf="avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Condition !== 'CLEAR'">ABI CONDITION</p>
                            </div>
                          </div>
                          <div class="col-md-4 p-2">
                            <div class="status"
                                 [ngClass]="{
                              colorGreen:
                                avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Scrapped === 'CLEAR',
                              colorAmber:
                                avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Scrapped === 'CAUTION',
                              colorRed:
                                avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Scrapped === 'WARNING'
                            }">
                              <p *ngIf="avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Scrapped === 'CLEAR'">NOT SCRAPPED</p>
                              <p *ngIf="avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Scrapped !== 'CLEAR'">SCRAPPED</p>
                            </div>
                          </div>
                          <div class="col-md-4 p-2">
                            <div class="status"
                                 [ngClass]="{
                              colorGreen:
                                avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.PlateChange === 'CLEAR',
                              colorAmber:
                                avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.PlateChange === 'CAUTION',
                              colorRed:
                                avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.PlateChange === 'WARNING'
                            }">
                              <p *ngIf="avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.PlateChange === 'CLEAR'">ORIGINAL PLATE</p>
                              <p *ngIf="avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.PlateChange !== 'CLEAR'">PLATE CHANGE</p>
                            </div>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-md-4 p-2">
                            <div class="status"
                                 [ngClass]="{
                              colorGreen:
                                avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Exported === 'CLEAR',
                              colorAmber:
                                avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Exported === 'CAUTION',
                              colorRed:
                                avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Exported === 'WARNING'
                            }">
                              <p *ngIf="avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Exported === 'CLEAR'">NO EXPORT DATA</p>
                              <p *ngIf="avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Exported !== 'CLEAR'">EXPORTED</p>
                            </div>
                          </div>
                          <div class="col-md-4 p-2">
                            <div class="status"
                                 [ngClass]="{
                              colorGreen:
                                avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Imported === 'CLEAR',
                              colorAmber:
                                avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Imported === 'CAUTION',
                              colorRed:
                                avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Imported === 'WARNING'
                            }">
                              <p *ngIf="avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Imported === 'CLEAR'">NO IMPORT DATA</p>
                              <p *ngIf="avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Imported !== 'CLEAR'">POTENTIAL IMPORT</p>
                            </div>
                          </div>
                          <div class="col-md-4 p-2">
                            <div class="status"
                                 [ngClass]="{
                              colorGreen:
                                avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.ExtraData === 'CLEAR',
                              colorAmber:
                                avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.ExtraData === 'CAUTION',
                              colorRed:
                                avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.ExtraData === 'WARNING'
                            }">
                              <p *ngIf="avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.ExtraData === 'CLEAR'">NO EXTRA DATA</p>
                              <p *ngIf="avaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.ExtraData !== 'CLEAR'">EXTRA DATA</p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <ng-template #noFoundStatus>
                        <div class="col-md-12"><i class="color-grey">{{NoRelevantDataMessage}}</i></div>
                      </ng-template>
                      <!-- status end -->
                    </div>
                    <!-- Previous search start -->
                    <div class="col-md-12 mt-4 table-bordered previous-search-wrapper">
                      <h4>
                        <a data-toggle="collapse" role="button" aria-expanded="false" aria-controls="collapsePreviusSearch"
                           href="#collapsePreviusSearch" target="_blank">Previous Searches</a>
                      </h4>
                      <div class="collapse" id="collapsePreviusSearch">
                        <table id="prviusSearchesID" class="table keeper-table my-4 table-borderless" *ngIf="avaResult?.vehicleData?.ApprovedProgramCheckResult?.PreviousSearchesDetails;else noFoundPreviousSearches">
                          <thead>
                            <tr>
                              <th scope="col">Type Of Business</th>
                              <th scope="col">Date Of Transaction</th>
                              <th scope="col">Time Of Transaction</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let search of avaResult?.vehicleData?.ApprovedProgramCheckResult?.PreviousSearchesDetails?.SearchItems">
                              <td data-table-title="Type Of Business">
                                {{ search?.TypeOfBusiness }}
                              </td>
                              <td data-table-title="Date Of Transaction">
                                {{ search?.DateOfTransaction| date:'dd-MM-yyyy' }}
                              </td>
                              <td data-table-title="Time Of Transaction">
                                {{ search?.TimeOfTransaction }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <ng-template #noFoundPreviousSearches>
                          <div class="col-md-12"><i class="color-grey">{{NoRelevantDataMessage}}</i></div>
                        </ng-template>
                      </div>
                    </div>
                    <!-- Previous search end -->
                  </div>
                </div>

                <div class="row pt-4" [ngClass]="{'print-mot-wrapper': avaResult?.mot?.Response?.DataItems?.MotHistory?.RecordList}">
                  <div class="col-md-12 table-bordered">
                    <!-- mot start -->
                    <h4>Mot History</h4>
                    <div class="container my-4" *ngIf="avaResult?.mot?.Response?.DataItems?.MotHistory?.RecordList;else noFoundMotHistory">
                      <div class="row border-head">
                        <div class="col">
                          <p class="fw-bold mot-history-head">Test Date</p>
                        </div>
                        <div class="col">
                          <p class="fw-bold mot-history-head">Expiry Date</p>
                        </div>
                        <div class="col">
                          <p class="fw-bold mot-history-head">Test Result</p>
                        </div>
                        <div class="col">
                          <p class="fw-bold mot-history-head">
                            Odometer Reading
                          </p>
                        </div>
                        <div class="col">
                          <p class="fw-bold mot-history-head">
                            Mot Test Number
                          </p>
                        </div>
                      </div>
                      <hr />
                      <div class="row mot-history-wrapper"
                           *ngFor="let mot of avaResult?.mot?.Response?.DataItems?.MotHistory?.RecordList">
                        <div class="col">
                          <p>{{ mot?.TestDate }}</p>
                        </div>
                        <div class="col">
                          <p>{{ mot?.ExpiryDate }}</p>
                        </div>
                        <div class="col">
                          <p>{{ mot?.TestResult }}</p>
                        </div>
                        <div class="col">
                          <p>{{ mot?.OdometerReading }}</p>
                        </div>
                        <div class="col">
                          <p>{{ mot?.TestNumber }}</p>
                        </div>

                        <div class="col-md-12 my-2"
                             *ngIf="mot?.advisoryNoticeList">
                          <h5>Advisory Notice Items</h5>
                          <ul class="mx-2 advisory-ul">
                            <li *ngFor="let notice of mot?.AdvisoryNoticeList"
                                class="mot-advisory-notice">
                              {{ notice }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <ng-template #noFoundMotHistory>
                      <div class="col-md-12"><i class="color-grey">{{NoRelevantDataMessage}}</i></div>
                    </ng-template>
                    <!-- mot end -->
                  </div>
                </div>

                <div class="row pt-4">
                  <div class="col-md-12 advert-wrapper">
                    <h4>Advert</h4>
                    <div class="col-md-12 advert-heading" *ngIf="avaResult?.advert?.AdvertSourceData;else noFoundAdvert">
                      <div id="avaadvertID{{j}}" class="row mb-12 table-bordered" *ngFor="let advert of avaResult?.advert?.AdvertSourceData; let j = index">
                        <div class="col-md-12">
                          <p class="card-title">
                            <b>Title:</b><a href="{{advert?.AdvertLink}}" target="_blank"> {{ advert?.AdvertTitle }}</a>
                          </p>
                        </div>
                        <div class="col-md-6">
                          <div id="advertImage{{j}}" class="carousel slide" data-ride="carousel">
                            <!-- Indicators -->
                            <!--<ul class="carousel-indicators">
                              <li data-target="#advertImage" data-slide-to="0" class="active"></li>
                              <li data-target="#advertImage" data-slide-to="1"></li>
                              <li data-target="#advertImage" data-slide-to="2"></li>
                            </ul>-->
                            <!-- The slideshow -->
                            <div class="carousel-inner">
                              <div *ngFor="let imgUrl of advert?.VehicleImages; let i = index" [ngClass]="i==0 ?'active':''" class="carousel-item">
                                <img id="{{advert.AdvertId}}" class="imageSize" src="{{imgUrl}}" alt="Los Angeles" alt="Car Image">
                              </div>
                            </div>
                            <!-- Left and right controls -->
                            <a class="carousel-control-prev" href="#advertImage{{j}}" data-slide="prev">
                              <span class="carousel-control-prev-icon"></span>
                            </a>
                            <a class="carousel-control-next" href="#advertImage{{j}}" data-slide="next">
                              <span class="carousel-control-next-icon"></span>
                            </a>
                          </div>
                          <!--<img class="card-img-top"
                          src="https://mdbootstrap.com/img/Photos/Horizontal/City/4-col/img%20(60).jpg" alt="Card image cap">-->
                        </div>
                        <div class="colo-md-6 card-body">

                          <!--<h4 class="card-title">Source: {{ advert?.AdvertSource }}</h4>-->
                          <div class="card-text">
                            <div class="col">
                              <p><b>Source:</b> {{ advert?.AdvertSource  }}</p>
                            </div>
                            <div class="col">
                              <p><b>Mileage:</b> {{ advert?.vehicleMileage }}</p>
                            </div>
                            <div class="col">
                              <p><b>Posted on:</b> {{ advert?.VehiclePostedOn | date:'dd-MM-yyyy'}}</p>
                            </div>
                            <div class="col">
                              <p><b>Price:</b> {{ advert?.VehiclePrice }}</p>
                            </div>
                            <div class="col" *ngIf="advert?.Seller">
                              <p><b>Seller:</b> {{ advert?.Seller}}</p>
                            </div>
                            <div class="col" *ngIf="advert?.DetailedData?.SellerDetail?.SellerEmail">
                              <p><b>Seller Email:</b>{{ advert?.DetailedData?.SellerDetail?.SellerEmail}}</p>
                            </div>
                            <div class="col" *ngIf="advert?.DetailedData?.SellerDetail?.SellerMobile">
                              <p><b>Seller Mobile:</b> {{ advert?.DetailedData?.SellerDetail?.SellerMobile}}</p>
                            </div>
                            <div class="col" *ngIf="advert?.DetailedData?.SellerDetail?.SellerLocation">
                              <p><b>Seller Location:</b> {{ advert?.DetailedData?.SellerDetail?.SellerLocation }}</p>
                            </div>
                          </div>
                          <!--<a class="btn btn-primary">Button</a>-->
                        </div>
                        <div class="col-md-12 my-2">
                          <h5>
                            <a data-toggle="collapse" role="button" aria-expanded="false" aria-controls="collapseDescription"
                               href="#collapseDescription{{j}}" target="_blank">Description</a>
                          </h5>
                          <div class="collapse" id="collapseDescription{{j}}">
                            <p class="vehicle-description">
                              {{ advert?.VehicleDescription }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ng-template #noFoundAdvert>
                      <div class="col-md-12"><i class="color-grey">{{NoRelevantDataMessage}}</i></div>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Financial Results -->
        <!-- <h3 class="print-search-result-tab-content">Financial</h3> -->
        <!--<div class="tab-pane fade show tab-height " id="financialSearch" role="tabpanel" aria-labelledby="financial-search-tab">
          <div class="col-md-12" *ngIf="noRelevantDataFinancial"><i class="color-grey float-right">{{NoRelevantDataMessage}}</i></div>
          <div class="col-md-12" *ngIf="!noRelevantDataFinancial"><i class="color-grey float-right">{{NoSearchSelect}}</i></div>
        </div>-->
        <!----------------------- Main Results --------------------------->
        <h3 class="print-search-result-tab-content">Crime & Legal</h3>
        <!-- <div id="accordion" class="accordion print-accordion-hide print-crime-accordion" *ngIf="tempAccessData.isCriminalityAccess">
          <div class="card mb-0">
            <div class="card-header" data-toggle="collapse" href="#collapseOne" id="headingOne" style="text-align: right" aria-expanded="false" (click)="ResultTableDisplay()">
              <span>{{collapseResultStatus}} <i class="fa {{collapseResultIcon}}" aria-hidden="true"></i></span>
            </div>
            <div id="collapseOne" class="card-body collapse {{collapseResultClass}} border-bottom" aria-labelledby="headingOne" data-parent="#accordion">
              <div class="row">
                <div class="col-md-12">
                  <div class="table-responsive">
                    <table class="table table-bordered table-striped">
                      <thead>
                        <tr>
                          <th>Category</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let lexicon of lexiconStatus">
                          <td>{{lexicon.name}}</td>
                          <td>
                            <span>{{lexicon.status}}</span>
                            <span class="loadingImg" *ngIf="lexicon.searchloading">
                              <img src="{{loadingAnimation}}" />
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->

        <div class="tab-pane fade show active" id="mainSearch" role="tabpanel" aria-labelledby="main-tab">
          <div class="col-md-12" *ngIf="!noRelevantDataCriminality"><i class="color-grey float-right">{{NoSearchSelect}}</i></div>
          <div class="row topmargin" *ngIf="noRelevantDataCriminality">
            <div class="col-md-12">
              <p class="results" *ngIf="IsResultValues">
                Search returned <span class="color-purple">{{resultcount}}</span> <span *ngIf="showAllResults"> relevant</span> results.
                <a (click)="AllResults()" *ngIf="showAllResults"><span class="color-purple">Show all results</span></a>
                <a (click)="RelevantResults()" *ngIf="showRelevantResults"><span class="color-purple">Show relevant results</span></a>
              </p>
            </div>
          </div>
          <br />
          <div class="row" *ngIf="noRelevantDataCriminality">
            <div class="col-md-12" *ngFor="let result of finalresults; let i=index">

              <div class="row">
                <div [ngClass]="{'col-md-10': !devData,'col-md-8':devData}"><h5><a href="{{result.link}}" target="_blank" title="{{result.link}}">{{result.title}}</a></h5></div>
                <div class="col-md-2 rank-history" *ngIf="devData"><button (click)="ShowRankHistory(rankHistorytemplate, i)">Rank : {{result.rank}}</button></div>
                <div class="col-md-2 d-flex justify-content-end">
                  <div>
                    <i class="pi pi-thumbs-up mr-2 like" [ngClass]="{'thumbs-up': result.like == true}"
                       (click)="likeFeedBack(i, 'Criminality')"></i>
                  </div>
                  <div>
                    <i class="pi pi-thumbs-down mr-2 dislike" [ngClass]="{'thumbs-down': result.dislike == true}"
                       (click)="dislikeFeedBack(i, 'Criminality')"></i>
                  </div>
                </div>
              </div>

              <p [innerHTML]="result.description" class="description"></p>
              <div class="row">
                <div class="col-md-6">
                  <p class="source"><small [hidden]="result.source == null">Source: {{result.source}}</small></p>
                </div>
                <div class="col-md-6">
                  <p class="pubdate"><small [hidden]="result.pubDate == null">Published Date: {{result.pubDate}}</small></p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-----------------------  Associated Results --------------------------->
        <h3 class="print-search-result-tab-content">Associated</h3>
        <div class="tab-pane fade show" id="associatedSearch" role="tabpanel" aria-labelledby="associated-search-tab">
          <div>
            <div class="col-md-12" *ngIf="!tempAccessData.isCriminalityAccess"><i class="color-grey float-right">{{NoSearchSelect}}</i></div>
            <div class="col-md-12" *ngIf="!associationloading && tempAccessData.isCriminalityAccess && associatedDataNotProvide"><i class="color-grey float-right">{{assiciateDataNotProvideMessage}}</i></div>
            <div class="col-md-12" *ngIf="!associationloading && tempAccessData.isCriminalityAccess && associatedFail && Associatedresultsdisplay.length === 0"><i class="color-grey float-right">{{SearchFail}}</i></div>
            <div class="col-md-12" *ngIf="!associationloading && tempAccessData.isCriminalityAccess && !associatedDataNotProvide && !associatedFail && Associatedresultsdisplay.length === 0"><i class="color-grey float-right">{{NoRelevantDataMessage}}</i></div>
            <span class="loadingImg" *ngIf="associationloading">
              <img src="{{loadingAnimation}}" />
            </span>
          </div>
          <div class="row topmargin" *ngIf="!associationloading">
            <div class="col-md-12">
              <p class="results" *ngIf="AssociatedresultCount">
                Search returned <span class="color-purple">{{AssociatedresultCount}}</span> results
              </p>
            </div>
          </div>
          <br />
          <div class="row" *ngIf="!associationloading">
            <div class="col-md-12" *ngFor="let result of Associatedresultsdisplay; let i=index">
              <div class="d-flex">
                <div class="col-md-10">
                  <h5><a href="{{result.link}}" target="_blank" title="{{result.link}}">{{result.title}}</a></h5>
                  <p [innerHTML]="result.description" class="description"></p>
                </div>
                <div class="col-md-2 d-flex justify-content-end">
                  <div>
                    <i class="pi pi-thumbs-up mr-2 like" [ngClass]="{'thumbs-up': result.like == true}"
                       (click)="likeFeedBack(i, 'Associations')"></i>
                  </div>
                  <div>
                    <i class="pi pi-thumbs-down dislike" [ngClass]="{'thumbs-down': result.dislike == true}"
                       (click)="dislikeFeedBack(i, 'Associations')"></i>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <p class="source"><small [hidden]="result.source == null">Source: {{result.source}}</small></p>
                </div>
                <div class="col-md-6">
                  <p class="pubdate"><small [hidden]="result.pubDate == null">Published Date: {{result.pubDate}}</small></p>
                </div>
              </div>
            </div>
          </div>
        </div>



        <!-----------------------  Life Style Results --------------------------->
        <!--<h3 class="print-search-result-tab-content">Life Style</h3>
        <div id="accordionTwo" class="accordion print-accordion-hide print-lifestyle-accordion" *ngIf="tempAccessData.isSportsAccess">
          <div class="card mb-0">
            <div class="card-header" data-toggle="collapse" href="#collapseTwo" id="headingTwo" style="text-align: right" aria-expanded="false" (click)="LifeStyleTableDisplay()">-->
        <!--<a class="card-title"></a>-->
        <!--<span>{{collapseLifeStyleStatus}} <i class="fa {{collapseLifeStyleIcon}}" aria-hidden="true"></i></span>
            </div>
            <div id="collapseTwo" class="card-body collapse {{collapseLifeStyleClass}} border-bottom" aria-labelledby="headingTwo" data-parent="#accordionTwo">
              <div class="row">
                <div class="col-md-12">
                  <div class="table-responsive">
                    <table class="table table-bordered table-striped">
                      <thead>
                        <tr>
                          <th>Category</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let lifestyle of lifeStyleStaus">
                          <td>{{lifestyle.displayname}}</td>
                          <td>
                            <span>{{lifestyle.status}}</span>
                            <span class="loadingImg" *ngIf="lifestyle.searchloading">
                              <img src="{{loadingAnimation}}" />
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>-->

        <div class="tab-pane fade show" id="lifeStyleSearch" role="tabpanel" aria-labelledby="lifeStyle-search-tab">
          <div id="accordionTwo" class="accordion" *ngIf="LifeStyleCategorySearchTable">
            <div class="card mb-0">
              <div class="card-header" data-toggle="collapse" href="#collapseTwo" id="headingTwo" aria-expanded="false" (click)="LifeStyleTableDisplay()">
                <!--<a class="card-title"></a>-->
                <span>{{collapseLifeStyleStatus}} <i class="fa {{collapseLifeStyleIcon}}" aria-hidden="true"></i></span>
              </div>
              <div id="collapseTwo" class="card-body collapse {{collapseLifeStyleClass}} border-bottom" aria-labelledby="headingTwo" data-parent="#accordionTwo">
                <div class="row">
                  <div class="col-md-12">
                    <div class="table-responsive">
                      <table class="table table-bordered table-striped" id="sportsStatusTable">
                        <thead>
                          <tr>
                            <th>Category</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let lifestyle of lifeStyleStaus">
                            <td>{{lifestyle.displayname}}</td>
                            <td>
                              <span>{{lifestyle.status}}</span>
                              <span class="loadingImg" *ngIf="lifestyle.searchloading">
                                <img src="{{loadingAnimation}}" />
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!------------------------ Display Life Style results  -------------------->
          <div id="lifestyleResult">
            <div class="col-md-12" *ngIf="!noRelevantDataLifeStyle"><i class="color-grey float-right">{{NoSearchSelect}}</i></div>
            <div class="row topmargin">
              <div class="col-md-12">
                <p class="results" *ngIf="displaylifeStyleCount">
                  Search returned <span class="color-purple">{{LifeStyleResultCount}}</span> results
                </p>
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-md-12" *ngFor="let result of finalLifeStyleResults; let i=index">
                <div class="d-flex">
                  <div class="col-md-10">
                    <h5><a href="{{result.link}}" target="_blank" title="{{result.link}}">{{result.title}}</a></h5>
                    <p [innerHTML]="result.description" class="description"></p>
                  </div>
                  <div class="col-md-2 d-flex justify-content-end">
                    <div>
                      <i class="pi pi-thumbs-up mr-2 like" [ngClass]="{'thumbs-up': result.like == true}"
                         (click)="likeFeedBack(i, 'Sports')"></i>
                    </div>
                    <div>
                      <i class="pi pi-thumbs-down dislike" [ngClass]="{'thumbs-down': result.dislike == true}"
                         (click)="dislikeFeedBack(i, 'Sports')"></i>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <p class="source"><small [hidden]="result.source == null">Source: {{result.source}}</small></p>
                  </div>
                  <div class="col-md-6">
                    <p class="pubdate"><small [hidden]="result.pubDate == null">Published Date: {{result.pubDate}}</small></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-----------------------  Social Media Results --------------------------->
        <h3 class="print-search-result-tab-content">Social Media</h3>

        <!-- print accordion social media -->
        <div id="accordionThree" class="accordion  print-accordion-hide" *ngIf="tempAccessData.isSocialMediaAccess">
          <div class="card mb-0">
            <div class="card-header" data-toggle="collapse" href="#collapseThree" id="headingThree" aria-expanded="false" (click)="SocialMediaTableDisplay()">
              <span>{{collapseSocialMediaStatus}} <i class="fa {{collapseSocialMediaIcon}}" aria-hidden="true"></i></span>
            </div>
            <div id="collapseThree" class="card-body collapse {{collapseSocialMediaClass}} border-bottom" aria-labelledby="headingThree" data-parent="#accordionThree">
              <div class="row">
                <div class="col-md-12">
                  <div class="table-responsive">
                    <table class="table table-bordered table-striped" id="socialMediaStatusTable">
                      <thead>
                        <tr>
                          <th>Category</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let media of socialMediaStatus">
                          <td>{{media.displayname}}</td>
                          <td>
                            <span>{{media.status}}</span>
                            <span class="loadingImg" *ngIf="media.searchloading">
                              <img src="{{loadingAnimation}}" />
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="tab-pane fade show" id="socialSearch" role="tabpanel" aria-labelledby="social-search-tab">
          <div id="accordionThree" class="accordion" *ngIf="SocialMediaCategorySearchTable">
            <div class="card mb-0">
              <div class="card-header" data-toggle="collapse" href="#collapseThree" id="headingThree" aria-expanded="false" (click)="SocialMediaTableDisplay()">
                <span>{{collapseSocialMediaStatus}} <i class="fa {{collapseSocialMediaIcon}}" aria-hidden="true"></i></span>
              </div>
              <div id="collapseThree" class="card-body collapse {{collapseSocialMediaClass}} border-bottom" aria-labelledby="headingThree" data-parent="#accordionThree">
                <div class="row">
                  <div class="col-md-12">
                    <div class="table-responsive">
                      <table class="table table-bordered table-striped">
                        <thead>
                          <tr>
                            <th>Category</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let media of socialMediaStatus">
                            <td>{{media.displayname}}</td>
                            <td>
                              <span>{{media.status}}</span>
                              <span class="loadingImg" *ngIf="media.searchloading">
                                <img src="{{loadingAnimation}}" />
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--------------- Display Social Media Results --------------->
          <div id="socialResult">
            <div class="col-md-12" *ngIf="!noRelevantDataSocialMedia"><i class="color-grey float-right">{{NoSearchSelect}}</i></div>
            <div class="row topmargin">
              <div class="col-md-12">
                <p class="results" *ngIf="displaySocialMediaCount">
                  Search returned <span class="color-purple">{{SocialMediaResultCount}}</span> results
                </p>
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-md-12 marginBottom" *ngFor="let result of finalSocialMediaResults;let i=index">
                <div class="row">
                  <div class="col-md-1" *ngIf="result.imageURL">
                    <img src="{{result.imageURL}}" height="50" width="50" class="fbImage" />
                  </div>
                  <div class="col-md-11 d-flex">
                    <div class="col-md-9">
                      <h5><a href="{{result.link}}" target="_blank" title="{{result.link}}">{{result.title}}</a></h5>
                      <p [innerHTML]="result.description" class="description"></p>
                    </div>
                    <div class="col-md-2 d-flex justify-content-end">
                      <div>
                        <i class="pi pi-thumbs-up mr-2 like" [ngClass]="{'thumbs-up': result.like == true}"
                           (click)="likeFeedBack(i, 'Social')"></i>
                      </div>
                      <div>
                        <i class="pi pi-thumbs-down dislike" [ngClass]="{'thumbs-down': result.dislike == true}"
                           (click)="dislikeFeedBack(i, 'Social')"></i>
                      </div>
                    </div>
                  </div>
                </div>

                <!--<div class="row">
                  <div class="col-md-6">
                    <p class="source"><small [hidden]="result.source == null">Source: {{result.source}}</small></p>
                  </div>
                  <div class="col-md-6">
                    <p class="pubdate"><small [hidden]="result.pubDate == null">Published Date: {{result.pubDate}}</small></p>
                  </div>
                </div>-->
              </div>
            </div>
          </div>
        </div>

        <!--------------- Advert Result --------------->
        <h3 class="print-search-result-tab-content">Advert</h3>
        <div class="tab-pane fade show" id="advertSearch" role="tabpanel" aria-labelledby="advert-search-tab">
          <div>
            <span class="loadingImg" *ngIf="advertloading">
              <img src="{{loadingAnimation}}" />
            </span>
          </div>
          <div class="col-md-12" *ngIf="!noRelevantDataAdvert && !advertloading"><i class="color-grey float-right">{{NoSearchSelect}}</i></div>
          <div class="col-md-12" *ngIf="AdvertValidationMessage"><i class="color-grey float-right">{{AdvertValidationMessage}}</i></div>
          <div class="row topmargin">
            <div class="col-md-12">
              <p class="results" *ngIf="AdvertResultCount">
                Search returned <span class="color-purple">{{AdvertResultCount}}</span> results
              </p>
            </div>
          </div>
          <br />
          <div class="row" *ngIf="advertResults">
            <div class="col-md-12" *ngFor="let result of advertResults; let i=index">
              <div class="d-flex">
                <div class="col-md-10">
                  <h5><a href="{{result.link}}" target="_blank" title="{{result.link}}">{{result.title}}</a></h5>
                  <p [innerHTML]="result.description" class="description"></p>
                </div>
                <div class="col-md-2 d-flex justify-content-end">
                  <div>
                    <i class="pi pi-thumbs-up mr-2 like" [ngClass]="{'thumbs-up': result.like == true}"
                       (click)="likeFeedBack(i, 'Adverts')"></i>
                  </div>
                  <div>
                    <i class="pi pi-thumbs-down dislike" [ngClass]="{'thumbs-down': result.dislike == true}"
                       (click)="dislikeFeedBack(i, 'Adverts')"></i>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <p class="source"><small [hidden]="result.source == null">Source: {{result.source}}</small></p>
                </div>
                <div class="col-md-6">
                  <p class="pubdate"><small [hidden]="result.pubDate == null">Published Date: {{result.pubDate}}</small></p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--------------- Display Directorship --------------->
        <h3 class="print-search-result-tab-content">Directorship</h3>
        <div class="tab-pane fade show tab-height " id="directorshipSearch" role="tabpanel" aria-labelledby="directorship-search-tab">
          <div>
            <span class="loadingImg" *ngIf="directorshiploading">
              <img src="{{loadingAnimation}}" />
            </span>
          </div>
          <div class="col-md-12" *ngIf="!noRelevantDataDirectorship"><i class="color-grey float-right">{{NoSearchSelect}}</i></div>
          <div class="col-md-12" *ngIf="DirectorShipErrorMessage"><i class="color-grey float-right">{{DirectorShipErrorMessage}}</i></div>
          <div class="col-md-12" *ngIf="directorshipSearchFail"><i class="color-grey float-right">{{SearchFail}}</i></div>
          <div class="mt-6 company-result-page" *ngFor="let people of userDirectorshipData let i=index">
            <div class="mt-2">
              <h5>
                <a data-toggle="collapse" role="button" aria-expanded="false" aria-controls="collapseExample"
                   href="#collapseExample{{i}}" target="_blank">{{people.Name }} <span class="color-purple" *ngIf="people.IsDisqualified">{{'('+ 'Disqualified'+')'}}</span></a>
              </h5>
              <div class="color-grey margin-top-ds"> <small>{{people.Description}}</small></div>
              <div class="collapse collapse-company-details" id="collapseExample{{i}}">
                <div class="card card-body">
                  <div class="row">
                    <div class="col-md-4">
                      <div>Address</div>
                      <div class="display-font-weight">{{people.Address}}</div>
                    </div>
                    <div class="col-md-4">
                      <div>Date Of Birth</div>
                      <div class="display-font-weight">{{people.DateOfBirth}}</div>
                    </div>
                  </div>
                  <div *ngFor="let cmp of people.CompaniesData">
                    <div class="row mt-4">
                      <div class="col-md-12">
                        <h5 class="text-Decoration">{{cmp.Name + '(' + cmp.Number + ')'}}</h5>
                      </div>
                    </div>
                    <div class="row mt-4">
                      <div class="col-md-2">
                        <div>Company status</div>
                        <div class="display-font-weight">{{cmp.Status}}</div>
                      </div>
                      <div class="col-md-2">
                        <div>Officer Role</div>
                        <div class="display-font-weight">{{cmp.OfficerRole}}</div>
                      </div>
                      <div class="col-md-2">
                        <div>Appointed On</div>
                        <div class="display-font-weight">{{cmp.companyObject.appointed_on}}</div>
                      </div>
                    </div>
                    <div class="row mt-4">
                      <div class="col-md-2">
                        <div>Resigned On</div>
                        <div class="display-font-weight">{{cmp.companyObject.resigned_on}}</div>
                      </div>
                      <div class="col-md-2">
                        <div>Nationality</div>
                        <div class="display-font-weight">{{cmp.companyObject.nationality}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ul class="grid-view">
                <li *ngFor="let cmp of people.CompaniesData">{{cmp.Name + '(' + cmp.Status + ')'}} <br /></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <label class="target"></label>

    <!--------------- Pop Up Dialog Box for displaying Rank History of each link ---------------------->
    <ng-template #rankHistorytemplate>
      <div class="modal-header">
        <h5>Domain : google.{{rankDomain}}</h5>
        <button type="button" class="close pull-right" aria-label="Close" (click)="rankHistoryPopUp.hide()">
          <span aria-hidden="true">×</span>
        </button>
      </div>

      <div class="modal-body">
        <h5>Domain Value:  {{rankDomainValue}}</h5>
        <h5>Lexicons : <span *ngFor="let lexicon of rankHistoryLexicon; last as isLast">{{lexicon}}<span *ngIf="!isLast">, </span></span></h5>
        <h5>Duplicate links:  {{duplicatelinkCount}}</h5>
        <h5>Rank assigned for duplicate links:  {{duplicatelinkRank}}</h5>
        <h5>Negative Keywords: <span *ngFor="let keyword of negativekeywords; last as isLast">{{keyword}}<span *ngIf="!isLast">, </span></span></h5>
        <h5>Negative Points: {{negativePoints}}</h5>
        <div class="table-responsive">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>Keyword</th>
                <th>Dependent Keyword</th>
                <th>Frequency</th>
                <th>Weight</th>
                <th>Count</th>
                <th>Final Weight</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let history of rankHistory">
                <td>{{history.keyword}}</td>
                <td>{{history.dependentKeyword}}</td>
                <td>{{history.includeFrequency}}</td>
                <td>{{history.weight}}</td>
                <td>{{history.keywordCount}}</td>
                <td>{{history.finalWeight}}</td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="rankHistoryPopUp.hide()">Close</button>
      </div>
    </ng-template>

  </div>

  <div class="container" id="element">

  </div>
  <!-- <app-toast-message [planExpiry]="planExpiry"
                     [isPlanExpired]="isPlanExpired"  *ngIf="isPlanExpired == true"></app-toast-message> -->
  <p-confirmDialog key="ProspectProfileRequest" [style]="{width: '50vw'}" [baseZIndex]="10000"></p-confirmDialog>
  <p-toast position="bottom-right" key="PlanExpiry"></p-toast>
</div>
<div id="pdf-backdrop" class="d-flex align-items-center justify-content-center flex-column" *ngIf="isDownloadStart">
  <div class="lds-roller my-4"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
  <h5 class="text-light">Generating PDF...</h5>
</div>

<!--<p-messages [value]="msgs"></p-messages>-->
