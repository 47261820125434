<div class="container">
  <span>HeadsUp Username: {{requestData.profileData.searchUsername ? requestData.profileData.searchUsername :
    '-'}}</span>
  <br />
  <span>Search Date &amp; Time: {{requestData.profileData.searchDate}} - {{requestData.profileData.searchTime}}</span>
</div>
<div>
  <app-pdf-search-form-email [requestData]="requestData"></app-pdf-search-form-email>
</div>
<br />
<br />
<div class="container">

  <!-- Email Result -->
  <div>
    <h3><b><u>Mobile</u></b></h3>
    <app-emailvalidation-result-email [emailValidation]="emailValidation"></app-emailvalidation-result-email>
    <br />
    <app-emailage-result-email [emailage]="emailage"></app-emailage-result-email>
  </div>

  <!-- Opensource Result -->
  <div>
    <h3><b><u>Opensource</u></b></h3>
    <app-opensource-result-mobile [opensource]="opensource"></app-opensource-result-mobile>
  </div>

  <!-- Prospect Result -->
  <div *ngIf="prospect.isProspectHitAccess && !regionAccess.isAURegion" class="page-break"></div>
  <div *ngIf="prospect.isProspectHitAccess && !regionAccess.isAURegion">
    <h3><b><u>Prospect</u></b></h3>
    <app-prospect-result-personal [prospect]="prospect"></app-prospect-result-personal>
  </div>
</div>