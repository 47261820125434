<div class="card-body">
  <div>

    <div class="row mb-3">
      <div class="col-md-6 p-0 text-right">
        Existence
      </div>
      <div class="col-md-6 p-0 text-left">
        <div>
          <div class="loadingImg" *ngIf="business.isLoading">
            <img src="{{messageService.loadingAnimation}}" />
          </div>

          <div *ngIf="!business.isLoading && business?.responseData">
            <!-- Limited Company -->
            <div *ngIf="isLimitedCompany()">
              <div *ngIf="business?.responseData?.creditsafeResult; else default1">
                <span class="ml-3 dot dot-green"
                pTooltip="Red - No record found
                          Amber - Possible match identified
                          Green - Exact record found"
                tooltipPosition="top"></span>
              </div>
              <ng-template #default1>
                <div>
                  <span class="ml-3 dot dot-red"
                  pTooltip="Red - No record found
                            Amber - Possible match identified
                            Green - Exact record found"
                  tooltipPosition="top"></span>
                </div>
              </ng-template>
            </div>

            <!-- Non Limited Company -->
            <div *ngIf="isNonLimitedCompanyExistence()">
              <div *ngIf="(business?.responseData?.businessTargeterObject?.nameAndPostcodeMatchCount > 0 || business?.responseData?.businessTargeterObject?.nameMatchCount > 0) && (business?.responseData?.businessTargeterObject?.resultMatch == 'Found')">
                <span class="ml-3 dot dot-green"
                pTooltip="Red - No record found
                          Amber - Possible match identified
                          Green - Exact record found"
                tooltipPosition="top"></span>
              </div>
              <div *ngIf="((business?.responseData?.businessNonRegisteredProfileResult) && business?.responseData?.businessTargeterObject?.nameMatchCount > 0 && business?.responseData?.businessTargeterObject?.resultMatch == 'PartialFound') ||
                   (business?.responseData?.businessNonRegisteredProfileResult && business?.responseData?.businessTargeterObject?.nameMatchCount == 0 && business?.responseData?.businessTargeterObject?.nameAndPostcodeMatchCount == 0)">
                <span class="ml-3 dot dot-amber"
                pTooltip="Red - No record found
                          Amber - Possible match identified
                          Green - Exact record found"
                tooltipPosition="top"></span>
              </div>
              <div *ngIf="(!business?.responseData?.businessNonRegisteredProfileResult && !business?.responseData?.creditsafeResult) && (business?.responseData?.businessTargeterResult?.SearchResults || business?.responseData?.businessTargeterResult?.SearchResults?.length == 0)">
                <span class="ml-3 dot dot-red"
                pTooltip="Red - No record found
                          Amber - Possible match identified
                          Green - Exact record found"
                tooltipPosition="top"></span>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-md-6 p-0 text-right">
        Status
      </div>
      <div class="col-md-6 p-0 text-left">
        <div>
          <div class="loadingImg" *ngIf="business.isLoading">
            <img src="{{messageService.loadingAnimation}}" />
          </div>

          <div *ngIf="!business.isLoading && business?.responseData">
            <!-- Limited Company -->
            <div *ngIf="isLimitedCompany()">
              <div *ngIf="business?.responseData?.creditsafeResult?.report?.companySummary?.companyStatus?.status == 'NonActive' ||
                  business?.responseData?.creditsafeResult?.report?.companySummary?.companyStatus?.status == 'Pending' ||
                  business?.responseData?.creditsafeResult?.report?.companySummary?.mainActivity?.description == 'Dormant Company'">
                <span class="ml-3 dot dot-red" pTooltip="This flag only applies to incorporated company status:
                Red - Status is not active
                Green - Status is active" tooltipPosition="top"></span>
              </div>
              <div *ngIf="(business?.responseData?.creditsafeResult?.report?.companySummary?.companyStatus?.status == 'Active')">
                <span class="ml-3 dot dot-green" pTooltip="This flag only applies to incorporated company status:
                Red - Status is not active
                Green - Status is active" tooltipPosition="top"></span>
              </div>
              <div *ngIf="(business?.responseData?.creditsafeResult?.report?.companySummary?.companyStatus?.status != 'Active' &&
                   business?.responseData?.creditsafeResult?.report?.companySummary?.companyStatus?.status != 'NonActive' &&
                   business?.responseData?.creditsafeResult?.report?.companySummary?.companyStatus?.status != 'Pending' &&
                   business?.responseData?.creditsafeResult?.report?.companySummary?.mainActivity?.description != 'Dormant Company')">
                <span class="ml-3 dot dot-amber"></span>
              </div>
            </div>

            <!-- Non Limited Company -->
            <div *ngIf="isNonLimitedCompany()">
              <span class="ml-3 dot dot-white"></span>
            </div>

          </div>
        </div>
      </div>
    </div>

  </div>
</div>
