import { Component, Input, OnInit } from '@angular/core';
import { BusinessResultModel } from '../../../../models/BusinessResultModel';

@Component({
  selector: 'app-ltd-companyhistory-financials-business',
  templateUrl: './ltd-companyhistory-financials-business.component.html',
  styleUrls: ['./ltd-companyhistory-financials-business.component.css']
})
export class LtdCompanyhistoryFinancialsBusinessComponent implements OnInit {

  @Input() public business: BusinessResultModel = new BusinessResultModel();

  constructor() { }

  ngOnInit(): void {
  }

}
