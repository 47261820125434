export class NewClient {
  constructor(
    public userId: string,
    public firstName: string,
    public lastName: string,
    public department: string,
    public email: string,
    public password: string,
    public licenceId:number,
    public licenceName: string,
    public isAdmin:boolean,
    public isActive: boolean,
    public isDeleted: boolean,
    public emailConfirmed:boolean
  ) { }
}
