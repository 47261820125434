import { Component, OnInit, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../../service/login.service';

@Component({
  selector: 'app-thank-you-messages',
  templateUrl: './thank-you-messages.component.html',
  styleUrls: ['./thank-you-messages.component.css']
})
export class ThankYouMessagesComponent implements OnInit {

  public status: string;
  public registration: boolean;
  public confirm: boolean;
  public linkexpired: boolean;
  public forgotPassword: boolean;
  public resetPassword: boolean;
  public verificationlinkResent: boolean;
  public contactdetails: boolean;
  public confirmationError: boolean;
  public adduser: boolean;
  public alreadyConfirmed: boolean;

  constructor(
    private loginService: LoginService,
    private route: ActivatedRoute,
    private router: Router,
    private elementRef: ElementRef) { }
  ngAfterViewInit() {
    this.elementRef.nativeElement.ownerDocument.body.style.background = 'url(/assets/Images/LoginBackground.svg)';
  }

  ngOnInit() {
    this.status = "";
    this.registration = false;
    this.confirm = false;
    this.linkexpired = false;
    this.forgotPassword = false;
    this.resetPassword = false;
    this.verificationlinkResent = false;
    this.contactdetails = false;
    this.confirmationError = false;
    this.adduser = false;
    this.alreadyConfirmed = false;
    this.status = this.route.snapshot.queryParams.status;
    if (this.status == "registration") {
      this.registration = true;
    }
    else if (this.status == "confirm") {
      this.confirm = true;
    }
    else if (this.status == "linkexpired") {
      this.linkexpired = true;
    }
    else if (this.status == "forgotPassword") {
      this.forgotPassword = true;
    }
    else if (this.status == "resetPassword") {
      this.resetPassword = true;
    }
    else if (this.status == "verificationlinkResent") {
      this.verificationlinkResent = true;
    }
    else if (this.status == "contactdetails") {
      this.contactdetails = true;
    }
    else if (this.status == "adduser") {
      this.adduser = true;
    }
    else if (this.status == "alreadyConfirmed") {
      this.alreadyConfirmed = true;
    }
    else {
      this.confirmationError = true;
    }
  }
}
