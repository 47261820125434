import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PDFExportComponent } from '@progress/kendo-angular-pdf-export';
import { ConfirmationService } from 'primeng/api';
import { MessagingService } from '../../../services/messaging.service';
import { SearchService } from '../../../services/search.service';
import { Shared } from '../../../services/shared';
import { ProspectResultModel } from '../../models/CounterFraudResultModel';
import { Item } from '../../models/Items';
import { MobileResultModel } from '../../models/MobileResultModel';
import { AccessData, ProfileData, RequestData } from '../../models/SearchRequestModel';
import { SearchType } from '../../models/SearchType';
import { AddressResultModel } from '../../models/AddressResultModel';
import { TenantRegion } from '../../models/TenantRegionResposne';
import { isPossiblePhoneNumber, parsePhoneNumber, isValidPhoneNumber, validatePhoneNumberLength } from 'libphonenumber-js';

@Component({
  selector: 'app-mobile',
  templateUrl: './mobile.component.html',
  styleUrls: ['./mobile.component.css', '../shared/shared.searchpage.css']
})
export class MobileComponent implements OnInit {
  public regionAccess: TenantRegion = new TenantRegion();
  public mobileForm: FormGroup;
  public UKvalidation: any = {
    'reference': [Validators.required],
    'mobile': [Validators.required, Validators.pattern('^[0-9]{11}$')]
  };
  public AUSvalidation: any = {
    'reference': [Validators.required],
    'mobile': [Validators.required, Validators.pattern('^[0-9]{10}$')]
  };
  public accessData: AccessData = new AccessData();
  public showResultTab: boolean = false;
  public saveAsPDFButtonVisibility: boolean = true;
  public clearSearchButtonVisibility: boolean = true;
  public loggedInUsername: string = "";

  public mobileResult: MobileResultModel = new MobileResultModel();
  public opensourceResult: MobileResultModel = new MobileResultModel();
  public prospectResultModel: ProspectResultModel = new ProspectResultModel();
  public addressResultModel: AddressResultModel = new AddressResultModel();

  // PDF
  public requestDataModel: RequestData = new RequestData();
  @ViewChild('pdf') pdf: PDFExportComponent;

  constructor(private elementRef: ElementRef,
    private formBuilder: FormBuilder,
    private searchService: SearchService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private messageService: MessagingService) { }

  ngOnInit(): void {
    let isLoggedIn = localStorage.getItem("access_token") ? true : false;
    if (!isLoggedIn)
      this.router.navigate(['/Login']);
    this.saveAsPDFButtonVisibility = false;
    this.clearSearchButtonVisibility = false;
    this.searchService.GetAllowedSearchAvailablityItems().subscribe(data => {
      let keys = Array.from(data).map(x => x.key);
      if (!keys.find(x => x == "MOBILE")) {
        this.router.navigate(['/v3']);
      }
    }, error => {
      console.log(error);
    })

    this.mobileForm = this.formBuilder.group({
      audit: [""],
      reference: [""],
      mobile: [""]
    });
    this.getTenantRegion();
    this.CheckProspectHitAndProfileAccess();
    this.getLicenceItems();
    this.getUserDetails();
  }

  ngAfterViewInit() {
    this.elementRef.nativeElement.ownerDocument.body.style.background = 'url(/assets/Images/new/Background/HUT-elem-bg-1.png)';
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundAttachment = 'fixed';
  }

  public mobileSearch(): void {
    this.showResultTab = false;
    this.resetResultObjects();

    this.addValidators();
    this.mobileForm.updateValueAndValidity();

    if (this.mobileForm.valid) {
      Promise.resolve(this.getRandomString(10)).then(
        (data) => {

          this.saveAsPDFButtonVisibility = true;
          this.clearSearchButtonVisibility = true;

          this.showResultTab = true;
          var requestObj: RequestData = this.prepareRequestData();

          // Mobile search
          this.mobileSearchScreen(requestObj);

          // // Opensource search
           this.openSourceMobileSearch(requestObj);

           if (this.prospectResultModel.isProspectHitAccess && !this.regionAccess.isAURegion) {
             // Prospect Hit Check
             this.prospectHitCheck(requestObj);
           }

           // Mobile Address
           if (!this.regionAccess.isAURegion)
             this.MobileAddressSearch(requestObj);

        }
      );
    }
  }

  public getTenantRegion() {
    this.searchService.GetTenantRegion().subscribe(data => {
      this.regionAccess = data;
    }, error => {
      console.log(error);
    });
  }

  public generatePDF() {
    var requestObj: RequestData = this.prepareRequestData();
    this.requestDataModel = requestObj;

    setTimeout(() => {
      this.pdf.forcePageBreak = ".page-break"
      this.pdf.keepTogether = ".keep-together";
      this.pdf.paperSize = "A4";
      const margin = {
        top: '2cm',
        bottom: '3cm',
        left: '1cm',
        right: '1cm'
      }
      this.pdf.margin = margin;
      this.pdf.scale = 0.5;
      this.pdf.saveAs('export.pdf');
    }, 2000);
  }

  public getUserDetails() {
    this.searchService.getUserDetails().subscribe(data => {
      this.loggedInUsername = data.username;
    }, error => {
      console.log(error);
    })
  }

  public MobileAddressSearch(requestObj: RequestData) {
    this.addressResultModel.validation = false;
    this.addressResultModel.searchFail = false;
    this.addressResultModel.isLoading = false;
    this.addressResultModel.responseData = null;
    this.addressResultModel.noSearchSelect = false;

    this.addressResultModel.isLoading = true;
    this.searchService.MobileLexisNexisReverseSearch(requestObj).subscribe(data => {
      this.addressResultModel.isLoading = false;
      this.addressResultModel.responseData = data;
    }, error => {
      this.addressResultModel.isLoading = false;
      this.addressResultModel.searchFail = true;
      console.log(error);
    })
  }

  // Check about the access of Prospect hit & Access API
  public CheckProspectHitAndProfileAccess() {
    this.prospectResultModel.isProspectHitAccess = false;
    this.prospectResultModel.isProspectProfileAccess = false;
    this.searchService.CheckProsectHitAndProfileAccessClient().subscribe(data => {
      this.prospectResultModel.isProspectHitAccess = data['isProspectHitEnabled'];
      this.prospectResultModel.isProspectProfileAccess = data['isProspectProfileEnabled'];
    });
  }

  // Prospect profile request from prospect result component
  public prospectProfileRequest() {
    var requestObj: RequestData = this.prepareRequestData();
    this.prospectProfileCheck(requestObj);
  }

  // Prospect profile check API call
  public prospectProfileCheck(requestObj: RequestData): void {
    if (!this.prospectResultModel.prospectProfileButtonDisable) {
      this.confirmationService.confirm({
        key: "ProspectProfileRequest",
        message: 'In the event of any green tick(s) below, full Prospect detail can be requested and this will be provided to the requestor via email within 2 working days.',
        header: 'Confirmation',
        icon: 'pi pi-check',
        accept: () => {
          if (this.prospectResultModel.prospectHitResponseData) {
            this.prospectResultModel.isProspectProfileLoading = true;
            this.prospectResultModel.prospectProfileResponseData = null;
            this.searchService.GetProspectProfileData(requestObj).subscribe((data) => {
              this.prospectResultModel.isProspectProfileLoading = false;
              this.prospectResultModel.prospectProfileResponseData = data;
              this.prospectResultModel.prospectProfileButtonText = this.messageService.prospectProfileButtonText_Requested;
              this.prospectResultModel.prospectProfileButtonDisable = true;
            }, (error) => {
              console.log(error);
              this.prospectResultModel.isProspectProfileLoading = false;
              this.prospectResultModel.prospectProfileButtonDisable = true;
            });
          }
        },
        reject: () => {
        }
      });
    }

  }


  // Prospect hit check API call
  public prospectHitCheck(requestObj: RequestData): void {
    this.prospectResultModel.noSearchSelect = false;
    if (requestObj.accessData.isCounterFraudAccess) {
      this.prospectResultModel.isProspectHitLoading = false;
      this.prospectResultModel.isProspectProfileLoading = false;
      this.prospectResultModel.validation = false;
      this.prospectResultModel.searchFail = false;
      this.prospectResultModel.prospectHitResponseData = null;
      this.prospectResultModel.prospectProfileResponseData = null;
      this.prospectResultModel.prospectProfileButtonDisable = false;
      this.prospectResultModel.prospectProfileButtonText = this.messageService.prospectProfileButtonText_RequestProfile;

      if ((requestObj.profileData.fullName && requestObj.profileData.dateOfBirth) ||
        (requestObj.profileData.postalCode && requestObj.profileData.addressLine1) ||
        requestObj.profileData.phoneNumber || requestObj.profileData.email || requestObj.profileData.vRN) {
        this.prospectResultModel.profileData = requestObj.profileData;
        this.prospectResultModel.isProspectHitLoading = true;
        this.searchService.GetProspectHitData(requestObj).subscribe((data) => {
          this.prospectResultModel.isProspectHitLoading = false;
          this.prospectResultModel.prospectHitResponseData = data;
          this.prospectResultModel.prospectProfileButtonDisable = this.prospectResultModel.prospectHitResponseData.hits.filter(x => x.i2Hit == true).length > 0 ? false : true;
        }, (error) => {
          this.prospectResultModel.isProspectHitLoading = false;
          this.prospectResultModel.searchFail = true;
          console.log(error);
        });
      } else {
        this.prospectResultModel.validation = true;
      }
    } else {
      this.prospectResultModel.noSearchSelect = true;
    }
  }


  public openSourceMobileSearch(requestObj: RequestData): void {
    this.opensourceResult.isLoading = false;
    this.opensourceResult.noSearchSelect = false;
    this.opensourceResult.responseData = null;
    this.opensourceResult.searchFail = false;
    this.opensourceResult.validationError = false;

    if (this.accessData.isMobileAccess) {
      if (this.mobileForm.get('mobile').value) {
        this.opensourceResult.isLoading = true;
        this.searchService.OpenSourceMobileSearch(requestObj).subscribe(data => {
          this.opensourceResult.isLoading = false;
          this.opensourceResult.responseData = data;
        }, error => {
          console.log(error);
          this.opensourceResult.isLoading = false;
          this.opensourceResult.searchFail = true;
        });
      } else {
        this.opensourceResult.validationError = true;
      }
    } else {
      this.opensourceResult.noSearchSelect = true;
    }
  }

  public mobileSearchScreen(requestObj: RequestData): void {
    this.mobileResult.isLoading = false;
    this.mobileResult.noSearchSelect = false;
    this.mobileResult.responseData = null;
    this.mobileResult.searchFail = false;
    this.mobileResult.validationError = false;

    if (this.accessData.isMobileAccess) {
      if (this.mobileForm.get('mobile').value) {
        this.mobileResult.isLoading = true;
        this.searchService.MobileSearchScreen(requestObj).subscribe(data => {
          this.mobileResult.isLoading = false;
          this.mobileResult.responseData = data;
        }, error => {
          console.log(error);
          this.mobileResult.isLoading = false;
          this.mobileResult.searchFail = true;
        });
      } else {
        this.mobileResult.validationError = true;
      }
    } else {
      this.mobileResult.noSearchSelect = true;
    }

  }

  public async getRandomString(length) {
    await this.searchService.GetAuditKey().then(
      data => {
        this.mobileForm.patchValue({
          audit: data
        });
      });
  }

  // Reset the result objects
  public resetResultObjects() {
    this.addressResultModel = new AddressResultModel();
    this.mobileResult = new MobileResultModel();
    this.opensourceResult = new MobileResultModel();
  }

  // Add the validations during the 'search' button click
  public addValidators(): void {
    for (const control in this.mobileForm.controls) {
      this.mobileForm.get(control).clearValidators();
      var validationControl = this.regionAccess.isAURegion ? this.AUSvalidation[control] : this.UKvalidation[control];
      this.mobileForm.get(control).setValidators(validationControl);
      this.mobileForm.get(control).updateValueAndValidity();
    }
  }

  // Clear the validations during the 'clear' button click
  // This'll remove all the error message
  public mobileClearSearch(): void {
    for (const control in this.mobileForm.controls) {
      this.mobileForm.get(control).clearValidators();
      this.mobileForm.get(control).updateValueAndValidity();
    }
    this.mobileForm.patchValue({
      audit: "",
      reference: "",
      mobile: ""
    });
    this.showResultTab = false;
    this.resetResultObjects();
    this.saveAsPDFButtonVisibility = false;
    this.clearSearchButtonVisibility = false;
  }

  // Prepare the request object for API call
  public prepareRequestData(): RequestData {
    // Profile object framing
    var profileData = new ProfileData();
    profileData.auditKey = this.mobileForm.get('audit').value;
    profileData.referenceNumber = this.mobileForm.get('reference').value;

    if (this.mobileForm.get('mobile').value) {
      profileData.phoneNumber = this.mobileForm.get('mobile').value;
      const phoneNumber = !this.regionAccess.isAURegion ? parsePhoneNumber(profileData.phoneNumber, 'GB') : parsePhoneNumber(profileData.phoneNumber, 'AU');
      if (phoneNumber.isValid() === true && isPossiblePhoneNumber(profileData.phoneNumber, 'GB') === true
        && isValidPhoneNumber(profileData.phoneNumber, 'GB') && validatePhoneNumberLength(profileData.phoneNumber, 'GB') === undefined) {
        profileData.phoneNumber = phoneNumber.formatInternational();
      } else if(phoneNumber.isValid() === true && isPossiblePhoneNumber(profileData.phoneNumber, 'AU') === true
      && isValidPhoneNumber(profileData.phoneNumber, 'AU') && validatePhoneNumberLength(profileData.phoneNumber, 'AU') === undefined){
        profileData.phoneNumber = phoneNumber.formatInternational();
      }
    }
    //profileData.phoneNumber = this.mobileForm.get('mobile').value;

    profileData.searchType = SearchType.Mobile.toString();
    profileData.searchDate = Shared.getCurrentDate();
    profileData.searchTime = Shared.getCurrentTime();
    profileData.searchUsername = this.loggedInUsername;
    // RequestData object framing
    var requestObj = new RequestData();
    requestObj.profileData = profileData;
    requestObj.accessData = this.accessData;
    return requestObj;
  }

  public getLicenceItems(): void {
    this.searchService.AllowedLicence().subscribe(data => {
      this.getSecondAccess(data);
    });
  }

  public getSecondAccess(Items: Item[]) {
    Items.forEach(item => {
      if (item.licenceType.toLowerCase() == "search") {
        switch ((item.key)) {
          case "HUTDB": {
            this.accessData.isHUTDatabaseAccess = item.defaultValue;
            this.accessData.isHUTDatabaseToggle = item.toggle;
            break;
          }
          case "MOBILE": {
            this.accessData.isMobileAccess = item.defaultValue;
            this.accessData.isMobileToggle = item.toggle;
            break;
          }
          case "EMAIL": {
            this.accessData.isEmailAccess = item.defaultValue;
            this.accessData.isEmailToggle = item.toggle;
            break;
          }
          case "IDENTITY": {
            this.accessData.isIdentityAccess = item.defaultValue;
            this.accessData.isIdentityToggle = item.toggle;
            break;
          }
          case "FINANCIAL": {
            this.accessData.isFinancialAccess = item.defaultValue;
            this.accessData.isFinancialToggle = item.toggle;
            break;
          }
          case "ADDRESS": {
            this.accessData.isAddressAccess = item.defaultValue;
            this.accessData.isAddressToggle = item.toggle;
            break;
          }
          case "CRIMINALITY": {
            this.accessData.isCriminalityAccess = item.defaultValue;
            this.accessData.isCriminalityToggle = item.toggle;
            break;
          }
          case "SOCIAL": {
            this.accessData.isSocialMediaAccess = item.defaultValue;
            this.accessData.isSocialMediaToggle = item.toggle;
            break;
          }
          case "COUNTER_FRAUD": {
            this.accessData.isCounterFraudAccess = item.defaultValue;
            this.accessData.isCounterFraudToggle = item.toggle;
            break;
          }
          case "SPORTS": {
            this.accessData.isSportsAccess = item.defaultValue;
            this.accessData.isSportsToggle = item.toggle;
            break;
          }
          case "ADVERT": {
            this.accessData.isAdvertsAccess = item.defaultValue;
            this.accessData.isAdvertsToggle = item.toggle;
            break;
          }
          case "COMPANY_HOUSE": {
            this.accessData.isCompaniesHouseAccess = item.defaultValue;
            this.accessData.isCompaniesHouseToggle = item.toggle;
            break;
          }
          case "AVA": {
            this.accessData.isAVAAccess = item.defaultValue;
            this.accessData.isAVAToggle = item.toggle;
            break;
          }
          case "AML": {
            this.accessData.isAMLAccess = item.defaultValue;
            this.accessData.isAMLToggle = item.toggle;
            break;
          }
          case "CCJ_Detail": {
            this.accessData.isCCJDetailAccess = item.defaultValue;
            this.accessData.isCCJDetailToggle = item.toggle;
            break;
          }
          case "Insolvency_Detail": {
            this.accessData.isInsolvencyDetailAccess = item.defaultValue;
            this.accessData.isInsolvencyDetailToggle = item.toggle;
            break;
          }
          case "RTW": {
            this.accessData.isRTWAccess = item.defaultValue;
            this.accessData.isRTWToggle = item.toggle;
            break;
          }
          case "IDCHECK": {
            this.accessData.isIDCheckAccess = item.defaultValue;
            this.accessData.isIDCheckToggle = item.toggle;
            break;
          }
        }
      }
    });
  }

  public onMobileChange() {
    this.mobileForm.get('mobile').clearValidators();
    var mobileValidation = this.regionAccess.isAURegion ? this.AUSvalidation['mobile'] : this.UKvalidation['mobile'];
    this.mobileForm.get('mobile').setValidators(mobileValidation);
    this.mobileForm.get('mobile').updateValueAndValidity();
  }

}
