<div class="app-outer-container page-background">
  <div class="container">
    <div class="row">
      <div class="col-md-3">
      </div>
      <div class="col-md-6">
        <div class="card card-body card-radius">
          <h2 class="headingalign text-center">Email verification link</h2>
          <form name="form" (ngSubmit)="f.form.valid && ResendLink()" #f="ngForm">
            <div class="form-group row">
              <div class="col-md-12 searchtextAlign">
                <p><small>Enter your email address below to resend email verification link.</small></p>
              </div>
              <div class="col-md-12">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fa fa-envelope" aria-hidden="true"></i></span>
                  </div>
                  <input type="text" [disabled]="loading" class="form-control" name="email" placeholder="Email address" [(ngModel)]="resendlink.email"
                         #email="ngModel" [ngClass]="{ 'is-invalid': f.submitted && email.invalid }" email autocomplete="off"
                         (keydown.space)="$event.preventDefault()" required />
                  <div *ngIf="f.submitted && email.invalid" class="invalid-feedback">
                    <div *ngIf="email.errors.required" class="leftpadding">Email is required</div>
                    <div *ngIf="email.errors.email" class="leftpadding">Email must be a valid email address</div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div class="form-group row">
              <div class="col-md-12">
                <button type="submit" [disabled]="loading" class="btn round-button red-grad-button buttonMargin">Send link</button>
                <span *ngIf="loading" class="rightalign">
                  <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                </span>
              </div>
              <div class="col-md-12">
                <p class="alert alert-danger" *ngIf="validationerror">
                  <span><small>{{validationerror}}</small></span>
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="col-md-3">
      </div>
    </div>
  </div>
</div>
