import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PDFExportComponent } from '@progress/kendo-angular-pdf-export';
import { ConfirmationService } from 'primeng/api';
import { AccessData, ProfileData, RequestData } from '../../../models/SearchRequestModel';
import { AVAResultModel } from '../../../models/AVAResultModel';
import { DVLAResultModel, MotHistoryResultModel } from '../../../models/DVLAResultModel';
import { ProspectResultModel } from '../../../models/CounterFraudResultModel';
import { ExperianResultModel } from '../../../models/ExperianResultModel';
import { MessagingService } from '../../../../services/messaging.service';
import { SearchService } from '../../../../services/search.service';
import { Shared } from '../../../../services/shared';
import { SearchType } from '../../../models/SearchType';
import { Item } from '../../../models/Items';

@Component({
  selector: 'app-uk-motor',
  templateUrl: './uk-motor.component.html',
  styleUrls: ['./uk-motor.component.css', '../../shared/shared.searchpage.css']
})
export class UkMotorComponent implements OnInit {

  private vrnValidationPattern: string = "^(([A-Za-z]{1,2}[ ]?[0-9]{1,4})|([A-Za-z]{3}[ ]?[0-9]{1,3})|([0-9]{1,3}[ ]?[A-Za-z]{3})|([0-9]{1,4}[ ]?[A-Za-z]{1,2})|([A-Za-z]{3}[ ]?[0-9]{1,3}[ ]?[A-Za-z])|([A-Za-z][ ]?[0-9]{1,3}[ ]?[A-Za-z]{3})|([A-Za-z]{2}[ ]?[0-9]{2}[ ]?[A-Za-z]{3})|([A-Za-z]{3}[ ]?[0-9]{4})|([0-9]{2,3}-{1}[A-Z]{1,2}-?[0-9]{1,6}))$";

  public motorForm: FormGroup;
  public validation: any = {
    'reference': [Validators.required],
    'vrn': [Validators.required, Validators.pattern(this.vrnValidationPattern)]
  };
  public accessData: AccessData = new AccessData();
  public showResultTab: boolean = false;
  public isExperianTabShow: boolean = false;
  public saveAsPDFButtonVisibility: boolean = true;
  public clearSearchButtonVisibility: boolean = true;
  public loggedInUsername: string = "";

  public avaResultModel: AVAResultModel = new AVAResultModel();
  public dvlaResultModel: DVLAResultModel = new DVLAResultModel();
  public prospectResultModel: ProspectResultModel = new ProspectResultModel();
  public experianResultModel: ExperianResultModel = new ExperianResultModel();
  public motHistoryResultModel: MotHistoryResultModel = new MotHistoryResultModel();

  // PDF
  public requestDataModel: RequestData = new RequestData();
  @ViewChild('pdf') pdf: PDFExportComponent;

  constructor(private elementRef: ElementRef,
    private formBuilder: FormBuilder,
    private searchService: SearchService,
    private messageService: MessagingService,
    private router: Router,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit(): void {
    let isLoggedIn = localStorage.getItem("access_token") ? true : false;
    if (!isLoggedIn)
      this.router.navigate(['/Login']);
    this.saveAsPDFButtonVisibility = false;
    this.clearSearchButtonVisibility = false;
    this.searchService.GetAllowedSearchAvailablityItems().subscribe(data => {
      let keys = Array.from(data).map(x => x.key);
      if (!keys.find(x => x == "MOTOR")) {
        this.router.navigate(['/v3']);
      }
    }, error => {
      console.log(error);
    })

    this.motorForm = this.formBuilder.group({
      audit: [""],
      reference: [""],
      vrn: [""],
      avaAccess: [false]
    });
    this.CheckProspectHitAndProfileAccess();
    this.getLicenceItems();
    this.getUserDetails();
  }

  ngAfterViewInit() {
    this.elementRef.nativeElement.ownerDocument.body.style.background = 'url(/assets/Images/new/Background/HUT-elem-bg-1.png)';
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundAttachment = 'fixed';
  }

  public motorSearch(): void {
    this.addValidators();
    this.motorForm.updateValueAndValidity();

    if (this.motorForm.valid) {

      Promise.resolve(this.getRandomString(10)).then(
        (data) => {
          this.saveAsPDFButtonVisibility = true;
          this.clearSearchButtonVisibility = true;
          this.resetResultObjects();

          this.showResultTab = true;
          var requestObj: RequestData = this.prepareRequestData();

          if (requestObj.accessData.isAVAAccess) {
            this.isExperianTabShow = false;
          } else {
            this.isExperianTabShow = true;
          }

          // AVA Search
          this.avaCheck(requestObj);

          // DVLA Motor Check
          this.dvlaMotorCheck(requestObj);

          if (this.prospectResultModel.isProspectHitAccess) {
            // Prospect Hit Check
            this.prospectHitCheck(requestObj);
          }

          // Experian Search
          this.experianMotor(requestObj);

          // MOT History
          this.motHistory(requestObj);
        }
      );

    }
  }

  public generatePDF() {
    var requestObj: RequestData = this.prepareRequestData();
    this.requestDataModel = requestObj;

    setTimeout(() => {
      this.pdf.forcePageBreak = ".page-break"
      this.pdf.keepTogether = ".keep-together";
      this.pdf.paperSize = "A4";
      const margin = {
        top: '2cm',
        bottom: '3cm',
        left: '1cm',
        right: '1cm'
      }
      this.pdf.margin = margin;
      this.pdf.scale = 0.5;
      this.pdf.saveAs('export.pdf');
    }, 5000);
  }

  public getUserDetails() {
    this.searchService.getUserDetails().subscribe(data => {
      this.loggedInUsername = data.username;
    }, error => {
      console.log(error);
    })
  }

  // Get MOT History Data
  public motHistory(requestData: RequestData) {
    this.motHistoryResultModel.isLoading = false;
    this.motHistoryResultModel.noRelevantData = false;
    this.motHistoryResultModel.responseData = null;
    this.motHistoryResultModel.searchFail = false;

    this.motHistoryResultModel.isLoading = true;
    this.searchService.MotHistory(requestData).subscribe(data => {
      this.motHistoryResultModel.isLoading = false;
      if (data["status"] == 200) {
        let result = JSON.parse(data["data"]);
        if (result.length > 0) {
          this.motHistoryResultModel.responseData = result[0];
        } else {
          this.motHistoryResultModel.noRelevantData = true;
        }
      } else {
        this.motHistoryResultModel.noRelevantData = true;
      }
    }, error => {
      console.log(error);
      this.motHistoryResultModel.isLoading = false;
      this.motHistoryResultModel.searchFail = true;
    })
  }

  // Check about the access of Prospect hit & Access API
  public CheckProspectHitAndProfileAccess() {
    this.prospectResultModel.isProspectHitAccess = false;
    this.prospectResultModel.isProspectProfileAccess = false;
    this.searchService.CheckProsectHitAndProfileAccessClient().subscribe(data => {
      this.prospectResultModel.isProspectHitAccess = data['isProspectHitEnabled'];
      this.prospectResultModel.isProspectProfileAccess = data['isProspectProfileEnabled'];
    });
  }

  // Prospect profile request from prospect result component
  public prospectProfileRequest() {
    var requestObj: RequestData = this.prepareRequestData();
    this.prospectProfileCheck(requestObj);
  }

  // Prospect profile check API call
  public prospectProfileCheck(requestObj: RequestData): void {
    if (!this.prospectResultModel.prospectProfileButtonDisable) {
      this.confirmationService.confirm({
        key: "ProspectProfileRequest",
        message: 'In the event of any green tick(s) below, full Prospect detail can be requested and this will be provided to the requestor via email within 2 working days.',
        header: 'Confirmation',
        icon: 'pi pi-check',
        accept: () => {
          if (this.prospectResultModel.prospectHitResponseData) {
            this.prospectResultModel.isProspectProfileLoading = true;
            this.prospectResultModel.prospectProfileResponseData = null;
            this.searchService.GetProspectProfileData(requestObj).subscribe((data) => {
              this.prospectResultModel.isProspectProfileLoading = false;
              this.prospectResultModel.prospectProfileResponseData = data;
              this.prospectResultModel.prospectProfileButtonText = this.messageService.prospectProfileButtonText_Requested;
              this.prospectResultModel.prospectProfileButtonDisable = true;
            }, (error) => {
              console.log(error);
              this.prospectResultModel.isProspectProfileLoading = false;
              this.prospectResultModel.prospectProfileButtonDisable = true;
            });
          }
        },
        reject: () => {
        }
      });
    }

  }


  // Prospect hit check API call
  public prospectHitCheck(requestObj: RequestData): void {
    this.prospectResultModel.noSearchSelect = false;
    if (requestObj.accessData.isCounterFraudAccess) {
      this.prospectResultModel.isProspectHitLoading = false;
      this.prospectResultModel.isProspectProfileLoading = false;
      this.prospectResultModel.validation = false;
      this.prospectResultModel.searchFail = false;
      this.prospectResultModel.prospectHitResponseData = null;
      this.prospectResultModel.prospectProfileResponseData = null;
      this.prospectResultModel.prospectProfileButtonDisable = false;
      this.prospectResultModel.prospectProfileButtonText = this.messageService.prospectProfileButtonText_RequestProfile;

      if ((requestObj.profileData.fullName && requestObj.profileData.dateOfBirth) ||
        (requestObj.profileData.postalCode && requestObj.profileData.addressLine1) ||
        requestObj.profileData.phoneNumber || requestObj.profileData.email || requestObj.profileData.vRN) {
        this.prospectResultModel.profileData = requestObj.profileData;
        this.prospectResultModel.isProspectHitLoading = true;
        this.searchService.GetProspectHitData(requestObj).subscribe((data) => {
          this.prospectResultModel.isProspectHitLoading = false;
          this.prospectResultModel.prospectHitResponseData = data;
          this.prospectResultModel.prospectProfileButtonDisable = this.prospectResultModel.prospectHitResponseData.hits.filter(x => x.i2Hit == true).length > 0 ? false : true;
        }, (error) => {
          this.prospectResultModel.isProspectHitLoading = false;
          this.prospectResultModel.searchFail = true;
          console.log(error);
        });
      } else {
        this.prospectResultModel.validation = true;
      }
    } else {
      this.prospectResultModel.noSearchSelect = true;
    }
  }

  // Experian request
  public experianMotor(requestObj: RequestData): void {
    this.experianResultModel.isLoading = false;
    this.experianResultModel.noRelevantData = false;
    this.experianResultModel.noSearchSelect = false;
    this.experianResultModel.responseData = null;
    this.experianResultModel.searchFail = false;

    if (!requestObj.accessData.isAVAAccess) {
      this.experianResultModel.isLoading = true;
      this.searchService.ExperianMotor(requestObj).subscribe(data => {
        this.experianResultModel.isLoading = false;
        this.experianResultModel.responseData = JSON.parse(data["data"]);
        if (!this.experianResultModel.responseData) {
          this.experianResultModel.noRelevantData = true;
        }
      }, error => {
        this.experianResultModel.isLoading = false;
        this.experianResultModel.searchFail = false;
        console.log(error);
      });
    }

  }

  // DVLA Motor request
  public dvlaMotorCheck(requestObj: RequestData) {
    this.dvlaResultModel.isLoading = false;
    this.dvlaResultModel.noRelevantData = false;
    this.dvlaResultModel.responseData = null;
    this.dvlaResultModel.searchFail = false;

    this.dvlaResultModel.isLoading = true;
    this.searchService.DVLAMotor(requestObj).subscribe(data => {
      this.dvlaResultModel.isLoading = false;
      if (data.status == 200) {
        this.dvlaResultModel.responseData = JSON.parse(data.data);
      } else {
        this.dvlaResultModel.noRelevantData = true;
        this.dvlaResultModel.responseData = null;
      }
    }, error => {
      this.dvlaResultModel.isLoading = false;
      this.dvlaResultModel.searchFail = true;
    })
  }

  // AVA check API call
  public avaCheck(requestObj: RequestData): void {
    this.avaResultModel.isLoading = false;
    this.avaResultModel.noRelevantData = false;
    this.avaResultModel.responseData = null;
    this.avaResultModel.searchFail = false;
    this.avaResultModel.noSearchSelect = false;

    if (this.accessData.isAVAAccess) {
      this.avaResultModel.isLoading = true;
      this.searchService.SearchAVA(requestObj).subscribe((data) => {
        this.avaResultModel.isLoading = false;
        if (data) {
          this.avaResultModel.responseData = data;
          this.avaResultModel.responseData.mot = JSON.parse(this.avaResultModel.responseData.mot);
          this.avaResultModel.responseData.vehicleData = JSON.parse(this.avaResultModel.responseData.vehicleData);
        } else {
          this.avaResultModel.noRelevantData = true;
        }
      }, (error) => {
        this.avaResultModel.isLoading = false;
        this.avaResultModel.searchFail = true;
        console.log(error);
      })
    } else {
      this.avaResultModel.noSearchSelect = true;
    }

  }

  public async getRandomString(length) {
    await this.searchService.GetAuditKey().then(
      data => {
        this.motorForm.patchValue({
          audit: data
        });
      });
  }

  // Add the validations during the 'search' button click
  public addValidators(): void {
    for (const control in this.motorForm.controls) {
      this.motorForm.get(control).clearValidators();
      this.motorForm.get(control).setValidators(this.validation[control]);
      this.motorForm.get(control).updateValueAndValidity();
    }
  }

  // Clear the validations during the 'clear' button click
  // This'll remove all the error message
  public motorClearSearch(): void {
    //this.motorForm.reset();
    this.motorForm.patchValue({
      reference: "",
      vrn: "",
      audit: ""
    });
    for (const control in this.motorForm.controls) {
      this.motorForm.get(control).clearValidators();
      this.motorForm.get(control).updateValueAndValidity();
    }
    this.showResultTab = false;
    this.resetResultObjects();
    this.saveAsPDFButtonVisibility = false;
    this.clearSearchButtonVisibility = false;
  }

  // Reset Object
  public resetResultObjects() {
    this.avaResultModel = new AVAResultModel();
    this.dvlaResultModel = new DVLAResultModel();
    this.experianResultModel = new ExperianResultModel();
    this.motHistoryResultModel = new MotHistoryResultModel();

    this.prospectResultModel.isProspectHitLoading = false;
    this.prospectResultModel.isProspectProfileLoading = false;
    this.prospectResultModel.validation = false;
    this.prospectResultModel.searchFail = false;
    this.prospectResultModel.prospectHitResponseData = null;
    this.prospectResultModel.prospectProfileResponseData = null;
    this.prospectResultModel.prospectProfileButtonDisable = false;
    this.prospectResultModel.prospectProfileButtonText = "";
  }

  // Prepare the request object for API call
  public prepareRequestData(): RequestData {
    // Profile object framing
    var profileData = new ProfileData();
    profileData.auditKey = this.motorForm.get('audit').value;
    profileData.referenceNumber = this.motorForm.get('reference').value;
    profileData.vRN = this.motorForm.get('vrn').value;

    profileData.searchType = SearchType.Motor.toString();
    profileData.searchDate = Shared.getCurrentDate();
    profileData.searchTime = Shared.getCurrentTime();
    profileData.searchUsername = this.loggedInUsername;

    // Access object framing
    var accessData = new AccessData();
    accessData = this.accessData;
    accessData.isAVAAccess = this.motorForm.get('avaAccess').value;

    // RequestData object framing
    var requestObj = new RequestData();
    requestObj.profileData = profileData;
    requestObj.accessData = accessData;
    return requestObj;
  }

  public disableAccessData(): void {
    this.motorForm.patchValue({
      avaAccess: this.accessData.isAVAAccess
    });

    if (!this.accessData.isAVAToggle) {
      this.motorForm.get("avaAccess").disable();
    }

  }

  public getLicenceItems(): void {
    this.searchService.AllowedLicence().subscribe(data => {
      this.getSecondAccess(data);
      this.disableAccessData();
    });
  }

  public getSecondAccess(Items: Item[]) {
    Items.forEach(item => {
      if (item.licenceType.toLowerCase() == "search") {
        switch ((item.key)) {
          case "HUTDB": {
            this.accessData.isHUTDatabaseAccess = item.defaultValue;
            this.accessData.isHUTDatabaseToggle = item.toggle;
            break;
          }
          case "MOBILE": {
            this.accessData.isMobileAccess = item.defaultValue;
            this.accessData.isMobileToggle = item.toggle;
            break;
          }
          case "EMAIL": {
            this.accessData.isEmailAccess = item.defaultValue;
            this.accessData.isEmailToggle = item.toggle;
            break;
          }
          case "IDENTITY": {
            this.accessData.isIdentityAccess = item.defaultValue;
            this.accessData.isIdentityToggle = item.toggle;
            break;
          }
          case "FINANCIAL": {
            this.accessData.isFinancialAccess = item.defaultValue;
            this.accessData.isFinancialToggle = item.toggle;
            break;
          }
          case "ADDRESS": {
            this.accessData.isAddressAccess = item.defaultValue;
            this.accessData.isAddressToggle = item.toggle;
            break;
          }
          case "CRIMINALITY": {
            this.accessData.isCriminalityAccess = item.defaultValue;
            this.accessData.isCriminalityToggle = item.toggle;
            break;
          }
          case "SOCIAL": {
            this.accessData.isSocialMediaAccess = item.defaultValue;
            this.accessData.isSocialMediaToggle = item.toggle;
            break;
          }
          case "COUNTER_FRAUD": {
            this.accessData.isCounterFraudAccess = item.defaultValue;
            this.accessData.isCounterFraudToggle = item.toggle;
            break;
          }
          case "SPORTS": {
            this.accessData.isSportsAccess = item.defaultValue;
            this.accessData.isSportsToggle = item.toggle;
            break;
          }
          case "ADVERT": {
            this.accessData.isAdvertsAccess = item.defaultValue;
            this.accessData.isAdvertsToggle = item.toggle;
            break;
          }
          case "COMPANY_HOUSE": {
            this.accessData.isCompaniesHouseAccess = item.defaultValue;
            this.accessData.isCompaniesHouseToggle = item.toggle;
            break;
          }
          case "AVA": {
            this.accessData.isAVAAccess = item.defaultValue;
            this.accessData.isAVAToggle = item.toggle;
            break;
          }
          case "AML": {
            this.accessData.isAMLAccess = item.defaultValue;
            this.accessData.isAMLToggle = item.toggle;
            break;
          }
          case "CCJ_Detail": {
            this.accessData.isCCJDetailAccess = item.defaultValue;
            this.accessData.isCCJDetailToggle = item.toggle;
            break;
          }
          case "Insolvency_Detail": {
            this.accessData.isInsolvencyDetailAccess = item.defaultValue;
            this.accessData.isInsolvencyDetailToggle = item.toggle;
            break;
          }
          case "RTW": {
            this.accessData.isRTWAccess = item.defaultValue;
            this.accessData.isRTWToggle = item.toggle;
            break;
          }
          case "IDCHECK": {
            this.accessData.isIDCheckAccess = item.defaultValue;
            this.accessData.isIDCheckToggle = item.toggle;
            break;
          }
        }
      }
    });
  }

}
