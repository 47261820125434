<div class="container">
  <div>
    <div class="col-md-12" *ngIf="!vatResult.isLoading && !vatResult.validation && vatResult.noRelevantData && vatResult.searchFail"><i class="color-grey float-right">{{messageService.SearchFail}}</i></div>
    <div class="col-md-12" *ngIf="!vatResult.isLoading && !vatResult.validation && vatResult.noRelevantData && !vatResult.searchFail"><i class="color-grey float-right">{{messageService.NoRelevantDataMessage}}</i></div>
    <div class="col-md-12" *ngIf="!vatResult.isLoading && vatResult.validation && !vatResult.noRelevantData && !vatResult.searchFail"><i class="color-grey float-right">{{messageService.VatRequiredMessage}}</i></div>
  </div>

  <div class="loadingImg" *ngIf="vatResult.isLoading">
    <img src="{{messageService.loadingAnimation}}" />
  </div>

  <div *ngIf="vatResult.responseData">
    <div><b><u>VAT Check:</u></b></div>
    <div class="row" *ngIf="vatResult.responseData?.target?.name">
      <div class="col-md-4">Name:</div>
      <div class="col-md-8">{{vatResult.responseData?.target?.name}}</div>
    </div>
    <div class="row" *ngIf="vatResult.responseData?.target?.vatNumber">
      <div class="col-md-4">VAT Number:</div>
      <div class="col-md-8">{{vatResult.responseData?.target?.vatNumber}}</div>
    </div>
    <div class="row" *ngIf="vatResult.responseData?.target?.address?.line1">
      <div class="col-md-4">Address Line 1:</div>
      <div class="col-md-8">{{vatResult.responseData?.target?.address?.line1}}</div>
    </div>
    <div class="row" *ngIf="vatResult.responseData?.target?.address?.line2">
      <div class="col-md-4">Address Line 2:</div>
      <div class="col-md-8">{{vatResult.responseData?.target?.address?.line2}}</div>
    </div>
    <div class="row" *ngIf="vatResult.responseData?.target?.address?.line3">
      <div class="col-md-4">Address Line 3:</div>
      <div class="col-md-8">{{vatResult.responseData?.target?.address?.line3}}</div>
    </div>
    <div class="row" *ngIf="vatResult.responseData?.target?.address?.line4">
      <div class="col-md-4">Address Line 4:</div>
      <div class="col-md-8">{{vatResult.responseData?.target?.address?.line4}}</div>
    </div>
    <div class="row" *ngIf="vatResult.responseData?.target?.address?.postcode">
      <div class="col-md-4">Postcode:</div>
      <div class="col-md-8">{{vatResult.responseData?.target?.address?.postcode}}</div>
    </div>
    <div class="row" *ngIf="vatResult.responseData?.target?.address?.countryCode">
      <div class="col-md-4">Country Code:</div>
      <div class="col-md-8">{{vatResult.responseData?.target?.address?.countryCode}}</div>
    </div>
  </div>

</div>
