import { MessageService } from 'primeng/api';
import { SubscriptionService } from 'src/app/subscription/service/subscription.service';
import { PlanExpiry } from './../../subscription/Models/PlanExpiry';
import { Component, OnInit, HostListener, ViewChild, ElementRef, TemplateRef, Renderer2 } from '@angular/core';
import { ScreenSearchData, ScreenType } from '../Models/ScreenSearchData';
import { ScreenSearchDataService } from '../service/screen-search-data.service';
import { CSVRecords, ScreenInputData } from '../Models/CSVRecords';
import { AppComponent } from '../../app.component';
import { SearchService } from 'src/app/home/services/search.service';
import { interval, Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { SubscriptionPlan } from '../../subscription/Models/SubscriptionPlan';
import { parsePhoneNumber, isPossiblePhoneNumber, isValidPhoneNumber, validatePhoneNumberLength } from 'libphonenumber-js'


@Component({
  selector: 'app-screen-search-data',
  templateUrl: './screen-search-data.component.html',
  styleUrls: ['./screen-search-data.component.css'],
  providers: [ScreenSearchDataService, MessageService]
})
export class ScreenSearchDataComponent implements OnInit {
  public licenceError: string = null;
  public screenSearchData: ScreenSearchData[];
  public file: File = null;
  public tempFile: any = null;
  public filename = "Choose File";
  public eventTarget: any;
  public tempEventTarget: any;
  public validationerror: String = "";
  public successMessage: String = "";
  public download: boolean[];
  public records: any[];
  public commercialTemplateURL: string;
  public personalTemplateURL: string;
  public mobileTemplateURL: string;
  public allowedTemplates: string[];

  //PlanExpiry
  public planExpiry: PlanExpiry = null;
  public isPlanExpired: boolean = false;
  //public PlanDetails: SubscriptionPlan;
  public PlanExpiryMessage: string = null;
  public ToastWarningHeader: string = null;
  public loadScreenIntervalCall: any;
  public expiryIntervalCall: any;

  // Header positions
  public referenceNumberColumn: number = null;
  public businessNameColumn: number = null;
  public ltdNumberColumn: number = null;
  public businessPostcodeColumn: number = null;
  public principalNameColumn: number = null;
  public principalPostcodeColumn: number = null;
  public principalMobileColumn: number = null;
  public principalEmailColumn: number = null;
  public principalDobColumn: number = null;
  public lossDateColumn: number = null;

  @ViewChild('alert') alert: ElementRef;
  @ViewChild('file') fileInput: ElementRef;
  @HostListener('window:scroll', ['$event'])
  scrollTrigger(event) {
    let foundElements: HTMLCollectionOf<Element> = document.getElementsByClassName('app-outer-container');
    if (foundElements[0].scrollTop <= 165) {
      if (this.imagePositionClass != "") {
        this.imagePositionClass = "";
      }
    }
    else if (foundElements[0].scrollTop > 165) {
      if (this.imagePositionClass != "fix-position-top") {
        this.imagePositionClass = "fix-position-top";
      }
    }
  }

  public loadingAnimation: string = "data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==";
  public imagePositionClass: string = "";
  constructor(private screenSearchDataService: ScreenSearchDataService,
    private elementRef: ElementRef,
    private appComponent: AppComponent,
    private searchservice: SearchService,
    private subscriptionService: SubscriptionService,
    private messageService: MessageService) { }

  ngOnInit() {
    this.appComponent.isLoginExpires();
    this.resetColumnIndex();
    this.PlanExpiryMessage = "Your subscription has expired. Please contact your account manager.";
    this.ToastWarningHeader = "HUT Warning";
    this.loadScreenDataGrid();
    this.getPlanExpired(1);
    this.loadScreenIntervalCall = interval(15000).subscribe(val => this.loadScreenDataGrid());
    this.expiryIntervalCall = interval(10000).subscribe(val => this.getPlanExpired(0));
    this.commercialTemplateURL = environment.commercialTemplateURL;
    this.personalTemplateURL = environment.personalTemplateURL;
    this.mobileTemplateURL = environment.mobileTemplateURL;
    this.getScreenAllowedTemplates();
  }

  ngOnDestroy() {
    this.expiryIntervalCall.unsubscribe();
    this.loadScreenIntervalCall.unsubscribe();
  }

  ngAfterViewInit() {
    this.appComponent.settingsvisibility = true;
    this.elementRef.nativeElement.ownerDocument.body.style.background = 'url(/assets/Images/Background.svg)';
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundAttachment = 'fixed';
    this.searchservice.AllowedLicence().subscribe(data => {
      this.appComponent.screen = false;
      this.appComponent.search = false;
      if (data.find(i => i.licenceType.toLowerCase() == "search")) {
        this.appComponent.search = true;
      }
    });

  }

  public getScreenAllowedTemplates() {
    this.searchservice.AllowedLicence().subscribe(
      data => {
        let screenTemplate = data.filter(x => x.licenceType.toLowerCase() == "screen" && x.defaultValue == true);
        this.allowedTemplates = screenTemplate.map(x => x.itemName.toLowerCase());
      }
    );
  }

  loadScreenDataGrid() {
    this.screenSearchDataService.GetScreenSearchData().subscribe(data => {
      this.screenSearchData = data
    });
    this.getScreenAllowedTemplates();
  }

  onFileSelected(event) {
    if (event.target.files.length > 0) {
      this.filename = event.target.files[0].name
      this.file = event.srcElement.files;
      this.tempFile = event.srcElement.files[0];
      this.eventTarget = event.target;
      this.tempEventTarget = event.target.files[0];
      this.validationerror = null;
      this.successMessage = "";
    }
  }

  getPlanExpired(val) {
    this.subscriptionService.GetPlanExpiry(0).toPromise().then(
      data => {
        this.planExpiry = data;
        let days = this.planExpiry.subscriptionExpiry.days;
        let hours = this.planExpiry.subscriptionExpiry.hours;
        let minutes = this.planExpiry.subscriptionExpiry.minutes;
        let seconds = this.planExpiry.subscriptionExpiry.seconds;
        if (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) {
          this.isPlanExpired = true;
          if (val === 1 && this.isPlanExpired) {
            this.messageService.add({ key: 'PlanExpiry', severity: 'warn', summary: this.ToastWarningHeader, detail: this.PlanExpiryMessage, sticky: true });
            setTimeout(() => {
              this.messageService.clear();
            }, 13000);
          }
        }
        else {
          this.isPlanExpired = false;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  uploadListener(): void {
    this.resetColumnIndex();
    if (this.isPlanExpired) {
      this.validationerror = this.PlanExpiryMessage;
      setTimeout(() => { this.validationerror = null }, 7000);
    }
    else {
      this.resetFileInput();
      this.isPlanExpired = false;
      this.successMessage = "";

      if (this.tempFile != null) {
        if (this.isValidCSVFile(this.tempFile)) {
          let reader = new FileReader();
          reader.readAsText(this.tempEventTarget);

          reader.onload = () => {
            let csvData = reader.result;
            let csvRecordsArray = (<string>csvData).split(/\r\n|\n/);
            csvRecordsArray = csvRecordsArray.filter(x => x || !(/^[,]*$/.test(x)));
            let headersRow = this.getHeaderArray(csvRecordsArray);
            this.getColumnIndex(headersRow);
            var data = this.getDataFromFile(csvRecordsArray, headersRow);
            if (data && data.data.length > 0) {
              this.requestScreenSearch(data);
            } else if (data.data.length == 0) {
              this.validationerror = "Please upload csv file with data.";
              this.clearMessage();
            }
          }
        }
        else {
          this.validationerror = "Please upload .csv file.";
          this.clearMessage();
        }
      }
      else {
        this.validationerror = "Please upload a file";
        this.clearMessage();
      }
    }
  }

  getHeaderArray(csvRecordsArr: any): string[] {
    let headers = (<string>csvRecordsArr[0]).split(',');
    let headerArray: string[] = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j].toString());
    }
    return headerArray;
  }

  getDataFromFile(csvRecordsArray: string[], headersRow: string[]): ScreenInputData {
    let cSVRecords: CSVRecords[];
    // company type
    if (this.referenceNumberColumn != null && this.businessNameColumn != null && this.businessPostcodeColumn != null &&
      this.ltdNumberColumn != null && this.principalNameColumn != null && this.principalPostcodeColumn != null &&
      this.principalMobileColumn != null && this.principalEmailColumn != null && this.principalDobColumn != null) {
      if (!this.allowedTemplates.some(x => x == ScreenType.commercial.toString())) {
        this.validationerror = "You don't have access to this template.";
        this.clearMessage();
        return null;
      }
      const type = ScreenType.commercial.toString();
      cSVRecords = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length, type);
      var screenInputData = new ScreenInputData(type, cSVRecords, this.tempFile.name);
      return screenInputData;
    }
    // person type
    else if (this.referenceNumberColumn != null && (this.businessNameColumn == null && this.businessPostcodeColumn == null &&
      this.ltdNumberColumn == null && this.lossDateColumn == null) && this.principalNameColumn != null && this.principalPostcodeColumn != null &&
      this.principalMobileColumn != null && this.principalEmailColumn != null && this.principalDobColumn != null) {
      if (!this.allowedTemplates.some(x => x == ScreenType.personal.toString())) {
        this.validationerror = "You don't have access to this template.";
        this.clearMessage();
        return null;
      }
      const type = ScreenType.personal.toString();
      cSVRecords = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length, type);
      var screenInputData = new ScreenInputData(type, cSVRecords, this.tempFile.name);
      return screenInputData;
    }
    // mobile type
    else if (this.referenceNumberColumn != null && (this.businessNameColumn == null && this.businessPostcodeColumn == null &&
      this.ltdNumberColumn == null) && this.principalNameColumn != null && this.principalPostcodeColumn != null &&
      this.principalMobileColumn != null && this.principalEmailColumn != null && this.principalDobColumn != null && this.lossDateColumn != null) {
      if (!this.allowedTemplates.some(x => x == ScreenType.mobile.toString())) {
        this.validationerror = "You don't have access to this template.";
        this.clearMessage();
        return null;
      }
      const type = ScreenType.mobile.toString();
      cSVRecords = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length, type);
      var screenInputData = new ScreenInputData(type, cSVRecords, this.tempFile.name);
      return screenInputData;
    }
    // invalid type
    else if (this.referenceNumberColumn == null && this.businessNameColumn == null && this.businessPostcodeColumn == null &&
      this.ltdNumberColumn == null && this.principalNameColumn == null && this.principalPostcodeColumn == null &&
      this.principalMobileColumn == null && this.principalEmailColumn == null && this.principalDobColumn == null) {
      this.validationerror = "Please upload valid file";
      this.clearMessage();
      return null;
    }
    else {
      this.validationerror = "Please upload valid file";
      this.clearMessage();
      return null;
    }
  }

  isValidCSVFile(file: any) {
    return file.name.endsWith(".csv");
  }

  //Storing File records in CSVFile Object array 
  getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any, type: string) {
    let csvArr: CSVRecords[] = [];
    let principalMobile: string;
    for (let i = 1; i <= csvRecordsArray.length - 1; i++) {
      try {
        let currentRecord: any[] = csvRecordsArray[i].split(",");
        if (currentRecord.every(x => x.trim() == ""))
          continue;
        let reference: string = this.referenceNumberColumn != null ? currentRecord[this.referenceNumberColumn].trim() : "";
        let businessName: string = this.businessNameColumn != null ? currentRecord[this.businessNameColumn].trim() : "";
        let ltdNumber: string = this.ltdNumberColumn != null ? currentRecord[this.ltdNumberColumn].trim() : "";
        let businessPostcode: string = this.businessPostcodeColumn != null ? currentRecord[this.businessPostcodeColumn].trim() : "";
        let principalName: string = this.principalNameColumn != null ? currentRecord[this.principalNameColumn].trim() : "";
        let principalPostCode: string = this.principalPostcodeColumn != null ? currentRecord[this.principalPostcodeColumn].trim() : "";
        let principalEmail: string = this.principalEmailColumn != null ? currentRecord[this.principalEmailColumn].trim() : "";
        let principalDob: string = this.principalDobColumn != null ? currentRecord[this.principalDobColumn].trim() : "";
        let principalMobile: string = this.principalMobileColumn != null ? currentRecord[this.principalMobileColumn].trim() : "";
        let eventDate: string = this.lossDateColumn != null ? currentRecord[this.lossDateColumn].trim() : "";

        if (principalMobile != "" && principalMobile != null) {
          principalMobile = principalMobile.trim();
          const phoneNumber = parsePhoneNumber(principalMobile, 'GB');
          if (phoneNumber.isValid() === true && isPossiblePhoneNumber(principalMobile, 'GB') === true
            && isValidPhoneNumber(principalMobile, 'GB') && validatePhoneNumberLength(principalMobile, 'GB') === undefined) {
            principalMobile = phoneNumber.formatInternational();
          } else {
            principalMobile = "Bad data";
          }
        }

        if (currentRecord.length == headerLength) {
          if (type == ScreenType.commercial.toString())
            csvArr.push(new CSVRecords(reference, businessName, ltdNumber, "", "", businessPostcode, principalName, principalPostCode, principalMobile, principalEmail, principalDob, eventDate));
          else
            csvArr.push(new CSVRecords(reference, "", "", "", "", "", principalName, principalPostCode, principalMobile, principalEmail, principalDob, eventDate));
        } else if ((currentRecord.length != headerLength && !currentRecord[0].includes("\""))) {
          csvArr.push(new CSVRecords(reference, "", "", "", "", "", "", "", "", "", "", ""));
        } 
      } catch (error) { console.log("Error on getDataRecordsArrayFromCSVFile : ", error); }
    }
    return csvArr;
  }

  refreshResult() {
    this.loadScreenDataGrid();
  }

  clearMessage() {
    setTimeout(() => {
      this.validationerror = null;
    }, 5000);
  }

  requestScreenSearch(screenInputData: ScreenInputData) {
    this.screenSearchDataService.ProcessScreenSearch(screenInputData)
      .subscribe(data => {
        this.successMessage = "Search file processed into queue.";
        this.resetFileInput();
        this.tempFile = null;
        this.loadScreenDataGrid();
        setTimeout(() => {
          this.successMessage = null;
        }, 5000);
      }, error => {
        if (error.status == 404) {
          this.validationerror = "You don't have access to this template.";
          this.clearMessage();
        } else {
          this.validationerror = "Something went wrong. try once again.";
          this.clearMessage();
        }
      });
  }

  resetFileInput() {
    this.filename = "Choose File";
    this.file = null;
    this.fileInput.nativeElement.value = "";
  }

  resetColumnIndex() {
    this.referenceNumberColumn = null;
    this.principalPostcodeColumn = null
    this.principalNameColumn = null;
    this.principalMobileColumn = null;
    this.principalEmailColumn = null;
    this.principalDobColumn = null;
    this.ltdNumberColumn = null;
    this.businessPostcodeColumn = null;
    this.businessNameColumn = null
    this.lossDateColumn = null;
  }

  getColumnIndex(columnNames: string[]) {
    columnNames.forEach((x, index) => {
      switch (x.toLowerCase().trim()) {
        case "Reference No".toLowerCase():
          this.referenceNumberColumn = index;
          break;
        case "Business Name".toLowerCase():
          this.businessNameColumn = index;
          break;
        case "Ltd Co Number".toLowerCase():
          this.ltdNumberColumn = index;
          break;
        case "Business Postcode".toLowerCase():
          this.businessPostcodeColumn = index;
          break;
        case "Principal Name".toLowerCase():
          this.principalNameColumn = index;
          break;
        case "Principal Postcode".toLowerCase():
          this.principalPostcodeColumn = index;
          break;
        case "Principal Mobile".toLowerCase():
          this.principalMobileColumn = index;
          break;
        case "Principal Email".toLowerCase():
          this.principalEmailColumn = index;
          break;
        case "Principal DoB".toLowerCase():
          this.principalDobColumn = index;
          break;
        case "Loss Date".toLowerCase():
          this.lossDateColumn = index;
          break;
      }
    });
  }

}
