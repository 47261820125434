import { Component, Input, OnInit } from '@angular/core';
import { CCJResponseModel } from '../../models/CCJResponseModel';
import { InputAccessData } from '../../models/InputDataModel';

@Component({
  selector: 'app-cjj-check',
  templateUrl: './cjj-check.component.html',
  styleUrls: ['./cjj-check.component.css']
})
export class CJJCheckComponent implements OnInit {

  @Input() public ccjResultData: CCJResponseModel = new CCJResponseModel();
  @Input() public accessData: InputAccessData = new InputAccessData();

  public NoSearchSelect = "Search option not selected.";
  public SearchFail = "Search failed.";
  public NoRelevantDataMessage = "No relevant data found.";
  public OtherFieldRequired = "Additional data is required.";

  constructor() { }

  ngOnInit(): void {
  }

}
