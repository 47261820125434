import { Component, Input, OnInit } from '@angular/core';
import { MessagingService } from '../../../../../../services/messaging.service';
import { EmailageCheckResultModel } from '../../../../../models/EmailResultModel';
import { FinancialResultModel } from '../../../../../models/FinancialResultModel';

@Component({
  selector: 'app-email-new-result-personal',
  templateUrl: './email-new-result-personal.component.html',
  styleUrls: ['./email-new-result-personal.component.css', '../../../../shared/shared.searchpage.css']
})
export class EmailNewResultPersonalComponent implements OnInit {

  @Input() emailage: EmailageCheckResultModel = new EmailageCheckResultModel();
  @Input() financial: FinancialResultModel = new FinancialResultModel();

  constructor(public messageService: MessagingService) { }

  ngOnInit(): void {
  }

}
