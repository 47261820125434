<app-search-top-nav></app-search-top-nav>
<section id="personal-search" class="search">
  <div class="container">
    <div class="logo-div d-flex justify-content-center">
      <img class="logo" src="../../../../../assets/Images/new/HUT-icon.v3-hut-screen-RO.png" />
    </div>
    <form>
      <div class="form-group row form-top-margin">
        <div class="col-md-8 offset-md-2 form-data-background form-borderdesign  shadow-lg p-3">
          <div class="padding-form-design">
            <div class="col-md-12 pt-4">

              <div class="row">
                <div class="col-md-9">
                  <div class="browse-File select-file">
                    <input type="file" class="custom-file-input" id="customFile" (change)="uploadFileEvent($event)" ngModel #file>
                    <label class="custom-file-label" for="customFile">{{ filename }}</label>
                  </div>
                </div>
                <div class="col-md-2">
                  <button class="btn btn-primary" (click)="screenSearch()">Upload</button>
                </div>
              </div>

              <div class="row centeralign pt-2 message" *ngIf="validationerror">
                <div class="col-md-12">
                  <div class="screen-message-red">
                    <span class="validation-message"><small>{{validationerror}}<br /></small></span>
                  </div>
                </div>
              </div>

              <div class="row centeralign pt-2 message" *ngIf="successMessage">
                <div class="col-md-12">
                  <div class="screen-message-green">
                    <span class="\validation-message"><small>{{successMessage}}<br /></small></span>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="template centeralign pt-3">
                    <span class="">
                      Please upload your data file to Screen based on this
                      <br />
                      <a class="template-url" href="{{commercialTemplateURL}}">commercial template</a>,
                      <a class="template-url" href="{{personalTemplateURL}}">personal template</a> or
                      <a class="template-url" href="{{mobileTemplateURL}}">mobile template.</a>
                    </span>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </form>

    <div class="centeralign">
      <div class="card">
        <div class="card-body pt-4">
          <div class="d-flex justify-content-end">
            <span class="refresh-icon" (click)="refreshResult()"><i class="fa fa-refresh" aria-hidden="true"></i></span>
          </div>
          <table class="table table-striped stripped">
            <thead>
              <tr>
                <th scope="col">Filename</th>
                <th scope="col">Status</th>
                <th scope="col">Uploaded by</th>
                <th scope="col">Uploaded On</th>
                <th scope="col">Total Records</th>
                <th scope="col">Failed Records</th>
                <th scope="col">Result</th>
              </tr>
            </thead>
            <tbody *ngIf="screenSearchData">
              <tr *ngFor="let element of screenSearchData">

                <td> {{ element.filename }}</td>
                <td> {{ element.jobStatus }}</td>
                <td> {{element.submitedUserName}}</td>
                <td> {{ element.createdDate | date : 'dd-MM-yyy hh:mm a ' }}</td>
                <td> {{ element.recordCount }}</td>
                <td> {{ element.failedCount?element.failedCount:0 }}</td>
                <td>
                  <div>
                    <a href="{{element.blobstorageURL}}" target="_blank">
                      <i *ngIf="element.jobStatus=='Completed'" class="fa fa-arrow-down" aria-hidden="true"></i>
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</section>
