import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TableModule } from 'primeng/table';
import { PDFExportModule } from "@progress/kendo-angular-pdf-export";
import { DropdownModule } from 'primeng/dropdown';
import {TooltipModule} from 'primeng/tooltip';

import { V3RoutingModule } from './v3-routing.module';
import { V3Component } from './v3.component';

import { PersonalComponent } from './search-pages/components/personal/personal.component';
import { BusinessComponent } from './search-pages/components/business/business.component';
import { MobileComponent } from './search-pages/components/mobile/mobile.component';
import { EmailComponent } from './search-pages/components/email/email.component';
import { ScreenComponent } from './search-pages/components/screen/screen.component';
import { MotorComponent } from './search-pages/components/motor/motor.component';
import { RtwComponent } from './search-pages/components/rtw/rtw.component';
import { PhotoanalysisComponent } from './search-pages/components/photoanalysis/photoanalysis.component';
import { IdcheckComponent } from './search-pages/components/idcheck/idcheck.component';
import { DocumentvalidationComponent } from './search-pages/components/documentvalidation/documentvalidation.component';
import { ProfessionalComponent } from './search-pages/components/professional/professional.component';
import { SearchTopNavComponent } from './search-pages/components/search-top-nav/search-top-nav.component';
import { GaugeChartModule } from 'angular-gauge-chart';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { AmlResultPersonalComponent } from './search-pages/components/personal/uk-personal/result-page/aml-result/aml-result.component';
import { CcjResultPersonalComponent } from './search-pages/components/personal/uk-personal/result-page/ccj-result/ccj-result.component';
import { MobileResultPersonalComponent } from './search-pages/components/personal/uk-personal/result-page/mobile-result/mobile-result.component';
import { CounterFraudResultPersonalComponent } from './search-pages/components/personal/uk-personal/result-page/counter-fraud-result/counter-fraud-result.component';
import { EmailResultPersonalComponent } from './search-pages/components/personal/uk-personal/result-page/email-result/email-result.component';
import { RighttoworkResultPersonalComponent } from './search-pages/components/personal/uk-personal/result-page/righttowork-result/righttowork-result.component';
import { IdcheckResultPersonalComponent } from './search-pages/components/personal/uk-personal/result-page/idcheck-result/idcheck-result.component';
import { FinancialResultPersonalComponent } from './search-pages/components/personal/uk-personal/result-page/financial-result/financial-result.component';
import { SocialmediaResultPersonalComponent } from './search-pages/components/personal/uk-personal/result-page/socialmedia-result/socialmedia-result.component';
import { SportsResultPersonalComponent } from './search-pages/components/personal/uk-personal/result-page/sports-result/sports-result.component';
import { CriminalityResultPersonalComponent } from './search-pages/components/personal/uk-personal/result-page/criminality-result/criminality-result.component';
import { AssociationResultPersonalComponent } from './search-pages/components/personal/uk-personal/result-page/association-result/association-result.component';
import { ProspectResultPersonalComponent } from './search-pages/components/personal/uk-personal/result-page/prospect-result/prospect-result.component';
import { CompaniesHouseResultPersonalComponent } from './search-pages/components/personal/uk-personal/result-page/companies-house-result/companies-house-result.component';
import { InsolvencyResultPersonalComponent } from './search-pages/components/personal/uk-personal/result-page/insolvency-result/insolvency-result.component';
import { EmailvalidationResultEmailComponent } from './search-pages/components/email/result-pages/emailvalidation-result-email/emailvalidation-result-email.component';
import { EmailageResultEmailComponent } from './search-pages/components/email/result-pages/emailage-result-email/emailage-result-email.component';
import { MobileResultMobileComponent } from './search-pages/components/mobile/result-pages/mobile-result-mobile/mobile-result-mobile.component';
import { OpensourceResultMobileComponent } from './search-pages/components/mobile/result-pages/opensource-result-mobile/opensource-result-mobile.component';
import { AvaResultMotorComponent } from './search-pages/components/motor/uk-motor/result-pages/ava-result-motor/ava-result-motor.component';
import { MotorResultMotorComponent } from './search-pages/components/motor/uk-motor/result-pages/motor-result-motor/motor-result-motor.component';
import { PiplResultComponent } from './search-pages/components/personal/uk-personal/result-page/pipl-result/pipl-result.component';
import { CounterfraudNewResultPersonalComponent } from './search-pages/components/personal/uk-personal/result-page/counterfraud-new-result-personal/counterfraud-new-result-personal.component';
import { FinancialNewResultPersonalComponent } from './search-pages/components/personal/uk-personal/result-page/financial-new-result-personal/financial-new-result-personal.component';
import { EmailNewResultPersonalComponent } from './search-pages/components/personal/uk-personal/result-page/email-new-result-personal/email-new-result-personal.component';
import { MobileNewResultPersonalComponent } from './search-pages/components/personal/uk-personal/result-page/mobile-new-result-personal/mobile-new-result-personal.component';
import { HutmotorResultMotorComponent } from './search-pages/components/motor/uk-motor/result-pages/hutmotor-result-motor/hutmotor-result-motor.component';
import { OpensourceResultBusinessComponent } from './search-pages/components/business/result-pages/opensource-result-business/opensource-result-business.component';
import { VatResultBusinessComponent } from './search-pages/components/business/result-pages/vat-result-business/vat-result-business.component';
import { BusinessResultBusinessComponent } from './search-pages/components/business/result-pages/business-result-business/business-result-business.component';
import { MothistoryResultMotorComponent } from './search-pages/components/motor/uk-motor/result-pages/mothistory-result-motor/mothistory-result-motor.component';
import { ExportPdfPersonComponent } from './search-pages/components/personal/uk-personal/export-pdf/export-pdf-person/export-pdf-person.component';
import { PdfSearchFormPersonalComponent } from './search-pages/components/personal/uk-personal/export-pdf/export-pdf-person/pdf-search-form-personal/pdf-search-form-personal.component';
import { PdfCompanieshouseResultPersonalComponent } from './search-pages/components/personal/uk-personal/export-pdf/export-pdf-person/pdf-companieshouse-result-personal/pdf-companieshouse-result-personal.component';
import { PdfCriminalityResultPersonalComponent } from './search-pages/components/personal/uk-personal/export-pdf/export-pdf-person/pdf-criminality-result-personal/pdf-criminality-result-personal.component';
import { PdfSocialmediaResultPersonalComponent } from './search-pages/components/personal/uk-personal/export-pdf/export-pdf-person/pdf-socialmedia-result-personal/pdf-socialmedia-result-personal.component';
import { PdfSportsResultPersonalComponent } from './search-pages/components/personal/uk-personal/export-pdf/export-pdf-person/pdf-sports-result-personal/pdf-sports-result-personal.component';
import { T2aResultPersonalComponent } from './search-pages/components/personal/uk-personal/result-page/t2a-result-personal/t2a-result-personal.component';
import { LandingComponent } from './landing/landing.component';
import { ExportPdfMobileComponent } from './search-pages/components/mobile/export-pdf/export-pdf-mobile/export-pdf-mobile.component';
import { PdfSearchFormMobileComponent } from './search-pages/components/mobile/export-pdf/export-pdf-mobile/pdf-search-form-mobile/pdf-search-form-mobile.component';
import { ExportPdfMotorComponent } from './search-pages/components/motor/uk-motor/export-pdf/export-pdf-motor/export-pdf-motor.component';
import { PdfSearchFormMotorComponent } from './search-pages/components/motor/uk-motor/export-pdf/export-pdf-motor/pdf-search-form-motor/pdf-search-form-motor.component';
import { PdfMothistoryResultMotorComponent } from './search-pages/components/motor/uk-motor/export-pdf/export-pdf-motor/pdf-mothistory-result-motor/pdf-mothistory-result-motor.component';
import { PdfAvaResultMotorComponent } from './search-pages/components/motor/uk-motor/export-pdf/export-pdf-motor/pdf-ava-result-motor/pdf-ava-result-motor.component';
import { PdfHutmotorResultMotorComponent } from './search-pages/components/motor/uk-motor/export-pdf/export-pdf-motor/pdf-hutmotor-result-motor/pdf-hutmotor-result-motor.component';
import { ExportPdfEmailComponent } from './search-pages/components/email/export-pdf/export-pdf-email/export-pdf-email.component';
import { PdfSearchFormEmailComponent } from './search-pages/components/email/export-pdf/export-pdf-email/pdf-search-form-email/pdf-search-form-email.component';
import { ExportPdfDocumentValidationComponent } from './search-pages/components/documentvalidation/export-pdf/export-pdf-document-validation/export-pdf-document-validation.component';
import { ProspectDashboardPersonalComponent } from './search-pages/components/personal/uk-personal/result-page/prospect-dashboard-personal/prospect-dashboard-personal.component';
import { ProspectDashboardBusinessComponent } from './search-pages/components/business/result-pages/prospect-dashboard-business/prospect-dashboard-business.component';
import { LandregistryResultPersonalComponent } from './search-pages/components/personal/uk-personal/result-page/landregistry-result-personal/landregistry-result-personal.component';
import { DashboardOneBusinessComponent } from './search-pages/components/business/result-pages/dashboard-one-business/dashboard-one-business.component';
import { DashboardTwoBusinessComponent } from './search-pages/components/business/result-pages/dashboard-two-business/dashboard-two-business.component';
import { DashboardThreeBusinessComponent } from './search-pages/components/business/result-pages/dashboard-three-business/dashboard-three-business.component';
import { ExportPdfBusinessComponent } from './search-pages/components/business/export-pdf/export-pdf-business/export-pdf-business.component';
import { PdfSearchFormBusinessComponent } from './search-pages/components/business/export-pdf/export-pdf-business/pdf-search-form-business/pdf-search-form-business.component';
import { LtdRegisterationBusinessComponent } from './search-pages/components/business/result-pages/ltd-registeration-business/ltd-registeration-business.component';
import { LtdSharesBusinessComponent } from './search-pages/components/business/result-pages/ltd-shares-business/ltd-shares-business.component';
import { PdfLtdOfficerBusinessComponent } from './search-pages/components/business/export-pdf/export-pdf-business/pdf-ltd-officer-business/pdf-ltd-officer-business.component';
import { PdfLtdAccountsBusinessComponent } from './search-pages/components/business/export-pdf/export-pdf-business/pdf-ltd-accounts-business/pdf-ltd-accounts-business.component';
import { LtdMiscellaneousFinancialsBusinessComponent } from './search-pages/components/business/result-pages/ltd-miscellaneous-financials-business/ltd-miscellaneous-financials-business.component';
import { LtdCompanyhistoryFinancialsBusinessComponent } from './search-pages/components/business/result-pages/ltd-companyhistory-financials-business/ltd-companyhistory-financials-business.component';
import { LtdCommentaryperformanceFinancialsBusinessComponent } from './search-pages/components/business/result-pages/ltd-commentaryperformance-financials-business/ltd-commentaryperformance-financials-business.component';
import { LtdRatinghistoryBusinessComponent } from './search-pages/components/business/result-pages/ltd-ratinghistory-business/ltd-ratinghistory-business.component';
import { LtdCreditlimitBusinessComponent } from './search-pages/components/business/result-pages/ltd-creditlimit-business/ltd-creditlimit-business.component';
import { LtdOfficersBusinessComponent } from './search-pages/components/business/result-pages/ltd-officers-business/ltd-officers-business.component';
import { LtdAccountsBusinessComponent } from './search-pages/components/business/result-pages/ltd-accounts-business/ltd-accounts-business.component';
import { NonLtdBusinessComponent } from './search-pages/components/business/result-pages/non-ltd-business/non-ltd-business.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './services/interceptors/auth-interceptor';
import { SearchService } from './services/search.service';
import { UkMotorComponent } from './search-pages/components/motor/uk-motor/uk-motor.component';
import { AuMotorComponent } from './search-pages/components/motor/au-motor/au-motor.component';
import { AuRiskSummaryComponent } from './search-pages/components/motor/au-motor/result-pages/au-risk-summary/au-risk-summary.component';
import { AuVehicleDescriptionComponent } from './search-pages/components/motor/au-motor/result-pages/au-vehicle-description/au-vehicle-description.component';
import { AuWovrStolenComponent } from './search-pages/components/motor/au-motor/result-pages/au-wovr-stolen/au-wovr-stolen.component';
import { UkPersonalComponent } from './search-pages/components/personal/uk-personal/uk-personal.component';
import { AddressResultPersonalComponent } from './search-pages/components/personal/uk-personal/result-page/address-result/address-result.component';
import { AuPersonalComponent } from './search-pages/components/personal/au-personal/au-personal.component';
import { ExportPdfPersonalAuComponent } from './search-pages/components/personal/au-personal/export-pdf/export-pdf-personal-au/export-pdf-personal-au.component';
import { LandregistryResultBusinessComponent } from './search-pages/components/business/result-pages/landregistry-result-business/landregistry-result-business.component';
import { SocialmediaResultBusinessComponent } from './search-pages/components/business/result-pages/socialmedia-result-business/socialmedia-result-business.component';
import { UkMotorResultComponent } from './search-pages/components/motor/uk-motor/result-pages/uk-motor-result/uk-motor-result.component';


@NgModule({
  declarations: [
    LandingComponent,
    PersonalComponent,
    V3Component,
    BusinessComponent,
    MobileComponent,
    EmailComponent,
    ScreenComponent,
    MotorComponent,
    RtwComponent,
    PhotoanalysisComponent,
    IdcheckComponent,
    DocumentvalidationComponent,
    ProfessionalComponent,
    AddressResultPersonalComponent,
    AmlResultPersonalComponent,
    SearchTopNavComponent,
    CcjResultPersonalComponent,
    MobileResultPersonalComponent,
    CounterFraudResultPersonalComponent,
    EmailResultPersonalComponent,
    RighttoworkResultPersonalComponent,
    IdcheckResultPersonalComponent,
    FinancialResultPersonalComponent,
    InsolvencyResultPersonalComponent,
    CompaniesHouseResultPersonalComponent,
    ProspectResultPersonalComponent,
    AssociationResultPersonalComponent,
    CriminalityResultPersonalComponent,
    SportsResultPersonalComponent,
    SocialmediaResultPersonalComponent,
    EmailvalidationResultEmailComponent,
    EmailageResultEmailComponent,
    MobileResultMobileComponent,
    OpensourceResultMobileComponent,
    AvaResultMotorComponent,
    MotorResultMotorComponent,
    PiplResultComponent,
    CounterfraudNewResultPersonalComponent,
    FinancialNewResultPersonalComponent,
    EmailNewResultPersonalComponent,
    MobileNewResultPersonalComponent,
    HutmotorResultMotorComponent,
    OpensourceResultBusinessComponent,
    VatResultBusinessComponent,
    BusinessResultBusinessComponent,
    ExportPdfPersonComponent,
    PdfSearchFormPersonalComponent,
    PdfCompanieshouseResultPersonalComponent,
    PdfCriminalityResultPersonalComponent,
    PdfSocialmediaResultPersonalComponent,
    PdfSportsResultPersonalComponent,
    MothistoryResultMotorComponent,
    T2aResultPersonalComponent,
    ExportPdfMobileComponent,
    PdfSearchFormMobileComponent,
    ExportPdfMotorComponent,
    PdfSearchFormMotorComponent,
    PdfMothistoryResultMotorComponent,
    PdfAvaResultMotorComponent,
    PdfHutmotorResultMotorComponent,
    ExportPdfEmailComponent,
    PdfSearchFormEmailComponent,
    ExportPdfDocumentValidationComponent,
    ProspectDashboardPersonalComponent,
    ProspectDashboardBusinessComponent,
    LandregistryResultPersonalComponent,
    DashboardOneBusinessComponent,
    DashboardTwoBusinessComponent,
    DashboardThreeBusinessComponent,
    ExportPdfBusinessComponent,
    PdfSearchFormBusinessComponent,
    LtdRegisterationBusinessComponent,
    LtdSharesBusinessComponent,
    PdfLtdOfficerBusinessComponent,
    PdfLtdAccountsBusinessComponent,
    LtdMiscellaneousFinancialsBusinessComponent,
    LtdCompanyhistoryFinancialsBusinessComponent,
    LtdCommentaryperformanceFinancialsBusinessComponent,
    LtdRatinghistoryBusinessComponent,
    LtdCreditlimitBusinessComponent,
    LtdOfficersBusinessComponent,
    LtdAccountsBusinessComponent,
    NonLtdBusinessComponent,
    UkMotorComponent,
    AuMotorComponent,
    AuRiskSummaryComponent,
    AuVehicleDescriptionComponent,
    AuWovrStolenComponent,
    UkPersonalComponent,
    AuPersonalComponent,
    ExportPdfPersonalAuComponent,
    LandregistryResultBusinessComponent,
    SocialmediaResultBusinessComponent,
    UkMotorResultComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    V3RoutingModule,
    FormsModule,
    ReactiveFormsModule,
    GaugeChartModule,
    ButtonModule,
    ConfirmDialogModule,
    TableModule,
    PDFExportModule,
    DropdownModule,
    TooltipModule
  ],
  providers: [
    ConfirmationService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true, deps: [SearchService] }
  ]
})
export class V3Module { }
