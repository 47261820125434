import { Component, Input, OnInit } from '@angular/core';
import { MobileResultModel } from '../../../../../../search-pages/models/MobileResultModel';
import { MessagingService } from '../../../../../../services/messaging.service';

@Component({
  selector: 'app-mobile-result-personal',
  templateUrl: './mobile-result.component.html',
  styleUrls: ['./mobile-result.component.css', '../../../../shared/shared.searchpage.css']
})
export class MobileResultPersonalComponent implements OnInit {

  @Input() mobile: MobileResultModel = new MobileResultModel();

  constructor(private messageService: MessagingService) { }

  ngOnInit(): void {
  }

}
