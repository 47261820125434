import { AVAResultData } from "../../home/models/AVAModel/AVAResultData";

export class AvaResponseModel {
  public searchFail: boolean;
  public noRelevantData: boolean;
  public errorMessage: string;
  public responseData: AVAResultData;

  constructor() { }
}
