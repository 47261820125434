<div class="terms-and-conditions">
  <div class="text-center"><strong>WEBSITE AND APP TERMS OF USE and ASSOCIATED TERMS OF SALE</strong></div>
  <div class="text-center"><strong>(Last revised on July 2021)</strong></div>
  <br />
  <div class="text-center text-danger"><strong>PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING OUR WEBSITE OR APP, AS THEY CONTAIN IMPORTANT INFORMATION ABOUT HOW YOU MAY USE OUR WEBSITE AND APP AND ANY ASSOCIATED PURCHASES AND TRANSACTIONS.</strong></div>
  <div>
    <ul>
      <li>
        1. <strong>The terms on which you may use our website and app</strong>
        <ul>
          <li>1.1 These Terms of Use (together with the other documents referred to below) set out the terms on which you may use our website, <a href="https://www.hut.global">www.hut.global</a> and our App.</li>
          <li>1.2	You should read these Terms of Use carefully before using either platform, as by using our website and App you are confirming that you accept these Terms of Use and that you will comply with them.</li>
          <li>1.3	If you don’t accept these Terms of Use you should leave our website immediately and cease from using our App or any of our services.</li>
          <li>
            1.4	The following policies also apply to your use of our website and App:
            <ul>
              <li>1.4.1	Our <a href="https://www.hut.global/compliance/">privacy policy</a>.  By using our platforms you are consenting to us processing any personal data that you provide to us or that we collect from you as a result of your use of our platforms. Our privacy policy sets out what we do with that personal data.</li>
              <!--<li>1.4.2	Our <a href="">cookies policy</a>.</li>-->
            </ul>
          </li>
          <li>1.5	If you buy any services from our website or through using our App our terms and conditions of sale as defined within clause 6 of our Terms of Use will apply to the sale.</li>
          <li>1.6	You are responsible for ensuring that anyone else who accesses our platforms through your internet connection is aware of these Terms of Use and the policies referred to above and that they comply with them.</li>
        </ul>
      </li>
      <li>
        2. <strong>Changes to these Terms of Use and our other policies</strong>
        <ul>
          <li>2.1	We make changes to these Terms of Use from time to time.  You should therefore check these Terms of Use whenever you return to our platforms to see whether any changes have been made, as these will be binding on you.</li>
          <li>2.2	We also make changes to our privacy policy and cookies policy from time to time, so you should also check these regularly to see whether any changes have been made.</li>
        </ul>
      </li>
      <li>
        3. <strong>About us</strong>
        <ul>
          <li>3.1	We, Heads Up Technologies Limited, operate this website at <a href="https://www.hut.global">www.hut.global</a>. We are a private limited company registered in England and Wales under company number  and our registered office is at Unit 13, Redhill 23 Business Park, 29 Holmethorpe Avenue, Redhill, RH1 2GD. Our VAT number is 367 742 659. </li>
          <li>
            3.2	You can contact us:<br />
            By email at <a href="mailto:support@hut.global">support@hut.global</a><br />
            By post at Unit 13, Redhill 23 Business Park, 29 Holmethorpe Avenue, Redhill, RH1 2GD.
          </li>
          <li>3.3	Our Customer Complaints Code gives you information about what you can do if you are not happy.  It tells you how you can complain, what we will do and when and what you can do if you are still not happy.  The information provided in this Code is not contractual and is in addition to any other rights you may have under the terms and conditions that apply when we supply goods and services to you.  You can find these terms at <a href="https://www.hut.global">www.hut.global</a> and our Customer Complaints Code at <a href="https://www.hut.global">www.hut.global</a>.</li>
          <li>3.4	We are a limited company.</li>
        </ul>
      </li>
      <li>
        4. <strong>Restrictions on your use of our platforms</strong>
        <ul>
          <li>4.1	You may only use our website and App for lawful purposes and in compliance with all applicable laws, including without limitation data protection and privacy laws, laws relating to copyright of content and laws relating to unsolicited commercial electronic messages.</li>
          <li>
            4.2	In addition, you must not:
            <ul>
              <li>4.2.1	use our website and App to send or transmit, whether directly or indirectly, unsolicited or unauthorised advertising or promotional material, chain letters or pyramid selling schemes;</li>
              <li>4.2.2	use our website and App for mail bombing or flooding or to make deliberate attempts to overload a system;</li>
              <li>4.2.3	use our website and App to post or submit content or links to content where you know that or ought to have known that the owner of that content or Personal Data has not consented to or authorised that posting or linking or where that posting or linking infringes the rights of that owner;</li>
              <li>4.2.4	knowingly or recklessly introduce to our platforms, or use our platforms to transmit, any application, material or ware (such as viruses, spyware, malware or adware) which are malicious or harmful;</li>
              <li>4.2.5	attempt to gain unauthorised access to our website or App, the server, equipment or network on which our website and App are stored, any server, computer or database connected to our website or App or any software;</li>
              <li>4.2.6	use our website or App to attempt to gain unauthorised access to any other website, App, internet account, server, computer, equipment, system, network, data or information;</li>
              <li>4.2.7	use our website or App to monitor data or traffic on any network or system;</li>
              <li>4.2.8	use our website or App to collect or use information, including without limitation email addresses, screen names or other identifiers, by deceit (such as phishing, internet scamming, password robbery, spearing, scraping and harvesting);</li>
              <li>4.2.9	use our website or App to distribute software;</li>
              <li>4.2.10	carry out a denial-of-service attack or a distributed denial-of-service attack on our website or App or use our website or App to carry out any such attack on any other website or App;</li>
              <li>
                4.2.11	use our website or App:
                <ul>
                  <li>4.2.11.1	for any purpose which; or</li>
                  <li>4.2.11.2	to send, transmit, publish, upload, download, use, store, reproduce or knowingly receive any material that does not comply with any content standards we may set out from time to time. This includes any content:</li>
                  <li>4.2.11.3	of a sexually explicit nature </li>
                  <li>4.2.11.4	which directly or indirectly relates to criminal or illegal activity</li>
                  <li>4.2.11.5	which could be deemed as representing harassment</li>
                  <li>4.2.11.6 	which could be deemed as representing intimidation</li>
                </ul>
              </li>
              <li>4.2.12	use our website or App to groom, harm or take advantage of minors in any way or to attempt to do so; or</li>
              <li>4.2.13	use our website or App in violation of export laws, controls, regulations or sanction policies of the United Kingdom or any other country.</li>
            </ul>
          </li>
          <li>4.3	If you breach any term of these Terms of Use your right to use our website or App will immediately end.  In addition we may take such other actions including, without limitation, legal action as we consider appropriate. In this event, we reserve the right to not return any outstanding user credit(s) or monetary value associated with your user account.</li>
          <li>4.4	If you breach any term of these Terms of Use, or if we receive a request from a law enforcement authority to do so, we may report your identity, details of any breach and any other information we consider necessary to relevant law enforcement authorities.</li>
        </ul>
      </li>
      <li>
        5. <strong>Passwords and security requirements</strong>
        <ul>
          <li>5.1	If you register an account on our website or App you must provide accurate and complete registration information and keep that information up to date at all times.</li>
          <li>5.2	Use of our website and App is password protected.  In order to use it you will need a user identification code and password in order to access it.  It is your responsibility to keep this information secret and confidential and not to disclose it to any other person.</li>
          <li>5.3	If you think that somebody else might know your user identification code, password and/or pre-selected answers to security questions then you must log in to your account and change these as soon as possible.</li>
          <li>5.4	We reserve the right to disable any user subscription, identification code and password at any time if, in our reasonable opinion, you fail to comply with any of these Terms of Use, if we consider that you are using our website or App to download content or infringe in any manner the rights of another person or if we consider that there may be a security risk.</li>
        </ul>
      </li>
      <li>
        6. <strong>Our website, its content and subscriptions</strong>
        <ul>
          <li>6.1	Part of our website and App are made available to you free of charge, whilst some content and the use of some services are only available to those who have paid for, or have otherwise been permitted, access to it, for example via a paid subscription.</li>
          <li>6.2	When you purchase a subscription to our website or App or access paid for services via our platforms you are confirming that you accept these Terms of Use and agree to comply with the following additional terms relating to your subscription and purchase which represent a separate Terms of Sale agreement.</li>
        </ul>
      </li>
      <li>
        <strong>Terms of Sale Agreement.</strong>
        <ul>
          <li>1. <strong>Account</strong>. If a Service requires you to open an account, you must complete the registration process by providing us with current, complete and accurate information required by the applicable registration form. Your use of the account to access our platforms, content and services is subject to all of the terms governing your account with us. You are responsible for keeping your account information and password confidential and are responsible for all activity that occurs under your account.</li>
          <li>2. <strong>No Unlawful or Prohibited Use</strong>. As a condition of use of our Services, you warrant to us that you will not use the services for any purpose that is unlawful or prohibited by these Terms of Sale, or any other terms that apply to your use of the services. You may not use the services in any manner that could damage, disable, overburden, or impair any server, or the network(s) connected to any server, or interfere with any other party's use and enjoyment of the services. You may not attempt to gain unauthorized access to the services, other accounts, computer systems or networks connected to any of our servers, through hacking, password mining or any other means. You may not obtain or attempt to obtain any materials or information through any means not intentionally made available through our platforms or services.  You may not use the services in a way that infringes rights of third parties, including wilfully harming a person or entity, including Heads Up Technologies Limited. You may not commercially distribute, publish, license, or sell any products, information or services obtained from our platforms. </li>
        </ul>
      </li>
      <li>
        <strong>Terms Relating to the Sale of Products AND SERVICES to You</strong>
        <ul>
          <li>3. <strong>Geographic Availability</strong>. Service availability may vary depending on your region or device. In addition, there may be limits on where we can provide services or digital content. To complete your purchase, you may be required to have a valid billing address within the country or region where the service will be purchased. </li>
          <li>4. <strong>End Users Only</strong>. You must be an end user to purchase services from us. </li>
          <li>5. <strong>Billing</strong>. By providing us with a payment method, you: (i) represent that you are authorized to use the payment method that you provided and that any payment information you provide is true and accurate; (ii) authorize us to charge you for any products, services or available content purchased using your payment method; and (iii) authorize us to charge you for any paid feature of the our platforms that you choose to sign up for or use. You agree to promptly update your account and other information, including your email address and credit card numbers and expiration dates, so that we can complete your transactions and contact you as needed in connection with your transactions. We may bill you (a) in advance; (b) at the time of purchase; (c) shortly after purchase; or (d) on a recurring basis for subscriptions. Also, we may charge you up to the amount you have approved, and we will notify you in advance of any change in the amount to be charged for recurring subscriptions. We may bill you at the same time for more than one of your prior billing periods for amounts that haven't previously been processed. </li>
          <li>If you are taking part in any trial-period offer, you must cancel the service by the end of the trial period to avoid incurring new charges unless we notify you otherwise. If you do not cancel the service at the end of the trial period, you authorize us to charge your payment method for the product or service.</li>
          <li>6. <strong>Recurring Payments</strong>. When you purchase services or digital content on a subscription basis (e.g., weekly, monthly, every 3 months, or annually (as applicable)), you acknowledge and agree that you are authorizing recurring payment, and payments shall be made to us by the method you have chosen at the recurring intervals chosen by you, until the subscription is terminated by you or by us. By authorizing recurring payments, you are authorizing us to process such payments as either electronic debits or fund transfers, or as electronic drafts from your designated account (in the case of Automated Clearing House or similar payments), or as charges to your designated account (in the case of credit card or similar payments) (collectively, “Electronic Payments”). Subscription fees are generally billed or charged in advance of the applicable subscription period. If any payment is returned unpaid or if any credit card or similar transaction is rejected or denied, we as the service provider reserve the right to collect any applicable return item, rejection or insufficient funds fee and to process any such payment as an Electronic Payment.</li>
          <li>7. <strong>Availability and Quantity and Order Limits</strong>. Prices and availability of services and digital content available on our platforms are subject to change at any time and without notice. We may place a limit on the quantities that may be purchased per order, per account, per credit card, per person, or per household. If for any reason the services or digital content that you order are, or become following your order, unavailable, we may contact you to offer you an alternative product. If you do not choose to purchase the alternative offered, we will cancel your order.</li>
          <li>We may refuse or reject any order at any time, refunding you any monies you have paid for the order, for legitimate reasons which include, but are not limited to, if you have not met the conditions specified at the time of the order, if your payment cannot be processed, if the ordered digital content or services are not available, or in the event of an obvious pricing error or other errors. In the event of such pricing or other errors, we reserve the right to either (a) cancel your order or purchase or (b) contact you with alternative options. In the event of cancellation, your access to any associated content will be disabled.</li>
          <li>We may remove or disable content, or services on your device in order to protect you, our platforms or potentially affected parties. Some content and applications may be unavailable from time to time or may be offered for a limited time. Availability can be affected by region. </li>
          <li>8. <strong>Updates</strong>. If applicable, we will automatically check for and download updates to your applications, even if you’re not signed into your account.  Certain services within our App that are entirely or partly hosted online may be updated at any time by the app developer and may not require your permission to update. Some other apps may not be accessible until you accept an update.</li>
          <li>9. <strong>Software Licenses and Use Rights</strong>. Software and other digital content made available through our platforms are licensed, not sold, to you. Please respect our intellectual property rights when using our services. </li>
          <li>10. <strong>Automatic Renewal Selection</strong>. Provided that automatic renewals are allowed in your country, region, province, or state, you may choose for services to automatically renew at the end of a fixed service period. We will remind you by email before any service renews for a new term. Once we have reminded you that you elected to automatically renew your service, we may automatically renew the  service at the end of the current service period and charge you the then-current price for the renewal term, unless you have chosen to cancel the service as described below. We will also remind you that we will bill your chosen payment method for the renewal, whether it was on file on the renewal date or provided later. We will also provide you with instructions on how you may cancel the services. You must cancel before the renewal date to avoid being billed for the renewal.</li>
          <li>11. <strong>Payments to You</strong>. If we owe you a payment, then you agree to timely and accurately provide us with any information we need to get that payment to you. You are responsible for any taxes and charges you may incur as a result of this payment to you. You must also comply with any other conditions we place on your right to any payment. If you receive a payment in error, we may reverse or require return of the payment. You agree to cooperate with us in our efforts to do this. We may also reduce a payment to you without notice to adjust for any previous overpayment.</li>
        </ul>
      </li>
      <li>
        <strong>GENERAL Terms</strong>
        <ul>
          <li>12. <strong>Changing Terms</strong>. We may change the Terms of Sale at any time and without notice to you. The Terms of Sale in force at the time you place your order (or, for apps at the time you attempt to install the app) will govern your acquisition transaction and serve as the transaction contract between us. Before your next transaction, we may have changed the Terms of Sale without notice to you. Please review the Terms of Sale each time make a purchase with us. We recommend that you save or print a copy of the Terms of Sale for future reference when you acquire something.</li>
          <li>13. <strong>Authorized Use; Age Limitations</strong>. You acknowledge that you are authorised to place an order and to perform any other legal acts required of you under these Terms of Sale. Age limits may apply to your use of our services. </li>
          <li>14. <strong>Termination of Use or Access</strong>. We may terminate your account or use of our services at any time for any legitimate reason, including, without limitation, if you are in breach of these Terms of Sale. By using our services you agree to be responsible (in accordance with these terms) for any orders you make or charges you incur prior to such termination. We may change, discontinue, or otherwise suspend the our platforms at any time, for any reason, and without prior notice to you. If such a change, discontinuance or suspension impacts your use of the service, or otherwise disrupts your order, please contact us. </li>
          <li>15. <strong>Limitation of Liability</strong>. Nothing in this limitation of liability shall exclude or limit any mandatory consumer protections that are available to you in your territory or any other liability which cannot be excluded by law. The same shall apply to liability of Heads Up Technologies Limited, its agents and/or its legal representatives in the event of fraud or their negligence resulting in personal injury or death.</li>
          <li>a. Heads Up Technologies Limited, its agents and/or its legal representatives shall not be liable for any indirect damage, including financial loss, such as loss of profit, unless Heads Up Technologies Limited, its agents and/or its legal representative have at least acted with negligence or willful misconduct.</li>
          <li>b. Heads Up technologies Limited shall not be liable for your Submissions, content, or material, or activities provided by users. Such content and activities are neither attributable to Heads Up Technologies Limited nor do they represent Heads Up Technologies Limited’s opinion.</li>
          <li>c. Heads Up Technologies Limited is not responsible or liable for any failure to perform or delay in performing its obligations under these Terms to the extent that the failure or delay is caused by circumstances beyond Heads Up Technologies Limited’s  reasonable control (such as labour disputes, acts of God, war or terrorist activity, malicious damage, accidents or compliance with any applicable law or government order).</li>
          <li>16. <strong>Choice of Law and Place to Resolve Disputes</strong>. If you are a consumer these Terms of Use are governed by the law of England and Wales and the courts of England and Wales have non-exclusive jurisdiction to determine any dispute arising out of or in connection with them.  If you are resident in Scotland you may also bring proceedings in Scotland and if you are resident in Northern Ireland you may also bring proceedings in Northern Ireland.  As a consumer, you will benefit from any mandatory provisions of the law of the country in which you are resident. Nothing in these Terms of Use affects your rights as a consumer to rely on such mandatory provisions of local law.</li>
          <li>17. We do not guarantee that access to our website or App, or the content and services associated with our platforms, will always be available or uninterrupted.  Your right to access our website and App is given on a temporary basis only.</li>
          <li>18. We reserve the right to change our website and App and the associated services, including without limitation by adding or removing content or functionality, at any time.</li>
          <li>19. We cannot guarantee that all content on our website and App and the associated services, is accurate, complete or up to date.  We therefore make no warranties, representations, undertakings or guarantees that the content on any of our platforms is accurate, complete or up to date.</li>
          <li>20. We make no warranties, representations, undertakings or guarantees that our website, App or associated services will be free from errors or omissions.</li>
          <li>21. The content on our website and App and the associated services are provided for general information purposes only.  Nothing within our platforms or services is intended to be advice on which you should solely rely.  You should always obtain appropriate specialist advice before taking, or not taking, action on the basis of any content on our website, App or the associated services we provide via our platforms.</li>
          <li>22. We make no warranties, representations, undertakings or guarantees that our website and App is secure or free from bugs or viruses.  You are responsible for ensuring that the device from which you access our website and App has suitable anti-virus software in place.</li>
        </ul>
      </li>
      <li>
        7. <strong>Intellectual property rights</strong>
        <ul>
          <li>7.1	We, or our licensors, own the copyright and all other intellectual property rights in our website and App and all content on it.</li>
          <li>7.2	The trademarks, logos and slogans that appear with or relate to the website and App are our registered trade marks.</li>
          <li>7.3	You may download the content we own from our website and/or print a reasonable number of copies of any page on our website for your personal use.  We do not permit downloading, copying or printing of any content from our App including the HeadsUp Search Reports which must be retained within our App. You must not make any changes to any content which you have downloaded or printed from our website.  If you reproduce the downloaded or printed content in any medium you must ensure that you reproduce it accurately and completely and include a statement acknowledging (i) the content’s owner and original source, and (ii) that it has been sourced from our website.</li>
          <li>7.4	If you use, download, print or reproduce content from our website and App in breach of these Terms of Use your right to use our website and App will immediately end and you must return to us or destroy (at our option) all digital and paper copies of the content that you have made.</li>
        </ul>
      </li>
      <li>
        8.	<strong>Links from our website and App to other websites and Apps</strong><br />
        <p>Our platforms may contain links to other websites and Apps.  These links are provided for your convenience.  We have no control over, and no responsibility or liability to you for, those other websites or Apps or their content.</p>
      </li>
      <li>
        9.	<strong>Links from other websites and Apps to our platforms</strong>
        <ul>
          <li>9.1	You may create a link to our platforms from another website or App, but only if you comply with the following conditions:
            <ul>
              <li>9.1.1	you may only link to the homepage or landing page of our platform. You must not create a link to any other page of our platforms, nor frame any page of our website or App;</li>
              <li>9.1.2	you may only link to our platforms from a platform which you own; and</li>
              <li>9.1.3	you must not suggest that we have any association with you or that we approve or endorse your website or any of your products or services unless we expressly agree that you can do so.</li>
            </ul>
          </li>
          <li>9.2	We reserve the right to require you to remove any link to our platforms at any time and without giving you prior notice.</li>
        </ul>
      </li>
      <li>
        10.	<strong>Our liability to you</strong>
        <ul>
          <li>10.1	Nothing in these Terms of Use limits or excludes our liability to you for:
            <ul>
              <li>10.1.1	death or personal injury resulting from negligence;</li>
              <li>10.1.2	fraud or fraudulent misrepresentation; or</li>
              <li>10.1.3	any other matter for which we are not permitted by law to exclude or limit our liability.</li>
            </ul>
          </li>
          <li>10.2	As these Terms of Use govern your access and use of our website and App, all warranties, conditions and other terms relating to our website and App and/or its content which would otherwise be implied by law (whether by statute, common law or otherwise) are excluded.</li>
          <li>10.3	We will not be liable to you for any loss or damage arising out of or in connection with your use of (or inability to use) our website and App and the services we provide through our platforms or your use of or reliance upon any content or information on or provided via our website and App, whether in contract, tort (including without limitation negligence), misrepresentation, restitution, under statute or otherwise.</li>
        </ul>
      </li>
      <li>
        11.	<strong>Applicable law</strong>
        <ul>
          <li>11.1	If you are a consumer these Terms of Use are governed by the law of England and Wales and the courts of England and Wales have non-exclusive jurisdiction to determine any dispute arising out of or in connection with them.  If you are resident in Scotland you may also bring proceedings in Scotland and if you are resident in Northern Ireland you may also bring proceedings in Northern Ireland.  As a consumer, you will benefit from any mandatory provisions of the law of the country in which you are resident. Nothing in these Terms of Use affects your rights as a consumer to rely on such mandatory provisions of local law.</li>
          <li>11.2	If you are a business user these Terms of Use (and any non-contractual obligations arising out of or in connection with them) are governed by the law of England and Wales and the courts of England and Wales have exclusive jurisdiction to determine any dispute arising out of or in connection with them (including without limitation in relation to any non-contractual obligations).</li>
        </ul>
      </li>
    </ul>
  </div>
</div>
