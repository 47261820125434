import { Component, Input, OnInit } from '@angular/core';
import { RequestData } from '../../../../../../models/SearchRequestModel';

@Component({
  selector: 'app-pdf-search-form-personal',
  templateUrl: './pdf-search-form-personal.component.html',
  styleUrls: ['./pdf-search-form-personal.component.css', '../../../../../shared/shared.searchpage.css']
})
export class PdfSearchFormPersonalComponent implements OnInit {

  @Input() public requestData: RequestData = new RequestData();

  constructor() { }

  ngOnInit(): void {
  }

}
