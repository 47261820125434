<app-search-top-nav></app-search-top-nav>
<section id="personal-search" class="search">
  <div class="container">
    <div class="logo-div d-flex justify-content-center">
      <img class="logo" src="../../../../../assets/Images/new/HUT-icon.v3-photo-analysis-RO.png" />
    </div>
    <form role="form" [formGroup]="photoAnalysisForm">
      <div class="form-group row form-top-margin">
        <div class="col-md-8 offset-md-2 form-data-background form-borderdesign shadow-lg p-3">
          <div class="row padding-form-design">
            <div class="col-md-12 z-index-formdata">
              <div class="row">
                <div class="input-group">
                  <div class="col-md-2 hut-form-label d-flex justify-content-end mt-4">
                    <b>Reference:</b>
                  </div>
                  <div class="col-md-6 pt-3">
                    <div class="form-group">
                      <input type="text" placeholder="" class="form-control" formControlName="reference" autocomplete="off" />
                      <p class="text-danger" *ngIf="photoAnalysisForm.get('reference').errors?.required">
                        <strong><small>Reference is required</small></strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 mx-4">
              <div class="row">
                <div class="col-md-9">
                  <div class="browse-File select-file">
                    <input type="file" class="custom-file-input" id="customFile" (change)="onFileChange($event)" />
                    <label class="custom-file-label" for="customFile">{{(photoAnalysisForm.get('file')?.value?.name) ? photoAnalysisForm.get('file')?.value?.name : 'Choose File'}}</label>
                  </div>
                </div>
                <div class="col-md-2">
                  <button class="btn btn-primary" (click)="photoAnalysisSearch()">Upload</button>
                </div>
              </div>
              <p class="text-danger" *ngIf="photoAnalysisForm.get('file').errors?.required">
                <strong><small>File upload is required</small></strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</section>
