import { Component, Input, OnInit } from '@angular/core';
import { MessagingService } from '../../../../../../services/messaging.service';
import { LandRegistryResultModel } from '../../../../../models/LandRegistryResultModel';

@Component({
  selector: 'app-landregistry-result-personal',
  templateUrl: './landregistry-result-personal.component.html',
  styleUrls: ['./landregistry-result-personal.component.css', '../../../../shared/shared.searchpage.css']
})
export class LandregistryResultPersonalComponent implements OnInit {

  @Input() landRegistry: LandRegistryResultModel = new LandRegistryResultModel();

  constructor(private messageService: MessagingService) { }

  ngOnInit(): void {
  }

  public convertDateString(dateStr: string){
    return new Date(dateStr);
  }

}
