import { Component, Input, OnInit } from '@angular/core';
import { RequestData } from '../../../../models/SearchRequestModel';

@Component({
  selector: 'app-export-pdf-document-validation',
  templateUrl: './export-pdf-document-validation.component.html',
  styleUrls: ['./export-pdf-document-validation.component.css', '../../../shared/shared.searchpage.css', '../../documentvalidation.component.css']
})
export class ExportPdfDocumentValidationComponent implements OnInit {

  @Input() public requestData: RequestData = new RequestData();
  @Input() public fileOneUrl: string = "";
  @Input() public fileTwoUrl: string = "";
  @Input() public USIdCheckUploadResult: any;
  @Input() public FileNames: string[] = [];
  @Input() public loggedInUsername: string = "";
  @Input() public File: File[];

  constructor() { }

  ngOnInit(): void {
  }

}
