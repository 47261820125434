<div class="row mb-3">
  <div class="col-md-6 p-0 text-right">
    Business
  </div>
  <div class="col-md-6 p-0 text-left">
    <span class="loadingImg" *ngIf="counterFraud.isLoading">
      <img src="{{messageService.loadingAnimation}}" />
    </span>

    <div class="col-md-12" *ngIf="counterFraud.noSearchSelect && !counterFraud.isLoading"><i class="color-grey float-right">{{messageService.NoSearchSelectShort}}</i></div>
    <div class="col-md-12" *ngIf="counterFraud.validationMessage && !counterFraud.isLoading"><i class="color-grey float-right">{{messageSercice.OtherFieldRequired}}</i></div>
    <div class="col-md-12" *ngIf="counterFraud.noRelevantData && !counterFraud.isLoading"><i class="color-grey float-right">{{messageService.NoRelevantDataMessageShort}}</i></div>
    <div class="col-md-12" *ngIf="counterFraud.searchFail && !counterFraud.isLoading"><i class="color-grey float-right">{{messageService.SearchFail}}</i></div>

    <div *ngIf="!counterFraud.isLoading && !counterFraud.searchFail && !counterFraud.noRelevantData && !counterFraud.validationMessage && !counterFraud.noSearchSelect && counterFraud.responseData?.data">
      <span *ngIf="counterFraud.responseData.data.ragStatus.toLowerCase() == 'green'" class="ml-3 dot dot-green"></span>
      <span *ngIf="counterFraud.responseData.data.ragStatus.toLowerCase() != 'green'" class="ml-3 dot dot-red"></span>
    </div>
  </div>
</div>
