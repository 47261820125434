<div class="container">
  <span>HeadsUp Username: {{requestData.profileData.searchUsername ? requestData.profileData.searchUsername : '-'}}</span>
  <br />
  <span>Search Date &amp; Time: {{requestData.profileData.searchDate}} - {{requestData.profileData.searchTime}}</span>
</div>
<div>
  <app-pdf-search-form-mobile [requestData]="requestData"></app-pdf-search-form-mobile>
</div>
<br />
<br />
<div class="container">

  <!-- Mobile Result -->
  <h3><b><u>Mobile</u></b></h3>
  <app-mobile-result-mobile [mobile]="mobile"></app-mobile-result-mobile>

  <!-- Opensource Result -->
  <h3><b><u>Opensource</u></b></h3>
  <app-opensource-result-mobile [opensource]="opensource"></app-opensource-result-mobile>

  <!-- Prospect Result -->
  <div *ngIf="prospect.isProspectHitAccess && !regionAccess.isAURegion">
    <h3><b><u>Prospect</u></b></h3>
    <app-prospect-result-personal [prospect]="prospect"></app-prospect-result-personal>
  </div>

  <!-- Address Result -->
  <div *ngIf="!regionAccess.isAURegion">
    <h3><b><u>Contact</u></b></h3>
    <app-address-result-personal [address]="address"></app-address-result-personal>
  </div>

</div>
