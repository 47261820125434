export class UserDetails {
  userId: string="";
  firstName: string="";
  lastName: string="";
  department: string="";
  email: string="";
  emailConfirmed: boolean=false;
  phoneNumber: string="";
  phoneNumberConfirmed: boolean=false;
  userName: string="";
  accessFailedCount: number=0;
  isActive: boolean=false;
  isAdmin: boolean;
  isDeleted: boolean=false;
  subscription: string = "";
  licenceId: number = 0;
  licenceName: string = "";
  licenceIsActive: boolean = false;
  password: string = "";
  confirmPassword: string = "";
}


