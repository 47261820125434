import { Component, Input, OnInit } from '@angular/core';
import { RequestData } from '../../../../../models/SearchRequestModel';

@Component({
  selector: 'app-pdf-search-form-mobile',
  templateUrl: './pdf-search-form-mobile.component.html',
  styleUrls: ['./pdf-search-form-mobile.component.css', '../../../../shared/shared.searchpage.css']
})
export class PdfSearchFormMobileComponent implements OnInit {

  @Input() public requestData: RequestData = new RequestData();

  constructor() { }

  ngOnInit(): void {
  }

}
