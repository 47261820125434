import { Component, Input, OnInit } from '@angular/core';
import { PIPLResultModel } from '../../../../../../search-pages/models/PIPLResultModel';
import { MessagingService } from '../../../../../../services/messaging.service';
import { SocialMediaResultModel } from '../../../../../models/SocialMediaResultModel';

@Component({
  selector: 'app-socialmedia-result-personal',
  templateUrl: './socialmedia-result.component.html',
  styleUrls: ['./socialmedia-result.component.css', '../../../../shared/shared.searchpage.css']
})
export class SocialmediaResultPersonalComponent implements OnInit {

  @Input() socialMedia: SocialMediaResultModel = new SocialMediaResultModel();

  constructor(private messageService: MessagingService) { }

  ngOnInit(): void {
  }

}
