<!--<div class="col-md-12" *ngIf="!pipl.isLoading && pipl.noSearchSelect"><i class="color-grey float-right">{{messageService.NoSearchSelect}}</i></div>
<div class="col-md-12" *ngIf="!pipl.isLoading && !pipl.noSearchSelect && pipl.validation && !pipl.searchFail && !pipl.noRelevantData"><i class="color-grey float-right">{{messageService.EmailRequiredMessage}}</i></div>
<div class="col-md-12" *ngIf="!pipl.isLoading && !pipl.noSearchSelect && !pipl.validation && pipl.searchFail && !pipl.noRelevantData"><i class="color-grey float-right">{{messageService.SearchFail}}</i></div>
<div class="col-md-12" *ngIf="!pipl.isLoading && !pipl.noSearchSelect && !pipl.validation && pipl.noRelevantData && !pipl.searchFail"><i class="color-grey float-right">{{messageService.NoRelevantDataMessage}}</i></div>-->

<div>
  <span class="loadingImg" *ngIf="pipl.isLoading">
    <img src="{{messageService.loadingAnimation}}" />
  </span>
</div>

<div *ngIf="!pipl.isLoading && !pipl.searchFail && !pipl.noRelevantData && pipl.responseData" style="word-wrap: break-word;">
  <div *ngIf="pipl?.responseData?.usernames && pipl?.responseData?.usernames.length > 0" class="mb-4 keep-together">
    <div><b><u>USERNAMES</u></b></div>
    <div *ngFor="let username of pipl?.responseData?.usernames" class="mt-2 mx-5">
      <div class="row">
        <div class="col-3">
          <span *ngIf="username?.name">{{username.name}}</span>
        </div>
        <div class="col-3">
          <span *ngIf="username?.validSince">valid since: {{username.validSince}}</span>
        </div>
        <div class="col-3">
          <span *ngIf="username?.lastSeen">Last seen: {{username.lastSeen}}</span>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="pipl?.responseData?.phoneNumbers && pipl?.responseData?.phoneNumbers.length > 0" class="mb-4 keep-together">
    <div><b><u>PHONES</u></b></div>
    <div *ngFor="let phone of pipl?.responseData?.phoneNumbers" class="mt-2 mx-5">
      <span *ngIf="phone?.phoneNumber">{{phone.phoneNumber}}</span>
    </div>
  </div>

  <div *ngIf="pipl?.responseData?.addresses && pipl?.responseData?.addresses.length > 0" class="mb-4 keep-together">
    <div><b><u>ADDRESSES</u></b></div>
    <div *ngFor="let address of pipl?.responseData?.addresses" class="mt-2 mx-5">
      <span *ngIf="address?.address">{{address.address}}</span>
    </div>
  </div>

  <div *ngIf="pipl?.responseData?.relationships && pipl?.responseData?.relationships.length > 0" class="mb-4 keep-together">
    <div><b><u>RELATIONSHIPS</u></b></div>
    <div *ngFor="let relation of pipl?.responseData?.relationships" class="mt-2 mx-5">
      <div class="row">
        <div class="col-3">
          <span *ngIf="relation?.name">{{relation.name}}</span>
        </div>
        <div class="col-3">
          <span *ngIf="relation?.validSince">Valid since: {{relation.validSince}}</span>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="pipl?.responseData?.userIds && pipl?.responseData?.userIds.length > 0" class="mb-4 keep-together">
    <div><b><u>USER IDs</u></b></div>
    <div *ngFor="let user of pipl?.responseData?.userIds" class="mt-2 mx-5">
      <div class="row">
        <div class="col-3">
          <span *ngIf="user?.userId">{{user.userId}}</span>
        </div>
        <div class="col-3">
          <span *ngIf="user?.validSince">Valid since: {{user.validSince}}</span>
        </div>
        <div class="col-3">
          <span *ngIf="user?.lastValid">Last seen: {{user.lastValid}}</span>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="pipl?.responseData?.urls && pipl?.responseData?.urls.length > 0" class="mb-4 keep-together">
    <div><b><u>URLs</u></b></div>
    <div *ngFor="let url of pipl?.responseData?.urls" class="mt-2 mx-5">
      <div *ngIf="url?.domain">{{url.domain}}</div>
      <div *ngFor="let link of url.urls">
        <span *ngIf="link" class="mx-5">{{link}}</span>
      </div>
    </div>
  </div>
</div>
