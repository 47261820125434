export enum SearchType {
  Personal = "Personal",
  Email = "Email",
  Business = "Business",
  Mobile = "Mobile",
  RightToWork = "RightToWork",
  Motor = "Motor",
  Professional = "Professional",
  IdCheck = "IdCheck",
  PhotoAnalysis = "PhotoAnalysis",
  DocumentValidation = "DocumentValidation"
}
