import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CounterFraudResponse } from '../../models/CounterFraudModel';
import { InputAccessData, InputData } from '../../models/InputDataModel';

@Component({
  selector: 'app-counter-fraud',
  templateUrl: './counter-fraud.component.html',
  styleUrls: ['./counter-fraud.component.css']
})
export class CounterFraudComponent implements OnInit {

  @Input() public inputData: InputData = new InputData();
  @Input() public accessData: InputAccessData = new InputAccessData();
  @Input() public counterFraud: CounterFraudResponse = new CounterFraudResponse();

  // Constant Message
  public NoSearchSelectShort = "Not selected.";
  public OtherFieldRequired = "Additional data is required.";
  public NoRelevantDataMessageShort = "No records.";
  public SearchFail = "Search failed.";

  // Counter Fraud Chart Dependancy
  public canvasWidth = 270
  public needleValue = 0
  public centralLabel = ''
  public bottomLabel = ''
  public options = {
    hasNeedle: true,
    needleColor: 'gray',
    needleUpdateSpeed: 1000,
    arcColors: ['green', 'orange', '#CE103E'],
    arcDelimiters: [33.33, 66.66, 99.99],
    needleStartValue: 0,
  }

  constructor() { }

  ngOnInit(): void {
  }

  //ngOnChanges(changes: SimpleChanges) {
  //  console.log("CounterFraud onChanges : ", changes.counterFraud.currentValue);
  //  const color = changes.counterFraud.currentValue?.responseData?.data?.ragStatus;
  //  console.log("COlor : ", color);
  //  if (color) {
  //    if (color.toLowerCase() === 'green') {
  //      this.needleValue = 17;
  //      this.centralLabel = '';
  //    }
  //    if (color.toLowerCase() === 'amber') {
  //      this.needleValue = 50;
  //      this.centralLabel = '';
  //    }
  //    if (color.toLowerCase() === 'red') {
  //      this.needleValue = 85;
  //      this.centralLabel = '';
  //    }
  //  }
  //}

}
