<div class="app-outer-container">
  <div class="container">
    <div class="form-data-background form-borderdesign  shadow-lg p-3 #F7F7F7">
      <form (ngSubmit)="SearchDataDemo()">
        <div class="form-group row">
          <div class="col-md-9">
            <div class="row padding-form-design">
              <div class="col-md-12 z-index-formdata">
                <div class="row">
                  <div class="input-group">
                    <div class="col-md-3">
                      <b>Company Name:</b>
                    </div>
                    <div class="col-md-9">
                      <div class="form-group">
                        <input type="text" placeholder="" class="form-control" [(ngModel)]="companyName" name="reference" autocomplete="off" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row padding-form-design">
              <div class="col-md-12 z-index-formdata">
                <div class="row">
                  <div class="input-group">
                    <div class="col-md-3">
                      <b>CRO Number:</b>
                    </div>
                    <div class="col-md-9">
                      <div class="form-group">
                        <input type="text" placeholder="" class="form-control" [(ngModel)]="croNumber" name="croNumber" autocomplete="off" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row padding-form-design">
              <div class="col-md-12 z-index-formdata">
                <div class="row">
                  <div class="input-group">
                    <div class="col-md-3">
                      <b>Mobile Number:</b>
                    </div>
                    <div class="col-md-9">
                      <div class="form-group">
                        <input type="text" placeholder="" class="form-control" [(ngModel)]="mobileNumber" name="mobileNumber" autocomplete="off" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row padding-form-design pb-2">
              <!--<div class="col-md-2"></div>-->
              <div class="col-md-10">
                <div class="row">
                  <div class="input-group">
                    <div class="col-md-12">
                      <div class="form-group">
                        <button type="submit" class="btn colorRed float-left mx-2">
                          Search <i class="fa fa-search" aria-hidden="true"></i>
                        </button>
                        <button type="button" class="btn colorRed float-left mx-2" (click)="ClearButtonClick()">
                          Clear <i class="fa fa-times-circle fa-lg" aria-hidden="true"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="container">
        <h5>Result</h5>
        <div class="row">
          <div class="col-md-6">
            <h6><u>Company Data</u></h6>
            <div class="container">
              <div class="row">
                <div class="col-md-4">Registered Name : </div>
                <div class="col-md-8">{{search4LessResult?.coname}}</div>
              </div>
              <div class="row">
                <div class="col-md-4">Registered Number : </div>
                <div class="col-md-8">{{search4LessResult?.conum}}</div>
              </div>
              <div class="row">
                <div class="col-md-4">Registered Address : </div>
                <div class="col-md-8">{{search4LessResult?.addr}}</div>
              </div>
              <div class="row">
                <div class="col-md-4">Registered on : </div>
                <div class="col-md-8">{{search4LessResult?.regdate}}</div>
              </div>
              <div class="row">
                <div class="col-md-4">Company Status : </div>
                <div class="col-md-8">{{search4LessResult?.statusdesc}}</div>
              </div>
              <div class="row">
                <div class="col-md-4">Company Type : </div>
                <div class="col-md-8">{{search4LessResult?.typedesc}}</div>
              </div>
              <div class="row">
                <div class="col-md-4">Purpose : </div>
                <div class="col-md-8">{{search4LessResult?.prinobjectdesc}}</div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <h6><u>Telephone Data</u></h6>
            <div class="row">
              <div class="col-md-4">Number is Valid : </div>
              <div class="col-md-8">{{phronesisResult?.discover?.number?.isValid}}</div>
            </div>
            <div class="row">
              <div class="col-md-4">Country : </div>
              <div class="col-md-8">{{phronesisResult?.discover?.number?.country?.name}}</div>
            </div>
            <div class="row">
              <div class="col-md-4">Network Name : </div>
              <div class="col-md-8">{{phronesisResult?.discover?.number?.onNetwork?.networkName}}</div>
            </div>
            <div class="row">
              <div class="col-md-4">Ported : </div>
              <div class="col-md-8">{{phronesisResult?.discover?.number?.wasOnNetwork?.networkName}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
