import { Component, Input, OnInit } from '@angular/core';
import { BusinessResultModel } from '../../../../models/BusinessResultModel';

@Component({
  selector: 'app-non-ltd-business',
  templateUrl: './non-ltd-business.component.html',
  styleUrls: ['./non-ltd-business.component.css']
})
export class NonLtdBusinessComponent implements OnInit {

  @Input() public business: BusinessResultModel = new BusinessResultModel();

  constructor() { }

  ngOnInit(): void {
  }

}
