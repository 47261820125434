<div class="col-md-12" *ngIf="ava.searchFail && !ava.isLoading"><i class="color-grey float-right">{{messageService.SearchFail}}</i></div>
<div class="col-md-12" *ngIf="ava.noSearchSelect && !ava.isLoading"><i class="color-grey float-right">{{messageService.NoSearchSelect}}</i></div>
<div class="col-md-12" *ngIf="ava.noRelevantData && !ava.isLoading"><i class="color-grey float-right">{{messageService.NoRelevantDataMessage}}</i></div>
<div>
  <span class="loadingImg" *ngIf="ava.isLoading">
    <img src="{{messageService.loadingAnimation}}" />
  </span>
</div>
<div class="row" *ngIf="ava.responseData">
  <div class="col-md-12">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <!-- ------------------ vehicle details start ------------------ -->
          <h4>Vehicle Details</h4>
          <div id="avaVehicleDetailID" class="vehicle-wrapper container-fluid my-4" *ngIf="ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleRegistration;else noFoundVehicle">
            <div class="row">
              <div class="col-md-12">
                <table class="table vehicle-table table-borderless">
                  <tbody>
                    <!-- first row -->
                    <tr>
                      <th scope="row">VRN :</th>
                      <td data-table-title="VRN :">
                        {{ ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleRegistration?.VRM }}
                      </td>
                      <th>Engine Number :</th>
                      <td data-table-title="Engine Number :">
                        {{ ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleRegistration?.EngineNumber }}
                      </td>
                    </tr>
                    <!-- second row -->
                    <tr>
                      <th scope="row">VIN :</th>
                      <td data-table-title="VIN :">
                        {{ ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleRegistration?.VINSerialNumber }}
                      </td>
                      <th>Engine Capcity :</th>
                      <td data-table-title="Engine Capcity :">
                        {{ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleRegistration?.EngineCapacity}}
                      </td>
                    </tr>

                    <!-- third row -->
                    <tr>
                      <th scope="row">Make :</th>
                      <td data-table-title="Make :">
                        {{ ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleRegistration?.MakeModel }}
                      </td>
                      <th>Fuel Type :</th>
                      <td data-table-title="Fuel Type :">
                        {{ ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleRegistration?.Fuel }}
                      </td>
                    </tr>

                    <!-- fourth row -->
                    <tr>
                      <th scope="row">Model :</th>
                      <td data-table-title="Model :">
                        {{ ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleRegistration?.Model }}
                      </td>
                      <th>Year of Manufacture :</th>
                      <td data-table-title="Year of Manufacture :">
                        {{ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleRegistration?.YearOfManufacture}}
                      </td>
                    </tr>

                    <!-- fifth row -->
                    <tr>
                      <th scope="row">Colour :</th>
                      <td data-table-title="Colour :">
                        {{ ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleRegistration?.Colour }}
                      </td>
                      <th>First Registered in UK :</th>
                      <td data-table-title="First Registered in UK :">
                        {{ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleRegistration?.DateFirstRegistered | date: 'dd-MM-yyyy'}}
                      </td>
                    </tr>

                    <!-- sixth row -->
                    <tr>
                      <th scope="row">Body Type :</th>
                      <td data-table-title="Body Type :">
                        <div *ngIf="ava?.responseData?.advert">{{ ava?.responseData?.advert?.VehicleBodyType }}</div>
                      </td>
                      <th>Previous Keepers :</th>
                      <td data-table-title="Previous Keepers :">
                        {{ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.KeeperChangesDetails?.KeeperChangesCount}}
                      </td>
                    </tr>

                    <!-- seventh row -->
                    <tr>
                      <th scope="row">Transmission :</th>
                      <td data-table-title="Transmission :">
                        {{ ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleRegistration?.Transmission }}
                      </td>
                      <th>Co2 Emission :</th>
                      <td data-table-title="Co2 Emission :">
                        {{ ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleRegistration?.CO2Emissions }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <ng-template #noFoundVehicle>
            <div class="col-md-12"><i class="color-grey">{{NoRelevantDataMessage}}</i></div>
          </ng-template>
          <!-- ------------------ vehicle details end ------------------ -->
        </div>
      </div>
      <div class="row message-status-wrapper">
        <div class="col-md-6">

          <div class="col-md-12 mt-4 table-bordered message-wrapper">
            <h4>Messages</h4>
            <div id="avaMessages" *ngIf="ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.MessageDetails && ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.MessageDetails?.MessageCount>0;else noFoundMessage" class="">
              <div class="border-bottom" *ngFor="let message of ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.MessageDetails?.MessageList1">
                <div class="text-center">
                  <p>{{message?.MsgLine1}}</p>
                  <p>{{message?.MsgLine2}}</p>
                  <p>{{message?.MsgLine3}}</p>
                  <p>{{message?.MsgLine4}}</p>
                </div>
              </div>
            </div>
            <ng-template #noFoundMessage>
              <div class="col-md-12"><i class="color-grey">{{NoRelevantDataMessage}}</i></div>
            </ng-template>
          </div>
          <div class="table-bordered col-md-12 pt-4 caution-data-wrapper">
            <h4>Caution Data</h4>
            <div *ngIf="ava?.responseData?.vehicleData ;else noFoundCaution" class="my-2">
              <div id="avaCaution" *ngIf="ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleRegistration?.GBVRM || ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleRegistration?.NIVRM">
                <div class="table-bordered" *ngIf="ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleRegistration?.GBVRM">
                  <span class="align-left section-head">Additional DVLA Data</span>
                  <div>
                    Notified as previously registered at DVLA as&nbsp;
                    <span>{{ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleRegistration?.GBVRM}}</span>
                    <br />
                    <span>You must check the previous VRM.</span>
                  </div>
                </div>
                <div class="table-bordered" *ngIf="ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleRegistration?.NIVRM">
                  <span class="align-left section-head">Additional DVLA Data</span>
                  <div>
                    Notified as previously registered at DVLNI as&nbsp;
                    <span>{{ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleRegistration?.NIVRM}}</span>
                    <br />
                    <span>You must check the previous VRM.</span>
                  </div>
                </div>
              </div>
              <!-- Stolen -->
              <div id="cautionStolen" class="table-bordered" *ngIf="ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Stolen === 'WARNING' && ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.StolenVehicleDetails">
                <h5>Stolen</h5>
                <div class="row border-head">
                  <div class="col">
                    <p class="fw-bold mot-history-head">Date:</p>
                  </div>
                  <div class="col">
                    <p class="fw-bold mot-history-head">Status:</p>
                  </div>
                  <div class="col">
                    <p class="fw-bold mot-history-head">Police Force:</p>
                  </div>
                  <div class="col">
                    <p class="fw-bold mot-history-head">Contact No.:</p>
                  </div>
                </div>
                <hr />
                <div class="row mot-history-wrapper">
                  <div class="col">
                    <p>{{ ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.StolenVehicleDetails?.DateOfTransaction }}</p>
                  </div>
                  <div class="col">
                    <p>{{ ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.StolenVehicleDetails?.Status }}</p>
                  </div>
                  <div class="col">
                    <p>{{ ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.StolenVehicleDetails?.Force }}</p>
                  </div>
                  <div class="col">
                    <p>{{ ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.StolenVehicleDetails?.ContactNumber }}</p>
                  </div>
                </div>
              </div>
              <!-- End Stolen -->
              <!-- High Risk -->
              <div id="cautionHighRisk" class="table-bordered" *ngIf="ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.HighRisk === 'WARNING' && ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.HighRiskRecordDetails.HighRiskItems.Length > 0">
                <h5>High Risk</h5>
                <div class="row border-head" *ngFor="let highRisk of ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.HighRiskRecordDetails.HighRiskItems">
                  <div class="col">
                    <p class="fw-bold mot-history-head">High Risk Type: {{ highRisk.HighRiskType }}</p>
                  </div>
                  <div class="col">
                    <p class="fw-bold mot-history-head">Reference No.: ---</p>
                  </div>
                  <div class="col">
                    <p class="fw-bold mot-history-head">Company Name: {{ highRisk.CompanyName }}</p>
                  </div>
                  <div class="col">
                    <p class="fw-bold mot-history-head">Date of Interest: {{ highRisk.DateOfInterest}}</p>
                  </div>
                  <div class="col">
                    <p class="fw-bold mot-history-head">Contact No.: {{ highRisk.ContactNumber }}</p>
                  </div>
                  <div class="col">
                    <p class="fw-bold mot-history-head">Period: {{ highRisk.RegistrationPeriod }}</p>
                  </div>
                  <div class="col">
                    <p class="fw-bold mot-history-head">Contact Reference: {{ highRisk.ContactReference }}</p>
                  </div>
                  <div class="col">
                    <p class="fw-bold mot-history-head">Extra Information: {{ highRisk.ExtraInfo }}</p>
                  </div>
                </div>
              </div>
              <!-- End High Risk -->
              <!-- Finance -->
              <div id="cautionFinanca" class="table-bordered" *ngIf="(ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Finance === 'WARNING' || ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Finance === 'CAUTION') && ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.Finance?.FinanceDataRecordCount>0">
                <div class="col-md-12">
                  <h5>Finance</h5>
                  <div class="border-bottom" *ngFor="let Finance of ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.Finance?.FinanceDataRecords">
                    <div class="col-sm-12">
                      <div class="col-xs-12 align-left"><b>Date of Interest:</b> {{Finance?.AgreementDate | date:'dd-MM-yyyy'}}</div>
                    </div>
                    <div class="col-sm-12">
                      <div class="col-xs-12 align-left"><b>Agreement Type:</b> {{Finance?.AgreementType}}</div>
                    </div>
                    <div class="col-md-12">
                      <div class="col-xs-12 align-left"><b>Finance Company:</b> <span>{{Finance?.FinanceCompany}}</span></div>
                    </div>
                    <div class="col-sm-12">
                      <div class="col-xs-12 align-left"><b>Agreement Term:</b> <span>{{Finance?.AgreementTerm + ' months'}}</span></div>
                    </div>
                    <div class="col-sm-12">
                      <div class="col-xs-12 align-left"><b>Contact No.:</b>  <span>{{Finance?.ContactNumber}}</span></div>
                    </div>
                    <div class="col-sm-12">
                      <div class="col-xs-12 align-left"><b>Vehicle Description:</b> <span>{{Finance?.VehicleDescription}}</span></div>
                    </div>
                    <div class="col-sm-12">
                      <div class="col-xs-5 align-left"><b>Agreement No.:</b> <span>{{Finance?.AgreementNumber}}</span></div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- End Finance -->
              <!-- Condition -->
              <div id="cautionCondition" class="table-bordered" *ngIf="(ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Condition === 'WARNING' || ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Condition === 'CAUTION') && ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.ConditionData?.ConditionDetailsItems.length>0">
                <div class="col-sm-12">
                  <h5>ABI Condition</h5>
                  <div class="row border-bottom" *ngFor="let ConditionDetails of ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.ConditionData?.ConditionDetailsItems">
                    <div class="col-sm-12">
                      <div class="col-xs-12 align-center"><strong>Loss Type:</strong> {{ConditionDetails?.Status}}</div>
                    </div>
                    <div class="col-sm-12">
                      <div class="col-xs-12 align-center"><strong>Loss Date:</strong> {{ConditionDetails?.LossDate | date:'dd-MM-yyyy'}}</div>
                    </div>
                    <div class="col-sm-12">
                      <div class="col-xs-12 align-center"><strong>MIAFTR Entry Date:</strong> {{ConditionDetails?.MIAFTRENTRYDATE | date:'dd-MM-yyyy'}}</div>
                    </div>
                    <div class="col-sm-12">
                      <div class="col-xs-12 align-center"><strong>Make and Model:</strong> {{ConditionDetails?.Make + ' ' + ConditionDetails?.Model}}</div>
                    </div>
                    <div class="col-sm-12">
                      <div class="col-xs-12 align-center"><strong>Theft indicator:</strong> {{ConditionDetails?.TheftIndicatorLiteral}}</div>
                    </div>
                    <div class="col-sm-12"></div>
                  </div>
                </div>
              </div>
              <!-- End COndition -->
              <!-- Scrapped -->
              <div id="cautionScrapped" class="table-bordered" *ngIf="(ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Scrapped === 'WARNING' || ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Scrapped === 'CAUTION') && ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.ConditionData.ConditionDetailsItemsCount>0">
                <div class="col-sm-12">
                  <h5>Scrapped</h5>
                  <div *ngIf="ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleRegistration?.Scrapped === 1">
                    Recorded as scrapped on&nbsp;<span>{{ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleRegistration?.DateScrapped | date:'dd-MM-yyyy'}}</span>
                  </div>
                  <div class="container" *ngIf="ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleRegistration?.Scrapped === 2">
                    DVLA have recorded a Certificate of Destruction was issued on this vehicle on&nbsp;<span>{{ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleRegistration?.DateScrapped | date:'dd-MM-yyyy'}}</span>
                  </div>
                </div>
              </div>
              <!-- End Scrapped -->
              <!-- Plate Change -->
              <div id="cautionPlateChange" class="table-bordered" *ngIf="(ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.PlateChange === 'WARNING' || ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.PlateChange === 'CAUTION') && ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.PlateChangeDetails?.PlateChangeCount>0">
                <div class="col-sm-12">
                  <h5 class="section-head">Plate Change</h5>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="col-xs-12 align-center"><strong>Previous VRM</strong></div>
                    </div>
                    <div class="col-sm-6">
                      <div class="col-xs-12 align-center"><strong>Date of Transfer</strong></div>
                    </div>
                  </div>
                  <div class="row" *ngFor="let PlateChanges of ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.PlateChangeDetails?.PlateChanges1">
                    <div class="col-sm-6">
                      <div class="col-xs-12 align-center"><span>{{PlateChanges?.PreviousVRM}}</span></div>
                    </div>
                    <div class="col-sm-6">
                      <div class="col-xs-12 align-center"><span>{{PlateChanges?.DateOfReceipt | date:'dd-MM-yyyy'}}</span></div>
                    </div>
                  </div>
                  <div class="row pt-4" *ngIf="(ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.MessageDetails?.MessageList1).length > 0">
                    <div class="col-sm-12">
                      <span>
                        Current VRM&nbsp;
                        <strong>
                          <span>{{ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.PlateChangeDetails?.PlateChanges1[0].CurrentVRM}}</span>
                        </strong>
                        &nbsp;please check the plate for further details.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- End Plate Change -->
              <!-- Exported -->
              <div id="cautionExported" class="table-bordered" *ngIf="(ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Exported === 'WARNING' || ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Exported === 'CAUTION') && vaResult?.vehicleData?.ApprovedProgramCheckResult?.VehicleRegistration?.Exported === 1">
                <div class="col-sm-12">
                  <h5>Exported</h5>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="col-xs-12 align-center"><strong>Date</strong></div>
                    </div>
                    <div class="col-sm-6">
                      <div class="col-xs-12 align-center"><strong>Status</strong></div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="col-xs-12 align-center"><span>{{ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleRegistration?.DateExported | date:'dd-MM-yyyy'}}</span></div>
                    </div>
                    <div class="col-sm-6">
                      <div class="col-xs-12 align-center"><span>Exported</span></div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- End Exported -->
              <!-- Imported -->
              <div id="cautionImported" class="table-bordered" *ngIf="(ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Imported === 'CAUTION' || ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Imported === 'WARNING') && ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleRegistration?.Imported === 1">
                <div class="col-sm-12">
                  <h3>Potential Import</h3>
                  <div class="pt-4">
                    Previously registered and/or used, declared manufactured in&nbsp;<span>{{ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleRegistration?.YearOfManufacture}}</span>
                  </div>
                </div>
              </div>
              <!-- End Imported -->
              <!-- Colour Change -->
              <div id="cautionColourChange" class="table-bordered" *ngIf="ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.ColourChangesDetails?.ColourChanges1 && ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.ColourChangesDetails?.ColourChangeCount>0">
                <h5>Colour Change</h5>
                <div class="row">
                  <div class="col-sm-2">
                    <div class="col-xs-12 align-center"><strong>Colours</strong></div>
                  </div>
                  <div class="col-sm-5">
                    <div class="col-xs-12 align-center"><strong>Previous colour</strong></div>
                  </div>
                  <div class="col-sm-5">
                    <div class="col-xs-12 align-center"><strong>Date changed</strong></div>
                  </div>
                </div>
                <div class="row" *ngFor="let ColourChange of ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.ColourChangesDetails?.ColourChanges1">
                  <div class="col-sm-2">
                    <div class="col-xs-12 align-center"><span>{{ColourChange?.NumberOfPreviousColours}}</span></div>
                  </div>
                  <div class="col-sm-5">
                    <div class="col-xs-12 align-center"><span>{{ColourChange?.LastColour}}</span></div>
                  </div>
                  <div class="col-sm-5">
                    <div class="col-xs-12 align-center"><span>{{ColourChange?.DateOfLastColourChange | date:'dd-MM-yyyy'}}</span></div>
                  </div>
                </div>
              </div>
              <!-- End Colour Change -->
              <!-- Vehicle Identity Check -->
              <div id="cautionVehicleIdentityCheck" class="table-bordered" *ngIf="ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleIDCheckData?.VehicleIDCheck1 && ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleIDCheckData?.VICCount>0">
                <h5>Vehicle Identity Check</h5>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="col-xs-12 align-center"><strong>Result</strong></div>
                  </div>
                  <div class="col-sm-6">
                    <div class="col-xs-12 align-center"><strong>VIC Date</strong></div>
                  </div>
                </div>
                <div class="row" *ngFor="let VehicleIDCheck of ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleIDCheckData?.VehicleIDCheck1">
                  <div class="col-sm-6">
                    <div class="col-xs-12 align-center"><span>{{(VehicleIDCheck?.VICResult === 1)?'PASS':'FAIL'}}</span></div>
                  </div>
                  <div class="col-sm-6">
                    <div class="col-xs-12 align-center"><span>{{VehicleIDCheck?.VICDate | date:'dd-MM-yyyy'}}</span></div>
                  </div>
                </div>
              </div>
              <!-- End Vehicle Identity Check -->
            </div>
            <ng-template #noFoundCaution>
              <div class="col-md-12"><i class="color-grey">{{NoRelevantDataMessage}}</i></div>
            </ng-template>
          </div>
          <div class="col-md-12 pt-4 table-bordered keeper-changes-wrapper">
            <h4>Keeper Changes</h4>
            <table id="keeperChangesID" class="table keeper-table my-4 table-borderless" *ngIf="ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.KeeperChangesDetails;else noFoundKeepers">
              <!-- keepers -->
              <thead>
                <tr>
                  <th scope="col">Keeper</th>
                  <th scope="col">Last Changed</th>
                  <th scope="col">Transaction</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let keeper of ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.KeeperChangesDetails?.KeeperChanges1">
                  <td data-table-title="Keeper">
                    {{ keeper?.NumberOfPreviousKeepers }}
                  </td>
                  <td data-table-title="Last Changed">
                    {{ keeper?.DateOfLastKeeperChange | date:'dd-MM-yyyy'}}
                  </td>
                  <td data-table-title="Transaction">
                    {{ keeper?.DateOfTransaction | date:'dd-MM-yyyy'}}
                  </td>
                </tr>
              </tbody>
            </table>

            <ng-template #noFoundKeepers>
              <div class="col-md-12"><i class="color-grey">{{NoRelevantDataMessage}}</i></div>
            </ng-template>
          </div>
        </div>
        <div class="col-md-6">
          <div class="col-md-12 pt-4 status-wrapper">
            <!-- status start -->
            <h4>Status</h4>
            <div id="avaStatusID" *ngIf="ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatus || ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail;else noFoundStatus"
                 class="container-fluid"
                 [ngClass]="{
                        borderGreen: ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatus === 'CLEAR',
                        borderAmber: ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatus === 'CAUTION',
                        borderRed: ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatus === 'WARNING'
                      }">
              <div class="row">
                <div class="col-md-12 p-0">
                  <div class="status-heading"
                       [ngClass]="{
                              colorGreen: ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatus === 'CLEAR',
                              colorAmber: ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatus === 'CAUTION',
                              colorRed: ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatus === 'WARNING'
                            }">
                    <p>{{ ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatus }}</p>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4 p-2">
                  <div class="status"
                       [ngClass]="{
                              colorGreen:
                                ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Stolen === 'CLEAR',
                              colorAmber:
                                ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Stolen === 'CAUTION',
                              colorRed:
                                ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Stolen === 'WARNING'
                            }">
                    <p *ngIf="ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Stolen === 'CLEAR'">NOT STOLEN</p>
                    <p *ngIf="ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Stolen !== 'CLEAR'">STOLEN</p>
                  </div>
                </div>
                <div class="col-md-4 p-2">
                  <div class="status"
                       [ngClass]="{
                              colorGreen:
                                ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.HighRisk === 'CLEAR',
                              colorAmber:
                                ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.HighRisk === 'CAUTION',
                              colorRed:
                                ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.HighRisk === 'WARNING'
                            }">
                    <p *ngIf="ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.HighRisk === 'CLEAR'">NO HIGH RISK</p>
                    <p *ngIf="ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.HighRisk !== 'CLEAR'">HIGH RISK</p>
                  </div>
                </div>
                <div class="col-md-4 p-2">
                  <div class="status"
                       [ngClass]="{
                              colorGreen:
                                ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Finance === 'CLEAR',
                              colorAmber:
                                ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Finance === 'CAUTION',
                              colorRed: ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Finance === 'WARNING'
                            }">
                    <p *ngIf="ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Finance === 'CLEAR'">NO FINANCE</p>
                    <p *ngIf="ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Finance !== 'CLEAR'">FINANCE</p>

                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4 p-2">
                  <div class="status"
                       [ngClass]="{
                              colorGreen:
                                ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Condition === 'CLEAR',
                              colorAmber:
                                ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Condition === 'CAUTION',
                              colorRed:
                                ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Condition === 'WARNING'
                            }">
                    <p *ngIf="ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Condition === 'CLEAR'">NO ABI CONDITION</p>
                    <p *ngIf="ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Condition !== 'CLEAR'">ABI CONDITION</p>
                  </div>
                </div>
                <div class="col-md-4 p-2">
                  <div class="status"
                       [ngClass]="{
                              colorGreen:
                                ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Scrapped === 'CLEAR',
                              colorAmber:
                                ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Scrapped === 'CAUTION',
                              colorRed:
                                ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Scrapped === 'WARNING'
                            }">
                    <p *ngIf="ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Scrapped === 'CLEAR'">NOT SCRAPPED</p>
                    <p *ngIf="ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Scrapped !== 'CLEAR'">SCRAPPED</p>
                  </div>
                </div>
                <div class="col-md-4 p-2">
                  <div class="status"
                       [ngClass]="{
                              colorGreen:
                                ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.PlateChange === 'CLEAR',
                              colorAmber:
                                ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.PlateChange === 'CAUTION',
                              colorRed:
                                ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.PlateChange === 'WARNING'
                            }">
                    <p *ngIf="ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.PlateChange === 'CLEAR'">ORIGINAL PLATE</p>
                    <p *ngIf="ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.PlateChange !== 'CLEAR'">PLATE CHANGE</p>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4 p-2">
                  <div class="status"
                       [ngClass]="{
                              colorGreen:
                                ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Exported === 'CLEAR',
                              colorAmber:
                                ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Exported === 'CAUTION',
                              colorRed:
                                ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Exported === 'WARNING'
                            }">
                    <p *ngIf="ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Exported === 'CLEAR'">NO EXPORT DATA</p>
                    <p *ngIf="ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Exported !== 'CLEAR'">EXPORTED</p>
                  </div>
                </div>
                <div class="col-md-4 p-2">
                  <div class="status"
                       [ngClass]="{
                              colorGreen:
                                ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Imported === 'CLEAR',
                              colorAmber:
                                ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Imported === 'CAUTION',
                              colorRed:
                                ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Imported === 'WARNING'
                            }">
                    <p *ngIf="ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Imported === 'CLEAR'">NO IMPORT DATA</p>
                    <p *ngIf="ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.Imported !== 'CLEAR'">POTENTIAL IMPORT</p>
                  </div>
                </div>
                <div class="col-md-4 p-2">
                  <div class="status"
                       [ngClass]="{
                              colorGreen:
                                ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.ExtraData === 'CLEAR',
                              colorAmber:
                                ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.ExtraData === 'CAUTION',
                              colorRed:
                                ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.ExtraData === 'WARNING'
                            }">
                    <p *ngIf="ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.ExtraData === 'CLEAR'">NO EXTRA DATA</p>
                    <p *ngIf="ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.VehicleStatusDetail?.ExtraData !== 'CLEAR'">EXTRA DATA</p>
                  </div>
                </div>
              </div>
            </div>

            <ng-template #noFoundStatus>
              <div class="col-md-12"><i class="color-grey">{{NoRelevantDataMessage}}</i></div>
            </ng-template>
            <!-- status end -->
          </div>
          <!-- Previous search start -->
          <div class="col-md-12 mt-4 table-bordered previous-search-wrapper">
            <h4>
              <a data-toggle="collapse" role="button" aria-expanded="false" aria-controls="collapsePreviusSearch"
                 href="#collapsePreviusSearch" target="_blank">Previous Searches</a>
            </h4>
            <div class="collapse" id="collapsePreviusSearch">
              <table id="prviusSearchesID" class="table keeper-table my-4 table-borderless" *ngIf="ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.PreviousSearchesDetails;else noFoundPreviousSearches">
                <thead>
                  <tr>
                    <th scope="col">Type Of Business</th>
                    <th scope="col">Date Of Transaction</th>
                    <th scope="col">Time Of Transaction</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let search of ava?.responseData?.vehicleData?.ApprovedProgramCheckResult?.PreviousSearchesDetails?.SearchItems">
                    <td data-table-title="Type Of Business">
                      {{ search?.TypeOfBusiness }}
                    </td>
                    <td data-table-title="Date Of Transaction">
                      {{ search?.DateOfTransaction| date:'dd-MM-yyyy' }}
                    </td>
                    <td data-table-title="Time Of Transaction">
                      {{ search?.TimeOfTransaction }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <ng-template #noFoundPreviousSearches>
                <div class="col-md-12"><i class="color-grey">{{NoRelevantDataMessage}}</i></div>
              </ng-template>
            </div>
          </div>
          <!-- Previous search end -->
        </div>
      </div>

      <div class="row pt-4" [ngClass]="{'print-mot-wrapper': ava?.responseData?.mot?.Response?.DataItems?.MotHistory?.RecordList}">
        <div class="col-md-12 table-bordered">
          <!-- mot start -->
          <h4>Mot History</h4>
          <div class="container my-4" *ngIf="ava?.responseData?.mot?.Response?.DataItems?.MotHistory?.RecordList;else noFoundMotHistory">
            <div class="row border-head">
              <div class="col">
                <p class="fw-bold mot-history-head">Test Date</p>
              </div>
              <div class="col">
                <p class="fw-bold mot-history-head">Expiry Date</p>
              </div>
              <div class="col">
                <p class="fw-bold mot-history-head">Test Result</p>
              </div>
              <div class="col">
                <p class="fw-bold mot-history-head">
                  Odometer Reading
                </p>
              </div>
              <div class="col">
                <p class="fw-bold mot-history-head">
                  Mot Test Number
                </p>
              </div>
            </div>
            <hr />
            <div class="row mot-history-wrapper"
                 *ngFor="let mot of ava?.responseData?.mot?.Response?.DataItems?.MotHistory?.RecordList">
              <div class="col">
                <p>{{ mot?.TestDate }}</p>
              </div>
              <div class="col">
                <p>{{ mot?.ExpiryDate }}</p>
              </div>
              <div class="col">
                <p>{{ mot?.TestResult }}</p>
              </div>
              <div class="col">
                <p>{{ mot?.OdometerReading }}</p>
              </div>
              <div class="col">
                <p>{{ mot?.TestNumber }}</p>
              </div>

              <div class="col-md-12 my-2"
                   *ngIf="mot?.advisoryNoticeList">
                <h5>Advisory Notice Items</h5>
                <ul class="mx-2 advisory-ul">
                  <li *ngFor="let notice of mot?.AdvisoryNoticeList"
                      class="mot-advisory-notice">
                    {{ notice }}
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <ng-template #noFoundMotHistory>
            <div class="col-md-12"><i class="color-grey">{{NoRelevantDataMessage}}</i></div>
          </ng-template>
          <!-- mot end -->
        </div>
      </div>

      <div class="row pt-4">
        <div class="col-md-12 advert-wrapper">
          <h4>Advert</h4>
          <div class="col-md-12 advert-heading" *ngIf="ava?.responseData?.advert?.AdvertSourceData;else noFoundAdvert">
            <div id="avaadvertID{{j}}" class="row mb-12 table-bordered" *ngFor="let advert of ava?.responseData?.advert?.AdvertSourceData; let j = index">
              <div class="col-md-12">
                <p class="card-title">
                  <b>Title:</b><a href="{{advert?.AdvertLink}}" target="_blank"> {{ advert?.AdvertTitle }}</a>
                </p>
              </div>
              <div class="col-md-6">
                <div id="advertImage{{j}}" class="carousel slide" data-ride="carousel">
                  <!-- Indicators -->
                  <!--<ul class="carousel-indicators">
                    <li data-target="#advertImage" data-slide-to="0" class="active"></li>
                    <li data-target="#advertImage" data-slide-to="1"></li>
                    <li data-target="#advertImage" data-slide-to="2"></li>
                  </ul>-->
                  <!-- The slideshow -->
                  <div class="carousel-inner">
                    <div *ngFor="let imgUrl of advert?.VehicleImages; let i = index" [ngClass]="i==0 ?'active':''" class="carousel-item">
                      <img id="{{advert.AdvertId}}" class="imageSize" src="{{imgUrl}}" alt="Los Angeles" alt="Car Image">
                    </div>
                  </div>
                  <!-- Left and right controls -->
                  <a class="carousel-control-prev" href="#advertImage{{j}}" data-slide="prev">
                    <span class="carousel-control-prev-icon"></span>
                  </a>
                  <a class="carousel-control-next" href="#advertImage{{j}}" data-slide="next">
                    <span class="carousel-control-next-icon"></span>
                  </a>
                </div>
                <!--<img class="card-img-top"
                src="https://mdbootstrap.com/img/Photos/Horizontal/City/4-col/img%20(60).jpg" alt="Card image cap">-->
              </div>
              <div class="colo-md-6 card-body">

                <!--<h4 class="card-title">Source: {{ advert?.AdvertSource }}</h4>-->
                <div class="card-text">
                  <div class="col">
                    <p><b>Source:</b> {{ advert?.AdvertSource  }}</p>
                  </div>
                  <div class="col">
                    <p><b>Mileage:</b> {{ advert?.vehicleMileage }}</p>
                  </div>
                  <div class="col">
                    <p><b>Posted on:</b> {{ advert?.VehiclePostedOn | date:'dd-MM-yyyy'}}</p>
                  </div>
                  <div class="col">
                    <p><b>Price:</b> {{ advert?.VehiclePrice }}</p>
                  </div>
                  <div class="col" *ngIf="advert?.Seller">
                    <p><b>Seller:</b> {{ advert?.Seller}}</p>
                  </div>
                  <div class="col" *ngIf="advert?.DetailedData?.SellerDetail?.SellerEmail">
                    <p><b>Seller Email:</b>{{ advert?.DetailedData?.SellerDetail?.SellerEmail}}</p>
                  </div>
                  <div class="col" *ngIf="advert?.DetailedData?.SellerDetail?.SellerMobile">
                    <p><b>Seller Mobile:</b> {{ advert?.DetailedData?.SellerDetail?.SellerMobile}}</p>
                  </div>
                  <div class="col" *ngIf="advert?.DetailedData?.SellerDetail?.SellerLocation">
                    <p><b>Seller Location:</b> {{ advert?.DetailedData?.SellerDetail?.SellerLocation }}</p>
                  </div>
                </div>
                <!--<a class="btn btn-primary">Button</a>-->
              </div>
              <div class="col-md-12 my-2">
                <h5>
                  <a data-toggle="collapse" role="button" aria-expanded="false" aria-controls="collapseDescription"
                     href="#collapseDescription{{j}}" target="_blank">Description</a>
                </h5>
                <div class="collapse" id="collapseDescription{{j}}">
                  <p class="vehicle-description">
                    {{ advert?.VehicleDescription }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <ng-template #noFoundAdvert>
            <div class="col-md-12"><i class="color-grey">{{NoRelevantDataMessage}}</i></div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
