import { ToastMessageComponent } from './shared/components/toast-message/toast-message.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NoCacheHeadersInterceptor } from './Cache-Interceptor';
import { GaugeChartModule } from 'angular-gauge-chart'
//import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { TooltipModule } from 'primeng/tooltip';
import { ToastModule} from 'primeng/toast';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import { CardModule } from 'primeng/card';
import { DynamicDialogModule } from 'primeng/dynamicdialog'
import { Ng2TelInputModule } from 'ng2-tel-input';
import { NgxSliderModule } from '@angular-slider/ngx-slider';

/** Custom modules import */
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './shared/components/login/login.component';
import { UserRegisterComponent } from './shared/components/user-register/user-register.component';
import { ThankYouMessagesComponent } from './shared/components/thank-you-messages/thank-you-messages.component';
import { PagenotfoundComponent } from './shared/components/pagenotfound/pagenotfound.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { HomeComponent } from './home/home.component';
import { HeadsUpInterceptor } from './HeadsUpInterceptor';
import { ForgetPasswordComponent } from './shared/components/forget-password/forget-password.component';
import { ResetPasswordComponent } from './shared/components/resetpassword/resetpassword.component';
import { ResendVerificationlinkComponent } from './shared/components/resend-verificationlink/resend-verificationlink.component';
import { ContactFormComponent } from './shared/components/contact-form/contact-form.component';
import { ScreenSearchDataComponent } from './screensearch/screen-search-data/screen-search-data.component';
import { FilenamePipe } from './screensearch/filename.pipe';
import { SubscriptionComponent } from './subscription/subscription.component';
import { ExcelService } from './subscription/service/excel.service';
import { ConfirmationService } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { TermsAndConditionsComponent } from './shared/components/terms-and-conditions/terms-and-conditions.component';
import { CookiePolicyComponent } from './shared/components/cookie-policy/cookie-policy.component';
import { InputNumberModule } from 'primeng/inputnumber';
import { ExportpdfComponent } from './exportpdf/exportpdf.component';
//import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { SearchFormComponent } from './exportpdf/components/search-form/search-form.component';
import { AccessToggleComponent } from './exportpdf/components/access-toggle/access-toggle.component';
import { ResultTopCardComponent } from './exportpdf/components/result-top-card/result-top-card.component';
import { MobileEnquiryComponent } from './exportpdf/components/mobile-enquiry/mobile-enquiry.component';
import { CounterFraudComponent } from './exportpdf/components/counter-fraud/counter-fraud.component';
import { EmailCheckComponent } from './exportpdf/components/email-check/email-check.component';
import { FinancialCheckComponent } from './exportpdf/components/financial-check/financial-check.component';
import { AmlCheckComponent } from './exportpdf/components/aml-check/aml-check.component';
import { AvaCheckComponent } from './exportpdf/components/ava-check/ava-check.component';
import { CJJCheckComponent } from './exportpdf/components/cjj-check/cjj-check.component';
import { InsolvencyCheckComponent } from './exportpdf/components/insolvency-check/insolvency-check.component';
import { AddressCheckComponent } from './exportpdf/components/address-check/address-check.component';
import { ProspectCheckComponent } from './exportpdf/components/prospect-check/prospect-check.component';
import { DemoComponent } from './demo/ireland-demo/demo.component';
import { UsidcheckdemoComponent } from './demo/usidcheckdemo/usidcheckdemo.component';
import { V3Module } from './demo/V3/v3.module';


@NgModule({
  declarations: [
    AppComponent,
    PagenotfoundComponent,
    UserRegisterComponent,
    HomeComponent,
    ThankYouMessagesComponent,
    ResendVerificationlinkComponent,
    ContactFormComponent,
    ScreenSearchDataComponent,
    SubscriptionComponent,
    FilenamePipe,
    ToastMessageComponent,
    ExportpdfComponent,
    SearchFormComponent,
    AccessToggleComponent,
    ResultTopCardComponent,
    MobileEnquiryComponent,
    CounterFraudComponent,
    EmailCheckComponent,
    FinancialCheckComponent,
    AmlCheckComponent,
    AvaCheckComponent,
    CJJCheckComponent,
    InsolvencyCheckComponent,
    AddressCheckComponent,
    ProspectCheckComponent,
    DemoComponent,
    UsidcheckdemoComponent
  ],
  imports: [
    TableModule,
    CardModule,
    CheckboxModule,
    DropdownModule,
    TooltipModule,
    ConfirmDialogModule,
    ToastModule,
    ButtonModule,
    InputNumberModule,
    GaugeChartModule,
    DynamicDialogModule,
    NgxSliderModule,
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    ModalModule.forRoot(),
    //TooltipModule.forRoot(),
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    Ng2TelInputModule,
    V3Module,
    RouterModule.forRoot([
      { path: '', component: LoginComponent },
      //{ path: 'search', component: HomeComponent, canActivate: [AuthGuard] },
      { path: 'Login', component: LoginComponent },
      { path: 'ForgetPassword', component: ForgetPasswordComponent },
      { path: 'ResetPassword', component: ResetPasswordComponent },
      { path: 'Thankyou', component: ThankYouMessagesComponent },
      { path: 'ResendLink', component: ResendVerificationlinkComponent },
      { path: 'ContactUs', component: ContactFormComponent },
      //{ path: 'screen', component: ScreenSearchDataComponent },
      { path: 'settings', component: SubscriptionComponent, canActivate: [AuthGuard] },
      //{ path: 'demo', component: DemoComponent },
      //{ path: 'usidcheckdemo', component: UsidcheckdemoComponent },
      //{ path: 'cookie-policy', component: CookiePolicyComponent },
      { path: '**', component: PagenotfoundComponent }
    ]),
    //PDFExportModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    ExcelService,
    DatePipe,
    //{ provide: HTTP_INTERCEPTORS, useClass: HeadsUpInterceptor, multi: true },
    UserRegisterComponent,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NoCacheHeadersInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    TermsAndConditionsComponent
  ]
})
export class AppModule { }
