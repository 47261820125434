import { Component, Input, OnInit } from '@angular/core';
import { MessagingService } from '../../../../../../services/messaging.service';
import { CounterFraudResultModel } from '../../../../../models/CounterFraudResultModel';

@Component({
  selector: 'app-prospect-dashboard-personal',
  templateUrl: './prospect-dashboard-personal.component.html',
  styleUrls: ['./prospect-dashboard-personal.component.css', '../../../../shared/shared.searchpage.css']
})
export class ProspectDashboardPersonalComponent implements OnInit {

  @Input() counterFraud: CounterFraudResultModel = new CounterFraudResultModel();

  constructor(public messageService: MessagingService) { }

  ngOnInit(): void {
  }

}
