import { Items } from "./Items";

export class CriminalityResultModel {
  public lexiconStatus: LexiconStatus[] = [];
  public noRelevantDataCriminality: boolean;
  public noSearchSelect: boolean;
  public resultCount: number;
  public isResultValues: boolean;
  public showAllResults: boolean;
  public showRelevantResults: boolean;
  //public lexicons: string[] = ["Risk"];
  public lexicons: string[] = ["Risk", "Theft", "Fraud", "Driving", "Drugs", "Violence", "Crime", "Officials", "Offenders"];
  public compareLexicons: string[] = [];
  public rankingResults: Items[] = [];
  public sortFinalResults: Items[] = [];
  public relevantResults: Items[] = [];
  public finalResults: Items[] = [];
  public searchUniqueIdlist: string[] = [];
  public filterLexiconStatus: LexiconStatus;
  public devData: boolean = true;
  public domainResults: Items[] = [];
  public criminalitySuccessCount: number = 0;
  public crimeSearchResultCount: number;
  public resultComplete: boolean;
  public criminalityRelevantRank: number = 0;
  public collapseResultStatus: string;
  public collapseResultIcon: string;
  public collapseResultClass: string;
  public isLoading: boolean;
}

export class LexiconStatus {
  constructor(
    public name: string,
    public status: string,
    public searchloading: boolean,
    public searchStatusCount: number,
    public analysisStatusCount: number
  ) { }
}
