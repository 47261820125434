import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PDFExportComponent } from '@progress/kendo-angular-pdf-export';
import { ConfirmationService } from 'primeng/api';
import { MessagingService } from '../../../services/messaging.service';
import { SearchService } from '../../../services/search.service';
import { Shared } from '../../../services/shared';
import { ProspectResultModel } from '../../models/CounterFraudResultModel';
import { EmailageCheckResultModel, EmailCheckResultModel } from '../../models/EmailResultModel';
import { Item } from '../../models/Items';
import { AccessData, ProfileData, RequestData } from '../../models/SearchRequestModel';
import { SearchType } from '../../models/SearchType';
import { TenantRegion } from '../../models/TenantRegionResposne';
import { MobileResultModel } from '../../models/MobileResultModel';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.css', '../shared/shared.searchpage.css']
})
export class EmailComponent implements OnInit {
  public regionAccess: TenantRegion = new TenantRegion();
  public emailForm: FormGroup;
  public validation: any = {
    'reference': [Validators.required],
    'email': [Validators.required, Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$')]
  };
  public accessData: AccessData = new AccessData();
  public showResultTab: boolean = false;
  public saveAsPDFButtonVisibility: boolean = true;
  public clearSearchButtonVisibility: boolean = true;
  public loggedInUsername: string = "";

  public emailageOrgId: string = "";
  public emailValidationResultModel = new EmailCheckResultModel();
  public emailageResultModel = new EmailageCheckResultModel();
  public prospectResultModel: ProspectResultModel = new ProspectResultModel();
  public opensourceResult: MobileResultModel = new MobileResultModel();

  // PDF
  public requestDataModel: RequestData = new RequestData();
  @ViewChild('pdf') pdf: PDFExportComponent;

  constructor(private elementRef: ElementRef,
    private formBuilder: FormBuilder,
    private searchService: SearchService,
    private router: Router,
    private messageService: MessagingService,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit(): void {
    let isLoggedIn = localStorage.getItem("access_token") ? true : false;
    if (!isLoggedIn)
      this.router.navigate(['/Login']);
    this.saveAsPDFButtonVisibility = false;
    this.clearSearchButtonVisibility = false;
    this.searchService.GetAllowedSearchAvailablityItems().subscribe(data => {
      let keys = Array.from(data).map(x => x.key);
      if (!keys.find(x => x == "EMAIL")) {
        this.router.navigate(['/v3']);
      }
    }, error => {
      console.log(error);
    })

    this.emailForm = this.formBuilder.group({
      audit: [""],
      reference: [""],
      email: [""]
    });
    this.getUserDetails();
    this.getTenantRegion();
    this.CheckProspectHitAndProfileAccess();
    this.getLicenceItems();
    this.getEmailageOrgId();
  }

  ngAfterViewInit() {
    this.elementRef.nativeElement.ownerDocument.body.style.background = 'url(/assets/Images/new/Background/HUT-elem-bg-1.png)';
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundAttachment = 'fixed';
  }

  public emailSearch(): void {
    this.addValidators();
    this.emailForm.updateValueAndValidity();

    if (this.emailForm.valid) {

      Promise.resolve(this.getRandomString(10)).then(
        (data) => {
          this.saveAsPDFButtonVisibility = true;
          this.clearSearchButtonVisibility = true;

          this.resetResultObjects();

          this.showResultTab = true;
          var requestObj: RequestData = this.prepareRequestData();

          // Email Validation Check
          this.emailValidationCheck(requestObj);

          if (this.prospectResultModel.isProspectHitAccess && !this.regionAccess.isAURegion) {
            // Prospect Hit Check
            this.prospectHitCheck(requestObj);
          }

          // Email OpenSource
          this.openSourceEmailSearch(requestObj);

          // Load Emailage
          this.loadEmailAgeCheck(requestObj);

        }
      );

    }
  }

  public openSourceEmailSearch(requestObj: RequestData): void {
    this.opensourceResult.isLoading = false;
    this.opensourceResult.noSearchSelect = false;
    this.opensourceResult.responseData = null;
    this.opensourceResult.searchFail = false;
    this.opensourceResult.validationError = false;

    if (requestObj.accessData.isEmailAccess) {
      if (this.emailForm.get('email').value) {
        this.opensourceResult.isLoading = true;
        this.searchService.OpenSourceEmailSearch(requestObj).subscribe(data => {
          this.opensourceResult.isLoading = false;
          this.opensourceResult.responseData = data;
        }, error => {
          console.log(error);
          this.opensourceResult.isLoading = false;
          this.opensourceResult.searchFail = true;
        });
      } else {
        this.opensourceResult.validationError = true;
      }
    } else {
      this.opensourceResult.noSearchSelect = true;
    }
  }

  public getTenantRegion() {
    this.searchService.GetTenantRegion().subscribe(data => {
      this.regionAccess = data;
    }, error => {
      console.log(error);
    });
  }

  public generatePDF() {
    var requestObj: RequestData = this.prepareRequestData();
    this.requestDataModel = requestObj;

    setTimeout(() => {
      this.pdf.forcePageBreak = ".page-break"
      this.pdf.keepTogether = ".keep-together";
      this.pdf.paperSize = "A4";
      const margin = {
        top: '2cm',
        bottom: '3cm',
        left: '1cm',
        right: '1cm'
      }
      this.pdf.margin = margin;
      this.pdf.scale = 0.5;
      this.pdf.saveAs('export.pdf');
    }, 2000);
  }

  public getUserDetails() {
    this.searchService.getUserDetails().subscribe(data => {
      this.loggedInUsername = data.username;
    }, error => {
      console.log(error);
    })
  }

  // Check about the access of Prospect hit & Access API
  public CheckProspectHitAndProfileAccess() {
    this.prospectResultModel.isProspectHitAccess = false;
    this.prospectResultModel.isProspectProfileAccess = false;
    this.searchService.CheckProsectHitAndProfileAccessClient().subscribe(data => {
      this.prospectResultModel.isProspectHitAccess = data['isProspectHitEnabled'];
      this.prospectResultModel.isProspectProfileAccess = data['isProspectProfileEnabled'];
    });
  }

  // Prospect profile request from prospect result component
  public prospectProfileRequest() {
    var requestObj: RequestData = this.prepareRequestData();
    this.prospectProfileCheck(requestObj);
  }

  // Prospect profile check API call
  public prospectProfileCheck(requestObj: RequestData): void {
    if (!this.prospectResultModel.prospectProfileButtonDisable) {
      this.confirmationService.confirm({
        key: "ProspectProfileRequest",
        message: 'In the event of any green tick(s) below, full Prospect detail can be requested and this will be provided to the requestor via email within 2 working days.',
        header: 'Confirmation',
        icon: 'pi pi-check',
        accept: () => {
          if (this.prospectResultModel.prospectHitResponseData) {
            this.prospectResultModel.isProspectProfileLoading = true;
            this.prospectResultModel.prospectProfileResponseData = null;
            this.searchService.GetProspectProfileData(requestObj).subscribe((data) => {
              this.prospectResultModel.isProspectProfileLoading = false;
              this.prospectResultModel.prospectProfileResponseData = data;
              this.prospectResultModel.prospectProfileButtonText = this.messageService.prospectProfileButtonText_Requested;
              this.prospectResultModel.prospectProfileButtonDisable = true;
            }, (error) => {
              console.log(error);
              this.prospectResultModel.isProspectProfileLoading = false;
              this.prospectResultModel.prospectProfileButtonDisable = true;
            });
          }
        },
        reject: () => {
        }
      });
    }

  }

  // Prospect hit check API call
  public prospectHitCheck(requestObj: RequestData): void {
    this.prospectResultModel.noSearchSelect = false;
    if (requestObj.accessData.isCounterFraudAccess) {
      this.prospectResultModel.isProspectHitLoading = false;
      this.prospectResultModel.isProspectProfileLoading = false;
      this.prospectResultModel.validation = false;
      this.prospectResultModel.searchFail = false;
      this.prospectResultModel.prospectHitResponseData = null;
      this.prospectResultModel.prospectProfileResponseData = null;
      this.prospectResultModel.prospectProfileButtonDisable = false;
      this.prospectResultModel.prospectProfileButtonText = this.messageService.prospectProfileButtonText_RequestProfile;

      if ((requestObj.profileData.fullName && requestObj.profileData.dateOfBirth) ||
        (requestObj.profileData.postalCode && requestObj.profileData.addressLine1) ||
        requestObj.profileData.phoneNumber || requestObj.profileData.email || requestObj.profileData.vRN) {
        this.prospectResultModel.profileData = requestObj.profileData;
        this.prospectResultModel.isProspectHitLoading = true;
        this.searchService.GetProspectHitData(requestObj).subscribe((data) => {
          this.prospectResultModel.isProspectHitLoading = false;
          this.prospectResultModel.prospectHitResponseData = data;
          this.prospectResultModel.prospectProfileButtonDisable = this.prospectResultModel.prospectHitResponseData.hits.filter(x => x.i2Hit == true).length > 0 ? false : true;
        }, (error) => {
          this.prospectResultModel.isProspectHitLoading = false;
          this.prospectResultModel.searchFail = true;
          console.log(error);
        });
      } else {
        this.prospectResultModel.validation = true;
      }
    } else {
      this.prospectResultModel.noSearchSelect = true;
    }
  }

  // Email validation check API call
  public emailValidationCheck(requestObj: RequestData): void {
    this.emailValidationResultModel.isLoading = false;
    this.emailValidationResultModel.noRelevantData = false;
    this.emailValidationResultModel.responseData = null;
    this.emailValidationResultModel.searchFail = false;
    this.emailValidationResultModel.validation = false;
    this.emailValidationResultModel.noSearchSelect = false;

    if (requestObj.accessData.isEmailAccess) {
      if (requestObj.profileData.email) {
        this.emailValidationResultModel.isLoading = true;
        this.searchService.EmailValidationCheck(requestObj).subscribe((data) => {

          var debounceReason = data["data"].debounce.reason;

          // Email Age
          this.emailAgeCheck(requestObj, debounceReason);

          this.emailValidationResultModel.isLoading = false;
          this.emailValidationResultModel.responseData = data["data"];
          if (this.emailValidationResultModel?.responseData == null) {
            this.emailValidationResultModel.noRelevantData = true;
          }
        }, (error) => {

          // Email Age
          this.emailAgeCheck(requestObj, "");

          console.log(error);
          this.emailValidationResultModel.isLoading = false;
          if (error.status === 403) {
            this.emailValidationResultModel.searchFail = true;
          }
        });
      } else {
        this.emailValidationResultModel.validation = true;
      }
    } else {
      this.emailValidationResultModel.noSearchSelect = true;
    }

  }

  // Load email age API call
  public loadEmailAgeCheck(requestObj: RequestData): void {
    this.emailageResultModel.isLoading = false;
    this.emailageResultModel.responseData = null;
    this.emailageResultModel.searchFail = false;
    this.emailageResultModel.noSearchSelect = false;

    if (requestObj.profileData.email && requestObj.accessData.isEmailAccess) {
      this.emailageResultModel.isLoading = true;
    } else {
      this.emailageResultModel.noSearchSelect = true;
    }
  }

  // Email age API call
  public emailAgeCheck(requestObj: RequestData, debounceReason: string): void {
    this.emailageResultModel.isLoading = false;
    this.emailageResultModel.responseData = null;
    this.emailageResultModel.searchFail = false;
    this.emailageResultModel.noSearchSelect = false;

    if (requestObj.profileData.email && requestObj.accessData.isEmailAccess) {
      this.emailageResultModel.isLoading = true;
      this.searchService.EmailAge(requestObj, debounceReason).subscribe((data) => {
        this.emailageResultModel.isLoading = false;
        try {
          let completeJson = JSON.parse(data["data"]["completeResponse"]);
          let rawJson: string = completeJson["integration_hub_results"][this.emailageOrgId + ":emailage"]["Emailage"]["tps_vendor_raw_response"];
          completeJson["integration_hub_results"][this.emailageOrgId + ":emailage"]["Emailage"]["tps_vendor_raw_response"] = JSON.parse(rawJson.trim());
          data["data"]["completeResponse"] = completeJson;
        } catch { }
        this.emailageResultModel.responseData = data;
        var rawResult = Array.from(this.emailageResultModel.responseData["data"]["completeResponse"]["integration_hub_results"][this.emailageOrgId + ":emailage"]["Emailage"]["tps_vendor_raw_response"]["query"]["results"]);
        this.emailageResultModel.urls = [];
        rawResult.forEach(x => {
          Array.from(x["smlinks"]).forEach(y => {
            this.emailageResultModel.urls.push(y);
          });
        });
      }, (error) => {
        console.log(error);
        this.emailageResultModel.searchFail = true;
        this.emailageResultModel.isLoading = false;
      });
    } else {
      this.emailageResultModel.noSearchSelect = true;
    }

  }

  public async getRandomString(length) {
    await this.searchService.GetAuditKey().then(
      data => {
        this.emailForm.patchValue({
          audit: data
        });
      });
  }

  // Reset the result objects
  public resetResultObjects() {
    this.emailValidationResultModel = new EmailCheckResultModel();
    this.emailageResultModel = new EmailageCheckResultModel();
  }

  public getEmailageOrgId() {
    this.searchService.GetEmailageOrdId().subscribe(data => {
      this.emailageOrgId = data;
    });
  }

  public getLicenceItems(): void {
    this.searchService.AllowedLicence().subscribe(data => {
      this.getSecondAccess(data);
    });
  }

  public getSecondAccess(Items: Item[]) {
    Items.forEach(item => {
      if (item.licenceType.toLowerCase() == "search") {
        switch ((item.key)) {
          case "HUTDB": {
            this.accessData.isHUTDatabaseAccess = item.defaultValue;
            this.accessData.isHUTDatabaseToggle = item.toggle;
            break;
          }
          case "MOBILE": {
            this.accessData.isMobileAccess = item.defaultValue;
            this.accessData.isMobileToggle = item.toggle;
            break;
          }
          case "EMAIL": {
            this.accessData.isEmailAccess = item.defaultValue;
            this.accessData.isEmailToggle = item.toggle;
            break;
          }
          case "IDENTITY": {
            this.accessData.isIdentityAccess = item.defaultValue;
            this.accessData.isIdentityToggle = item.toggle;
            break;
          }
          case "FINANCIAL": {
            this.accessData.isFinancialAccess = item.defaultValue;
            this.accessData.isFinancialToggle = item.toggle;
            break;
          }
          case "ADDRESS": {
            this.accessData.isAddressAccess = item.defaultValue;
            this.accessData.isAddressToggle = item.toggle;
            break;
          }
          case "CRIMINALITY": {
            this.accessData.isCriminalityAccess = item.defaultValue;
            this.accessData.isCriminalityToggle = item.toggle;
            break;
          }
          case "SOCIAL": {
            this.accessData.isSocialMediaAccess = item.defaultValue;
            this.accessData.isSocialMediaToggle = item.toggle;
            break;
          }
          case "COUNTER_FRAUD": {
            this.accessData.isCounterFraudAccess = item.defaultValue;
            this.accessData.isCounterFraudToggle = item.toggle;
            break;
          }
          case "SPORTS": {
            this.accessData.isSportsAccess = item.defaultValue;
            this.accessData.isSportsToggle = item.toggle;
            break;
          }
          case "ADVERT": {
            this.accessData.isAdvertsAccess = item.defaultValue;
            this.accessData.isAdvertsToggle = item.toggle;
            break;
          }
          case "COMPANY_HOUSE": {
            this.accessData.isCompaniesHouseAccess = item.defaultValue;
            this.accessData.isCompaniesHouseToggle = item.toggle;
            break;
          }
          case "AVA": {
            this.accessData.isAVAAccess = item.defaultValue;
            this.accessData.isAVAToggle = item.toggle;
            break;
          }
          case "AML": {
            this.accessData.isAMLAccess = item.defaultValue;
            this.accessData.isAMLToggle = item.toggle;
            break;
          }
          case "CCJ_Detail": {
            this.accessData.isCCJDetailAccess = item.defaultValue;
            this.accessData.isCCJDetailToggle = item.toggle;
            break;
          }
          case "Insolvency_Detail": {
            this.accessData.isInsolvencyDetailAccess = item.defaultValue;
            this.accessData.isInsolvencyDetailToggle = item.toggle;
            break;
          }
          case "RTW": {
            this.accessData.isRTWAccess = item.defaultValue;
            this.accessData.isRTWToggle = item.toggle;
            break;
          }
          case "IDCHECK": {
            this.accessData.isIDCheckAccess = item.defaultValue;
            this.accessData.isIDCheckToggle = item.toggle;
            break;
          }
        }
      }
    });
  }

  public onEmailChange(): void {
    this.emailForm.get('email').clearValidators();
    this.emailForm.get('email').setValidators(this.validation['email']);
    this.emailForm.get('email').updateValueAndValidity();
  }

  // Add the validations during the 'search' button click
  public addValidators(): void {
    for (const control in this.emailForm.controls) {
      this.emailForm.get(control).clearValidators();
      this.emailForm.get(control).setValidators(this.validation[control]);
      this.emailForm.get(control).updateValueAndValidity();
    }
  }

  // Clear the validations during the 'clear' button click
  // This'll remove all the error message
  public emailClearSearch(): void {
    for (const control in this.emailForm.controls) {
      this.emailForm.get(control).clearValidators();
      this.emailForm.get(control).updateValueAndValidity();
    }
    this.emailForm.patchValue({
      audit: "",
      reference: "",
      email: ""
    });
    this.showResultTab = false;
    this.saveAsPDFButtonVisibility = false;
    this.clearSearchButtonVisibility = false;
    this.resetResultObjects();
  }

  // Prepare the request object for API call
  public prepareRequestData(): RequestData {
    // Profile object framing
    var profileData = new ProfileData();
    profileData.auditKey = this.emailForm.get('audit').value;
    profileData.referenceNumber = this.emailForm.get('reference').value;
    profileData.email = this.emailForm.get('email').value;

    profileData.searchType = SearchType.Email.toString();
    profileData.searchDate = Shared.getCurrentDate();
    profileData.searchTime = Shared.getCurrentTime();
    profileData.searchUsername = this.loggedInUsername;

    // RequestData object framing
    var requestObj = new RequestData();
    requestObj.profileData = profileData;
    requestObj.accessData = this.accessData;
    return requestObj;
  }

}
