import { Component, Input, OnInit } from '@angular/core';
import { ExperianResultModel } from '../../../../../../models/ExperianResultModel';

@Component({
  selector: 'app-pdf-hutmotor-result-motor',
  templateUrl: './pdf-hutmotor-result-motor.component.html',
  styleUrls: ['./pdf-hutmotor-result-motor.component.css']
})
export class PdfHutmotorResultMotorComponent implements OnInit {

  @Input() public experian: ExperianResultModel = new ExperianResultModel();

  constructor() { }

  ngOnInit(): void {
  }

}
