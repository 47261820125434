<div>
  <div class="card-body">
    <span class="card-title Header-Font-Format"><b>Counter Fraud</b></span>
    <div class="col-md-12" *ngIf="!accessData.CounterFraud"><i class="color-grey float-right">{{NoSearchSelectShort}}</i></div>
    <div class="col-md-12" *ngIf="accessData.CounterFraud && counterFraud.validationMessage"><i class="color-grey float-right">{{OtherFieldRequired}}</i></div>
    <div class="col-md-12" *ngIf="accessData.CounterFraud && counterFraud.noRelevantData"><i class="color-grey float-right">{{NoRelevantDataMessageShort}}</i></div>
    <div class="col-md-12" *ngIf="accessData.CounterFraud && counterFraud.searchFail"><i class="color-grey float-right">{{SearchFail}}</i></div>
    <div class="row" *ngIf="accessData.CounterFraud && !counterFraud.searchFail && !counterFraud.noRelevantData && !counterFraud.validationMessage">
      <rg-gauge-chart class="align-gauge-chart" [canvasWidth]="canvasWidth"
                      [needleValue]="counterFraud.needleValue"
                      [centralLabel]="centralLabel"
                      [options]="options"
                      [name]=""
                      [bottomLabel]="bottomLabel"></rg-gauge-chart>
    </div>
  </div>
</div>
