<table class="table table-striped table-hover">
  <tbody>
    <tr>
      <th>Number Of Exact County Court Judgments (CCJs)</th>
      <td>{{business?.responseData?.creditsafeResult?.report?.negativeInformation?.ccjSummary?.numberOfExact}}</td>
    </tr>
    <tr>
      <th>Number Of Exact Satisfied CCJs</th>
      <td>{{business?.responseData?.creditsafeResult?.report?.negativeInformation?.ccjSummary?.numberOfSatisfied}}</td>
    </tr>
    <tr>
      <th>Number Of Possible CCJs</th>
      <td>{{business?.responseData?.creditsafeResult?.report?.negativeInformation?.ccjSummary?.numberOfPossible}}</td>
    </tr>
    <tr>
      <th>Number Of Writs</th>
      <td>{{business?.responseData?.creditsafeResult?.report?.negativeInformation?.ccjSummary?.numberOfWrits}}</td>
    </tr>
    <tr>
      <th>Value Of Exact CCJs</th>
      <td>{{business?.responseData?.creditsafeResult?.report?.negativeInformation?.ccjSummary?.valueOfExact}}</td>
    </tr>
    <tr>
      <th>CCJ Currency</th>
      <td>{{business?.responseData?.creditsafeResult?.report?.negativeInformation?.ccjSummary?.currency}}</td>
    </tr>
    <tr>
      <th>Value Of Possible CCJs</th>
      <td>{{business?.responseData?.creditsafeResult?.report?.negativeInformation?.ccjSummary?.valueOfPossible}}</td>
    </tr>
    <tr><th></th><td></td></tr>
  </tbody>
</table>

<table>
  <tbody>
    <tr>
      <th>CCJ Exact</th>
    </tr>
    <tr>
      <td>
        <table class="table table-striped table-hover" *ngIf="business?.responseData?.creditsafeResult?.report?.negativeInformation?.countyCourtJudgements?.registered?.exact?.length > 0">
          <thead>
            <tr>
              <th>Date</th>
              <th>Court</th>
              <th>Case Number</th>
              <th>Value</th>
              <th>Currency</th>
              <th>Status</th>
              <th>Date Satisfied</th>
              <th>Defendant</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let ccj of business?.responseData?.creditsafeResult?.report?.negativeInformation?.countyCourtJudgements?.registered?.exact">
              <td>{{ccj?.ccjDate | date:'dd-MMM-yyyy'}}</td>
              <td>{{ccj?.court}}</td>
              <td>{{ccj?.caseNumber}}</td>
              <td>{{ccj?.ccjAmount}}</td>
              <td>{{ccj?.currency}}</td>
              <td>{{ccj?.ccjStatus}}</td>
              <td>{{ccj?.ccjPaidDate | date:'dd-MMM-yyyy'}}</td>
              <td>{{ccj?.incomingRecordDetails}}</td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  </tbody>
</table>

<table class="table table-striped table-hover">
  <tbody>
    <tr>
      <th>Registered Land and Property</th>
      <td>{{business?.responseData?.creditsafeResult?.report?.additionalInformation?.landRegistry?.registeredLandAndProperty}}</td>
    </tr>
    <tr>
      <th>Mortgage Summary</th>
      <td>
        <table class="table table-striped table-hover" *ngIf="business?.responseData?.creditsafeResult?.report?.additionalInformation?.mortgageSummary">
          <tbody>
            <tr>
              <th>Outstanding</th>
              <td>{{business?.responseData?.creditsafeResult?.report?.additionalInformation?.mortgageSummary?.outstanding}}</td>
            </tr>
            <tr>
              <th>Satisfied</th>
              <td>{{business?.responseData?.creditsafeResult?.report?.additionalInformation?.mortgageSummary?.satisfied}}</td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  </tbody>
</table>

<table>
  <tbody>
    <tr>
      <th>Mortage Details</th>
    </tr>
    <tr>
      <td>
        <table class="table table-striped table-hover" *ngIf="business?.responseData?.creditsafeResult?.report?.additionalInformation?.mortgageDetails && business?.responseData?.creditsafeResult?.report?.additionalInformation?.mortgageDetails?.length > 0">
          <thead>
            <tr>
              <th>Mortgage Type</th>
              <th>Date Charge Created</th>
              <th>Date Charge Registered</th>
              <th>Status</th>
              <th>Persons Entitled</th>
              <th>Amount Secured</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let mortgage of business?.responseData?.creditsafeResult?.report?.additionalInformation?.mortgageDetails">
              <td>{{mortgage?.mortgageType}}</td>
              <td>{{mortgage?.dateChargeCreated | date:'dd-MMM-yyyy'}}</td>
              <td>{{mortgage?.dateChargeRegistered | date:'dd-MMM-yyyy'}}</td>
              <td>{{mortgage?.status}}</td>
              <td>{{mortgage?.personsEntitled}}</td>
              <td>{{mortgage?.amountSecured}}</td>
              <td>{{mortgage?.details}}</td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  </tbody>
</table>
