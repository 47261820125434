<div class="app-outer-container page-background">
  <div class="container color-purple">
    <div class="row h-100">
      <div class="col-lg-7 my-auto">
      </div>
      <div class="col-lg-5">
        <!--<div class="register-title color-purple">
          <h1>Welcome To HeadsUp</h1>
          <div class="page-description">
            <p>Our revolutionary new product for insurer SIU’s. With HeadsUp, a researcher can reduce their search time by 80% along with getting more detailed focused results.</p>
          </div>
        </div>-->
        <div class="card card-body card-radius">
          <h3 class="headingalign">Register</h3>
          <form name="form" #f="ngForm" (ngSubmit)="f.form.valid && UserRegister(f)" novalidate>

            <div class="form-group row textboxMarginbottom">
              <div class="col-md-12">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fa fa-user-circle-o" aria-hidden="true"></i></span>
                  </div>
                  <input type="text" class="form-control" name="firstName" placeholder="First Name"
                         [(ngModel)]="register.firstName" #firstName="ngModel"
                         [ngClass]="{ 'is-invalid': f.submitted && firstName.invalid }"
                         (keydown.space)="$event.preventDefault()" autocomplete="off" required />
                  <div *ngIf="f.submitted && firstName.invalid" class="invalid-feedback">
                    <div *ngIf="firstName.errors.required" class="leftpadding">First Name is required</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row textboxMarginbottom">
              <div class="col-md-12">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fa fa-user-circle-o" aria-hidden="true"></i></span>
                  </div>
                  <input type="text" class="form-control" name="lastName" placeholder="Last Name"
                         [(ngModel)]="register.lastName" #lastName="ngModel"
                         [ngClass]="{ 'is-invalid': f.submitted && lastName.invalid }"
                         (keydown.space)="$event.preventDefault()" autocomplete="off" required />
                  <div *ngIf="f.submitted && lastName.invalid" class="invalid-feedback">
                    <div *ngIf="lastName.errors.required" class="leftpadding">Last Name is required</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row textboxMarginbottom">
              <div class="col-md-12">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fa fa-server" aria-hidden="true"></i></span>
                  </div>
                  <input type="text" class="form-control" name="department" placeholder="Department"
                         [(ngModel)]="register.department" #department="ngModel"
                         [ngClass]="{ 'is-invalid': f.submitted && department.invalid }"
                         (keydown.space)="$event.preventDefault()" autocomplete="off" required />
                  <div *ngIf="f.submitted && department.invalid" class="invalid-feedback">
                    <div *ngIf="department.errors.required" class="leftpadding">Department is required</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row textboxMarginbottom">
              <div class="col-md-12">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fa fa-envelope" aria-hidden="true"></i></span>
                  </div>
                  <input type="text" class="form-control" name="email" placeholder="Email" [(ngModel)]="register.email"
                         #email="ngModel" [ngClass]="{ 'is-invalid': f.submitted && email.invalid }" email
                         (keydown.space)="$event.preventDefault()" autocomplete="off" required />
                  <div *ngIf="f.submitted && email.invalid" class="invalid-feedback">
                    <div *ngIf="email.errors.required" class="leftpadding">Email is required</div>
                    <div *ngIf="email.errors.email" class="leftpadding">Email must be a valid email address</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row textboxMarginbottom">
              <div class="col-md-12">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fa fa-key" aria-hidden="true"></i></span>
                  </div>
                  <input type="password" class="form-control" name="password" placeholder="Password"
                         [(ngModel)]="register.password" #password="ngModel"
                         [ngClass]="{ 'is-invalid': f.submitted && password.invalid }" minlength="6"
                         (keydown.space)="$event.preventDefault()" required />
                  <div *ngIf="f.submitted && password.invalid" class="invalid-feedback">
                    <div *ngIf="password.errors.required" class="leftpadding">Password is required</div>
                    <div *ngIf="password.errors.minlength" class="leftpadding">
                      Password must be at least 6 characters
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-12">
                <button [disabled]="loading" class="btn round-button red-grad-button buttonMargin">Register</button>
                <span *ngIf="loading" class="rightalign">
                  <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                </span>
              </div>
              <div class="col-md-12">
                <p class="alert alert-danger" *ngIf="validationerror">
                  <span><small>{{validationerror}}</small></span>
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="pop-up">
  <div class="login-conatiner">
    <app-login></app-login>
  </div>
</div>
