<div class="container">
  <span>HeadsUp Username: {{requestData.profileData.searchUsername ? requestData.profileData.searchUsername : '-'}}</span>
  <br />
  <span>Search Date &amp; Time: {{requestData.profileData.searchDate}} - {{requestData.profileData.searchTime}}</span>
</div>
<div>
  <app-pdf-search-form-business [requestData]="requestData"></app-pdf-search-form-business>
</div>
<br />
<div>
  <div class="container">
    <div class="row mb-3" id="new-dashboard">
      <div class="offset-md-2 col-md-3 card">
        <app-dashboard-one-business [business]="business" [requestData]="requestData"></app-dashboard-one-business>
      </div>
      <div class="col-md-3 card">
        <app-dashboard-two-business [business]="business"
                                    [requestData]="requestData"
                                    [companiesHouse]="companiesHouse"
                                    [prospect]="prospect"></app-dashboard-two-business>
      </div>
      <div class="col-md-3 card">
        <app-dashboard-three-business [emailage]="emailage" [mobile]="mobile"></app-dashboard-three-business>
      </div>
    </div>
  </div>
</div>
<div class="container">

  <!-- Business -->
  <div class="page-break"></div>
  <h3><b><u>Business</u></b></h3>
  <div *ngIf="business?.responseData?.creditsafeResult">
    <h4><b><u>Registration</u></b></h4>
    <app-ltd-registeration-business [business]="business"></app-ltd-registeration-business>

    <div class="page-break"></div>
    <h4><b><u>Officer</u></b></h4>
    <app-pdf-ltd-officer-business [business]="business"></app-pdf-ltd-officer-business>

    <div class="page-break"></div>
    <h4><b><u>Shares</u></b></h4>
    <app-ltd-shares-business [business]="business"></app-ltd-shares-business>

    <div class="page-break"></div>
    <h4><b><u>Accounts</u></b></h4>
    <app-pdf-ltd-accounts-business [business]="business"></app-pdf-ltd-accounts-business>

    <div class="page-break"></div>
    <h4><b><u>Miscellaneous Financials</u></b></h4>
    <app-ltd-miscellaneous-financials-business [business]="business"></app-ltd-miscellaneous-financials-business>

    <div class="page-break"></div>
    <h4><b><u>Company History</u></b></h4>
    <app-ltd-companyhistory-financials-business [business]="business"></app-ltd-companyhistory-financials-business>

    <div class="page-break"></div>
    <h4><b><u>Commentary On Performance</u></b></h4>
    <app-ltd-commentaryperformance-financials-business [business]="business"></app-ltd-commentaryperformance-financials-business>

    <div class="page-break"></div>
    <h4><b><u>Rating History</u></b></h4>
    <app-ltd-ratinghistory-business [business]="business"></app-ltd-ratinghistory-business>

    <div class="page-break"></div>
    <h4><b><u>Credit Limit History</u></b></h4>
    <app-ltd-creditlimit-business [business]="business"></app-ltd-creditlimit-business>
  </div>
  <div *ngIf="business?.responseData?.businessNonRegisteredProfileResult">
    <app-non-ltd-business [business]="business"></app-non-ltd-business>
  </div>

  <!-- Open Source -->
  <div class="page-break"></div>
  <h3><b><u>Open Source</u></b></h3>
  <app-opensource-result-business [opensource]="opensource"></app-opensource-result-business>

  <!-- VAT -->
  <div class="page-break"></div>
  <h3><b><u>VAT</u></b></h3>
  <app-vat-result-business [vatResult]="vatResult"></app-vat-result-business>

  <!-- Email -->
  <div class="page-break"></div>
  <h3><b><u>Email</u></b></h3>
  <app-emailvalidation-result-email [emailValidation]="emailValidation"></app-emailvalidation-result-email>
  <br />
  <app-emailage-result-email [emailage]="emailage"></app-emailage-result-email>

  <!-- Mobile -->
  <div class="page-break"></div>
  <h3><b><u>Mobile</u></b></h3>
  <app-mobile-result-mobile [mobile]="mobile"></app-mobile-result-mobile>

  <!-- Prospect -->
  <div class="page-break"></div>
  <h3><b><u>Prospect</u></b></h3>
  <app-prospect-result-personal [prospect]="prospect"></app-prospect-result-personal>

  <!-- Proprietor -->
  <div class="page-break"></div>
  <h3><b><u>Proprietor</u></b></h3>
  <div *ngIf="(requestData.profileData.fullName && (requestData.profileData.dateOfBirth || requestData.profileData.postalCode)); else proprietorNoResult">
    <div class="row">
      <div class="col-md-4 offset-md-2">
        <app-counterfraud-new-result-personal [counterFraud]="counterFraud" [financial]="address" [mobile]="mobile"></app-counterfraud-new-result-personal>
      </div>
      <div class="col-md-4">
        <app-financial-new-result-personal [financial]="address"></app-financial-new-result-personal>
      </div>
    </div>
    <app-address-result-personal [address]="address"></app-address-result-personal>
  </div>
  <ng-template #proprietorNoResult>
    <div class="col-md-12"><i class="color-grey float-right">{{messagingService.ProprietorDetailsRequired}}</i></div>
  </ng-template>

  <!-- Land Registry -->
   <div class="page-break"></div>
   <h3><b><u>Land Registry</u></b></h3>
   <app-landregistry-result-business [landRegistry]="landRegistry"></app-landregistry-result-business>
</div>
