<div class="loadingImg" *ngIf="motorWeb.isLoading">
  <img src="{{messageService.loadingAnimation}}" />
</div>
<div class="col-md-12" *ngIf="motorWeb.searchFail && !motorWeb.isLoading"><i class="color-grey float-right">{{messageService.SearchFail}}</i></div>
<div class="col-md-12" *ngIf="motorWeb.noRelevantData && !motorWeb.isLoading"><i class="color-grey float-right">{{messageService.NoRelevantDataMessage}}</i></div>

<div *ngIf="!motorWeb.isLoading && !motorWeb.searchFail && !motorWeb.noRelevantData">
  <div class="row">
    <div class="col-md-4">Plate Number</div>
    <div class="col-md-4">{{requestData?.profileData?.vRN | uppercase}}</div>
  </div>
  <div class="row">
    <div class="col-md-4">State</div>
    <div class="col-md-4">{{requestData?.profileData?.state}}</div>
  </div>
  <div class="row">
    <div class="col-md-4">VIN</div>
    <div class="col-md-4">{{motorWeb?.responseData?.vehicle?.vin}}</div>
  </div>
  <div class="row">
    <div class="col-md-4">Year</div>
    <div class="col-md-4">{{motorWeb?.responseData?.vehicle?.year}}</div>
  </div>
  <div class="row">
    <div class="col-md-4">Make</div>
    <div class="col-md-4">{{motorWeb?.responseData?.vehicle?.make}}</div>
  </div>
  <div class="row">
    <div class="col-md-4">Model</div>
    <div class="col-md-4">{{motorWeb?.responseData?.vehicle?.model}}</div>
  </div>
  <div class="row">
    <div class="col-md-4">Model Generation</div>
    <div class="col-md-4">{{motorWeb?.responseData?.vehicle['model-generation']}}</div>
  </div>
  <div class="row">
    <div class="col-md-4">Variant</div>
    <div class="col-md-4">{{motorWeb?.responseData?.vehicle?.variant}}</div>
  </div>
  <div class="row">
    <div class="col-md-4">Vehicle Description</div>
    <div class="col-md-4">{{motorWeb?.responseData?.vehicle['vehicle-description']}}</div>
  </div>
  <div class="row">
    <div class="col-md-4">Body Style</div>
    <div class="col-md-4">{{motorWeb?.responseData?.vehicle['body-style']}}</div>
  </div>
  <div class="row">
    <div class="col-md-4">Colour</div>
    <div class="col-md-4">{{motorWeb?.responseData?.vehicle?.colour?.primary}}</div>
  </div>
  <div class="row">
    <div class="col-md-4">Engine Capacity</div>
    <div class="col-md-4">{{motorWeb?.responseData?.vehicle['engine-capacity']}}</div>
  </div>
  <div class="row">
    <div class="col-md-4">Fuel Type</div>
    <div class="col-md-4">{{motorWeb?.responseData?.vehicle['fuel-type']}}</div>
  </div>
  <div class="row">
    <div class="col-md-4">Tranmission</div>
    <div class="col-md-4">{{motorWeb?.responseData?.vehicle?.transmission}}</div>
  </div>
  <div class="row">
    <div class="col-md-4">Drive Type</div>
    <div class="col-md-4">{{motorWeb?.responseData?.vehicle['drive-type']}}</div>
  </div>

</div>
