
<table class="table table-striped table-hover">
  <thead>
    <tr>
      <th>Date</th>
      <th>Rating Description</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let commentary of business?.responseData?.creditsafeResult?.report?.additionalInformation?.ratingHistory">
      <td>{{commentary?.date | date:'dd-MM-yyyy'}}</td>
      <td>{{commentary?.ratingDescription}}</td>
    </tr>
  </tbody>
</table>
