import { Component, Input, OnInit } from '@angular/core';
import { BusinessResultModel } from '../../../../models/BusinessResultModel';

@Component({
  selector: 'app-ltd-ratinghistory-business',
  templateUrl: './ltd-ratinghistory-business.component.html',
  styleUrls: ['./ltd-ratinghistory-business.component.css']
})
export class LtdRatinghistoryBusinessComponent implements OnInit {

  @Input() public business: BusinessResultModel = new BusinessResultModel();

  constructor() { }

  ngOnInit(): void {
  }

}
