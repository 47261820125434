export class AddressResultModel {
  public isLoading: boolean;
  public noSearchSelect: boolean;
  public responseData: any;
  public validation: boolean;
  public searchFail: boolean;
}

export class T2AResultModel {
  public isLoading: boolean;
  public noSearchSelect: boolean;
  public responseData: any;
  public validation: boolean;
  public searchFail: boolean;
}
