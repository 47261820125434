<div>
    <div class="col-md-12"
        *ngIf="!landRegistry?.isLoading && !landRegistry.validation && !landRegistry.noRelevantData && landRegistry.searchFail">
        <i class="color-grey float-right">{{messageService.SearchFail}}</i>
    </div>
    <div class="col-md-12"
        *ngIf="!landRegistry?.isLoading && !landRegistry.validation && landRegistry.noRelevantData && !landRegistry.searchFail">
        <i class="color-grey float-right">{{messageService.NoRelevantDataMessage}}</i>
    </div>
    <div class="col-md-12"
        *ngIf="!landRegistry?.isLoading && landRegistry.validation && !landRegistry.noRelevantData && !landRegistry.searchFail">
        <i class="color-grey float-right">{{messageService.CompanyNumberRequiredMessage}}</i>
    </div>
</div>
<div>
    <span class="loadingImg" *ngIf="landRegistry?.isLoading">
        <img src="{{messageService.loadingAnimation}}" />
    </span>
</div>

<div
    *ngIf="!landRegistry?.isLoading && landRegistry?.responseData && !landRegistry.validation && !landRegistry.noRelevantData && !landRegistry.searchFail">
    <div class="container">
        <div class="row border-bottom" *ngFor="let land of landRegistry?.responseData">
            <div class="col-md-12">
                <div class="shadow-sm h-100 keep-together my-3">
                    <div class="address-box-design border-bottom p-4 border border-primary shadow">
                        <div>
                            <p class="mb-1">Title Number: {{land.registeredTitle}}</p>
                            <p class="mb-1">Address: {{land.address}}</p>
                            <p class="mb-1">Tenure: {{land.tenure}}</p>
                            <p class="mb-1">Change Date: {{land.changeDate | date:'dd-MM-yyyy'}}</p>
                            <p class="mb-1">Proprietorship: {{land.proprietorship}}</p>
                            <p class="mb-1">Property Type: {{land.propertyType}}</p>
                            <p class="mb-1">New Build: {{land.newlyBuild}}</p>
                            <p class="mb-1">Correspondence Address: {{land.correspondenceAddress}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>