import { Component, Input, OnInit } from '@angular/core';
import { VehicleIdResultModel } from '../../../../../models/VehicleIdResultModel';
import { MessagingService } from '../../../../../../services/messaging.service';

@Component({
  selector: 'app-au-wovr-stolen',
  templateUrl: './au-wovr-stolen.component.html',
  styleUrls: ['./au-wovr-stolen.component.css']
})
export class AuWovrStolenComponent implements OnInit {

  @Input() vehicleId: VehicleIdResultModel = new VehicleIdResultModel();

  constructor(private messageService: MessagingService) { }

  ngOnInit(): void {
  }

}
