import { Component, ElementRef, OnInit } from '@angular/core';
import { SearchService } from '../services/search.service';
import { LandingPageImage } from './model/landingpage-image';
import { AppComponent } from '../../../app.component';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent implements OnInit {

  public landingImage: LandingPageImage = new LandingPageImage();
  public userDetails: any;

  // Init image
  public personalImgSrc: string = this.landingImage.personalImgSrc;
  public hutScreenImgSrc: string = this.landingImage.hutScreenImgSrc;
  public businessImgSrc: string = this.landingImage.businessImgSrc;
  public mobileImgSrc: string = this.landingImage.mobileImgSrc;
  public emailImgSrc: string = this.landingImage.emailImgSrc;
  public motorImgSrc: string = this.landingImage.motorImgSrc;
  public professionalImgSrc: string = this.landingImage.professionalImgSrc;
  public photoAnalysisImgSrc: string = this.landingImage.photoAnalysisImgSrc;
  public documentValidationImgSrc: string = this.landingImage.documentValidationImgSrc;
  public rtwImgSrc: string = this.landingImage.rtwImgSrc;
  public idCheckImgSrc: string = this.landingImage.idCheckImgSrc;

  // Access Screening
  public isPersonalAccess: boolean = false;
  public isHUTScreenAccess: boolean = false;
  public isBusinessAccess: boolean = false;
  public isMobileAccess: boolean = false;
  public isEmailAccess: boolean = false;
  public isMotorAccess: boolean = false;
  public isCheckProfessionalAccess: boolean = false;
  public isPhotoAnalysisAccess: boolean = false;
  public isDocumentValidationAccess: boolean = false;
  public isRTWAccess: boolean = false;
  public isIDCheckAccess: boolean = false;

  constructor(
    private elementRef: ElementRef,
    private searchService: SearchService,
    public appComponent: AppComponent
  ) { }

  ngOnInit(): void {
    this.appComponent.isLoginExpires();
    this.initScreeningAccess();
    this.searchService.getUserDetails().subscribe(data => {
      this.userDetails = data;
    }, error => {
      console.log(error);
    })
  }

  ngAfterViewInit() {
    this.elementRef.nativeElement.ownerDocument.body.style.background = 'url(/assets/Images/new/Background/HUT-elem-bg-1.png)';
  }

  initScreeningAccess() {
    this.isPersonalAccess = false;
    this.isHUTScreenAccess = false;
    this.isBusinessAccess = false;
    this.isMobileAccess = false;
    this.isMotorAccess = false;
    this.isEmailAccess = false;
    this.isPhotoAnalysisAccess = false;
    this.isCheckProfessionalAccess = false;
    this.isDocumentValidationAccess = false;
    this.isRTWAccess = false;
    this.isIDCheckAccess = false;

    this.searchService.AllowedLicence().subscribe(data => {
      let keys = Array.from(data).filter(x => x.licenceType == "SearchAvailability" && x.defaultValue == true).map(x => x.key);
      this.setSearchAvailabilityAccess(keys);
    }, error => {
      console.log(error);
    })
  }

  setSearchAvailabilityAccess(keys: string[]) {
    keys.forEach(x => {
      switch (x) {
        case "PERSONAL":
          this.isPersonalAccess = true;
          break;
        case "HUTSCREEN":
          this.isHUTScreenAccess = true;
          break;
        case "BUSINESS":
          this.isBusinessAccess = true;
          break;
        case "MOBILE":
          this.isMobileAccess = true;
          break;
        case "EMAIL":
          this.isEmailAccess = true;
          break;
        case "MOTOR":
          this.isMotorAccess = true;
          break;
        case "PHOTOANALYSIS":
          this.isPhotoAnalysisAccess = true;
          break;
        case "DOCUMENTVALIDATION":
          this.isDocumentValidationAccess = true;
          break;
        case "RIGHTTOWORK":
          this.isRTWAccess = true;
          break;
        case "IDCHECK":
          this.isIDCheckAccess = true;
          break;
        case "CHECKPROFESSIONAL":
          this.isCheckProfessionalAccess = true;
          break;
      }
    })
  }
}
