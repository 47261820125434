import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessagingService } from '../../../services/messaging.service';
import { SearchService } from '../../../services/search.service';
import { RightToWorkCheckResultModel } from '../../models/EmailResultModel';
import { Item } from '../../models/Items';
import { RTWDepartmentDropdownModel } from '../../models/RTWModel';
import { AccessData, ProfileData, RequestData } from '../../models/SearchRequestModel';
import { SearchType } from '../../models/SearchType';

@Component({
  selector: 'app-rtw',
  templateUrl: './rtw.component.html',
  styleUrls: ['./rtw.component.css', '../shared/shared.searchpage.css']
})
export class RtwComponent implements OnInit {
  public departmentDropdown: any[] = [{ label: 'Select Department', value: 'SelectDepartment', id: 0 }];
  public rtwForm: FormGroup;
  public validation: any = {
    'reference': [Validators.required],
    'name': [Validators.required],
    'email': [Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$')],
    'department': [Validators.min(1)]
  };
  public showResultTab: boolean = false;
  public rightToWorkResultModel: RightToWorkCheckResultModel = new RightToWorkCheckResultModel();

  constructor(private elementRef: ElementRef,
    private formBuilder: FormBuilder,
    private searchService: SearchService,
    private router: Router,
    private messageService: MessagingService
  ) { }

  ngOnInit(): void {
    let isLoggedIn = localStorage.getItem("access_token") ? true : false;
    if (!isLoggedIn)
      this.router.navigate(['/Login']);
    this.searchService.GetAllowedSearchAvailablityItems().subscribe(data => {
      let keys = Array.from(data).map(x => x.key);
      if (!keys.find(x => x == "RIGHTTOWORK")) {
        this.router.navigate(['/v3']);
      }
    }, error => {
      console.log(error);
    })

    this.rtwForm = this.formBuilder.group({
      audit: [""],
      reference: [""],
      name: [""],
      email: [""],
      department: [0]
    });
    this.searchService.GetRTWDepartmentDropdown().subscribe(data => {
      data.forEach(x => {
        this.departmentDropdown.push({ label: x.value, value: x.value, id: x.id })
      })
    }, error => {
      console.log(error);
    })
  }

  ngAfterViewInit() {
    this.elementRef.nativeElement.ownerDocument.body.style.background = 'url(/assets/Images/new/Background/HUT-elem-bg-1.png)';
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundAttachment = 'fixed';
  }

  public rtwSearch(): void {
    this.addValidators();
    this.rtwForm.updateValueAndValidity();

    if (this.rtwForm.valid) {

      Promise.resolve(this.getRandomString(10)).then(
        (data) => {
          //this.resetResultObjects();

          this.showResultTab = true;
          var requestObj: RequestData = this.prepareRequestData();

          // Right To Work
          this.rightToWorkCheck(requestObj);
        }
      );

    }
  }

  // Right To Work check API call
  public rightToWorkCheck(requestObj: RequestData): void {
    this.rightToWorkResultModel.isLoading = false;
    this.rightToWorkResultModel.access = requestObj.accessData;
    this.rightToWorkResultModel.responseData = null;
    this.rightToWorkResultModel.searchFail = false;
    this.rightToWorkResultModel.validation = false;

    if (requestObj.profileData.fullName && requestObj.profileData.email) {
      this.rightToWorkResultModel.isLoading = true;
      this.searchService.RightToWorkSearch(requestObj).subscribe((data) => {
        this.rightToWorkResultModel.isLoading = false;
        this.rightToWorkResultModel.responseData = data;
      }, (error) => {
        console.log(error);
        this.rightToWorkResultModel.isLoading = false;
        this.rightToWorkResultModel.searchFail = true;
      });
    }

  }

  public onEmailChange(): void {
    this.rtwForm.get('email').clearValidators();
    this.rtwForm.get('email').setValidators(this.validation['email']);
    this.rtwForm.get('email').updateValueAndValidity();
  }

  public async getRandomString(length) {
    await this.searchService.GetAuditKey().then(
      data => {
        this.rtwForm.patchValue({
          audit: data
        });
      });
  }

  // Prepare the request object for API call
  public prepareRequestData(): RequestData {
    // Profile object framing
    var profileData = new ProfileData();
    profileData.auditKey = this.rtwForm.get('audit').value;
    profileData.referenceNumber = this.rtwForm.get('reference').value;
    profileData.email = this.rtwForm.get('email').value;
    profileData.fullName = this.rtwForm.get('name').value;
    if (this.rtwForm.get('name').value) {
      let searchtextarray = this.rtwForm.get('name').value.split(" ");
      profileData.firstName = searchtextarray[0];
      profileData.lastName = searchtextarray.length > 1 ? searchtextarray[searchtextarray.length - 1] : searchtextarray[1];
      profileData.midleName = searchtextarray.length > 2 ? searchtextarray[1] : null;
    } else {
      profileData.firstName = null;
      profileData.lastName = null;
      profileData.midleName = null;
    }
    profileData.departmentId = this.rtwForm.get('department').value;

    profileData.searchType = SearchType.RightToWork.toString();

    // RequestData object framing
    var requestObj = new RequestData();
    requestObj.profileData = profileData;
    return requestObj;
  }

  // Add the validations during the 'search' button click
  public addValidators(): void {
    for (const control in this.rtwForm.controls) {
      this.rtwForm.get(control).clearValidators();
      this.rtwForm.get(control).setValidators(this.validation[control]);
      this.rtwForm.get(control).updateValueAndValidity();
    }
  }

  // Clear the validations during the 'clear' button click
  // This'll remove all the error message
  public rtwClearSearch(): void {
    for (const control in this.rtwForm.controls) {
      this.rtwForm.get(control).clearValidators();
      this.rtwForm.get(control).updateValueAndValidity();
    }
    this.rtwForm.patchValue({
      audit: "",
      reference: "",
      name: "",
      email: ""
    });
    this.showResultTab = false;
    //this.resetResultObjects();
  }

}
