export class FinancialResultModel {
  public isLoading: boolean;
  public noSearchSelect: boolean;
  public responseData: any;
  public noRelevantData: boolean;
  public searchFail: boolean;
  public validation: boolean;
  public isEmailProvided: boolean;
  public isMobileNumberProvided: boolean;
}

export class CCJResultModel {
  public isLoading: boolean;
  public noSearchSelect: boolean;
  public searchFail: boolean;
  public validation: boolean;
  public responseData: any;
}

export class InsolvencyResultModel {
  public isLoading: boolean;
  public noSearchSelect: boolean;
  public searchFail: boolean;
  public validation: boolean;
  public responseData: any;
}
