<div id="accordion" class="accordion print-crime-accordion keep-together" *ngIf="!criminality.noSearchSelect">
  <div class="card mb-0">
    <div class="card-header" href="#collapseOne" id="headingOne" aria-expanded="false" (click)="ResultTableDisplay()">
      <span>Hide Status <i class="fa fa-chevron-up" aria-hidden="true"></i></span>
    </div>
    <div id="collapseOne" class="card-body collapse show border-bottom" aria-labelledby="headingOne" data-parent="#accordion">
      <div class="row">
        <div class="col-md-12">
          <div class="table-responsive">
            <table class="table table-bordered table-striped" id="criminalityStatusTable">
              <thead>
                <tr>
                  <th>Category</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let lexicon of criminality.lexiconStatus">
                  <td>{{lexicon.name}}</td>
                  <td>
                    <span>{{lexicon.status}}</span>
                    <span class="loadingImg" *ngIf="lexicon.searchloading">
                      <img src="{{messageService.loadingAnimation}}" />
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="tab-pane fade show active" id="mainSearch" role="tabpanel" aria-labelledby="main-tab">
  <div class="col-md-12" *ngIf="criminality.noSearchSelect"><i class="color-grey float-right">{{messageService.NoSearchSelect}}</i></div>
  <div class="row topmargin" *ngIf="!criminality.noSearchSelect">
    <div class="col-md-12">
      <p class="results" *ngIf="criminality.isResultValues">
        Search returned <span class="color-purple">{{criminality.finalResults.length}}</span> results.
      </p>
    </div>
  </div>
  <br />
  <div class="row" *ngIf="!criminality.noSearchSelect">
    <div class="col-md-12 keep-together" *ngFor="let result of criminality.sortFinalResults; let i=index">
      <div *ngIf="result.link">
        <div class="row">
          <div [ngClass]="{'col-md-10': !devData,'col-md-8':devData}"><h5><a href="{{result.link}}" target="_blank" title="{{result.link}}">{{result.title}}</a></h5></div>
        </div>
        <p [innerHTML]="result.description" class="description"></p>
        <div class="row">
          <div class="col-md-6">
            <p class="source"><small [hidden]="result.source == null">Source: {{result.source}}</small></p>
          </div>
          <div class="col-md-6">
            <p class="pubdate"><small [hidden]="result.pubDate == null">Published Date: {{result.pubDate}}</small></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
