import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessagingService } from '../../../services/messaging.service';
import { SearchService } from '../../../services/search.service';
import { IdCheckResultModel } from '../../models/EmailResultModel';
import { Item } from '../../models/Items';
import { AccessData, ProfileData, RequestData } from '../../models/SearchRequestModel';
import { SearchType } from '../../models/SearchType';

@Component({
  selector: 'app-idcheck',
  templateUrl: './idcheck.component.html',
  styleUrls: ['./idcheck.component.css', '../shared/shared.searchpage.css']
})
export class IdcheckComponent implements OnInit {

  public idCheckForm: FormGroup;
  public validation: any = {
    'reference': [Validators.required],
    'name': [Validators.required],
    'email': [Validators.required, Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$')]
  };
  public showResultTab: boolean = false;

  public idCheckResultModel: IdCheckResultModel = new IdCheckResultModel();

  constructor(private elementRef: ElementRef,
    private formBuilder: FormBuilder,
    private searchService: SearchService,
    private router: Router,
    private messageService: MessagingService
  ) { }

  ngOnInit(): void {
    let isLoggedIn = localStorage.getItem("access_token") ? true : false;
    if (!isLoggedIn)
      this.router.navigate(['/Login']);
    this.searchService.GetAllowedSearchAvailablityItems().subscribe(data => {
      let keys = Array.from(data).map(x => x.key);
      if (!keys.find(x => x == "IDCHECK")) {
        this.router.navigate(['/v3']);
      }
    }, error => {
      console.log(error);
    })

    this.idCheckForm = this.formBuilder.group({
      audit: [""],
      reference: [""],
      name: [""],
      email: [""]
    });
  }

  ngAfterViewInit() {
    this.elementRef.nativeElement.ownerDocument.body.style.background = 'url(/assets/Images/new/Background/HUT-elem-bg-1.png)';
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundAttachment = 'fixed';
  }

  public idCheckSearch(): void {
    this.addValidators();
    this.idCheckForm.updateValueAndValidity();

    if (this.idCheckForm.valid) {

      Promise.resolve(this.getRandomString(10)).then(
        (data) => {
          this.resetResultObjects();

          this.showResultTab = true;
          var requestObj: RequestData = this.prepareRequestData();

          // ID Check
          this.idCheck(requestObj)
        }
      );

    }
  }

  // Id check API call
  public idCheck(requestObj: RequestData): void {
    this.idCheckResultModel.isLoading = false;
    this.idCheckResultModel.access = requestObj.accessData;
    this.idCheckResultModel.responseData = null;
    this.idCheckResultModel.searchFail = false;
    this.idCheckResultModel.validation = false;

    if (requestObj.profileData.fullName && requestObj.profileData.email) {
      this.idCheckResultModel.isLoading = true;
      setTimeout(() => {
        this.searchService.IDCheck(requestObj).subscribe((data) => {
          this.idCheckResultModel.isLoading = false;
          this.idCheckResultModel.responseData = data;
        }, (error) => {
          console.log(error);
          this.idCheckResultModel.isLoading = false;
          this.idCheckResultModel.searchFail = true;
        });
      }, 30000)
    } else {
      this.idCheckResultModel.validation = true;
    }
  }

  public async getRandomString(length) {
    await this.searchService.GetAuditKey().then(
      data => {
        this.idCheckForm.patchValue({
          audit: data
        });
      });
  }

  // Add the validations during the 'search' button click
  public addValidators(): void {
    for (const control in this.idCheckForm.controls) {
      this.idCheckForm.get(control).clearValidators();
      this.idCheckForm.get(control).setValidators(this.validation[control]);
      this.idCheckForm.get(control).updateValueAndValidity();
    }
  }

  // Clear the validations during the 'clear' button click
  // This'll remove all the error message
  public idCheckClearSearch(): void {
    for (const control in this.idCheckForm.controls) {
      this.idCheckForm.get(control).clearValidators();
      this.idCheckForm.get(control).updateValueAndValidity();
    }
    this.idCheckForm.patchValue({
      audit: "",
      reference: "",
      name: "",
      email: ""
    });
    this.idCheckResultModel = null;
    this.showResultTab = false;
    //this.resetResultObjects();
  }

  // Prepare the request object for API call
  public prepareRequestData(): RequestData {
    // Profile object framing
    var profileData = new ProfileData();
    profileData.auditKey = this.idCheckForm.get('audit').value;
    profileData.referenceNumber = this.idCheckForm.get('reference').value;
    profileData.email = this.idCheckForm.get('email').value;
    profileData.fullName = this.idCheckForm.get('name').value;
    if (this.idCheckForm.get('name').value) {
      let searchtextarray = this.idCheckForm.get('name').value.split(" ");
      profileData.firstName = searchtextarray[0];
      profileData.lastName = searchtextarray.length > 1 ? searchtextarray[searchtextarray.length - 1] : searchtextarray[1];
      profileData.midleName = searchtextarray.length > 2 ? searchtextarray[1] : null;
    } else {
      profileData.firstName = null;
      profileData.lastName = null;
      profileData.midleName = null;
    }

    profileData.searchType = SearchType.IdCheck.toString();

    // RequestData object framing
    var requestObj = new RequestData();
    requestObj.profileData = profileData;
    return requestObj;
  }

  // Reset the result objects
  public resetResultObjects() {
    this.idCheckResultModel = new IdCheckResultModel();
  }

  public onEmailChange(): void {
    this.idCheckForm.get('email').clearValidators();
    this.idCheckForm.get('email').setValidators(this.validation['email']);
    this.idCheckForm.get('email').updateValueAndValidity();
  }

}
