<div class="col-md-12" *ngIf="!landRegistry.isLoading && !landRegistry.validation && !landRegistry.noSearchSelect && (landRegistry.searchFail || landRegistry?.responseData?.completeResponse?.Results?.Summary?.ResultText == 'FAIL')"><i class="color-grey float-right">{{messageService.SearchFail}}</i></div>
<div class="col-md-12" *ngIf="!landRegistry.isLoading && !landRegistry.searchFail && !landRegistry.validation && !landRegistry.noSearchSelect && (landRegistry?.responseData?.prsDetails == null || landRegistry?.responseData?.prsDetails.length == 0)"><i class="color-grey float-right">{{messageService.NoLandRegistryMatch}}</i></div>
<div class="col-md-12" *ngIf="!landRegistry.isLoading && !landRegistry.searchFail && landRegistry.validation && !landRegistry.noSearchSelect"><i class="color-grey float-right">{{messageService.PostcodeAndAddressLine1Required}}</i></div>
<div class="col-md-12" *ngIf="!landRegistry.isLoading && !landRegistry.searchFail && !landRegistry.validation && landRegistry.noSearchSelect"><i class="color-grey float-right">{{messageService.NoSearchSelect}}</i></div>
<div>
  <span class="loadingImg" *ngIf="landRegistry.isLoading">
    <img src="{{messageService.loadingAnimation}}" />
  </span>
</div>
<div *ngIf="landRegistry?.responseData && !landRegistry.isLoading && !(landRegistry?.responseData?.prsDetails == null || !landRegistry?.responseData?.prsDetails.matchResult || landRegistry?.responseData?.prsDetails?.matches?.length == 0)">
  <div class="row">
    <div class="col-md-6 ml-4">
      <p *ngIf="landRegistry?.responseData?.prsDetails.matchResult">Match Result: {{landRegistry?.responseData?.prsDetails.matchResult}}</p>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 border-bottom" *ngFor="let prs of landRegistry?.responseData?.prsDetails?.matches">
      <div class="shadow-sm bg-white p-4 h-100 keep-together">
        <div class="tab-content" id="myTabContent">
          <div class="" id="prsdetail">
            <div class="bg-white card addresses-item mb-4 border border-primary shadow">
              <div class="gold-members pt-2 pl-2 pr-2">
                <div class="media">
                  <div class="media-body">
                    <div class="border-bottom p-4">
                      <div>
                        <p *ngIf="prs?.titleNumber" class="mb-1">Title Number : {{prs?.titleNumber}}</p>
                        <p *ngIf="prs?.tenure" class="mb-1">Tenure : {{prs?.tenure}}</p>
                        <p *ngIf="prs?.ownershipType" class="mb-1">Ownership Type : {{prs?.ownershipType}}</p>
                        <div *ngIf="prs?.property">
                          <div *ngFor="let property of prs?.property">
                            <p *ngIf="property?.type" class="mb-1">Property Type : {{property?.type}}</p>
                            <p *ngIf="property?.date" class="mb-1">Date of Sale : {{convertDateString(property?.date) | date: 'dd-MM-yyyy'}}</p>
                            <p *ngIf="property?.price" class="mb-1">Price (&pound;) : {{property?.price}}</p>
                          </div>
                        </div>
                        <div *ngIf="prs?.propertyAddress && (prs?.propertyAddress?.buildingNumber ||
                             prs?.propertyAddress?.buildingName || prs?.propertyAddress?.subBuildingName ||
                             prs?.propertyAddress?.streetName || prs?.propertyAddress?.cityName ||
                             prs?.propertyAddress?.postcode)">
                          <h5 class="mt-2">Address</h5>
                          <p *ngIf="prs?.propertyAddress?.buildingNumber" class="mb-1">Building Number : {{prs?.propertyAddress?.buildingNumber}}</p>
                          <p *ngIf="prs?.propertyAddress?.buildingName" class="mb-1">Building Name : {{prs?.propertyAddress?.buildingName}}</p>
                          <p *ngIf="prs?.propertyAddress?.subBuildingName" class="mb-1">Sub Building Name : {{prs?.propertyAddress?.subBuildingName}}</p>
                          <p *ngIf="prs?.propertyAddress?.streetName" class="mb-1">Street Name : {{prs?.propertyAddress?.streetName}}</p>
                          <p *ngIf="prs?.propertyAddress?.cityName" class="mb-1">City Name : {{prs?.propertyAddress?.cityName}}</p>
                          <p *ngIf="prs?.propertyAddress?.postcode" class="mb-1">Postcode : {{prs?.propertyAddress?.postcode}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
