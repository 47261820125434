<br />
<div class="app-outer-container form-top-margin" (scroll)="scrollTrigger($event)">
  <div class="card container">
    <div class="pt-4 card-title">
      <div class="row">
        <h2 class="float-left color-white pl-4">Screen</h2>
      </div>
      <div class="row">
        <!--<div class="col-md-8">-->
        <div class="col-md-8 custom-file pt-4">
          <div class="col-md-9 float-left browse-File select-file">
            <input type="file" class="custom-file-input" id="customFile" (change)="onFileSelected($event)" ngModel #file>
            <label class="custom-file-label" for="customFile">{{ filename }}</label>
          </div>
          <div class="col-md-2 float-left">
            <button class="btn btn-primary" (click)="uploadListener()">
              Upload
            </button>
          </div>
          <div class="col-md-12 template">
            <span class="float-left"> Please upload your data file to Screen based on this <br/><a href="{{commercialTemplateURL}}">commercial template</a>, <a href="{{personalTemplateURL}}">personal template</a> or <a href="{{mobileTemplateURL}}">mobile template.</a></span>
          </div>
        </div>
      </div>
      <div class="row pt-4 message">
        <div class="col-md-12 pt-4" *ngIf="validationerror || successMessage">
          <!--<p class="redColor" *ngIf="validationerror" style="width:40%;margin-top:20px;margin-left:30%">-->
            <span class="redColor validation-message" *ngIf="validationerror"><small>{{validationerror}} <br /></small></span>
          <!--</p>
          <p class="color-green" *ngIf="successMessage" style="width:40%;margin-top:20px;margin-left:30%">-->
            <span class="color-green validation-message" *ngIf="successMessage"><small>{{successMessage}} <br /></small></span>
          <!--</p>-->
        </div>
      </div>
      <div class="card-body pt-4">
        <div class="d-flex justify-content-end">
          <span class="refresh-icon" (click)="refreshResult()"><i class="fa fa-refresh" aria-hidden="true"></i></span>
        </div>
        <table class="table table-striped stripped">
          <thead>
            <tr>
              <th scope="col">Filename</th>
              <th scope="col">Status</th>
              <th scope="col">Uploaded by</th>
              <th scope="col">Uploaded On</th>
              <th scope="col">Total Records</th>
              <th scope="col">Failed Records</th>
              <th scope="col">Result</th>
            </tr>
          </thead>
          <tbody *ngIf="screenSearchData">
            <tr *ngFor="let element of screenSearchData">

              <td> {{ element.filename | filename }}</td>
              <td> {{ element.jobStatus }}</td>
              <td> {{element.submitedUserName}}</td>
              <td> {{ element.createdDate | date : 'dd-MM-yyy hh:mm a ' }}</td>
              <td> {{ element.recordCount }}</td>
              <td> {{ element.failedCount?element.failedCount:0 }}</td>
              <td>
                <div>
                  <a href="{{element.blobstorageURL}}" target="_blank">
                    <i *ngIf="element.jobStatus=='Completed'" class="fa fa-arrow-down" aria-hidden="true"></i>
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <!-- <app-toast-message [planExpiry]="planExpiry"
                     [isPlanExpired]="isPlanExpired" *ngIf="isPlanExpired == true"></app-toast-message> -->
  <p-toast position="bottom-right" key="PlanExpiry"></p-toast>
</div>






