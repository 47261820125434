import { Component, Input, OnInit } from '@angular/core';
import { AmlResponseModel } from '../../models/AmlResponseModel';
import { InputAccessData } from '../../models/InputDataModel';

@Component({
  selector: 'app-aml-check',
  templateUrl: './aml-check.component.html',
  styleUrls: ['./aml-check.component.css']
})
export class AmlCheckComponent implements OnInit {

  @Input() public amlResultData: AmlResponseModel = new AmlResponseModel();
  @Input() public accessData: InputAccessData = new InputAccessData();

  public NoRelevantDataMessage = "No relevant data found.";
  public NoSearchSelect = "Search option not selected.";
  public SearchFail = "Search failed.";

  constructor() { }

  ngOnInit(): void {
  }

  public isValidAMLAddress(obj: any[]) {
    let addresses = obj.filter(address => {
      if ((address.address1 != null && address.address1 != '') || (address.address2 != null && address.address2 != '') || (address.address3 != null && address.address3 != '') || (address.postcode != null && address.postcode != '') || (address.city != null && address.city != '') || (address.country != null && address.country != '')) {
        return address;
      }
    });
    if (addresses.length > 0)
      return true;
    else
      return false;
  }

}
