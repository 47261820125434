<div class="loadingImg" *ngIf="vehicleId.isLoading">
  <img src="{{messageService.loadingAnimation}}" />
</div>
<div class="col-md-12" *ngIf="vehicleId.searchFail && !vehicleId.isLoading"><i class="color-grey float-right">{{messageService.SearchFail}}</i></div>
<div class="col-md-12" *ngIf="vehicleId.noRelevantData && !vehicleId.isLoading"><i class="color-grey float-right">{{messageService.NoRelevantDataMessage}}</i></div>

<div *ngIf="!vehicleId.isLoading && !vehicleId.searchFail && !vehicleId.noRelevantData">
  <h4>WOVR</h4>
  <div class="pb-4" *ngIf="!vehicleId?.responseData?.vehicleList[0]?.writtenOffInfoModule?.incidentList || vehicleId?.responseData?.vehicleList[0]?.writtenOffInfoModule?.incidentList?.length == 0">
    <i class="color-grey float-right">{{messageService.NoRelevantDataMessage}}</i>
  </div>
  <div *ngIf="vehicleId?.responseData?.vehicleList[0]?.writtenOffInfoModule?.incidentList?.length > 0">
    <div class="row">
      <div class="col-md-4">Written Off Incident Code</div>
      <div class="col-md-4">{{vehicleId?.responseData?.vehicleList[0]?.writtenOffInfoModule?.incidentList[0]?.code}}</div>
    </div>
    <div class="row">
      <div class="col-md-4">Written Off Damage Code</div>
      <div class="col-md-4">{{vehicleId?.responseData?.vehicleList[0]?.writtenOffInfoModule?.incidentList[0]?.damageCodes}}</div>
    </div>
    <div class="row">
      <div class="col-md-4">Written Off Jurisdiction</div>
      <div class="col-md-4">{{vehicleId?.responseData?.vehicleList[0]?.writtenOffInfoModule?.incidentList[0]?.jurisdiction}}</div>
    </div>
    <div class="row">
      <div class="col-md-4">Written Off Recorded Date</div>
      <div class="col-md-4">{{vehicleId?.responseData?.vehicleList[0]?.writtenOffInfoModule?.incidentList[0]?.recordedDate}}</div>
    </div>
    <div class="row">
      <div class="col-md-4">Written Off Type Code</div>
      <div class="col-md-4">{{vehicleId?.responseData?.vehicleList[0]?.writtenOffInfoModule?.incidentList[0]?.typeCode}}</div>
    </div>
  </div>
  
  <h4>Stolen</h4>
  <div class="pb-4" *ngIf="!vehicleId?.responseData?.vehicleList[0]?.stolenInfoModule?.stolenIncidentList || vehicleId?.responseData?.vehicleList[0]?.stolenInfoModule?.stolenIncidentList?.length == 0">
    <i class="color-grey float-right">{{messageService.NoRelevantDataMessage}}</i>
  </div>
  <div *ngIf="vehicleId?.responseData?.vehicleList[0]?.stolenInfoModule?.stolenIncidentList?.length > 0">
    <div class="row">
      <div class="col-md-4">Stolen Incident Type</div>
      <div class="col-md-4">{{vehicleId?.responseData?.vehicleList[0]?.stolenInfoModule?.stolenIncidentList[0]?.incidentType}}</div>
    </div>
    <div class="row">
      <div class="col-md-4">Stolen Incident Jurisdiction</div>
      <div class="col-md-4">{{vehicleId?.responseData?.vehicleList[0]?.stolenInfoModule?.stolenIncidentList[0]?.jurisdiction}}</div>
    </div>
    <div class="row">
      <div class="col-md-4">Stolen Incident Reported Date</div>
      <div class="col-md-4">{{vehicleId?.responseData?.vehicleList[0]?.stolenInfoModule?.stolenIncidentList[0]?.reportedDate}}</div>
    </div>
    <div class="row">
      <div class="col-md-4">Stolen Incident List</div>
      <div class="col-md-4">{{vehicleId?.responseData?.vehicleList[0]?.stolenInfoModule?.stolenIncidentList[0]?.summary}}</div>
    </div>
  </div>
</div>
