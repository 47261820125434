import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProspectResultModel } from '../../../../../../search-pages/models/CounterFraudResultModel';
import { MessagingService } from '../../../../../../services/messaging.service';

@Component({
  selector: 'app-prospect-result-personal',
  templateUrl: './prospect-result.component.html',
  styleUrls: ['./prospect-result.component.css', '../../../../shared/shared.searchpage.css']
})
export class ProspectResultPersonalComponent implements OnInit {

  @Input() prospect: ProspectResultModel = new ProspectResultModel();
  @Output() prospectProfileRequestEventEmitter: EventEmitter<string> = new EventEmitter<string>();

  constructor(private messageService: MessagingService) { }

  ngOnInit(): void {
  }

  public RequestProspectProfileData() {
    this.prospectProfileRequestEventEmitter.emit("");
  }

}
