import { Component, Input, OnInit } from '@angular/core';
import { MessagingService } from '../../../../../../services/messaging.service';
import { MotHistoryResultModel } from '../../../../../models/DVLAResultModel';

@Component({
  selector: 'app-mothistory-result-motor',
  templateUrl: './mothistory-result-motor.component.html',
  styleUrls: ['./mothistory-result-motor.component.css', '../../../../shared/shared.searchpage.css']
})
export class MothistoryResultMotorComponent implements OnInit {

  @Input() motHistory: MotHistoryResultModel = new MotHistoryResultModel();

  constructor(public messageService: MessagingService) { }

  ngOnInit(): void {
  }

}
