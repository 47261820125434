<div class="card-body">
  <span class="card-title Header-Font-Format"><b>ID Check</b></span>
  <div *ngIf="!idCheck.isLoading">
    <div class="col-md-12" *ngIf="!idCheck?.access?.isIDCheckAccess"><i class="color-grey float-right">{{messageService.NoSearchSelectShort}}</i></div>
    <div class="col-md-12" *ngIf="idCheck?.access?.isIDCheckAccess && idCheck.validation"><i class="color-grey float-right">{{messageService.OtherFieldRequired}}</i></div>
    <div class="col-md-12" *ngIf="idCheck?.access?.isIDCheckAccess && idCheck.searchFail"><i class="color-grey float-right">{{messageService.SearchFail}}</i></div>
  </div>
  <span class="loadingImg" *ngIf="idCheck.isLoading && idCheck?.access?.idCheck">
    <img src="{{messageService.loadingAnimation}}" />
  </span>
  <div *ngIf="!idCheck.isLoading && !idCheck.searchFail && idCheck.responseData">
    <p *ngIf="idCheck.responseData?.data"><i class="fa fa-check-circle color-green"></i> Checked</p>
    <p *ngIf="!idCheck.responseData?.data"><i class="fa fa-ban color-red"></i> Not Checked</p>
  </div>
</div>
