<div>
  <div class="card-body">
    <span class="card-title Header-Font-Format"><b>Mortality</b></span>
    <div class="row" *ngIf="accessData.Financial">
      <span *ngIf="financial?.responseData?.data?.MortalityFlag != null">Status: <span [ngClass]="financial?.responseData?.data?.MortalityFlag ? 'status-Red':'status-Green'"> {{financial?.responseData?.data?.MortalityFlag ?'Red':'Green'}}</span></span>
    </div>
    <div class="col-md-12" *ngIf="accessData.Financial && !financial.searchFail && (financial.noRelevantData || financial?.responseData?.data?.MortalityFlag == null) && !financial.validationMessage"><i class="color-grey float-right">{{NoRelevantDataMessageShort}}</i></div>
    <div class="col-md-12" *ngIf="!accessData.Financial && !financial.validationMessage"><i class="color-grey float-right">{{NoSearchSelectShort}}</i></div>
    <div class="col-md-12" *ngIf="accessData.Financial && financial.searchFail && !financial.validationMessage"><i class="color-grey float-right">{{SearchFail}}</i></div>
    <div class="col-md-12" *ngIf="financial.validationMessage"><i class="color-grey float-right">{{postCodeRequiredMessage}}</i></div>
  </div>
  <div class="card-body">
    <span class="card-title Header-Font-Format"><b>CCJ</b></span>
    <div class="row" *ngIf="accessData.Financial">
      <span *ngIf="financial?.responseData?.data?.CcjFlag != null">Status: <span [ngClass]="financial?.responseData?.data?.CcjFlag ? 'status-Red':'status-Green'"> {{financial?.responseData?.data?.CcjFlag ?'Red':'Green'}}</span></span>
    </div>
    <div class="col-md-12" *ngIf="accessData.Financial && !financial.searchFail && (financial.noRelevantData || financial?.responseData?.data?.CcjFlag == null) && !financial.validationMessage"><i class="color-grey float-right">{{NoRelevantDataMessageShort}}</i></div>
    <div class="col-md-12" *ngIf="!accessData.Financial && !financial.validationMessage"><i class="color-grey float-right">{{NoSearchSelectShort}}</i></div>
    <div class="col-md-12" *ngIf="accessData.Financial && financial.searchFail && !financial.validationMessage"><i class="color-grey float-right">{{SearchFail}}</i></div>
    <div class="col-md-12" *ngIf="financial.validationMessage"><i class="color-grey float-right">{{postCodeRequiredMessage}}</i></div>
  </div>
  <div class="card-body">
    <span class="card-title Header-Font-Format"><b>Insolvency</b></span>
    <div class="row" *ngIf="accessData.Financial">
      <span *ngIf="financial?.responseData?.data?.InsolvencyFlag != null">Status: <span [ngClass]="financial?.responseData?.data?.InsolvencyFlag ? 'status-Red':'status-Green'"> {{financial?.responseData?.data?.InsolvencyFlag ?'Red':'Green'}}</span></span>
    </div>
    <div class="col-md-12" *ngIf="accessData.Financial && !financial.searchFail && (financial.noRelevantData || financial?.responseData?.data?.InsolvencyFlag == null) && !financial.validationMessage"><i class="color-grey float-right">{{NoRelevantDataMessageShort}}</i></div>
    <div class="col-md-12" *ngIf="!accessData.Financial && !financial.validationMessage"><i class="color-grey float-right">{{NoSearchSelectShort}}</i></div>
    <div class="col-md-12" *ngIf="accessData.Financial && financial.searchFail && !financial.validationMessage"><i class="color-grey float-right">{{SearchFail}}</i></div>
    <div class="col-md-12" *ngIf="financial.validationMessage"><i class="color-grey float-right">{{postCodeRequiredMessage}}</i></div>
  </div>
</div>
