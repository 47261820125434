import { Component, Input, OnInit } from '@angular/core';
import { MessagingService } from '../../../../../../services/messaging.service';
import { AVAResultModel } from '../../../../../models/AVAResultModel';

@Component({
  selector: 'app-ava-result-motor',
  templateUrl: './ava-result-motor.component.html',
  styleUrls: ['./ava-result-motor.component.css', '../../../../shared/shared.searchpage.css']
})
export class AvaResultMotorComponent implements OnInit {

  @Input() ava: AVAResultModel = new AVAResultModel();

  constructor(private messageService: MessagingService) { }

  ngOnInit(): void {
  }

}
