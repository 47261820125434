<div>
  <ul style="display:grid;">
    <li class="Header-Font-Format pt-4"><h2>We Tell You</h2></li>

    <li class="result-animation" [ngClass]="inputData.searchName ? 'result-get-list-font-design': 'checkmark-default-design'">
      <label class="switch">
        <input type="checkbox" name="isHUTDatabaseAccess" [(ngModel)]="accessData.HUTDatabase">
        <span class="slider round"></span>
      </label>
      <img src="assets/Images/icons/HUTDatabase.svg" class="icon-size" />
      <span>HUT Database</span>
    </li>
    <li class="result-animation" [ngClass]="inputData.phoneNumber ? 'result-get-list-font-design': 'checkmark-default-design'">
      <label class="switch">
        <input type="checkbox" name="isMobileAccess" [(ngModel)]="accessData.Mobile">
        <span class="slider round"></span>
      </label>
      <img src="assets/Images/icons/Mobile.svg" class="icon-size" />
      <span>Mobile</span>
    </li>
    <li class="result-animation" [ngClass]="inputData.email ? 'result-get-list-font-design': 'checkmark-default-design'">
      <label class="switch">
        <input type="checkbox" name="isEmailAccess" [(ngModel)]="accessData.Email">
        <span class="slider round"></span>
      </label>
      <img src="assets/Images/icons/Email.svg" class="icon-size" />
      <span>Email</span>
    </li>
    <li class="result-animation" [ngClass]="inputData.searchName && (inputData.postCode || inputData.phoneNumber) ? 'result-get-list-font-design': 'checkmark-default-design'">
      <label class="switch">
        <input type="checkbox" name="isIdentityAccess" [(ngModel)]="accessData.Identity">
        <span class="slider round"></span>
      </label>
      <img src="assets/Images/icons/Identity.svg" class="icon-size" />
      <span>Identity</span>
    </li>
    <li class="result-animation" [ngClass]="inputData.searchName && inputData.postCode  ? 'result-get-list-font-design': 'checkmark-default-design'">
      <label class="switch">
        <input type="checkbox" name="isFinancialAccess" [(ngModel)]="accessData.Financial">
        <span class="slider round"></span>
      </label>
      <img src="assets/Images/icons/Financial.svg" class="icon-size" />
      <span>Financial</span>
    </li>
    <li class="result-animation" [ngClass]="inputData.postCode && inputData.searchName ? 'result-get-list-font-design': 'checkmark-default-design'">
      <label class="switch">
        <input type="checkbox" name="isAddressAccess" [(ngModel)]="accessData.Address">
        <span class="slider round"></span>
      </label>
      <img src="assets/Images/icons/Address.svg" class="icon-size" />
      <span>Address</span>
      <div id="addressCanvas"></div>
    </li>
    <li class="result-animation" [ngClass]="inputData.searchName ? 'result-get-list-font-design': 'checkmark-default-design'">
      <label class="switch">
        <input type="checkbox" name="isCriminalityAccess" [(ngModel)]="accessData.Criminality">
        <span class="slider round"></span>
      </label>
      <img src="assets/Images/icons/Criminality.svg" class="icon-size" />
      <span>Criminality</span>
    </li>
    <li class="result-animation" [ngClass]="inputData.searchName ? 'result-get-list-font-design': 'checkmark-default-design'">
      <label class="switch">
        <input type="checkbox" name="isSocialMediaAccess" [(ngModel)]="accessData.SocialMedia">
        <span class="slider round"></span>
      </label>
      <img src="assets/Images/icons/Social.svg" class="icon-size" />
      <span>Social Media</span>
    </li>
    <li class="result-animation" [ngClass]="inputData.searchName && (inputData.postCode || inputData.dateOfBirth || inputData.email || inputData.phoneNumber || inputData.vrn || inputData.companyName) ? 'result-get-list-font-design': 'checkmark-default-design'">
      <label class="switch">
        <input type="checkbox" name="isCounterFraudAccess" [(ngModel)]="accessData.CounterFraud">
        <span class="slider round"></span>
      </label>
      <img src="assets/Images/icons/Fraud.svg" class="icon-size" />
      <span>Counter Fraud</span>
    </li>
    <li class="result-animation" [ngClass]="inputData.searchName ? 'result-get-list-font-design': 'checkmark-default-design'">
      <label class="switch">
        <input type="checkbox" name="isSportsAccess" [(ngModel)]="accessData.Sports">
        <span class="slider round"></span>
      </label>
      <img src="assets/Images/icons/Sports.svg" class="icon-size" />
      <span>Sports</span>
    </li>
    <li class="result-animation" [ngClass]="inputData.phoneNumber ? 'result-get-list-font-design': 'checkmark-default-design'">
      <label class="switch">
        <input type="checkbox" name="isAdvertsAccess" [(ngModel)]="accessData.Advert">
        <span class="slider round"></span>
      </label>
      <img src="assets/Images/icons/Adverts.svg" class="icon-size" />
      <span>Adverts</span>
    </li>
    <li class="result-animation" [ngClass]="inputData.searchName ? 'result-get-list-font-design': 'checkmark-default-design'">
      <label class="switch">
        <input type="checkbox" name="isCompaniesHouseAccess" [(ngModel)]="accessData.CompaniesHouse">
        <span class="slider round"></span>
      </label>
      <img src="assets/Images/icons/CompaniesHouse.svg" class="icon-size" />
      <span>Companies House</span>
    </li>
    <li class="result-animation" [ngClass]="inputData.vrn ? 'result-get-list-font-design': 'checkmark-default-design'">
      <label class="switch">
        <input type="checkbox" name="isAVAAccess" [(ngModel)]="accessData.AVA">
        <span class="slider round"></span>
      </label>
      <img src="assets/Images/icons/AVA.svg" class="icon-size" />
      <span>AVA</span>
    </li>
    <li class="result-animation" [ngClass]="inputData.searchName ? 'result-get-list-font-design': 'checkmark-default-design'">
      <label class="switch">
        <input type="checkbox" name="isAMLAccess" [(ngModel)]="accessData.AML">
        <span class="slider round"></span>
      </label>
      <img src="assets/Images/icons/Financial.svg" class="icon-size" />
      <span>AML</span>
    </li>
    <li class="result-animation" [ngClass]="inputData.searchName && (inputData.dateOfBirth || (inputData.postCode && inputData.addressLine1)) ? 'result-get-list-font-design': 'checkmark-default-design'">
      <label class="switch">
        <input type="checkbox" name="isCCJDetailAccess" [(ngModel)]="accessData.CCJDetails">
        <span class="slider round"></span>
      </label>
      <img src="assets/Images/icons/Financial.svg" class="icon-size" />
      <span>CCJ Detail</span>
    </li>
    <li class="result-animation" [ngClass]="inputData.searchName && inputData.dateOfBirth ? 'result-get-list-font-design': 'checkmark-default-design'">
      <label class="switch">
        <input type="checkbox" name="isInsolvencyDetailAccess" [(ngModel)]="accessData.InsolvencyDetails">
        <span class="slider round"></span>
      </label>
      <img src="assets/Images/icons/Financial.svg" class="icon-size" />
      <span>Insolvency Detail</span>
    </li>
  </ul>
</div>
