import { Component, Input, OnInit } from '@angular/core';
import { EmailageCheckResultModel, EmailCheckResultModel } from '../../../../../../search-pages/models/EmailResultModel';
import { MessagingService } from '../../../../../../services/messaging.service';

@Component({
  selector: 'app-email-result-personal',
  templateUrl: './email-result.component.html',
  styleUrls: ['./email-result.component.css', '../../../../shared/shared.searchpage.css']
})
export class EmailResultPersonalComponent implements OnInit {

  @Input() email: EmailCheckResultModel = new EmailCheckResultModel();
  @Input() emailage: EmailageCheckResultModel = new EmailageCheckResultModel();

  constructor(private messageService: MessagingService) { }

  ngOnInit(): void {
  }

}
