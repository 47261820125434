<div class="col-md-12" *ngIf="!address?.isLoading && address.noSearchSelect"><i class="color-grey float-right">{{messageService.NoSearchSelect}}</i></div>
<div class="col-md-12" *ngIf="!address?.isLoading && !address.noSearchSelect && address.validation"><i class="color-grey float-right">{{messageService.PostcodeOrDOBRequiredMessage}}</i></div>
<div class="col-md-12" *ngIf="!address?.isLoading && !address.noSearchSelect && address.searchFail"><i class="color-grey float-right">{{messageService.SearchFail}}</i></div>
<div class="col-md-12" *ngIf="!address?.isLoading && !address.noSearchSelect && (!address.responseData || address?.responseData?.data?.CompleteResponse?.Response?.People?.length == 0 || address?.responseData?.data?.CompleteResponse?.Response?.length == 0)"><i class="color-grey float-right">{{messageService.NoRelevantDataMessage}}</i></div>
<div>
  <span class="loadingImg" *ngIf="address?.isLoading">
    <img src="{{messageService.loadingAnimation}}" />
  </span>
</div>
<div *ngIf="!address?.isLoading && address?.responseData && address?.responseData?.data?.CompleteResponse?.Response">
  <br />
  <div class="container">
    <div class="row border-bottom" *ngFor="let people of address?.responseData?.data?.CompleteResponse?.Response?.People">
      <div class="col-md-4">
        <div class="shadow-sm bg-white h-100 keep-together">
          <div class="address-box-design border-bottom p-4 border border-primary shadow">
            <div>
              <h5 class="mb-2"><span>{{(people?.Person?.Name?.Prefix +'. '+people?.Person?.Name?.Fullname) | titlecase }}</span></h5>
              <p class="mb-1" *ngIf="people?.OccupancyPeriod">Occupancy Period: {{(people?.OccupancyPeriod?.From ? people?.OccupancyPeriod?.From :'' ) + '-' + (people?.OccupancyPeriod?.To? people?.OccupancyPeriod?.To:'') }}</p>
              <p class="mb-1" *ngIf="people?.ResidencyScore">Residency Score: {{people?.ResidencyScore | titlecase}}</p>
              <p class="mb-1" *ngIf="people?.RecencyDate">Recency Date: {{(people?.RecencyDate?.Day ? people?.RecencyDate?.Day + '-' :'')+(people?.RecencyDate?.Month ? people?.RecencyDate?.Month +'-':'')+ (people?.RecencyDate?.Year? people?.RecencyDate?.Year :'')}}</p>
              <p class="mb-1">DOB: {{people?.Person?.DOB?.Day + '-' + people?.Person?.DOB?.Month + '-' + people?.Person?.DOB?.Year}}</p>
              <p class="mb-1" *ngIf="people?.LandlineNumbers?.Number.length > 0">Landline Numbers:</p>
              <p class="mb-1 pl-4" *ngFor="let lndline of people?.LandlineNumbers?.Number">{{lndline?.Number}}<i [ngClass]="lndline?.Verified >0 ? 'fa fa-check-circle color-green' : 'not-verified'"></i></p>
              <p class="mb-1" *ngIf="people?.MobileNumbers?.Number?.length > 0">Mobile Numbers:</p>
              <p class="mb-1 pl-4" *ngFor="let mobile of people?.MobileNumbers?.Number">{{mobile?.Number}}<i [ngClass]="mobile?.Verified >0 ? 'fa fa-check-circle color-green' : 'not-verified'"></i></p>
              <p class="mb-1" *ngIf="people?.Emails?.length > 0">Emails: </p>
              <p class="mb-1 pl-4" *ngFor="let email of people?.Emails">{{email|lowercase }}</p>
            </div>
            <h5>Address: </h5>
            <div class="pl-4">
              <p *ngIf="people?.Address?.AddressLine1" class="mb-1">{{people?.Address?.AddressLine1 | titlecase}}</p>
              <p *ngIf="people?.Address?.AddressLine2" class="mb-1">{{people?.Address?.AddressLine2 | titlecase}}</p>
              <p *ngIf="people?.Address?.AddressLine3" class="mb-1">{{people?.Address?.AddressLine3 | titlecase}}</p>
              <p *ngIf="people?.Address?.AddressLine4" class="mb-1">{{people?.Address?.AddressLine4 | titlecase}}</p>
              <p *ngIf="people?.Address?.AddressLine5" class="mb-1">{{people?.Address?.AddressLine5 | titlecase}}</p>
              <p *ngIf="people?.Address?.AddressLine6" class="mb-1">{{people?.Address?.AddressLine6 | titlecase}}</p>
              <p *ngIf="people?.Address?.Country" class="mb-1">{{people?.Address?.Country | titlecase}}</p>
              <p *ngIf="people?.Address?.Postcode" class="mb-1">{{people?.Address?.Postcode | uppercase}}</p>
            </div>
            <div>
              <p class="mb-1" *ngIf="getDataSourcesString(people?.DataSources?.Source)">Sources: {{getDataSourcesString(people?.DataSources?.Source)}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="shadow-sm bg-white p-4 h-100">
          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade  active show" id="addresses" role="tabpanel" aria-labelledby="addresses-tab">
              <h4 class="mt-0 mb-4">Additional Addresses</h4>
              <div class="row" *ngIf="people?.AdditionalAddresses?.length;else noAdditionalAdressFound">
                <div class="col-md-6" *ngFor="let additional of people?.AdditionalAddresses">
                  <div class="bg-white card addresses-item mb-4 border border-primary shadow">
                    <div class="gold-members pt-2 pl-2 pr-2">
                      <div>
                        <div>
                          <div class="border-bottom p-4">
                            <div>
                              <h5 class="mb-2"><span>{{(additional?.AdditionalAddress?.Name?.Prefix +'. '+additional?.AdditionalAddress?.Name?.Fullname) | titlecase }}</span></h5>
                              <p class="mb-1" *ngIf="additional?.AdditionalAddress?.OccupancyPeriod">Occupancy Period: {{(additional?.AdditionalAddress?.OccupancyPeriod?.From? additional?.AdditionalAddress?.OccupancyPeriod?.From + '-':'') + (additional?.AdditionalAddress?.OccupancyPeriod?.To ? additional?.AdditionalAddress?.OccupancyPeriod?.To:'') }}</p>
                              <p class="mb-1" *ngIf="additional?.AdditionalAddress?.ResidencyScore">Residency Score: {{additional?.AdditionalAddress?.ResidencyScore | titlecase}}</p>
                              <p class="mb-1" *ngIf="additional?.AdditionalAddress?.RecencyDate">Recency Date: {{(additional?.AdditionalAddress?.RecencyDate?.Day ? additional?.AdditionalAddress?.RecencyDate?.Day+'-':'')+ (additional?.AdditionalAddress?.RecencyDate?.Month ?additional?.AdditionalAddress?.RecencyDate?.Month+'-':'')+(additional?.AdditionalAddress?.RecencyDate?.Year?additional?.AdditionalAddress?.RecencyDate?.Year:'')}}</p>
                              <!--<p class="mb-1" *ngIf="additional?.AdditionalAddress?.DOB">DOB: {{additional?.AdditionalAddress?.DOB?.Day + '-' + additional?.AdditionalAddress?.DOB?.Month + '-' + additional?.AdditionalAddress?.DOB?.Year}}</p>-->
                              <p class="mb-1" *ngIf="additional?.AdditionalAddress?.LandlineNumbers.Number?.length > 0">Landline Numbers:</p>
                              <p class="mb-1 pl-4" *ngFor="let lndline of additional?.AdditionalAddress?.LandlineNumbers?.Number">{{lndline?.Number}}<i [ngClass]="lndline?.Verified >0 ? 'fa fa-check-circle color-green' : 'not-verified'"></i></p>
                              <p class="mb-1" *ngIf="additional?.AdditionalAddress?.MobileNumbers.Number?.length > 0">Mobile Numbers:</p>
                              <p class="mb-1 pl-4" *ngFor="let mobile of additional?.AdditionalAddress?.MobileNumbers?.Number">{{mobile?.Number}}<i [ngClass]="mobile?.Verified >0 ? 'fa fa-check-circle color-green' : 'not-verified'"></i></p>
                              <p class="mb-1" *ngIf="additional?.AdditionalAddress?.Emails?.length">Emails: </p>
                              <p class="mb-1 pl-4" *ngFor="let email of additional?.AdditionalAddress?.Emails">{{email | lowercase }}</p>
                            </div>
                            <h5>Address: </h5>
                            <div class="pl-4 mb-1">
                              <p *ngIf="additional?.AdditionalAddress?.Address?.AddressLine1" class="mb-1">{{additional?.AdditionalAddress?.Address?.AddressLine1 | titlecase}}</p>
                              <p *ngIf="additional?.AdditionalAddress?.Address?.AddressLine2" class="mb-1">{{additional?.AdditionalAddress?.Address?.AddressLine2 | titlecase}}</p>
                              <p *ngIf="additional?.AdditionalAddress?.Address?.AddressLine3" class="mb-1">{{additional?.AdditionalAddress?.Address?.AddressLine3 | titlecase}}</p>
                              <p *ngIf="additional?.AdditionalAddress?.Address?.AddressLine4" class="mb-1">{{additional?.AdditionalAddress?.Address?.AddressLine4 | titlecase}}</p>
                              <p *ngIf="additional?.AdditionalAddress?.Address?.AddressLine5" class="mb-1">{{additional?.AdditionalAddress?.Address?.AddressLine5 | titlecase}}</p>
                              <p *ngIf="additional?.AdditionalAddress?.Address?.AddressLine6" class="mb-1">{{additional?.AdditionalAddress?.Address?.AddressLine6 | titlecase}}</p>
                              <p *ngIf="additional?.AdditionalAddress?.Address?.Country" class="mb-1">{{additional?.AdditionalAddress?.Address?.Country | titlecase}}</p>
                              <p *ngIf="additional?.AdditionalAddress?.Address?.Postcode" class="mb-1">{{additional?.AdditionalAddress?.Address?.Postcode | uppercase}}</p>
                            </div>
                            <div>
                              <p class="mb-1" *ngIf="getDataSourcesString(additional?.AdditionalAddress?.DataSources?.Source)">Sources: {{getDataSourcesString(additional?.AdditionalAddress?.DataSources?.Source)}}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ng-template #noAdditionalAdressFound>
                <div class="col-md-12"><i class="color-grey">No additional address found.</i></div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
