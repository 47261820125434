import { Component, Input, OnInit } from '@angular/core';
import { CompaniesHouseResultModel } from '../../../../../../search-pages/models/CompaniesHouseResultModel';
import { MessagingService } from '../../../../../../services/messaging.service';

@Component({
  selector: 'app-companies-house-result-personal',
  templateUrl: './companies-house-result.component.html',
  styleUrls: ['./companies-house-result.component.css', '../../../../shared/shared.searchpage.css']
})
export class CompaniesHouseResultPersonalComponent implements OnInit {

  @Input() companiesHouse: CompaniesHouseResultModel = new CompaniesHouseResultModel();

  constructor(private messageService: MessagingService) { }

  ngOnInit(): void {
  }

}
