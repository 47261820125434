import { Component, Input, OnInit } from '@angular/core';
import { AVAResultModel } from '../../../../../../models/AVAResultModel';
import { MessagingService } from '../../../../../../../services/messaging.service';

@Component({
  selector: 'app-pdf-ava-result-motor',
  templateUrl: './pdf-ava-result-motor.component.html',
  styleUrls: ['./pdf-ava-result-motor.component.css', '../../../../../shared/shared.searchpage.css', '../../../result-pages/ava-result-motor/ava-result-motor.component.css']
})
export class PdfAvaResultMotorComponent implements OnInit {

  @Input() public ava: AVAResultModel = new AVAResultModel();
  public messageService: MessagingService = new MessagingService();

  constructor() { }

  ngOnInit(): void {
  }

}
