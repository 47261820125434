<div class="app-outer-container  page-background">
  <div class="container">
    <div class="row">
      <div class="col-md-2 topmargin">
      </div>
      <div class="col-md-8 topmargin">
        <!--<div class="card card-body card-radius text-center" *ngIf="registration">
    <h4>Thank you for registering.</h4>
    <p>We have sent you a verification email. Please click on the link to verify your email address.</p>
  </div>-->
        <!--<div class="card card-body card-radius text-center" *ngIf="confirm">
    <h4>Thank you for verifying your email.</h4>
    <p>Your account is now active. Please Sign In to continue.</p>
  </div>-->

        <div class="card card-body card-radius text-center" *ngIf="linkexpired">
          <h4>Link expired ! Please Contact Us.</h4>
          <!--<p><span (click)="ResendConfirmationLink()" class="confirmlink">Click here</span> to resend confirmation link to activate your mail ID.</p>-->
        </div>

        <div class="card card-body card-radius text-center" *ngIf="forgotPassword">
          <h4>Password reset link sent.</h4>
          <p>Thank you for submitting a request to reset your password. If you have an account with us, you will receive an email shortly with instructions to reset your password.</p>
        </div>

        <div class="card card-body card-radius text-center" *ngIf="adduser">
          <h4>Thank you for verifying your email.</h4>
          <p>Your account is now active. You shall receive the access details shortly to your registered email.</p>
        </div>

        <div class="card card-body card-radius text-center" *ngIf="resetPassword">
          <h4>Password updated Successfully.</h4>
          <p>Please Sign in to continue.</p>
        </div>

        <div class="card card-body card-radius text-center" *ngIf="verificationlinkResent">
          <h4>Email verification link sent.</h4>
          <p>Please click on the link there to verify your email address.</p>
        </div>

        <div class="card card-body card-radius text-center" *ngIf="contactdetails">
          <h4>Thank you for contacting us. </h4>
          <p>We will get back to you soon. </p>
        </div>
        <div class="card card-body card-radius text-center" *ngIf="alreadyConfirmed">
          <h4>Email already confirmed.</h4>
        </div>
        
        <div class="card card-body card-radius text-center" *ngIf="confirmationError">
          <h4>Error: {{status}}</h4>
        </div>
      </div>
    </div>
  </div>
</div>
