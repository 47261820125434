<div class="col-md-12" *ngIf="!aml.isLoading && aml.noSearchSelect"><i class="color-grey float-right">{{messageService.NoSearchSelect}}</i></div>
<div class="col-md-12" *ngIf="!aml.isLoading && !aml.noSearchSelect && (aml.searchFail || aml?.responseData?.data?.completeResponse?.Results?.Summary?.ResultText == 'FAIL')"><i class="color-grey float-right">{{messageService.SearchFail}}</i></div>
<div class="col-md-12" *ngIf="!aml.isLoading && !aml.noSearchSelect && !aml.searchFail && (aml?.responseData?.data?.sanctions == null || aml?.responseData?.data?.sanctions.length == 0)"><i class="color-grey float-right">{{messageService.NoRelevantDataMessage}}</i></div>
<div>
  <span class="loadingImg" *ngIf="aml.isLoading">
    <img src="{{messageService.loadingAnimation}}" />
  </span>
</div>
<div *ngIf="aml?.responseData?.data?.sanctions && !aml.isLoading && !(aml?.responseData?.data?.sanctions == null || aml?.responseData?.data?.sanctions?.length == 0)">
  <div class="row">
    <div class="col-md-6 border-bottom" *ngFor="let sanction of aml?.responseData?.data?.sanctions">
      <div class="shadow-sm bg-white p-4 h-100">
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade active show" id="aml" role="tabpanel" aria-labelledby="aml-tab">
            <div class="bg-white card addresses-item mb-4 border border-primary shadow keep-together">
              <div class="gold-members pt-2 pl-2 pr-2">
                <div class="media">
                  <div class="media-body">
                    <div class="border-bottom p-4">
                      <div>
                        <h5 *ngIf="sanction?.name" class="mb-2">Name : {{sanction?.name }}</h5>
                        <p *ngIf="sanction?.recency" class="mb-1">Recency : {{sanction.recency}}</p>
                        <p *ngIf="sanction?.source" class="mb-1">Source : {{sanction.source}}</p>
                        <p *ngIf="sanction?.type" class="mb-1">Type : {{sanction?.type}}</p>
                        <p *ngIf="sanction?.dob" class="mb-1">DOB : {{sanction?.dob}}</p>
                        <div class="mb-1" *ngIf="(sanction?.addresses && sanction?.addresses?.length > 0 && isValidAMLAddress(sanction?.addresses))">
                          <h6 class="mb-2">Addresses :</h6>
                          <div class="ml-2" *ngFor="let address of sanction?.addresses">
                            <div *ngIf="((address.address1 != null && address.address1 != '') || (address.address2 != null && address.address2 != '') || (address.address3 != null && address.address3 != '') || (address.postcode != null && address.postcode != '') || (address.city != null && address.city != '') || (address.country != null && address.country != ''))">Address:</div>
                            <div *ngIf="(address.address1 != null && address.address1 != '')">Address line 1 : {{address.address1}}</div>
                            <div *ngIf="(address.address2 != null && address.address2 != '')">Address line 2 : {{address.address2}}</div>
                            <div *ngIf="(address.address3 != null && address.address3 != '')">Address line 3 : {{address.address3}}</div>
                            <div *ngIf="(address.postcode != null && address.postcode != '')">Post code : {{address.postcode}}</div>
                            <div *ngIf="(address.city != null && address.city != '')">City : {{address.city}}</div>
                            <div *ngIf="(address.country != null && address.country != '')">Country : {{address.country}}</div>
                          </div>
                        </div>
                        <div *ngIf="sanction?.alias?.length > 0">
                          <h6 class="mb-2">Aliases :</h6>
                          <p class="mb-1 ml-3" *ngFor="let alias of sanction?.alias">{{alias}}</p>
                        </div>
                        <div *ngIf="sanction?.positions?.length > 0">
                          <h6 class="mb-2">Positions :</h6>
                          <p class="mb-1 ml-3" *ngFor="let position of sanction?.positions">{{position}}</p>
                        </div>
                        <div *ngIf="sanction?.exceptions?.length > 0">
                          <h6 class="mb-2">Details :</h6>
                          <p class="mb-1 ml-3" *ngFor="let exception of sanction?.exceptions">{{exception}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
