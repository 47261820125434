import { Component, OnInit } from '@angular/core';
import { TenantRegion } from '../../models/TenantRegionResposne';
import { SearchService } from '../../../services/search.service';

@Component({
  selector: 'app-personal',
  templateUrl: './personal.component.html',
  styleUrls: ['./personal.component.css']
})
export class PersonalComponent implements OnInit {

  public regionAccess: TenantRegion = new TenantRegion();
  public isExitDisabled: boolean = false;
  
  constructor(private searchService: SearchService) { }

  ngOnInit(): void {
    this.getTenantRegion();
  }

  public receiveDisableExit($event: boolean) {
    this.isExitDisabled = $event
  }

  public getTenantRegion() {
    this.searchService.GetTenantRegion().subscribe(data => {
      this.regionAccess = data;
    }, error => {
      console.log(error);
    });
  }
}
