import { Component, Input, OnInit } from '@angular/core';
import { BusinessResultModel } from '../../../../../models/BusinessResultModel';

@Component({
  selector: 'app-pdf-ltd-officer-business',
  templateUrl: './pdf-ltd-officer-business.component.html',
  styleUrls: ['./pdf-ltd-officer-business.component.css', '../../../../shared/shared.searchpage.css']
})
export class PdfLtdOfficerBusinessComponent implements OnInit {

  @Input() public business: BusinessResultModel = new BusinessResultModel();

  constructor() { }

  ngOnInit(): void {
  }

  public currentDirectorExpand() {
    const thisRef = this;
    if (this.business?.responseData?.creditsafeResult?.report?.directors?.currentDirectors &&
      this.business?.responseData?.creditsafeResult?.report?.directors?.currentDirectors?.length > 0) {
      this.business?.responseData?.creditsafeResult?.report?.directors?.currentDirectors.forEach(x => {
        thisRef[x.name] = true;
      });
    }
    return thisRef;
  }

  public previousDirectorExpand() {
    const thisRef = this;
    if (this.business?.responseData?.creditsafeResult?.report?.directors?.previousDirectors &&
      this.business?.responseData?.creditsafeResult?.report?.directors?.previousDirectors?.length > 0) {
      this.business?.responseData?.creditsafeResult?.report?.directors?.previousDirectors.forEach(x => {
        thisRef[x.name] = true;
      });
    }
    return thisRef;
  }

  public activePSCExpand() {
    const thisRef = this;
    if (this.business?.responseData?.creditsafeResult?.report?.additionalInformation?.personsWithSignificantControl?.activePSC &&
      this.business?.responseData?.creditsafeResult?.report?.additionalInformation?.personsWithSignificantControl?.activePSC?.length > 0) {
      this.business?.responseData?.creditsafeResult?.report?.additionalInformation?.personsWithSignificantControl?.activePSC.forEach(x => {
        thisRef[x.name] = true;
      });
    }
    return thisRef;
  }

  public ceasedPSCExpand() {
    const thisRef = this;
    if (this.business?.responseData?.creditsafeResult?.report?.additionalInformation?.personsWithSignificantControl?.ceasedPSC &&
      this.business?.responseData?.creditsafeResult?.report?.additionalInformation?.personsWithSignificantControl?.ceasedPSC?.length > 0) {
      this.business?.responseData?.creditsafeResult?.report?.additionalInformation?.personsWithSignificantControl?.ceasedPSC.forEach(x => {
        thisRef[x.name] = true;
      });
    }
    return thisRef;
  }

}
