import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SearchService } from './home/services/search.service';
import { Token } from './demo/V3/search-pages/models/Items';
import { Shared } from './demo/V3/services/shared';
import { IdleService } from './shared/service/idle.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  public loggedIn: boolean = false;
  public loggedInUser: string;
  public screen: boolean = false;
  public search: boolean = false;
  public settingsvisibility: boolean = true;
  public settingsPage: boolean = false;
  public tenantId: number;
  public userId: string;
  public token: string;
  public isShowHeader: boolean = true;
  public isShowFooter: boolean = true;

  constructor(private router: Router, private route: ActivatedRoute, private searchService: SearchService, private idleService: IdleService) {
    this.route.queryParams.subscribe((params) => {
      this.tenantId = params.TenantId;
      this.token = params.Token;
      this.userId = params.UserId;
      if (this.token && this.tenantId && this.userId) {
        this.redirectToSettingsWithTenant();
      }
    });

    // route detection
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isShowHeader = true;
        this.isShowFooter = true;
        if (event.url === "/settings" || this.tenantId) {
          this.settingsPage = true;
        } else if (event.url.includes('v3')) {
          this.isShowHeader = false;
          this.isShowFooter = false;
        } else {
          this.settingsPage = false;
        }
      }
    });
  }

  ngOnInit() {
    this.idleService.startWatching();
    let cc = window as any;
    this.loggedIn = localStorage.getItem("access_token") ? true : false;
    this.isLoginExpires();
  }

  ngDoCheck() {
    this.loggedIn = localStorage.getItem("access_token") ? true : false;
  }

  public redirectToSettingsWithTenant(): void {
    localStorage.removeItem("access_token");
    localStorage.removeItem("expires_in");
    localStorage.removeItem("token_type");
    localStorage.removeItem("userId");
    localStorage.removeItem("username");
    localStorage.setItem("access_token", this.token);
    localStorage.setItem("userId", this.userId);
    this.router.navigate(["/settings"], { queryParams: { Tenant: btoa(this.tenantId.toString()) } });
  }

  logout() {
    this.loggedInUser = "";
    localStorage.removeItem("access_token");
    localStorage.removeItem("expires_in");
    localStorage.removeItem("token_type");
    localStorage.removeItem("userId");
    localStorage.removeItem("username");
    this.search = false;
    this.screen = false;
    this.settingsvisibility = false;
    this.router.navigate(['/Login']);
  }

  toHome() {
    this.router.navigate(['/v3']);
  }

  screenDisplay() {
    this.router.navigate(["/screen"]);
  }
  searchDisplay() {
    this.router.navigate(["/search"]);
  }
  settings() {
    this.router.navigate(["/settings"]);
  }

  reloadComponent() {
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }

  public isLoginExpires() {
    let expiryDate = Shared.getAccessTokenExpiryDateTime(null);

    if (expiryDate) {
      var today = new Date();
      if (expiryDate < today) {
        var token = new Token(localStorage.getItem("access_token"));
        this.searchService.refreshtoken(token).subscribe(
          (data) => {
            localStorage.setItem("access_token", data["access_token"]);
            localStorage.setItem("expires_in", Shared.getExpiryDate(Shared.getAccessTokenExpiryDateTime(data["access_token"]).toUTCString()));
            localStorage.setItem("token_type", data["token_type"]);
          },
          (error) => {
            if (error.status == 400) {
              this.logout();
            }
          }
        );
      }
    }
  }

  public parseDate(dateStr): Date {
    return new Date(dateStr);
  }

  title = 'app';
}
