import { Component, Input, OnInit } from '@angular/core';
import { MessagingService } from '../../../../../../services/messaging.service';
import { PIPLResultModel } from '../../../../../models/PIPLResultModel';

@Component({
  selector: 'app-pipl-result',
  templateUrl: './pipl-result.component.html',
  styleUrls: ['./pipl-result.component.css', '../../../../shared/shared.searchpage.css']
})
export class PiplResultComponent implements OnInit {

  @Input() pipl: PIPLResultModel = new PIPLResultModel();

  constructor(private messageService: MessagingService) { }

  ngOnInit(): void {
  }

}
