<table class="table table-striped table-hover">
  <tbody>
    <tr>
      <th>Issued Share Capital Currency</th>
      <td>{{business?.responseData?.creditsafeResult?.report?.shareCapitalStructure?.issuedShareCapital?.currency}}</td>
    </tr>
    <tr>
      <th>Issued Share Captial Value</th>
      <td>{{business?.responseData?.creditsafeResult?.report?.shareCapitalStructure?.issuedShareCapital?.value}}</td>
    </tr>
    <tr>
      <th>Number Of Shares Issued</th>
      <td>{{business?.responseData?.creditsafeResult?.report?.shareCapitalStructure?.numberOfSharesIssued}}</td>
    </tr>
    <tr>
      <td colspan="2">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>Shareholder Name</th>
              <th>Shareholder Type</th>
              <th>Currency</th>
              <th>Total Number Of Shares Owned</th>
              <th>Total Value Of Shares Owned</th>
              <th>Percentage Of Shares Held</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let shares of business?.responseData?.creditsafeResult?.report?.shareCapitalStructure?.shareHolders">
              <td>{{shares?.name}}</td>
              <td>{{shares?.shareholderType}}</td>
              <td>{{shares?.currency}}</td>
              <td>{{shares?.totalNumberOfSharesOwned}}</td>
              <td>{{shares?.totalValueOfSharesOwned}}</td>
              <td>{{shares?.percentSharesHeld}}</td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  </tbody>
</table>
