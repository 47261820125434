import { Component, Input, OnInit } from '@angular/core';
import { AddressResponseModel } from '../../models/AddressResponseModel';
import { InputAccessData } from '../../models/InputDataModel';

@Component({
  selector: 'app-address-check',
  templateUrl: './address-check.component.html',
  styleUrls: ['./address-check.component.css']
})
export class AddressCheckComponent implements OnInit {

  @Input() public address: AddressResponseModel = new AddressResponseModel();
  @Input() public accessData: InputAccessData = new InputAccessData();

  public NoRelevantDataMessage = "No relevant data found.";
  public NoSearchSelect = "Search option not selected.";
  public SearchFail = "Search failed.";
  public postCodeRequiredMessage: string = "Postcode is required.";

  constructor() { }

  ngOnInit(): void {
    //console.log(this.address?.responseData?.data?.CompleteResponse?.Response);
  }

}
