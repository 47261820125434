import { Component, Input, OnInit } from '@angular/core';
import { InputAccessData, InputData } from '../../models/InputDataModel';

@Component({
  selector: 'app-access-toggle',
  templateUrl: './access-toggle.component.html',
  styleUrls: ['./access-toggle.component.css']
})
export class AccessToggleComponent implements OnInit {

  @Input() public inputData: InputData = new InputData();
  @Input() public accessData: InputAccessData = new InputAccessData();

  constructor() { }

  ngOnInit(): void {
  }

}
