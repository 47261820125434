export class ScreenSearchData {
  public Id: String;
  public JobID: String;
  public SubmitedBy: String;
  public StartedAt: String;
  public EndedAt: String;
  public JobStatus: String;
  public Filename: String;
  public RecordCount: Number;
  public SuccessCount: Number;
  public BlobstorageURL: String;
  public CreatedDate: String;
}

export enum ScreenType {
  personal = "personal",
  commercial = "commercial",
  mobile = "mobile"
}
