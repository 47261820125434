import { Component, Input, OnInit } from '@angular/core';
import { MessagingService } from '../../../../../services/messaging.service';
import { EmailageCheckResultModel } from '../../../../models/EmailResultModel';

@Component({
  selector: 'app-emailage-result-email',
  templateUrl: './emailage-result-email.component.html',
  styleUrls: ['./emailage-result-email.component.css', '../../../shared/shared.searchpage.css']
})
export class EmailageResultEmailComponent implements OnInit {

  @Input() emailage: EmailageCheckResultModel = new EmailageCheckResultModel(); 

  constructor(private messageService: MessagingService) { }

  ngOnInit(): void {
  }

}
