<div id="accordionTwo" class="accordion" *ngIf="!sports.noSearchSelect">
  <div class="card mb-0">
    <div class="card-header" data-toggle="collapse" href="#collapseTwo" id="headingTwo" aria-expanded="false" (click)="SportsTableDisplay()">
      <span>{{sports.collapseSportsStatus}} <i class="fa {{sports.collapseSportsIcon}}" aria-hidden="true"></i></span>
    </div>
    <div id="collapseTwo" class="card-body collapse {{sports.collapseSportsClass}} border-bottom" aria-labelledby="headingTwo" data-parent="#accordionTwo">
      <div class="row">
        <div class="col-md-12">
          <div class="table-responsive">
            <table class="table table-bordered table-striped" id="sportsStatusTable">
              <thead>
                <tr>
                  <th>Category</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let lifestyle of sports.sportsStatus">
                  <td>{{lifestyle.displayname}}</td>
                  <td>
                    <span>{{lifestyle.status}}</span>
                    <span class="loadingImg" *ngIf="lifestyle.searchloading">
                      <img src="{{messageService.loadingAnimation}}" />
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="lifestyleResult">
  <div class="col-md-12" *ngIf="sports.noSearchSelect"><i class="color-grey float-right">{{messageService.NoSearchSelect}}</i></div>
  <div class="row topmargin" *ngIf="!sports.noSearchSelect">
    <div class="col-md-12">
      <p class="results" *ngIf="sports.displaySportsCount">
        Search returned <span class="color-purple">{{sports.sportsResultCount}}</span> results
      </p>
    </div>
  </div>
  <br />
  <div class="row" *ngIf="!sports.noSearchSelect">
    <div class="col-md-12" *ngFor="let result of sports.finalSportsResults; let i=index">
      <div *ngIf="result.link">
        <div class="d-flex">
          <div class="col-md-10">
            <h5><a href="{{result.link}}" target="_blank" title="{{result.link}}">{{result.title}}</a></h5>
            <p [innerHTML]="result.description" class="description"></p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <p class="source"><small [hidden]="result.source == null">Source: {{result.source}}</small></p>
          </div>
          <div class="col-md-6">
            <p class="pubdate"><small [hidden]="result.pubDate == null">Published Date: {{result.pubDate}}</small></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
