import { Component, Input, OnInit } from '@angular/core';
import { InputData } from '../../models/InputDataModel';

@Component({
  selector: 'app-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.css']
})
export class SearchFormComponent implements OnInit {

  @Input() public inputData: InputData = new InputData();

  constructor() { }

  ngOnInit(): void {
  }

}
