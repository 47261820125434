export class Shared {

  public static getCurrentDate(): string {
    var currentdate = new Date();
    return currentdate.getDate() + "-" + this.monthName(currentdate.getMonth()) + "-" + currentdate.getFullYear();
  }

  public static getCurrentTime(): string {
    return new Date().toLocaleTimeString();
  }

  private static monthName(mon) {
    return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][mon];
  }

  public static getExpiryDate(utcDate: string): string{
    var date = new Date(utcDate);
    return date.toString();
  }

  public static getAccessTokenExpiryDateTime(token: string | null): Date | null {
    if(!token) token = localStorage.getItem("access_token");
    if(!token) return null;

    const jwtPayload = JSON.parse(window.atob(token.split('.')[1]));
    var expiryDate = new Date(0);
    expiryDate.setUTCSeconds(jwtPayload.exp);
    return expiryDate;
  }

}
