<div class="app-outer-container">
  <div class="container">
    <div class="form-data-background form-borderdesign  shadow-lg p-3 #F7F7F7">
      <img src="../../../assets/Images/AU-Logo.png" class="mr-4" style="width: 300px;" />
      <div class="row">
        <div class="col-6">
          <form (ngSubmit)="SearchUSIDCheckDemo()">
            <div class="form-group row">
              <div class="col-md-12">
                <div class="row padding-form-design">
                  <div class="col-md-12 z-index-formdata">
                    <div class="row">
                      <div class="input-group">
                        <div class="col-md-3">
                          <b>Name:</b>
                        </div>
                        <div class="col-md-9">
                          <div class="form-group">
                            <input type="text" placeholder="" class="form-control" [(ngModel)]="name" name="name" autocomplete="off" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row padding-form-design">
                  <div class="col-md-12 z-index-formdata">
                    <div class="row">
                      <div class="input-group">
                        <div class="col-md-3">
                          <b>Email:</b>
                        </div>
                        <div class="col-md-9">
                          <div class="form-group">
                            <input type="text" placeholder="" class="form-control" [(ngModel)]="email" name="email" autocomplete="off" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row padding-form-design pb-2">
                  <!--<div class="col-md-2"></div>-->
                  <div class="col-md-10">
                    <div class="row">
                      <div class="input-group">
                        <div class="col-md-12">
                          <div class="form-group">
                            <button type="submit" class="btn colorRed float-left mx-2">
                              Send <i class="fa fa-search" aria-hidden="true"></i>
                            </button>
                            <button type="button" class="btn colorRed float-left mx-2" (click)="ClearButtonClick1()">
                              Clear <i class="fa fa-times-circle fa-lg" aria-hidden="true"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-6">
          <form (ngSubmit)="UploadImageDemo()">
            <!--<img src="../../../assets/Images/AU-Logo.png" class="my-3" />-->
            <div class="form-group row">
              <div class="col-md-12">
                <div class="row padding-form-design">
                  <div class="col-md-12 z-index-formdata">
                    <div class="row">
                      <div class="input-group">
                        <div class="col-md-3">
                          <b>Reference:</b>
                        </div>
                        <div class="col-md-9">
                          <div class="form-group">
                            <input type="text" placeholder="" class="form-control" [(ngModel)]="reference2" name="reference" autocomplete="off" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row padding-form-design">
                  <div class="col-md-12 z-index-formdata">
                    <div class="row">
                      <div class="input-group">
                        <div class="col-md-3">
                          <b></b>
                        </div>
                        <div class="col-md-9">
                          <div class="custom-file">
                            <div class="float-left browse-File select-file">
                              <input type="file" id="customFile" class="fileUploadBrowse" accept="image/*" multiple (change)="onFileSelected($event)" ngModel #file />
                              <label for="customFile"></label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row padding-form-design pb-2">
                  <!--<div class="col-md-2"></div>-->
                  <div class="col-md-10">
                    <div class="row">
                      <div class="input-group">
                        <div class="col-md-12">
                          <div class="form-group">
                            <button type="submit" class="btn colorRed float-left mx-2">
                              Upload <i class="fa fa-upload" aria-hidden="true"></i>
                            </button>
                            <button type="button" class="btn colorRed float-left mx-2" (click)="ClearButtonClick2()">
                              Clear <i class="fa fa-times-circle fa-lg" aria-hidden="true"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="container">
        
        <div class="row">
          <div class="col-md-6">
            <div><span class="h5">Result: </span></div>
            <div *ngIf="USIdCheckResult?.status == 200">
              <p *ngIf="USIdCheckResult?.data"> US ID Check <i class="fa fa-check-circle color-green"></i></p>
              <p *ngIf="!USIdCheckResult?.data"> US ID Check <i class="fa fa-ban color-red"></i></p>
            </div>
          </div>
          <div class="col-md-6">
            <div><span class="h5">Result: </span></div>
            <div *ngIf="USIdCheckUploadResult?.status == 200">
              <p *ngIf="USIdCheckUploadResult?.data">Document uploaded <i class="fa fa-check-circle color-green"></i></p>
              <p *ngIf="!USIdCheckUploadResult?.data">Document uploaded <i class="fa fa-ban color-red"></i></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
