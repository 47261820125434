<div class="card-body">
  <span class="card-title Header-Font-Format"><b>Counter Fraud</b></span>
  <div class="col-md-12" *ngIf="counterFraud.noSearchSelect && !counterFraud.isLoading"><i class="color-grey float-right">{{messageService.NoSearchSelectShort}}</i></div>
  <div class="col-md-12" *ngIf="counterFraud.validationMessage && !counterFraud.isLoading"><i class="color-grey float-right">{{messageSercice.OtherFieldRequired}}</i></div>
  <div class="col-md-12" *ngIf="counterFraud.noRelevantData && !counterFraud.isLoading"><i class="color-grey float-right">{{messageService.NoRelevantDataMessageShort}}</i></div>
  <div class="col-md-12" *ngIf="counterFraud.searchFail && !counterFraud.isLoading"><i class="color-grey float-right">{{messageService.SearchFail}}</i></div>
  <span class="loadingImg" *ngIf="counterFraud.isLoading">
    <img src="{{messageService.loadingAnimation}}" />
  </span>
  <div class="row" *ngIf="!counterFraud.isLoading && !counterFraud.searchFail && !counterFraud.noRelevantData && !counterFraud.validationMessage && !counterFraud.noSearchSelect">
    <rg-gauge-chart class="align-gauge-chart" [canvasWidth]="canvasWidth"
                    [needleValue]="counterFraud.needleValue"
                    [centralLabel]="centralLabel"
                    [options]="options"
                    [name]=""
                    [bottomLabel]="bottomLabel"></rg-gauge-chart>
  </div>
</div>
