<div class="container">
  <div class="logo-div d-flex justify-content-center">
    <img class="logo" src="assets/Images/new/HUT-icon.v3-business-RO.png" />
  </div>
  <form role="form">
    <div class="form-group row form-top-margin">
      <div class="col-md-9 offset-md-2 form-data-background form-borderdesign  shadow-lg p-3">
        <div class="row padding-form-design">
          <div class="col-md-12 z-index-formdata">
            <div class="row">
              <div class="input-group">
                <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                  <b>Reference:</b>
                </div>
                <div class="col-md-8 pt-3">
                  <div class="form-group">
                    <input type="text" placeholder="" class="form-control" value="{{requestData.profileData.referenceNumber}}" autocomplete="off" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 z-index-formdata">
            <div class="row">
              <div class="input-group">
                <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                  <b>Company Number:</b>
                </div>
                <div class="col-md-8 pt-3">
                  <div class="form-group">
                    <input type="text" placeholder="" class="form-control" value="{{requestData.profileData.companyNumber}}" autocomplete="off" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 z-index-formdata">
            <div class="row">
              <div class="input-group">
                <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                  <b>Business Name:</b>
                </div>
                <div class="col-md-8 pt-3">
                  <div class="form-group">
                    <input type="text" placeholder="" class="form-control" value="{{requestData.profileData.companyName}}" autocomplete="off" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 z-index-formdata">
            <div class="row">
              <div class="input-group">
                <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                  <b>Business Postcode:</b>
                </div>
                <div class="col-md-8 pt-3">
                  <div class="form-group mb-0">
                    <input type="text" placeholder="" class="form-control" value="{{requestData.profileData.companyPostalCode}}" autocomplete="off" />
                    <p class="non-limited-label">Use for Non-limited business searches only</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 z-index-formdata">
            <div class="row">
              <div class="input-group">
                <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                  <b>VAT Number:</b>
                </div>
                <div class="col-md-8 pt-3">
                  <div class="form-group">
                    <input type="text" placeholder="" class="form-control" value="{{requestData.profileData.vatNumber}}" autocomplete="off" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 z-index-formdata">
            <div class="row">
              <div class="input-group">
                <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                  <b>Email:</b>
                </div>
                <div class="col-md-8 pt-3">
                  <div class="form-group">
                    <input type="email" placeholder="" class="form-control" value="{{requestData.profileData.email}}" autocomplete="off" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 z-index-formdata">
            <div class="row">
              <div class="input-group">
                <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                  <b>Mobile:</b>
                </div>
                <div class="col-md-8 pt-3">
                  <div class="form-group">
                    <input type="text" placeholder="" class="form-control" value="{{requestData.profileData.phoneNumber}}" autocomplete="off" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 z-index-formdata">
            <div class="row">
              <div class="input-group">
                <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                  <b>Proprietor Name:</b>
                </div>
                <div class="col-md-8 pt-3">
                  <div class="form-group mb-0">
                    <input type="text" placeholder="" class="form-control" value="{{requestData.profileData.fullName}}" autocomplete="off" />
                    <p class="non-limited-label">Use for Non-limited business searches only</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 z-index-formdata">
            <div class="row">
              <div class="input-group">
                <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                  <b>Proprietor Post Code:</b>
                </div>
                <div class="col-md-8 pt-3">
                  <div class="form-group mb-0">
                    <input type="text" placeholder="" class="form-control" value="{{requestData.profileData.postalCode}}" autocomplete="off" />
                    <p class="non-limited-label">Use for Non-limited business searches only</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 z-index-formdata">
            <div class="row">
              <div class="input-group">
                <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                  <b>Proprietor DOB:</b>
                </div>
                <div class="col-md-8 pt-3">
                  <div class="form-group mb-0">
                    <input type="date" placeholder="" class="form-control datepicker" value="{{requestData.profileData.dateOfBirth}}" autocomplete="off" />
                    <p class="non-limited-label">Use for Non-limited business searches only</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

</div>
