import { Component, Input, OnInit } from '@angular/core';
import { AssociatedResultModel } from '../../../../../../search-pages/models/AssociatedResultModel';
import { MessagingService } from '../../../../../../services/messaging.service';

@Component({
  selector: 'app-association-result-personal',
  templateUrl: './association-result.component.html',
  styleUrls: ['./association-result.component.css', '../../../../shared/shared.searchpage.css']
})
export class AssociationResultPersonalComponent implements OnInit {

  @Input() associated: AssociatedResultModel = new AssociatedResultModel();

  constructor(private messageService: MessagingService) { }

  ngOnInit(): void {
  }

}
