import { Component, Input, OnInit } from '@angular/core';
import { MessagingService } from '../../../../../../services/messaging.service';
import { AMLResultModel } from '../../../../../models/AMLResultModel';

@Component({
  selector: 'app-aml-result-personal',
  templateUrl: './aml-result.component.html',
  styleUrls: ['./aml-result.component.css', '../../../../shared/shared.searchpage.css']
})
export class AmlResultPersonalComponent implements OnInit {

  @Input() aml: AMLResultModel = new AMLResultModel();

  constructor(private messageService: MessagingService) { }

  ngOnInit(): void {
  }

  public isValidAMLAddress(obj: any[]) {
    let addresses = obj.filter(address => {
      if ((address.address1 != null && address.address1 != '') || (address.address2 != null && address.address2 != '') || (address.address3 != null && address.address3 != '') || (address.postcode != null && address.postcode != '') || (address.city != null && address.city != '') || (address.country != null && address.country != '')) {
        return address;
      }
    });
    if (addresses.length > 0)
      return true;
    else
      return false;
  }

}
