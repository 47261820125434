<div>
  <div class="col-md-12" *ngIf="!associated.isLoading && associated.noSearchSelect"><i class="color-grey float-right">{{messageService.NoSearchSelect}}</i></div>
  <div class="col-md-12" *ngIf="!associated.isLoading && !associated.noSearchSelect && (associated?.profileData?.associatedNames.length === 0)"><i class="color-grey float-right">{{messageService.AssiciateDataNotProvideMessage}}</i></div>
  <div class="col-md-12" *ngIf="!associated.isLoading && !associated.noSearchSelect && associated.searchFail && associated?.responseData?.length === 0"><i class="color-grey float-right">{{messageService.SearchFail}}</i></div>
  <div class="col-md-12" *ngIf="!associated.isLoading && !associated.noSearchSelect && !(associated?.profileData?.associatedNames.length === 0) && !associated.searchFail && (associated?.responseData?.length === 0)"><i class="color-grey float-right">{{messageService.NoRelevantDataMessage}}</i></div>
  <span class="loadingImg" *ngIf="associated.isLoading">
    <img src="{{messageService.loadingAnimation}}" />
  </span>
</div>
<div class="row" *ngIf="!associated.isLoading">
  <div class="col-md-12">
    <p class="results" *ngIf="associated?.responseData && associated?.responseData.length > 0">
      Search returned <span class="color-purple">{{associated?.responseData?.length}}</span> results
    </p>
  </div>
</div>
<br />
<div class="row" *ngIf="!associated.isLoading && associated?.responseData && associated?.responseData.length > 0">
  <div class="col-md-12 keep-together" *ngFor="let result of associated?.responseData; let i=index">
    <div *ngIf="result.link">
      <div class="d-flex">
        <div class="col-md-10">
          <h5><a href="{{result.link}}" target="_blank" title="{{result.link}}">{{result.title}}</a></h5>
          <p [innerHTML]="result.description" class="description"></p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <p class="source"><small [hidden]="result.source == null">Source: {{result.source}}</small></p>
        </div>
        <div class="col-md-6">
          <p class="pubdate"><small [hidden]="result.pubDate == null">Published Date: {{result.pubDate}}</small></p>
        </div>
      </div>
    </div>
  </div>
</div>
