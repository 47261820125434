import { Items } from "./Items";
import { ProfileData } from "./SearchRequestModel";

export class AssociatedResultModel {
  public profileData: ProfileData;
  public isLoading: boolean;
  public noSearchSelect: boolean;
  public responseData: Items[];
  public searchFail: boolean;

  public associatedResultCount: number = 0;
  public associatedResultCombine: Items[] = [];
}
