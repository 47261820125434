import { ProfileData } from "./SearchRequestModel";

export class CounterFraudResultModel {
  public isLoading: boolean;
  public noSearchSelect: boolean;
  public validationMessage: boolean;
  public noRelevantData: boolean;
  public searchFail: boolean;
  public responseData: any;
  public needleValue: number = 0;

  constructor() { }
}

export class ProspectResultModel {
  public profileData: ProfileData;
  public noSearchSelect: boolean;
  public isProspectHitLoading: boolean;
  public isProspectProfileLoading: boolean;
  public isProspectHitAccess: boolean;
  public isProspectProfileAccess: boolean;
  public validation: boolean;
  public searchFail: boolean;
  public prospectHitResponseData: any;
  public prospectProfileResponseData: any;
  public prospectProfileButtonDisable: boolean;
  public prospectProfileButtonText: string;
}
