<div class="card-body">
  <span class="card-title Header-Font-Format"><b>Email Check</b></span>
  <div *ngIf="!email.noError">
    <div class="col-md-12" *ngIf="accessData.Email && email?.validationMessage"><i class="color-grey float-right">{{emailRequiredMessage}}</i></div>
    <div class="col-md-12" *ngIf="!accessData.Email"><i class="color-grey float-right">{{NoSearchSelectShort}}</i></div>
    <div class="col-md-12" *ngIf="accessData.Email && email?.searchFail"><i class="color-grey float-right">{{SearchFail}}</i></div>
    <div class="col-md-12" *ngIf="accessData.Email && email?.noRelevantData"><i class="color-grey float-right">{{NoRelevantDataMessageShort}}</i></div>
  </div>
  <div *ngIf="email.noError && !email.searchFail">
    <p *ngIf="email?.responseData?.data === true"><i class="fa fa-ban color-red"></i> Blocked </p>
    <p *ngIf="email?.responseData?.data === false"><i class="fa fa-check-circle color-green"></i> Not Blocked </p>
  </div>
</div>
