<div class="card-body">
  <span class="card-title Header-Font-Format"><b>Mobile Number Enquiry</b></span>
  <span class="loadingImg" *ngIf="mobile.isLoading">
    <img src="{{messageService.loadingAnimation}}" />
  </span>
  <div *ngIf="!mobile.isLoading">
    <div class="col-md-12" *ngIf="mobile.noSearchSelect"><i class="color-grey float-right">{{messageService.NoSearchSelectShort}}</i></div>
    <div class="col-md-12" *ngIf="mobile.searchFail"><i class="color-grey float-right">{{messageService.SearchFail}}</i></div>
    <div class="col-md-12" *ngIf="mobile.validationError"><i class="color-grey float-right">{{messageService.MobileRequiredMessage}}</i></div>
  </div>
  <div class="row" *ngIf="!mobile.isLoading && !mobile.validationError && !mobile.searchFail && !mobile.noSearchSelect">
    <div class="col-md-6">
      <p>Phone Type: <span>{{mobile?.responseData?.data?.type | titlecase}}</span> </p>
      <p>Carrier: <span>{{mobile?.responseData?.data?.carrier}}</span> </p>
      <b>Sim Swap Details</b>
      <div *ngIf="mobile?.responseData?.data?.simSwap">
        <p *ngIf="mobile?.responseData?.data?.simSwap?.status == 'red'"><i class="fa fa-exclamation-triangle color-red" aria-hidden="true">&nbsp;High risk</i></p>
        <p *ngIf="mobile?.responseData?.data?.simSwap?.status == 'amber'"><i class="fa fa-exclamation-triangle color-amber" aria-hidden="true">&nbsp;Medium risk</i></p>
        <p *ngIf="mobile?.responseData?.data?.simSwap?.status == 'green'"><i class="fa fa-exclamation-triangle color-green" aria-hidden="true">&nbsp;Low risk</i></p>
        <p class="info-text-sim-swap" *ngIf="mobile?.responseData?.data?.simSwap?.date"><small>Swap Date: <br /><span>{{mobile?.responseData?.data?.simSwap?.date | date:'dd-MM-yyyy hh:mm:ss'}}</span> </small></p>
      </div>
      <div *ngIf="!mobile?.responseData?.data?.simSwap">
        <div class="col-md-12"><i class="color-grey float-right">{{messageService.NoRelevantDataMessageShort}}</i></div>
      </div>
    </div>
    <div class="col-md-6">
      <p *ngIf="mobile?.responseData?.data?.blocked"><i class="fa fa-ban color-red"></i> Blocked </p>
      <span><b>Name Match</b> </span>
      <p *ngIf="mobile?.responseData?.data?.matchStatus == null"><i class="color-grey float-right">{{messageService.NoRelevantDataMessageShort}}</i></p>
      <p *ngIf="mobile?.responseData?.data?.matchStatus">
        <i class="fa fa-check-circle color-green"></i> Matched
        <br /> <span *ngIf="mobile?.responseData?.data?.registeredUser">
                 <small>
                   Subscriber: {{mobile?.responseData?.data?.registeredUser | titlecase}}
                 </small>
        </span>
      </p>
      <p *ngIf="!mobile?.responseData?.data?.matchStatus && mobile?.responseData?.data?.matchStatus != null">
        <i class="fa fa-times-circle color-red"></i>
        NOT Matched <br />
        <span *ngIf="mobile?.responseData?.data?.registeredUser">
          <small>
            Subscriber: {{mobile?.responseData?.data?.registeredUser | titlecase}}
          </small>
        </span>
      </p>

      <b>Number Deactivation Details</b>
      <div *ngIf="mobile?.responseData?.data?.numberDeactivation">
        <p class="info-text-sim-swap" *ngIf="mobile?.responseData?.data?.numberDeactivation?.lastDeactivated">Last Deactivated: <span>{{mobile?.responseData?.data?.numberDeactivation?.lastDeactivated}}</span></p>
        <p class="info-text-sim-swap" *ngIf="mobile?.responseData?.data?.numberDeactivation?.trackingSince">Tracking Since: <span>{{mobile?.responseData?.data?.numberDeactivation?.trackingSince}}</span></p>
      </div>
      <div *ngIf="!mobile?.responseData?.data?.numberDeactivation">
        <div class="col-md-12"><i class="color-grey float-right">{{messageService.NoRelevantDataMessageShort}}</i></div>
      </div>
    </div>
  </div>
</div>
