import { AccessData } from "./SearchRequestModel";

export class EmailCheckResultModel {
  public isLoading: boolean;
  public noSearchSelect: boolean;
  public validation: boolean;
  public searchFail: boolean;
  public noRelevantData: boolean;
  public responseData: any;
}

export class EmailageCheckResultModel {
  public isLoading: boolean;
  public noSearchSelect: boolean;
  public searchFail: boolean;
  public responseData: any;
  public validation: boolean;
  public urls: any[];
}

export class RightToWorkCheckResultModel {
  public access: AccessData;
  public isLoading: boolean;
  public validation: boolean;
  public searchFail: boolean;
  public responseData: any;
}

export class IdCheckResultModel {
  public access: AccessData;
  public isLoading: boolean;
  public validation: boolean;
  public searchFail: boolean;
  public responseData: any;
}
