import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SearchService } from '../../../services/search.service';
import { Item } from '../../models/Items';
import { AccessData, ProfileData, RequestData } from '../../models/SearchRequestModel';
import { SearchType } from '../../models/SearchType';

@Component({
  selector: 'app-professional',
  templateUrl: './professional.component.html',
  styleUrls: ['./professional.component.css', '../shared/shared.searchpage.css']
})
export class ProfessionalComponent implements OnInit {

  public professionalForm: FormGroup;
  public validation: any = {
    'reference': [Validators.required],
    'name': [Validators.required]
  };
  public accessData: AccessData = new AccessData();
  public showResultTab: boolean = false;

  constructor(private elementRef: ElementRef,
    private formBuilder: FormBuilder,
    private router: Router,
    private searchService: SearchService) { }

  ngOnInit(): void {
    let isLoggedIn = localStorage.getItem("access_token") ? true : false;
    if (!isLoggedIn)
      this.router.navigate(['/Login']);
    this.searchService.GetAllowedSearchAvailablityItems().subscribe(data => {
      let keys = Array.from(data).map(x => x.key);
      if (!keys.find(x => x == "CHECKPROFESSIONAL")) {
        this.router.navigate(['/v3']);
      }
    }, error => {
      console.log(error);
    })

    this.professionalForm = this.formBuilder.group({
      audit: [""],
      reference: [""],
      name: [""],
      category: [""],
      registerNumber: [""]
    });
    this.getLicenceItems();
  }

  ngAfterViewInit() {
    this.elementRef.nativeElement.ownerDocument.body.style.background = 'url(/assets/Images/new/Background/HUT-elem-bg-1.png)';
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundAttachment = 'fixed';
  }

  public professionalSearch(): void {
    this.addValidators();
    this.professionalForm.updateValueAndValidity();

    if (this.professionalForm.valid) {

      Promise.resolve(this.getRandomString(10)).then(
        (data) => {
          //this.resetResultObjects();

          this.showResultTab = true;
          var requestObj: RequestData = this.prepareRequestData();

        }
      );

    }
  }

  public async getRandomString(length) {
    await this.searchService.GetAuditKey().then(
      data => {
        this.professionalForm.patchValue({
          audit: data
        });
      });
  }

  // Add the validations during the 'search' button click
  public addValidators(): void {
    for (const control in this.professionalForm.controls) {
      this.professionalForm.get(control).clearValidators();
      this.professionalForm.get(control).setValidators(this.validation[control]);
      this.professionalForm.get(control).updateValueAndValidity();
    }
  }

  // Clear the validations during the 'clear' button click
  // This'll remove all the error message
  public professionalClearSearch(): void {
    for (const control in this.professionalForm.controls) {
      this.professionalForm.get(control).clearValidators();
      this.professionalForm.get(control).updateValueAndValidity();
    }
    this.professionalForm.patchValue({
      audit: "",
      reference: "",
      name: "",
      category: "",
      registerNumber: ""
    });
    this.showResultTab = false;
    //this.resetResultObjects();
  }

  // Prepare the request object for API call
  public prepareRequestData(): RequestData {
    // Profile object framing
    var profileData = new ProfileData();
    profileData.auditKey = this.professionalForm.get('audit').value;
    profileData.referenceNumber = this.professionalForm.get('reference').value;
    profileData.category = this.professionalForm.get('category').value;
    profileData.registerNumber = this.professionalForm.get('registerNumber').value;
    profileData.fullName = this.professionalForm.get('name').value;
    if (this.professionalForm.get('name').value) {
      let searchtextarray = this.professionalForm.get('name').value.split(" ");
      profileData.firstName = searchtextarray[0];
      profileData.lastName = searchtextarray.length > 1 ? searchtextarray[searchtextarray.length - 1] : searchtextarray[1];
      profileData.midleName = searchtextarray.length > 2 ? searchtextarray[1] : null;
    } else {
      profileData.firstName = null;
      profileData.lastName = null;
      profileData.midleName = null;
    }

    profileData.searchType = SearchType.Professional.toString();

    // RequestData object framing
    var requestObj = new RequestData();
    requestObj.profileData = profileData;
    requestObj.accessData = this.accessData;
    return requestObj;
  }

  public getLicenceItems(): void {
    this.searchService.AllowedLicence().subscribe(data => {
      this.getSecondAccess(data);
    });
  }

  public getSecondAccess(Items: Item[]) {
    Items.forEach(item => {
      if (item.licenceType.toLowerCase() == "search") {
        switch ((item.key)) {
          case "HUTDB": {
            this.accessData.isHUTDatabaseAccess = item.defaultValue;
            this.accessData.isHUTDatabaseToggle = item.toggle;
            break;
          }
          case "MOBILE": {
            this.accessData.isMobileAccess = item.defaultValue;
            this.accessData.isMobileToggle = item.toggle;
            break;
          }
          case "EMAIL": {
            this.accessData.isEmailAccess = item.defaultValue;
            this.accessData.isEmailToggle = item.toggle;
            break;
          }
          case "IDENTITY": {
            this.accessData.isIdentityAccess = item.defaultValue;
            this.accessData.isIdentityToggle = item.toggle;
            break;
          }
          case "FINANCIAL": {
            this.accessData.isFinancialAccess = item.defaultValue;
            this.accessData.isFinancialToggle = item.toggle;
            break;
          }
          case "ADDRESS": {
            this.accessData.isAddressAccess = item.defaultValue;
            this.accessData.isAddressToggle = item.toggle;
            break;
          }
          case "CRIMINALITY": {
            this.accessData.isCriminalityAccess = item.defaultValue;
            this.accessData.isCriminalityToggle = item.toggle;
            break;
          }
          case "SOCIAL": {
            this.accessData.isSocialMediaAccess = item.defaultValue;
            this.accessData.isSocialMediaToggle = item.toggle;
            break;
          }
          case "COUNTER_FRAUD": {
            this.accessData.isCounterFraudAccess = item.defaultValue;
            this.accessData.isCounterFraudToggle = item.toggle;
            break;
          }
          case "SPORTS": {
            this.accessData.isSportsAccess = item.defaultValue;
            this.accessData.isSportsToggle = item.toggle;
            break;
          }
          case "ADVERT": {
            this.accessData.isAdvertsAccess = item.defaultValue;
            this.accessData.isAdvertsToggle = item.toggle;
            break;
          }
          case "COMPANY_HOUSE": {
            this.accessData.isCompaniesHouseAccess = item.defaultValue;
            this.accessData.isCompaniesHouseToggle = item.toggle;
            break;
          }
          case "AVA": {
            this.accessData.isAVAAccess = item.defaultValue;
            this.accessData.isAVAToggle = item.toggle;
            break;
          }
          case "AML": {
            this.accessData.isAMLAccess = item.defaultValue;
            this.accessData.isAMLToggle = item.toggle;
            break;
          }
          case "CCJ_Detail": {
            this.accessData.isCCJDetailAccess = item.defaultValue;
            this.accessData.isCCJDetailToggle = item.toggle;
            break;
          }
          case "Insolvency_Detail": {
            this.accessData.isInsolvencyDetailAccess = item.defaultValue;
            this.accessData.isInsolvencyDetailToggle = item.toggle;
            break;
          }
          case "RTW": {
            this.accessData.isRTWAccess = item.defaultValue;
            this.accessData.isRTWToggle = item.toggle;
            break;
          }
          case "IDCHECK": {
            this.accessData.isIDCheckAccess = item.defaultValue;
            this.accessData.isIDCheckToggle = item.toggle;
            break;
          }
        }
      }
    });
  }

}
