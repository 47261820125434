import { Component, Input, OnInit } from '@angular/core';
import { BusinessResultModel } from '../../../../models/BusinessResultModel';

@Component({
  selector: 'app-ltd-accounts-business',
  templateUrl: './ltd-accounts-business.component.html',
  styleUrls: ['./ltd-accounts-business.component.css']
})
export class LtdAccountsBusinessComponent implements OnInit {

  @Input() public business: BusinessResultModel = new BusinessResultModel();

  constructor() { }

  ngOnInit(): void {
  }

}
