import { Items } from "../../../../home/models/Items";

export class MobileResultModel {
  public isLoading: any;
  public noSearchSelect: boolean;
  public responseData: any;
  public searchFail: boolean;
  public validationError: boolean;
  constructor() { }
}

export class OpenSourceResultModel {
  public isLoading: any;
  public noSearchSelect: boolean;
  public responseData: Items[];
  public tempResponseData: Items[];
  public openSourceResultCount: number = 0;
  public searchFail: boolean;
  public validationError: boolean;
  constructor() { }
}
