<div class="card-body">
  <span class="card-title Header-Font-Format"><b>Mortality</b></span>
  <div class="row" *ngIf="!financial.isLoading">
    <span *ngIf="financial?.responseData?.data?.MortalityFlag != null">Status: <span [ngClass]="financial?.responseData?.data?.MortalityFlag ? 'status-Red':'status-Green'"> {{financial?.responseData?.data?.MortalityFlag ?'Red':'Green'}}</span></span>
  </div>
  <div class="col-md-12" *ngIf="!financial.isLoading && financial.noSearchSelect"><i class="color-grey float-right">{{messageService.NoSearchSelectShort}}</i></div>
  <div class="col-md-12" *ngIf="!financial.isLoading && !financial.noSearchSelect && !financial.searchFail && (financial.noRelevantData || financial?.responseData?.data?.MortalityFlag == null) && !financial.validation"><i class="color-grey float-right">{{messageService.NoRelevantDataMessageShort}}</i></div>
  <div class="col-md-12" *ngIf="!financial.isLoading && !financial.noSearchSelect && financial.searchFail && !financial.validation"><i class="color-grey float-right">{{messageService.SearchFail}}</i></div>
  <div class="col-md-12" *ngIf="!financial.isLoading && !financial.noSearchSelect && financial.validation"><i class="color-grey float-right">{{messageService.PostCodeRequiredMessage}}</i></div>
  <span class="loadingImg" *ngIf="financial.isLoading">
    <img src="{{messageService.loadingAnimation}}" />
  </span>
</div>
<div class="card-body">
  <span class="card-title Header-Font-Format"><b>CCJ</b></span>
  <div class="row" *ngIf="!financial.isLoading">
    <span *ngIf="financial?.responseData?.data?.CcjFlag != null">Status: <span [ngClass]="financial?.responseData?.data?.CcjFlag ? 'status-Red':'status-Green'"> {{financial?.responseData?.data?.CcjFlag ?'Red':'Green'}}</span></span>
  </div>
  <div class="col-md-12" *ngIf="!financial.isLoading && financial.noSearchSelect"><i class="color-grey float-right">{{messageService.NoSearchSelectShort}}</i></div>
  <div class="col-md-12" *ngIf="!financial.isLoading && !financial.noSearchSelect && !financial.searchFail && (financial.noRelevantData || financial?.responseData?.data?.CcjFlag == null) && !financial.validation"><i class="color-grey float-right">{{messageService.NoRelevantDataMessageShort}}</i></div>
  <div class="col-md-12" *ngIf="!financial.isLoading && !financial.noSearchSelect && financial.searchFail && !financial.validation"><i class="color-grey float-right">{{messageService.SearchFail}}</i></div>
  <div class="col-md-12" *ngIf="!financial.isLoading && !financial.noSearchSelect && financial.validation"><i class="color-grey float-right">{{messageService.PostCodeRequiredMessage}}</i></div>
  <span class="loadingImg" *ngIf="financial.isLoading">
    <img src="{{messageService.loadingAnimation}}" />
  </span>
</div>
<div class="card-body">
  <span class="card-title Header-Font-Format"><b>Insolvency</b></span>
  <div class="row" *ngIf="!financial.isLoading">
    <span *ngIf="financial?.responseData?.data?.InsolvencyFlag != null">Status: <span [ngClass]="financial?.responseData?.data?.InsolvencyFlag ? 'status-Red':'status-Green'"> {{financial?.responseData?.data?.InsolvencyFlag ?'Red':'Green'}}</span></span>
  </div>
  <div class="col-md-12" *ngIf="!financial.isLoading && financial.noSearchSelect"><i class="color-grey float-right">{{messageService.NoSearchSelectShort}}</i></div>
  <div class="col-md-12" *ngIf="!financial.isLoading && !financial.noSearchSelect && !financial.searchFail && (financial.noRelevantData || financial?.responseData?.data?.InsolvencyFlag == null) && !financial.validation"><i class="color-grey float-right">{{messageService.NoRelevantDataMessageShort}}</i></div>
  <div class="col-md-12" *ngIf="!financial.isLoading && !financial.noSearchSelect && financial.searchFail && !financial.validation"><i class="color-grey float-right">{{messageService.SearchFail}}</i></div>
  <div class="col-md-12" *ngIf="!financial.isLoading && !financial.noSearchSelect && financial.validation"><i class="color-grey float-right">{{messageService.PostCodeRequiredMessage}}</i></div>
  <span class="loadingImg" *ngIf="financial.isLoading">
    <img src="{{messageService.loadingAnimation}}" />
  </span>
</div>
