import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { isPossiblePhoneNumber, isValidPhoneNumber, parsePhoneNumber, validatePhoneNumberLength } from 'libphonenumber-js';
import { environment } from '../../../../../../environments/environment';
import { CSVRecords, ScreenInputData } from '../../../../../screensearch/Models/CSVRecords';
import { ScreenSearchData, ScreenType } from '../../../../../screensearch/Models/ScreenSearchData';
import { SearchService } from '../../../services/search.service';
import { interval } from 'rxjs';

@Component({
  selector: 'app-screen',
  templateUrl: './screen.component.html',
  styleUrls: ['./screen.component.css', '../shared/shared.searchpage.css']
})
export class ScreenComponent implements OnInit {
  @ViewChild('file') fileInput: ElementRef;

  public commercialTemplateURL = environment.commercialTemplateURL;
  public personalTemplateURL = environment.personalTemplateURL;
  public mobileTemplateURL = environment.mobileTemplateURL;

  public file: File = null;
  public tempFile: any = null;
  public filename = "Choose File";
  public eventTarget: any;
  public tempEventTarget: any;
  public validationerror: String = "";
  public successMessage: String = "";
  public allowedTemplates: string[];
  public screenSearchData: ScreenSearchData[];
  public loadScreenIntervalCall: any;

  // Header positions
  public referenceNumberColumn: number = null;
  public businessNameColumn: number = null;
  public ltdNumberColumn: number = null;
  public businessPostcodeColumn: number = null;
  public businessMobileColumn: number = null;
  public businessEmailColumn: number = null;
  public principalNameColumn: number = null;
  public principalPostcodeColumn: number = null;
  public principalMobileColumn: number = null;
  public principalEmailColumn: number = null;
  public principalDobColumn: number = null;
  public lossDateColumn: number = null;

  constructor(private elementRef: ElementRef,
    private router: Router,
    private searchService: SearchService) { }

  ngOnInit(): void {
    let isLoggedIn = localStorage.getItem("access_token") ? true : false;
    if (!isLoggedIn)
      this.router.navigate(['/Login']);
    this.searchService.GetAllowedSearchAvailablityItems().subscribe(data => {
      let keys = Array.from(data).map(x => x.key);
      if (!keys.find(x => x == "HUTSCREEN")) {
        this.router.navigate(['/v3']);
      }
    }, error => {
      console.log(error);
    })
    this.getScreenAllowedTemplates();
    this.loadScreenDataGrid();
    this.loadScreenIntervalCall = interval((2 * 60) * 1000).subscribe(val => this.loadScreenDataGrid());
  }

  ngOnDestroy() {
    this.loadScreenIntervalCall.unsubscribe();
  }

  ngAfterViewInit() {
    this.elementRef.nativeElement.ownerDocument.body.style.background = 'url(/assets/Images/new/Background/HUT-elem-bg-1.png)';
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundAttachment = 'fixed';
  }

  public getScreenAllowedTemplates() {
    this.searchService.AllowedLicence().subscribe(
      data => {
        let screenTemplate = data.filter(x => x.licenceType.toLowerCase() == "screen" && x.defaultValue == true);
        this.allowedTemplates = screenTemplate.map(x => x.itemName.toLowerCase());
      }
    );
  }

  public uploadFileEvent(event): void {
    if (event.target.files.length > 0) {
      this.filename = event.target.files[0].name
      this.file = event.srcElement.files;
      this.tempFile = event.srcElement.files[0];
      this.eventTarget = event.target;
      this.tempEventTarget = event.target.files[0];
      this.validationerror = null;
      this.successMessage = "";
    }
  }

  public clearMessage() {
    setTimeout(() => {
      this.validationerror = null;
      this.successMessage = null;
    }, 5000);
  }

  public getDataFromFile(csvRecordsArray: string[], headersRow: string[]): ScreenInputData {
    let cSVRecords: CSVRecords[];
    // company type
    if (this.referenceNumberColumn != null && this.businessNameColumn != null && this.businessPostcodeColumn != null &&
      this.businessMobileColumn != null && this.businessEmailColumn != null) {
      if (!this.allowedTemplates.some(x => x == ScreenType.commercial.toString())) {
        this.validationerror = "You don't have access to this template.";
        this.clearMessage();
        return null;
      }
      const type = ScreenType.commercial.toString();
      cSVRecords = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length, type);
      var screenInputData = new ScreenInputData(type, cSVRecords, this.filename);
      return screenInputData;
    }
    // person type
    else if (this.referenceNumberColumn != null && (this.businessNameColumn == null && this.businessPostcodeColumn == null &&
      this.ltdNumberColumn == null && this.lossDateColumn == null) && this.principalNameColumn != null && this.principalPostcodeColumn != null &&
      this.principalMobileColumn != null && this.principalEmailColumn != null && this.principalDobColumn != null) {
      if (!this.allowedTemplates.some(x => x == ScreenType.personal.toString())) {
        this.validationerror = "You don't have access to this template.";
        this.clearMessage();
        return null;
      }
      const type = ScreenType.personal.toString();
      cSVRecords = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length, type);
      var screenInputData = new ScreenInputData(type, cSVRecords, this.filename);
      return screenInputData;
    }
    // mobile type
    else if (this.referenceNumberColumn != null && (this.businessNameColumn == null && this.businessPostcodeColumn == null &&
      this.ltdNumberColumn == null) && this.principalNameColumn != null && this.principalPostcodeColumn != null &&
      this.principalMobileColumn != null && this.principalEmailColumn != null && this.principalDobColumn != null && this.lossDateColumn != null) {
      if (!this.allowedTemplates.some(x => x == ScreenType.mobile.toString())) {
        this.validationerror = "You don't have access to this template.";
        this.clearMessage();
        return null;
      }
      const type = ScreenType.mobile.toString();
      cSVRecords = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length, type);
      var screenInputData = new ScreenInputData(type, cSVRecords, this.filename);
      return screenInputData;
    }
    // invalid type
    else if (this.referenceNumberColumn == null && this.businessNameColumn == null && this.businessPostcodeColumn == null &&
      this.ltdNumberColumn == null && this.principalNameColumn == null && this.principalPostcodeColumn == null &&
      this.principalMobileColumn == null && this.principalEmailColumn == null && this.principalDobColumn == null) {
      this.validationerror = "Please upload valid file";
      this.clearMessage();
      return null;
    }
    else {
      this.validationerror = "Please upload valid file";
      this.clearMessage();
      return null;
    }
  }

  //Storing File records in CSVFile Object array 
  public getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any, type: string) {
    let csvArr: CSVRecords[] = [];
    let principalMobile: string;
    for (let i = 1; i <= csvRecordsArray.length - 1; i++) {
      try {
        var currentRecord = csvRecordsArray[i].split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/).map(item => item.replace(/["']/g, ""));

        if (currentRecord.every(x => x.trim() == "")) continue;

        let reference: string = this.referenceNumberColumn != null ? currentRecord[this.referenceNumberColumn].trim() : "";
        let businessName: string = this.businessNameColumn != null ? currentRecord[this.businessNameColumn].trim() : "";
        let ltdNumber: string = this.ltdNumberColumn != null ? currentRecord[this.ltdNumberColumn].trim() : "";
        let businessPostcode: string = this.businessPostcodeColumn != null ? currentRecord[this.businessPostcodeColumn].trim() : "";
        let businessMobile: string = this.businessMobileColumn != null ? currentRecord[this.businessMobileColumn].trim() : "";
        let businessEmail: string = this.businessEmailColumn != null ? currentRecord[this.businessEmailColumn].trim() : "";
        let principalName: string = this.principalNameColumn != null ? currentRecord[this.principalNameColumn].trim() : "";
        let principalPostCode: string = this.principalPostcodeColumn != null ? currentRecord[this.principalPostcodeColumn].trim() : "";
        let principalEmail: string = this.principalEmailColumn != null ? currentRecord[this.principalEmailColumn].trim() : "";
        let principalDob: string = this.principalDobColumn != null ? currentRecord[this.principalDobColumn].trim() : "";
        let principalMobile: string = this.principalMobileColumn != null ? currentRecord[this.principalMobileColumn].trim() : "";
        let eventDate: string = this.lossDateColumn != null ? currentRecord[this.lossDateColumn].trim() : "";

        principalMobile = this.getFormatedPhoneNumber(principalMobile);
        businessMobile = this.getFormatedPhoneNumber(businessMobile);

        if (currentRecord.length == headerLength) {
          if (type == ScreenType.commercial.toString())
            csvArr.push(new CSVRecords(reference, businessName, "", businessPostcode, businessMobile, businessEmail, "", "", "", "", "", ""));
          else
            csvArr.push(new CSVRecords(reference, "", "", "", "", "", principalName, principalPostCode, principalMobile, principalEmail, principalDob, eventDate));
        } else if ((currentRecord.length != headerLength && !currentRecord[0].includes("\""))) {
          csvArr.push(new CSVRecords(reference, "", "", "", "", "", "", "", "", "", "", ""));
        }
      } catch (error) { console.log("Error on getDataRecordsArrayFromCSVFile : ", error); }
    }
    return csvArr;
  }

  public getFormatedPhoneNumber(phoneNumber: string): string {
    try {
      if (phoneNumber != "" && phoneNumber != null) {
        phoneNumber = phoneNumber.trim();
        const phoneNumberTemp = parsePhoneNumber(phoneNumber, 'GB');
        try {
          return phoneNumberTemp.formatInternational();
        } catch (err) {
          return "Bad data";
        }
      }
      return "";
    } catch (error) {
      console.log("Phone Number: ", phoneNumber);
      console.log("getFormatedPhoneNumber : Error : ", error);
      return "";
    }
  }

  public loadScreenDataGrid() {
    this.searchService.GetScreenSearchData().subscribe(data => {
      this.screenSearchData = data;
    });
    this.getScreenAllowedTemplates();
  }

  public requestScreenSearch(screenInputData: ScreenInputData) {
    this.searchService.ProcessScreenSearch(screenInputData)
      .subscribe(data => {
        this.successMessage = "Search file processed into queue.";
        this.resetFileInput();
        this.tempFile = null;
        this.loadScreenDataGrid();
        this.clearMessage();
      }, error => {
        if (error.status == 404) {
          this.validationerror = "You don't have access to this template.";
          this.clearMessage();
        } else {
          this.validationerror = "Something went wrong. try once again.";
          this.clearMessage();
        }
      });
  }

  resetFileInput() {
    this.filename = "Choose File";
    this.file = null;
    this.fileInput.nativeElement.value = "";
  }

  public resetColumnIndex() {
    this.referenceNumberColumn = null;
    this.businessNameColumn = null;
    this.ltdNumberColumn = null;
    this.businessPostcodeColumn = null;
    this.principalNameColumn = null;
    this.principalPostcodeColumn = null;
    this.principalMobileColumn = null;
    this.principalEmailColumn = null;
    this.principalDobColumn = null;
    this.lossDateColumn = null;
    this.businessMobileColumn = null;
    this.businessEmailColumn = null;
  }

  public getColumnIndex(columnNames: string[]) {
    this.resetColumnIndex();
    columnNames.forEach((x, index) => {
      switch (x.toLowerCase().trim()) {
        case "Reference No".toLowerCase():
          this.referenceNumberColumn = index;
          break;
        case "Business Name".toLowerCase():
          this.businessNameColumn = index;
          break;
        case "Ltd Co Number".toLowerCase():
          this.ltdNumberColumn = index;
          break;
        case "Business Postcode".toLowerCase():
          this.businessPostcodeColumn = index;
          break;
        case "Principal Name".toLowerCase():
          this.principalNameColumn = index;
          break;
        case "Principal Postcode".toLowerCase():
          this.principalPostcodeColumn = index;
          break;
        case "Principal Mobile".toLowerCase():
          this.principalMobileColumn = index;
          break;
        case "Principal Email".toLowerCase():
          this.principalEmailColumn = index;
          break;
        case "Principal DoB".toLowerCase():
          this.principalDobColumn = index;
          break;
        case "Loss Date".toLowerCase():
          this.lossDateColumn = index;
          break;
        case "Business Mobile".toLowerCase():
          this.businessMobileColumn = index;
          break;
        case "Business Email".toLowerCase():
          this.businessEmailColumn = index;
          break;
      }
    });
  }

  public isValidCSVFile() {
    return this.filename.endsWith(".csv");
  }

  public getHeaderArray(csvRecordsArr: any): string[] {
    let headers = (<string>csvRecordsArr[0]).split(',');
    let headerArray: string[] = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j].toString());
    }
    return headerArray;
  }

  public screenSearch() {

    if (this.isValidCSVFile() && this.tempFile) {
      let reader = new FileReader();
      reader.readAsText(this.tempFile);
      reader.onload = () => {
        let csvData = reader.result;
        let csvRecordsArray = (<string>csvData).split(/\r\n|\n/);
        csvRecordsArray = csvRecordsArray.filter(x => x || !(/^[,]*$/.test(x)));
        let headersRow = this.getHeaderArray(csvRecordsArray);
        this.getColumnIndex(headersRow);
        let screenInputData = this.getDataFromFile(csvRecordsArray, headersRow);
        if (screenInputData && screenInputData.data.length > 0) {
          this.requestScreenSearch(screenInputData);
        } else if (screenInputData.data.length == 0) {
          this.validationerror = "Please upload csv file with data.";
          this.clearMessage();
        }
      }
    } else {
      this.validationerror = "Please upload .csv file.";
      this.clearMessage();
    }
  }

  public refreshResult() {
    this.loadScreenDataGrid();
  }

}
