import { Component, OnInit, ElementRef } from '@angular/core';
import { LoginService } from '../../service/login.service';
import { Forgetpassword } from '../../models/forgetpassword';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {
  public forgetpassword: Forgetpassword = new Forgetpassword("");
  public validationerror: string;
  public loading: boolean;
  public resendtext: boolean;

  constructor(
    private loginService: LoginService,
    private router: Router,
    private elementRef: ElementRef) { }
  ngAfterViewInit() {
    this.elementRef.nativeElement.ownerDocument.body.style.background = 'url(/assets/Images/LoginBackground.svg)';
  }

  ngOnInit() {
  }

  public ForgetPassword() {
    this.loading = true;
    this.loginService.forgetpassword(this.forgetpassword)
      .subscribe(
        data => {
          this.loading = false;
          this.router.navigate(["/Thankyou"], { queryParams: { status: 'forgotPassword' } });
        },
        error => {
          console.log(error);
          if (error.status == 400) {
            if (JSON.parse(error._body)["Email"][0] == "Invalid Email") {
              this.loading = false;
              this.validationerror = "Email must be a valid email address";
              setTimeout(() => {
                this.validationerror = "";
              }, 5000);
            }
          }
          else if (error.status == 401) {
            this.loading = false;
            this.validationerror = "";
            this.router.navigate(["/Thankyou"], { queryParams: { status: 'forgotPassword' } });
          }
          else if (error.status == 403) {
            this.resendtext = true;
            this.loading = false;
            this.validationerror = "Your email is not verified. We have sent you a verification email. Please click on the link there to verify your email address.";
            setTimeout(() => {
              this.validationerror = "";
            }, 12000);
          }
          else if (error.status == 404) {
            this.loading = false;
            this.validationerror = "";
            this.router.navigate(["/Thankyou"], { queryParams: { status: 'forgotPassword' } });
          }
          else {
            this.loading = false;
            this.validationerror = "Internal Server Error";
            setTimeout(() => {
              this.validationerror = "";
            }, 5000);
          }
        });
  }

  public ResendLink() {
    this.router.navigate(["/ResendLink"]);
  }
}
