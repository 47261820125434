<div class="col-md-12" *ngIf="!ccj.isLoading && !ccj.validation && !ccj.noSearchSelect && (ccj.searchFail || ccj?.responseData?.Results?.Summary?.ResultText == 'FAIL')"><i class="color-grey float-right">{{messageService.SearchFail}}</i></div>
<div class="col-md-12" *ngIf="!ccj.isLoading && !ccj.searchFail && !ccj.validation && !ccj.noSearchSelect && (ccj?.responseData?.ccjDetails == null || ccj?.responseData?.ccjDetails.length == 0)"><i class="color-grey float-right">{{messageService.NoRelevantDataMessage}}</i></div>
<div class="col-md-12" *ngIf="!ccj.isLoading && !ccj.searchFail && ccj.validation && !ccj.noSearchSelect"><i class="color-grey float-right">{{messageService.OtherFieldRequired}}</i></div>
<div class="col-md-12" *ngIf="!ccj.isLoading && !ccj.searchFail && !ccj.validation && ccj.noSearchSelect"><i class="color-grey float-right">{{messageService.NoSearchSelect}}</i></div>
<div>
  <span class="loadingImg" *ngIf="ccj.isLoading">
    <img src="{{messageService.loadingAnimation}}" />
  </span>
</div>
<div *ngIf="ccj?.responseData && !ccj.isLoading && !(ccj?.responseData?.ccjDetails == null || ccj?.responseData?.ccjDetails.length == 0)">
  <div class="row">
    <div class="col-md-6 border-bottom" *ngFor="let ccj of ccj?.responseData?.ccjDetails">
      <div class="shadow-sm bg-white p-4 h-100 keep-together">
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade active show" id="ccjdetail" role="tabpanel" aria-labelledby="ccjdetail-tab">
            <div class="bg-white card addresses-item mb-4 border border-primary shadow">
              <div class="gold-members pt-2 pl-2 pr-2">
                <div class="media">
                  <div class="media-body">
                    <div class="border-bottom p-4">
                      <div>
                        <h6 *ngIf="ccj.courtName" class="mb-2">Court Name : {{ccj.courtName}}</h6>
                        <p *ngIf="ccj.caseNumber" class="mb-1">Case Number : {{ccj.caseNumber}}</p>
                        <p *ngIf="ccj.amount" class="mb-1">Amount : <span [innerHTML]="ccj.amount"></span></p>
                        <p *ngIf="ccj.judgementType" class="mb-1">Type : {{ccj.judgementType}}</p>
                        <p *ngIf="ccj.judgementDate" class="mb-1">Date : {{ccj.judgementDate | date: 'dd-MM-yyyy'}}</p>
                        <p *ngIf="ccj.name" class="mb-1">Name : {{ccj.name}}</p>
                        <div *ngIf="ccj.address1 || ccj.address2 || ccj.address3 || ccj.address4 || ccj.address5 || ccj.postcode" class="mb-1">
                          <div>Address : </div>
                          <div *ngIf="ccj.address1">{{ccj.address1}}</div>
                          <div *ngIf="ccj.address2">{{ccj.address2}}</div>
                          <div *ngIf="ccj.address3">{{ccj.address3}}</div>
                          <div *ngIf="ccj.address4">{{ccj.address4}}</div>
                          <div *ngIf="ccj.address5">{{ccj.address5}}</div>
                          <div *ngIf="ccj.postcode">{{ccj.postcode}}</div>
                        </div>
                        <p *ngIf="ccj.dateEnd" class="mb-1">Satisfied Date : {{ccj.dateEnd | date: 'dd-MM-yyyy'}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
