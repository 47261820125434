import { Component, Input, OnInit } from '@angular/core';
import { MessagingService } from '../../../../../../services/messaging.service';
import { T2AResultModel } from '../../../../../models/AddressResultModel';

@Component({
  selector: 'app-t2a-result-personal',
  templateUrl: './t2a-result-personal.component.html',
  styleUrls: ['./t2a-result-personal.component.css', '../../../../shared/shared.searchpage.css']
})
export class T2aResultPersonalComponent implements OnInit {

  @Input() public t2a: T2AResultModel = new T2AResultModel();

  constructor(private messageService: MessagingService) { }

  ngOnInit(): void {
  }

}
