import { Component, Input, OnInit } from '@angular/core';
import { ProspectResultModel } from '../../../../models/CounterFraudResultModel';
import { MobileResultModel } from '../../../../models/MobileResultModel';
import { RequestData } from '../../../../models/SearchRequestModel';
import { AddressResultModel } from '../../../../models/AddressResultModel';
import { TenantRegion } from '../../../../models/TenantRegionResposne';

@Component({
  selector: 'app-export-pdf-mobile',
  templateUrl: './export-pdf-mobile.component.html',
  styleUrls: ['./export-pdf-mobile.component.css', '../../../shared/shared.searchpage.css']
})
export class ExportPdfMobileComponent implements OnInit {

  @Input() public requestData: RequestData = new RequestData();
  @Input() public mobile: MobileResultModel = new MobileResultModel();
  @Input() public opensource: MobileResultModel = new MobileResultModel();
  @Input() public prospect: ProspectResultModel = new ProspectResultModel();
  @Input() public address: AddressResultModel = new AddressResultModel();
  @Input() public regionAccess: TenantRegion = new TenantRegion();

  constructor() { }

  ngOnInit(): void {
  }

}
