<div *ngIf="socialMedia.noSearchSelect == false">
  <div id="accordionThree" class="accordion">
    <div class="card mb-0">
      <div class="card-header" data-toggle="collapse" href="#collapseThree" id="headingThree" aria-expanded="false">
        <span>{{socialMedia.collapseSocialMediaStatus}} <i class="fa {{socialMedia.collapseSocialMediaIcon}}" aria-hidden="true"></i></span>
      </div>
      <div id="collapseThree" class="card-body collapse {{socialMedia.collapseSocialMediaClass}} border-bottom" aria-labelledby="headingThree" data-parent="#accordionThree">
        <div class="row">
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th>Category</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let media of socialMedia.socialMediaStatus">
                    <td>{{media.displayname}}</td>
                    <td>
                      <span>{{media.status}}</span>
                      <span class="loadingImg" *ngIf="media.searchloading">
                        <img src="{{messageService.loadingAnimation}}" />
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--------------- Display Social Media Results --------------->
  <div id="socialResult" *ngIf="socialMedia.finalSocialMediaResults.length > 0">
    <div class="row topmargin">
      <div class="col-md-12">
        <p class="results">
          Search returned <span class="color-purple">{{socialMedia.SocialMediaResultCount}}</span> results
        </p>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-12 marginBottom" *ngFor="let result of socialMedia.finalSocialMediaResults;let i=index">
        <div *ngIf="result.link">
          <div class="row">
            <div class="col-md-1" *ngIf="result.imageURL">
              <img src="{{result.imageURL}}" height="50" width="50" class="fbImage" />
            </div>
            <div class="col-md-11 d-flex">
              <div class="col-md-9">
                <h5><a href="{{result.link}}" target="_blank" title="{{result.link}}">{{result.title}}</a></h5>
                <p [innerHTML]="result.description" class="description"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
