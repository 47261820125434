import { Component, Input, OnInit } from '@angular/core';
import { MessagingService } from '../../../../../../services/messaging.service';
import { DVLAResultModel } from '../../../../../models/DVLAResultModel';

@Component({
  selector: 'app-motor-result-motor',
  templateUrl: './motor-result-motor.component.html',
  styleUrls: ['./motor-result-motor.component.css', '../../../../shared/shared.searchpage.css']
})
export class MotorResultMotorComponent implements OnInit {

  @Input() motor: DVLAResultModel = new DVLAResultModel();

  constructor(private messageService: MessagingService) { }

  ngOnInit(): void {
  }

}
