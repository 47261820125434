import { Component, OnInit, Directive, ElementRef } from '@angular/core';
import { Contact } from '../../models/contact';
import { LoginService } from '../../service/login.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';


declare var $: any;

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.css']
})

export class ContactFormComponent implements OnInit {
  public contact: Contact = new Contact("", "", "", "");
  public validationerror: string;
  public loading: boolean;
  public loggedIn: boolean;
  public nameError: string;
  public telephoneNumberPattern = "^([0-9\(\)\/\+ \-]*)$";

  constructor(
    private loginService: LoginService,
    private router: Router,
    private formBuilder: FormBuilder,
    private elementRef: ElementRef) { }

  ngAfterViewInit() {
    this.elementRef.nativeElement.ownerDocument.body.style.background = 'url(/assets/Images/LoginBackground.svg)';
  }

  ngOnInit() {
    const fragment = document.createRange().createContextualFragment('<script src=\'https://www.google.com/recaptcha/api.js\'></script>');
    this.elementRef.nativeElement.appendChild(fragment);

    this.loggedIn = localStorage.getItem("access_token") ? true : false;
    if (this.loggedIn) {
      this.router.navigate(["/search"]);
    }
    else {
      this.router.navigate(["/ContactUs"]);
    }
  }

  public ContactSubmit() {
    this.contact.recaptcha = $(".g-recaptcha-response").val();

    if (this.contact.recaptcha === "") {
      this.validationerror = "Please complete the Captcha";
      setTimeout(() => {
        this.validationerror = "";
      }, 5000);
    }
    else {
      this.loading = true;
      this.loginService.ContactDetails(this.contact)
        .subscribe(
          data => {
            this.router.navigate(["/Thankyou"], { queryParams: { status: 'contactdetails' } });
            this.loading = false;
          },
          error => {
            if (error.status == 400) {
              this.loading = false;
              this.validationerror = JSON.parse(error._body)["ErrorMessage"][0];
              setTimeout(() => {
                this.validationerror = "";
              }, 5000);
            }
            else {
              this.loading = false;
              this.validationerror = "Something went wrong";
              setTimeout(() => {
                this.validationerror = "";
              }, 5000);
              console.log(error);
            }

          });
    }
  }



}
