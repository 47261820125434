import { Component, Input, OnInit } from '@angular/core';
import { ProspectResultModel } from '../../../../models/CounterFraudResultModel';
import { EmailageCheckResultModel, EmailCheckResultModel } from '../../../../models/EmailResultModel';
import { RequestData } from '../../../../models/SearchRequestModel';
import { TenantRegion } from '../../../../models/TenantRegionResposne';
import { MobileResultModel } from '../../../../models/MobileResultModel';

@Component({
  selector: 'app-export-pdf-email',
  templateUrl: './export-pdf-email.component.html',
  styleUrls: ['./export-pdf-email.component.css']
})
export class ExportPdfEmailComponent implements OnInit {

  @Input() public requestData: RequestData = new RequestData();
  @Input() public emailageOrgId: string = "";
  @Input() public emailValidation = new EmailCheckResultModel();
  @Input() public emailage = new EmailageCheckResultModel();
  @Input() public prospect: ProspectResultModel = new ProspectResultModel();
  @Input() public regionAccess: TenantRegion = new TenantRegion();
  @Input() public opensource: MobileResultModel = new MobileResultModel();

  constructor() { }

  ngOnInit(): void {
  }

}
