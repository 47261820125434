import { Component, Input, OnInit } from '@angular/core';
import { InputAccessData, InputData } from '../../models/InputDataModel';
import { ProspectResponseModel } from '../../models/ProspectResponseModel';

@Component({
  selector: 'app-prospect-check',
  templateUrl: './prospect-check.component.html',
  styleUrls: ['./prospect-check.component.css']
})
export class ProspectCheckComponent implements OnInit {

  @Input() public prospectResultData: ProspectResponseModel = new ProspectResponseModel();
  @Input() public inputData: InputData = new InputData();
  @Input() public accessData: InputAccessData = new InputAccessData();

  public NoSearchSelect = "Search option not selected.";
  public SearchFail = "Search failed.";
  public NoRelevantDataMessage = "No relevant data found.";
  public OtherFieldRequired = "Additional data is required.";

  constructor() { }

  ngOnInit(): void {
  }

}
