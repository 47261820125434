<div class="container mb-5">
  <div class="mb-5">
    <div class="col-md-12" *ngIf="!business.isLoading && business?.noRelevantData"><i class="color-grey float-right">{{messageService.NoRelevantDataMessage}}</i></div>
    <span class="loadingImg" *ngIf="business.isLoading">
      <img src="{{messageService.loadingAnimation}}" />
    </span>
    <div class="background-white result-display-padding form-borderdesign result-view-align" *ngIf="!business.isLoading && !business?.noRelevantData && business?.responseData?.creditsafeResult">
      <br />
      <ul class="nav nav-tabs" id="searchResultTab" role="tablist">
        <li class="nav-item">
          <a class="nav-link active" id="register-result-tab" data-toggle="tab" href="#registerResult" role="tab"
             aria-controls="profile" aria-selected="false"><b>Registration</b></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="officers-result-tab" data-toggle="tab" href="#officersResult" role="tab"
             aria-controls="profile" aria-selected="false"><b>Officers</b></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="shares-result-tab" data-toggle="tab" href="#sharesResult" role="tab"
             aria-controls="profile" aria-selected="false"><b>Shares</b></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="account-result-tab" data-toggle="tab" href="#accountResult" role="tab"
             aria-controls="profile" aria-selected="false"><b>Accounts</b></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="miscellaneous-result-tab" data-toggle="tab" href="#miscellaneousResult" role="tab"
             aria-controls="profile" aria-selected="false"><b>Miscellaneous Financials</b></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="companyHistory-result-tab" data-toggle="tab" href="#companyHistoryResult" role="tab"
             aria-controls="profile" aria-selected="false"><b>Company History</b></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="commentary-result-tab" data-toggle="tab" href="#commentaryResult" role="tab"
             aria-controls="profile" aria-selected="false"><b>Commentary On Performance</b></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="rating-result-tab" data-toggle="tab" href="#ratingResult" role="tab"
             aria-controls="profile" aria-selected="false"><b>Rating History</b></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="creditLimit-result-tab" data-toggle="tab" href="#creditLimitResult" role="tab"
             aria-controls="profile" aria-selected="false"><b>Credit Limit History</b></a>
        </li>
      </ul>

      <div class="tab-content" id="searchResultTabContent">
        <br />

        <!-- Registeration Results -->
        <div class="tab-pane fade show tab-height active" id="registerResult" role="tabpanel" aria-labelledby="register-result-tab">
          <app-ltd-registeration-business [business]="business"></app-ltd-registeration-business>
        </div>

        <!-- Officers Results -->
        <div class="tab-pane fade show tab-height" id="officersResult" role="tabpanel" aria-labelledby="officers-result-tab">
          <app-ltd-officers-business [business]="business"></app-ltd-officers-business>
        </div>

        <!-- Shares Result -->
        <div class="tab-pane fade show tab-height" id="sharesResult" role="tabpanel" aria-labelledby="shares-result-tab">
          <app-ltd-shares-business [business]="business"></app-ltd-shares-business>
        </div>

        <!-- Accounts Result -->
        <div class="tab-pane fade show tab-height" id="accountResult" role="tabpanel" aria-labelledby="account-result-tab">
          <app-ltd-accounts-business [business]="business"></app-ltd-accounts-business>
        </div>

        <!-- Miscellaneous Financials -->
        <div class="tab-pane fade show tab-height" id="miscellaneousResult" role="tabpanel" aria-labelledby="miscellaneous-result-tab">
          <app-ltd-miscellaneous-financials-business [business]="business"></app-ltd-miscellaneous-financials-business>
        </div>

        <!-- Comapany History Financials -->
        <div class="tab-pane fade show tab-height" id="companyHistoryResult" role="tabpanel" aria-labelledby="companyHistory-result-tab">
          <app-ltd-companyhistory-financials-business [business]="business"></app-ltd-companyhistory-financials-business>
        </div>

        <!-- Commentary On Performance Financials -->
        <div class="tab-pane fade show tab-height" id="commentaryResult" role="tabpanel" aria-labelledby="commentary-result-tab">
          <app-ltd-commentaryperformance-financials-business [business]="business"></app-ltd-commentaryperformance-financials-business>
        </div>

        <!-- Rating History Financials -->
        <div class="tab-pane fade show tab-height" id="ratingResult" role="tabpanel" aria-labelledby="rating-result-tab">
          <app-ltd-ratinghistory-business [business]="business"></app-ltd-ratinghistory-business>
        </div>

        <!-- Credit Limit Financials -->
        <div class="tab-pane fade show tab-height" id="creditLimitResult" role="tabpanel" aria-labelledby="creditLimit-result-tab">
          <app-ltd-creditlimit-business [business]="business"></app-ltd-creditlimit-business>
        </div>

      </div>
    </div>

    <!-- Non Limited Company result -->
    <div *ngIf="!business.isLoading && !business?.noRelevantData && business?.responseData?.businessNonRegisteredProfileResult">
      <app-non-ltd-business [business]="business"></app-non-ltd-business>
    </div>
  </div>
</div>
