<div class="card-body">
  <div>
    <span class="card-title Header-Font-Format d-flex justify-content-center mb-3"><b>Email</b></span>
    <div>
      <div class="row mb-3">
        <div class="col-md-6 p-0 text-right">
          Risk
        </div>
        <div class="col-md-6 p-0 text-left">
          <div>
            <div *ngIf="!emailage.isLoading">
              <div class="col-md-12" *ngIf="emailage.noSearchSelect"><i class="color-grey float-right">{{messageService.NoSearchSelectShort}}</i></div>
              <div class="col-md-12" *ngIf="emailage.validation"><i class="color-grey float-right">{{messageService.EmailRequiredMessage}}</i></div>
              <div class="col-md-12" *ngIf="emailage.searchFail"><i class="color-grey float-right">{{messageService.SearchFail}}</i></div>
              <div class="col-md-12" *ngIf="emailage.noRelevantData"><i class="color-grey float-right">{{messageSercice.NoRelevantDataMessageShort}}</i></div>
            </div>
            <div class="loadingImg" *ngIf="emailage.isLoading">
              <img src="{{messageService.loadingAnimation}}" />
            </div>
            <div *ngIf="!emailage.isLoading && !emailage.searchFail && emailage.responseData">
              <div *ngIf="emailage.responseData?.data?.emailRiskInfo?.riskBandId == 1 || emailage.responseData?.data?.emailRiskInfo?.riskBandId == 2">
                <div class="box box-green ml-3">{{emailage.responseData?.data?.emailRiskInfo?.riskBandIdStatus}}</div>
              </div>
              <div *ngIf="emailage.responseData?.data?.emailRiskInfo?.riskBandId == 3">
                <div class="box box-orange ml-3">{{emailage.responseData?.data?.emailRiskInfo?.riskBandIdStatus}}</div>
              </div>
              <div *ngIf="emailage.responseData?.data?.emailRiskInfo?.riskBandId >=4 && emailage.responseData?.data?.emailRiskInfo?.riskBandId <= 6">
                <div class="box box-red ml-3">{{emailage.responseData?.data?.emailRiskInfo?.riskBandIdStatus}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 p-0 text-right">
          Match
        </div>
        <div class="col-md-6 p-0 text-left">
          <div>
            <div class="loadingImg" *ngIf="financial.isEmailProvided && financial.isLoading">
              <img src="{{messageService.loadingAnimation}}" />
            </div>

            <div *ngIf="!financial.isLoading">
              <div class="col-md-12" *ngIf="financial.noSearchSelect"><i class="color-grey float-right">{{messageService.NoSearchSelectShort}}</i></div>
              <div class="col-md-12" *ngIf="!financial.noSearchSelect && !financial.validation && !financial.isEmailProvided"><i class="color-grey float-right">{{messageService.EmailRequiredMessage}}</i></div>
              <div class="col-md-12" *ngIf="!financial.noSearchSelect && financial.validation && financial.isEmailProvided"><i class="color-grey float-right">{{messageService.PostCodeRequiredMessage}}</i></div>
              <div class="col-md-12" *ngIf="!financial.noSearchSelect && !financial.searchFail && (!financial?.responseData?.data?.Emails || financial?.responseData?.data?.Emails.length == 0)"><i class="color-grey float-right">{{messageService.NoRelevantDataMessageShort}}</i></div>
            </div>

            <div *ngIf="financial.isEmailProvided && !financial.isLoading && !financial.searchFail && financial.responseData && (financial?.responseData?.data?.Emails && financial?.responseData?.data?.Emails.length > 0)">
              <span *ngIf="financial?.responseData?.data?.IsEmailFound == true" class="box box-green ml-3">YES</span>
              <span *ngIf="financial?.responseData?.data?.IsEmailFound == false" class="box box-red ml-3">NO</span>
            </div>
            <div *ngIf="financial.isEmailProvided && !financial.isLoading && !financial.searchFail && financial.responseData && (!financial?.responseData?.data?.Emails || financial?.responseData?.data?.Emails.length == 0)">
              <span class="ml-3 color-grey">No Data</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
