<div class="container">
  <div>
    <div class="col-md-12" *ngIf="!opensource.isLoading && !opensource.validationError && opensource.searchFail && !opensource.noSearchSelect"><i class="color-grey float-right">{{messageService.SearchFail}}</i></div>
    <div class="col-md-12" *ngIf="!opensource.isLoading && !opensource.validationError && (!opensource.responseData || opensource.responseData.length == 0) && !opensource.noSearchSelect"><i class="color-grey float-right">{{messageService.NoRelevantDataMessage}}</i></div>
  </div>

  <div class="loadingImg" *ngIf="opensource.isLoading">
    <img src="{{messageService.loadingAnimation}}" />
  </div>

  <div *ngIf="opensource.responseData && opensource.responseData.length > 0">
    <br />
    <div class="col-md-12" *ngFor="let result of opensource.responseData; let i=index">
      <div class="d-flex">
        <div class="col-md-10">
          <h5><a href="{{result.link}}" target="_blank" title="{{result.link}}">{{result.title}}</a></h5>
          <p [innerHTML]="result.description" class="description"></p>
        </div>
        <!--<div class="col-md-2 d-flex justify-content-end">
          <div>
            <i class="pi pi-thumbs-up mr-2 like" [ngClass]="{'thumbs-up': result.like == true}"></i>
          </div>
          <div>
            <i class="pi pi-thumbs-down dislike" [ngClass]="{'thumbs-down': result.dislike == true}"></i>
          </div>
        </div>-->
      </div>
      <div class="row">
        <div class="col-md-6">
          <p class="source"><small [hidden]="result.source == null">Source: {{result.source}}</small></p>
        </div>
        <div class="col-md-6">
          <p class="pubdate"><small [hidden]="result.pubDate == null">Published Date: {{result.pubDate}}</small></p>
        </div>
      </div>
    </div>
  </div>
  
</div>
