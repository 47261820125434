import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccessData, ProfileData, RequestData } from '../../../models/SearchRequestModel';
import { PDFExportComponent } from '@progress/kendo-angular-pdf-export';
import { SearchService } from '../../../../services/search.service';
import { Router } from '@angular/router';
import { Item } from '../../../models/Items';
import { SearchType } from '../../../models/SearchType';
import { Shared } from '../../../../services/shared';
import { VehicleIdResultModel } from '../../../models/VehicleIdResultModel';
import { MotorWebResultModel } from '../../../models/MotorWebResultModel';
import { MessagingService } from '../../../../services/messaging.service';
import { WhiteSpace } from '../../../../validators/no-space.validator';
import { AustraliaSearchService } from 'src/app/demo/V3/services/australia.search.service';

@Component({
  selector: 'app-au-motor',
  templateUrl: './au-motor.component.html',
  styleUrls: ['./au-motor.component.css', '../../shared/shared.searchpage.css']
})
export class AuMotorComponent implements OnInit {

  public validation: any = {
    'reference': [Validators.required, Validators.minLength(5), Validators.maxLength(255), WhiteSpace.noSpaceAllowed],
    'plate': [Validators.required],
    'state': [Validators.required]
  };
  public motorForm: FormGroup;
  public accessData: AccessData = new AccessData();
  public showResultTab: boolean = false;
  public saveAsPDFButtonVisibility: boolean = true;
  public clearSearchButtonVisibility: boolean = true;
  public loggedInUsername: string = "";
  public dropdownStates: any[] = [{ label: 'Select State', value: '', id: 0 }];

  // Result Object
  public motorWebResultModel: MotorWebResultModel = new MotorWebResultModel();
  public vehicleIdResultModel: VehicleIdResultModel = new VehicleIdResultModel();

  // PDF
  public requestDataModel: RequestData = new RequestData();
  @ViewChild('pdf') pdf: PDFExportComponent;

  constructor(private elementRef: ElementRef,
    private formBuilder: FormBuilder,
    private searchService: SearchService,
    private australiaSearchService: AustraliaSearchService,
    private router: Router,
    private messageService: MessagingService) { }

  ngOnInit(): void {
    let isLoggedIn = localStorage.getItem("access_token") ? true : false;
    if (!isLoggedIn)
      this.router.navigate(['/Login']);
    this.saveAsPDFButtonVisibility = false;
    this.clearSearchButtonVisibility = false;
    this.searchService.GetAllowedSearchAvailablityItems().subscribe(data => {
      let keys = Array.from(data).map(x => x.key);
      if (!keys.find(x => x == "MOTOR")) {
        this.router.navigate(['/v3']);
      }
    }, error => {
      console.log(error);
    });

    this.motorForm = this.formBuilder.group({
      audit: [""],
      reference: [""],
      plate: [""],
      state: [""]
    });
    this.getLicenceItems();
    this.getUserDetails();
    this.getStatesForDropdown();
  }

  ngAfterViewInit() {
    this.elementRef.nativeElement.ownerDocument.body.style.background = 'url(/assets/Images/new/Background/HUT-elem-bg-1.png)';
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundAttachment = 'fixed';
  }

  public motorSearch(): void {
    
    this.addValidators();
    this.motorForm.updateValueAndValidity();
    
    if (this.motorForm.valid) {

      Promise.resolve(this.getRandomString(10)).then(
        (data) => {
          this.saveAsPDFButtonVisibility = true;
          this.clearSearchButtonVisibility = true;
          this.resetResultObjects();

          this.showResultTab = true;
          var requestObj: RequestData = this.prepareRequestData();
          this.requestDataModel = requestObj;

          // Vehicle Id Search
          this.vehicleIdSearch(requestObj);

          // Motor Web Search
          this.motorWebSearch(requestObj);
        }
      );

    }
  }

  // Get states dropdown values
  public getStatesForDropdown() {
    this.searchService.GetStatesForDropdown().subscribe(data => {
      if (data && data.length > 0) {
        data.forEach(x => {
          this.dropdownStates.push({ id: x.id, label: x.name, value: x.code });
        })
      }
    }, error => {
      console.log(error);
    })
  }

  // Vehicle Id Search
  public vehicleIdSearch(requestObj: RequestData): void {
    this.vehicleIdResultModel = new VehicleIdResultModel();
    this.vehicleIdResultModel.isLoading = false;
    this.vehicleIdResultModel.noRelevantData = false;
    this.vehicleIdResultModel.responseData = null;
    this.vehicleIdResultModel.searchFail = false;

    this.vehicleIdResultModel.isLoading = true;
    this.australiaSearchService.GetVehicleIdSearchResult(requestObj).subscribe(data => {
      this.vehicleIdResultModel.isLoading = false;
      if (data.status == 200 && data.data) {
        this.vehicleIdResultModel.responseData = JSON.parse(data.data);
        if (!this.vehicleIdResultModel.responseData.vehicleList && this.vehicleIdResultModel.responseData.vehicleList.length == 0) {
          this.vehicleIdResultModel.noRelevantData = true;
        }
      } else {
        this.vehicleIdResultModel.noRelevantData = true;
      }
    }, error => {
      this.vehicleIdResultModel.isLoading = false;
      this.vehicleIdResultModel.searchFail = true;
      console.log(error);
    })
  }

  // Motor Web Search
  public motorWebSearch(requestObj: RequestData): void {
    this.motorWebResultModel = new MotorWebResultModel();
    this.motorWebResultModel.isLoading = false;
    this.motorWebResultModel.noRelevantData = false;
    this.motorWebResultModel.responseData = null;
    this.motorWebResultModel.searchFail = false;

    this.motorWebResultModel.isLoading = true;
    this.australiaSearchService.GetMotorWebSearchResult(requestObj).subscribe(data => {
      this.motorWebResultModel.isLoading = false;
      if (data.status == 200 && data.data) {
        this.motorWebResultModel.responseData = JSON.parse(data.data);
      }else{
        this.motorWebResultModel.noRelevantData = true;
      }
    }, error => {
      this.motorWebResultModel.isLoading = false;
      this.motorWebResultModel.searchFail = true;
      console.log(error);
    });
  }

  // Add the validations during the 'search' button click
  public addValidators(): void {
    for (const control in this.motorForm.controls) {
      this.motorForm.get(control).clearValidators();
      this.motorForm.get(control).setValidators(this.validation[control]);
      this.motorForm.get(control).updateValueAndValidity();
    }
  }

  // Prepare the request object for API call
  public prepareRequestData(): RequestData {
    // Profile object framing
    var profileData = new ProfileData();
    profileData.auditKey = this.motorForm.get('audit').value;
    profileData.referenceNumber = this.motorForm.get('reference').value.replace(/\s/g, "");
    profileData.vRN = this.motorForm.get('plate').value;
    profileData.state = this.motorForm.get('state').value;

    profileData.searchType = SearchType.Motor.toString();
    profileData.searchDate = Shared.getCurrentDate();
    profileData.searchTime = Shared.getCurrentTime();
    profileData.searchUsername = this.loggedInUsername;

    // Access object framing
    var accessData = new AccessData();
    accessData = this.accessData;

    // RequestData object framing
    var requestObj = new RequestData();
    requestObj.profileData = profileData;
    requestObj.accessData = accessData;
    return requestObj;
  }

  // Reset Object
  public resetResultObjects() {
    this.motorWebResultModel = null;
    this.vehicleIdResultModel = null;
  }

  public getUserDetails() {
    this.searchService.getUserDetails().subscribe(data => {
      this.loggedInUsername = data.username;
    }, error => {
      console.log(error);
    });
  }

  public async getRandomString(length) {
    await this.searchService.GetAuditKey().then(
      data => {
        this.motorForm.patchValue({
          audit: data
        });
      });
  }

  public getLicenceItems(): void {
    this.searchService.AllowedLicence().subscribe(data => {
      this.getSecondAccess(data);
    });
  }

  public getSecondAccess(Items: Item[]) {
    Items.forEach(item => {
      if (item.licenceType.toLowerCase() == "search") {
        switch ((item.key)) {
          case "HUTDB": {
            this.accessData.isHUTDatabaseAccess = item.defaultValue;
            this.accessData.isHUTDatabaseToggle = item.toggle;
            break;
          }
          case "MOBILE": {
            this.accessData.isMobileAccess = item.defaultValue;
            this.accessData.isMobileToggle = item.toggle;
            break;
          }
          case "EMAIL": {
            this.accessData.isEmailAccess = item.defaultValue;
            this.accessData.isEmailToggle = item.toggle;
            break;
          }
          case "IDENTITY": {
            this.accessData.isIdentityAccess = item.defaultValue;
            this.accessData.isIdentityToggle = item.toggle;
            break;
          }
          case "FINANCIAL": {
            this.accessData.isFinancialAccess = item.defaultValue;
            this.accessData.isFinancialToggle = item.toggle;
            break;
          }
          case "ADDRESS": {
            this.accessData.isAddressAccess = item.defaultValue;
            this.accessData.isAddressToggle = item.toggle;
            break;
          }
          case "CRIMINALITY": {
            this.accessData.isCriminalityAccess = item.defaultValue;
            this.accessData.isCriminalityToggle = item.toggle;
            break;
          }
          case "SOCIAL": {
            this.accessData.isSocialMediaAccess = item.defaultValue;
            this.accessData.isSocialMediaToggle = item.toggle;
            break;
          }
          case "COUNTER_FRAUD": {
            this.accessData.isCounterFraudAccess = item.defaultValue;
            this.accessData.isCounterFraudToggle = item.toggle;
            break;
          }
          case "SPORTS": {
            this.accessData.isSportsAccess = item.defaultValue;
            this.accessData.isSportsToggle = item.toggle;
            break;
          }
          case "ADVERT": {
            this.accessData.isAdvertsAccess = item.defaultValue;
            this.accessData.isAdvertsToggle = item.toggle;
            break;
          }
          case "COMPANY_HOUSE": {
            this.accessData.isCompaniesHouseAccess = item.defaultValue;
            this.accessData.isCompaniesHouseToggle = item.toggle;
            break;
          }
          case "AVA": {
            this.accessData.isAVAAccess = item.defaultValue;
            this.accessData.isAVAToggle = item.toggle;
            break;
          }
          case "AML": {
            this.accessData.isAMLAccess = item.defaultValue;
            this.accessData.isAMLToggle = item.toggle;
            break;
          }
          case "CCJ_Detail": {
            this.accessData.isCCJDetailAccess = item.defaultValue;
            this.accessData.isCCJDetailToggle = item.toggle;
            break;
          }
          case "Insolvency_Detail": {
            this.accessData.isInsolvencyDetailAccess = item.defaultValue;
            this.accessData.isInsolvencyDetailToggle = item.toggle;
            break;
          }
          case "RTW": {
            this.accessData.isRTWAccess = item.defaultValue;
            this.accessData.isRTWToggle = item.toggle;
            break;
          }
          case "IDCHECK": {
            this.accessData.isIDCheckAccess = item.defaultValue;
            this.accessData.isIDCheckToggle = item.toggle;
            break;
          }
        }
      }
    });
  }

}
