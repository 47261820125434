<div class="card-body">
  <div>

    <div class="row mb-3">
      <div class="col-md-6 p-0 text-right">
        Email
      </div>
      <div class="col-md-6 p-0 text-left">
        <div>
          <div *ngIf="!emailage.isLoading">
            <div class="col-md-12" *ngIf="emailage.noSearchSelect"><i class="color-grey float-right">{{messageService.NoSearchSelectShort}}</i></div>
            <div class="col-md-12" *ngIf="emailage.validation"><i class="color-grey float-right">{{messageService.EmailRequiredMessage}}</i></div>
            <div class="col-md-12" *ngIf="emailage.searchFail"><i class="color-grey float-right">{{messageService.SearchFail}}</i></div>
            <div class="col-md-12" *ngIf="emailage.noRelevantData"><i class="color-grey float-right">{{messageSercice.NoRelevantDataMessageShort}}</i></div>
          </div>
          <div class="loadingImg" *ngIf="emailage.isLoading">
            <img src="{{messageService.loadingAnimation}}" />
          </div>
          <div *ngIf="!emailage.isLoading && !emailage.searchFail && emailage.responseData">
            <div *ngIf="emailage.responseData?.data?.emailRiskInfo?.riskBandId == 1 || emailage.responseData?.data?.emailRiskInfo?.riskBandId == 2">
              <div class="box box-green ml-3">{{emailage.responseData?.data?.emailRiskInfo?.riskBandIdStatus}}</div>
            </div>
            <div *ngIf="emailage.responseData?.data?.emailRiskInfo?.riskBandId == 3">
              <div class="box box-orange ml-3">{{emailage.responseData?.data?.emailRiskInfo?.riskBandIdStatus}}</div>
            </div>
            <div *ngIf="emailage.responseData?.data?.emailRiskInfo?.riskBandId >=4 && emailage.responseData?.data?.emailRiskInfo?.riskBandId <= 6">
              <div class="box box-red ml-3">{{emailage.responseData?.data?.emailRiskInfo?.riskBandIdStatus}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-md-6 p-0 text-right">
        Mobile
      </div>
      <div class="col-md-6 p-0 text-left">
        <div>
          <span class="loadingImg" *ngIf="mobile.isLoading">
            <img src="{{messageService.loadingAnimation}}" />
          </span>

          <div *ngIf="!mobile.isLoading">
            <div class="col-md-12" *ngIf="mobile.noSearchSelect"><i class="color-grey float-right">{{messageService.NoSearchSelectShort}}</i></div>
            <div class="col-md-12" *ngIf="mobile.searchFail"><i class="color-grey float-right">{{messageService.SearchFail}}</i></div>
            <div class="col-md-12" *ngIf="mobile.validationError"><i class="color-grey float-right">{{messageService.MobileRequiredMessage}}</i></div>
          </div>

          <div *ngIf="!mobile.isLoading">
            <div *ngIf="mobile?.responseData?.data?.phoneScoreCheck?.risk">
              <span *ngIf="mobile?.responseData?.data?.phoneScoreCheck?.risk?.level == 'low'" class="box box-green ml-3">{{mobile?.responseData?.data?.phoneScoreCheck?.risk?.level}}</span>
              <span *ngIf="mobile?.responseData?.data?.phoneScoreCheck?.risk?.level == 'very-low'" class="box box-green ml-3">{{mobile?.responseData?.data?.phoneScoreCheck?.risk?.level}}</span>
              <span *ngIf="mobile?.responseData?.data?.phoneScoreCheck?.risk?.level == 'medium-low'" class="box box-orange ml-3">{{mobile?.responseData?.data?.phoneScoreCheck?.risk?.level}}</span>
              <span *ngIf="mobile?.responseData?.data?.phoneScoreCheck?.risk?.level == 'high'" class="box box-red ml-3">{{mobile?.responseData?.data?.phoneScoreCheck?.risk?.level}}</span>
              <span *ngIf="mobile?.responseData?.data?.phoneScoreCheck?.risk?.level == 'very-high'" class="box box-red ml-3">{{mobile?.responseData?.data?.phoneScoreCheck?.risk?.level}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
