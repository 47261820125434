<div>
  <div class="card-body">
    <span class="card-title Header-Font-Format"><b>Mobile Number Enquiry</b></span>
    <div>
      <div class="col-md-12" *ngIf="mobile.noRelevantDataMobile"><i class="color-grey float-right">{{NoRelevantDataMessageShort}}</i></div>
      <div class="col-md-12" *ngIf="!accessData.Mobile"><i class="color-grey float-right">{{NoSearchSelectShort}}</i></div>
      <div class="col-md-12" *ngIf="mobile.searchFail && accessData.Mobile"><i class="color-grey float-right">{{SearchFail}}</i></div>
      <div class="col-md-12" *ngIf="mobile.validationError && accessData.Mobile"><i class="color-grey float-right">{{mobileRequiredMessage}}</i></div>
    </div>
    <div class="row" *ngIf="accessData.Mobile && !mobile.validationError && !mobile.searchFail && !mobile.noRelevantDataMobile">
      <div class="col-md-6">
        <p>Phone Type: <span>{{mobile?.responseData?.data?.Type | titlecase}}</span> </p>
        <p>Carrier: <span>{{mobile?.responseData?.data?.Carrier}}</span> </p>
        <b>Sim Swap Details</b>
        <div *ngIf="mobile?.responseData?.data?.SimSwap">
          <p *ngIf="mobile?.responseData?.data?.SimSwap?.Status == 'red'" class="color-red"><i class="fa fa-exclamation-triangle color-red"></i>&nbsp;High risk</p>
          <p *ngIf="mobile?.responseData?.data?.SimSwap?.Status == 'amber'" class="color-amber"><i class="fa fa-exclamation-triangle color-amber"></i>&nbsp;Medium risk</p>
          <p *ngIf="mobile?.responseData?.data?.SimSwap?.Status == 'green'" class="color-green"><i class="fa fa-exclamation-triangle color-green"></i>&nbsp;Low risk</p>
          <p class="info-text-sim-swap" *ngIf="mobile?.responseData?.data?.SimSwap?.Date"><small>Swap Date: <br /><span>{{mobile?.responseData?.data?.SimSwap?.Date | date:'dd-MM-yyyy hh:mm:ss'}}</span> </small></p>
        </div>
        <div *ngIf="!mobile?.responseData?.data?.SimSwap">
          <div class="col-md-12"><i class="color-grey float-right">{{NoRelevantDataMessageShort}}</i></div>
        </div>
      </div>
      <div class="col-md-6">
        <p *ngIf="mobile?.responseData?.data?.Blocked"><i class="fa fa-ban color-red"></i> Blocked </p>
        <span><b>Name Match</b> </span>
        <p *ngIf="mobile?.responseData?.data?.MatchStatus == null"><i class="color-grey float-right">{{NoRelevantDataMessageShort}}</i></p>
        <p *ngIf="mobile?.responseData?.data?.MatchStatus">
          <i class="fa fa-check-circle color-green"></i> Matched
          <br /> <span *ngIf="mobile?.responseData?.data?.RegisteredUser">
            <small>
              Subscriber: {{mobile?.responseData?.data?.RegisteredUser | titlecase}}
            </small>
          </span>
        </p>
        <p *ngIf="!mobile?.responseData?.data?.MatchStatus && mobile?.responseData?.data?.MatchStatus != null">
          <i class="fa fa-times-circle color-red"></i>
          NOT Matched <br />
          <span *ngIf="mobile?.responseData?.data?.RegisteredUser">
            <small>
              Subscriber: {{mobile?.responseData?.data?.RegisteredUser | titlecase}}
            </small>
          </span>
        </p>

        <b>Number Deactivation Details</b>
        <div *ngIf="mobile?.responseData?.PhoneContactData?.PhoneContactJSON?.number_deactivation?.status.code == 2800">
          <p class="info-text-sim-swap" *ngIf="mobile?.responseData?.PhoneContactData?.PhoneContactJSON?.number_deactivation?.last_deactivated">Last Deactivated: <span>{{mobile?.responseData?.PhoneContactData?.PhoneContactJSON?.number_deactivation?.last_deactivated | date: 'dd-MM-yyyy' }}</span></p>
          <p class="info-text-sim-swap" *ngIf="mobile?.responseData?.PhoneContactData?.PhoneContactJSON?.number_deactivation?.tracking_since">Tracking Since: <span>{{mobile?.responseData?.PhoneContactData?.PhoneContactJSON?.number_deactivation?.tracking_since | date: 'dd-MM-yyyy'}}</span></p>
        </div>
        <div *ngIf="mobile?.responseData?.PhoneContactData?.PhoneContactJSON?.number_deactivation?.status.code != 2800 || !mobile?.responseData?.PhoneContactData?.PhoneContactJSON?.number_deactivation">
          <div class="col-md-12"><i class="color-grey float-right">{{NoRelevantDataMessageShort}}</i></div>
        </div>
      </div>
    </div>
  </div>
</div>
