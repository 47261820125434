<div class="container mb-5">
  <div class="mb-5">
    <span class="loadingImg" *ngIf="experian.isLoading">
      <img src="{{messageService.loadingAnimation}}" />
    </span>
    <div class="background-white result-display-padding form-borderdesign result-view-align" *ngIf="!experian.isLoading">
      <br />
      <ul class="nav nav-tabs" id="searchResultTab" role="tablist">
        <li class="nav-item">
          <a class="nav-link active" id="register-result-tab" data-toggle="tab" href="#registerResult" role="tab"
             aria-controls="profile" aria-selected="false"><b>Registration</b></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="specifications-result-tab" data-toggle="tab" href="#specificationsResult" role="tab"
             aria-controls="profile" aria-selected="false"><b>Specifications</b></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="finance-result-tab" data-toggle="tab" href="#financeResult" role="tab"
             aria-controls="profile" aria-selected="false"><b>Finance</b></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="condition-alert-result-tab" data-toggle="tab" href="#conditionAlertResult" role="tab"
             aria-controls="profile" aria-selected="false"><b>Condition Alert</b></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="ved-mileage-result-tab" data-toggle="tab" href="#vedMileageResult" role="tab"
             aria-controls="profile" aria-selected="false"><b>VED</b></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="theft-result-tab" data-toggle="tab" href="#theftResult" role="tab"
             aria-controls="profile" aria-selected="false"><b>Theft</b></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="previous-searches-result-tab" data-toggle="tab" href="#previousSearchesResult" role="tab"
             aria-controls="profile" aria-selected="false"><b>Previous Searches</b></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="vehicle-codes-result-tab" data-toggle="tab" href="#vehicleCodesResult" role="tab"
             aria-controls="profile" aria-selected="false"><b>Vehicle Codes</b></a>
        </li>
      </ul>

      <div class="tab-content" id="searchResultTabContent">
        <br />

        <!-- Registeration Results -->
        <div class="tab-pane fade show tab-height active" id="registerResult" role="tabpanel" aria-labelledby="register-result-tab">

          <table class="table table-striped table-hover">
            <tbody>
              <tr>
                <th scope="row">Year of Manufacture</th>
                <td>{{experian?.responseData?.vehicle?.summary?.dateofmanufacture | date:'yyyy'}}</td>
              </tr>
              <tr>
                <th scope="row">Vehicle Used Before UK Registration</th>
                <td>{{experian?.responseData?.vehicle?.status?.usedbeforefirstregistration}}</td>
              </tr>
              <tr>
                <th scope="row">UK Registration Date</th>
                <td>{{experian?.responseData?.vehicle?.summary?.dateoffirstreginuk | date:'dd-MMM-yyyy'}}</td>
              </tr>
              <tr>
                <th>Number Of Registration Certificates Issued</th>
                <td>{{experian?.responseData?.vehicle?.provenance?.v5cchanges ? experian?.responseData?.vehicle?.provenance?.v5cchanges?.length : '0'}}</td>
              </tr>
              <tr>
                <th>Date Of Registration Certificate Issue</th>
                <td>{{experian?.responseData?.vehicle?.summary?.dateoffirstregistration  | date:'dd-MMM-yyyy'}}</td>
              </tr>
              <tr>
                <th>Date Of Registration Certificate Update</th>
                <td>
                  <table class="table table-striped" *ngIf="experian?.responseData?.vehicle?.provenance?.v5cchanges?.length > 0">
                    <tbody>
                      <tr *ngFor="let v5cchange of experian?.responseData?.vehicle?.provenance?.v5cchanges">
                        <td>{{v5cchange.date | date:'dd-MMM-yyyy'}}</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <th></th>
                <td></td>
              </tr>
              <tr>
                <th>VIN Serial Number</th>
                <td>{{experian?.responseData?.vehicle?.summary?.vin}}</td>
              </tr>
              <tr>
                <th>Engine Number</th>
                <td>{{experian?.responseData?.vehicle?.technical?.engine?.enginenumber}}</td>
              </tr>
              <tr>
                <th></th>
                <td></td>
              </tr>
              <tr>
                <th>Number Of Keeper Changes</th>
                <td>{{experian?.responseData?.vehicle?.provenance?.keeperchanges?.length ? experian?.responseData?.vehicle?.provenance?.keeperchanges?.length : '0'}}</td>
              </tr>
              <tr *ngIf="experian?.responseData?.vehicle?.provenance?.keeperchanges?.length && experian?.responseData?.vehicle?.provenance?.keeperchanges?.length > 0">
                <th>Date Of Keeper Change</th>
                <td>
                  <table class="table table-striped" *ngIf="experian?.responseData?.vehicle?.provenance?.keeperchanges?.length > 0">
                    <tbody>
                      <tr *ngFor="let keeperchange of experian?.responseData?.vehicle?.provenance?.keeperchanges">
                        <td>{{keeperchange.date | date:'dd-MMM-yyyy'}}</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <th></th>
                <td></td>
              </tr>
              <tr>
                <th>Number Of Plate Changes</th>
                <td>{{experian?.responseData?.vehicle?.provenance?.platechanges?.length ? experian?.responseData?.vehicle?.provenance?.platechanges?.length : '0'}}</td>
              </tr>
              <tr *ngIf="experian?.responseData?.vehicle?.provenance?.platechanges?.length && experian?.responseData?.vehicle?.provenance?.platechanges?.length > 0">
                <th>Plate Changes Info</th>
                <td>
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <td>Previous VRM</td>
                        <td>Date of Receipt</td>
                        <td>Current VRM</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let platechange of experian?.responseData?.vehicle?.provenance?.platechanges">
                        <td>{{platechange.previousvrm}}</td>
                        <td>{{platechange.date | date:'dd-MMM-yyyy'}}</td>
                        <td>{{platechange.currentvrm}}</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <th></th>
                <td></td>
              </tr>
              <tr>
                <th>Imported</th>
                <td>{{experian?.responseData?.vehicle?.status?.imported == 'NotImported' ? 'False' : 'True'}}</td>
              </tr>
              <tr>
                <th>Exported</th>
                <td>{{experian?.responseData?.vehicle?.status?.exported == 'NotExported' ? 'False' : 'True'}}</td>
              </tr>
              <tr>
                <th>Scrapped</th>
                <td>{{experian?.responseData?.vehicle?.status?.scrapped == 'NotScrapped' ? 'False' : 'True'}}</td>
              </tr>
              <tr>
                <th>Date Exported</th>
                <td>{{experian?.responseData?.vehicle?.summary?.dateexported | date:'dd-MMM-yyyy'}}</td>
              </tr>
              <tr>
                <th>Date Scrapped</th>
                <td>{{experian?.responseData?.vehicle?.summary?.datescrapped | date:'dd-MMM-yyyy'}}</td>
              </tr>
            </tbody>
          </table>

        </div>

        <!-- Specifications Results -->
        <div class="tab-pane fade show tab-height" id="specificationsResult" role="tabpanel" aria-labelledby="specifications-result-tab">

          <table class="table table-striped table-hover">
            <tbody>
              <tr>
                <th>Make</th>
                <td>{{experian?.responseData?.vehicle?.summary?.make}}</td>
              </tr>
              <tr>
                <th>Model</th>
                <td>{{experian?.responseData?.vehicle?.summary?.model}}</td>
              </tr>
              <tr>
                <th>Fuel</th>
                <td>{{experian?.responseData?.vehicle?.summary?.fuel}}</td>
              </tr>
              <tr>
                <th>Colour</th>
                <td>{{experian?.responseData?.vehicle?.provenance?.colour?.currrentcolour}}</td>
              </tr>
              <tr>
                <th>Number Of Colour Changes</th>
                <td>{{experian?.responseData?.vehicle?.provenance?.colour?.changes?.length ? experian?.responseData?.vehicle?.provenance?.colour?.changes?.length : '0'}}</td>
              </tr>
              <tr *ngIf="experian?.responseData?.vehicle?.provenance?.colour?.changes?.length && experian?.responseData?.vehicle?.provenance?.colour?.changes?.length > 0">
                <th>Colour Change Info</th>
                <td>
                  <table class="table table-striped" *ngIf="experian?.responseData?.vehicle?.provenance?.colour?.changes?.length > 0">
                    <thead>
                      <tr>
                        <th>Last Colour</th>
                        <th>Date Of Last Colour Change</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let colorChange of experian?.responseData?.vehicle?.provenance?.colour?.changes">
                        <td>{{colorChange?.lastcolour}}</td>
                        <td>{{colorChange?.date | date:'dd-MMM-yyyy'}}</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <th>Country Of Origin</th>
                <td>{{experian?.responseData?.vehicle?.industry?.smmt?.additional?.countryoforigin}}</td>
              </tr>
              <tr>
                <th>Transmission</th>
                <td>{{experian?.responseData?.vehicle?.technical?.engine?.transmission}}</td>
              </tr>
              <tr>
                <th>Seating Capacity</th>
                <td>{{experian?.responseData?.vehicle?.technical?.body?.seatingcapacityincdriver}}</td>
              </tr>
              <tr>
                <th>Maximum Permissable Mass</th>
                <td>{{experian?.responseData?.vehicle?.technical?.body?.maxpermissiblemass}}</td>
              </tr>
              <tr>
                <th>Engine Make</th>
                <td>{{experian?.responseData?.vehicle?.industry?.smmt?.engine?.enginemake}}</td>
              </tr>
              <tr>
                <th>Engine Capacity</th>
                <td>{{experian?.responseData?.vehicle?.technical?.engine?.enginecapacity}}</td>
              </tr>
              <tr>
                <th>Engine Description</th>
                <td>{{experian?.responseData?.vehicle?.industry?.smmt?.engine?.enginedescription}}</td>
              </tr>
              <tr>
                <th>Engine Location</th>
                <td>{{experian?.responseData?.vehicle?.industry?.smmt?.engine?.enginelocation}}</td>
              </tr>
              <tr>
                <th>Number Of Gears </th>
                <td>{{experian?.responseData?.vehicle?.technical?.engine?.numberofgears}}</td>
              </tr>
              <tr>
                <th>Number Of Doors And Body Type</th>
                <td>{{experian?.responseData?.vehicle?.summary?.bodytype}}</td>
              </tr>
              <tr>
                <th>Wheel Plan</th>
                <td>{{experian?.responseData?.vehicle?.technical?.body?.wheelplan}}</td>
              </tr>
              <tr>
                <th>Drive Type</th>
                <td>{{experian?.responseData?.vehicle?.industry?.smmt?.additional?.drivetype}}</td>
              </tr>
              <tr>
                <th>Brake Horsepower</th>
                <td>{{experian?.responseData?.vehicle?.industry?.smmt?.fueleconomy?.powerbhp}}</td>
              </tr>
              <tr>
                <th>Power kW</th>
                <td>{{experian?.responseData?.vehicle?.industry?.smmt?.fueleconomy?.powerkw}}</td>
              </tr>
              <tr>
                <th>RPM</th>
                <td>{{experian?.responseData?.vehicle?.industry?.smmt?.fueleconomy?.powerrpm}}</td>
              </tr>
              <tr>
                <th>Torque ft</th>
                <td>{{experian?.responseData?.vehicle?.industry?.smmt?.fueleconomy?.torquefoot}}</td>
              </tr>
              <tr>
                <th>Torque NM</th>
                <td>{{experian?.responseData?.vehicle?.industry?.smmt?.fueleconomy?.torquenm}}</td>
              </tr>
              <tr>
                <th>Torque RPM</th>
                <td>{{experian?.responseData?.vehicle?.industry?.smmt?.fueleconomy?.torquerpm}}</td>
              </tr>
              <tr>
                <th>Maximum Speed MPH</th>
                <td>{{experian?.responseData?.vehicle?.industry?.smmt?.fueleconomy?.maxspeedmph}}</td>
              </tr>
              <tr>
                <th>Maximum Speed KPH</th>
                <td>{{experian?.responseData?.vehicle?.industry?.smmt?.fueleconomy?.maxspeedkph}}</td>
              </tr>
              <tr>
                <th>Drive Axle</th>
                <td>{{experian?.responseData?.vehicle?.industry?.smmt?.engine?.drivingaxle}}</td>
              </tr>
              <tr>
                <th>Number Of Axles</th>
                <td>{{experian?.responseData?.vehicle?.industry?.smmt?.dimensions?.numberofaxles}}</td>
              </tr>
              <tr>
                <th>Power Delivery</th>
                <td>{{experian?.responseData?.vehicle?.industry?.smmt?.engine?.powerdelivery}}</td>
              </tr>
              <tr>
                <th>European Engine Standard </th>
                <td>{{experian?.responseData?.vehicle?.industry?.smmt?.engine?.eurostatus}}</td>
              </tr>
              <tr>
                <th>Bore (mm)</th>
                <td>{{experian?.responseData?.vehicle?.industry?.smmt?.engine?.bore}}</td>
              </tr>
              <tr>
                <th>Stroke (mm)</th>
                <td>{{experian?.responseData?.vehicle?.industry?.smmt?.engine?.stroke}}</td>
              </tr>
              <tr>
                <th>Aspiration </th>
                <td>{{experian?.responseData?.vehicle?.industry?.smmt?.engine?.aspiration}}</td>
              </tr>
              <tr>
                <th>Cylinder Arrangement</th>
                <td>{{experian?.responseData?.vehicle?.industry?.smmt?.engine?.cylinderarrangement}}</td>
              </tr>
              <tr>
                <th>Number Of Cylinders</th>
                <td>{{experian?.responseData?.vehicle?.industry?.smmt?.engine?.numberofcylinders}}</td>
              </tr>
              <tr>
                <th>Valves Per Cylinder</th>
                <td>{{experian?.responseData?.vehicle?.industry?.smmt?.engine?.valvespercylinder}}</td>
              </tr>
              <tr>
                <th>Valve Gear</th>
                <td>{{experian?.responseData?.vehicle?.industry?.smmt?.engine?.valvegear}}</td>
              </tr>
              <tr>
                <th>Height</th>
                <td>{{experian?.responseData?.vehicle?.industry?.smmt?.dimensions?.height}}</td>
              </tr>
              <tr>
                <th>Length</th>
                <td>{{experian?.responseData?.vehicle?.industry?.smmt?.dimensions?.carlength}}</td>
              </tr>
              <tr>
                <th>Width</th>
                <td>{{experian?.responseData?.vehicle?.industry?.smmt?.dimensions?.width}}</td>
              </tr>
              <tr>
                <th>Kerb Weight</th>
                <td>{{experian?.responseData?.vehicle?.industry?.smmt?.dimensions?.kerbweight}}</td>
              </tr>
              <tr>
                <th>Rigid or Articulated</th>
                <td>{{experian?.responseData?.vehicle?.industry?.smmt?.dimensions?.rigidartic}}</td>
              </tr>
              <tr>
                <th>Wheelbase</th>
                <td>{{experian?.responseData?.vehicle?.industry?.smmt?.dimensions?.wheelbase}}</td>
              </tr>
              <tr>
                <th>Gross Vehicle Weight</th>
                <td>{{experian?.responseData?.vehicle?.technical?.body?.grossweight}}</td>
              </tr>
            </tbody>
          </table>

        </div>

        <!-- Finance Results -->
        <div class="tab-pane fade show tab-height" id="financeResult" role="tabpanel" aria-labelledby="finance-result-tab">

          <table class="w-100">
            <tbody>
              <tr>
                <th>Number Of Finance Agreements</th>
                <td>{{experian?.responseData?.vehicle?.provenance?.finance?.length ? experian?.responseData?.vehicle?.provenance?.finance?.length : 0}}</td>
              </tr>
              <tr>
                <td colspan="2">
                  <table class="table table-striped table-hover" *ngIf="experian?.responseData?.vehicle?.provenance?.finance?.length > 0">
                    <thead>
                      <tr>
                        <th>Finance Company</th>
                        <th>Finance Reference Number</th>
                        <th>Agreement Type</th>
                        <th>Agreement Term</th>
                        <th>Start Date Of Agreement</th>
                        <th>Finance Company Contact Number</th>
                        <th>Finance Company's Description Of Asset</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let finance of experian?.responseData?.vehicle?.provenance?.finance">
                        <td>{{finance?.financecompany}}</td>
                        <td>{{finance?.agreementnumber}}</td>
                        <td>{{finance?.agreementtype}}</td>
                        <td>{{finance?.agreementterminmonths}}</td>
                        <td>{{finance?.dateofagreement | date:'dd-MMM-yyyy'}}</td>
                        <td>{{finance?.contactnumber}}</td>
                        <td>{{finance?.description}}</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>

        </div>

        <!-- Condition Results -->
        <div class="tab-pane fade show tab-height" id="conditionAlertResult" role="tabpanel" aria-labelledby="condition-alert-result-tab">

          <table class="w-100">
            <tbody>
              <tr>
                <th>Number Of Condition Alerts</th>
                <td>{{experian?.responseData?.vehicle?.provenance?.condition?.length ? experian?.responseData?.vehicle?.provenance?.condition?.length : '0'}}</td>
              </tr>
              <tr>
                <td colspan="2">
                  <table class="table table-striped table-hover" *ngIf="experian?.responseData?.vehicle?.provenance?.condition?.length > 0">
                    <thead>
                      <tr>
                        <th>Loss Date</th>
                        <th>Category</th>
                        <th>Theft Indicator</th>
                        <th>Recorded Insurer on MIAFTR</th>
                        <th>Vehicle Make Provided By Insurer</th>
                        <th>Vehicle Model Provided By Insurer</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let condition of experian?.responseData?.vehicle?.provenance?.condition">
                        <td>{{condition?.lossdate | date:'dd-MMM-yyyy'}}</td>
                        <td>{{condition?.status}}</td>
                        <td>{{condition?.theftindicator}}</td>
                        <td>{{condition?.insurername}}</td>
                        <td>{{condition?.make}}</td>
                        <td>{{condition?.model}}</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>

        </div>

        <!-- VED & Mileage Results -->
        <div class="tab-pane fade show tab-height" id="vedMileageResult" role="tabpanel" aria-labelledby="ved-mileage-result-tab">

          <table class="table table-striped">
            <tbody>
              <tr>
                <th>CO2 Band</th>
                <td>{{experian?.responseData?.vehicle?.technical?.emissions?.co2band}}</td>
              </tr>
              <tr>
                <th>CO2 Emissions</th>
                <td>{{experian?.responseData?.vehicle?.technical?.emissions?.co2}}</td>
              </tr>
              <tr>
                <th>VED Band</th>
                <td>{{experian?.responseData?.vehicle?.technical?.emissions?.ved?.band}}</td>
              </tr>
              <tr>
                <th>VED Annual Cost</th>
                <td>{{experian?.responseData?.vehicle?.technical?.emissions?.ved?.cost12months}}</td>
              </tr>
              <!--<tr>
                <th>Number Of Mileage Records</th>
                <td>{{experian?.responseData?.vehicle?.provenance?.mileage?.length ? experian?.responseData?.vehicle?.provenance?.mileage?.length : '0'}}</td>
              </tr>
              <tr *ngIf="experian?.responseData?.vehicle?.provenance?.mileage?.length && experian?.responseData?.vehicle?.provenance?.mileage?.length > 0">
                <th>Mileage Info</th>
                <td>
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th>Mileage</th>
                        <th>Source Of Mileage</th>
                        <th>Mileage Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let mileage of experian?.responseData?.vehicle?.provenance?.mileage">
                        <td>{{mileage?.mileage}}</td>
                        <td>{{mileage?.source }}</td>
                        <td>{{mileage?.date | date:'dd-MMM-yyyy'}}</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>-->
            </tbody>
          </table>

        </div>

        <!-- Theft Results -->
        <div class="tab-pane fade show tab-height" id="theftResult" role="tabpanel" aria-labelledby="theft-result-tab">

          <table class="w-100">
            <tbody>
              <tr>
                <th>Number Of Stolen Records</th>
                <td>{{experian?.responseData?.vehicle?.provenance?.stolen?.length ? experian?.responseData?.vehicle?.provenance?.stolen?.length : '0'}}</td>
              </tr>
              <tr>
                <td colspan="2">
                  <table class="table table-striped table-hover" *ngIf="experian?.responseData?.vehicle?.provenance?.stolen?.length > 0">
                    <thead>
                      <tr>
                        <th>Theft Date</th>
                        <th>Police Force</th>
                        <th>Police Force Contact Number</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let condition of experian?.responseData?.vehicle?.provenance?.stolen">
                        <td>{{condition?.creationdate | date:'dd-MMM-yyyy'}}</td>
                        <td>{{condition?.forcename}}</td>
                        <td>{{condition?.contactnumber}}</td>
                        <td>{{condition?.status}}</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>

        </div>

        <!-- Previous Searches Results -->
        <div class="tab-pane fade show tab-height" id="previousSearchesResult" role="tabpanel" aria-labelledby="previous-searches-result-tab">

          <table class="table table-striped">
            <tbody>
              <tr>
                <th>Number of Previous Searches</th>
                <td>{{experian?.responseData?.vehicle?.previoussearches?.length ? experian?.responseData?.vehicle?.previoussearches?.length : '0'}}</td>
              </tr>
              <tr>
                <td colspan="2">
                  <table class="table table-striped table-hover" *ngIf="experian?.responseData?.vehicle?.previoussearches?.length > 0">
                    <thead>
                      <tr>
                        <th>Date of Previous Search </th>
                        <th>Type Of Business Undertaking Search</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let previousSearch of experian?.responseData?.vehicle?.previoussearches">
                        <td>{{previousSearch?.date | date:'dd-MMM-yyyy'}}</td>
                        <td>{{previousSearch?.typeofbusiness}}</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>

        </div>

        <!-- Vehicle Codes Results -->
        <div class="tab-pane fade show tab-height" id="vehicleCodesResult" role="tabpanel" aria-labelledby="vehicle-codes-result-tab">

          <table class="table table-striped">
            <tbody>
              <tr>
                <th>DVLA Vehicle Model And Trim Code</th>
                <td>{{experian?.responseData?.vehicle?.industry?.smmt?.makemodel?.dvladtpmodelcode}}</td>
              </tr>
              <tr>
                <th>DVLA Vehicle Make Code</th>
                <td>{{experian?.responseData?.vehicle?.industry?.smmt?.makemodel?.dvladtpmakecode}}</td>
              </tr>
              <tr>
                <th>Motor Vehicle Registration Information System Model Code</th>
                <td>{{experian?.responseData?.vehicle?.industry?.smmt?.makemodel?.smmtmvrismodelcode}}</td>
              </tr>
              <tr>
                <th>Motor Vehicle Registration Information System Make Code</th>
                <td>{{experian?.responseData?.vehicle?.industry?.smmt?.makemodel?.smmtmvrismakecode}}</td>
              </tr>
              <tr>
                <th>Transmission Code</th>
                <td>{{experian?.responseData?.vehicle?.technical?.engine?.transmissioncode}}</td>
              </tr>
              <tr>
                <th>Body Code</th>
                <td>{{experian?.responseData?.vehicle?.technical?.body?.bodycode}}</td>
              </tr>
              <tr>
                <th>Fuel Code</th>
                <td>{{experian?.responseData?.vehicle?.technical?.engine?.fuelcode}}</td>
              </tr>
              <tr>
                <th>Colour Code </th>
                <td>{{experian?.responseData?.vehicle?.summary?.colourcode}}</td>
              </tr>
            </tbody>
          </table>

        </div>

      </div>
    </div>
  </div>
</div>
