export class ContactData {
  reference: string;
  phoneNumber: string;
  fullName: string;
  firstName: string;
  midleName: string;
  lastName: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  postalCode: string;
  state: string;
  country: string;
  dateOfBirth: string;
  companyName: string;
  vRN: string;
  email: string;
  referenceNumber: string;
  associatedNames: any[];
  auditKey: string;
}
