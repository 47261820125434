<app-search-top-nav></app-search-top-nav>
<section id="personal-search" class="search">
  <div class="container">
    <div class="logo-div d-flex justify-content-center">
      <img class="logo" src="../../../../../assets/Images/new/HUT-icon.v3-doc-validation-RO.png" />
    </div>
    <form role="form" [formGroup]="documentValidationForm">
      <div class="form-group row form-top-margin">
        <div class="col-md-8 offset-md-2 form-data-background form-borderdesign  shadow-lg p-3">
          <div class="row padding-form-design">
            <div class="col-md-12 z-index-formdata">
              <div class="row">
                <div class="input-group">
                  <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                    <b>Reference:</b>
                  </div>
                  <div class="col-md-8 pt-3">
                    <div class="form-group">
                      <input type="text" placeholder="" class="form-control" formControlName="reference" autocomplete="off" />
                      <p class="text-danger" *ngIf="documentValidationForm.get('reference').errors?.required">
                        <strong><small>Reference is required</small></strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="row">
                <div class="offset-md-3 col-md-8">
                  <div class="browse-File select-file">
                    <input type="file" class="custom-file-input" id="customFile" accept="image/*" multiple (change)="onFileSelected($event)" />
                    <label class="custom-file-label" for="customFile">
                      <span *ngIf="FileNames.length == 0 || FileNames.length > 2">Choose File</span>
                      <span *ngIf="FileNames.length == 1">{{FileNames[0]}}</span>
                      <span *ngIf="FileNames.length == 2">{{FileNames[0]}}, {{FileNames[1]}}</span>
                    </label>
                  </div>
                  <div *ngIf="isFileValidation">
                    <p class="text-danger" *ngIf="FileNames.length == 0">
                      <strong><small>Please choose at least 1 file.</small></strong>
                    </p>
                    <p class="text-danger" *ngIf="FileNames.length > 2">
                      <strong><small>Please choose maximum of 2 files.</small></strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 padding-form-design pb-2">
              <div class="row">
                <div class="input-group">
                  <div class="offset-md-3 col-md-12">
                    <div class="form-group">
                      <button type="submit" class="btn colorRed mx-2" (click)="documentValidationSearch()">Upload <i class="fa fa-upload" aria-hidden="true"></i></button>
                      <button type="button" class="btn colorRed btn-pdf mx-2"
                              *ngIf="saveAsPDFButtonVisibility"
                              (click)="generatePDF()"
                              [disabled]="isLoading">
                        Save as PDF
                      </button>
                      <button class="btn colorRed reset-btn mx-2" (click)="documentValidationClearSearch()" [disabled]="isLoading">Clear</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>

    <div class="container row mb-5" *ngIf="showResultTab">
      <div class="col-md-8 offset-md-2 mb-5">
        <div class="background-white result-display-padding form-borderdesign result-view-align bg-light px-4 py-2" style="border: 1px solid rgba(0,0,0,.125);">
          <div class="row">
            <div class="col-md-6">
              <div><span class="h5">Result: </span></div>
              <div *ngIf="isLoading">
                <img src="{{messageService.loadingAnimation}}" />
              </div>
              <div *ngIf="USIdCheckUploadResult?.status == 200 && !isLoading">
                <p *ngIf="USIdCheckUploadResult?.data">Document uploaded <i class="fa fa-check-circle color-green"></i></p>
                <p *ngIf="!USIdCheckUploadResult?.data">Document uploaded <i class="fa fa-ban color-red"></i></p>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="USIdCheckUploadResult?.status == 200">
            <div class="col-md-6">
              <div *ngIf="fileOneUrl">
                <div>{{File[0].name}}</div>
                <img class="document-image" [src]="fileOneUrl" />
              </div>
            </div>
            <div class="col-md-6">
              <div *ngIf="fileTwoUrl">
                <div>{{File[1].name}}</div>
                <img class="document-image" [src]="fileTwoUrl" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</section>

<!-- PDF -->
<div style="position: absolute; top: 0; right: 120%;">
  <kendo-pdf-export #pdf>
    <app-export-pdf-document-validation [requestData]="requestDataModel"
                          [fileOneUrl]="fileOneUrl"
                          [fileTwoUrl]="fileTwoUrl"
                          [USIdCheckUploadResult]="USIdCheckUploadResult"
                          [FileNames]="FileNames"
                          [loggedInUsername]="loggedInUsername"
                          [File]="File"></app-export-pdf-document-validation>
  </kendo-pdf-export>
</div>
