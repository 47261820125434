import { Component, OnInit, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../../service/login.service';
import { ResetPassword } from '../../models/resetpassword';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.css']
})
export class ResetPasswordComponent implements OnInit {
  public resetPassword: ResetPassword = new ResetPassword("", "", "", "");
  public validationerror: string;
  public loading: boolean;

  constructor(
    private loginService: LoginService,
    private router: Router,
    private route: ActivatedRoute,
    private elementRef: ElementRef) { }
    
  ngAfterViewInit() {
    this.elementRef.nativeElement.ownerDocument.body.style.background = 'url(/assets/Images/LoginBackground.svg)';
  }

  ngOnInit() {
    //this.resetPassword.email = this.route.snapshot.queryParams.email;
    //this.resetPassword.token = this.route.snapshot.queryParams.token;

    this.resetPassword.email = this.route.snapshot.queryParamMap.get("email");
    this.resetPassword.token = this.route.snapshot.queryParamMap.get("token");
  }

  public ResetPassword() {
    this.loginService.resetpassword(this.resetPassword)
      .subscribe(
        data => {
          this.router.navigate(["/Thankyou"], { queryParams: { status: 'resetPassword' } });
          this.loading = false;
        },
        error => {
          console.log(error);
          if (error.status == 400) {
            if (JSON.parse(error._body)["ConfirmPassword"][0] == "Password Mismatch") {
              this.loading = false;
              this.validationerror = "Password and Confirm Password must match!";
              setTimeout(() => {
                this.validationerror = "";
              }, 5000);
            }
          }
          else if (error.status == 401) {
            this.loading = false;
            this.validationerror = JSON.parse(error._body).message;
            setTimeout(() => {
              this.validationerror = "";
            }, 8000);
          }
          else if (error.status == 405) {
            this.loading = false;
            this.validationerror = "Your password reset link is not valid, or already used. You can reset password using forgot password link on the login page.";
            setTimeout(() => {
              this.validationerror = "";
            }, 12000);
          }
          else {
            this.loading = false;
            this.validationerror = "Internal Server Error";
            setTimeout(() => {
              this.validationerror = "";
            }, 5000);
          }
        });
  }
}

