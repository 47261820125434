<section id="motor-search" class="search">
  <div class="container">
    <div class="logo-div d-flex justify-content-center">
      <img class="logo" src="../../../../../assets/Images/new/HUT-icon.v3-motor-RO.png" />
    </div>
    <form role="form" [formGroup]="motorForm">
      <div class="form-group row form-top-margin">
        <div class="offset-md-2 col-md-8 form-data-background form-borderdesign shadow-lg p-3">
          <div class="row padding-form-design">
            <div class="col-md-12 z-index-formdata">
              <div class="row">
                <div class="input-group">
                  <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                    <b>Reference:</b>
                  </div>
                  <div class="col-md-8 pt-3">
                    <div class="form-group">
                      <input type="text" placeholder="" class="form-control" formControlName="reference" autocomplete="off" />
                      <p class="text-danger" *ngIf="motorForm.get('reference').errors?.required">
                        <strong><small>Reference is required</small></strong>
                      </p>
                      <p class="text-danger" *ngIf="motorForm.get('reference').errors?.minlength">
                        <strong><small>Reference cannot be less than {{motorForm.get('reference').errors?.minlength?.requiredLength}} characters.</small></strong>
                      </p>
                      <p class="text-danger" *ngIf="motorForm.get('reference').errors?.maxlength">
                        <strong><small>Reference cannot be longer than {{motorForm.get('reference').errors?.maxlength?.requiredLength}} characters.</small></strong>
                      </p>
                      <p class="text-danger" *ngIf="motorForm.get('reference').errors?.noSpaceAllowed">
                        <strong><small>White space not allowed.</small></strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 z-index-formdata">
              <div class="row">
                <div class="input-group">
                  <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                    <b>Plate:</b>
                  </div>
                  <div class="col-md-8 pt-3">
                    <div class="form-group">
                      <input type="text" placeholder="" class="form-control" formControlName="plate" autocomplete="off" />
                      <p class="text-danger" *ngIf="motorForm.get('plate').errors?.required">
                        <strong><small>Plate is required</small></strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 z-index-formdata">
              <div class="row">
                <div class="input-group">
                  <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                    <b>State:</b>
                  </div>
                  <div class="col-md-8 pt-3">
                    <div class="form-group">
                      <p-dropdown [options]="dropdownStates" formControlName="state" optionLabel="label" optionValue="value"></p-dropdown>
                      <p class="text-danger" *ngIf="motorForm.get('state').errors?.required">
                        <strong><small>State is required</small></strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 padding-form-design pb-2">
              <div class="row">
                <div class="input-group">
                  <div class="col-md-12 offset-md-3">
                    <div class="form-group">
                      <button type="submit" class="btn colorRed mx-2" (click)="motorSearch()">Search <i class="fa fa-search" aria-hidden="true"></i></button>
                      <button type="button" class="btn colorRed btn-pdf mx-2"
                              *ngIf="saveAsPDFButtonVisibility">
                        Save as PDF
                      </button>
                      <button class="btn colorRed reset-btn mx-2"
                              *ngIf="clearSearchButtonVisibility">
                        Clear Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>

    <div class="container mb-5" *ngIf="showResultTab">
      <div class="mb-5">
        <div class="background-white result-display-padding form-borderdesign result-view-align" style="border: 1px solid rgba(0,0,0,.125);">
          <ul class="nav nav-tabs" id="searchResultTab" role="tablist">
            <li class="nav-item">
              <a class="nav-link active" id="risk-summary-tab" data-toggle="tab" href="#riskSummary" role="tab"
                 aria-controls="profile" aria-selected="false"><b>Risk Summary</b></a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="vehicle-description-tab" data-toggle="tab" href="#vehicleDescription" role="tab"
                 aria-controls="profile" aria-selected="false"><b>Vehicle Description</b></a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="wovr-stolen-tab" data-toggle="tab" href="#wovrStolen" role="tab"
                 aria-controls="profile" aria-selected="false"><b>WOVR &amp; Stolen</b></a>
            </li>
          </ul>

          <div class="tab-content" id="searchResultTabContent">
            <!---------------------- Category Search table for Main results --------------->
            <!-- Risk Summary -->
            <div class="tab-pane fade show tab-height active" id="riskSummary" role="tabpanel" aria-labelledby="risk-summary-tab">
              <app-au-risk-summary [vehicleId]="vehicleIdResultModel"></app-au-risk-summary>
            </div>

            <!-- Vehicle Description -->
            <div class="tab-pane fade show tab-height" id="vehicleDescription" role="tabpanel" aria-labelledby="vehicle-description-tab">
              <app-au-vehicle-description [motorWeb]="motorWebResultModel" [requestData]="requestDataModel"></app-au-vehicle-description>
            </div>

            <!-- MOVR & Stolen -->
            <div class="tab-pane fade show tab-height" id="wovrStolen" role="tabpanel" aria-labelledby="wovr-stolen-tab">
              <app-au-wovr-stolen [vehicleId]="vehicleIdResultModel"></app-au-wovr-stolen>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="pt-1"></div>
  </div>
</section>
