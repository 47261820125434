<div class="card-body">
  <span class="card-title Header-Font-Format"><b>RTW Check</b></span>
  <div *ngIf="!rtw.isLoading">
    <div class="col-md-12" *ngIf="!rtw?.access?.isRTWAccess"><i class="color-grey float-right">{{messageService.NoSearchSelectShort}}</i></div>
    <div class="col-md-12" *ngIf="rtw?.access?.isRTWAccess && rtw.validation"><i class="color-grey float-right">{{messageService.OtherFieldRequired}}</i></div>
    <div class="col-md-12" *ngIf="rtw?.access?.isRTWAccess && rtw.searchFail"><i class="color-grey float-right">{{messageService.SearchFail}}</i></div>
  </div>
  <span class="loadingImg" *ngIf="rtw.isLoading && rtw?.access.rtw">
    <img src="{{messageService.loadingAnimation}}" />
  </span>
  <div *ngIf="!rtw.isLoading && !rtw.searchFail && rtw.responseData">
    <p *ngIf="rtw.responseData?.data"><i class="fa fa-check-circle color-green"></i> Checked</p>
    <p *ngIf="!rtw.responseData?.data"><i class="fa fa-ban color-red"></i> Not Checked</p>
  </div>
</div>
