import { Component, Input, OnInit } from '@angular/core';
import { AccessData } from '../../../home/models/AccessData';
import { AVAResultData } from '../../../home/models/AVAModel/AVAResultData';
import { AvaResponseModel } from '../../models/AvaResponseModel';
import { InputAccessData } from '../../models/InputDataModel';

@Component({
  selector: 'app-ava-check',
  templateUrl: './ava-check.component.html',
  styleUrls: ['./ava-check.component.css']
})
export class AvaCheckComponent implements OnInit {

  @Input() public avaResult: AvaResponseModel = new AvaResponseModel();
  @Input() public accessData: InputAccessData = new InputAccessData();

  // Constant Message
  public NoRelevantDataMessage = "No relevant data found.";
  public NoSearchSelect = "Search option not selected.";
  public SearchFail = "Search failed.";

  constructor() { }

  ngOnInit(): void {
  }

}
