import { Component, Input, OnInit } from '@angular/core';
import { MotorWebResultModel } from '../../../../../models/MotorWebResultModel';
import { MessagingService } from '../../../../../../services/messaging.service';
import { RequestData } from '../../../../../models/SearchRequestModel';

@Component({
  selector: 'app-au-vehicle-description',
  templateUrl: './au-vehicle-description.component.html',
  styleUrls: ['./au-vehicle-description.component.css']
})
export class AuVehicleDescriptionComponent implements OnInit {

  @Input() motorWeb: MotorWebResultModel = new MotorWebResultModel();
  @Input() requestData: RequestData = new RequestData();

  constructor(private messageService: MessagingService) { }

  ngOnInit(): void {
  }

}
