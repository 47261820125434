export class DVLAResultModel {
  public isLoading: boolean;
  public responseData: any;
  public noRelevantData: boolean;
  public searchFail: boolean;
}

export class MotHistoryResultModel {
  public isLoading: boolean;
  public responseData: any;
  public noRelevantData: boolean;
  public searchFail: boolean;
}
