import { Component, Input, OnInit } from '@angular/core';
import { FinancialResultModel } from '../../../../../../search-pages/models/FinancialResultModel';
import { MessagingService } from '../../../../../../services/messaging.service';

@Component({
  selector: 'app-financial-result-personal',
  templateUrl: './financial-result.component.html',
  styleUrls: ['./financial-result.component.css', '../../../../shared/shared.searchpage.css']
})
export class FinancialResultPersonalComponent implements OnInit {

  @Input() financial: FinancialResultModel = new FinancialResultModel();

  constructor(private messageService: MessagingService) { }

  ngOnInit(): void {
  }

}
