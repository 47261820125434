<div class="pb-4">
  <!--<div>
    <div class="col-md-12" *ngIf="!emailage.isLoading && emailage.validation && !emailage.noSearchSelect"><i class="color-grey float-right">{{messageService.NoSearchSelect}}</i></div>
  </div>-->
  <div class="loadingImg" *ngIf="emailage.isLoading">
    <img src="{{messageService.loadingAnimation}}" />
  </div>

  <div *ngIf="!emailage.isLoading && !emailage.validation && !emailage.noSearchSelect && !emailage.searchFail">

    <div class="keep-together">
      <div><b><u>Domain Risk Information:</u></b></div>
      <div>
        <div class="col-md-12" *ngIf="emailage.searchFail && !emailage.noSearchSelect"><i class="color-grey float-right">{{messageService.SearchFail}}</i></div>
        <div class="col-md-12" *ngIf="!emailage.responseData?.data?.domainRiskInfo && !emailage.noSearchSelect"><i class="color-grey float-right">{{messageService.NoRelevantDataMessage}}</i></div>
      </div>

      <div *ngIf="emailage.responseData?.data?.domainRiskInfo && !emailage.searchFail">
        <div class="row" *ngIf="emailage.responseData?.data?.domainRiskInfo?.domain">
          <div class="col-md-4">Domain:</div>
          <div class="col-md-8">{{emailage.responseData?.data.domainRiskInfo.domain}}</div>
        </div>
        <div class="row" *ngIf="emailage.responseData?.data?.domainRiskInfo?.existence">
          <div class="col-md-4">Existence:</div>
          <div class="col-md-8">{{emailage.responseData?.data.domainRiskInfo.existence}}</div>
        </div>
        <div class="row" *ngIf="emailage.responseData?.data?.domainRiskInfo?.category">
          <div class="col-md-4">Category:</div>
          <div class="col-md-8">{{emailage.responseData?.data.domainRiskInfo.category}}</div>
        </div>
        <div class="row" *ngIf="emailage.responseData?.data?.domainRiskInfo?.company">
          <div class="col-md-4">Company:</div>
          <div class="col-md-8">{{emailage.responseData?.data.domainRiskInfo.company}}</div>
        </div>
        <div class="row" *ngIf="emailage.responseData?.data?.domainRiskInfo?.corporate">
          <div class="col-md-4">Corporate:</div>
          <div class="col-md-8">{{emailage.responseData?.data.domainRiskInfo.corporate}}</div>
        </div>
        <div class="row" *ngIf="emailage.responseData?.data?.domainRiskInfo?.country">
          <div class="col-md-4">Country:</div>
          <div class="col-md-8">{{emailage.responseData?.data.domainRiskInfo.country}}</div>
        </div>
        <div class="row" *ngIf="emailage.responseData?.data?.domainRiskInfo?.info">
          <div class="col-md-4">Info:</div>
          <div class="col-md-8">{{emailage.responseData?.data.domainRiskInfo.info}}</div>
        </div>
        <div class="row" *ngIf="emailage.responseData?.data?.domainRiskInfo?.countryRisk">
          <div class="col-md-4">Country Risk:</div>
          <div class="col-md-8">{{emailage.responseData?.data.domainRiskInfo.countryRisk}}</div>
        </div>
        <div class="row" *ngIf="emailage.responseData?.data?.domainRiskInfo?.domainAge">
          <div class="col-md-4">Age:</div>
          <div class="col-md-8">{{emailage.responseData?.data.domainRiskInfo.domainAge | date:'dd-MM-yyyy'}}</div>
        </div>
        <div class="row" *ngIf="emailage.responseData?.data?.domainRiskInfo?.riskLevel">
          <div class="col-md-4">Risk Level:</div>
          <div class="col-md-8">{{emailage.responseData?.data.domainRiskInfo.riskLevel}}</div>
        </div>
      </div>
    </div>

    <br />

    <div class="keep-together">
      <div><b><u>Email Risk Information:</u></b></div>
      <div>
        <div class="col-md-12" *ngIf="emailage.searchFail && !emailage.noSearchSelect"><i class="color-grey float-right">{{messageService.SearchFail}}</i></div>
        <div class="col-md-12" *ngIf="!emailage.responseData?.data?.emailRiskInfo && !emailage.noSearchSelect"><i class="color-grey float-right">{{messageService.NoRelevantDataMessage}}</i></div>
      </div>
      <div *ngIf="emailage.responseData?.data?.emailRiskInfo && !emailage.searchFail">
        <div class="row" *ngIf="emailage.responseData?.data.emailRiskInfo?.exist">
          <div class="col-md-4">Exists:</div>
          <div class="col-md-8">{{emailage.responseData?.data.emailRiskInfo.exist}}</div>
        </div>
        <div class="row" *ngIf="emailage.responseData?.data?.emailRiskInfo?.firstVerification">
          <div class="col-md-4">First Seen:</div>
          <div class="col-md-8">{{emailage.responseData?.data.emailRiskInfo.firstVerification | date:'dd-MM-YYYY'}}</div>
        </div>
        <div class="row" *ngIf="emailage.responseData?.data?.emailRiskInfo?.riskScore">
          <div class="col-md-4">Risk Score (0 - 999):</div>
          <div class="col-md-8">{{emailage.responseData?.data.emailRiskInfo.riskScore}}</div>
        </div>
        <div class="row" *ngIf="emailage.responseData?.data?.emailRiskInfo?.reason">
          <div class="col-md-4">Reason:</div>
          <div class="col-md-8">{{emailage.responseData?.data.emailRiskInfo.reason}}</div>
        </div>
        <div class="row" *ngIf="emailage.responseData?.data?.emailRiskInfo?.riskBandIdStatus">
          <div class="col-md-4">Risk Band Id:</div>
          <div class="col-md-8">{{emailage.responseData?.data.emailRiskInfo.riskBandIdStatus}}</div>
        </div>
      </div>
    </div>

    <br />

    <div class="keep-together">
      <div><b><u>Fraud Detail</u></b></div>
      <div>
        <div class="col-md-12" *ngIf="emailage.searchFail && !emailage.noSearchSelect"><i class="color-grey float-right">{{messageService.SearchFail}}</i></div>
        <div class="col-md-12" *ngIf="!emailage.responseData?.data?.fraudDetail && !emailage.noSearchSelect"><i class="color-grey float-right">{{messageService.NoRelevantDataMessage}}</i></div>
      </div>
      <div *ngIf="emailage.responseData?.data?.fraudDetail && !emailage.searchFail">
        <div class="row" *ngIf="emailage.responseData?.data?.fraudDetail?.lastFlaggedOn">
          <div class="col-md-4">Date of Last Fraud Flag:</div>
          <div class="col-md-8">{{emailage.responseData?.data.fraudDetail.lastFlaggedOn | date:'dd-MM-YYYY'}}</div>
        </div>
        <div class="row" *ngIf="emailage.responseData?.data?.fraudDetail?.sourceIndustry">
          <div class="col-md-4">Industry:</div>
          <div class="col-md-8">{{emailage.responseData?.data.fraudDetail.sourceIndustry}}</div>
        </div>
        <div class="row" *ngIf="emailage.responseData?.data?.fraudDetail?.fraudType">
          <div class="col-md-4">Fraud Type:</div>
          <div class="col-md-8">{{emailage.responseData?.data.fraudDetail.fraudType}}</div>
        </div>
      </div>
    </div>

    <div class="keep-together mt-4">
      <div><b><u>URLs Linked to Email:</u></b></div>
      <div>
        <div class="col-md-12" *ngIf="emailage.searchFail && !emailage.noSearchSelect"><i class="color-grey float-right">{{messageService.SearchFail}}</i></div>
        <div class="col-md-12" *ngIf="(!emailage?.urls || emailage?.urls.length == 0) && !emailage.noSearchSelect"><i class="color-grey float-right">{{messageService.NoRelevantDataMessage}}</i></div>
      </div>
      <div *ngIf="(emailage?.urls && emailage?.urls.length > 0)">
        <div *ngFor="let url of emailage.urls">
          <div class="row">
            <div class="col-md-4">{{url.source}} : </div>
            <div class="col-md-8"><a [href]="url.link">{{url.link}}</a></div>
          </div>
          <hr />
        </div>
      </div>
    </div>
  </div>

</div>
