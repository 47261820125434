<div class="cookie-container py-4">
  <div class="page-background">
    <div class="container">
      <div>
        <p-card header="Cookie Policy">
          <div class="policy">
            <p>In this cookie policy you can read about</p>
            <ul>
              <li>how we use cookies,</li>
              <li>the types of cookies we use,</li>
              <li>for how long they work and for what purposes they are used,</li>
              <li>how to change your cookie settings and opt-out, and</li>
              <li>Who are we and how can you contact us.</li>
            </ul>
            <p>When we use words like "us", "we", "our" we mean Heads Up Technologies Limited. You can find our company details below. We are the data controller of the data collected through the use of cookies on our website.</p>
            <p>Our website is <a href="https://www.hut.global">https://www.hut.global</a>, incl. our sub-domains, we own and operate.</p>
            <p>When we refer to "you" we mean you as a user or visitor of our website.</p>
            <p>This policy is part of our privacy policy. Our use of cookies may include processing of your personal data and we therefore recommend that you read our privacy policy, which can be found <a href="https://www.hut.global/compliance/">here</a>.</p>
            <p><strong>Consent</strong></p>
            <p>Where you have consented to our use of cookies through the consent banner on our website or App, apart from necessary cookies, you consent to our use of cookies as described under "Types of cookies and how we use them" below. You may at any time change or withdraw your cookie consent - See the section "How you can change your cookie settings, incl. opting out" below.</p>
            <p><strong>What do I need to know about cookies?</strong></p>
            <p>There are different types of cookies and they are used for different purposes.</p>
            <p>Below you can read about what a cookie is, the difference between first and third party cookies and session cookies vs. persistent cookies and what types of cookies we use on our website and why.</p>
            <p><strong>What is a cookie?</strong></p>
            <p>A cookie is a small piece of data that a website stores on your device when you visit it and which is then read when you later revisit the site. The word "cookies" in this policy and the consent also refers to other forms of automatic collection of data, e.g. Flash-cookies (Local Shared Objects), Web Storage (HTML5), Javascripts or cookies placed by other software.</p>
            <p>A cookie may contain information about the website itself, a unique identifier that allows the site to recognise your web browser when you return to the website, additional data that serves the purpose of the cookie, and the lifespan of the cookie itself.</p>
            <p>The word "cookies" or "cookie data" also covers information about IP and MAC addresses and other information about your device collected by said technologies.</p>
            <p>Cookies are used to enable certain features (e.g. logging in), to track site usage (e.g. analytics), to store your user settings (e.g. timezone, notification preferences), and to personalise your content (e.g. advertising, language).</p>
            <p><strong>Session cookies vs. persistent cookies</strong></p>
            <p>Session cookies only last as long as your online session. This means that they will disappear from your computer or device when you close your browser. They are therefore also sometimes referred to as temporary cookies. Typically, session cookies are used to remember what a user put in their basket when they are browsing a website.</p>
            <p>Persistent cookies are different. These cookies are sometimes called permanent cookies. They will stay on your computer or device after you close your browser. These types of cookies will expire according to the time specified in the cookie. You can see the specific duration of each persistent cookie below.</p>
            <p><strong>What’s the difference between first and third party cookies?</strong></p>
            <p>First party cookies are cookies that are set by the website that you are visiting and it's only this website that can access and read these cookies.</p>
            <p>Third party cookies are set by someone other than the owner of the website you’re visiting. As an example, some pages have content from other sites like YouTube. YouTube may set their own cookies on your browser when you play the video from YouTube. Cookies set by other sites and companies (i.e. third parties) can be used to track you on other websites that use the same third-party service.</p>
            <p>We may engage third parties to assist with maintenance, operation, creation or functionality of our website, e.g. analytics providers and content partners. We grant these third parties’ access to selected information to perform specific tasks on our behalf.</p>
            <p>We are, as a general rule, joint controller with providers of third-party cookies for the collection of personal data via such cookies and the disclosure to the provider. The provider of third-party cookies is data controller for the processing taking place after receiving the personal data from us. You can read more about the extent of our joint controllership with providers of third-party cookies in our privacy policy.</p>
            <p>We recommend reading the provider's privacy policy which can be found through links in the tables below, where you can also see which cookies on our website are first party cookies and third-party cookies.</p>
            <p><strong>Types of cookies and how we use them</strong></p>
            <p><strong>Necessary cookies</strong></p>
            <p>Necessary cookies are required for the basic functionality of our website and App to work. We use necessary cookies to make it possible for you to only have to enter your username and password once during a visit to our website or App.</p>
            <p>We also use necessary cookies to help with ensuring that you are given the option to accept or reject cookies, block non-necessary cookies from working until you give consent, and remember your cookie settings and choices. The cookies also help keep track of, if, and when, you gave consent to analytical cookies, T&Cs and email marketing.</p>
            <p>Necessary cookies are also used for payment processing.</p>
            <p>It's not necessary to accept nor possible to reject the use of necessary cookies as they are core for the functionality of our website.</p>
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-8">
                <table class="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>Cookie Name</th>
                      <th>Purpose</th>
                      <th>Expiration</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>cookieconsent_status</td>
                      <td>Used to determine if a user has accepted the cookie policy</td>
                      <td>1 year</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-md-2"></div>
            </div>
            <p><strong>Analytical cookies</strong></p>
            <p>Analytical cookies gather statistics. We use this information to make our website and App even better. The information collected via the analytical cookies track how you use our platforms during your visit. It helps us understand visitor usage patterns, identify, and diagnose problems or errors you may encounter, and make better strategic decisions in improving the website experience.</p>
            <p>We will only set analytical cookies on your device if you give us your consent.</p>
            <!-- Table -->
            <p><strong>Marketing cookies</strong></p>
            <p>Marketing cookies are used in determining what promotional content is more relevant and appropriate to you and your interests. Websites may use them to deliver targeted advertising or to limit the number of times you see an advertisement. This helps companies improve the effectiveness of their campaigns, and the quality of content presented to you. These cookies may be set by the website you’re visiting (first party) or by third-party services. Marketing cookies set by a third-party service may be used to track you on other websites that use the same third-party service.</p>
            <p>We will only set marketing cookies on your device if you give us your consent.</p>
           <!-- Table -->
            <p><strong>How you can change your cookie settings, incl. opting out</strong></p>
            <p>As part of our cookie solution, we always ask for your consent to cookies, except for necessary cookies, before placing cookies on your device.</p>
            <p>We also always give you the option to change your consent. If you at one point gave consent to non-necessary cookies on our website and App, you can always change which cookies you will give consent to. Just look for the shield or the cookie settings link on our website or App. If you click the shield or the cookie settings link, your cookie settings will appear, allowing you to always change your settings and reject cookies.</p>
            <p>It's also possible to instruct your browser to refuse cookies from our website. Most browsers are configured to accept cookies by default, but you can update these settings to either refuse cookies altogether, or to notify you when a website is trying to set or update a cookie. If you use multiple browsers and wish to block cookies or change or withdraw your consent, remember to do this in all browsers.</p>
            <p>If you browse websites from multiple devices, you may also need to update your settings on each individual device.</p>
            <p>Although some cookies can be blocked with little impact on your experience of a website, blocking all cookies may mean you are unable to access certain features and content on the site.</p>
            <p><strong>How often will we update this Cookie Notice?</strong></p>
            <p>We may update this Cookie Policy from time to time in order to reflect, for example, changes to the cookies we use or for other operational, legal or regulatory reasons. Please therefore re-visit this Cookie Policy regularly to stay informed about our use of cookies and related technologies. The date at the bottom of this Cookie Policy indicates when it was last updated.</p>
            <p><strong>Who are we and how can you contact us?</strong></p>
            <p>Here is our company information:</p>
            <p>
              Heads Up Technologies Limited<br />
              Unit 13, Redhill 23 Business Park, 29 Holmethorpe Avenue, Redhill, RH1 2GD<br />
              United Kingdom
            </p>
            <p>Company registration number: 12968519</p>
            <p>You can always write to us at: <a href="mailto:support@hut.global">support@hut.global</a></p>
          </div>
        </p-card>
      </div>
    </div>
  </div>
</div>

