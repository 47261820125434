import { Feedback } from './models/Feedback';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable'
import { SubscriptionService } from 'src/app/subscription/service/subscription.service';
import { interval } from 'rxjs';
import { Component, OnInit, HostListener, ViewChild, ElementRef, TemplateRef, Renderer2 } from '@angular/core';
import { SearchService } from '../home/services/search.service';
import { Router } from '@angular/router';
import { Items } from '../home/models/Items';
import { Token } from '../shared/models/token';
import { LexiconStatus } from '../home/models/LexiconStatus';
import { SocialMediaStatus } from './models/SocialMediaStatus';
import { SearchDomain } from './models/SearchDomain';
import { Keywords } from './models/Keywords';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { environment } from '../../environments/environment';
import { ContactData } from './models/ContactData';
import { AVAResultData } from './models/AVAModel/AVAResultData';
import { AccessData, RequestData, Item } from './models/AccessData';
import { AppComponent } from '../app.component';
import { PlanExpiry } from '../subscription/Models/PlanExpiry';
import { ConfirmationService, MessageService } from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';
import { Message } from 'primeng/api';
import html2canvas from 'html2canvas';
import { DatePipe } from '@angular/common';
import { isPossiblePhoneNumber, parsePhoneNumber, isValidPhoneNumber, validatePhoneNumberLength } from 'libphonenumber-js';
import { Shared } from '../demo/V3/services/shared';
import { LifeStyleStatus } from './models/LifeStyleStatus';
declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class HomeComponent implements OnInit {
  @ViewChild('alert') alert: ElementRef;
  @ViewChild('content', { static: true }) content: ElementRef;
  // @ViewChild('element',{static:true}) element:ElementRef;
  @HostListener('window:scroll', ['$event'])
  scrollTrigger(event) {
    let foundElements: HTMLCollectionOf<Element> = document.getElementsByClassName('app-outer-container');
    if (foundElements[0].scrollTop <= 165) {
      if (this.imagePositionClass != "") {
        this.imagePositionClass = "";
      }
    }
    else if (foundElements[0].scrollTop > 165) {
      if (this.imagePositionClass != "fix-position-top") {
        this.imagePositionClass = "fix-position-top";
      }
    }
  }

  //msgs: Message[] = [];
  public criminalityRelevantRank: number = 0;
  public criminalitySuccessCount: number = 0;
  public licenceError: string = null;
  public loadingAnimation: string = "data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==";
  public searchUniqueIdlist: string[] = [];
  public searchDomains: SearchDomain[] = [];
  public activeSearchDomains: string[] = [];
  public htmltopdf: string;
  public imagePositionClass: string = "";
  public search: string = "";
  public searchtext: string = "";
  public lexicons: string[] = ["Risk", "Theft", "Fraud", "Driving", "Drugs", "Violence", "Crime", "Officials", "Offenders"];
  //public lexicons: string[] = ["Risk"];
  public comparelexicons: string[] = [];
  public results: Items[] = [];
  public domainResults: Items[] = [];
  public rankingresults: Items[] = [];
  public sortfinalResults: Items[] = []
  public finalresults: Items[] = [];
  public relevantResults: Items[] = [];
  public lowRankingResults: Items[] = [];
  public crimeSearchResultCount: number;
  public resultcount: number;
  public IsResultValues: boolean;
  public showResultTab: boolean;
  public visibleResetBtn: boolean = true;
  public dataloading: boolean;
  public displaylexiconKey: boolean;
  public searchComplete: boolean;
  public follow: string;
  public followDisplay: boolean;
  public followStatus: boolean;
  public userId: string;
  public refreshtoken = new Token(null);
  public CategorySearchTable: boolean = false;
  public filterLexiconStatus: LexiconStatus;
  public lexiconStatus: LexiconStatus[] = [];
  public validationerror: boolean = false;
  public serverError: boolean;
  public subscription: string;
  public collapseResultClass: string;
  public collapseUserResultClass: string;
  public collapseContactResultClass: string;
  public collapseResultStatus: string;
  public collapseUserDetails: string;
  //public collapseContactDetails: string;
  public collapseResultIcon: string;
  public collapseContactResultIcon: string;
  public collapseUserResultIcon: string;
  public resultComplete: boolean;
  public showAllResults: boolean;
  //public showMoreResults: boolean;
  //public isShowResult: boolean;
  public showRelevantResults: boolean;
  public addAssociationNormalView: boolean;
  public addAssociationTextboxView: boolean;
  public counterFraudData: any;
  public prospectHitResultData: any;
  public financialData: any;
  public userContactData: any;
  public userDirectorshipData: any;
  public isShowContactDetail: boolean;
  public isShowInsurerFraudDetail: boolean;
  public isShowFinancialDetail: boolean;
  public isShowDirectorshipDetail: boolean;
  public contactloading: boolean;
  public insurerloading: boolean;
  public financialloading: boolean;
  public directorshiploading: boolean;
  public advertloading: boolean;
  public associationloading: boolean;
  public contactData = new ContactData();
  public accessData = new AccessData();
  public disableData = new AccessData();
  public requestData = new RequestData();
  public Items: Item[];
  public auditKey: string;
  public isShowEmail: boolean;
  public emailloading: boolean;
  public emailData: any;
  public tempAccessData: any;
  public tempContactData: any;
  public tempSearchName: any;
  public tempPhoneNumber: any;
  public emailValidationMessage: string;
  public counterfraudValidationMessage: boolean;
  public noErrorEmail: boolean;
  public noErrorPhone: boolean;
  // public dialCode:number;
  // public isShowCountryCode: boolean;

  // ----------------- Associated search -----------------------
  public associatedSearchUsers: Array<any>;
  public newAssociatedUser: any;
  public validAssociatedSearchUsers: any[];
  public AssociatedDomainresults: Items[] = [];
  public Associatedresults: Items[] = [];
  public Associatedresultsdisplay: Items[] = [];
  public AssociatedresultCount: number;
  public associatedStatusCount: number;
  public associatedDataNotProvide: boolean;
  public associatedFail: boolean;

  //----------------Life Style search --------------------------
  public lifeStyleCategory: string[] = ["Running", "Water", "Ball", "Racket", "Hockey", "Rugby", "Boxing", "MMA"];
  //public lifeStyleCategory: string[] = ["Running"];
  public comparelifeStyleCategory: string[] = [];
  //public lifestyle: boolean = true;
  //public isAdvertRunning: boolean = true;
  public lifeStyleStaus: LifeStyleStatus[] = [];
  public LifeStyleResultCount: number;
  public AdvertResultCount: number;
  public AdvertValidationMessage: string;
  public DirectorShipErrorMessage: string;
  public IsLifeStyleValue: boolean;
  public filterlifeStyleStaus: LifeStyleStatus;
  public lifeStyleSuccessCount: number = 0;
  public LifeStyleCategorySearchTable: boolean = false;
  public lifeStyleResults: Items[] = [];
  public finalLifeStyleResults: Items[] = [];
  public lifeStyledataloading: boolean;
  public displaylifeStyleCount: boolean;
  public collapseLifeStyleClass: string;
  public collapseLifeStyleStatus: string;
  public collapseLifeStyleIcon: string;
  public lifetStyleComplete: boolean;

  // ---------------- Social Media ----------------------
  public socialMediaCategory: string[] = ["facebook", "instagram", "linkedin", "twitter", "youtube", "tiktok"];
  //public socialMediaCategory: string[] = ["facebook"];
  public socialMediaSuccessCount: number = 0;
  public compareSocialMediaCategory: string[] = [];
  public socialMediaKeyAssign: Items[] = [];
  public socialMediaResults: Items[] = [];
  public finalSocialMediaResults: Items[] = [];
  public advertResults: Items[] = [];
  public socialMediaStatus: SocialMediaStatus[] = [];
  public filtersocialMediaStatus: SocialMediaStatus;
  public SocialMediaResultCount: number;
  public SocialMediaCategorySearchTable: boolean = false;
  public IsSocialMediaValue: boolean;
  public IsAdvertValue: boolean;
  public socialmedia: boolean = true;
  public socialMediadataloading: boolean;
  public displaySocialMediaCount: boolean;
  public collapseSocialMediaClass: string;
  public collapseSocialMediaStatus: string;
  public collapseSocialMediaIcon: string;
  public SocialMediaComplete: boolean;

  // ---------------- Display rank history ----------------
  public rankHistory: Keywords[];
  public rankHistoryLexicon: string[];
  public devData: boolean;
  public rankHistoryPopUp: BsModalRef;
  public rankDomain: string;
  public rankDomainValue: number;
  public duplicatelinkCount: number;
  public duplicatelinkRank: number;
  public negativePoints: number;
  public negativekeywords: string[] = [];
  public phonenumber: string;
  public canvasWidth = 270
  public needleValue = 0
  public centralLabel = ''
  //public name = 'Gauge chart'
  public bottomLabel = ''

  // ----------------- AVA ---------------
  public avaResult: AVAResultData;
  public avaloading: boolean;
  public avaErrorMessage: string;
  //public avaRunning: boolean;

  // Message type
  public noRelevantDataAddress: boolean;
  public noRelevantDataAVA: boolean;
  public noRelevantDataAdvert: boolean;
  public noRelevantDataAssociate: boolean;
  public noRelevantDataDirectorship: boolean;
  public noRelevantDataCriminality: boolean;
  public noRelevantDataFinancial: boolean;
  public noRelevantDataSocialMedia: boolean;
  public noRelevantDataLifeStyle: boolean;
  public noRelevantDataMobile: boolean;
  public noRelevantDataCounterFraud: boolean;
  public noRelevantDataEmail: boolean;

  // validation message
  public vrnvalidationmessage: boolean = false;
  public postvalidationmessage: boolean = false;
  public mobilevalidationerror: string;
  public isInvalidMobileNumber: boolean = false;
  public isValidVRN: boolean = true;
  //public dobvalidationmessage: string;
  public counterFraudValidation: boolean;
  public financialVaidation: boolean;
  public addressValidation: boolean;
  public referenceError: boolean = false;

  // search fail to execure error message
  public counterfraudSearchFail: boolean;
  public financialSearchFail: boolean;
  public emailSearchFail: boolean;
  public mobileSearchFail: boolean;
  public avaSearchFail: boolean;
  public directorshipSearchFail: boolean;

  // Plan Expiry
  public isPlanExpired: boolean = false;
  public planExpiry: PlanExpiry = null;
  //public PlanDetails: SubscriptionPlan = null;
  public PlanExpiryMessage: string = null;
  public ToastWarningHeader: string = null;
  public expiryIntervalCall: any;

  // Messages
  public emailNotValidMessage: string = "Email is not valid."
  public emailRequiredMessage: string = "Email is required.";
  public mobileRequiredMessage: string = "Mobile number is required.";
  public mobileNotValidMessage: string = "Mobile number is not valid.";
  public nameRequiredMessage: string = "Name is required.";
  public vrnRequiredMessage: string = "VRN is required.";
  public vrnNotValidMessage: string = "VRN is not valid.";
  public postCodeRequiredMessage: string = "Postcode is required.";
  public postCodeNotValidMessage: string = "Postcode is not valid.";
  public assiciateDataNotProvideMessage: string = "Associated entity is required.";
  public referenceRequiredMessage: string = "Reference number is required.";
  public NoRelevantDataMessage = "No relevant data found.";
  public NoDataFoundMessage = "No data found for the stated address – try checking previous and linked addresses from the address tab in HeadsUp.";
  public AdditionalDataMessage = "Please include a date of birth.";
  public NoRelevantDataMessageShort = "No records.";
  public NoSearchSelect = "Search option not selected.";
  public NoSearchSelectShort = "Not selected."
  public SearchFail = "Search failed.";
  public MobileValidationMessage = "Mobile number is required.";
  public OtherFieldRequired = "Additional data is required.";

  // Dynamic Validation
  public isNameRequired: boolean;
  public isVRNRequired: boolean;
  public isPostCodeRequired: boolean;
  public isReferenceRequired: boolean;

  public saveAsPDFButtonVisibility: boolean = true;
  public isDownloadStart: boolean = false;
  public x: number;
  public y: number;

  // AML
  public AMLResult: any;
  public amlloading: boolean;
  public AMLSearchFail: boolean;

  // CCJ
  public CCJResult: any;
  public ccjloading: boolean;
  public CCJSearchFail: boolean;
  public CCJErrorMessage: string;

  public isProspectHitAccess: boolean;
  public isProspectProfileAccess: boolean;
  public prospectloading: boolean;
  public prospectSearchFail: boolean;
  public prospectErrorMessage: string;
  public prospectProfileLoading: boolean;
  public prospectProfileButtonDisable: boolean;
  public prospectProfileButtonText: string;
  public prospectProfileButtonText_RequestProfile = "Request Profile";
  public prospectProfileButtonText_Requested = "Requested";
  public prospectProfileResultData: any;

  public rtwResult: any;
  public rtwloading: boolean;
  public rtwSearchFail: boolean;
  public rtwErrorMessage: string;
  public isShowRTW: boolean;

  public idCheckResult: any;
  public idcheckloading: boolean;
  public idCheckSearchFail: boolean;
  public idCheckErrorMessage: string;
  public isShowIDCheck: boolean;

  public IDCheckTooltipContent: string = "Document check for identity verification. For subscribers only.";
  public RTWTooltipContent: string = "Right to Work enquiry. For subscribers only.";

  public emailageResult: any;
  public emailageloading: boolean;
  public emailageSearchFail: boolean;
  public emailageErrorMessage: string;
  public isShowEmailage: boolean;

  public options = {
    hasNeedle: true,
    needleColor: 'gray',
    needleUpdateSpeed: 1000,
    arcColors: ['green', 'orange', '#CE103E'],
    arcDelimiters: [33.33, 66.66, 99.99],
    // rangeLabel: ['0', '100'],
    needleStartValue: 0,
  }
  constructor(
    private searchservice: SearchService,
    private router: Router,
    private modalService: BsModalService,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private appComponent: AppComponent,
    private subscriptionService: SubscriptionService,
    private confirmationService: ConfirmationService,
    private primengConfig: PrimeNGConfig,
    private messageService: MessageService,
    public datepipe: DatePipe
  ) { }

  ngAfterViewInit() {
    this.elementRef.nativeElement.ownerDocument.body.style.background = 'url(/assets/Images/Background.svg)';
  }
  ngOnChanges() {

  }
  ngOnInit() {
    this.primengConfig.ripple = true;
    this.appComponent.isLoginExpires();
    this.isNameRequired = true;
    this.isPostCodeRequired = false;
    this.isVRNRequired = true;
    this.isReferenceRequired = true;
    this.saveAsPDFButtonVisibility = false;
    this.tempAccessData = new AccessData();
    this.PlanExpiryMessage = "Your subscription has expired. Please contact your account manager.";
    this.ToastWarningHeader = "HUT Warning";
    this.CheckProspectHitAndProfileAccess();
    this.searchservice.AllowedLicence().subscribe(data => {
      this.Items = data;
      this.getSecondAccess(this.Items);
      this.appComponent.screen = false;
      if (data.find(i => i.licenceType.toLowerCase() == "screen")) {
        this.appComponent.screen = true;
      }
    });

    this.appComponent.search = false;
    this.getPlanExpired(1);
    this.expiryIntervalCall = interval(30000).subscribe(val => this.getPlanExpired(0));

    this.appComponent.settingsvisibility = true;
    this.devData = environment.DevData;
    this.userId = localStorage.getItem("userId");
    this.subscription = localStorage.getItem("subscription");
    this.associatedSearchUsers = [];
    this.newAssociatedUser = {};
    this.showResultTab = false;
    this.addAssociationNormalView = true;
    this.addAssociationTextboxView = false;
    this.collapseUserDetails = "Additional Information";
    //this.collapseContactDetails = "Show Contact Result";
    this.collapseResultIcon = "fa-chevron-down";
    this.collapseUserResultIcon = "fa-chevron-down";
    this.collapseContactResultIcon = "fa-chevron-down";
    this.collapseContactResultClass = "hide";
    this.isShowContactDetail = false;
    this.isShowInsurerFraudDetail = false;
    this.isShowFinancialDetail = false;
    this.isShowDirectorshipDetail = false;
    this.contactloading = false;
    this.insurerloading = false;
    this.financialloading = false;
    this.directorshiploading = false;
    this.advertloading = false;
    this.associationloading = false;
    this.emailloading = false;
    this.amlloading = false;
    this.ccjloading = false;
    this.rtwloading = false;
    this.idcheckloading = false;
    this.AdvertValidationMessage = '';
    this.DirectorShipErrorMessage = '';
    $('#collapseContactData').collapse(this.collapseContactResultClass);
    this.searchservice.getDomainJson()
      .subscribe((data) => {
        this.searchDomains = data;
        this.searchDomains.forEach((item: SearchDomain) => {
          if (item.IsActive == true) {
            this.activeSearchDomains.push(item.UniqueCode);
          }
        });
      }, error => {
        console.log(error);
      });
    this.subscriptionService.GetCriminalityRankForSearch().subscribe(data => {
      this.crimeSearchResultCount = data.criminalitySearchResultCount;
      var rank = data.criminalitySearchRank;
      if (rank != null && rank != 0) {
        this.criminalityRelevantRank = rank;
      } else {
        this.criminalityRelevantRank = 200;
      }
    }, error => {
      this.criminalityRelevantRank = 200;
    })
    // this.GetTenants();
    //this.searchservice.GetToggleSettings(this.userId)
    //  .subscribe(data => {
    //    Object.assign(this.accessData, data);
    //    Object.assign(this.disableData, data);
    //  });
  }

  ngOnDestroy() {
    this.expiryIntervalCall.unsubscribe();
  }

  // public GetTenants() {
  //   this.subscriptionService.getTenantName().subscribe(data => {
  //     this.isShowCountryCode = data.isInternationalEnabled;
  //     if(!this.isShowCountryCode) {
  //       var input = document.querySelector("#phone");
  //       (<any>window).intlTelInput(input, {
  //         separateDialCode: true,
  //         onlyCountries: ["gb"],
  //         placeholderNumberType: "aggressive",
  //       });
  //     }
  //   })
  // }

  likeFeedBack(index, type: string) {
    var result;
    if (type.toLowerCase() === "associations") {
      result = this.Associatedresultsdisplay[index];
      this.Associatedresults.find(x => {
        if (x.link == this.Associatedresultsdisplay[index].link) {
          x.like = true;
          x.dislike = false;
        }
      })
    } else if (type.toLowerCase() === "adverts") {
      result = this.advertResults[index];
      this.advertResults.find(x => {
        if (x.link == this.advertResults[index].link) {
          x.like = true;
          x.dislike = false;
        }
      })
    } else if (type.toLowerCase() === "sports") {
      result = this.finalLifeStyleResults[index];
      this.lifeStyleResults.find(x => {
        if (x.link == this.finalLifeStyleResults[index].link) {
          x.like = true;
          x.dislike = false;
        }
      })
    } else if (type.toLowerCase() === "social") {
      result = this.finalSocialMediaResults[index];
      this.socialMediaResults.find(x => {
        if (x.link == this.finalSocialMediaResults[index].link) {
          x.like = true;
          x.dislike = false;
        }
      })
    } else if (type.toLowerCase() === "criminality") {
      result = this.finalresults[index];
      this.sortfinalResults.find(x => {
        if (x.link == this.finalresults[index].link) {
          x.like = true;
          x.dislike = false;
        }
      })
      this.relevantResults.find(x => {
        if (x.link == this.finalresults[index].link) {
          x.like = true;
          x.dislike = false;
        }
      })
    }

    var feedback = new Feedback();
    feedback.resultLink = result.link;
    feedback.resultDescription = result.description;
    feedback.resultdomain = (result.link).match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i)[1];
    feedback.resultType = type;
    feedback.isAccepted = true;
    feedback.auditKey = this.auditKey;
    feedback.resultName = result.title;
    this.searchservice.FeedbackReport(feedback).subscribe(data => {
      // console.log(data);
    })
  }

  dislikeFeedBack(index, type: string) {
    var result;
    if (type.toLowerCase() === "associations") {
      result = this.Associatedresultsdisplay[index];
      this.Associatedresults.find(x => {
        if (x.link == this.Associatedresultsdisplay[index].link) {
          x.like = false;
          x.dislike = true;
        }
      })
    } else if (type.toLowerCase() === "adverts") {
      result = this.advertResults[index];
      this.advertResults.find(x => {
        if (x.link == this.advertResults[index].link) {
          x.like = false;
          x.dislike = true;
        }
      })
    } else if (type.toLowerCase() === "sports") {
      result = this.finalLifeStyleResults[index];
      this.lifeStyleResults.find(x => {
        if (x.link == this.finalLifeStyleResults[index].link) {
          x.like = false;
          x.dislike = true;
        }
      })
    } else if (type.toLowerCase() === "social") {
      result = this.finalSocialMediaResults[index];
      this.socialMediaResults.find(x => {
        if (x.link == this.finalSocialMediaResults[index].link) {
          x.like = false;
          x.dislike = true;
        }
      })
    } else if (type.toLowerCase() === "criminality") {
      result = this.finalresults[index];
      this.sortfinalResults.find(x => {
        if (x.link == this.finalresults[index].link) {
          x.like = false;
          x.dislike = true;
        }
      })
      this.relevantResults.find(x => {
        if (x.link == this.finalresults[index].link) {
          x.like = false;
          x.dislike = true;
        }
      })
    }
    var feedback = new Feedback();
    feedback.resultLink = result.link;
    feedback.resultDescription = result.description;
    feedback.resultdomain = (result.link).match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i)[1];
    feedback.resultType = type;
    feedback.isAccepted = false;
    feedback.auditKey = this.auditKey;
    feedback.resultName = result.title;
    this.searchservice.FeedbackReport(feedback).subscribe(data => {
      // console.log(data);
    })
  }

  // onCountryChange(event) {
  //   this.dialCode = event.dialCode;
  // }

  getSecondAccess(Items: Item[]) {
    Items.forEach(item => {
      if (item.licenceType.toLowerCase() == "search") {
        switch ((item.key)) {
          case "HUTDB": {
            this.accessData.isHUTDatabaseAccess = item.defaultValue;
            this.accessData.isHUTDatabaseToggle = item.toggle;
            break;
          }

          case "MOBILE": {
            this.accessData.isMobileAccess = item.defaultValue;
            this.accessData.isMobileToggle = item.toggle;
            break;
          }

          case "EMAIL": {
            this.accessData.isEmailAccess = item.defaultValue;
            this.accessData.isEmailToggle = item.toggle;
            break;
          }
          case "IDENTITY": {
            this.accessData.isIdentityAccess = item.defaultValue;
            this.accessData.isIdentityToggle = item.toggle;
            break;
          }
          case "FINANCIAL": {
            this.accessData.isFinancialAccess = item.defaultValue;
            this.accessData.isFinancialToggle = item.toggle;
            break;
          }
          case "ADDRESS": {
            this.accessData.isAddressAccess = item.defaultValue;
            this.accessData.isAddressToggle = item.toggle;
            break;
          }
          case "CRIMINALITY": {
            this.accessData.isCriminalityAccess = item.defaultValue;
            this.accessData.isCriminalityToggle = item.toggle;
            break;
          }
          case "SOCIAL": {
            this.accessData.isSocialMediaAccess = item.defaultValue;
            this.accessData.isSocialMediaToggle = item.toggle;
            break;
          }
          case "COUNTER_FRAUD": {
            this.accessData.isCounterFraudAccess = item.defaultValue;
            this.accessData.isCounterFraudToggle = item.toggle;
            break;
          }
          case "SPORTS": {
            this.accessData.isSportsAccess = item.defaultValue;
            this.accessData.isSportsToggle = item.toggle;
            break;
          }
          case "ADVERT": {
            this.accessData.isAdvertsAccess = item.defaultValue;
            this.accessData.isAdvertsToggle = item.toggle;
            break;
          }
          case "COMPANY_HOUSE": {
            this.accessData.isCompaniesHouseAccess = item.defaultValue;
            this.accessData.isCompaniesHouseToggle = item.toggle;
            break;
          }
          case "AVA": {
            this.accessData.isAVAAccess = item.defaultValue;
            this.accessData.isAVAToggle = item.toggle;
            break;
          }
          case "AML": {
            this.accessData.isAMLAccess = item.defaultValue;
            this.accessData.isAMLToggle = item.toggle;
            break;
          }
          case "CCJ_Detail": {
            this.accessData.isCCJDetailAccess = item.defaultValue;
            this.accessData.isCCJDetailToggle = item.toggle;
            break;
          }
          case "Insolvency_Detail": {
            this.accessData.isInsolvencyDetailAccess = item.defaultValue;
            this.accessData.isInsolvencyDetailToggle = item.toggle;
            break;
          }
          case "RTW": {
            this.accessData.isRTWAccess = item.defaultValue;
            this.accessData.isRTWToggle = item.toggle;
            break;
          }
          case "IDCHECK": {
            this.accessData.isIDCheckAccess = item.defaultValue;
            this.accessData.isIDCheckToggle = item.toggle;
            break;
          }
        }
      }
    });
  }

  ngAfterContentInit() {
    if (this.associatedSearchUsers.length == 0) {
      for (let i = 0; i < 4; i++) {
        this.associatedSearchUsers.push(this.newAssociatedUser);
        this.newAssociatedUser = {};
        //this.addAssociationNormalView = false;
        //this.addAssociationTextboxView = true;
      }
    }
  }
  public ShowUserDataDisplay() {
    if (this.collapseUserResultClass == "show") {
      this.collapseUserResultClass = "hide";
      this.collapseUserDetails = "Additional Information";
      this.collapseUserResultIcon = "fa-chevron-down";
      $('#collapseUserData').collapse(this.collapseUserResultClass);
    }
    else {
      this.collapseUserResultClass = "show";
      $('#collapseUserData').collapse(this.collapseUserResultClass);
      this.collapseUserDetails = "Hide Additional Information";
      this.collapseUserResultIcon = "fa-chevron-up";

    }
  }

  public copyIFBReference(reference) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = reference;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
  public async getRandomString(length) {
    await this.searchservice.GetAuditKey().then(
      data => {
        this.auditKey = data;
      });
  }

  getPlanExpired(val) {
    this.subscriptionService.GetPlanExpiry(0).toPromise().then(
      data => {
        this.planExpiry = data;
        let days = this.planExpiry.subscriptionExpiry.days;
        let hours = this.planExpiry.subscriptionExpiry.hours;
        let minutes = this.planExpiry.subscriptionExpiry.minutes;
        let seconds = this.planExpiry.subscriptionExpiry.seconds;
        if (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) {
          this.isPlanExpired = true;
          if (val === 1 && this.isPlanExpired) {
            this.messageService.add({ key: 'PlanExpiry', severity: 'warn', summary: this.ToastWarningHeader, detail: this.PlanExpiryMessage, sticky: true });
            setTimeout(() => {
              this.messageService.clear();
            }, 13000);
          }
          //this.subscriptionService.GetSubscriptionDetail().toPromise().then(
          //  data => {
          //    this.PlanDetails = data;
          //    if (val === 1 && this.isPlanExpired) {
          //      if (this.PlanDetails) {
          //        this.messageService.add({ key: 'PlanExpiry', severity: 'warn', summary: 'Warning', detail: 'Your HUT ' + this.PlanDetails.planName + ' plan has been expired.', sticky: true });
          //        setTimeout(() => {
          //          this.messageService.clear();
          //        }, 13000);
          //      }
          //    }
          //  },
          //  error => {
          //    this.PlanDetails = null;
          //    console.log(error);
          //  }
          //);

        }
        else {
          this.isPlanExpired = false;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  public SearchData() {
    if (this.isPlanExpired) {
      this.licenceError = this.PlanExpiryMessage;
      setTimeout(() => { this.licenceError = null }, 7000);
    } else {
      this.isPlanExpired = false;
      this.search = this.searchtext;
      this.search = this.search?.trim();
      this.counterFraudData = null;
      this.prospectHitResultData = null;
      this.financialData = null;
      this.userContactData = null;
      this.isShowContactDetail = false;
      this.isShowInsurerFraudDetail = false;
      this.isShowFinancialDetail = false;
      this.isShowDirectorshipDetail = false;
      this.isShowEmail = false;
      this.isShowRTW = false;
      this.isShowIDCheck = false;
      this.isShowEmailage = false;
      this.counterfraudValidationMessage = false;
      this.mobilevalidationerror = null;
      this.emailSearchFail = null;
      this.emailData = null;
      this.userDirectorshipData = [];
      this.CCJResult = null;
      this.AMLResult = null;
      //tempAccessData do not reassign in an other method. This is used to track search state.
      this.tempAccessData = JSON.parse(JSON.stringify(this.accessData));
      this.tempContactData = JSON.parse(JSON.stringify(this.contactData));
      this.tempSearchName = this.searchtext ? JSON.parse(JSON.stringify(this.searchtext)) : "";
      this.tempPhoneNumber = this.phonenumber ? JSON.parse(JSON.stringify(this.phonenumber)) : "";
      this.CheckProspectHitAndProfileAccess();
      this.showResultTab = false;
      if (this.validateBasedOnToggle()) {
        // console.log("Valid");
        this.isShowContactDetail = true;
        this.isShowInsurerFraudDetail = true;
        this.isShowDirectorshipDetail = true;
        this.isShowEmail = true;
        this.isShowFinancialDetail = true;
        this.isShowRTW = true;
        this.isShowEmailage = true;
        this.isShowIDCheck = true;
        Promise.resolve(this.getRandomString(10))
          .then((value) => {
            this.saveAsPDFButtonVisibility = true;
            this.requestData.accessData = this.tempAccessData;
            this.requestData.profileData = this.contactData;
            this.requestData.profileData.associatedNames = this.associatedSearchUsers.filter(x => JSON.stringify(x) != JSON.stringify({}) && x.name.trim() != "");
            if (this.search) {
              let searchtextarray = this.search.split(" ");
              this.requestData.profileData.firstName = searchtextarray[0];
              this.requestData.profileData.lastName = searchtextarray.length > 1 ? searchtextarray[searchtextarray.length - 1] : searchtextarray[1];
              this.requestData.profileData.midleName = searchtextarray.length > 2 ? searchtextarray[1] : null;
            } else {
              this.requestData.profileData.firstName = null;
              this.requestData.profileData.lastName = null;
              this.requestData.profileData.midleName = null;
            }
            this.collapseUserResultClass = "hide";
            this.collapseUserDetails = "Additional Information";
            this.collapseUserResultIcon = "fa-chevron-down";
            if (this.phonenumber != "" && this.phonenumber != null) {
              this.phonenumber = this.phonenumber.trim();
              const phoneNumber = parsePhoneNumber(this.phonenumber, 'GB');
              if (phoneNumber.isValid() === true && isPossiblePhoneNumber(this.phonenumber, 'GB') === true
                && isValidPhoneNumber(this.phonenumber, 'GB') && validatePhoneNumberLength(this.phonenumber, 'GB') === undefined) {
                this.contactData.phoneNumber = phoneNumber.formatInternational();
              } else {
                this.isInvalidMobileNumber = true;
              }
              this.isShowContactDetail = true;
            } else {
              this.contactData.phoneNumber = null;
            }
            if (this.search && (this.contactData.postalCode || this.contactData.dateOfBirth || this.contactData.email || this.phonenumber || this.contactData.vRN || this.contactData.companyName)) {
              this.isShowInsurerFraudDetail = true;
              this.counterfraudValidationMessage = false;
            }
            else {
              this.counterfraudValidationMessage = true;
            }
            //this.avaRunning = true;
            //Counter Fraud
            this.CounterFraudCheck()

            // Telisign
            this.TelisignCheck();

            //Directorship
            this.DirectorShipCheck();

            //Financial
            this.GetFinancialData();

            // Email
            this.EmailDomainCheck();

            //Address
            this.Address();

            $('#searchResultTab a[href="#mainSearch"]').tab('show');
            this.resultComplete = false;
            this.collapseResultClass = "show";
            this.collapseResultStatus = "Hide Status";
            this.collapseResultIcon = "fa-chevron-up";
            this.followDisplay = false;

            this.CheckFollowing();

            this.IsResultValues = false;
            this.searchComplete = false;

            this.serverError = false;
            this.showResultTab = true;
            this.showRelevantResults = false;
            this.showAllResults = false;
            //this.showMoreResults = false;
            this.visibleResetBtn = true;

            // Association
            this.associatedSearch();

            //Scroll to result view
            try {
              const errorField = this.renderer.selectRootElement('.target');
              errorField.scrollIntoView();
            } catch (err) { }
            //Scroll to result view end

            // Criminality
            this.CriminalityCheck();

            // AVA
            this.AVACheck();

            // Social Media
            this.SocialMediaCheck();

            // Sports
            this.LifeStyleCheck();

            // Advert
            this.AdvertCheck();

            // AML
            this.AMLCheck();

            // CCJ Detail
            this.CCJAndInsolvencyShowLoader();

            // Prospect Hit and Profile
            if (this.isProspectHitAccess) {
              this.ProspectData();
            }

            // RTW
            this.RightToWorkCheck();

            //ID Check
            this.IDCheck();

            // Emailage
            this.EmailAgeCheck();
          }
          );
      }
    }

  }

  public IDCheck() {
    this.idcheckloading = false;
    this.idCheckErrorMessage = null;
    this.idCheckSearchFail = false;
    if (this.accessData.isIDCheckAccess) {
      if (this.searchtext && this.contactData.email) {
        this.idcheckloading = true;
        this.idCheckErrorMessage = null;
        this.idCheckSearchFail = false;
        this.idCheckResult = null;
        this.searchservice.IDCheck(this.requestData, this.search, this.auditKey).subscribe(
          data => {
            console.log(data);
            if (data["status"] == 200) {
              this.idCheckResult = data;
            }
            this.idcheckloading = false;
          },
          error => {
            console.log(error);
            this.idCheckSearchFail = true;
            this.idcheckloading = false;
            this.idCheckResult = null;
          }
        );
      }
      else {
        this.idcheckloading = false;
        this.idCheckSearchFail = false;
        this.idCheckErrorMessage = this.OtherFieldRequired;
      }
    }

  }

  public EmailAgeCheck() {
    this.emailageloading = false;
    this.emailageErrorMessage = null;
    this.emailageSearchFail = false;
    this.emailageResult = null;
    if (this.accessData.isEmailAccess) {
      if (this.contactData.email) {
        this.emailageloading = true;
        this.emailageErrorMessage = null;
        this.emailageSearchFail = false;
        this.emailageResult = null;
        this.searchservice.EmailAge(this.requestData, this.search, this.auditKey).subscribe(
          data => {
            if (data["status"] == 200) {
              this.emailageResult = data["data"];
            }
            this.emailageloading = false;
          },
          error => {
            console.log(error);
            this.emailageSearchFail = true;
            this.emailageloading = false;
            this.emailageResult = null;
          }
        );
      }
    }
  }

  public RightToWorkCheck() {
    this.rtwloading = false;
    this.rtwErrorMessage = null;
    this.rtwSearchFail = false;
    this.rtwResult = null;
    if (this.accessData.isRTWAccess) {
      if (this.searchtext && this.contactData.email) {
        this.rtwloading = true;
        this.rtwErrorMessage = null;
        this.rtwSearchFail = false;
        this.rtwResult = null;
        this.searchservice.RightToWorkSearch(this.requestData, this.search, this.auditKey).subscribe(
          data => {
            if (data["status"] == 200) {
              this.rtwResult = data;
            }
            this.rtwloading = false;
          },
          error => {
            console.log(error);
            this.rtwSearchFail = true;
            this.rtwloading = false;
            this.rtwResult = null;
          }
        );
      } else {
        this.rtwloading = false;
        this.rtwSearchFail = false;
        this.rtwErrorMessage = this.OtherFieldRequired;
      }
    }
  }

  public CheckProspectHitAndProfileAccess() {
    this.isProspectHitAccess = false;
    this.searchservice.CheckProsectHitAndProfileAccessClient().subscribe(data => {
      this.prospectProfileLoading = false;
      this.isProspectHitAccess = data['isProspectHitEnabled'];
      this.isProspectProfileAccess = data['isProspectProfileEnabled'];
    });
  }

  public RequestProspectProfileData() {
    if (!this.prospectProfileButtonDisable) {
      this.confirmationService.confirm({
        key: "ProspectProfileRequest",
        message: 'In the event of any green tick(s) below, full Prospect detail can be requested and this will be provided to the requestor via email within 2 working days.',
        header: 'Confirmation',
        icon: 'pi pi-check',
        accept: () => {
          if (this.prospectHitResultData) {
            this.prospectProfileLoading = true;
            this.prospectProfileResultData = null;
            this.searchservice.GetProspectProfileData(this.requestData, this.search, this.auditKey, this.userId)
              .subscribe(
                data => {
                  this.prospectProfileLoading = false;
                  this.prospectProfileResultData = data;
                  this.prospectProfileButtonText = this.prospectProfileButtonText_Requested;
                  this.prospectProfileButtonDisable = true;
                }, error => {
                  console.log(error);
                  this.prospectProfileLoading = false;
                  this.prospectProfileButtonDisable = true;
                }
              );
          }
        },
        reject: () => {
        }
      });

    }
  }

  public ProspectData() {
    if (this.accessData.isCounterFraudAccess) {
      this.prospectProfileButtonText = this.prospectProfileButtonText_RequestProfile;
      this.prospectloading = true;
      this.prospectSearchFail = false;
      this.prospectErrorMessage = null;
      this.prospectProfileResultData = null;
      if ((this.searchtext && this.contactData.dateOfBirth) || (this.contactData.postalCode && this.contactData.addressLine1) || this.phonenumber || this.contactData.email || this.contactData.vRN) {
        this.prospectHitResultData = null;
        this.searchservice.GetProspectHitData(this.requestData, this.search, this.auditKey, this.userId)
          .subscribe(
            data => {
              this.prospectloading = false;
              this.prospectSearchFail = false;
              this.prospectHitResultData = data;
              this.prospectProfileButtonDisable = this.prospectHitResultData.hits.filter(x => x.i2Hit == true).length > 0 ? false : true;
            },
            error => {
              console.log(error);
              this.prospectSearchFail = true;
              this.prospectHitResultData = null;
              this.prospectloading = false;
            }
          );
      } else {
        this.prospectloading = false;
        this.prospectSearchFail = false;
        this.prospectErrorMessage = this.OtherFieldRequired;
      }
    }
  }

  public CCJAndInsolvencyShowLoader() {
    if (this.accessData.isCCJDetailAccess || this.accessData.isInsolvencyDetailAccess) {
      this.ccjloading = false;
      this.CCJSearchFail = false;
      this.CCJErrorMessage = null;
      this.CCJResult = null;
      if (this.accessData.isFinancialAccess) {
        if (this.search && ((this.contactData.dateOfBirth || (this.contactData.postalCode && this.contactData.addressLine1))) || this.contactData.dateOfBirth) {
          this.ccjloading = true;
          this.CCJResult = null;
          this.CCJSearchFail = false;
        }
      } else {
        this.ccjloading = false;
        this.CCJSearchFail = false;
        this.CCJErrorMessage = this.OtherFieldRequired;
        this.CCJResult = null;
      }
    }
  }

  public InsolvencyDetail() {
    this.CategorySearchTable = false;
    this.SocialMediaCategorySearchTable = false;
    this.LifeStyleCategorySearchTable = false;
  }

  public ProspectDetail() {
    this.CategorySearchTable = false;
    this.SocialMediaCategorySearchTable = false;
    this.LifeStyleCategorySearchTable = false;
  }

  public CCJDetail() {
    this.CategorySearchTable = false;
    this.SocialMediaCategorySearchTable = false;
    this.LifeStyleCategorySearchTable = false;
  }

  public CCJAndInsolvencyDetailsCheck(data: any) {
    if (this.accessData.isFinancialAccess && (this.accessData.isCCJDetailAccess || this.accessData.isInsolvencyDetailAccess)) {
      if (this.search && ((this.contactData.postalCode || this.contactData.addressLine1)) && this.contactData.dateOfBirth) {
        this.ccjloading = true;
        this.CCJResult = null;
        this.CCJSearchFail = false;
        this.searchservice.SearchCCJAndInsolvency(this.search, this.auditKey, this.requestData, data).subscribe(
          data => {
            this.ccjloading = false;
            var json = JSON.parse(JSON.stringify(data));
            if (json.status == 200) {
              json.data.completeResponse = JSON.parse(json.data.completeResponse);
              this.CCJResult = json.data;
            } else {
              this.CCJResult = null;
              this.CCJSearchFail = true;
            }
          },
          error => {
            console.log(error);
            this.CCJSearchFail = true;
            this.CCJResult = null;
            this.ccjloading = false;
          }
        );
      }
      else {
        this.CCJErrorMessage = this.AdditionalDataMessage;
        this.OtherFieldRequired = this.AdditionalDataMessage;
      }
    }
  }

  public AML() {
    this.CategorySearchTable = false;
    this.SocialMediaCategorySearchTable = false;
    this.LifeStyleCategorySearchTable = false;
  }

  public AMLCheck() {
    if (this.accessData.isAMLAccess) {
      if (this.search) {
        this.amlloading = true;
        this.AMLSearchFail = false;
        this.searchservice.SearchAML(this.search, this.auditKey, this.requestData).subscribe(
          data => {
            this.amlloading = false;
            var json = JSON.parse(JSON.stringify(data))
            if (json.status == 200) {
              json.data.completeResponse = JSON.parse(json.data.completeResponse);
              this.AMLResult = json.data;
            } else {
              this.AMLResult = null;
              this.AMLSearchFail = true;
            }
          },
          error => {
            this.AMLResult = null;
            this.AMLSearchFail = true;
            this.amlloading = false;
            console.log(error);
          }
        );
      }
    }
  }

  public IsArray(obj: any) {
    return Array.isArray(obj);
  }

  public isValidAMLAddress(obj: any[]) {
    let addresses = obj.filter(address => {
      if ((address.address1 != null && address.address1 != '') || (address.address2 != null && address.address2 != '') || (address.address3 != null && address.address3 != '') || (address.postcode != null && address.postcode != '') || (address.city != null && address.city != '') || (address.country != null && address.country != '')) {
        return address;
      }
    });
    if (addresses.length > 0)
      return true;
    else
      return false;
  }

  public StringToDate(obj: string) {
    try {
      var dateArr = obj.split('-');
      var date = new Date(parseInt(dateArr[0]), parseInt(dateArr[1]), parseInt(dateArr[2]));
      return date;
    } catch { }
  }

  public CriminalityCheck() {
    //TestingSingle
    if (this.accessData.isCriminalityAccess) {
      this.lexiconStatus =
        [
          { name: "Risk", status: "Searching . . .", searchloading: true, searchStatusCount: 0, analysisStatusCount: 0 },
          { name: "Theft", status: "Searching . . .", searchloading: true, searchStatusCount: 0, analysisStatusCount: 0 },
          { name: "Fraud", status: "Searching . . .", searchloading: true, searchStatusCount: 0, analysisStatusCount: 0 },
          { name: "Driving", status: "Searching . . .", searchloading: true, searchStatusCount: 0, analysisStatusCount: 0 },
          { name: "Drugs", status: "Searching . . .", searchloading: true, searchStatusCount: 0, analysisStatusCount: 0 },
          { name: "Violence", status: "Searching . . .", searchloading: true, searchStatusCount: 0, analysisStatusCount: 0 },
          { name: "Crime", status: "Searching . . .", searchloading: true, searchStatusCount: 0, analysisStatusCount: 0 },
          { name: "Offenders", status: "Searching . . .", searchloading: true, searchStatusCount: 0, analysisStatusCount: 0 },
          { name: "Officials", status: "Searching . . .", searchloading: true, searchStatusCount: 0, analysisStatusCount: 0 },
        ];
      this.comparelexicons = [];
      this.rankingresults = [];
      this.results = [];
      this.sortfinalResults = [];
      this.relevantResults = [];
      this.finalresults = [];
      this.lowRankingResults = [];
      this.resultcount = 0;
      this.displaylexiconKey = false;

      // --------------------------- Shuffle lexicon --------------------
      this.lexicons = this.shuffleLexicons(this.lexicons);

      // --------------------------- Each lexicon request --------------------
      this.CategorySearchTable = true;
      this.noRelevantDataCriminality = true;
      this.dataloading = true;
      this.lexicons.forEach((item: string) => {
        let searchIndex = 0;
        this.activeSearchDomains.forEach((searchDomain: string) => {
          this.getData(item, searchIndex, searchDomain);
        });
      });
    } else {
      this.noRelevantDataCriminality = false;
    }
  }

  public CheckFollowing() {
    this.searchservice.CheckFollowing(this.search, this.userId)
      .subscribe(
        data => {
          this.followStatus = data;
          if (this.followStatus) {
            this.follow = "Following";
            this.followDisplay = true;
          }
          else {
            this.follow = "Follow Search";
            this.followDisplay = true;
          }
        },
        error => {
          console.log(error);
        });
  }

  public CounterFraudCheck() {
    if (this.accessData.isCounterFraudAccess && !this.counterfraudValidationMessage) {
      //this.noRelevantDataCounterFraud = true;
      this.insurerloading = true;
      this.searchservice.GetCounterFraudData(this.requestData, this.search, this.auditKey, this.userId)
        .subscribe(
          data => {
            this.counterFraudData = JSON.parse(JSON.stringify(data));
            this.counterfraudSearchFail = false;
            //this.needleValue = 17;
            //this.centralLabel = '';
            this.insurerloading = false;
            //console.log(this.counterFraudData);
            if (this.counterFraudData.data && this.counterFraudData.status === 200 && this.counterFraudData.data.ragStatus) {
              this.noRelevantDataCounterFraud = false;
              const color = this.counterFraudData.data.ragStatus;
              if (color.toLowerCase() === 'green') {
                this.needleValue = 17;
                this.centralLabel = '';
              }
              if (color.toLowerCase() === 'amber') {
                this.needleValue = 50;
                this.centralLabel = '';
              }
              if (color.toLowerCase() === 'red') {
                this.needleValue = 85;
                this.centralLabel = '';
                //this.centralLabel = 'Red Level 0';
                //try {
                //  this.needleValue = 66.6 + (5.55 * this.counterFraudData.ProspectData.ProspectJSON.redLevel);
                //  //this.centralLabel = 'Red Level ' + this.userContactData.ProspectData.ProspectJSON.redLevel;
                //} catch { }
              }
            } else {
              this.noRelevantDataCounterFraud = true;
            }
          }, error => {
            if (error.status === 403) {
              this.counterfraudSearchFail = true;
            }
            //this.needleValue = 17;
            //this.centralLabel = '';
            this.insurerloading = false;
          });
    } else {
      this.noRelevantDataCounterFraud = false;
    }
  }

  public TelisignCheck() {
    if (this.accessData.isMobileAccess) {
      if (this.phonenumber) {
        this.contactloading = true;
        this.searchservice.GetMobileData(this.requestData, this.search, this.auditKey, this.userId)
          .subscribe(
            data => {
              this.userContactData = JSON.parse(JSON.stringify(data));
              this.mobileSearchFail = false;
              this.contactloading = false;
              this.noErrorPhone = false;
            }, error => {
              if (error.status === 403) {
                this.mobileSearchFail = true;
              }
              this.contactloading = false;
            });
      }
      else {
        this.mobilevalidationerror = this.mobileRequiredMessage;
      }
    } else {
      this.noErrorPhone = true;
      this.noRelevantDataMobile = false;
    }
  }

  public DirectorShipCheck() {
    if (this.accessData.isCompaniesHouseAccess) {
      this.directorshiploading = true;
      this.isShowDirectorshipDetail = true;
      this.DirectorShipErrorMessage = '';
      this.noRelevantDataDirectorship = true;
      this.searchservice.DirectorshipSearchData(this.requestData, this.search, this.auditKey, this.userId)
        .subscribe(
          data => {
            this.userDirectorshipData = data;
            if (!this.userDirectorshipData || this.userDirectorshipData.length <= 0) {
              this.DirectorShipErrorMessage = this.NoRelevantDataMessage;
            }
            this.directorshipSearchFail = false;
            this.directorshiploading = false;
          }, error => {
            if (error.status === 403) {
              this.directorshipSearchFail = true;
            } else {
              this.DirectorShipErrorMessage = this.NoRelevantDataMessage;
            }
            this.directorshiploading = false;
          });
    } else {
      this.noRelevantDataDirectorship = false;
    }
  }

  public EmailDomainCheck() {
    if (this.accessData.isEmailAccess) {
      this.isShowEmail = true;
      this.noErrorEmail = false;
      this.noRelevantDataEmail = null;
      if (this.contactData.email) {
        this.emailValidationMessage = null;
        this.emailloading = true;
        this.searchservice.EmailDomainCheck(this.search, this.userId, this.auditKey, this.requestData).subscribe(data => {
          this.emailloading = false;
          this.emailSearchFail = false;
          this.emailData = data;
          if (this.emailData.data !== null) {
            this.noErrorEmail = true;
          } else {
            this.noErrorEmail = false;
            this.noRelevantDataEmail = true;
          }
        },
          error => {
            if (error.status === 403) {
              this.emailSearchFail = true;
            }
            this.noErrorEmail = false;
            this.emailloading = false
          });
      } else {
        this.emailloading = false;
        this.emailValidationMessage = this.emailRequiredMessage;
        this.noRelevantDataEmail = false;
      }
    } else {
      this.emailloading = false;
      this.noRelevantDataEmail = false;
      this.noErrorEmail = false;
      this.emailValidationMessage = null;
    }
  }

  public shuffleLexicons(array) {
    var tmp, current, top = array.length;
    if (top) while (--top) {
      current = Math.floor(Math.random() * (top + 1));
      tmp = array[current];
      array[current] = array[top];
      array[top] = tmp;
    }
    return array;
  }

  public getData(lexiconKey, searchIndex, searchDomain) {
    this.searchservice.SearchData(this.search, lexiconKey, this.userId, searchDomain, this.auditKey, this.requestData)
      .subscribe(
        data => {
          if (data["id"] != null) {
            this.searchUniqueIdlist.push(data["id"]);
            //console.log(searchDomain + lexiconKey);
            //this.searchUniqueIdlist.forEach(item => {
            //  console.log(item);
            //});
            let AnalysisIndex = 0;
            this.analyseResult(this.searchUniqueIdlist, lexiconKey, this.search, AnalysisIndex, searchDomain, this.devData);
          }
          else {
            let AnalysisIndex = 0;
            this.analyseResult(this.searchUniqueIdlist, lexiconKey, this.search, AnalysisIndex, searchDomain, this.devData);
          }
        },
        error => {
          console.log(error);
          if (error.status == 401 && error.headers.has('Token-Expired')) {
            this.refreshtoken.token = localStorage.getItem("access_token");
            this.searchservice.refreshtoken(this.refreshtoken)
              .subscribe(
                data => {
                  localStorage.setItem("access_token", data["access_token"]);
                  localStorage.setItem("expires_in", Shared.getExpiryDate(Shared.getAccessTokenExpiryDateTime(data["access_token"]).toUTCString()));
                  localStorage.setItem("token_type", data["token_type"]);
                  this.getData(lexiconKey, searchIndex, searchDomain);
                },
                error => {
                  if (error.status == 400) {
                    this.logout();
                  }
                });
          }
          else {
            searchIndex++;
            if (searchIndex < 2) {
              this.getData(lexiconKey, searchIndex, searchDomain);
            }
            else {
              let AnalysisIndex = 0;
              this.analyseResult(this.searchUniqueIdlist, lexiconKey, this.search, AnalysisIndex, searchDomain, this.devData);
            }
          }
        });
  }

  public analyseResult(searchUniqueIdlist, lexiconCategory, searchvalue, AnalysisIndex, searchDomain, devData) {
    this.filterLexiconStatus = this.lexiconStatus.filter(x => x.name == lexiconCategory)[0];
    this.filterLexiconStatus.searchStatusCount++;
    if (this.filterLexiconStatus.searchStatusCount == this.activeSearchDomains.length) {
      this.filterLexiconStatus.status = "Searching done. Analysing results . . .";
    }

    if (searchUniqueIdlist.length == 0) {
      this.AnalyseError(lexiconCategory);
    }
    else {
      this.searchservice.analyseResultRanking(searchUniqueIdlist, lexiconCategory, searchvalue, searchDomain, devData, this.auditKey, this.requestData, this.userId)
        .subscribe(
          data => {
            this.domainResults = [];
            this.domainResults = data;

            if (this.domainResults.length > 0) {
              //console.log("Domain : " + searchDomain + ", Lexicon: " + lexiconCategory + ", Count: " + data.length);

              this.domainResults.forEach((result: Items) => {
                result.searchDomain = searchDomain
              });
            }

            this.rankingresults = this.rankingresults.concat(this.domainResults);
            this.filterLexiconStatus = this.lexiconStatus.filter(x => x.name == lexiconCategory)[0];
            this.filterLexiconStatus.analysisStatusCount++;

            if (this.filterLexiconStatus.analysisStatusCount == this.activeSearchDomains.length) {
              this.filterLexiconStatus.status = "Searching done. Analysis Complete.";
              this.criminalitySuccessCount++;
              this.filterLexiconStatus.searchloading = false;
              this.comparelexicons.push(lexiconCategory);
              this.rankingDuplicateLinks();
            }
            if (this.criminalitySuccessCount === this.lexicons.length) {
              this.searchservice.updateAudit(this.auditKey, 11, this.comparelexicons).subscribe();
              this.RelevantResults();
            }
          },
          error => {
            console.log(error);
            if (error.status == 502 || error.status == 504) {
              AnalysisIndex++;
              if (AnalysisIndex < 2) {
                this.analyseResult(searchUniqueIdlist, lexiconCategory, searchvalue, AnalysisIndex, searchDomain, devData);
              }
              else {
                this.AnalyseError(lexiconCategory);
              }
            }
            else {
              this.AnalyseError(lexiconCategory);
            }
          });
    }
  }

  public AnalyseError(lexiconCategory) {
    this.filterLexiconStatus = this.lexiconStatus.filter(x => x.name == lexiconCategory)[0];
    this.filterLexiconStatus.analysisStatusCount++;

    if (this.filterLexiconStatus.analysisStatusCount == this.activeSearchDomains.length) {
      this.filterLexiconStatus.status = "Searching done. Analysis Complete.";
      this.criminalitySuccessCount++;
      this.filterLexiconStatus.analysisStatusCount++;
      this.filterLexiconStatus.searchloading = false;
      this.comparelexicons.push(lexiconCategory);
      this.rankingDuplicateLinks();
    }
    if (this.criminalitySuccessCount === this.lexicons.length) {
      this.searchservice.updateAudit(this.auditKey, 11, this.comparelexicons).subscribe();
      this.RelevantResults();
    }
  }

  public rankingDuplicateLinks() {
    if (this.lexicons.length == this.comparelexicons.length) {
      this.collapseResultClass = "hide";
      this.collapseResultStatus = "Show Status";
      this.collapseResultIcon = "fa-chevron-down";
      this.resultComplete = true;

      this.rankingresults = this.rankingresults.sort((a, b) => b.rank - a.rank);
      this.sortfinalResults = this.getUniqueResults(this.rankingresults, 'link');
      this.sortfinalResults = this.sortfinalResults.filter((item: any) => item.rank != 0);

      //this.rankingresults.forEach(result => {
      //  console.log("Rank: " + result.rank + ", Link: " + result.link);
      //});

      this.sortfinalResults.forEach(result => {
        this.searchDomains.forEach((item: SearchDomain) => {
          if (item.UniqueCode == result.searchDomain) {
            result.rank += item.RankValue;
          }
        });
      });

      this.sortfinalResults.forEach((finalItem: Items) => {
        this.rankingresults.forEach((item: Items) => {
          try {
            if ((item.link.indexOf(finalItem.link) >= 0) && !(finalItem.lexiconcategory.includes(item.lexiconcategory[0]))) {
              finalItem.lexiconcategory.push(item.lexiconcategory[0]);
              finalItem.rank += 100;
            }
          } catch { }
        });
      });

      this.sortfinalResults = this.sortfinalResults.sort((a, b) => b.rank - a.rank);
      // this.sortfinalResults = this.sortfinalResults.slice(0,10);
      this.sortfinalResults.forEach((lrItem: any) => {
        if (lrItem.rank >= this.criminalityRelevantRank) {
          this.relevantResults.push(lrItem);
        }
      });

      this.showAllResults = true;
      this.IsResultValues = true;

      if (this.crimeSearchResultCount > 0) {
        this.finalresults = this.relevantResults.slice(0, this.crimeSearchResultCount);
        if (this.finalresults.length < this.crimeSearchResultCount) {
          for (var i = 0; i < this.sortfinalResults.length; i++) {
            if (!this.finalresults.some(x => x.link == this.sortfinalResults[i].link)) {
              this.finalresults.push(this.sortfinalResults[i]);
            }
            if (this.finalresults.length == this.crimeSearchResultCount)
              break;
          }
        }
      } else {
        this.finalresults = this.relevantResults;
      }

      this.resultcount = this.finalresults.length;

      if (this.subscription == "Pro") {
        this.IsLifeStyleValue = true;
        this.IsSocialMediaValue = true;
        this.IsAdvertValue = true;
      }
      else {
        this.IsLifeStyleValue = false;
        this.IsSocialMediaValue = false;
        this.IsAdvertValue = false;
      }

      this.dataloading = false;
      this.displaylexiconKey = false;
      this.searchComplete = true;
      setTimeout(() => {
        this.searchComplete = false;
      }, 5000);

      //this.searchservice.AuditUserSearchResults(this.search, this.userId)
      //  .subscribe(
      //    data => { }, error => {
      //      console.log(error.message);
      //    });
    }
  }

  //public ShowMore() {
  //  this.showMoreResults = false;
  //  if(this.isShowResult) {
  //    this.finalresults = this.sortfinalResults;
  //  } else {
  //    this.finalresults = this.relevantResults;
  //  }
  //}

  public AllResults() {
    //this.showMoreResults = false;
    //this.isShowResult = true;
    this.showAllResults = false;
    this.showRelevantResults = true;
    this.finalresults = this.sortfinalResults;
    this.resultcount = this.finalresults.length;
  }

  public RelevantResults() {
    //this.showMoreResults = true;
    //this.isShowResult = false;
    this.showAllResults = true;
    this.showRelevantResults = false;
    if (this.crimeSearchResultCount > 0) {
      this.finalresults = this.relevantResults.slice(0, this.crimeSearchResultCount);
      if (this.finalresults.length < this.crimeSearchResultCount) {
        for (var i = 0; i < this.sortfinalResults.length; i++) {
          if (!this.finalresults.some(x => x.link == this.sortfinalResults[i].link)) {
            this.finalresults.push(this.sortfinalResults[i]);
          }
          if (this.finalresults.length == this.crimeSearchResultCount)
            break;
        }
      }
    } else {
      this.finalresults = this.relevantResults;
    }
    this.resultcount = this.finalresults.length;
  }

  public getUniqueResults(arr, link) {
    const unique = arr
      .map(e => e[link])
      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the dead keys & store unique objects
      .filter(e => arr[e]).map(e => arr[e]);
    return unique;
  }

  public Follow() {
    this.followStatus = !this.followStatus;
    if (this.followStatus) {
      this.follow = "Following";
    }
    else {
      this.follow = "Follow Search";
    }

    this.searchservice.FollowSearchKeyword(this.search, this.userId, this.followStatus)
      .subscribe(
        data => {
        });
  }

  public onChange() {
    this.followDisplay = false;
  }

  onPhoneChange(val: string): void {
    //if (this.accessData.isMobileAccess) {
    //  if (val) {
    //    this.mobilevalidationerror = null;
    //  } else {
    //    this.mobilevalidationerror = this.MobileValidationMessage;
    //  }
    //} else {
    //  this.mobilevalidationerror = null;
    //}
    this.phonenumber = val.replace(/[^0-9+]/g, "");
  }

  onVRNChange(val: string): void {
    this.isValidVRN = true;
    this.vrnvalidationmessage = false;
    // this.validationerror = "";
    if (val && val.match(/^(([A-Za-z]{1,2}[ ]?[0-9]{1,4})|([A-Za-z]{3}[ ]?[0-9]{1,3})|([0-9]{1,3}[ ]?[A-Za-z]{3})|([0-9]{1,4}[ ]?[A-Za-z]{1,2})|([A-Za-z]{3}[ ]?[0-9]{1,3}[ ]?[A-Za-z])|([A-Za-z][ ]?[0-9]{1,3}[ ]?[A-Za-z]{3})|([A-Za-z]{2}[ ]?[0-9]{2}[ ]?[A-Za-z]{3})|([A-Za-z]{3}[ ]?[0-9]{4})|([0-9]{2,3}-{1}[A-Z]{1,2}-?[0-9]{1,6}))$/) === null) {
      this.vrnvalidationmessage = false;
      this.isValidVRN = false;
    } else {
      this.vrnvalidationmessage = false;
    }
    if (this.accessData.isAVAAccess && (!this.accessData.isHUTDatabaseAccess &&
      !this.accessData.isMobileAccess && !this.accessData.isIdentityAccess &&
      !this.accessData.isFinancialAccess && !this.accessData.isAddressAccess &&
      !this.accessData.isCriminalityAccess && !this.accessData.isSocialMediaAccess &&
      !this.accessData.isSportsAccess && !this.accessData.isCounterFraudAccess &&
      !this.accessData.isCompaniesHouseAccess && !this.accessData.isEmailAccess &&
      !this.accessData.isAdvertsAccess && !this.accessData.isAMLAccess &&
      !this.accessData.isCCJDetailAccess && !this.accessData.isInsolvencyDetailAccess)) {
      if (val === undefined || val.length === 0) {
        this.vrnvalidationmessage = true;
        this.isValidVRN = false;
      }
    }
    //console.log(this.isValidVRN);
  }


  onNameChange(val: string): void {
    this.validateBasedOnToggle();
  }

  onPostCodeChange(val: string) {
    //if (this.accessData.isHUTDatabaseAccess || this.accessData.isMobileAccess || this.accessData.isEmailAccess || this.accessData.isIdentityAccess || this.accessData.isFinancialAccess || this.accessData.isAddressAccess || this.accessData.isCriminalityAccess || this.accessData.isSocialMediaAccess || this.accessData.isCounterFraudAccess || this.accessData.isSportsAccess || this.accessData.isAdvertsAccess || this.accessData.isCompaniesHouseAccess) {
    //  if (!this.contactData.postalCode) {
    //    this.postvalidationmessage = "Post code is required.";
    //  }
    //  else {
    //    this.postvalidationmessage = null;
    //    //this.dobvalidationmessage = null;
    //  }
    //}
    this.validateBasedOnToggle();
  }

  public rtwNoFormsDisable() {
    if (!this.accessData.isRTWAccess) {
      this.accessData.isRTWNoFormsAccess = false;
    }
  }

  validateBasedOnToggle() {
    this.validationerror = false;
    this.postvalidationmessage = false;
    this.vrnvalidationmessage = false;
    this.referenceError = false;
    let errorStatus = true;

    this.rtwNoFormsDisable();

    if (!this.accessData.isHUTDatabaseAccess &&
      !this.accessData.isMobileAccess && !this.accessData.isIdentityAccess &&
      !this.accessData.isFinancialAccess && !this.accessData.isAddressAccess &&
      !this.accessData.isCriminalityAccess && !this.accessData.isSocialMediaAccess &&
      !this.accessData.isSportsAccess && !this.accessData.isCounterFraudAccess &&
      !this.accessData.isCompaniesHouseAccess && !this.accessData.isEmailAccess &&
      !this.accessData.isAdvertsAccess && !this.accessData.isAVAAccess &&
      !this.accessData.isAMLAccess && !this.accessData.isCCJDetailAccess &&
      !this.accessData.isInsolvencyDetailAccess && !this.accessData.isRTWAccess &&
      !this.accessData.isIDCheckAccess) {
      this.validationerror = false;
      this.postvalidationmessage = false;
      this.vrnvalidationmessage = false;
      this.referenceError = false;
      errorStatus = false;
      return errorStatus;
    }
    if (this.isReferenceRequired) {
      if (this.accessData.isHUTDatabaseAccess ||
        this.accessData.isMobileAccess || this.accessData.isIdentityAccess ||
        this.accessData.isFinancialAccess || this.accessData.isAddressAccess ||
        this.accessData.isCriminalityAccess || this.accessData.isSocialMediaAccess ||
        this.accessData.isSportsAccess || this.accessData.isCounterFraudAccess ||
        this.accessData.isCompaniesHouseAccess || this.accessData.isEmailAccess ||
        this.accessData.isAdvertsAccess || this.accessData.isAVAAccess ||
        this.accessData.isAMLAccess || this.accessData.isCCJDetailAccess ||
        this.accessData.isInsolvencyDetailAccess || this.accessData.isRTWAccess ||
        this.accessData.isIDCheckAccess) {
        if (!this.contactData.referenceNumber) {
          this.referenceError = true;
          errorStatus = false;
        }
      }
    }
    if (this.isNameRequired) {
      if (this.accessData.isAVAAccess && (this.accessData.isHUTDatabaseAccess ||
        this.accessData.isMobileAccess || this.accessData.isIdentityAccess ||
        this.accessData.isFinancialAccess || this.accessData.isAddressAccess ||
        this.accessData.isCriminalityAccess || this.accessData.isSocialMediaAccess ||
        this.accessData.isSportsAccess || this.accessData.isCounterFraudAccess ||
        this.accessData.isCompaniesHouseAccess || this.accessData.isEmailAccess ||
        this.accessData.isAdvertsAccess || this.accessData.isAMLAccess ||
        this.accessData.isCCJDetailAccess || this.accessData.isInsolvencyDetailAccess
        || this.accessData.isRTWAccess || this.accessData.isIDCheckAccess)) {
        if (!this.searchtext) {
          this.validationerror = true;
          errorStatus = false;
        }
      } else if (this.accessData.isHUTDatabaseAccess ||
        this.accessData.isMobileAccess || this.accessData.isIdentityAccess ||
        this.accessData.isFinancialAccess || this.accessData.isAddressAccess ||
        this.accessData.isCriminalityAccess || this.accessData.isSocialMediaAccess ||
        this.accessData.isSportsAccess || this.accessData.isCounterFraudAccess ||
        this.accessData.isCompaniesHouseAccess || this.accessData.isEmailAccess ||
        this.accessData.isAdvertsAccess || this.accessData.isAMLAccess ||
        this.accessData.isCCJDetailAccess || this.accessData.isInsolvencyDetailAccess ||
        this.accessData.isRTWAccess || this.accessData.isIDCheckAccess) {
        if (!this.searchtext) {
          this.validationerror = true;
          errorStatus = false;
        }
      }
    }
    if (this.isPostCodeRequired) {
      if (this.accessData.isAVAAccess && (this.accessData.isHUTDatabaseAccess ||
        this.accessData.isMobileAccess || this.accessData.isIdentityAccess ||
        this.accessData.isFinancialAccess || this.accessData.isAddressAccess ||
        this.accessData.isCriminalityAccess || this.accessData.isSocialMediaAccess ||
        this.accessData.isSportsAccess || this.accessData.isCounterFraudAccess ||
        this.accessData.isCompaniesHouseAccess || this.accessData.isEmailAccess ||
        this.accessData.isAdvertsAccess || this.accessData.isAMLAccess ||
        this.accessData.isCCJDetailAccess || this.accessData.isInsolvencyDetailAccess ||
        this.accessData.isRTWAccess || this.accessData.isIDCheckAccess)) {
        if (this.contactData.postalCode === undefined || this.contactData.postalCode === '') {
          this.postvalidationmessage = true;
          errorStatus = false;
        }
      } else if (this.accessData.isHUTDatabaseAccess ||
        this.accessData.isMobileAccess || this.accessData.isIdentityAccess ||
        this.accessData.isFinancialAccess || this.accessData.isAddressAccess ||
        this.accessData.isCriminalityAccess || this.accessData.isSocialMediaAccess ||
        this.accessData.isSportsAccess || this.accessData.isCounterFraudAccess ||
        this.accessData.isCompaniesHouseAccess || this.accessData.isEmailAccess ||
        this.accessData.isAdvertsAccess || this.accessData.isAMLAccess ||
        this.accessData.isCCJDetailAccess || this.accessData.isInsolvencyDetailAccess ||
        this.accessData.isRTWAccess || this.accessData.isIDCheckAccess) {
        if (this.contactData.postalCode === undefined || this.contactData.postalCode === '') {
          this.postvalidationmessage = true;
          errorStatus = false;
        }
      }
    }
    if (this.isVRNRequired) {
      if (this.accessData.isAVAAccess && (!this.accessData.isHUTDatabaseAccess &&
        !this.accessData.isMobileAccess && !this.accessData.isIdentityAccess &&
        !this.accessData.isFinancialAccess && !this.accessData.isAddressAccess &&
        !this.accessData.isCriminalityAccess && !this.accessData.isSocialMediaAccess &&
        !this.accessData.isSportsAccess && !this.accessData.isCounterFraudAccess &&
        !this.accessData.isCompaniesHouseAccess && !this.accessData.isEmailAccess &&
        !this.accessData.isAdvertsAccess && !this.accessData.isAMLAccess &&
        !this.accessData.isCCJDetailAccess && !this.accessData.isInsolvencyDetailAccess &&
        !this.accessData.isRTWAccess && !this.accessData.isIDCheckAccess)) {
        if (this.contactData.vRN === undefined || this.contactData.vRN === '') {
          this.vrnvalidationmessage = true;
          errorStatus = false;
        }
      }
    }
    if (!errorStatus) {
      return errorStatus;
    }
    errorStatus = true;
    return errorStatus;
  }

  referenceKeyPress(event) {
    var inp = String.fromCharCode(event.keyCode);
    if (/[a-zA-Z0-9/-]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
  onReferenceChange() {
    this.validateBasedOnToggle();
  }

  //onDOBChange() {
  //  console.log(this.contactData.dateOfBirth);
  //  if (!this.contactData.dateOfBirth) {
  //    this.dobvalidationmessage = "Invalid DOB.";
  //  } else {
  //    this.dobvalidationmessage = null;
  //    this.postvalidationmessage = null;
  //  }
  //  this.validateBasedOnCheckBox();
  //}

  onVRNCheckboxChange(checked: boolean) {
    if (checked) {
      this.onVRNChange(this.contactData.vRN);
      //console.log(this.contactData.vRN);
    } else {
      this.vrnvalidationmessage = null;
    }
  }

  // mobileKeyPress(event) {
  //   var inp = String.fromCharCode(event.keyCode);
  //   if (/[0-9]/.test(inp)) {
  //     return true;
  //   } else {
  //     event.preventDefault();
  //     return false;
  //   }
  // }

  //public AddAssociates(values: any) {
  //  if (values.currentTarget.checked) {
  //    for (let i = 0; i < 4; i++) {
  //      this.associatedSearchUsers.push(this.newAssociatedUser);
  //      this.newAssociatedUser = {};
  //      this.addAssociationNormalView = false;
  //      this.addAssociationTextboxView = true;
  //    }
  //  }
  //  else {
  //    this.addAssociationNormalView = true;
  //    this.addAssociationTextboxView = false;
  //    this.associatedSearchUsers = [];
  //  }
  //}

  public associatedSearch() {
    // ------------- Associated Values ----------------------------------
    this.Associatedresults = [];
    this.Associatedresultsdisplay = [];
    this.AssociatedresultCount = 0;
    this.associatedStatusCount = 0;
    this.associatedFail = false;
    if (this.tempAccessData.isCriminalityAccess) {
      this.associationloading = true;
      this.validAssociatedSearchUsers = this.associatedSearchUsers.filter(x => JSON.stringify(x) != JSON.stringify({}) && x.name.trim() != "");
      this.requestData.profileData.associatedNames = this.validAssociatedSearchUsers;
      if (this.validAssociatedSearchUsers.length != 0) {
        this.associatedDataNotProvide = false;
        this.activeSearchDomains.forEach((searchDomain: string) => {
          let associatedIndex = 0;
          this.EachDomainAssociatedSearch(this.search, this.validAssociatedSearchUsers, searchDomain, associatedIndex);
        });
      } else {
        this.associationloading = false;
        this.associatedDataNotProvide = true;
      }
    }
  }

  public EachDomainAssociatedSearch(search, validAssociatedSearchUsers, searchDomain, associatedIndex) {
    this.searchservice.AssociatedSearchData(search, searchDomain, this.requestData)
      .subscribe(
        data => {
          this.associatedFail = false;
          if (data["newsitems"] != null) {
            this.Associatedresults = this.Associatedresults.concat(data["newsitems"]);
          }
          this.associatedStatusCount++;

          if (this.associatedStatusCount == this.activeSearchDomains.length) {
            this.associationloading = false;
            if (this.Associatedresults.length > 0) {
              this.Associatedresults = this.getUniqueResults(this.Associatedresults, 'link');
            }
            this.AssociatedresultCount = this.Associatedresults.length;
          }
          this.Associatedresultsdisplay = this.Associatedresults;
        },
        error => {
          console.log(error);
          if (error.status == 401 && error.headers.has('Token-Expired')) {
            this.refreshtoken.token = localStorage.getItem("access_token");
            this.searchservice.refreshtoken(this.refreshtoken)
              .subscribe(
                data => {
                  localStorage.setItem("access_token", data["access_token"]);
                  localStorage.setItem("expires_in", Shared.getExpiryDate(Shared.getAccessTokenExpiryDateTime(data["access_token"]).toUTCString()));
                  localStorage.setItem("token_type", data["token_type"]);
                  this.associatedSearch();
                },
                error => {
                  if (error.status == 400) {
                    this.logout();
                  }
                });
          }
          else if (error.status == 502 || error.status == 504) {
            associatedIndex++;
            if (associatedIndex < 2) {
              this.EachDomainAssociatedSearch(search, validAssociatedSearchUsers, searchDomain, associatedIndex);
            }
            else {
              this.associationloading = false;
              this.associatedFail = true;
            }
          }
          else {
            this.associationloading = false;
            this.associatedFail = true;
          }
        });
  }

  public MainResults() {
    if (this.accessData.isCriminalityAccess) {
      this.CategorySearchTable = true;
      this.noRelevantDataCriminality = true;
      this.SocialMediaCategorySearchTable = false;
      this.LifeStyleCategorySearchTable = false;

      if (this.resultComplete) {
        this.collapseResultClass = "hide";
        this.collapseResultStatus = "Show Status";
        this.collapseResultIcon = "fa-chevron-down";
      }
    }
    else {
      this.CategorySearchTable = false;
      this.SocialMediaCategorySearchTable = false;
      this.LifeStyleCategorySearchTable = false;
      this.noRelevantDataCriminality = false;
    }
  }

  public LifeStyle() {
    if (this.accessData.isSportsAccess) {
      this.CategorySearchTable = false;
      this.SocialMediaCategorySearchTable = false;
      this.LifeStyleCategorySearchTable = true;

      if (this.lifetStyleComplete) {
        this.collapseLifeStyleClass = "hide";
        this.collapseLifeStyleStatus = "Show Status";
        this.collapseLifeStyleIcon = "fa-chevron-down";
      }
    }
    else {
      this.CategorySearchTable = false;
      this.SocialMediaCategorySearchTable = false;
      this.LifeStyleCategorySearchTable = false;
    }
  }

  public SocialMedia() {
    if (this.accessData.isSocialMediaAccess) {
      this.CategorySearchTable = false;
      this.SocialMediaCategorySearchTable = true;
      this.LifeStyleCategorySearchTable = false;

      if (this.SocialMediaComplete) {
        this.collapseSocialMediaClass = "hide";
        this.collapseSocialMediaStatus = "Show Status";
        this.collapseSocialMediaIcon = "fa-chevron-down";
      }
    }
    else {
      this.CategorySearchTable = false;
      this.SocialMediaCategorySearchTable = false;
      this.LifeStyleCategorySearchTable = false;
    }
  }

  public Associations() {
    this.CategorySearchTable = false;
    this.SocialMediaCategorySearchTable = false;
    this.LifeStyleCategorySearchTable = false;
  }

  public LifeStyleCheck() {
    // ------------- Life Style Values ----------------------------------
    //this.lifestyle = true;
    this.LifeStyleResultCount = 0;
    this.IsLifeStyleValue = false;
    this.finalLifeStyleResults = [];
    this.displaylifeStyleCount = false;

    if (this.accessData.isSportsAccess) {
      this.noRelevantDataLifeStyle = true;
      this.search = this.search.trim();
      if (this.search === "") {
        //this.validationerror = "Please enter the name to search.";
        //setTimeout(() => {
        //  this.validationerror = "";
        //}, 2000);
        //return false;
      }
      else {
        this.lifeStyleSearch();
      }
    }
    else {
      this.noRelevantDataLifeStyle = false;
      this.LifeStyleCategorySearchTable = false;
    }
  }

  public Advert() {
    this.CategorySearchTable = false;
    this.LifeStyleCategorySearchTable = false;
    this.SocialMediaCategorySearchTable = false;
  }

  public AdvertCheck() {
    //----------------Advert Values----------------------------------------
    this.advertResults = [];
    this.AdvertResultCount = 0;
    this.IsAdvertValue = false;
    this.advertloading = false;
    this.AdvertValidationMessage = "";

    if (this.accessData.isAdvertsAccess) {
      this.AdvertValidationMessage = "";
      if (!this.phonenumber) {
        this.noRelevantDataAdvert = true;
        this.AdvertValidationMessage = this.mobileRequiredMessage;
        this.advertloading = false;
        return false;
      }

      this.noRelevantDataAdvert = true;
      const phn = this.phonenumber.substr(this.phonenumber.length - 10)
      //this.contactData.phoneNumber = '+44' + phn;
      this.advertloading = true;
      this.GetAdvertSearch(phn);

    } else {
      this.noRelevantDataAdvert = false;
    }
  }

  public Directorship() {
    this.CategorySearchTable = false;
    this.LifeStyleCategorySearchTable = false;
    this.SocialMediaCategorySearchTable = false;
    if (this.accessData.isCompaniesHouseAccess) { }
  }

  public SocialMediaCheck() {
    // ------------- Social Media Values ----------------------------------
    //this.socialmedia = true;
    this.IsSocialMediaValue = false;
    this.SocialMediaResultCount = 0;
    this.finalSocialMediaResults = [];
    this.displaySocialMediaCount = false;
    this.searchUniqueIdlist = [];

    if (this.accessData.isSocialMediaAccess) {
      this.noRelevantDataSocialMedia = true;
      this.search = this.search.trim();
      if (this.search === "") {
        //this.validationerror = "Please enter the name to search.";
        //setTimeout(() => {
        //  this.validationerror = "";
        //}, 2000);
        //return false;
      }
      else {
        this.socialMediaSearch();
      }
    }
    else {
      this.noRelevantDataSocialMedia = false;
    }
  }

  public Address() {
    this.CategorySearchTable = false;
    this.SocialMediaCategorySearchTable = false;
    this.LifeStyleCategorySearchTable = false;
    if (this.accessData.isAddressAccess) {
      if (this.contactData.postalCode) {
        this.noRelevantDataAddress = true;
        this.addressValidation = false;
      } else {
        this.noRelevantDataAddress = false;
        this.addressValidation = true;
      }
    }
    else {
      this.noRelevantDataAddress = false;
    }
  }

  public AVA() {
    this.CategorySearchTable = false;
    this.SocialMediaCategorySearchTable = false;
    this.LifeStyleCategorySearchTable = false;
  }

  public AVACheck() {
    this.avaErrorMessage = null;
    if (true) {
      this.avaResult = null;
      if (this.accessData.isAVAAccess) {
        if (this.contactData.vRN) {
          //this.avaRunning = false;
          this.avaloading = true;
          this.searchservice.SearchAVA(this.search, this.userId, this.auditKey, this.requestData).subscribe(data => {
            this.avaResult = data;
            this.avaResult.mot = JSON.parse(this.avaResult.mot);
            this.avaResult.vehicleData = JSON.parse(this.avaResult.vehicleData);
            this.avaSearchFail = false;
            if (!data) {
              this.noRelevantDataAVA = true;
            }
            this.avaloading = false;
          }, error => {
            if (error.status === 403 || error.status === 500) {
              this.avaSearchFail = true;
            }
            this.avaResult = null;
            this.avaloading = false;
            console.log(error)
          });
        } else {
          this.avaErrorMessage = this.vrnRequiredMessage;
          this.avaloading = false;
        }
      } else {
        this.noRelevantDataAVA = false;
      }
    }
  }

  //public Financials() {
  //  this.CategorySearchTable = false;
  //  this.SocialMediaCategorySearchTable = false;
  //  this.LifeStyleCategorySearchTable = false;
  //  if (this.accessData.isFinancialAccess) {
  //    this.noRelevantDataFinancial = true;
  //  } else {
  //    this.noRelevantDataFinancial = false;
  //  }
  //}

  //TestingSingle
  public lifeStyleSearch() {
    if (true) {
      this.lifeStyleStaus =
        [
          { name: "Running", displayname: "Running/Cycling", status: "Searching . . .", searchloading: true, statusCount: 0 },
          { name: "Water", displayname: "Water based activities", status: "Searching . . .", searchloading: true, statusCount: 0 },
          { name: "Ball", displayname: "Ball activities", status: "Searching . . .", searchloading: true, statusCount: 0 },
          { name: "Racket", displayname: "Racket Sports", status: "Searching . . .", searchloading: true, statusCount: 0 },
          { name: "Hockey", displayname: "Hockey & Ice Hockey", status: "Searching . . .", searchloading: true, statusCount: 0 },
          { name: "Rugby", displayname: "Rugby Sports", status: "Searching . . .", searchloading: true, statusCount: 0 },
          { name: "Boxing", displayname: "Kick Boxing/Judo & Boxing", status: "Searching . . .", searchloading: true, statusCount: 0 },
          { name: "MMA", displayname: "MMA Sports", status: "Searching . . .", searchloading: true, statusCount: 0 },
        ];
      //"Hockey", "Rugby", "Boxing", "MMA"
      this.LifeStyleCategorySearchTable = true;
      this.collapseLifeStyleClass = "show";
      this.collapseLifeStyleStatus = "Hide Status";
      this.collapseLifeStyleIcon = "fa-chevron-up";
      this.lifetStyleComplete = false;

      this.lifeStyleCategory.forEach((item: string) => {
        let lifeStyleIndex = 0;
        this.activeSearchDomains.forEach((searchDomain: string) => {
          this.getLifeStyleData(item, searchDomain, lifeStyleIndex);
        });
      });
      //this.lifestyle = false;
    }
  }

  public getLifeStyleData(lifeStyleCategoryItem, searchDomain, lifeStyleIndex) {
    this.lifeStyleResults = [];
    this.comparelifeStyleCategory = [];
    this.lifeStyledataloading = true;

    this.searchservice.SearchLifeStyle(this.search, lifeStyleCategoryItem, searchDomain, this.userId, this.auditKey, this.requestData)
      .subscribe(
        data => {
          if (data["newsitems"] != null) {
            this.lifeStyleResults = this.lifeStyleResults.concat(data["newsitems"]);
          }
          this.filterlifeStyleStaus = this.lifeStyleStaus.filter(x => x.name == lifeStyleCategoryItem)[0];
          this.filterlifeStyleStaus.statusCount++;

          if (this.filterlifeStyleStaus.statusCount == this.activeSearchDomains.length) {
            this.comparelifeStyleCategory.push(lifeStyleCategoryItem);
            this.filterlifeStyleStaus.status = "Search completed";
            this.lifeStyleSuccessCount++;
            this.filterlifeStyleStaus.searchloading = false;
          }

          if (this.lifeStyleSuccessCount == this.lifeStyleStaus.length) {
            this.searchservice.updateAudit(this.auditKey, 9, this.comparelifeStyleCategory).subscribe();
          }

          if (this.lifeStyleCategory.length == this.comparelifeStyleCategory.length) {
            this.collapseLifeStyleClass = "hide";
            this.collapseLifeStyleStatus = "Show Status";
            this.collapseLifeStyleIcon = "fa-chevron-down";
            this.lifetStyleComplete = true;

            if (this.lifeStyleResults.length > 0) {
              this.lifeStyleResults = this.getUniqueResults(this.lifeStyleResults, 'link');
            }
            this.IsLifeStyleValue = true;
            this.LifeStyleResultCount = this.lifeStyleResults.length;
            this.finalLifeStyleResults = this.lifeStyleResults;
            this.displaylifeStyleCount = true;
            this.lifeStyledataloading = false;
          }
        },
        error => {
          console.log(error);
          if (error.status == 401 && error.headers.has('Token-Expired')) {
            this.refreshtoken.token = localStorage.getItem("access_token");
            this.searchservice.refreshtoken(this.refreshtoken)
              .subscribe(
                data => {
                  localStorage.setItem("access_token", data["access_token"]);
                  localStorage.setItem("expires_in", Shared.getExpiryDate(Shared.getAccessTokenExpiryDateTime(data["access_token"]).toUTCString()));
                  localStorage.setItem("token_type", data["token_type"]);
                  this.lifeStyleSearch();
                },
                error => {
                  if (error.status == 400) {
                    this.logout();
                  }
                });
          }
          else if (error.status == 502 || error.status == 504) {
            lifeStyleIndex++;
            if (lifeStyleIndex < 2) {
              this.getLifeStyleData(lifeStyleCategoryItem, searchDomain, lifeStyleIndex);
            }
            else {
              this.IsLifeStyleValue = true;
            }
          }
          else {
            this.IsLifeStyleValue = true;
          }
        });
  }

  //TestingSingle
  public socialMediaSearch() {
    if (true) {
      this.socialMediaStatus =
        [
          { name: "facebook", displayname: "Facebook", status: "Searching . . .", searchloading: true, statusCount: 0 },
          { name: "instagram", displayname: "Instagram", status: "Searching . . .", searchloading: true, statusCount: 0 },
          { name: "linkedin", displayname: "LinkedIn", status: "Searching . . .", searchloading: true, statusCount: 0 },
          { name: "twitter", displayname: "Twitter", status: "Searching . . .", searchloading: true, statusCount: 0 },
          { name: "youtube", displayname: "YouTube", status: "Searching . . .", searchloading: true, statusCount: 0 },
          { name: "tiktok", displayname: "TikTok", status: "Searching . . .", searchloading: true, statusCount: 0 },
        ];

      this.collapseSocialMediaClass = "show";
      this.collapseSocialMediaStatus = "Hide Status";
      this.collapseSocialMediaIcon = "fa-chevron-up";
      this.SocialMediaComplete = false;
      this.SocialMediaCategorySearchTable = true;

      this.socialMediaCategory.forEach((item: string) => {
        this.activeSearchDomains.forEach((searchDomain: string) => {
          let socialMediaIndex = 0;
          this.getsocialMediaData(item, searchDomain, socialMediaIndex);
        });
      });
      //this.socialmedia = false;
    }
  }

  public getsocialMediaData(socialMediaCategoryItem, searchDomain, socialMediaIndex) {
    this.socialMediaResults = [];
    this.compareSocialMediaCategory = [];
    this.socialMediadataloading = true;

    this.searchservice.SearchSocialMedia(this.search, socialMediaCategoryItem, searchDomain, this.userId, this.auditKey, this.requestData)
      .subscribe(
        data => {
          if (data["newsitems"] != null) {
            data["newsitems"].forEach((item: Items) => {
              item.lexiconcategory = data["key"];
            });
            this.socialMediaResults = this.socialMediaResults.concat(data["newsitems"]);
          }
          this.filtersocialMediaStatus = this.socialMediaStatus.filter(x => x.name == socialMediaCategoryItem)[0];
          this.filtersocialMediaStatus.statusCount++

          if (this.filtersocialMediaStatus.statusCount == this.activeSearchDomains.length) {
            this.compareSocialMediaCategory.push(socialMediaCategoryItem);
            this.socialMediaSuccessCount++;
            this.filtersocialMediaStatus.status = "Search completed";
            this.filtersocialMediaStatus.searchloading = false;
          }
          if (this.socialMediaSuccessCount == this.socialMediaStatus.length) {
            this.searchservice.updateAudit(this.auditKey, 8, this.compareSocialMediaCategory).subscribe();
          }

          if (this.socialMediaCategory.length == this.compareSocialMediaCategory.length) {
            this.SocialMediaComplete = true;
            this.collapseSocialMediaClass = "hide";
            this.collapseSocialMediaStatus = "Show Status";
            this.collapseSocialMediaIcon = "fa-chevron-down";

            if (this.socialMediaResults.length > 0) {
              this.socialMediaResults = this.getUniqueResults(this.socialMediaResults, 'link');
            }
            this.IsSocialMediaValue = true;
            this.SocialMediaResultCount = this.socialMediaResults.length;
            this.finalSocialMediaResults = this.socialMediaResults;

            this.finalSocialMediaResults.sort((a, b) => {
              if (a.lexiconcategory < b.lexiconcategory) { return -1; }
              return 0;
            });

            this.displaySocialMediaCount = true;
            this.socialMediadataloading = false;
          }
        },
        error => {
          console.log(error);
          if (error.status == 401 && error.headers.has('Token-Expired')) {
            this.refreshtoken.token = localStorage.getItem("access_token");
            this.searchservice.refreshtoken(this.refreshtoken)
              .subscribe(
                data => {
                  localStorage.setItem("access_token", data["access_token"]);
                  localStorage.setItem("expires_in", Shared.getExpiryDate(Shared.getAccessTokenExpiryDateTime(data["access_token"]).toUTCString()));
                  localStorage.setItem("token_type", data["token_type"]);
                  this.socialMediaSearch();
                },
                error => {
                  if (error.status == 400) {
                    this.logout();
                  }
                });
          }
          else if (error.status == 502 || error.status == 504) {
            socialMediaIndex++;
            if (socialMediaIndex < 2) {
              this.getsocialMediaData(socialMediaCategoryItem, searchDomain, socialMediaIndex);
            }
            else {
              this.IsSocialMediaValue = true;
            }
          }
          else {
            this.IsSocialMediaValue = true;
          }
        });
  }

  public GetAdvertSearch(phoneNumber) {

    this.advertResults = [];
    this.AdvertResultCount = 0;
    this.advertloading = true;
    this.searchservice.SearchAdvert(phoneNumber, this.userId, this.auditKey, this.requestData, this.search)
      .subscribe(
        data => {
          if (data["newsitems"]) {
            this.advertResults = data["newsitems"];
            this.AdvertResultCount = this.advertResults.length;
            this.advertloading = false;
            this.AdvertValidationMessage = "";
            if (!this.AdvertResultCount || this.AdvertResultCount <= 0) {
              this.AdvertValidationMessage = this.NoRelevantDataMessage;
            }
          }
          else {
            this.AdvertValidationMessage = this.NoRelevantDataMessage;;
          }
        }, error => {
          this.advertloading = false;
          this.AdvertValidationMessage = this.NoRelevantDataMessage;;
        });
  }
  // ---------------- Category table Collapse -----------------------
  public ResultTableDisplay() {
    if (this.collapseResultClass == "show") {
      this.collapseResultClass = "hide";
      this.collapseResultStatus = "Show Status";
      this.collapseResultIcon = "fa-chevron-down";
      $('#collapseOne').collapse(this.collapseResultClass);
    }
    else {
      this.collapseResultClass = "show";
      $('#collapseOne').collapse(this.collapseResultClass);
      this.collapseResultStatus = "Hide Status";
      this.collapseResultIcon = "fa-chevron-up";
    }
  }

  public LifeStyleTableDisplay() {
    if (this.collapseLifeStyleClass == "show") {
      this.collapseLifeStyleClass = "hide";
      this.collapseLifeStyleStatus = "Show Status";
      this.collapseLifeStyleIcon = "fa-chevron-down";
      $('#collapseTwo').collapse(this.collapseLifeStyleClass);
    }
    else {
      this.collapseLifeStyleClass = "show";
      this.collapseLifeStyleStatus = "Hide Status";
      this.collapseLifeStyleIcon = "fa-chevron-up";
      $('#collapseTwo').collapse(this.collapseLifeStyleClass);
    }
  }

  public SocialMediaTableDisplay() {
    if (this.collapseSocialMediaClass == "show") {
      this.collapseSocialMediaClass = "hide";
      this.collapseSocialMediaStatus = "Show Status";
      this.collapseSocialMediaIcon = "fa-chevron-down";
      $('#collapseThree').collapse(this.collapseSocialMediaClass);
    }
    else {
      this.collapseSocialMediaClass = "show";
      this.collapseSocialMediaStatus = "Hide Status";
      this.collapseSocialMediaIcon = "fa-chevron-up";
      $('#collapseThree').collapse(this.collapseSocialMediaClass);
    }
  }

  public GetFinancialData() {
    this.financialVaidation = false;
    if (this.accessData.isFinancialAccess) {
      this.financialSearchFail = false;
      this.noRelevantDataFinancial = false;
      if (this.requestData.profileData.postalCode && this.search) {
        this.financialloading = true;
        this.searchservice.GetPersonSearch(this.requestData, this.search, this.auditKey)
          .subscribe(
            data => {
              const resdata = JSON.parse(JSON.stringify(data));
              this.financialloading = false;
              this.financialSearchFail = false;
              this.noRelevantDataFinancial = false;
              if (!resdata) {
                this.noRelevantDataFinancial = true;
              } else {
                this.financialData = resdata;
                if (this.financialData?.data?.InsolvencyFlag == true || this.financialData?.data?.CcjFlag == true) {
                  this.CCJAndInsolvencyDetailsCheck(data);
                } else {
                  this.ccjloading = false;
                }
              }

            }, error => {
              if (error.status === 403) {
                this.financialSearchFail = true;
              }
              this.financialloading = false;
            });
      }
      else {
        this.financialloading = false;
        this.financialVaidation = true;
      }
    }
  }
  // ----------------- Rank History Popup Modal ------------------
  public ShowRankHistory(rankHistorytemplate: TemplateRef<any>, i) {
    this.rankHistory = this.finalresults[i].lexiconKeywords;
    this.rankHistory = this.rankHistory.sort((a, b) => b.keywordCount - a.keywordCount);
    this.rankHistoryLexicon = this.finalresults[i].lexiconcategory;
    this.rankDomain = this.finalresults[i].searchDomain;

    this.searchDomains.forEach((item: SearchDomain) => {
      if (item.UniqueCode == this.rankDomain) {
        this.rankDomainValue = item.RankValue;
      }
    });

    this.duplicatelinkCount = this.rankHistoryLexicon.length - 1;
    this.duplicatelinkRank = this.duplicatelinkCount * 100;
    this.negativePoints = this.finalresults[i].negativePoints;
    this.negativekeywords = this.finalresults[i].negativeKeywords;

    this.rankHistoryPopUp = this.modalService.show(
      rankHistorytemplate,
      Object.assign({}, { class: 'gray modal-lg' })
    );
  }

  public closeAlert() {
    this.serverError = false;
    this.alert.nativeElement.classList.remove('show');
  }

  public logout() {
    localStorage.removeItem("access_token");
    localStorage.removeItem("expires_in");
    localStorage.removeItem("token_type");
    localStorage.removeItem("userId");
    localStorage.removeItem("subscription");
    this.router.navigate(['/Login']);
  }

  // -------------------CSS Methods ------------------
  public getColorCSSClass(val) {
    if (val === 'Red') {
      return 'color-red';
    }
    if (val === 'Green') {
      return 'color-green';
    }
    if (val === 'Amber') {
      return 'color-amber';
    }
  }

  associatedEntries() {
    this.associatedSearchUsers.forEach(x => {
      if (x.name) {
        this.visibleResetBtn = true;
      }
    })
  }
  resetForm() {
    this.contactData = new ContactData();
    this.phonenumber = '';
    this.searchtext = "";
    this.associatedSearchUsers = new Array();
    if (this.associatedSearchUsers.length == 0) {
      for (let i = 0; i < 4; i++) {
        this.associatedSearchUsers.push(this.newAssociatedUser);
        this.newAssociatedUser = {};
        //this.addAssociationNormalView = false;
        //this.addAssociationTextboxView = true;
      }
    }
    this.saveAsPDFButtonVisibility = false;
    this.followDisplay = false;
  }
  // download pdf
  async downloadHTML2PDF() {
    // Choose the element and save the PDF for our user.
    var doc = new jsPDF('p', 'pt', 'a4');
    var metrics = ["1"];
    var allres = metrics.map(async (key, index) => {

      this.isDownloadStart = true;
      this.x = 20;
      this.y = 40;

      this.FormatSearchBy(doc);
      this.FormatInputAndToggle(doc);
      await this.FormatMobileFinancialCounterFraud(doc);

      doc.addPage();
      this.y = 40;

      this.FormatAddress(doc);
      this.FormatAdverts(doc);
      this.FormatAML(doc);
      this.FormatAssociations(doc);
      await this.FormatAVA(doc);
      this.FormatCCJDetail(doc);
      this.FormatCompanyHouse(doc);
      this.FormatCriminality(doc);
      this.FormatInsolvencyDetail(doc);

      if (this.isProspectHitAccess)
        this.FormatProspectHit(doc);

      this.FormatSocialMedia(doc);
      this.FormatSports(doc);
    });

    // saving pdf
    Promise.all(allres)
      .then(() => {
        //Datetime in filename
        var date = new Date();
        var year = date.getFullYear();
        var month = date.getMonth() + 1;      // "+ 1" becouse the 1st month is 0
        var day = date.getDate();
        var hour = date.getHours();
        var minutes = date.getMinutes();
        var secconds = date.getSeconds()

        var seedatetime = day + "" + month + "" + year + '_' + hour + "" + minutes + "" + secconds;
        let pdffilename = this.contactData.referenceNumber + "-" + seedatetime + ".pdf";
        doc.save(pdffilename);
        this.isDownloadStart = false;
      })
      .catch(err => {
        this.isDownloadStart = false;
        console.log(err)
      });
  }

  FormatProspectHit(doc: jsPDF) {
    this.y = this.y + 30;
    this.checkDocYAxis(doc);
    this.x = 20;
    this.SetHeadingFontSize(doc);
    this.SetBoldFontStyle(doc);
    doc.text("Prospect", this.x, this.y = this.y + 20);
    this.SetNormalFontStyle(doc);
    this.SetStandardFontSize(doc);
    if (this.tempAccessData.isCounterFraudAccess) {
      if ((this.searchtext && this.contactData.dateOfBirth) || (this.contactData.postalCode && this.contactData.addressLine1) || this.phonenumber || this.contactData.email || this.contactData.vRN) {
        if (this.prospectHitResultData && (this.prospectHitResultData?.hits != null && this.prospectHitResultData?.hits?.length > 0)) {
          this.SetStandardFontSize(doc);
          this.SetBoldFontStyle(doc);
          this.y = this.y + 20;
          doc.text("Details", this.x, this.y = this.y);
          doc.text("I2 Match", this.x = this.x + 250, this.y);
          doc.text("Previous Search", this.x = this.x + 100, this.y);
          this.y = this.y + 20;
          this.x = 20;
          for (let i = 0; i < 500; i++) {
            doc.text("-", this.x = this.x + 1, this.y)
          }
          this.prospectHitResultData?.hits.forEach(x => {
            this.x = 20;
            this.y = this.y + 20;
            this.checkDocYAxis(doc);
            this.SetNormalFontStyle(doc);
            if (x?.searchType == "Person") {
              doc.text("Person name : " + this.searchtext, this.x, this.y);
              this.y = this.y + 20;
              this.x = 20;
              doc.text("Date of birth : " + (this.tempContactData.dateOfBirth ? this.tempContactData.dateOfBirth : "-"), this.x, this.y);
            } else if (x?.searchType == "Telephone") {
              doc.text("Telephone : " + (this.tempPhoneNumber ? this.phonenumber : "-"), this.x, this.y);
            } else if (x?.searchType == "Email") {
              doc.text("Email : " + (this.tempContactData.email ? this.tempContactData.email : "-"), this.x, this.y);
            } else if (x?.searchType == "Vehicle") {
              doc.text("Vehicle number : " + (this.tempContactData.vRN ? this.tempContactData.vRN : "-"), this.x, this.y);
            } else {
              doc.text("Address : " + (this.tempContactData.addressLine1 ? this.tempContactData.addressLine1 : "-"), this.x, this.y);
            }
            doc.text("" + x?.i2Hit, this.x = this.x + 250, this.y);
            doc.text("" + x?.previousHit, this.x = this.x + 100, this.y);
            this.x = 20;
            this.y = this.y + 20;
            for (let i = 0; i < 500; i++) {
              doc.text("-", this.x = this.x + 1, this.y)
            }
            this.x = 20;
            this.checkDocYAxis(doc);
          });
        } else {
          if (!this.prospectSearchFail)
            this.setMessage(doc, this.NoRelevantDataMessage);
          else
            this.setMessage(doc, this.SearchFail);
        }
      } else {
        this.setMessage(doc, this.OtherFieldRequired);
      }
    } else {
      this.setMessage(doc, this.NoSearchSelect);
    }
  }

  FormatCCJDetail(doc: jsPDF) {
    this.y = this.y + 30;
    this.checkDocYAxis(doc);
    this.x = 20;
    this.SetHeadingFontSize(doc);
    this.SetBoldFontStyle(doc);
    doc.text("CCJ", this.x, this.y = this.y + 20);
    this.SetNormalFontStyle(doc);
    this.SetStandardFontSize(doc);
    if (this.tempAccessData.isCCJDetailAccess) {
      if (this.tempAccessData.isFinancialAccess) {
        if (this.contactData.dateOfBirth || (this.contactData.addressLine1 && this.contactData.postalCode)) {
          if (this.CCJResult?.ccjDetails != null || this.CCJResult?.ccjDetails.length > 0) {
            this.CCJResult.ccjDetails.forEach(item => {
              this.checkDocYAxis(doc);
              if (item?.courtName)
                doc.text("Court Name : " + item.courtName, this.x, this.y = this.y + 20);
              if (item?.caseNumber)
                doc.text("Case Number : " + item.caseNumber, this.x, this.y = this.y + 20);
              if (item?.amount) {
                var amount = item.amount.substr(item.amount.indexOf(';') + 1);
                doc.text("Amount : " + amount, this.x, this.y = this.y + 20);
              }
              if (item?.judgementType)
                doc.text("Judgement Type : " + item.judgementType, this.x, this.y = this.y + 20);
              if (item?.judgementDate)
                doc.text("Judgement Date : " + this.FormatDate(item.judgementDate, "ccj"), this.x, this.y = this.y + 20);
              this.AddLine(doc);
            });
          } else {
            this.setMessage(doc, this.NoRelevantDataMessage);
          }
        } else {
          this.setMessage(doc, this.OtherFieldRequired);
        }
      } else {
        this.setMessage(doc, this.OtherFieldRequired);
      }
    } else {
      this.setMessage(doc, this.NoSearchSelect);
    }
  }

  FormatInsolvencyDetail(doc: jsPDF) {
    this.y = this.y + 30;
    this.checkDocYAxis(doc);
    this.x = 20;
    this.SetHeadingFontSize(doc);
    this.SetBoldFontStyle(doc);
    doc.text("Insolvency", this.x, this.y = this.y + 20);
    this.SetNormalFontStyle(doc);
    this.SetStandardFontSize(doc);
    if (this.tempAccessData.isInsolvencyDetailAccess) {
      if (this.tempAccessData.isFinancialAccess) {
        if (this.contactData.dateOfBirth) {
          if (this.CCJResult?.insolvencyDetails != null || this.CCJResult?.insolvencyDetails.length > 0) {
            this.CCJResult.insolvencyDetails.forEach(item => {
              this.checkDocYAxis(doc);
              if (item?.name)
                doc.text("Name : " + item.name, this.x, this.y = this.y + 20);
              if (item?.dob)
                doc.text("DOB : " + this.FormatDate(item.dob, "ccj"), this.x, this.y = this.y + 20);
              if (item?.address != null && (item?.address?.address1 || item?.address?.address2 || item?.address?.address3 ||
                item?.address?.address4 || item?.address?.address5 || item?.address?.postcode || item?.address?.dps)) {
                this.SetBoldFontStyle(doc);
                doc.text("Address", this.x, this.y = this.y + 20);
                this.SetNormalFontStyle(doc);
                this.x = this.x + 20;
                if (item?.address?.address1)
                  doc.text("Address 1 : " + item?.address?.address1, this.x, this.y = this.y + 20);
                if (item?.address?.address2)
                  doc.text("Address 2 : " + item?.address?.address2, this.x, this.y = this.y + 20);
                if (item?.address?.address3)
                  doc.text("Address 3 : " + item?.address?.address3, this.x, this.y = this.y + 20);
                if (item?.address?.address4)
                  doc.text("Address 4 : " + item?.address?.address4, this.x, this.y = this.y + 20);
                if (item?.address?.address5)
                  doc.text("Address 5 : " + item?.address?.address5, this.x, this.y = this.y + 20);
                if (item?.address?.postcode)
                  doc.text("Postcode : " + item?.address?.postcode, this.x, this.y = this.y + 20);
                if (item?.address?.dps)
                  doc.text("DPS : " + item?.address?.dps, this.x, this.y = this.y + 20);
              }
              this.x = 20;
              this.checkDocYAxis(doc);
              if (item?.previousAddress != null && (item?.previousAddress?.address1 || item?.previousAddress?.address2 ||
                item?.previousAddress?.address3 || item?.previousAddress?.address4 || item?.previousAddress?.address5 ||
                item?.previousAddress?.postcode || item?.previousAddress?.dps)) {
                this.SetBoldFontStyle(doc);
                doc.text("Previous Address", this.x, this.y = this.y + 20);
                this.SetNormalFontStyle(doc);
                this.x = this.x + 20;
                if (item?.previousAddress?.address1)
                  doc.text("Address 1 : " + item?.previousAddress?.address1, this.x, this.y = this.y + 20);
                if (item?.previousAddress?.address2)
                  doc.text("Address 2 : " + item?.previousAddress?.address2, this.x, this.y = this.y + 20);
                if (item?.previousAddress?.address3)
                  doc.text("Address 3 : " + item?.previousAddress?.address3, this.x, this.y = this.y + 20);
                if (item?.previousAddress?.address4)
                  doc.text("Address 4 : " + item?.previousAddress?.address4, this.x, this.y = this.y + 20);
                if (item?.previousAddress?.address5)
                  doc.text("Address 5 : " + item?.previousAddress?.address5, this.x, this.y = this.y + 20);
                if (item?.previousAddress?.postcode)
                  doc.text("Postcode : " + item?.previousAddress?.postcode, this.x, this.y = this.y + 20);
                if (item?.previousAddress?.dps)
                  doc.text("DPS : " + item?.previousAddress?.dps, this.x, this.y = this.y + 20);
              }
              this.x = 20;
              this.checkDocYAxis(doc);
              if (item?.type)
                doc.text("Type : " + item.type, this.x, this.y = this.y + 20);
              if (item?.caseNo)
                doc.text("Case No : " + item.caseNo, this.x, this.y = this.y + 20);
              if (item?.caseType)
                doc.text("Case Type : " + item.caseType, this.x, this.y = this.y + 20);
              if (item?.court)
                doc.text("Court Name : " + item.court, this.x, this.y = this.y + 20);
              if (item?.startDate)
                doc.text("Start Date : " + this.FormatDate(item.startDate, "ccj"), this.x, this.y = this.y + 20);
              if (item?.status)
                doc.text("Status : " + item.status, this.x, this.y = this.y + 20);
              this.checkDocYAxis(doc);
              if (item?.occupation)
                doc.text("Occupation : " + item.occupation, this.x, this.y = this.y + 20);
              if (item?.aliases)
                doc.text("Aliases : " + item.aliases, this.x, this.y = this.y + 20);
              if (item?.description) {
                doc.text("Description : ", this.x, this.y = this.y + 20);
                let splitedDescription = doc.splitTextToSize(item.description, 500);
                splitedDescription.forEach((desc) => {
                  doc.text(desc, this.x, this.y = this.y + 20);
                });
              }
              if (item?.serviceOffice) {
                doc.text("Service Office : ", this.x, this.y = this.y + 20);
                let splitedServiceOffice = doc.splitTextToSize(item.serviceOffice, 500);
                splitedServiceOffice.forEach((serviceOffice) => {
                  doc.text(serviceOffice, this.x, this.y = this.y + 20);
                });
              }
              if (item?.telephoneNumber)
                doc.text("Telephone Number : " + item.telephoneNumber, this.x, this.y = this.y + 20);
              if (item?.tradingNames)
                doc.text("Trading Names : " + item.tradingNames, this.x, this.y = this.y + 20);
              if (item?.debtTotal)
                doc.text("Debt Total : " + item.debtTotal, this.x, this.y = this.y + 20);
              if (item?.assetTotal)
                doc.text("Asset Total : " + item.assetTotal, this.x, this.y = this.y + 20);
              if (item?.presentationDate)
                doc.text("Presentation Date : " + this.FormatDate(item.presentationDate, "ccj"), this.x, this.y = this.y + 20);
              this.AddLine(doc);
            });
          } else {
            this.setMessage(doc, this.NoRelevantDataMessage);
          }
        } else {
          this.setMessage(doc, this.OtherFieldRequired);
        }
      } else {
        this.setMessage(doc, this.OtherFieldRequired);
      }
    } else {
      this.setMessage(doc, this.NoSearchSelect);
    }
  }

  FormatLinksForAdvert(doc, text, link, x, y) {
    this.SetStandardFontSize(doc);
    doc.setTextColor("#0645AD");
    doc.textWithLink(text ? text : "", this.x = x, this.y = y, { url: link });
    doc.setTextColor("");
    return doc;
  }

  FormatLinks(doc, text, link, description, pubdate) {
    this.SetStandardFontSize(doc);
    doc.setTextColor("#0645AD");
    doc.textWithLink(text ? text : "", this.x, this.y = this.y + 20, { url: link });
    doc.setTextColor("");
    let splitedText = doc.splitTextToSize(description, 530);
    splitedText.forEach(item => {
      doc.text(item ? item : "", this.x, this.y = this.y + 20);
    })
    if (pubdate) {
      this.SetItalicFont(doc);
      doc.text('PublishDate: ' + pubdate, this.x + 400, this.y = this.y + 20);
      this.ClearItalicFont(doc);
    }
    return doc;
  }

  FormatCompanyHouseItem(doc: jsPDF, item) {
    this.SetStandardFontSize(doc);
    let searchName, isDisqualified, description, address, dateOfBirth, companies;
    searchName = item.Name;
    isDisqualified = item.IsDisqualified;
    description = item.Description;
    address = item.Address;
    dateOfBirth = item.DateOfBirth;
    companies = item.CompaniesData;
    doc.setFont("", "bold");
    doc.text(searchName + (isDisqualified ? "(Disqualified)" : ""), this.x, this.y = this.y + 20);
    this.SetItalicFont(doc);
    doc.setFontSize(9);
    doc.text(description ? description : "", this.x, this.y = this.y + 10);
    this.SetNormalFontStyle(doc);
    this.SetStandardFontSize(doc);
    doc.text("Address: ", this.x, this.y = this.y + 20);
    doc.text("Date Of Birth: ", this.x + 400, this.y);
    let splitedAddress = doc.splitTextToSize(address, 300);
    doc.text(dateOfBirth ? dateOfBirth : "", this.x + 400, this.y + 20);
    splitedAddress.forEach(item => {
      doc.text(item ? item : "", this.x, this.y = this.y + 20);
    });

    companies.forEach(company => {
      this.checkDocYAxis(doc);
      let companyName = company?.Name;
      let companyNumber = company?.Number;
      let companyStatus = company?.Status;
      let officerRole = company?.OfficerRole;
      let officerAppointedOn = company?.companyObject?.appointed_on;
      let officerResignedOn = company?.companyObject?.resigned_on;
      let officerNationality = company?.companyObject?.nationality;
      this.SetBoldFontStyle(doc);
      doc.text(companyName ? (companyName + "(" + companyNumber + ")") : "", this.x + 20, this.y = this.y + 30);
      this.x = 40;
      this.SetNormalFontStyle(doc);
      doc.text("Company status: ", this.x, this.y = this.y + 30);
      doc.text("Officer Role: ", this.x + 100, this.y);
      doc.text("Appointed On: ", this.x + 200, this.y);
      this.SetBoldFontStyle(doc);
      doc.text(companyStatus ? companyStatus : "-", this.x, this.y = this.y + 20);
      doc.text(officerRole ? officerRole : "-", this.x + 100, this.y);
      doc.text(officerAppointedOn ? officerAppointedOn : "-", this.x + 200, this.y);
      this.SetNormalFontStyle(doc);
      doc.text("Resigned On: ", this.x, this.y = this.y + 20);
      doc.text("Nationality: ", this.x + 100, this.y = this.y);
      this.SetBoldFontStyle(doc);
      doc.text(officerResignedOn ? officerResignedOn : "-", this.x, this.y = this.y + 20);
      doc.text(officerNationality ? officerNationality : "-", this.x + 100, this.y = this.y);
      this.SetNormalFontStyle(doc);
      this.AddLine(doc);
      this.checkDocYAxis(doc);
    });
    this.checkDocYAxis(doc);
  }

  FormatToggle(doc, name: string, value) {
    this.SetNormalFontStyle(doc);
    this.SetStandardFontSize(doc);
    doc.text(name + ": ", this.x, this.y = this.y + 20);
    this.SetBoldFontStyle(doc);
    doc.text(value ? "Yes" : "No", this.x + 100, this.y)
    this.SetNormalFontStyle(doc);
  }

  FormatMOTHistory(doc) {
    this.avaResult?.mot?.Response?.DataItems?.MotHistory?.RecordList.forEach(item => {
      this.x = 20;
      this.checkDocYAxis(doc);
      this.SetNormalFontStyle(doc);
      let testDate = this.FormatDate(item?.TestDate, "mot");
      let expiryDate = this.FormatDate(item?.ExpiryDate, "mot");
      doc.text(testDate ? testDate : "", this.x, this.y = this.y + 20);
      doc.text(expiryDate ? expiryDate : "", this.x = this.x + 100, this.y);
      doc.text("" + item?.TestResult, this.x = this.x + 80, this.y);
      doc.text("" + item?.OdometerReading, this.x = this.x + 100, this.y);
      doc.text("" + item?.TestNumber, this.x = this.x + 110, this.y);
      this.x = 20;
      doc.text("Advisory Notice Items", this.x, this.y = this.y + 20);
      item?.AdvisoryNoticeList.forEach((advisory, index) => {
        doc.text(index + 1 + ". " + advisory, this.x, this.y = this.y + 20);
      })
      this.y = this.y + 20;
      for (let i = 0; i < 700; i++) {
        doc.text("-", this.x = this.x + 1, this.y)
      }
      this.checkDocYAxis(doc);
    })
  }

  FormatSearchBy(doc: jsPDF) {
    let username = localStorage.getItem("username");

    var date = new Date();
    var year = date.getFullYear();
    var month = date.getMonth() + 1;      // "+ 1" becouse the 1st month is 0
    var day = date.getDate();
    var hour = date.getHours();
    var minutes = date.getMinutes();
    var searchdate = day + "-" + month + "-" + year + '  ' + hour + ":" + minutes;

    this.SetStandardFontSize(doc);
    this.SetItalicFont(doc);
    doc.text('Search performed by ' + username + ' on ' + searchdate, this.x, this.y);
    this.ClearItalicFont(doc);
  }

  FormatInputAndToggle(doc: jsPDF) {
    this.y = this.y + 20;
    let localY = this.y;

    this.SetHeadingFontSize(doc);
    this.SetBoldFontStyle(doc);
    doc.text('You Tell Us', this.x, this.y = this.y + 20);
    this.SetNormalFontStyle(doc);
    this.SetStandardFontSize(doc);

    doc.text('Reference: ' + this.tempContactData.referenceNumber, this.x, this.y = this.y + 20);
    doc.text('Name: ' + this.tempSearchName, this.x, this.y = this.y + 20);
    doc.text('Post Code: ' + (this.tempContactData.postalCode ? this.tempContactData.postalCode : "-"), this.x, this.y = this.y + 20);
    doc.text('Mobile: ' + (this.tempPhoneNumber ? this.phonenumber : "-"), this.x, this.y = this.y + 20);
    doc.text('Email: ' + (this.tempContactData.email ? this.tempContactData.email : "-"), this.x, this.y = this.y + 20);
    doc.text('VRN: ' + (this.tempContactData.vRN ? this.tempContactData.vRN : "-"), this.x, this.y = this.y + 20);
    doc.text('DOB: ' + (this.tempContactData.dateOfBirth ? this.tempContactData.dateOfBirth : "-"), this.x, this.y = this.y + 20);
    this.SetBoldFontStyle(doc);
    doc.text('Additional Information', this.x, this.y = this.y + 20);
    this.SetNormalFontStyle(doc);
    doc.text('House No/Name: ' + (this.tempContactData.addressLine1 ? this.tempContactData.addressLine1 : "-"), this.x, this.y = this.y + 20);
    doc.text('Street: ' + (this.tempContactData.addressLine2 ? this.tempContactData.addressLine2 : "-"), this.x, this.y = this.y + 20);
    doc.text('City: ' + (this.tempContactData.city ? this.tempContactData.city : "-"), this.x, this.y = this.y + 20);
    doc.text('County: ' + (this.tempContactData.state ? this.tempContactData.state : "-"), this.x, this.y = this.y + 20);
    doc.text('Country: ' + (this.tempContactData.country ? this.tempContactData.country : "-"), this.x, this.y = this.y + 20);
    doc.text('Company: ' + (this.tempContactData.companyName ? this.tempContactData.companyName : "-"), this.x, this.y = this.y + 20);
    doc.text('Associated Entities: ', this.x, this.y = this.y + 20);
    this.associatedSearchUsers.forEach((item, index) => {
      doc.text("Entity" + (index + 1) + ": " + (item.name ? item.name : "-"), this.x + 20, this.y = this.y + 20);
    });

    this.y = localY;
    this.x = 370;

    this.SetHeadingFontSize(doc);
    this.SetBoldFontStyle(doc);
    doc.text('We Tell You', this.x, this.y = this.y + 20);

    this.FormatToggle(doc, "HUT Database", this.tempAccessData.isHUTDatabaseAccess);
    this.FormatToggle(doc, "Mobile", this.tempAccessData.isMobileAccess);
    this.FormatToggle(doc, "Email", this.tempAccessData.isEmailAccess);
    this.FormatToggle(doc, "Identity", this.tempAccessData.isIdentityAccess);
    this.FormatToggle(doc, "Financial", this.tempAccessData.isFinancialAccess);
    this.FormatToggle(doc, "Address", this.tempAccessData.isAddressAccess);
    this.FormatToggle(doc, "Criminality", this.tempAccessData.isCriminalityAccess);
    this.FormatToggle(doc, "Social Media", this.tempAccessData.isSocialMediaAccess);
    this.FormatToggle(doc, "Counter Fraud", this.tempAccessData.isCounterFraudAccess);
    this.FormatToggle(doc, "Sports", this.tempAccessData.isSportsAccess);
    this.FormatToggle(doc, "Adverts", this.tempAccessData.isAdvertsAccess);
    this.FormatToggle(doc, "Companies House", this.tempAccessData.isCompaniesHouseAccess);
    this.FormatToggle(doc, "AVA", this.tempAccessData.isAVAAccess);
    this.FormatToggle(doc, "AML", this.tempAccessData.isAMLAccess);
    this.FormatToggle(doc, "CCJ Detail", this.tempAccessData.isCCJDetailAccess);
    this.FormatToggle(doc, "Insolvency Detail", this.tempAccessData.isInsolvencyDetailAccess);
    this.FormatToggle(doc, "RTW", this.tempAccessData.isRTWAccess);
    this.x = 20;

  }

  async FormatMobileFinancialCounterFraud(doc: jsPDF) {
    try {
      this.checkDocYAxis(doc);
      await html2canvas(document.querySelector("#contact-details")).then(contactDetails => {
        var context = contactDetails.getContext('2d');
        let contactCanvasImg = contactDetails.toDataURL("image/jpeg")
        doc.addImage(contactCanvasImg, 'png', this.x, this.y = this.y + 140, 550, 250);
      });
    } catch { }
  }

  FormatAdverts(doc: jsPDF) {
    this.y = this.y + 30;
    this.SetHeadingFontSize(doc);
    this.SetBoldFontStyle(doc);
    doc.text("Advert", this.x, this.y = this.y + 20);
    this.SetNormalFontStyle(doc);
    this.SetStandardFontSize(doc);
    if (this.tempAccessData.isAdvertsAccess) {
      if (this.contactData.phoneNumber) {
        if (this.advertResults.length > 0) {
          this.advertResults.forEach(item => {
            this.checkDocYAxis(doc);
            this.FormatLinks(doc, item.title, item.link, item.description, item.pubDate);
          });
        } else {
          this.setMessage(doc, this.NoRelevantDataMessage);
        }
      } else {
        this.setMessage(doc, this.mobileRequiredMessage);
      }
    } else {
      this.setMessage(doc, this.NoSearchSelect);
    }
  }

  FormatCriminality(doc: jsPDF) {
    this.y = this.y + 30;
    if (this.y > 300 && this.y < 600) {
      doc.addPage();
      this.y = 40;
    }
    this.checkDocYAxis(doc);
    this.SetHeadingFontSize(doc);
    this.SetBoldFontStyle(doc);
    doc.text("Crime & Legal", this.x, this.y = this.y + 20);
    this.SetNormalFontStyle(doc);
    this.SetStandardFontSize(doc);
    if (this.tempAccessData.isCriminalityAccess) {
      autoTable(doc, { html: '#criminalityStatusTable', startY: this.y = this.y + 20 });
      this.y = this.y + (this.lexicons.length * 20) + 20;
      this.checkDocYAxis(doc);
      this.SetBoldFontStyle(doc);
      doc.text("Result", this.x, this.y = this.y + 20);
      this.SetNormalFontStyle(doc);
      if (this.sortfinalResults.length > 0) {
        this.sortfinalResults.forEach(item => {
          this.checkDocYAxis(doc);
          this.FormatLinks(doc, item.title, item.link, item.description, item.pubDate);
        });
      } else {
        this.setMessage(doc, this.NoRelevantDataMessage);
      }
    } else {
      this.setMessage(doc, this.NoSearchSelect);
    }
  }

  FormatSocialMedia(doc: jsPDF) {
    this.y = this.y + 30;
    if (this.y > 300 && this.y < 600) {
      doc.addPage();
      this.y = 40;
    }
    this.x = 20;
    this.checkDocYAxis(doc);
    this.SetHeadingFontSize(doc);
    this.SetBoldFontStyle(doc);
    doc.text("Social Media", this.x, this.y = this.y + 20);
    this.SetNormalFontStyle(doc);
    this.SetStandardFontSize(doc);
    if (this.tempAccessData.isSocialMediaAccess) {
      autoTable(doc, { html: '#socialMediaStatusTable', startY: this.y = this.y + 20 });
      this.y = this.y + (this.socialMediaCategory.length * 20) + 20;
      this.checkDocYAxis(doc);
      this.SetBoldFontStyle(doc);
      doc.text("Result", this.x, this.y = this.y + 20);
      this.SetNormalFontStyle(doc);
      if (this.finalSocialMediaResults.length > 0) {
        this.finalSocialMediaResults.forEach(item => {
          this.checkDocYAxis(doc);
          this.FormatLinks(doc, item.title, item.link, item.description, item.pubDate);
        });
      } else {
        this.setMessage(doc, this.NoRelevantDataMessage);
      }
    } else {
      this.setMessage(doc, this.NoSearchSelect);
    }

  }

  FormatSports(doc: jsPDF) {
    this.y = this.y + 30;
    if (this.y > 300 && this.y < 600) {
      doc.addPage();
      this.y = 40;
    }
    this.x = 20;
    this.checkDocYAxis(doc);
    this.SetHeadingFontSize(doc);
    this.SetBoldFontStyle(doc);
    doc.text("Sports", this.x, this.y = this.y + 20);
    this.SetNormalFontStyle(doc);
    this.SetStandardFontSize(doc);
    if (this.tempAccessData.isSportsAccess) {
      autoTable(doc, { html: '#sportsStatusTable', startY: this.y = this.y + 20 });
      this.y = this.y + (this.lifeStyleCategory.length * 20) + 20;
      this.SetBoldFontStyle(doc);
      doc.text("Result", this.x, this.y = this.y + 20);
      this.SetNormalFontStyle(doc);
      if (this.finalLifeStyleResults.length > 0) {
        this.finalLifeStyleResults.forEach(item => {
          this.checkDocYAxis(doc);
          this.FormatLinks(doc, item.title, item.link, item.description, item.pubDate);
        });
      } else {
        this.setMessage(doc, this.NoRelevantDataMessage);
      }
    } else {
      this.setMessage(doc, this.NoSearchSelect);
    }
  }

  FormatAssociations(doc: jsPDF) {
    this.y = this.y + 30;
    this.checkDocYAxis(doc);
    this.SetHeadingFontSize(doc);
    this.SetBoldFontStyle(doc);
    doc.text("Associations", this.x, this.y = this.y + 20);
    this.SetNormalFontStyle(doc);
    this.SetStandardFontSize(doc);
    if (this.tempAccessData.isCriminalityAccess) {
      if (this.validAssociatedSearchUsers?.length > 0) {
        if (this.Associatedresultsdisplay && this.Associatedresultsdisplay.length > 0) {
          this.Associatedresultsdisplay.forEach(item => {
            this.checkDocYAxis(doc);
            this.FormatLinks(doc, item.title, item.link, item.description, item.pubDate);
          });
        } else {
          if (this.associatedFail) {
            this.setMessage(doc, this.SearchFail);
          } else {
            this.setMessage(doc, this.NoRelevantDataMessage);
          }
        }
      } else {
        this.setMessage(doc, this.assiciateDataNotProvideMessage);
      }
    } else {
      this.setMessage(doc, this.NoSearchSelect);
    }
  }

  FormatCompanyHouse(doc: jsPDF) {
    this.y = this.y + 30;
    this.x = 20;
    this.checkDocYAxis(doc);
    this.SetHeadingFontSize(doc);
    this.SetBoldFontStyle(doc);
    doc.text("Companies House", this.x, this.y = this.y + 20);
    this.SetNormalFontStyle(doc);
    this.SetStandardFontSize(doc);
    if (this.tempAccessData.isCompaniesHouseAccess) {
      if (this.userDirectorshipData && this.userDirectorshipData.length > 0) {
        this.userDirectorshipData.forEach(item => {
          this.checkDocYAxis(doc);
          this.FormatCompanyHouseItem(doc, item);
        });
      } else {
        if (this.directorshipSearchFail) {
          this.setMessage(doc, this.SearchFail);
        } else {
          this.setMessage(doc, this.NoRelevantDataMessage);
        }
      }
    } else {
      this.setMessage(doc, this.NoSearchSelect);
    }

  }

  FormatAddress(doc: jsPDF) {
    this.y = this.y + 30;
    this.checkDocYAxis(doc);
    this.x = 20;
    this.SetHeadingFontSize(doc);
    this.SetBoldFontStyle(doc);
    doc.text("Address", this.x, this.y = this.y + 20);
    this.SetNormalFontStyle(doc);
    this.SetStandardFontSize(doc);
    if (this.tempAccessData.isAddressAccess) {
      if (this.tempAccessData.isFinancialAccess && this.tempAccessData.isAddressAccess) {
        if (!this.contactData.postalCode) {
          this.setMessage(doc, this.postCodeRequiredMessage);
        } else {
          if (this.financialSearchFail) {
            this.setMessage(doc, this.SearchFail);
          } else {
            if (this.financialData?.data?.CompleteResponse?.Response) {
              if (this.financialData?.data?.CompleteResponse?.Response?.People?.length > 0) {
                this.financialData?.data?.CompleteResponse?.Response?.People.forEach(people => {

                  this.checkDocYAxis(doc);
                  this.x = this.x + 20;
                  let occupancyPeriod = (people?.OccupancyPeriod?.From ? people?.OccupancyPeriod?.From : '') + '-' + (people?.OccupancyPeriod?.To ? people?.OccupancyPeriod?.To : '');
                  //let recencyDay = (people?.RecencyDate?.Day ? people?.RecencyDate?.Day + '-' : '') + (people?.RecencyDate?.Month ? people?.RecencyDate?.Month + '-' : '') + (people?.RecencyDate?.Year ? people?.RecencyDate?.Year : '')
                  let recencyDay = this.FormatDate(people?.RecencyDate, "address");
                  //let dob = people?.Person?.DOB?.Day + '-' + people?.Person?.DOB?.Month + '-' + people?.Person?.DOB?.Year;
                  let dob = this.FormatDate(people?.Person?.DOB, "address");

                  doc.text("Name: " + (people?.Person?.Name?.Prefix + '. ' + people?.Person?.Name?.Fullname), this.x, this.y = this.y + 20);
                  doc.text("Occupancy Period: " + occupancyPeriod, this.x, this.y = this.y + 20);
                  doc.text("Residency Score: " + people?.ResidencyScore, this.x, this.y = this.y + 20);
                  doc.text("Recency Date: " + recencyDay, this.x, this.y = this.y + 20);
                  doc.text("DOB: " + dob, this.x, this.y = this.y + 20);
                  this.checkDocYAxis(doc);
                  if (people?.LandlineNumbers?.Number.length > 0) {
                    doc.text("Landine Numbers: ", this.x, this.y = this.y + 20);
                    people.LandlineNumbers.Number.forEach(landline => {
                      if (landline?.Number) {
                        doc.text(landline.Number, this.x + 20, this.y = this.y + 20);
                      }
                    });
                  }
                  this.checkDocYAxis(doc);
                  if (people?.MobileNumbers?.Number?.length > 0) {
                    doc.text("Mobiles: ", this.x, this.y = this.y + 20);
                    people?.MobileNumbers?.Number?.forEach(mobile => {
                      if (mobile?.Number) {
                        doc.text(mobile.Number, this.x + 20, this.y = this.y + 20);
                      }
                    });
                  }
                  this.checkDocYAxis(doc);
                  if (people?.Emails?.length > 0) {
                    doc.text("Emails: ", this.x, this.y = this.y + 20);
                    people?.Emails?.forEach((email: string) => {
                      if (email) {
                        doc.text(email.toLowerCase(), this.x + 20, this.y = this.y + 20);
                      }
                    });
                  }
                  this.checkDocYAxis(doc);
                  doc.setFont("", "bold");
                  doc.text("Address", this.x, this.y = this.y + 20);
                  doc.setFont("", "normal");
                  if (people?.Address?.AddressLine1) {
                    doc.text(people?.Address?.AddressLine1, this.x + 20, this.y = this.y + 20);
                  }
                  if (people?.Address?.AddressLine2) {
                    doc.text(people?.Address?.AddressLine2, this.x + 20, this.y = this.y + 20);
                  }
                  if (people?.Address?.AddressLine3) {
                    doc.text(people?.Address?.AddressLine3, this.x + 20, this.y = this.y + 20);
                  }
                  if (people?.Address?.AddressLine4) {
                    doc.text(people?.Address?.AddressLine4, this.x + 20, this.y = this.y + 20);
                  }
                  if (people?.Address?.AddressLine5) {
                    doc.text(people?.Address?.AddressLine5, this.x + 20, this.y = this.y + 20);
                  }
                  if (people?.Address?.AddressLine6) {
                    doc.text(people?.Address?.AddressLine6, this.x + 20, this.y = this.y + 20);
                  }
                  if (people?.Address?.Country) {
                    doc.text(people?.Address?.Country, this.x + 20, this.y = this.y + 20);
                  }
                  if (people?.Address?.Postcode) {
                    doc.text(people?.Address?.Postcode, this.x + 20, this.y = this.y + 20);
                  }

                  this.y = this.y + 20;
                  this.checkDocYAxis(doc);
                  doc.setFont("", "bold");
                  doc.text("Additional Addresses ", this.x, this.y = this.y + 20);
                  doc.setFont("", "normal");

                  if (people?.AdditionalAddresses?.length > 0) {
                    people?.AdditionalAddresses.forEach((additional, index) => {
                      this.checkDocYAxis(doc);
                      this.x = this.x + 20;

                      let additionaloccupancyPeriod = (additional?.AdditionalAddress?.OccupancyPeriod?.From ? additional?.AdditionalAddress?.OccupancyPeriod?.From + '-' : '') + (additional?.AdditionalAddress?.OccupancyPeriod?.To ? additional?.AdditionalAddress?.OccupancyPeriod?.To : '');
                      //let additionalrecencyDay = (additional?.AdditionalAddress?.RecencyDate?.Day ? additional?.AdditionalAddress?.RecencyDate?.Day + '-' : '') + (additional?.AdditionalAddress?.RecencyDate?.Month ? additional?.AdditionalAddress?.RecencyDate?.Month + '-' : '') + (additional?.AdditionalAddress?.RecencyDate?.Year ? additional?.AdditionalAddress?.RecencyDate?.Year : '');
                      let additionalrecencyDay = this.FormatDate(additional?.AdditionalAddress?.RecencyDate, "address");

                      doc.text("Name: " + (additional?.AdditionalAddress?.Name?.Prefix + '. ' + additional?.AdditionalAddress?.Name?.Fullname), this.x, this.y = this.y + 20);
                      doc.text("Occupancy Period: " + additionaloccupancyPeriod, this.x, this.y = this.y + 20);
                      doc.text("Residency Score: " + additional?.AdditionalAddress?.ResidencyScore, this.x, this.y = this.y + 20);
                      doc.text("Recency Date: " + additionalrecencyDay, this.x, this.y = this.y + 20);

                      this.checkDocYAxis(doc);

                      if (additional?.AdditionalAddress?.LandlineNumbers.Number?.length > 0) {
                        doc.text("Landine Numbers: ", this.x, this.y = this.y + 20);
                        additional?.AdditionalAddress?.LandlineNumbers.Number?.forEach(landline => {
                          if (landline?.Number) {
                            doc.text(landline.Number, this.x + 20, this.y = this.y + 20);
                          }
                        });
                      }
                      this.checkDocYAxis(doc);
                      if (additional?.AdditionalAddress?.MobileNumbers.Number?.length > 0) {
                        doc.text("Mobiles: ", this.x, this.y = this.y + 20);
                        additional?.AdditionalAddress?.MobileNumbers.Number?.forEach(mobile => {
                          if (mobile?.Number) {
                            doc.text(mobile?.Number, this.x + 20, this.y = this.y + 20);
                          }
                        });
                      }
                      this.checkDocYAxis(doc);
                      if (additional?.AdditionalAddress?.Emails?.length > 0) {
                        doc.text("Emails: ", this.x, this.y = this.y + 20);
                        additional?.AdditionalAddress?.Emails?.forEach((email: string) => {
                          if (email) {
                            doc.text(email.toLowerCase(), this.x + 20, this.y = this.y + 20);
                          }
                        });
                      }

                      this.checkDocYAxis(doc);
                      doc.setFont("", "bold");
                      doc.text("Address", this.x, this.y = this.y + 20);
                      doc.setFont("", "normal");
                      if (additional?.AdditionalAddress?.Address?.AddressLine1) {
                        doc.text(additional?.AdditionalAddress?.Address?.AddressLine1, this.x + 20, this.y = this.y + 20);
                      }
                      if (additional?.AdditionalAddress?.Address?.AddressLine2) {
                        doc.text(additional?.AdditionalAddress?.Address?.AddressLine2, this.x + 20, this.y = this.y + 20);
                      }
                      if (additional?.AdditionalAddress?.Address?.AddressLine3) {
                        doc.text(additional?.AdditionalAddress?.Address?.AddressLine3, this.x + 20, this.y = this.y + 20);
                      }
                      if (additional?.AdditionalAddress?.Address?.AddressLine4) {
                        doc.text(additional?.AdditionalAddress?.Address?.AddressLine4, this.x + 20, this.y = this.y + 20);
                      }
                      if (additional?.AdditionalAddress?.Address?.AddressLine5) {
                        doc.text(additional?.AdditionalAddress?.Address?.AddressLine5, this.x + 20, this.y = this.y + 20);
                      }
                      if (additional?.AdditionalAddress?.Address?.AddressLine6) {
                        doc.text(additional?.AdditionalAddress?.Address?.AddressLine6, this.x + 20, this.y = this.y + 20);
                      }
                      if (additional?.AdditionalAddress?.Address?.Country) {
                        doc.text(additional?.AdditionalAddress?.Address?.Country, this.x + 20, this.y = this.y + 20);
                      }
                      if (additional?.AdditionalAddress?.Address?.Postcode) {
                        doc.text(additional?.AdditionalAddress?.Address?.Postcode, this.x + 20, this.y = this.y + 20);
                      }
                      this.x = 20;
                      this.AddLine(doc);
                    })
                  }
                  this.x = 20;
                });
              } else {
                this.setMessage(doc, this.NoRelevantDataMessage);
              }
            } else {
              this.setMessage(doc, this.NoRelevantDataMessage);
            }
          }

        }
      } else if (!this.tempAccessData.isFinancialAccess && this.tempAccessData.isAddressAccess) {
        if (this.contactData.postalCode) {
          this.setMessage(doc, this.NoRelevantDataMessage);
        } else {
          this.setMessage(doc, this.postCodeRequiredMessage);
        }
      }
    } else {
      this.setMessage(doc, this.NoSearchSelect);
    }
  }

  FormatAML(doc: jsPDF) {
    this.y = this.y + 30;
    this.checkDocYAxis(doc);
    this.x = 20;
    this.SetHeadingFontSize(doc);
    this.SetBoldFontStyle(doc);
    doc.text("AML", this.x, this.y = this.y + 20);
    this.SetNormalFontStyle(doc);
    this.SetStandardFontSize(doc);
    if (this.tempAccessData.isAMLAccess) {
      if (this.AMLResult != null && this.AMLResult.sanctions != null) {
        if (this.AMLResult?.sanctions?.length > 0) {
          this.AMLResult?.sanctions?.forEach(sanction => {
            this.x = 20;
            this.WriteAMLResultDoc(sanction, doc);
            this.AddLine(doc);
            this.x = 20;
          });
        } else {
          this.setMessage(doc, this.NoRelevantDataMessage);
        }
      } else {
        this.WriteAMLResultDoc(this.AMLResult?.Results?.Sanction?.Match, doc);
      }
    } else {
      this.setMessage(doc, this.NoSearchSelect);
    }
  }

  WriteAMLResultDoc(sanction: any, doc: jsPDF) {
    this.checkDocYAxis(doc);
    if (sanction?.name) {
      doc.text("Name : " + sanction?.name, this.x, this.y = this.y + 20);
    }
    if (sanction?.recency) {
      doc.text("Recency : " + sanction?.recency, this.x, this.y = this.y + 20);
    }
    if (sanction?.source) {
      doc.text("Source : " + sanction?.source, this.x, this.y = this.y + 20);
    }
    if (sanction?.type) {
      doc.text("Type : " + sanction?.type, this.x, this.y = this.y + 20);
    }
    if (sanction?.dob) {
      doc.text("DOB : " + sanction?.dob, this.x, this.y = this.y + 20);
    }
    if (sanction?.addresses && sanction?.addresses?.length > 0 && this.isValidAMLAddress(sanction?.addresses)) {
      doc.text("Addresses : ", this.x, this.y = this.y + 20);
      let addresses: any[] = sanction?.addresses;
      addresses.forEach(address => {
        if ((address.address1 != null && address.address1 != '') || (address.address2 != null && address.address2 != '') || (address.address3 != null && address.address3 != '') || (address.postcode != null && address.postcode != '') || (address.city != null && address.city != '') || (address.country != null && address.country != '')) {
          doc.text("Address : ", this.x, this.y = this.y + 20);
          if ((address.address1 != null && address.address1 != ''))
            doc.text("Address line 1 : " + address.address1, this.x, this.y = this.y + 20);
          if ((address.address2 != null && address.address2 != ''))
            doc.text("Address line 2 : " + address.address1, this.x, this.y = this.y + 20);
          if ((address.address3 != null && address.address3 != ''))
            doc.text("Address line 3 : " + address.address3, this.x, this.y = this.y + 20);
          if ((address.postcode != null && address.postcode != ''))
            doc.text("Post code : " + address.postcode, this.x, this.y = this.y + 20);
          if ((address.city != null && address.city != ''))
            doc.text("City : " + address.city, this.x, this.y = this.y + 20);
          if ((address.country != null && address.country != ''))
            doc.text("Country : " + address.country, this.x, this.y = this.y + 20);
        }
      });
    }
    this.checkDocYAxis(doc);
    if (sanction?.alias != null) {
      if (sanction?.alias?.length > 0) {
        doc.text("Aliases : ", this.x, this.y = this.y + 20);
        sanction?.alias.forEach(alias => {
          doc.text(alias, this.x + 20, this.y = this.y + 20);
        })
      }
    }
    this.checkDocYAxis(doc);
    if (sanction?.positions != null) {
      if (sanction?.positions?.length > 0) {
        doc.text("Positions : ", this.x, this.y = this.y + 20);
        sanction?.positions.forEach(position => {
          if (position) {
            let splitedException = doc.splitTextToSize(position, 500);
            splitedException.forEach((title, index) => {
              if (index === 0) {
                doc.text(title, this.x + 20, this.y = this.y + 20);
              } else {
                doc.text(title, this.x + 20, this.y = this.y + 20);
              }
            })
          }
        });
      }
    }
    this.checkDocYAxis(doc);
    if (sanction?.exceptions != null) {
      if (sanction?.exceptions?.length > 0) {
        doc.text("Details : ", this.x, this.y = this.y + 20);
        sanction?.exceptions.forEach(exception => {
          if (exception) {
            let splitedException = doc.splitTextToSize(exception, 500);
            splitedException.forEach((title, index) => {
              if (index === 0) {
                doc.text(title, this.x + 20, this.y = this.y + 20);
              } else {
                doc.text(title, this.x + 20, this.y = this.y + 20);
              }
            })
          }
        });
      }
    }
  }

  async FormatAVA(doc: jsPDF) {
    this.y = this.y + 30;
    if (this.tempAccessData.isAVAAccess) {
      if (this.contactData.vRN) {
        if (this.avaSearchFail) {
          this.SetHeadingFontSize(doc);
          this.SetBoldFontStyle(doc);
          doc.text('AVA', this.x, this.y = this.y + 20);
          this.SetStandardFontSize(doc);
          this.setMessage(doc, this.SearchFail);
        } else {
          doc.addPage();
          this.y = 40;
          this.SetHeadingFontSize(doc);
          this.SetBoldFontStyle(doc);
          doc.text('AVA', this.x, this.y = this.y + 20);
          try {
            var domAVA = document.getElementById("avaSearch");
            domAVA.classList.add("active");
            await html2canvas(document.querySelector("#avaVehicleDetailID")).then(canvas => {
              doc.text('Vehicle Details', this.x, this.y = this.y + 20);
              this.SetNormalFontStyle(doc);
              this.SetStandardFontSize(doc);
              var context = canvas.getContext('2d');
              context.fillStyle = '#004879';
              let canvasImg = canvas.toDataURL("image/jpeg")

              doc.addImage(canvasImg, 'png', this.x, this.y = this.y + 20, 550, 350);
              domAVA.classList.remove("active");
            });
          }
          catch { }
          try {
            let domAVA = document.getElementById("avaSearch");
            domAVA.classList.add("active");
            await html2canvas(document.querySelector("#avaStatusID")).then(canvas => {
              doc.addPage();
              this.y = 40;
              this.SetHeadingFontSize(doc);
              this.SetBoldFontStyle(doc);
              doc.text('Status', this.x, this.y = this.y + 20);
              this.SetNormalFontStyle(doc);
              this.SetStandardFontSize(doc);
              var context = canvas.getContext('2d');
              let avaCaution = canvas.toDataURL("image/jpeg")
              doc.addImage(avaCaution, 'png', this.x, this.y = this.y + 20, 450, 250);
              this.y = this.y + canvas.getBoundingClientRect().height;
              domAVA.classList.remove("active");
            });
          }
          catch { }

          let cautionHeaderAdded: boolean = false;

          try {
            let domAVA = document.getElementById("avaSearch");
            domAVA.classList.add("active");
            await html2canvas(document.querySelector("#avaCaution")).then(canvas => {
              doc.addPage();
              this.y = 40;
              if (!cautionHeaderAdded) {
                this.SetHeadingFontSize(doc);
                this.SetBoldFontStyle(doc);
                doc.text('Caution Data', this.x, this.y = this.y + 20);
                this.SetNormalFontStyle(doc);
                this.SetStandardFontSize(doc);
                cautionHeaderAdded = true;
              }
              var context = canvas.getContext('2d');
              let avaCaution = canvas.toDataURL("image/jpeg")
              doc.addImage(avaCaution, 'png', this.x, this.y = this.y + 20, 550, 350);
              domAVA.classList.remove("active");
            });
          }
          catch { }

          try {
            let domAVA = document.getElementById("avaSearch");
            domAVA.classList.add("active");
            await html2canvas(document.querySelector("#cautionStolen")).then(canvas => {
              doc.addPage();
              this.y = 40;
              if (!cautionHeaderAdded) {
                this.SetHeadingFontSize(doc);
                this.SetBoldFontStyle(doc);
                doc.text('Caution Data', this.x, this.y = this.y + 20);
                this.SetNormalFontStyle(doc);
                this.SetStandardFontSize(doc);
                cautionHeaderAdded = true;
              }
              this.SetHeadingFontSize(doc);
              this.SetBoldFontStyle(doc);
              doc.text('Stolen', this.x, this.y = this.y + 20);
              this.SetNormalFontStyle(doc);
              this.SetStandardFontSize(doc);
              var context = canvas.getContext('2d');
              let avaCaution = canvas.toDataURL("image/jpeg")
              doc.addImage(avaCaution, 'png', this.x, this.y = this.y + 20, 550, 350);
              domAVA.classList.remove("active");
            });
          }
          catch { }

          try {
            let domAVA = document.getElementById("avaSearch");
            domAVA.classList.add("active");
            await html2canvas(document.querySelector("#cautionHighRisk")).then(canvas => {
              //if (this.y <= 350) {
              //  this.y = this.y + 20;
              //} else {
              //  doc.addPage();
              //  this.y = 40;
              //}
              doc.addPage();
              this.y = 40;
              if (!cautionHeaderAdded) {
                this.SetHeadingFontSize(doc);
                this.SetBoldFontStyle(doc);
                doc.text('Caution Data', this.x, this.y = this.y + 20);
                this.SetNormalFontStyle(doc);
                this.SetStandardFontSize(doc);
                cautionHeaderAdded = true;
              }
              this.SetHeadingFontSize(doc);
              this.SetBoldFontStyle(doc);
              doc.text('Hign Risk', this.x, this.y = this.y + 20);
              this.SetNormalFontStyle(doc);
              this.SetStandardFontSize(doc);
              var context = canvas.getContext('2d');
              let avaCaution = canvas.toDataURL("image/jpeg")
              doc.addImage(avaCaution, 'png', this.x, this.y = this.y + 20, 550, 350);
              domAVA.classList.remove("active");
            });
          }
          catch { }

          try {
            let domAVA = document.getElementById("avaSearch");
            domAVA.classList.add("active");
            await html2canvas(document.querySelector("#cautionFinanca")).then(canvas => {
              //if (this.y <= 350) {
              //  this.y = this.y + 20;
              //} else {
              //  doc.addPage();
              //  this.y = 40;
              //}
              doc.addPage();
              this.y = 40;
              if (!cautionHeaderAdded) {
                this.SetHeadingFontSize(doc);
                this.SetBoldFontStyle(doc);
                doc.text('Caution Data', this.x, this.y = this.y + 20);
                this.SetNormalFontStyle(doc);
                this.SetStandardFontSize(doc);
                cautionHeaderAdded = true;
              }
              this.SetHeadingFontSize(doc);
              this.SetBoldFontStyle(doc);
              doc.text('Finance', this.x, this.y = this.y + 20);
              this.SetNormalFontStyle(doc);
              this.SetStandardFontSize(doc);
              var context = canvas.getContext('2d');
              let avaCaution = canvas.toDataURL("image/jpeg")
              doc.addImage(avaCaution, 'png', this.x, this.y = this.y + 20, 550, 350);
              domAVA.classList.remove("active");
            });
          }
          catch { }

          try {
            let domAVA = document.getElementById("avaSearch");
            domAVA.classList.add("active");
            await html2canvas(document.querySelector("#cautionCondition")).then(canvas => {
              //if (this.y <= 350) {
              //  this.y = this.y + 20;
              //} else {
              //  doc.addPage();
              //  this.y = 40;
              //}
              doc.addPage();
              this.y = 40;
              if (!cautionHeaderAdded) {
                this.SetHeadingFontSize(doc);
                this.SetBoldFontStyle(doc);
                doc.text('Caution Data', this.x, this.y = this.y + 20);
                this.SetNormalFontStyle(doc);
                this.SetStandardFontSize(doc);
                cautionHeaderAdded = true;
              }
              this.SetHeadingFontSize(doc);
              this.SetBoldFontStyle(doc);
              doc.text('Condition', this.x, this.y = this.y + 20);
              this.SetNormalFontStyle(doc);
              this.SetStandardFontSize(doc);
              var context = canvas.getContext('2d');
              let avaCaution = canvas.toDataURL("image/jpeg")
              doc.addImage(avaCaution, 'png', this.x, this.y = this.y + 20, 550, 350);
              domAVA.classList.remove("active");
            });
          }
          catch { }

          try {
            let domAVA = document.getElementById("avaSearch");
            domAVA.classList.add("active");
            await html2canvas(document.querySelector("#cautionScrapped")).then(canvas => {
              //if (this.y <= 350) {
              //  this.y = this.y + 20;
              //} else {
              //  doc.addPage();
              //  this.y = 40;
              //}
              doc.addPage();
              this.y = 40;
              if (!cautionHeaderAdded) {
                this.SetHeadingFontSize(doc);
                this.SetBoldFontStyle(doc);
                doc.text('Caution Data', this.x, this.y = this.y + 20);
                this.SetNormalFontStyle(doc);
                this.SetStandardFontSize(doc);
                cautionHeaderAdded = true;
              }
              this.SetHeadingFontSize(doc);
              this.SetBoldFontStyle(doc);
              doc.text('cautionScrapped', this.x, this.y = this.y + 20);
              this.SetNormalFontStyle(doc);
              this.SetStandardFontSize(doc);
              var context = canvas.getContext('2d');
              let avaCaution = canvas.toDataURL("image/jpeg")
              doc.addImage(avaCaution, 'png', this.x, this.y = this.y + 20, 550, 350);
              domAVA.classList.remove("active");
            });
          }
          catch { }

          try {
            let domAVA = document.getElementById("avaSearch");
            domAVA.classList.add("active");
            await html2canvas(document.querySelector("#cautionPlateChange")).then(canvas => {
              //if (this.y <= 350) {
              //  this.y = this.y + 20;
              //} else {
              //  doc.addPage();
              //  this.y = 40;
              //}
              doc.addPage();
              this.y = 40;
              if (!cautionHeaderAdded) {
                this.SetHeadingFontSize(doc);
                this.SetBoldFontStyle(doc);
                doc.text('Caution Data', this.x, this.y = this.y + 20);
                this.SetNormalFontStyle(doc);
                this.SetStandardFontSize(doc);
                cautionHeaderAdded = true;
              }
              this.SetHeadingFontSize(doc);
              this.SetBoldFontStyle(doc);
              doc.text('Plate Change', this.x, this.y = this.y + 20);
              this.SetNormalFontStyle(doc);
              this.SetStandardFontSize(doc);
              var context = canvas.getContext('2d');
              let avaCaution = canvas.toDataURL("image/jpeg")
              doc.addImage(avaCaution, 'png', this.x, this.y = this.y + 20, 550, 350);
              domAVA.classList.remove("active");
            });
          }
          catch { }

          try {
            let domAVA = document.getElementById("avaSearch");
            domAVA.classList.add("active");
            await html2canvas(document.querySelector("#cautionExported")).then(canvas => {
              //if (this.y <= 350) {
              //  this.y = this.y + 20;
              //} else {
              //  doc.addPage();
              //  this.y = 40;
              //}
              doc.addPage();
              this.y = 40;
              if (!cautionHeaderAdded) {
                this.SetHeadingFontSize(doc);
                this.SetBoldFontStyle(doc);
                doc.text('Caution Data', this.x, this.y = this.y + 20);
                this.SetNormalFontStyle(doc);
                this.SetStandardFontSize(doc);
                cautionHeaderAdded = true;
              }
              this.SetHeadingFontSize(doc);
              this.SetBoldFontStyle(doc);
              doc.text('Exported', this.x, this.y = this.y + 20);
              this.SetNormalFontStyle(doc);
              this.SetStandardFontSize(doc);
              var context = canvas.getContext('2d');
              let avaCaution = canvas.toDataURL("image/jpeg")
              doc.addImage(avaCaution, 'png', this.x, this.y = this.y + 20, 550, 350);
              domAVA.classList.remove("active");
            });
          }
          catch { }

          try {
            let domAVA = document.getElementById("avaSearch");
            domAVA.classList.add("active");
            await html2canvas(document.querySelector("#cautionImported")).then(canvas => {
              //if (this.y <= 350) {
              //  this.y = this.y + 20;
              //} else {
              //  doc.addPage();
              //  this.y = 40;
              //}
              doc.addPage();
              this.y = 40;
              if (!cautionHeaderAdded) {
                this.SetHeadingFontSize(doc);
                this.SetBoldFontStyle(doc);
                doc.text('Caution Data', this.x, this.y = this.y + 20);
                this.SetNormalFontStyle(doc);
                this.SetStandardFontSize(doc);
                cautionHeaderAdded = true;
              }
              this.SetHeadingFontSize(doc);
              this.SetBoldFontStyle(doc);
              doc.text('Imported', this.x, this.y = this.y + 20);
              this.SetNormalFontStyle(doc);
              this.SetStandardFontSize(doc);
              var context = canvas.getContext('2d');
              let avaCaution = canvas.toDataURL("image/jpeg")
              doc.addImage(avaCaution, 'png', this.x, this.y = this.y + 20, 550, 350);
              domAVA.classList.remove("active");
            });
          }
          catch { }

          try {
            let domAVA = document.getElementById("avaSearch");
            domAVA.classList.add("active");
            await html2canvas(document.querySelector("#cautionColourChange")).then(canvas => {
              //if (this.y <= 350) {
              //  this.y = this.y + 20;
              //} else {
              //  doc.addPage();
              //  this.y = 40;
              //}
              doc.addPage();
              this.y = 40;
              if (!cautionHeaderAdded) {
                this.SetHeadingFontSize(doc);
                this.SetBoldFontStyle(doc);
                doc.text('Caution Data', this.x, this.y = this.y + 20);
                this.SetNormalFontStyle(doc);
                this.SetStandardFontSize(doc);
                cautionHeaderAdded = true;
              }
              this.SetHeadingFontSize(doc);
              this.SetBoldFontStyle(doc);
              doc.text('Color Change', this.x, this.y = this.y + 20);
              this.SetNormalFontStyle(doc);
              this.SetStandardFontSize(doc);
              var context = canvas.getContext('2d');
              let avaCaution = canvas.toDataURL("image/jpeg")
              doc.addImage(avaCaution, 'png', this.x, this.y = this.y + 20, 550, 350);
              domAVA.classList.remove("active");
            });
          }
          catch { }

          try {
            let domAVA = document.getElementById("avaSearch");
            domAVA.classList.add("active");
            await html2canvas(document.querySelector("#cautionVehicleIdentityCheck")).then(canvas => {
              //if (this.y <= 350) {
              //  this.y = this.y + 20;
              //} else {
              //  doc.addPage();
              //  this.y = 40;
              //}
              doc.addPage();
              this.y = 40;
              if (!cautionHeaderAdded) {
                this.SetHeadingFontSize(doc);
                this.SetBoldFontStyle(doc);
                doc.text('Caution Data', this.x, this.y = this.y + 20);
                this.SetNormalFontStyle(doc);
                this.SetStandardFontSize(doc);
                cautionHeaderAdded = true;
              }
              this.SetHeadingFontSize(doc);
              this.SetBoldFontStyle(doc);
              doc.text('Vehicle Identity Check', this.x, this.y = this.y + 20);
              this.SetNormalFontStyle(doc);
              this.SetStandardFontSize(doc);
              var context = canvas.getContext('2d');
              let avaCaution = canvas.toDataURL("image/jpeg")
              doc.addImage(avaCaution, 'png', this.x, this.y = this.y + 20, 550, 350);
              domAVA.classList.remove("active");
            });
          }
          catch { }


          try {
            let domAVA = document.getElementById("avaSearch");
            domAVA.classList.add("active");
            await html2canvas(document.querySelector("#avaMessages")).then(canvas => {
              doc.addPage();
              this.y = 40;
              this.SetHeadingFontSize(doc);
              this.SetBoldFontStyle(doc);
              doc.text('Messages', this.x, this.y = this.y);
              this.SetNormalFontStyle(doc);
              this.SetStandardFontSize(doc);
              var context = canvas.getContext('2d');
              let avaMessages = canvas.toDataURL("image/jpeg")
              doc.addImage(avaMessages, 'png', this.x, this.y = this.y + 20, 550, 350);
              domAVA.classList.remove("active");
            });
          }
          catch { }

          if (this.avaResult?.vehicleData?.ApprovedProgramCheckResult?.PreviousSearchesDetails) {
            if (this.avaResult?.vehicleData?.ApprovedProgramCheckResult?.PreviousSearchesDetails?.SearchItems && this.avaResult?.vehicleData?.ApprovedProgramCheckResult?.PreviousSearchesDetails?.SearchItems?.length > 0) {
              doc.addPage();
              this.y = 40;
              this.SetBoldFontStyle(doc);
              doc.text('Previous Searches', this.x, this.y = this.y);
              this.SetNormalFontStyle(doc);
              autoTable(doc, { html: '#prviusSearchesID', startY: this.y = this.y + 20 });
            } else {
              this.y = this.y + 20;
              this.SetBoldFontStyle(doc);
              doc.text('Previous Searches', this.x, this.y = this.y);
              this.setMessage(doc, this.NoRelevantDataMessage);
            }

          }

          if (this.avaResult?.vehicleData?.ApprovedProgramCheckResult?.KeeperChangesDetails) {
            if (this.avaResult?.vehicleData?.ApprovedProgramCheckResult?.KeeperChangesDetails?.KeeperChanges1 && this.avaResult?.vehicleData?.ApprovedProgramCheckResult?.KeeperChangesDetails?.KeeperChanges1?.length > 0) {
              doc.addPage();
              this.y = 40;
              this.SetStandardFontSize(doc);
              this.SetBoldFontStyle(doc);
              doc.text('Keeper Changes', this.x, this.y = this.y);
              this.SetNormalFontStyle(doc);
              autoTable(doc, { html: '#keeperChangesID', startY: this.y = this.y + 20 });
            } else {
              this.y = this.y + 20;
              this.SetBoldFontStyle(doc);
              doc.text('Keeper Changes', this.x, this.y = this.y);
              this.setMessage(doc, this.NoRelevantDataMessage);
            }
          }

          if (this.avaResult?.mot?.Response?.DataItems?.MotHistory?.RecordList) {
            if (this.avaResult?.mot?.Response?.DataItems?.MotHistory?.RecordList?.length > 0) {
              doc.addPage();
              this.y = 40;
              this.SetStandardFontSize(doc);
              this.SetBoldFontStyle(doc);
              doc.text('MOT History', this.x, this.y = this.y);
              doc.text("Test Date", this.x, this.y = this.y + 20);
              doc.text("Expiry Date", this.x = this.x + 100, this.y);
              doc.text("Test Result", this.x = this.x + 80, this.y);
              doc.text("Odm Reading", this.x = this.x + 100, this.y);
              doc.text("Mot Test Number", this.x = this.x + 110, this.y);
              this.SetNormalFontStyle(doc);

              this.x = 20;
              this.FormatMOTHistory(doc);
            } else {
              this.y = this.y + 20;
              this.SetBoldFontStyle(doc);
              doc.text('MOT History', this.x, this.y = this.y);
              this.setMessage(doc, this.NoRelevantDataMessage);
            }
          }

          if (this.avaResult?.advert?.AdvertSourceData && this.avaResult?.advert?.AdvertSourceData.length > 0) {
            this.SetBoldFontStyle(doc);
            this.x = 20;
            doc.text("Advert", this.x, this.y = this.y + 20);

            for (var i = 0; i < this.avaResult?.advert?.AdvertSourceData?.length; i++) {
              this.checkDocYAxis(doc);

              this.SetStandardFontSize(doc);
              this.SetNormalFontStyle(doc);
              let postedOn = this.FormatDate(this.avaResult?.advert.AdvertSourceData[i]?.VehiclePostedOn, "avaadvert");
              this.x = 40;
              this.SetBoldFontStyle(doc);
              doc.text("Title: ", this.x, this.y = this.y + 20);
              this.SetNormalFontStyle(doc);
              if (this.avaResult.advert.AdvertSourceData[i].AdvertTitle) {
                let splitedTitle = doc.splitTextToSize(this.avaResult?.advert?.AdvertSourceData[i]?.AdvertTitle, 500);
                splitedTitle.forEach((title, index) => {
                  if (index === 0) {
                    //doc.text(title, this.x + 60, this.y);
                    this.FormatLinksForAdvert(doc, title, this.avaResult?.advert?.AdvertSourceData[i]?.AdvertLink, this.x + 30, this.y);
                  } else {
                    //doc.text(title, this.x + 60, this.y = this.y + 20);
                    this.FormatLinksForAdvert(doc, title, this.avaResult.advert.AdvertSourceData[i].AdvertLink, this.x, this.y + 20);
                  }
                })
              }

              this.x = 20;
              this.y = this.y + 40;
              this.checkDocYAxis(doc);

              try {
                doc.addImage(this.avaResult?.advert?.AdvertSourceData[i].vehicleBase64, 'png', this.x, this.y, 350, 220);
              }
              catch (e) {
                console.log(e);
              }

              this.y = this.y + 250;
              this.x = 20;
              this.checkDocYAxis(doc);

              this.SetBoldFontStyle(doc);
              doc.text("Source: ", this.x, this.y = this.y + 20);
              this.SetNormalFontStyle(doc);
              doc.text(this.avaResult.advert.AdvertSourceData[i].AdvertSource, this.x + 60, this.y);
              this.SetBoldFontStyle(doc);
              doc.text("Mileage: ", this.x, this.y = this.y + 20);
              this.SetNormalFontStyle(doc);
              doc.text(this.avaResult.advert.AdvertSourceData[i].VehicleMileage, this.x + 60, this.y);
              this.SetBoldFontStyle(doc);
              doc.text("Posted on: ", this.x, this.y = this.y + 20);
              this.SetNormalFontStyle(doc);
              doc.text(postedOn, this.x + 60, this.y);
              this.SetBoldFontStyle(doc);
              doc.text("Price: ", this.x, this.y = this.y + 20);
              this.SetNormalFontStyle(doc);
              doc.text(this.avaResult.advert.AdvertSourceData[i].VehiclePrice, this.x + 60, this.y);

              this.x = 20;
              this.checkDocYAxis(doc);
              this.SetBoldFontStyle(doc);
              doc.text("Description", this.x, this.y = this.y + 40);
              this.SetNormalFontStyle(doc);
              let splitedDescription = doc.splitTextToSize(this.avaResult.advert.AdvertSourceData[i].VehicleDescription, 500);
              splitedDescription.forEach(text => {
                doc.text(text, this.x, this.y = this.y + 20);
                this.checkDocYAxis(doc);
              })
            }
          } else {
            this.y = this.y + 20;
            this.SetBoldFontStyle(doc);
            doc.text('Adverts', this.x, this.y = this.y + 20);
            this.setMessage(doc, this.NoRelevantDataMessage);
          }

          try {
            let AVADom = document.getElementById("avaSearch");
            AVADom.classList.remove("active");
          } catch { }
        }
      } else {
        this.SetHeadingFontSize(doc);
        this.SetBoldFontStyle(doc);
        doc.text('AVA', this.x, this.y = this.y + 20);
        this.SetNormalFontStyle(doc);
        this.setMessage(doc, this.vrnRequiredMessage);
      }
    } else {
      this.checkDocYAxis(doc);
      this.SetHeadingFontSize(doc);
      this.SetBoldFontStyle(doc);
      doc.text('AVA', this.x, this.y = this.y + 20);
      this.SetNormalFontStyle(doc);
      this.setMessage(doc, this.NoSearchSelect);
    }
  }

  AddLine(doc) {
    this.y = this.y + 20
    for (let i = 0; i < 250; i++) {
      doc.text("-", this.x = this.x + 2, this.y);
    }
    this.x = 40;
  }

  setMessage(doc, message) {
    this.SetStandardFontSize(doc);
    this.SetNormalFontStyle(doc);
    doc.text(message ? message : "", this.x, this.y = this.y + 20);
  }

  checkDocYAxis(doc) {
    if (this.y > 600) {
      doc.addPage();
      this.y = 40;
    }
  }

  SetBoldFontStyle(doc) {
    doc.setFont("Intro Sans-serif", "bold");
  }

  SetNormalFontStyle(doc) {
    doc.setFont("Intro Sans-serif", "normal");
  }

  SetHeadingFontSize(doc) {
    doc.setFontSize(16);
    return doc;
  }

  SetStandardFontSize(doc) {
    doc.setFontSize(12);
    return doc;
  }

  SetItalicFont(doc) {
    doc.setFont("Intro Sans-serif", "italic");
    return doc;
  }

  ClearItalicFont(doc) {
    doc.setFont("Intro Sans-serif", "");
    return doc;
  }

  FormatDate(input: any, condition: string) {
    if (!input && !condition) {
      return "";
    }
    try {
      if (condition === "address") {
        let date = (input?.Day ? input.Day + '-' : '') + (input?.Month ? input?.Month + '-' : '') + (input?.Year ? input?.Year : '')
        return date;
      } else if (condition === "mot") {
        let arrDate = input.split("/");
        let date = arrDate[0] + "-" + arrDate[1] + "-" + arrDate[2];
        return date;
      } else if (condition === "avaadvert") {
        let localDate = new Date(input);
        var year = localDate.getFullYear();
        var month = localDate.getMonth() + 1;      // "+ 1" becouse the 1st month is 0
        var day = localDate.getDate();
        var hour = localDate.getHours();
        var minutes = localDate.getMinutes();
        var date = day + "-" + month + "-" + year + '  ' + hour + ":" + minutes;
        return date;
      } else if (condition === "ccj") {
        let localDate = new Date(input);
        var year = localDate.getFullYear();
        var month = localDate.getMonth() + 1;      // "+ 1" becouse the 1st month is 0
        var day = localDate.getDate();
        var date = day + "-" + month + "-" + year;
        return date;
      }
    } catch { return ""; }
  }

}
