import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AVAResultModel } from 'src/app/demo/V3/search-pages/models/AVAResultModel';
import { ProspectResultModel } from 'src/app/demo/V3/search-pages/models/CounterFraudResultModel';
import { DVLAResultModel, MotHistoryResultModel } from 'src/app/demo/V3/search-pages/models/DVLAResultModel';
import { ExperianResultModel } from 'src/app/demo/V3/search-pages/models/ExperianResultModel';

@Component({
  selector: 'app-uk-motor-result',
  templateUrl: './uk-motor-result.component.html',
  styleUrls: ['./uk-motor-result.component.css', '../../../../shared/shared.searchpage.css']
})
export class UkMotorResultComponent implements OnInit {

  @Input() public ava: AVAResultModel = new AVAResultModel();
  @Input() public dvla: DVLAResultModel = new DVLAResultModel();
  @Input() public prospect: ProspectResultModel = new ProspectResultModel();
  @Input() public experian: ExperianResultModel = new ExperianResultModel();
  @Input() public motHistory: MotHistoryResultModel = new MotHistoryResultModel();
  @Input() public isExperianTabShow: boolean = false;
  @Output() prospectProfileRequestEventEmitter: EventEmitter<string> = new EventEmitter<string>();
  
  constructor() { }

  ngOnInit(): void {
  }

  public prospectProfileRequest() {
    this.prospectProfileRequestEventEmitter.emit("");
  }

}
