import { Component, Input, OnInit } from '@angular/core';
import { MessagingService } from '../../../../../services/messaging.service';
import { MobileResultModel } from '../../../../models/MobileResultModel';

@Component({
  selector: 'app-opensource-result-business',
  templateUrl: './opensource-result-business.component.html',
  styleUrls: ['./opensource-result-business.component.css', '../../../shared/shared.searchpage.css']
})
export class OpensourceResultBusinessComponent implements OnInit {

  @Input() opensource: MobileResultModel = new MobileResultModel();

  constructor(private messageService: MessagingService) { }

  ngOnInit(): void {
  }

}
