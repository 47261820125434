<table class="table table-striped table-hover">
  <tbody>
    <tr>
      <th>Business Name</th>
      <td>{{business?.responseData?.businessNonRegisteredProfileResult?.BusinessName}}</td>
    </tr>
    <tr>
      <th>Earliest Date On Record For Business</th>
      <td>{{business?.responseData?.businessNonRegisteredProfileResult?.Identification?.EarliestKnownDate}}</td>
    </tr>
    <tr>
      <th>Most Recent Date On Record For Business</th>
      <td>{{business?.responseData?.businessNonRegisteredProfileResult?.Identification?.LastUpdated}}</td>
    </tr>
    <tr></tr>
    <tr>
      <th>Most Recent Proprietorship Start Date</th>
      <td>{{business?.responseData?.businessNonRegisteredProfileResult?.OwnershipStartDate}}</td>
    </tr>
    <tr>
      <th>Most Recent Proprietorship End Date</th>
      <td>{{business?.responseData?.businessNonRegisteredProfileResult?.OwnershipEndDate}}</td>
    </tr>
    <tr></tr>
    <tr>
      <th>Business Location</th>
      <td>
        <div *ngIf="business?.responseData?.businessNonRegisteredProfileResult?.Identification?.BusinessLocation?.LocationLine1">
          {{business?.responseData?.businessNonRegisteredProfileResult?.Identification?.BusinessLocation?.LocationLine1}}
        </div>
        <div *ngIf="business?.responseData?.businessNonRegisteredProfileResult?.Identification?.BusinessLocation?.LocationLine2">
          {{business?.responseData?.businessNonRegisteredProfileResult?.Identification?.BusinessLocation?.LocationLine2}}
        </div>
        <div *ngIf="business?.responseData?.businessNonRegisteredProfileResult?.Identification?.BusinessLocation?.LocationLine3">
          {{business?.responseData?.businessNonRegisteredProfileResult?.Identification?.BusinessLocation?.LocationLine3}}
        </div>
        <div *ngIf="business?.responseData?.businessNonRegisteredProfileResult?.Identification?.BusinessLocation?.LocationLine4">
          {{business?.responseData?.businessNonRegisteredProfileResult?.Identification?.BusinessLocation?.LocationLine4}}
        </div>
        <div *ngIf="business?.responseData?.businessNonRegisteredProfileResult?.Identification?.BusinessLocation?.LocationLine5">
          {{business?.responseData?.businessNonRegisteredProfileResult?.Identification?.BusinessLocation?.LocationLine5}}
        </div>
        <div *ngIf="business?.responseData?.businessNonRegisteredProfileResult?.Identification?.BusinessLocation?.Postcode">
          {{business?.responseData?.businessNonRegisteredProfileResult?.Identification?.BusinessLocation?.Postcode}}
        </div>
      </td>
    </tr>
    <tr>
      <th>Premise Type</th>
      <td>{{business?.responseData?.businessNonRegisteredProfileResult?.Identification?.ThomsonData?.PremiseTypeDesc}}</td>
    </tr>
    <tr>
      <th>Business Telephone Number</th>
      <td>{{business?.responseData?.businessNonRegisteredProfileResult?.Identification?.TelephoneNumber}}</td>
    </tr>
    <tr>
      <th>Website</th>
      <td>{{business?.responseData?.businessNonRegisteredProfileResult?.Identification?.ThomsonData?.URL}}</td>
    </tr>
    <tr></tr>
    <tr>
      <th>Business Activities</th>
      <td>{{business?.responseData?.businessNonRegisteredProfileResult?.Identification?.PrincipalActivities}}</td>
    </tr>
    <tr>
      <th>SIC Information</th>
      <td *ngIf="business?.responseData?.businessNonRegisteredProfileResult?.Identification?.SICInformation1992?.length > 0">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>Code</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let sic of business?.responseData?.businessNonRegisteredProfileResult?.Identification?.SICInformation1992">
              <td>{{sic.Code}}</td>
              <td>{{sic.Description}}</td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
    <tr>
      <th>Average Number Of Employees</th>
      <td>{{business?.responseData?.businessNonRegisteredProfileResult?.Identification?.AvgNumEmployees}}</td>
    </tr>
    <tr></tr>
    <tr>
      <th>Contact Information</th>
      <td *ngIf="business?.responseData?.businessNonRegisteredProfileResult?.Identification?.ThomsonData?.Contact?.length > 0">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>Contact Title</th>
              <th>Contact Forename</th>
              <th>Contact Surname</th>
              <th>Contact Position</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let contact of business?.responseData?.businessNonRegisteredProfileResult?.Identification?.ThomsonData?.Contact">
              <td>{{contact?.ContactTitle}}</td>
              <td>{{contact?.ContactForename}}</td>
              <td>{{contact?.ContactSurname}}</td>
              <td>{{contact?.ContactPosition}}</td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
    <tr></tr>
    <tr>
      <th>Notice Of Corrections</th>
      <td>{{business?.responseData?.businessNonRegisteredProfileResult?.Identification?.ConsumerCreditLicenceSummary?.NocFlag == 'N' ? 'No' : 'Yes'}}</td>
    </tr>
    <tr></tr>
    <tr>
      <th>Number Of Consumer Credit Licences (CCLs)</th>
      <td>{{business?.responseData?.businessNonRegisteredProfileResult?.Identification?.CCLs?.CCLDetail?.length}}</td>
    </tr>
    <tr *ngIf="business?.responseData?.businessNonRegisteredProfileResult?.Identification?.CCLs?.CCLDetail?.length > 0">
      <th>CCL Details</th>
      <td>
        <table>
          <thead>
            <tr>
              <th>CCL Record Type</th>
              <th>CCL Number</th>
              <th>CCL Date</th>
              <th>CCL Categories</th>
              <th>Licensee Name</th>
              <th>Date Of Determination To Revoke CCL</th>
              <th>Date Of Determination To Suspend CCL</th>
              <th>Date Of Determination To Vary CCL</th>
              <th>CCL Appeal Date</th>
              <th>CCL Suspension Date</th>
              <th>CCL Suspension Period</th>
              <th>CCL Termination Date</th>
              <th>CCL Lapsed Date</th>
              <th>Trading Names Under CCL</th>
              <th>Licensee Address</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let ccl of business?.responseData?.businessNonRegisteredProfileResult?.Identification?.CCLs?.CCLDetail">
              <td>{{ccl?.RecordType}}</td>
              <td>{{ccl?.LicenceNumber}}</td>
              <td>{{ccl?.LicenceDate}}</td>
              <td>{{ccl?.LicenceCategories}}</td>
              <td>{{ccl?.LicenseeDetail}}</td>
              <td>{{ccl?.DeterminationToRevokeDate}}</td>
              <td>{{ccl?.DeterminationToSuspendDate}}</td>
              <td>{{ccl?.DeterminationToVaryDate}}</td>
              <td>{{ccl?.AppealDate}}</td>
              <td>{{ccl?.SuspensionDate}}</td>
              <td>{{ccl?.SuspendPeriod}}</td>
              <td>{{ccl?.TerminationDate}}</td>
              <td>{{ccl?.LapsedDate}}</td>
              <td>{{ccl?.NumberTradingNames}}</td>
              <td>
                <div>
                  <div *ngIf="ccl?.LicenseeLocation?.LocationLine1">{{ccl?.LicenseeLocation?.LocationLine1}}</div>
                  <div *ngIf="ccl?.LicenseeLocation?.LocationLine2">{{ccl?.LicenseeLocation?.LocationLine2}}</div>
                  <div *ngIf="ccl?.LicenseeLocation?.LocationLine3">{{ccl?.LicenseeLocation?.LocationLine3}}</div>
                  <div *ngIf="ccl?.LicenseeLocation?.LocationLine4">{{ccl?.LicenseeLocation?.LocationLine4}}</div>
                  <div *ngIf="ccl?.LicenseeLocation?.LocationLine5">{{ccl?.LicenseeLocation?.LocationLine5}}</div>
                  <div *ngIf="ccl?.LicenseeLocation?.Postcode">{{ccl?.LicenseeLocation?.Postcode}}</div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  </tbody>
</table>
