import { Keywords } from "./Keywords";

export class Items {
  constructor(
    public title: string,
    public link: string,
    public pubDate: Date,
    public description: string,
    public source: string,
    public rank: number,
    public lexiconcategory: string[],
    public searchDomain: string,
    public lexiconKeywords: Keywords[],
    public negativePoints: number,
    public negativeKeywords: string[],
    public matchedLocation: string,
    public like: boolean,
    public dislike: boolean,
  ) { }
}

export class SearchDomain {
  constructor(
    public Id: string,
    public Name: string,
    public SearchUrl: string,
    public PageUrl: string,
    public CountryBasedUrl: string,
    public UniqueCode: string,
    public IsActive: boolean,
    public RankValue: number
  ) { }
}

export class Token {
  constructor(
    public token: string,
  ) { }
}

export class Item {
  public key: string;
  public itemId: number;
  public itemName: string;
  public licenceType: string;
  public defaultValue: boolean;
  public toggle: boolean;
  public imageUrl: string;
  public ParentId: number;
}
