<div class="background-white result-display-padding form-borderdesign result-view-align" style="border: 1px solid rgba(0,0,0,.125);">
  <ul class="nav nav-tabs" id="ukMotorResultTab" role="tablist">
    <li class="nav-item" *ngIf="isExperianTabShow">
      <a class="nav-link" id="hutmotor-search-tab" data-toggle="tab" href="#hutmotorSearch" role="tab"
         aria-controls="profile" aria-selected="false"><b>HUT Motor</b></a>
    </li>
    <li class="nav-item">
      <a class="nav-link active" id="dvla-search-tab" data-toggle="tab" href="#dvlaSearch" role="tab"
         aria-controls="profile" aria-selected="false"><b>DVLA Realtime</b></a>
    </li>
    <li class="nav-item">
      <a class="nav-link" id="ava-search-tab" data-toggle="tab" href="#avaSearch" role="tab"
         aria-controls="profile" aria-selected="false"><b>AVA</b></a>
    </li>
    <li class="nav-item" *ngIf="prospect.isProspectHitAccess">
      <a class="nav-link" id="motor-prospect-search-tab" data-toggle="tab" href="#motorProspectSearch" role="tab"
         aria-controls="profile" aria-selected="false"><b>Prospect</b></a>
    </li>
    <li class="nav-item">
      <a class="nav-link" id="mothistory-search-tab" data-toggle="tab" href="#motHistorySearch" role="tab"
         aria-controls="profile" aria-selected="false"><b>MOT History</b></a>
    </li>
  </ul>

  <div class="tab-content" id="ukMotorResultTabContent">
    <!---------------------- Category Search table for Main results --------------->
    <!-- HUT Motor Results -->
    <div *ngIf="isExperianTabShow" class="tab-pane fade show tab-height" id="hutmotorSearch" role="tabpanel" aria-labelledby="hutmotor-search-tab">
      <app-hutmotor-result-motor [experian]="experian"></app-hutmotor-result-motor>
    </div>

    <!-- Vehicle Data Results -->
    <div class="tab-pane fade show tab-height active" id="dvlaSearch" role="tabpanel" aria-labelledby="dvla-search-tab">
      <app-motor-result-motor [motor]="dvla"></app-motor-result-motor>
    </div>

    <!-- AVA Result -->
    <div class="tab-pane fade show tab-height" id="avaSearch" role="tabpanel" aria-labelledby="ava-search-tab">
      <app-ava-result-motor [ava]="ava"></app-ava-result-motor>
    </div>

    <!-- Prospect Result -->
    <div class="tab-pane fade show tab-height" id="motorProspectSearch" role="tabpanel" aria-labelledby="motor-prospect-search-tab">
      <app-prospect-result-personal [prospect]="prospect" (prospectProfileRequestEventEmitter)="prospectProfileRequest()"></app-prospect-result-personal>
    </div>

    <!-- MOT History Result -->
    <div class="tab-pane fade show tab-height" id="motHistorySearch" role="tabpanel" aria-labelledby="mothistory-search-tab">
      <app-mothistory-result-motor [motHistory]="motHistory"></app-mothistory-result-motor>
    </div>
  </div>
</div>
