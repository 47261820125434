<div class="col-md-12" *ngIf="motor.searchFail && !motor.isLoading"><i class="color-grey float-right">{{messageService.SearchFail}}</i></div>
<div class="col-md-12" *ngIf="motor.noRelevantData && !motor.isLoading"><i class="color-grey float-right">{{messageService.VehicleRecordNotFound}}</i></div>
<div>
  <span class="loadingImg" *ngIf="motor.isLoading">
    <img src="{{messageService.loadingAnimation}}" />
  </span>
</div>
<div *ngIf="motor.responseData">
  <div class="row" *ngIf="motor?.responseData?.registrationNumber">
    <div class="col-md-4">Registration Number:</div>
    <div class="col-md-8">{{motor.responseData.registrationNumber}}</div>
  </div>
  <div class="row" *ngIf="motor?.responseData?.make">
    <div class="col-md-4">Make:</div>
    <div class="col-md-8">{{motor.responseData.make}}</div>
  </div>
  <div class="row" *ngIf="motor?.responseData?.colour">
    <div class="col-md-4">Colour:</div>
    <div class="col-md-8">{{motor.responseData.colour}}</div>
  </div>
  <div class="row" *ngIf="motor?.responseData?.wheelplan">
    <div class="col-md-4">Wheelplan:</div>
    <div class="col-md-8">{{motor.responseData.wheelplan}}</div>
  </div>
  <div class="row" *ngIf="motor?.responseData?.engineCapacity">
    <div class="col-md-4">Engine Capacity:</div>
    <div class="col-md-8">{{motor.responseData.engineCapacity}}</div>
  </div>
  <div class="row" *ngIf="motor?.responseData?.fuelType">
    <div class="col-md-4">Fuel Type:</div>
    <div class="col-md-8">{{motor.responseData.fuelType}}</div>
  </div>
  <div class="row" *ngIf="motor?.responseData?.co2Emissions">
    <div class="col-md-4">CO2 Emissions:</div>
    <div class="col-md-8">{{motor.responseData.co2Emissions}}</div>
  </div>
  <div class="row" *ngIf="motor?.responseData?.typeApproval">
    <div class="col-md-4">Type Approval:</div>
    <div class="col-md-8">{{motor.responseData.typeApproval}}</div>
  </div>
  <div class="row" *ngIf="motor?.responseData?.revenueWeight">
    <div class="col-md-4">Revenue Weight:</div>
    <div class="col-md-8">{{motor.responseData.revenueWeight}}</div>
  </div>

  <br />

  <div class="row" *ngIf="motor?.responseData?.taxStatus">
    <div class="col-md-4">Tax Status:</div>
    <div class="col-md-8">{{motor.responseData.taxStatus}}</div>
  </div>
  <div class="row" *ngIf="motor?.responseData?.taxDueDate">
    <div class="col-md-4">Tax Due Date:</div>
    <div class="col-md-8">{{motor.responseData.taxDueDate}}</div>
  </div>

  <br />

  <div class="row" *ngIf="motor?.responseData?.motStatus">
    <div class="col-md-4">MOT Status:</div>
    <div class="col-md-8">{{motor.responseData.motStatus}}</div>
  </div>
  <div class="row" *ngIf="motor?.responseData?.motExpiryDate">
    <div class="col-md-4">MOT Expiry Date:</div>
    <div class="col-md-8">{{motor.responseData.motExpiryDate}}</div>
  </div>

  <br />

  <div class="row" *ngIf="motor?.responseData?.yearOfManufacture">
    <div class="col-md-4">Year of Manufacture:</div>
    <div class="col-md-8">{{motor.responseData.yearOfManufacture}}</div>
  </div>
  <div class="row" *ngIf="motor?.responseData?.monthOfFirstRegistration">
    <div class="col-md-4">Month of First Registration:</div>
    <div class="col-md-8">{{motor.responseData.monthOfFirstRegistration}}</div>
  </div>
  <div class="row" *ngIf="motor?.responseData?.dateOfLastV5CIssued">
    <div class="col-md-4">Date Last V5C Issued:</div>
    <div class="col-md-8">{{motor.responseData.dateOfLastV5CIssued}}</div>
  </div>
  <div class="row" *ngIf="motor?.responseData?.markedForExport != null">
    <div class="col-md-4">Marked for Export:</div>
    <div class="col-md-8">{{motor.responseData.markedForExport}}</div>
  </div>
</div>
