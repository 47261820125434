import { Component, OnInit, ElementRef } from '@angular/core';
import { Register } from '../../models/register';
import { LoginService } from '../../service/login.service';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'app-user-register',
    templateUrl: './user-register.component.html',
    styleUrls: ['./user-register.component.css'],
    providers: [LoginService],
    preserveWhitespaces: true
})
export class UserRegisterComponent implements OnInit {
    public register: Register = new Register("", "", "", "", "");
    public validationerror: string;
    public loading: boolean;
    public loggedIn: boolean;

    constructor(
        private loginService: LoginService,
      private router: Router,
      private elementRef: ElementRef) { }
  ngAfterViewInit() {
    this.elementRef.nativeElement.ownerDocument.body.style.background = 'url(/assets/Images/LoginBackground.svg)';
  }

    ngOnInit() {
        this.loggedIn = localStorage.getItem("access_token") ? true : false;
          this.router.navigate(["/Login"]);
    }

    public UserRegister(f) {
        this.loading = true;
        this.loginService.register(this.register)
            .subscribe(
                data => {
                    this.router.navigate(["/Thankyou"], { queryParams: { status: 'registration' } });
                    this.loading = false;
                },
                error => {
                    if (error.status == 400) {
                        if (JSON.parse(error._body)["Email"][0] == "Invalid Email") {
                            this.loading = false;
                            this.validationerror = "Please enter valid email address";
                            setTimeout(() => {
                                this.validationerror = "";
                            }, 5000);                            
                        }
                    }
                    else if (error.status == 401) {
                        this.loading = false;
                        this.validationerror = JSON.parse(error._body).message;
                        setTimeout(() => {
                            this.validationerror = "";
                        }, 5000);
                    }
                    else if (error.status == 500) {
                        this.loading = false;
                        this.validationerror = "Internal Server Error";
                        setTimeout(() => {
                            this.validationerror = "";
                        }, 5000);
                    }
                });
    }
}
