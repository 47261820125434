<div class="col-md-12" *ngIf="!companiesHouse.isLoading && companiesHouse.noSearchSelect"><i class="color-grey float-right">{{messageService.NoSearchSelect}}</i></div>
<div class="col-md-12" *ngIf="!companiesHouse.isLoading && !companiesHouse.noSearchSelect && companiesHouse.noRelevantData"><i class="color-grey float-right">{{messageService.NoRelevantDataMessage}}</i></div>
<div class="col-md-12" *ngIf="!companiesHouse.isLoading && !companiesHouse.noSearchSelect && companiesHouse.searchFail"><i class="color-grey float-right">{{messageService.SearchFail}}</i></div>
<div>
  <span class="loadingImg" *ngIf="companiesHouse.isLoading">
    <img src="{{messageService.loadingAnimation}}" />
  </span>
</div>
<div *ngIf="!companiesHouse.isLoading && companiesHouse.responseData">
  <div class="mt-6 company-result-page keep-together" *ngFor="let people of companiesHouse.responseData let i=index">
    <div class="mt-2">
      <h5>
        <a aria-expanded="false" aria-controls="collapseExample" class="text-primary">{{people.Name }} <span class="color-purple" *ngIf="people.IsDisqualified">{{'('+ 'Disqualified'+')'}}</span></a>
      </h5>
      <div class="color-grey margin-top-ds"> <small>{{people.Description}}</small></div>
      <div class="collapse-company-details">
        <div class="card card-body">
          <div class="row">
            <div class="col-md-4">
              <div>Address</div>
              <div class="display-font-weight">{{people.Address}}</div>
            </div>
            <div class="col-md-4">
              <div>Date Of Birth</div>
              <div class="display-font-weight">{{people.DateOfBirth}}</div>
            </div>
          </div>
          <div *ngFor="let cmp of people.CompaniesData">
            <div class="row mt-4">
              <div class="col-md-12">
                <h5 class="text-Decoration">{{cmp.Name + '(' + cmp.Number + ')'}}</h5>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-md-2">
                <div>Company status</div>
                <div class="display-font-weight">{{cmp.Status}}</div>
              </div>
              <div class="col-md-2">
                <div>Officer Role</div>
                <div class="display-font-weight">{{cmp.OfficerRole}}</div>
              </div>
              <div class="col-md-2">
                <div>Appointed On</div>
                <div class="display-font-weight">{{cmp.companyObject.appointed_on}}</div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-md-2">
                <div>Resigned On</div>
                <div class="display-font-weight">{{cmp.companyObject.resigned_on}}</div>
              </div>
              <div class="col-md-2">
                <div>Nationality</div>
                <div class="display-font-weight">{{cmp.companyObject.nationality}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ul class="grid-view">
        <li *ngFor="let cmp of people.CompaniesData">{{cmp.Name + '(' + cmp.Status + ')'}} <br /></li>
      </ul>
    </div>
  </div>
</div>
