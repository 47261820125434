import { Component, Input, OnInit } from '@angular/core';
import { CCJResultModel } from '../../../../../../search-pages/models/FinancialResultModel';
import { MessagingService } from '../../../../../../services/messaging.service';

@Component({
  selector: 'app-ccj-result-personal',
  templateUrl: './ccj-result.component.html',
  styleUrls: ['./ccj-result.component.css', '../../../../shared/shared.searchpage.css']
})
export class CcjResultPersonalComponent implements OnInit {

  @Input() ccj: CCJResultModel = new CCJResultModel();

  constructor(private messageService: MessagingService) { }

  ngOnInit(): void {
  }

}
