import { Items } from "./Items";

export class SportsResultModel {
  public sportsCategory: string[] = ["Running", "Water", "Ball", "Racket", "Hockey", "Rugby", "Boxing", "MMA"];
  public sportsStatus: SportsStatus[] = [];
  public filterSportsStaus: SportsStatus;
  public collapseSportsClass: string;
  public collapseSportsStatus: string;
  public collapseSportsIcon: string;
  public sportsComplete: boolean;
  public sportsResults: Items[] = [];
  public finalSportsResults: Items[] = [];
  public compareSportsCategory: string[] = [];
  public sportsSuccessCount: number = 0;
  public sportsResultCount: number;
  public displaySportsCount: boolean;
  public noSearchSelect: boolean;
  public isLoading: boolean;
}

export class SportsStatus {
  constructor(
    public name: string,
    public displayname: string,
    public status: string,
    public searchloading: boolean,
    public statusCount: number
  ) { }
}
