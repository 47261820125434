import { Options } from "@angular-slider/ngx-slider";
import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AppComponent } from '../app.component';
import { Item } from '../home/models/AccessData';
import { Audit } from '../shared/models/audit';
import { Forgetpassword } from '../shared/models/forgetpassword';
import { LoginService } from '../shared/service/login.service';
import { AuditReport } from './Models/AuditReport';
import { EmailDomains } from './Models/EmailDomains';
import { ItemAccess, LicenceTemplate } from './Models/LicenceTemplate';
import { NewClient } from './Models/NewClient';
import { DomainDetails, NewDomain } from './Models/NewDomain';
import { PlanExpiry } from './Models/PlanExpiry';
import { ResendLinkModel } from './Models/ResendLinkModel';
import { SubscriptionPlan } from './Models/SubscriptionPlan';
import { UserDetails } from './Models/UserDetails';
import { TenantSettings } from './Models/UserSettings';
import { ExcelService } from './service/excel.service';
import { SubscriptionService } from './service/subscription.service';
import { SearchService } from "../demo/V3/services/search.service";

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class SubscriptionComponent implements OnInit, AfterViewInit {

  value: number = 1;
  options: Options = {
    showTicksValues: true,
    stepsArray: [
      { legend: "Relaxed", value: 1 },
      { legend: "Normal", value: 2 },
      { legend: "Strict", value: 3 },
    ],
  };


  public updateLicence: LicenceTemplate = new LicenceTemplate();
  public displayTenantName: object;
  public isRowEditable: boolean = false;
  public editLicence: boolean = false;
  public licenceError: string = null;
  public licenceSuccess: string = null;
  public editVisibility: boolean = false;
  public isTemplateVisibleButton: boolean = false;
  public tempLicenceScreen: ItemAccess[] = [];
  public tempLicenceSearch: ItemAccess[] = [];
  public tempLicenceSearchAvailability: ItemAccess[] = [];
  public isAddTemplateModelOpen: boolean = false;
  public searchToggle: boolean = false;
  public screenToggle: boolean = false;
  public searchAvailabilityToggle: boolean = false;
  public getSubscriptionItems: Item[] = [];
  public checkItem: Item = new Item();
  public planExpiry: PlanExpiry = null;
  public licenceTemplateList: LicenceTemplate[] = [];
  public newLicence: LicenceTemplate = new LicenceTemplate();
  public status: string = "";
  public screenToggleDisable: boolean;
  public searchToggleDisable: boolean;
  public searchAvailabilityToggleDisable: boolean;
  /*User Management*/
  public loggedIn: boolean;
  public imagePositionClass: string = "";
  public user: UserDetails = new UserDetails();
  public isModalOpen: boolean = false;
  public confirmPassword: string = null;
  public confirmUpdatePassword: string = null;
  public newPassword: string = null;
  public newUpdatePassword: string = null;
  public isShowAdduserbtn: boolean;
  public isShowAddDomainbtn: boolean;
  public rowEditable: boolean;
  public updateMember: boolean;
  public updateDomain: boolean;
  public clients: UserDetails[];
  public register: NewClient = new NewClient("", "", "", "", "", "", 0, "", false, false, false, false);
  public registerDomain: NewDomain = new NewDomain("", false, 0);
  public isChangePasswordModalOpen: boolean = false;
  public isUpdatePasswordModalOpen: boolean = false;
  public isAddUserModalOpen: boolean = false;
  public isAddDomainModelOpen: boolean = false;
  public disableEmailField: boolean = false;
  public Plan: SubscriptionPlan = new SubscriptionPlan();

  public reporter: AuditReport[];
  public audits: Audit[];
  public reporterPermenant: AuditReport[];
  public Domains: EmailDomains[];
  public isAddDomain: boolean = true;
  public isUpdateDomain: boolean = true;
  public auditReportDisable: boolean;
  public AuditReportDownloadText: string;
  public auditReportLoading: boolean;
  public rowRecordNotFound: boolean;

  public licenceId: number = 0;
  public licencestatus: string = "";
  public filterValue: string;
  public selectedValue: AuditReport;
  public tenantId: number;
  public userId: string;

  @ViewChild('dropDownStatus') drop;
  @ViewChild('dropDownLicence') licence;

  //Errors
  public registrationError: string = null;
  public registrationDomainError: string = null;
  public passwordValidationError: string = null;
  public DetailsError: boolean = false;
  public editError: string = null;
  public subscriptionError: string = null;

  //SuccessMessage
  public successMessage: string = null;
  public registrationSuccess: string = null;
  public registrationDomainSuccess: string = null;
  public editSuccess: string = null;
  public clientrecordsfound: string = null;
  public domainrecordsfound: string = null;
  public licenceList: any[] = [{ label: 'No Template', value: 'NoLicence', id: 0 }];

  public statuses: object[] = [
    { label: 'Active', value: 'Active' },
    { label: 'Inactive', value: 'Inactive' }
  ]

  // Plan Expiry
  public isPlanExpired: boolean = false;
  public PlanExpiryMessage: string = null;
  public ToastMessageWarning: string = null;

  public isAdmin: boolean;
  public isScreenAccess: boolean;
  public isSearchAccess: boolean;
  public timeOut: any;

  public criminalitySearchRankOptions: any[] = [
    { name: "Strict", value: 400, range: 3 },
    { name: "Normal", value: 200, range: 2 },
    { name: "Relaxed", value: 100, range: 1 }
  ];
  public criminalitySearchOptionSelect: number;
  public criminalitySearchOptionSelectDescription: string;
  public criminalitySearchResultCount: number;
  public userSettingsSuccessMessage: string;

  constructor(private elementRef: ElementRef,
    private subscriptionService: SubscriptionService,
    private app: AppComponent,
    private searchService: SearchService,
    private loginService: LoginService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private excelService: ExcelService,
    private route: ActivatedRoute,
    public datepipe: DatePipe) { }

  ngAfterViewInit() {
    this.elementRef.nativeElement.ownerDocument.body.style.background = 'url(/assets/Images/Background.svg)';
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundAttachment = 'fixed';
  }

  ngOnInit(): void {
    this.app.isLoginExpires();
    this.route.queryParams
      .subscribe(params => {
        if (params.Tenant) {
          this.tenantId = Number(atob(params.Tenant));
          if (isNaN(this.tenantId)) {
            this.tenantId = 0;
          } else {
            this.app.isShowHeader = false;
          }
        } else {
          this.tenantId = 0;
        }
      }
      );
    this.auditReportDisable = false;
    this.auditReportDisable = true;
    this.AuditReportDownloadText = "Download";
    this.PlanExpiryMessage = "Your subscription has expired. Please contact your account manager.";
    this.ToastMessageWarning = "HUT Warning";
    this.app.settingsvisibility = false;
    this.screenToggleDisable = true;
    this.searchToggleDisable = true;
    this.isScreenAccess = false;
    this.isSearchAccess = false;
    this.userSettingsSuccessMessage = "";
    this.criminalitySearchResultCount = 0;
    this.getPlanExpired();
    this.getAuditreport();
    this.subscriptionService.getTenantName(this.tenantId).subscribe(data => {
      this.displayTenantName = data.tenantName;
    })
    this.subscriptionService.GetProfileDetails().subscribe(
      data => {
        this.user = data;
        this.user.firstName = this.transform(this.user.firstName);
        this.user.lastName = this.transform(this.user.lastName);
      });
    this.searchService.AllowedLicence().subscribe(data => {
      this.app.screen = false;
      this.app.search = false;
      if (data) {
        if (data.find(i => i.licenceType.toLowerCase() == "screen")) {
          this.app.screen = this.tenantId ? false : true;
        }
        if (data.find(i => i.licenceType.toLowerCase() == "search")) {
          this.app.search = this.tenantId ? false : true;
        }
      }
    });
    this.isAdmin = this.loginService.isAdminUser();
    if (this.isAdmin) {
      this.subscriptionService.GetLicenceItems().toPromise().then(
        data => {
          data.forEach(item => {
            if (item.licenceType.toLowerCase() == "search") {
              this.tempLicenceSearch.push(item);
            } else if (item.licenceType.toLowerCase() == "screen") {
              item.defaultValue = true;
              item.toggle = true;
              this.tempLicenceScreen.push(item);
            } else if (item.licenceType.toLowerCase() == "searchavailability") {
              this.tempLicenceSearchAvailability.push(item);
            }
          });
        }
      );
      this.subscriptionService.GetSubscribedPlan(this.tenantId).toPromise().then(data => {
        this.Plan = data;
        if (this.Plan.allowedLicenseItems.find(e => e.licenceType.toLowerCase() === "screen")) {
          this.screenToggleDisable = false;
          this.isScreenAccess = true;
        } else {
          this.screenToggleDisable = true;
        }
        if (this.Plan.allowedLicenseItems.find(e => e.licenceType.toLowerCase() === "search")) {
          this.searchToggleDisable = false;
          this.isSearchAccess = true;
        } else {
          this.searchToggleDisable = true;
        }
        if (this.Plan.allowedLicenseItems.find(e => e.licenceType.toLowerCase() === "searchavailability")) {
          this.searchAvailabilityToggleDisable = false;
          this.isSearchAccess = true;
        } else {
          this.searchAvailabilityToggleDisable = true;
        }
        this.loadBasedOnSubscription(this.Plan.allowedLicenseItems);
      });
      this.GetLicenceTemplateList();
      this.LoadClients();
      this.GetLicenceList();
      //if (this.tenantId == 0) {
      //  this.getAllTenantEmailDomains();
      //}
      //this.GetTenantSettings();
      this.subscriptionService.GetPlanExpiry(this.tenantId).toPromise().then(
        data => {
          this.planExpiry = data;
        },
        error => {
          console.log(error);
        }
      )
    }
  }

  getAllTenantEmailDomains() {
    this.domainrecordsfound = null;
    this.subscriptionService.GetAllEmailDomains(this.tenantId).subscribe(
      data => {
        this.Domains = data;
        if (this.Domains.length == 0) {
          this.domainrecordsfound = "No domain records found";
        }
        // console.log(this.Domains);
      }, error => {
        if (error.status) {
          this.domainrecordsfound = "No domain records found";
        }
      }
    )
  }

  @ViewChild('templateForm') create: NgForm;
  // @ViewChild('content', {static: false}) content:ElementRef;

  AuditReportDownload() {
    this.auditReportDisable = true;
    this.AuditReportDownloadText = "Downloading...";
    let startDate = this.datepipe.transform(this.selectedValue.startingDate, 'dd-MM-yyyy');
    let endDate = this.datepipe.transform(this.selectedValue.endingDate, 'dd-MM-yyyy')
    this.subscriptionService.GetAuditDownloadReport(startDate, endDate, this.tenantId).subscribe(
      data => {
        this.audits = data;
        setTimeout(() => {
          this.ExportTOExcel();
          this.auditReportDisable = false;
          this.AuditReportDownloadText = "Download";
        }, 3000);
      },
      error => {
        this.auditReportDisable = false;
        this.AuditReportDownloadText = "Download";
        console.log(error);
      }
    )
  }

  monthWiseRadioChange() {
    if (this.selectedValue.screencount === 0 && this.selectedValue.searchcount === 0 && this.selectedValue.api === 0) {
      this.rowRecordNotFound = true;
    } else {
      this.rowRecordNotFound = false;
      this.auditReportDisable = false;
    }
  }

  getPlanExpired() {
    this.subscriptionService.GetPlanExpiry(this.tenantId).toPromise().then(
      data => {
        this.planExpiry = data;
        let days = this.planExpiry.subscriptionExpiry.days;
        let hours = this.planExpiry.subscriptionExpiry.hours;
        let minutes = this.planExpiry.subscriptionExpiry.minutes;
        let seconds = this.planExpiry.subscriptionExpiry.seconds;
        if (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) {
          this.isPlanExpired = true;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  getAuditreport() {
    if (!this.reporterPermenant) {
      this.auditReportLoading = true;
      this.auditReportDisable = true;
      this.filterValue = "3";
      this.reporter = [];
    }
    this.subscriptionService.GetAuditreport(this.tenantId).subscribe(
      data => {
        this.auditReportLoading = false;
        this.reporterPermenant = data;
        if (this.reporterPermenant) {
          this.reporterPermenant.reverse();
          this.filter();
        }
      },
      error => {
        console.log(error);
        this.auditReportLoading = false;
      }
    )
  }


  filter() {
    this.reporter = [];
    this.selectedValue = null;
    this.auditReportDisable = true;
    let data = this.reporterPermenant;
    if (this.filterValue == "3") {
      if (data.length > 3) {
        for (let i = 0; i < 3; i++) {
          this.reporter.push(data[i]);
        }
      } else {
        this.reporter = data;
      }
    } else if (this.filterValue == "6") {
      if (data.length > 6) {
        for (let i = 0; i < 6; i++) {
          this.reporter.push(data[i]);
        }
      } else {
        this.reporter = data;
      }
    } else if (this.filterValue == "12") {
      if (data.length > 12) {
        for (let i = 0; i < 12; i++) {
          this.reporter.push(data[i]);
        }
      } else {
        this.reporter = data;
      }
    }
  }
  ExportTOExcel(): void {
    if (this.selectedValue) {
      var date = this.datepipe.transform(this.audits[0].searchDate, 'MMMM_yyyy', 'en-US')
      this.excelService.exportAsExcelFile(this.audits, 'HUT_Report_' + date);
    }
  }

  //ExportTOExcel(): void {
  //  let element = document.getElementById("content");
  //  const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
  //  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //  XLSX.utils.book_append_sheet(wb, ws, 'data');
  //  var date = this.datepipe.transform(this.audits[0].searchDate, 'MMMM_yyyy', 'en-US')
  //    XLSX.writeFile(wb, 'HUT_Report_' + date + '.csv');
  //}

  addNewLicence() {
    if (this.isPlanExpired) {
      this.messageService.add({ key: 'PlanExpiry', severity: 'warn', summary: this.ToastMessageWarning, detail: this.PlanExpiryMessage, sticky: true });
      setTimeout(() => {
        this.messageService.clear();
      }, 13000);
      this.isAddTemplateModelOpen = false;
    }
    else {
      this.isPlanExpired = false;
      this.newLicence.licenceName = "";
      this.newLicence.description = "";
      this.isAddTemplateModelOpen = true;
      this.editVisibility = true;
      this.screenToggle = false;
      this.searchToggle = false;
      this.searchAvailabilityToggle = false;
      this.editLicence = false;
      this.loadBasedOnSubscription(this.Plan.allowedLicenseItems);
    }
  }

  addNewMember() {
    if (this.isPlanExpired) {
      this.messageService.add({ key: 'PlanExpiry', severity: 'warn', summary: this.ToastMessageWarning, detail: this.PlanExpiryMessage, sticky: true });
      setTimeout(() => {
        this.messageService.clear();
      }, 13000);
      this.isAddTemplateModelOpen = false;
    } else {
      this.updateMember = false;
      this.isShowAdduserbtn = true;
      this.isAddUserModalOpen = true;
      this.disableEmailField = false;
      this.register = new NewClient("", "", "", "", "", "", 0, "", false, false, false, false);
    }
  }
  closeAddUserMember() {
    this.updateMember = false;
    this.register = null;
    this.isAddUserModalOpen = false;
    this.disableEmailField = false;
  }

  loadBasedOnSubscription(licenceItems: ItemAccess[]) {
    this.tempLicenceSearch.forEach(data => {
      data.defaultValue = false;
      data.toggle = true;
      if (licenceItems) {
        if (licenceItems.find(x => x.key == data.key && x.licenceType == data.licenceType)) {
          data.disabled = false;
        } else {
          data.disabled = true;
          data.toggle = true;
          data.defaultValue = false;
        }
      }
    });
    this.tempLicenceScreen.forEach(data => {
      data.defaultValue = true;
      data.toggle = true;
      if (licenceItems) {
        if (licenceItems.find(x => x.key == data.key && x.licenceType == data.licenceType)) {
          data.disabled = false;
        } else {
          data.disabled = true;
          data.toggle = true;
          data.defaultValue = false;
        }
      }
    });
    this.tempLicenceSearchAvailability.forEach(data => {
      data.defaultValue = false;
      data.toggle = true;
      if (licenceItems) {
        if (licenceItems.find(x => x.key == data.key && x.licenceType == data.licenceType)) {
          data.disabled = false;
        } else {
          data.disabled = true;
          data.toggle = true;
          data.defaultValue = false;
        }
      }
    });
  }

  loadTemplate(licenceItems: ItemAccess[]) {
    licenceItems.forEach(item => {
      if (item.licenceType.toLowerCase() == "search") {
        this.tempLicenceSearch.find(e => {
          if (e.key === item.key) {
            e.itemId = item.itemId;
            if (!e.disabled) {
              e.disabled = false;
              e.toggle = !item.toggle;
              e.defaultValue = item.defaultValue;
            } else {
              e.disabled = true;
              e.toggle = true;
              e.defaultValue = false;
            }
          }
        });
      } else if (item.licenceType.toLowerCase() == "screen") {
        this.tempLicenceScreen.find(e => {
          if (e.key === item.key) {
            e.itemId = item.itemId;
            if (!e.disabled) {
              e.disabled = false;
              e.toggle = !item.toggle;
              e.defaultValue = item.defaultValue;
            } else {
              e.disabled = true;
              e.toggle = true;
              e.defaultValue = false;
            }
          }
        });
      } else if (item.licenceType.toLowerCase() == "searchavailability") {
        this.tempLicenceSearchAvailability.find(e => {
          if (e.key === item.key) {
            e.itemId = item.itemId;
            if (!e.disabled) {
              e.disabled = false;
              e.toggle = !item.toggle;
              e.defaultValue = item.defaultValue;
            } else {
              e.disabled = true;
              e.toggle = true;
              e.defaultValue = false;
            }
          }
        });
      }
    })
    this.GetLicenceList();
  }

  onSubmitTest() {
    if (this.isAddTemplateModelOpen && !this.editLicence) {
      if (this.newLicence.licenceName) {
        if (this.searchToggle || this.screenToggle || this.searchAvailabilityToggle) {

          this.newLicence.allowedLicenseItems = this.mapLicenceItem();
          if (this.searchToggle == false)
            this.newLicence.allowedLicenseItems = this.newLicence.allowedLicenseItems.filter(x => x.licenceType.toLocaleLowerCase() !== "search");
          if (this.screenToggle == false)
            this.newLicence.allowedLicenseItems = this.newLicence.allowedLicenseItems.filter(x => x.licenceType.toLocaleLowerCase() !== "screen");
          if (this.searchAvailabilityToggle == false)
            this.newLicence.allowedLicenseItems = this.newLicence.allowedLicenseItems.filter(x => x.licenceType.toLocaleLowerCase() !== "searchavailability");

          this.newLicence.allowedLicenseItems.forEach(x => {
            x.toggle = !x.toggle
          });
          this.subscriptionService.CreateLicence(this.newLicence, this.tenantId).subscribe(data => {
            this.licenceSuccess = "New licence created successfully";
            this.GetLicenceTemplateList();
            this.licenceList = [{ label: 'No Template', value: 'NoLicence', id: 0 }];
            this.GetLicenceList();
            this.newLicence = new LicenceTemplate();
            this.clearMessage();
            this.isAddTemplateModelOpen = false;
            this.editVisibility = false;
          },
            error => {
              if (error.status === 409) {
                this.licenceError = "Licence name already exist. Try different licence name.";
              } else {
                this.licenceError = "Something went wrong try again later";
              }
            }
          );

          this.clearMessage();
        } else {
          this.licenceError = "Choose any licence Item";
          this.clearMessage();
        }
      } else {
        this.licenceError = "Please enter the licence name";
        this.clearMessage();
      }

    } else if (this.editLicence && this.isAddTemplateModelOpen) {
      if (this.searchToggle || this.screenToggle || this.searchAvailabilityToggle) {
        this.newLicence.isActive = (this.licencestatus.toLowerCase() == 'active') ? true : false;

        var licenceItems = this.mapLicenceItem();
        this.newLicence.allowedLicenseItems = licenceItems ? licenceItems : this.updateLicence.allowedLicenseItems;
        if (this.searchToggle == false)
          this.newLicence.allowedLicenseItems = this.newLicence.allowedLicenseItems.filter(x => x.licenceType.toLocaleLowerCase() !== "search");
        if (this.screenToggle == false)
          this.newLicence.allowedLicenseItems = this.newLicence.allowedLicenseItems.filter(x => x.licenceType.toLocaleLowerCase() !== "screen");
        if (this.searchAvailabilityToggle == false)
          this.newLicence.allowedLicenseItems = this.newLicence.allowedLicenseItems.filter(x => x.licenceType.toLocaleLowerCase() !== "searchavailability");

        this.newLicence.allowedLicenseItems.forEach(x => {
          x.toggle = !x.toggle
        });
        this.subscriptionService.UpdateLicence(this.newLicence, this.tenantId).toPromise().then(data => {
          this.licenceSuccess = "Licence Updated Sucessfully";
          this.GetLicenceTemplateList();
          this.licenceList = [{ label: 'No Template', value: 'NoLicence', id: 0 }];
          this.GetLicenceList();
          this.newLicence = new LicenceTemplate();
          this.isAddTemplateModelOpen = false;
          this.newLicence.description = "";
          this.editVisibility = false;
          this.editLicence = false;
          this.clearMessage();
        },
          error => {
            if (error.status === 409) {
              this.licenceError = "Licence name already exist. Try different licence name.";
            } else {
              this.licenceError = "Something went wrong try again later";
            }
            this.clearMessage();
          }
        );
      } else {
        this.licenceError = "Choose any licence Item";
        this.clearMessage();
      }
    }
  }

  mapLicenceItem(): Item[] {
    let tempItems: Item[] = null;
    if (this.searchToggle || this.screenToggle || this.searchAvailabilityToggle) {
      tempItems = [...this.tempLicenceSearch, ...this.tempLicenceScreen, ...this.tempLicenceSearchAvailability];
      if (!this.screenToggle) {
        tempItems.forEach(e => {
          if (e.licenceType == "Screen") {
            e.defaultValue = false;
            e.toggle = false;
          }
        });
      }
      if (!this.searchToggle) {
        tempItems.forEach(e => {
          if (e.licenceType == "Search") {
            e.defaultValue = false;
            e.toggle = false;
          }
        });
      }
      if (!this.searchAvailabilityToggle) {
        tempItems.forEach(e => {
          if (e.licenceType == "SearchAvailability") {
            e.defaultValue = false;
            e.toggle = false;
          }
        });
      }
    }
    return tempItems;
  }

  selectCrossBtn() {
    this.newLicence = new LicenceTemplate();
    this.isAddTemplateModelOpen = false;
    if (this.isAddTemplateModelOpen || this.editVisibility) {
      this.isAddTemplateModelOpen = false;
      this.editVisibility = false;
    }

  }
  getLicence(item: LicenceTemplate) {
    let isFindSearch = item.allowedLicenseItems.find(licence => {
      if (licence.licenceType == "Search") {
        return true;
      }
    })
    if (isFindSearch) {
      this.searchToggle = true;
    } else {
      this.searchToggle = false;
    }
    let isFindScreen = item.allowedLicenseItems.find(licence => {
      if (licence.licenceType === "Screen") {
        return true;
      }
    })
    if (isFindScreen) {
      this.screenToggle = true;
    } else {
      this.screenToggle = false;
    }
    let isFindSearchAvailability = item.allowedLicenseItems.find(licence => {
      if (licence.licenceType === "SearchAvailability") {
        return true;
      }
    })
    if (isFindSearchAvailability) {
      this.searchAvailabilityToggle = true;
    } else {
      this.searchAvailabilityToggle = false;
    }
  }

  onRowEditLicenceInit(update: LicenceTemplate) {
    this.licencestatus = update.isActive ? 'Active' : 'Inactive';
    this.newLicence = update;
    this.isAddTemplateModelOpen = true;
    this.editLicence = true;
    this.editVisibility = true;
    this.loadBasedOnSubscription(this.Plan.allowedLicenseItems);
    this.loadTemplate(update.allowedLicenseItems);
    this.updateLicence = update;
  }

  deleteSelectedTemplate(licence: LicenceTemplate) {
    this.subscriptionService.DeleteLicence(licence).subscribe(data => {
      this.licenceSuccess = "licence deleted successfully";
      this.GetLicenceTemplateList();
    }, error => {
      if (error.status == 406) {
        this.confirmationService.confirm({
          message: 'The license is assigned to some users. Are you sure that you want to delete license?',
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.subscriptionService.ForceDeleteLicence(licence).subscribe(data => {
              this.subscriptionService.DeleteLicence(licence).subscribe(data => {
                this.GetLicenceTemplateList();
                this.LoadClients();
              })
            });
          },
          reject: () => {
          }
        });
      } else {
        console.log(error);
      }
    });
    this.clearMessage();
  }

  onRowEditLicenceSave(licence: LicenceTemplate) {
    this.editLicence = true;
    this.updateLicence = licence;

  }

  onRowEditLicenceCancel() {
    this.editVisibility = false;
    this.editLicence = false;
  }

  scrollTrigger(event) {
    let foundElements: HTMLCollectionOf<Element> = document.getElementsByClassName('app-outer-container');
    if (foundElements[0].scrollTop <= 165) {
      if (this.imagePositionClass != "") {
        this.imagePositionClass = "";
      }
    }
    else if (foundElements[0].scrollTop > 165) {
      if (this.imagePositionClass != "fix-position-top") {
        this.imagePositionClass = "fix-position-top";
      }
    }
  }

  onRowEditInit(rowData) {
    this.updateMember = true;
    this.newUpdatePassword = "";
    this.confirmUpdatePassword = "";
    this.disableEmailField = true;
    this.rowEditable = true;
    window.scrollTo(0, 235);
    this.register = rowData;
    this.isShowAdduserbtn = false;
    this.isAddUserModalOpen = true;
    return rowData;
  }

  onRowDelete(rowData) {
  }

  openUpdatePasswordModal() {
    this.isUpdatePasswordModalOpen = true;
    this.newUpdatePassword = null;
    this.confirmUpdatePassword = null;
  }

  closeUpdatePasswordModal() {
    this.isUpdatePasswordModalOpen = false;
    this.newUpdatePassword = null;
    this.confirmUpdatePassword = null;
  }

  closeSetPasswordModel() {
    this.updateMember = false;
    this.register = null;
    this.isAddUserModalOpen = false;
    this.isUpdatePasswordModalOpen = false;
    this.disableEmailField = false;
  }

  updateUser() {
    let updateUser = new UserDetails();
    updateUser.userId = this.register.userId;
    updateUser.firstName = this.register.firstName;
    updateUser.lastName = this.register.lastName;
    updateUser.department = this.register.department;
    updateUser.email = this.register.email;
    updateUser.isActive = this.register.isActive;
    updateUser.isAdmin = this.register.isAdmin;
    updateUser.licenceId = this.register.licenceId;
    updateUser.userName = this.register.email;
    updateUser.password = this.newUpdatePassword;
    updateUser.confirmPassword = this.confirmUpdatePassword;
    updateUser.isDeleted = this.register.isDeleted;
    this.subscriptionService.EditUser(updateUser).subscribe(
      data => {
        this.isAddUserModalOpen = false;
        this.register = null;
        this.LoadClients();
        this.resetClearMessageTime();
        this.clearMessage();
        this.registrationSuccess = "User updated successfully."
      },
      error => {
        if (error.status == 406) {
          this.passwordValidationError = "Password and confirm password doesn't match";
          this.confirmUpdatePassword = null;
          this.newUpdatePassword = null;
          this.resetClearMessageTime();
          this.clearMessage();
        } else if (error.status == 543) {
          this.successMessage = "Password changed successfully";
          this.clearMessage();
        } else {
          this.registrationError = "Something went wrong"
          this.confirmUpdatePassword = null;
          this.newUpdatePassword = null;
          this.resetClearMessageTime();
          this.clearMessage();
        }
      }
    );
  }

  updatePasswordEvent() {
    let error = true;
    if (!this.newUpdatePassword) {
      this.passwordValidationError = "New password is required";
      this.DetailsError = false;
      this.resetClearMessageTime();
      this.clearMessage();
      error = true;
      return error;
    } else if (this.newUpdatePassword && !this.IsStrongPassword(this.newUpdatePassword)) {
      this.passwordValidationError = null;
      this.DetailsError = true;
      this.resetClearMessageTime();
      this.clearMessage();
      error = true;
      return error;
    } else {
      this.DetailsError = false;
      this.passwordValidationError = null;
      error = false;
      return error;
    }
  }

  updateConfirmPasswordEvent() {
    let error = true;
    if (!this.newUpdatePassword) {
      this.passwordValidationError = "New password is required";
      this.DetailsError = false;
      this.resetClearMessageTime();
      this.clearMessage();
      error = true;
      return error;
    } else if (this.newUpdatePassword && !this.IsStrongPassword(this.newUpdatePassword)) {
      this.passwordValidationError = null;
      this.DetailsError = true;
      this.resetClearMessageTime();
      this.clearMessage();
      error = true;
      return error;
    } else if (this.newUpdatePassword !== this.confirmUpdatePassword) {
      this.passwordValidationError = "Password and confirm password doesn't match";
      this.resetClearMessageTime();
      this.clearMessage();
      error = true;
      return error;
    } else {
      this.DetailsError = false;
      this.passwordValidationError = null;
      error = false;
      return error;
    }
  }

  openChangePasswordModal() {
    this.isChangePasswordModalOpen = true;
    this.newPassword = null;
    this.confirmPassword = null;
  }

  closeChangePasswordModal() {
    this.isChangePasswordModalOpen = false;
    this.newPassword = null;
    this.confirmPassword = null;
  }

  changePassword() {
    if (!this.changePasswordEvent() && !this.changeConfirmPasswordEvent()) {
      this.subscriptionService.changePassword(this.newPassword, this.confirmPassword).subscribe(
        data => {
          if (data) {
            this.successMessage = "Password changed succesfully";
            this.clearMessage();
            setTimeout(() => {
              this.app.logout();
            }, 3000);
          }
        },
        error => {
          if (error.status == 406) {
            this.passwordValidationError = "Password and confirm password doesn't match";
            this.confirmPassword = null;
            this.newPassword = null;
            this.resetClearMessageTime();
            this.clearMessage();
          } else {
            this.passwordValidationError = "Something went wrong";
            this.confirmPassword = null;
            this.newPassword = null;
            this.resetClearMessageTime();
            this.clearMessage();
          }
        }
      );
    }
  }

  changePasswordEvent() {
    let error = true;
    if (!this.newPassword) {
      this.passwordValidationError = "New password is required";
      this.DetailsError = false;
      this.resetClearMessageTime();
      this.clearMessage();
      error = true;
      return error;
    } else if (this.newPassword && !this.IsStrongPassword(this.newPassword)) {
      this.passwordValidationError = null;
      this.DetailsError = true;
      this.resetClearMessageTime();
      this.clearMessage();
      error = true;
      return error;
    } else {
      this.DetailsError = false;
      this.passwordValidationError = null;
      error = false;
      return error;
    }
  }

  changeConfirmPasswordEvent() {
    let error = true;
    if (!this.newPassword) {
      this.passwordValidationError = "New password is required";
      this.DetailsError = false;
      this.resetClearMessageTime();
      this.clearMessage();
      error = true;
      return error;
    } else if (this.newPassword && !this.IsStrongPassword(this.newPassword)) {
      this.passwordValidationError = null;
      this.DetailsError = true;
      this.resetClearMessageTime();
      this.clearMessage();
      error = true;
      return error;
    } else if (this.newPassword !== this.confirmPassword) {
      this.passwordValidationError = "Password and confirm password doesn't match";
      this.resetClearMessageTime();
      this.clearMessage();
      error = true;
      return error;
    } else {
      this.DetailsError = false;
      this.passwordValidationError = null;
      error = false;
      return error;
    }
  }


  addNewDomain() {
    this.registerDomain = new NewDomain("", false, 0);
    this.isShowAddDomainbtn = true;
    this.updateDomain = false;
    this.isAddDomainModelOpen = true;
  }

  closeAddDomain() {
    this.isAddDomainModelOpen = false;
    this.registerDomain = new NewDomain("", false, 0);
    this.getAllTenantEmailDomains();
  }

  AddDomain() {
    this.isAddDomain = true;
    if (this.registerDomain.domainName) {
      this.Domains.forEach(x => {
        if (x.domainName == this.registerDomain.domainName.toLocaleLowerCase().trim()) {
          this.isAddDomain = false;
          this.registrationDomainError = "This domain is already exists";
          this.getAllTenantEmailDomains();
        }
      }),
        this.clearMessage();
      if (this.isAddDomain)
        this.subscriptionService.AddDomain(this.registerDomain, this.tenantId).subscribe(data => {
          this.registrationDomainSuccess = "Domain added successfully";
          this.getAllTenantEmailDomains();
          this.isAddDomainModelOpen = false;
          this.clearMessage();
          this.registerDomain = new NewDomain("", false, 0);
        });
    } else {
      this.registrationDomainError = "Enter the domain name";
      this.resetClearMessageTime();
      this.clearMessage();
    }
  }

  UpdateDomain() {
    this.isUpdateDomain = true;
    let domain = new DomainDetails();
    domain.id = this.registerDomain.id;
    domain.domainName = this.registerDomain.domainName.toLocaleLowerCase().trim();
    domain.isActive = this.registerDomain.isActive;
    if (domain.domainName) {
      this.Domains.forEach(x => {
        if (x.domainName == domain.domainName) {
          this.isUpdateDomain = false;
          this.registrationDomainError = "This domain is already exists";
          this.getAllTenantEmailDomains();
        }
      }),
        this.clearMessage();
      if (this.isUpdateDomain) {
        this.subscriptionService.EditDomain(domain, this.tenantId).subscribe(data => {
          this.registrationDomainSuccess = "Domain updated successfully";
          this.getAllTenantEmailDomains();
          this.isAddDomainModelOpen = false;
          this.isShowAddDomainbtn = true;
          this.clearMessage();
          this.registerDomain = new NewDomain("", false, 0);
        }, error => {
          if (error.status) {
            this.registrationDomainError = "Something went wrong";
          }
        })
      }
    } else {
      this.registrationDomainError = "Enter the domain name";
      this.resetClearMessageTime();
      this.clearMessage();
    }
  }

  closeUpdateModel() {
    this.isAddDomainModelOpen = false;
    this.registerDomain = new NewDomain("", false, 0);
    this.getAllTenantEmailDomains();
  }

  closeAddModel() {
    this.isAddDomainModelOpen = false;
    this.registerDomain = new NewDomain("", false, 0);
    this.getAllTenantEmailDomains();
  }

  onRowEditDomainInit(rowData) {
    this.isAddDomainModelOpen = true;
    this.isShowAddDomainbtn = false;
    this.updateDomain = true;
    window.scrollTo(0, 245);
    this.registerDomain = rowData;
  }

  IsStrongPassword(password: string): boolean {
    let requiredlength = password.length >= 8 ? true : false;
    let hasNumber = /\d/.test(password);
    let hasUpper = /[A-Z]/.test(password);
    let hasLower = /[a-z]/.test(password);
    let hasSpecialChar = /[^a-zA-Z0-9]/.test(password);
    let hasWhiteSpace = /^\s+$/.test(password);
    return hasNumber && hasUpper && hasLower && hasSpecialChar && requiredlength && !hasWhiteSpace;
  }

  clearMessage() {
    this.timeOut = setTimeout(() => {
      this.successMessage = null;
      this.DetailsError = false;
      this.passwordValidationError = null;
      this.registrationError = null;
      this.editSuccess = null;
      this.registrationSuccess = null;
      this.registrationDomainError = null;
      this.registrationDomainSuccess = null;
      this.domainrecordsfound = null;
      this.editError = null;
      this.licenceError = null;
      this.licenceSuccess = null;
    }, 10000);
  }

  resetClearMessageTime() {
    clearTimeout(this.timeOut);
  }

  AddUser() {
    this.fieldValidation();
  }


  fieldValidation() {
    if (this.register.firstName || this.register.lastName || this.register.email) {
      if (this.register.firstName) {
        if (this.register.lastName) {
          if (this.register.email) {
            const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (regularExpression.test(this.register.email.toLowerCase())) {
              this.subscriptionService.AddUser(this.register, this.tenantId).subscribe(data => {
                this.registrationSuccess = "User Added Successfully";
                this.subscriptionService.GetSubscribedPlan(this.tenantId).subscribe(data => {
                  this.Plan = data;
                });
                this.LoadClients();
                this.isAddUserModalOpen = false;
                this.clearMessage();
                this.register = new NewClient("", "", "", "", "", "", 0, "", false, false, false, false);

              },
                error => {
                  if (error.status == 409) {
                    this.registrationError = "This account already exist.";
                    this.resetClearMessageTime();
                    this.clearMessage();
                  }
                  if (error.status == 406) {
                    this.registrationError = "You have exceeded the maximum user limit of " + this.Plan.userCount + " for your subscription.";
                    this.resetClearMessageTime();
                    this.clearMessage();
                  }
                  if (error.status == 403) {
                    this.registrationError = "Some of the data entered is not valid.";
                    this.resetClearMessageTime();
                    this.clearMessage();
                  }
                  if (error.status == 404) {
                    this.registrationError = "This user domain is blocked for use. Please use a business email.";
                    this.resetClearMessageTime();
                    this.clearMessage();
                  }
                }
              );
            } else {
              this.registrationError = "Email is not valid";
              this.resetClearMessageTime();
              this.clearMessage();
            }
          } else {
            this.registrationError = "Enter the Email";
            this.resetClearMessageTime();
            this.clearMessage();
          }
        } else {
          this.registrationError = "Email the last Name";
          this.resetClearMessageTime();
          this.clearMessage();
        }
      } else {
        this.registrationError = "Enter the first Name";
        this.resetClearMessageTime();
        this.clearMessage();
      }

    } else {
      this.registrationError = "Enter the required details";
      this.resetClearMessageTime();
      this.clearMessage();
    }
  }

  GetLicenceList() {
    this.subscriptionService.LicenceList(this.tenantId).subscribe(
      data => {
        data.forEach(x => {
          if (x.isActive) {
            this.licenceList.push({ label: x.licenceName, value: x.licenceName, id: x.licenceId })
          }
        })
      });
  }

  LoadClients() {
    this.subscriptionService.GetClients(this.tenantId).subscribe(
      data => {
        this.clients = data;
      },
      error => {
        if (error.status) {
          this.clientrecordsfound = "No clients found"
        }
      }
    );
  }

  onUsersTabClick() {
    this.LoadClients();
    this.licenceList = [{ label: 'No Template', value: 'NoLicence', id: 0 }];
    this.GetLicenceList();
  }


  reSendVerificationEmail(email: string) {
    var resendLinkModel = new ResendLinkModel(email);

    this.subscriptionService.reSendConfirmationEmail(resendLinkModel).subscribe(
      data => {
        this.messageService.add({ key: 'ConfirmEmail', severity: 'success', summary: 'HUT Success', detail: 'Confirmation email sent to ' + email, sticky: true });
        setTimeout(() => {
          this.messageService.clear()
        }, 7000);
      },
      error => {
        if (error.status == 500) {
          this.messageService.add({ key: 'ConfirmEmail', severity: 'error', summary: 'HUT Error', detail: 'Error occured while sent confirmation mail', sticky: true });
          setTimeout(() => {
            this.messageService.clear()
          }, 7000);
        } else if (error.status == 406) {
          this.messageService.add({ key: 'ConfirmEmail', severity: 'warn', summary: this.ToastMessageWarning, detail: 'Email already verified', sticky: true });
          setTimeout(() => {
            this.messageService.clear()
          }, 7000);
        } else if (error.status == 401) {
          this.messageService.add({ key: 'ConfirmEmail', severity: 'error', summary: 'HUT Error', detail: 'You are not authorized', sticky: true });
          setTimeout(() => {
            this.messageService.clear()
          }, 7000);
        }
      }
    );
  }

  resetPasswordLink(email) {
    var forget = new Forgetpassword(email);
    this.loginService.forgetpassword(forget)
      .subscribe(
        data => {
          this.messageService.add({ key: 'ConfirmEmail', severity: 'success', summary: 'HUT Success', detail: 'Reset Password Link sent to ' + email, sticky: true });
          setTimeout(() => {
            this.messageService.clear()
          }, 7000);
        })
  }

  transform(value: string): string {

    let first = value.substr(0, 1).toUpperCase();
    return first + value.substr(1);
  }

  GetLicenceTemplateList() {
    this.subscriptionService.GetLicenceTemplateList(this.tenantId).toPromise().then(data => {
      this.licenceTemplateList = data;
    });
  }

  GetTenantSettings() {
    this.subscriptionService.GetTenantSettings(this.tenantId).subscribe(data => {
      this.criminalitySearchResultCount = data.criminalitySearchResultCount;
      var filter = this.criminalitySearchRankOptions.filter(x => x.value == data.criminalitySearchRank);
      if (filter.length > 0) {
        this.criminalitySearchOptionSelect = filter[0].range;
        this.criminalitySearchOptionSelectDescription = filter[0].name;
      } else {
        this.criminalitySearchOptionSelect = null;
      }
    }, error => {
      console.log(error);
    })
  }

  criminalityrankchange() {
    var filter = this.criminalitySearchRankOptions.filter(x => x.range == this.criminalitySearchOptionSelect);
    if (filter.length > 0) {
      this.criminalitySearchOptionSelectDescription = filter[0].name;
    }
  }

  SaveTenantSettings() {
    var criminalityRank: number;
    var filter = this.criminalitySearchRankOptions.filter(x => x.range == this.criminalitySearchOptionSelect);
    if (filter.length > 0) {
      criminalityRank = filter[0].value;
    }
    const requestData: TenantSettings = {
      criminalitySearchRank: criminalityRank,
      criminalitySearchResultCount: this.criminalitySearchResultCount
    }
    this.subscriptionService.SaveTenantSettings(requestData, this.tenantId).subscribe(data => {
      this.userSettingsSuccessMessage = "User settings saved successful";

      setTimeout(() => {
        this.userSettingsSuccessMessage = null;
      }, 3000)
    }, error => {
      console.log(error);
    });
  }
}
