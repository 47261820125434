import { LicenceTemplate } from './../Models/LicenceTemplate';
import { PlanExpiry } from './../Models/PlanExpiry';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { Register } from '../../shared/models/register';
import { UserDetails } from '../Models/UserDetails';
import { map } from 'rxjs/operators';
import { SubscriptionPlan } from '../Models/SubscriptionPlan';
import { NewClient } from '../Models/NewClient';
import { Item } from '../../home/models/AccessData';
import { ResendLinkModel } from '../Models/ResendLinkModel';
import { AuditReport } from '../Models/AuditReport';
import { Audit } from '../../shared/models/audit';
import { EmailDomains } from '../Models/EmailDomains';
import { NewDomain } from '../Models/NewDomain';
import { TenantSettings } from '../Models/UserSettings';
@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  constructor(private http: HttpClient) { }

  //Profile Management

  GetProfileDetails(): Observable<UserDetails> {
    //const httpOptions = {
    //  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    //};
    return this.http.get<UserDetails>('api/Subscription/GetDetails');
  }

  changePassword(newpassword: string,confirmPassword:string): Observable<any> {
    //const httpOptions = {
    //  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    //};
    return this.http.get<UserDetails>('api/Subscription/ChangePassword?newPassword=' + newpassword + '&confirmPassword=' + confirmPassword)
      .pipe(
        map(response => {
          let body = response;
          return body
            || {};
        }
        )
      );
  }

  getTenantName(tenentId?:number) : Observable<any>  {
    return this.http.get<any>('api/Subscription/GetTenantName/'+tenentId);
  }

  //User Management

  GetClients(tenentId?:number):Observable<UserDetails[]>{
    //const httpOptions = {
    //  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    //};
    return this.http.get<UserDetails[]>('api/Subscription/GetClients/'+tenentId);

  }

  AddUser(register: NewClient,tenantId?:number) {
    //const httpOptions = {
    //  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    //};
    return this.http.post<UserDetails[]>('api/Subscription/AddUser/'+tenantId,register);
  }


  EditUser(user:UserDetails) {
    //const httpOptions = {
    //  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    //};
    return this.http.post<UserDetails>('api/Subscription/EditUser', user);
  }

  LicenceList(tenentId?:number): Observable<LicenceTemplate[]> {
    //const httpOptions = {
    //  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    //};
    return this.http.get<LicenceTemplate[]>('api/Subscription/GetLicenceList/'+tenentId);
  }


  // Serach Template


  GetSubscribedPlan(tenentId?:number): Observable<SubscriptionPlan> {
    //const httpOptions = {
    //  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    //};
    return this.http.get<SubscriptionPlan>('api/Subscription/GetSubscriptionPlan/'+tenentId);
  }

  GetLicenceTemplateList(tenentId:number): Observable<LicenceTemplate[]> {
    //const httpOptions = {
    //  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    //};
    return this.http.get<LicenceTemplate[]>('api/Subscription/GetLicenceTemplateList/'+tenentId);
  }

  UpdateLicence(licenceTeamplate: LicenceTemplate,tenentId?:number): Observable<any> {
    //const httpOptions = {
    //  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    //};
    return this.http.post<any>('api/Subscription/UpdateLicence/'+tenentId, licenceTeamplate);
  }

  CreateLicence(licenceTeamplate: LicenceTemplate,tenentId?:number): Observable<any> {
    //const httpOptions = {
    //  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    //};
    return this.http.post<any>('api/Subscription/CreateLicence/'+tenentId, licenceTeamplate);
  }

  DeleteLicence(licenceTemplate: LicenceTemplate): Observable<any> {
    //const httpOptions = {
    //  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    //};
    return this.http.delete<any>(`api/Subscription/DeleteLicence/${licenceTemplate.licenceId}`);
  }

  ForceDeleteLicence(licenceTemplate: LicenceTemplate): Observable<any> {
    //const httpOptions = {
    //  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    //};
    return this.http.delete<any>(`api/Subscription/ForceDeleteLicence/${licenceTemplate.licenceId}`);
  }

  GetLicenceItems(): Observable<Item[]> {
    //const httpOptions = {
    //  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    //};
    return this.http.get<Item[]>('api/Subscription/GetLicenceItems');
  }

  GetPlanExpiry(tenentId:number):Observable<PlanExpiry>{
    //const httpOptions = {
    //  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    //};
    return this.http.get<PlanExpiry>('api/Subscription/GetPlanExpiry?tenantId='+tenentId);

  }

  GetSubscriptionDetail(tenentId?:number){
    //const httpOptions = {
    //  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    //};
    return this.http.get<SubscriptionPlan>('api/Subscription/GetSubscriptionPlanDetails/'+tenentId);
  }

  reSendConfirmationEmail(reSendEmail: ResendLinkModel) {
    return this.http.post<any>('api/Subscription/ResendConfirmationLink', reSendEmail);
  }

  // Report

  GetAuditreport(tenentId?:number) {
    //const httpOptions = {
    //  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    //};
    return this.http.get<AuditReport[]>('api/Subscription/GetAuditreport/'+tenentId);
  }

  GetAuditDownloadReport(startDate: string, endDate: string,tenentId?:number) {
    //const httpOptions = {
    //  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    //};
    return this.http.get<Audit[]>('api/Subscription/GetAuditData/'+tenentId+'?startDate=' + startDate + '&endDate=' + endDate);
  }

  //  Manage Email Domains

  GetAllEmailDomains(tenentId?:number): Observable<EmailDomains[]> {
    //const httpOptions = {
    //  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    //};
    return this.http.get<EmailDomains[]>('api/Subscription/GetTenantEmailDomains/'+tenentId,);
  }

  AddDomain(domain:NewDomain,tenentId:number) {
    //const httpOptions = {
    //  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    //};
    return this.http.post('api/Subscription/AddDomain/'+tenentId,domain);
  }

  EditDomain(domain:NewDomain,tenentId:number) {
    //const httpOptions = {
    //  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    //};
    return this.http.post('api/Subscription/UpdateDomain/'+tenentId,domain);
  }

  // criminality rank

  GetCriminalityRankForSearch(): Observable<TenantSettings> {
    //const httpOptions = {
    //  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    //};
    return this.http.get<TenantSettings>('api/Subscription/GetCriminalitySearchRankForSearch');
  }

  // User settings

  GetTenantSettings(tenantId: number): Observable<TenantSettings> {
    //const httpOptions = {
    //  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    //};
    return this.http.get<TenantSettings>('api/Subscription/GetTenantSettings?tenantId=' + tenantId);
  }

  SaveTenantSettings(data: TenantSettings, tenantId: number) {
    //const httpOptions = {
    //  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    //};
    return this.http.post('api/Subscription/SaveTenantSettings?tenantId=' + tenantId, data);
  }
}

