import { Component, Input, OnInit } from '@angular/core';
import { MessagingService } from '../../../../../../services/messaging.service';
import { AddressResultModel } from '../../../../../models/AddressResultModel';

@Component({
  selector: 'app-address-result-personal',
  templateUrl: './address-result.component.html',
  styleUrls: ['./address-result.component.css', '../../../../shared/shared.searchpage.css']
})
export class AddressResultPersonalComponent implements OnInit {

  @Input() public address: AddressResultModel = new AddressResultModel();

  constructor(private messageService: MessagingService) { }

  ngOnInit(): void {
  }

  public getDataSourcesString(sources: any): string {
    if (!sources || sources.length == 0)
      return "";

    let sourceArr = sources.filter(x => x.Name);
    if (sourceArr.length == 0)
      return "";

    return sourceArr.map(x => x.Name).join(", ");
  }

}
