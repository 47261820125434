<table class="table table-striped table-hover">
  <tbody>
    <tr>
      <th>Company History</th>
    </tr>
    <tr>
      <td>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>Date</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let companyHistory of business?.responseData?.creditsafeResult?.report?.additionalInformation?.companyHistory">
              <td>{{companyHistory?.date | date:'dd-MMM-yyyy'}}</td>
              <td>{{companyHistory?.description}}</td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  </tbody>
</table>

<table class="table table-striped table-hover">
  <tbody>
    <tr>
      <th>Status History</th>
    </tr>
    <tr>
      <td>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>Date</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let statusHistory of business?.responseData?.creditsafeResult?.report?.additionalInformation?.statusHistory">
              <td>{{statusHistory?.date | date:'dd-MMM-yyyy'}}</td>
              <td>{{statusHistory?.description}}</td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  </tbody>
</table>
