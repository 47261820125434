import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  public EmailNotValidMessage: string = "Email is not valid.";
  public EmailRequiredMessage: string = "Email is required.";
  public MobileRequiredMessage: string = "Mobile number is required.";
  public MobileNotValidMessage: string = "Mobile number is not valid.";
  public PhoneNumberNotValidMessage: string = "Phone number is not valid.";
  public NameRequiredMessage: string = "Name is required.";
  public VrnRequiredMessage: string = "VRN is required.";
  public VrnNotValidMessage: string = "VRN is not valid.";
  public PostCodeRequiredMessage: string = "Postcode is required.";
  public PostCodeNotValidMessage: string = "Postcode is not valid.";
  public AssiciateDataNotProvideMessage: string = "Associated entity is required.";
  public ReferenceRequiredMessage: string = "Reference number is required.";
  public NoRelevantDataMessage: string = "No relevant data found.";
  public NoDataFoundMessage: string = "No data found for the stated address – try checking previous and linked addresses from the address tab in HeadsUp.";
  public AdditionalDataMessage: string = "Please include a date of birth.";
  public NoRelevantDataMessageShort: string = "No records.";
  public NoRecordsOfSubject: string = "N/A - No Record of Subject.";
  public NoSearchSelect: string = "Search option not selected.";
  public NoSearchSelectShort: string = "Not selected.";
  public SearchFail: string = "Search failed.";
  public MobileValidationMessage: string = "Mobile number is required.";
  public OtherFieldRequired: string = "Additional data is required.";
  public CompanyNumberNotValidMessage: string = "Company number is not valid.";
  public CompanyPostcodeNotValid: string = "Company postcode is not valid.";
  public BusinessValidationMessage: string = "A Company Number or Business Name required.";
  public BusinessNameRequiredMessage: string = "Business Name required.";
  public VatRequiredMessage: string = "VAT Number is required.";
  public T2ARequiredMessage: string = "Postcode and Address line 1 is required."
  public loadingAnimation: string = "data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==";
  public NoDataShortMessage: string = "No data.";
  public PostcodeOrDOBRequiredMessage: string = "Postcode or DOB required.";
  public CompanyNumberRequiredMessage: string = "Company Number Required.";
  
  public prospectProfileButtonText_RequestProfile: string = "Request Profile";
  public prospectProfileButtonText_Requested: string = "Requested";

  public VehicleRecordNotFound: string = "Record for vehicle not found";
  public NoLandRegistryMatch: string = "No Land Registry Match";
  public PostcodeAndAddressLine1Required: string = "Postcode and Address line 1 is required";
  public ProprietorDetailsRequired: string = "Proprietor details required.";
  constructor() { }
}
