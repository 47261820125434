
export class PlanExpiry
{
    constructor(
        public subscriptionExpiry:SubscriptionExpiry,
        public subscriptionBalaceCount:SubscriptionBalaceCount){
    }
   
}

export class SubscriptionExpiry
{
    constructor(
        public days:number,
        public hours:number,
        public minutes: number,
        public seconds: number){
    }
}
   
export class SubscriptionBalaceCount
{
    constructor(
        public ScreenCount:number,
        public SearchCount:number){
    }

}



// export interface PlanExpiry {
    
//     subscriptionExpiry : {days:number , hours:number}

//     subscriptionBalaceCount :null;
// }