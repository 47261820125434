<div class="card-body">
  <div>
    <span class="card-title Header-Font-Format d-flex justify-content-center mb-3"><b>Counter Fraud</b></span>
    <div>
      <div class="row mb-3">
        <div class="col-md-6 p-0 text-right">
          Existence
        </div>
        <div class="col-md-6 p-0 text-left">
          <span class="loadingImg" *ngIf="financial.isLoading">
            <img src="{{messageService.loadingAnimation}}" />
          </span>

          <div *ngIf="!financial.isLoading">
            <div
              *ngIf="(!financial?.responseData?.data?.CompleteResponse?.Response || !financial?.responseData?.data?.CompleteResponse?.Response?.People || financial?.responseData?.data?.CompleteResponse?.Response?.People?.length == 0)">
              <span class="ml-3 dot dot-red"></span>
            </div>
            <div
              *ngIf="(financial?.responseData?.data?.CompleteResponse?.Response?.People && financial?.responseData?.data?.CompleteResponse?.Response?.People?.length > 0)">
              <span class="ml-3 dot dot-green"></span>
            </div>
          </div>

        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-6 p-0 text-right">
          Mortality
        </div>
        <div class="col-md-6 p-0 text-left">
          <span class="loadingImg" *ngIf="financial.isLoading">
            <img src="{{messageService.loadingAnimation}}" />
          </span>

          <div class="col-md-12" *ngIf="!financial.isLoading && financial.noSearchSelect"><i
              class="color-grey float-right">{{messageService.NoSearchSelectShort}}</i></div>
          <div class="col-md-12"
            *ngIf="!financial.isLoading && !financial.noSearchSelect && !financial.searchFail && (financial.noRelevantData || financial?.responseData?.data?.MortalityFlag == null) && !financial.validation">
            <i class="color-grey float-right">{{messageService.NoRecordsOfSubject}}</i></div>
          <div class="col-md-12"
            *ngIf="!financial.isLoading && !financial.noSearchSelect && financial.searchFail && !financial.validation">
            <i class="color-grey float-right">{{messageService.SearchFail}}</i></div>
          <div class="col-md-12" *ngIf="!financial.isLoading && !financial.noSearchSelect && financial.validation"><i
              class="color-grey float-right">{{messageService.PostCodeRequiredMessage}}</i></div>

          <div *ngIf="financial?.responseData?.data?.MortalityFlag != null && !financial.isLoading">
            <span *ngIf="financial?.responseData?.data?.MortalityFlag" class="ml-3 dot dot-red"></span>
            <span *ngIf="!financial?.responseData?.data?.MortalityFlag" class="ml-3 dot dot-green"></span>
          </div>
        </div>
      </div>
      <app-prospect-dashboard-personal [counterFraud]="counterFraud"></app-prospect-dashboard-personal>
    </div>
  </div>
</div>