<div class="container">
  <div class="logo-div d-flex justify-content-center">
    <img class="logo" src="../../../../../assets/Images/new/HUT-icon.v3-motor-RO.png" />
  </div>
  <form role="form">
    <div class="form-group row form-top-margin">
      <div class="col-md-8 form-data-background form-borderdesign  shadow-lg p-3">
        <div class="row padding-form-design">
          <div class="col-md-12 z-index-formdata">
            <div class="row">
              <div class="input-group">
                <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                  <b>Reference:</b>
                </div>
                <div class="col-md-8 pt-3">
                  <div class="form-group">
                    <input type="text" placeholder="" class="form-control" value="{{requestData.profileData.referenceNumber}}" autocomplete="off" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 z-index-formdata">
            <div class="row">
              <div class="input-group">
                <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                  <b>VRN:</b>
                </div>
                <div class="col-md-8 pt-3">
                  <div class="form-group">
                    <input type="text" placeholder="" class="form-control" value="{{requestData.profileData.vRN}}" autocomplete="off" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 toggle-div form-borderdesign result-form-background shadow-lg p-3 #F7F7F7">
        <ul class="ul-toggle">
          <li class="result-animation" [ngClass]="requestData.profileData.vRN ? 'result-get-list-font-design': 'checkmark-default-design'">
            <label class="switch">
              <input type="checkbox" [checked]="requestData.accessData.isAVAAccess">
              <span class="slider round"></span>
            </label>
            <img src="assets/Images/icons/AVA.svg" class="icon-size" />
            <span>AVA</span>
          </li>
        </ul>
      </div>
    </div>
  </form>
</div>
