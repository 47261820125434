<div class="pdf-container">
  <div class="row">
    <div class="col-md-9 form-data-background form-borderdesign  shadow-lg #F7F7F7">
      <app-search-form [inputData]="inputData"></app-search-form>
    </div>
    <div class="col-md-3 col-md-offset-2 form-borderdesign result-form-background shadow-lg p-3 #F7F7F7">
      <app-access-toggle [accessData]="accessData" [inputData]="inputData"></app-access-toggle>
    </div>
  </div>
  <div class="page-break"></div>
  <div class="row">
    <div class="col-md-12">
      <app-result-top-card [accessData]="accessData"
                           [inputData]="inputData"
                           [mobile]="mobile"
                           [counterFraud]="counterFraud"
                           [email]="email"
                           [financial]="financial"></app-result-top-card>
    </div>
  </div>
  <div class="page-break"></div>
  <div class="row" *ngIf="financial.responseData && accessData.Address">
    <div class="col-md-12">
      <ul class="nav nav-tabs" id="searchResultTab" role="tablist">
        <li class="nav-item">
          <a class="nav-link" id="address-search-tab" data-toggle="tab" role="tab"
             aria-controls="profile" aria-selected="false"><b>Address</b></a>
        </li>
      </ul>
    </div>
    <div class="col-md-12">
      <app-address-check [address]="address" [accessData]="accessData"></app-address-check>
    </div>
  </div>


  <div class="row" *ngIf="amlResultData && accessData.AML">
    <div class="col-md-12">
      <ul class="nav nav-tabs" id="searchResultTab" role="tablist">
        <li class="nav-item">
          <a class="nav-link" id="address-search-tab" data-toggle="tab" role="tab"
             aria-controls="profile" aria-selected="false"><b>AML</b></a>
        </li>
      </ul>
    </div>
    <div class="col-md-12">
      <app-aml-check [amlResultData]="amlResultData" [accessData]="accessData"></app-aml-check>
    </div>
  </div>

  <div class="row" *ngIf="avaResult && accessData.AVA">
    <div class="col-md-12">
      <ul class="nav nav-tabs" id="searchResultTab" role="tablist">
        <li class="nav-item">
          <a class="nav-link" id="address-search-tab" data-toggle="tab" role="tab"
             aria-controls="profile" aria-selected="false"><b>Ava</b></a>
        </li>
      </ul>
    </div>
    <div class="col-md-12">
      <app-ava-check [avaResult]="avaResult" [accessData]="accessData"></app-ava-check>
    </div>
  </div>

  <div class="row" *ngIf="ccjResultData && accessData.CCJDetails">
    <div class="col-md-12">
      <ul class="nav nav-tabs" id="searchResultTab" role="tablist">
        <li class="nav-item">
          <a class="nav-link" id="address-search-tab" data-toggle="tab" role="tab"
             aria-controls="profile" aria-selected="false"><b>CCJ</b></a>
        </li>
      </ul>
    </div>
    <div class="col-md-12">
      <app-cjj-check [ccjResultData]="ccjResultData" [accessData]="accessData"></app-cjj-check>
    </div>
  </div>

  <div class="row" *ngIf="ccjResultData && accessData.CCJDetails">
    <div class="col-md-12">
      <ul class="nav nav-tabs" id="searchResultTab" role="tablist">
        <li class="nav-item">
          <a class="nav-link" id="address-search-tab" data-toggle="tab" role="tab"
             aria-controls="profile" aria-selected="false"><b>Insolvency</b></a>
        </li>
      </ul>
    </div>
    <div class="col-md-12">
      <app-insolvency-check [insolvencyResultData]="insolvencyData" [accessData]="accessData"></app-insolvency-check>
    </div>
  </div>

  <div class="row" *ngIf="prospectData && accessData.CounterFraud">
    <div class="col-md-12">
      <ul class="nav nav-tabs" id="searchResultTab" role="tablist">
        <li class="nav-item">
          <a class="nav-link" id="address-search-tab" data-toggle="tab" role="tab"
             aria-controls="profile" aria-selected="false"><b>Prospect</b></a>
        </li>
      </ul>
    </div>
    <div class="col-md-12">
      <app-prospect-check [prospectResultData]="prospectData" [inputData]="inputData" [accessData]="accessData"></app-prospect-check>
    </div>
  </div>
</div>
