import { Component, OnInit } from '@angular/core';
import { TenantRegion } from '../../models/TenantRegionResposne';
import { SearchService } from '../../../services/search.service';

@Component({
  selector: 'app-motor',
  templateUrl: './motor.component.html',
  styleUrls: ['./motor.component.css', '../shared/shared.searchpage.css']
})
export class MotorComponent implements OnInit {

  public regionAccess: TenantRegion = new TenantRegion();

  constructor(private searchService: SearchService) { }

  ngOnInit(): void {
    this.getTenantRegion();
  }

  public getTenantRegion() {
    this.searchService.GetTenantRegion().subscribe(data => {
      this.regionAccess = data;
    }, error => {
      console.log(error);
    });
  }

}
