<div class="card-body">
  <span class="card-title Header-Font-Format"><b>Email Check</b></span>
  <div class="d-block">
    <div *ngIf="!email.isLoading">
      <div class="col-md-12" *ngIf="email.noSearchSelect"><i class="color-grey float-right">{{messageService.NoSearchSelectShort}}</i></div>
      <div class="col-md-12" *ngIf="email.validation"><i class="color-grey float-right">{{messageService.EmailRequiredMessage}}</i></div>
      <div class="col-md-12" *ngIf="email.searchFail"><i class="color-grey float-right">{{messageService.SearchFail}}</i></div>
      <div class="col-md-12" *ngIf="email.noRelevantData"><i class="color-grey float-right">{{messageSercice.NoRelevantDataMessageShort}}</i></div>
    </div>
    <div class="loadingImg" *ngIf="email.isLoading">
      <img src="{{messageService.loadingAnimation}}" />
    </div>
    <div *ngIf="email?.responseData?.data != null && !email.isLoading && (!email.searchFail || !email.noRelevantData || !email.validation)">
      <p *ngIf="email?.responseData?.data === true"><i class="fa fa-ban color-red"></i> Blocked </p>
      <p *ngIf="email?.responseData?.data === false"><i class="fa fa-check-circle color-green"></i> Not Blocked </p>
    </div>
  </div>
  <div class="d-block">
    <div *ngIf="!emailage.isLoading">
      <div class="col-md-12" *ngIf="emailage.searchFail"><i class="color-grey float-right">{{messageService.SearchFail}}</i></div>
    </div>
    <div class="loadingImg" *ngIf="emailage.isLoading">
      <img src="{{messageService.loadingAnimation}}" />
    </div>
    <div *ngIf="!emailage.isLoading && !emailage.searchFail && emailage.responseData">
      <div *ngIf="emailage.responseData?.data?.isValidFirstSeenDays"><i class="fa fa-check-circle color-green"></i> Not New</div>
      <div *ngIf="!emailage.responseData?.data?.isValidFirstSeenDays"><i class="fa fa-ban color-red"></i> New Email</div>
    </div>
  </div>
</div>
