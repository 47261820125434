import { Component, Input, OnInit } from '@angular/core';
import { CounterFraudResultModel } from '../../../../../../search-pages/models/CounterFraudResultModel';
import { MessagingService } from '../../../../../../services/messaging.service';

@Component({
  selector: 'app-counter-fraud-result-personal',
  templateUrl: './counter-fraud-result.component.html',
  styleUrls: ['./counter-fraud-result.component.css', '../../../../shared/shared.searchpage.css']
})
export class CounterFraudResultPersonalComponent implements OnInit {

  @Input() counterFraud: CounterFraudResultModel = new CounterFraudResultModel();

  public canvasWidth = 270
  public centralLabel = ''
  public bottomLabel = ''
  public options = {
    hasNeedle: true,
    needleColor: 'gray',
    needleUpdateSpeed: 1000,
    arcColors: ['green', 'orange', '#CE103E'],
    arcDelimiters: [33.33, 66.66, 99.99],
    needleStartValue: 0,
  }

  constructor(private messageService: MessagingService) { }

  ngOnInit(): void {
  }

}
