import { Component, Input, OnInit } from '@angular/core';
import { MessagingService } from '../../../../../../../services/messaging.service';
import { SocialMediaResultModel } from '../../../../../../models/SocialMediaResultModel';

@Component({
  selector: 'app-pdf-socialmedia-result-personal',
  templateUrl: './pdf-socialmedia-result-personal.component.html',
  styleUrls: ['./pdf-socialmedia-result-personal.component.css', '../../../../../shared/shared.searchpage.css']
})
export class PdfSocialmediaResultPersonalComponent implements OnInit {

  @Input() socialMedia: SocialMediaResultModel = new SocialMediaResultModel();

  constructor(private messageService: MessagingService) { }

  ngOnInit(): void {
  }

}
