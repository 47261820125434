import { Component, Input, OnInit } from '@angular/core';
import { RightToWorkCheckResultModel } from '../../../../../../search-pages/models/EmailResultModel';
import { MessagingService } from '../../../../../../services/messaging.service';

@Component({
  selector: 'app-righttowork-result-personal',
  templateUrl: './righttowork-result.component.html',
  styleUrls: ['./righttowork-result.component.css', '../../../../shared/shared.searchpage.css']
})
export class RighttoworkResultPersonalComponent implements OnInit {

  @Input() rtw: RightToWorkCheckResultModel = new RightToWorkCheckResultModel();

  constructor(private messageService: MessagingService) { }

  ngOnInit(): void {
  }

}
