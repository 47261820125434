import { Component, Input, OnInit } from '@angular/core';
import { MessagingService } from '../../../../../services/messaging.service';
import { EmailCheckResultModel } from '../../../../models/EmailResultModel';

@Component({
  selector: 'app-emailvalidation-result-email',
  templateUrl: './emailvalidation-result-email.component.html',
  styleUrls: ['./emailvalidation-result-email.component.css', '../../../shared/shared.searchpage.css']
})
export class EmailvalidationResultEmailComponent implements OnInit {

  @Input() emailValidation: EmailCheckResultModel = new EmailCheckResultModel();

  constructor(private messageService: MessagingService) { }

  ngOnInit(): void {
  }

}
