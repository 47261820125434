import { Component, Input, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { PlanExpiry } from 'src/app/subscription/Models/PlanExpiry';
import { SubscriptionService } from 'src/app/subscription/service/subscription.service';

@Component({
    selector: 'app-toast-message',
    templateUrl: 'toast-message.component.html',
    providers:[MessageService]
})

export class ToastMessageComponent implements OnInit {

    // public planExpiry: PlanExpiry = null;
    // public isPlanExpired: boolean = false;

    @Input() planExpiry: PlanExpiry = null;
    @Input() isPlanExpired: boolean = false;

    constructor(private subscriptionService: SubscriptionService,
    private messageService: MessageService) { }

    ngOnInit() { 
        this.subscriptionService.GetPlanExpiry(0).toPromise().then(
            data => {
              this.planExpiry = data;
              let days = this.planExpiry.subscriptionExpiry.days;
              let hours = this.planExpiry.subscriptionExpiry.hours;
              let minutes = this.planExpiry.subscriptionExpiry.minutes;
              let seconds = this.planExpiry.subscriptionExpiry.seconds;
              if (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) {
                this.isPlanExpired = true;
                this.subscriptionService.GetSubscriptionDetail().toPromise().then(
                  data => {
                    this.messageService.add({ key: 'PlanExpiry', severity: 'warn', summary: 'HeadsUp', detail: 'Your ' + data.planName + ' plan has been expired', sticky: true });
                    setTimeout(() => {
                      this.messageService.clear();
                    }, 13000);
                  },
                  error => {
                    console.log(error);
                  }
                );
              }
               else {
                this.isPlanExpired = false;
              }
            },
            error => {
              console.log(error);
            }
          );
    }
}
