import { Component, Input, OnInit } from '@angular/core';
import { SportsResultModel } from '../../../../../../search-pages/models/SportsResultModel';
import { MessagingService } from '../../../../../../services/messaging.service';
declare var $: any;

@Component({
  selector: 'app-sports-result-personal',
  templateUrl: './sports-result.component.html',
  styleUrls: ['./sports-result.component.css', '../../../../shared/shared.searchpage.css']
})
export class SportsResultPersonalComponent implements OnInit {

  @Input() sports: SportsResultModel = new SportsResultModel();

  constructor(private messageService: MessagingService) { }

  ngOnInit(): void {
  }

  public SportsTableDisplay() {
    if (this.sports.collapseSportsClass == "show") {
      this.sports.collapseSportsClass = "hide";
      this.sports.collapseSportsStatus = "Show Status";
      this.sports.collapseSportsIcon = "fa-chevron-down";
      $('#collapseTwo').collapse(this.sports.collapseSportsClass);
    }
    else {
      this.sports.collapseSportsClass = "show";
      this.sports.collapseSportsStatus = "Hide Status";
      this.sports.collapseSportsIcon = "fa-chevron-up";
      $('#collapseTwo').collapse(this.sports.collapseSportsClass);
    }
  }

}
