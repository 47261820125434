<table class="table table-striped table-hover">
  <thead>
    <tr>
      <th>Comment</th>
      <th>Positive or Negative</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let commentary of business?.responseData?.creditsafeResult?.report?.additionalInformation?.commentaries">
      <td>{{commentary?.commentaryText}}</td>
      <td>{{commentary?.positiveOrNegative}}</td>
    </tr>
  </tbody>
</table>
