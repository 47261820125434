export class InputData {
  public referenceNumber: string ;
  public searchName: string ;
  public postCode: string ;
  public phoneNumber: string ;
  public email: string ;
  public vrn: string ;
  public dateOfBirth: string ;
  public addressLine1: string ;
  public addressLine2: string ;
  public city: string ;
  public state: string ;
  public country: string ;
  public companyName: string ;
  public associatedName: any[] = [{ user: '' }, { user: '' }, { user: '' }, { user: '' }];

  constructor() { }
}

export class InputAccessData {
  public HUTDatabase: boolean;
  public Mobile: boolean;
  public Email: boolean;
  public Identity: boolean;
  public Financial: boolean;
  public Address: boolean;
  public Criminality: boolean;
  public SocialMedia: boolean;
  public CounterFraud: boolean;
  public Sports: boolean;
  public Advert: boolean;
  public CompaniesHouse: boolean;
  public AVA: boolean;
  public AML: boolean;
  public CCJDetails: boolean;
  public InsolvencyDetails: boolean;

  constructor() { }
}
