import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PDFExportComponent } from '@progress/kendo-angular-pdf-export';
import { MessagingService } from '../../../services/messaging.service';
import { SearchService } from '../../../services/search.service';
import { Shared } from '../../../services/shared';
import { Item } from '../../models/Items';
import { AccessData, ProfileData, RequestData } from '../../models/SearchRequestModel';
import { SearchType } from '../../models/SearchType';

@Component({
  selector: 'app-documentvalidation',
  templateUrl: './documentvalidation.component.html',
  styleUrls: ['./documentvalidation.component.css', '../shared/shared.searchpage.css']
})
export class DocumentvalidationComponent implements OnInit {

  public documentValidationForm: FormGroup;
  public validation: any = {
    'reference': [Validators.required],
    'email': [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$')]
  };
  public accessData: AccessData = new AccessData();
  public showResultTab: boolean = false;
  public File: File[];
  public FileNames: string[] = [];
  public USIdCheckUploadResult: any;
  public isFileValidation: boolean = false;
  public isLoading: boolean = false;
  public saveAsPDFButtonVisibility: boolean = true;
  public fileOneUrl: string = "";
  public fileTwoUrl: string = "";
  public loggedInUsername: string = "";

  // PDF
  public requestDataModel: RequestData = new RequestData();
  @ViewChild('pdf') pdf: PDFExportComponent;
  @ViewChild('file') fileInput: ElementRef;

  constructor(private elementRef: ElementRef,
    private formBuilder: FormBuilder,
    private searchService: SearchService,
    private messageService: MessagingService,
    private router: Router) { }

  ngOnInit(): void {
    let isLoggedIn = localStorage.getItem("access_token") ? true : false;
    if (!isLoggedIn)
      this.router.navigate(['/Login']);
    this.isLoading = false;
    this.saveAsPDFButtonVisibility = false;
    this.documentValidationForm = this.formBuilder.group({
      audit: [""],
      reference: [""]
    });
    this.isFileValidation = false;
    this.getUserDetails();
  }

  ngAfterViewInit() {
    this.elementRef.nativeElement.ownerDocument.body.style.background = 'url(/assets/Images/new/Background/HUT-elem-bg-1.png)';
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundAttachment = 'fixed';
  }

  documentValidationSearch() {
    this.addValidators();
    this.documentValidationForm.updateValueAndValidity();
    this.isFileValidation = false;

    if (this.documentValidationForm.valid && this.fileValidation()) {

      Promise.resolve(this.getRandomString(10)).then(
        (data) => {
          this.saveAsPDFButtonVisibility = true;
          this.resetResultObjects();

          this.showResultTab = true;
          var requestObj: RequestData = this.prepareRequestData();

          // Document validation check
          this.documentValidationCheck(requestObj);

        }
      );

    }
  }

  public getUserDetails() {
    this.searchService.getUserDetails().subscribe(data => {
      this.loggedInUsername = data.username;
    }, error => {
      console.log(error);
    })
  }

  public generatePDF() {
    var requestObj: RequestData = this.prepareRequestData();
    this.requestDataModel = requestObj;

    setTimeout(() => {
      this.pdf.forcePageBreak = ".page-break"
      this.pdf.keepTogether = ".keep-together";
      this.pdf.paperSize = "A4";
      const margin = {
        top: '2cm',
        bottom: '3cm',
        left: '1cm',
        right: '1cm'
      }
      this.pdf.margin = margin;
      this.pdf.scale = 0.5;
      this.pdf.saveAs('export.pdf');
    }, 2000);
  }

  // Document validation check
  public documentValidationCheck(requestObj: RequestData) {
    if (this.File.length == 0) {
      alert("Please upload atlease 1 file.");
      return;
    }

    if (this.File.length > 2) {
      alert("Please upload 2 files only.");
      return;
    }

    const formData = new FormData();
    if (this.File.length > 0) {
      this.fileOneUrl = "";
      this.fileTwoUrl = "";
      if (this.File.length == 2) {
        var reader1 = new FileReader();
        reader1.onload = (e: any) => {
          this.fileOneUrl = e.target.result
        };
        reader1.readAsDataURL(this.File[0]);

        var reader2 = new FileReader();
        reader2.onload = (e: any) => {
          this.fileTwoUrl = e.target.result
        };
        reader2.readAsDataURL(this.File[1]);

        formData.append('frontfile', this.File[0], this.File[0].name);
        formData.append('rearfile', this.File[1], this.File[1].name);
      } else if (this.File.length == 1) {
        var reader = new FileReader();
        reader.onload = (e: any) => {
          this.fileOneUrl = e.target.result
        };
        reader.readAsDataURL(this.File[0]);
        
        formData.append('frontfile', this.File[0], this.File[0].name);
      }
    }

    if (this.File.length > 0) {
      this.isLoading = true;
      setTimeout(() => {
        this.searchService.DocumentValidation(requestObj.profileData.referenceNumber, requestObj.profileData.searchType, formData).subscribe(
          data => {
            this.isLoading = false;
            this.USIdCheckUploadResult = data;
          },
          error => {
            console.log(error);
            this.fileOneUrl = "";
            this.fileTwoUrl = "";
          }
        );
      }, 10000);
    }
  }

  public getLicenceItems(): void {
    this.searchService.AllowedLicence().subscribe(data => {
      this.getSecondAccess(data);
    });
  }

  public getSecondAccess(Items: Item[]) {
    Items.forEach(item => {
      if (item.licenceType.toLowerCase() == "search") {
        switch ((item.key)) {
          case "HUTDB": {
            this.accessData.isHUTDatabaseAccess = item.defaultValue;
            this.accessData.isHUTDatabaseToggle = item.toggle;
            break;
          }
          case "MOBILE": {
            this.accessData.isMobileAccess = item.defaultValue;
            this.accessData.isMobileToggle = item.toggle;
            break;
          }
          case "EMAIL": {
            this.accessData.isEmailAccess = item.defaultValue;
            this.accessData.isEmailToggle = item.toggle;
            break;
          }
          case "IDENTITY": {
            this.accessData.isIdentityAccess = item.defaultValue;
            this.accessData.isIdentityToggle = item.toggle;
            break;
          }
          case "FINANCIAL": {
            this.accessData.isFinancialAccess = item.defaultValue;
            this.accessData.isFinancialToggle = item.toggle;
            break;
          }
          case "ADDRESS": {
            this.accessData.isAddressAccess = item.defaultValue;
            this.accessData.isAddressToggle = item.toggle;
            break;
          }
          case "CRIMINALITY": {
            this.accessData.isCriminalityAccess = item.defaultValue;
            this.accessData.isCriminalityToggle = item.toggle;
            break;
          }
          case "SOCIAL": {
            this.accessData.isSocialMediaAccess = item.defaultValue;
            this.accessData.isSocialMediaToggle = item.toggle;
            break;
          }
          case "COUNTER_FRAUD": {
            this.accessData.isCounterFraudAccess = item.defaultValue;
            this.accessData.isCounterFraudToggle = item.toggle;
            break;
          }
          case "SPORTS": {
            this.accessData.isSportsAccess = item.defaultValue;
            this.accessData.isSportsToggle = item.toggle;
            break;
          }
          case "ADVERT": {
            this.accessData.isAdvertsAccess = item.defaultValue;
            this.accessData.isAdvertsToggle = item.toggle;
            break;
          }
          case "COMPANY_HOUSE": {
            this.accessData.isCompaniesHouseAccess = item.defaultValue;
            this.accessData.isCompaniesHouseToggle = item.toggle;
            break;
          }
          case "AVA": {
            this.accessData.isAVAAccess = item.defaultValue;
            this.accessData.isAVAToggle = item.toggle;
            break;
          }
          case "AML": {
            this.accessData.isAMLAccess = item.defaultValue;
            this.accessData.isAMLToggle = item.toggle;
            break;
          }
          case "CCJ_Detail": {
            this.accessData.isCCJDetailAccess = item.defaultValue;
            this.accessData.isCCJDetailToggle = item.toggle;
            break;
          }
          case "Insolvency_Detail": {
            this.accessData.isInsolvencyDetailAccess = item.defaultValue;
            this.accessData.isInsolvencyDetailToggle = item.toggle;
            break;
          }
          case "RTW": {
            this.accessData.isRTWAccess = item.defaultValue;
            this.accessData.isRTWToggle = item.toggle;
            break;
          }
          case "IDCHECK": {
            this.accessData.isIDCheckAccess = item.defaultValue;
            this.accessData.isIDCheckToggle = item.toggle;
            break;
          }
        }
      }
    });
  }

  // Prepare the request object for API call
  public prepareRequestData(): RequestData {
    // Profile object framing
    var profileData = new ProfileData();
    profileData.auditKey = this.documentValidationForm.get('audit').value;
    profileData.referenceNumber = this.documentValidationForm.get('reference').value;

    profileData.searchType = SearchType.DocumentValidation.toString();
    profileData.searchDate = Shared.getCurrentDate();
    profileData.searchTime = Shared.getCurrentTime();
    profileData.searchUsername = this.loggedInUsername;

    // RequestData object framing
    var requestObj = new RequestData();
    requestObj.profileData = profileData;
    requestObj.accessData = this.accessData;
    return requestObj;
  }

  public async getRandomString(length) {
    await this.searchService.GetAuditKey().then(
      data => {
        this.documentValidationForm.patchValue({
          audit: data
        });
      });
  }

  onFileSelected(event) {
    this.isFileValidation = false;
    this.File = [];
    this.FileNames = [];

    if (event.target.files.length > 0) {
      if (event.target.files.length <= 2) {
        this.File = Array.from<File>(event.srcElement.files);
        this.FileNames = this.File.map(x => { return x.name });
      } else {
        this.isFileValidation = true;
        this.FileNames = Array.from<File>(event.srcElement.files).map(x => { return x.name })
      }
    }
    else {
      this.isFileValidation = true;
    }
  }

  public fileValidation(): boolean {
    this.isFileValidation = false;

    if (!this.FileNames || this.FileNames.length == 0) {
      this.isFileValidation = true;
      return false;
    }

    if (this.FileNames.length > 2) {
      this.isFileValidation = true;
      return false;
    }

    return true;
  }

  // Add the validations during the 'search' button click
  public addValidators(): void {
    for (const control in this.documentValidationForm.controls) {
      this.documentValidationForm.get(control).clearValidators();
      this.documentValidationForm.get(control).setValidators(this.validation[control]);
      this.documentValidationForm.get(control).updateValueAndValidity();
    }
  }

  // Clear the validations during the 'clear' button click
  // This'll remove all the error message
  public documentValidationClearSearch(): void {
    this.fileOneUrl = "";
    this.fileTwoUrl = "";
    for (const control in this.documentValidationForm.controls) {
      this.documentValidationForm.get(control).clearValidators();
      this.documentValidationForm.get(control).updateValueAndValidity();
    }
    this.documentValidationForm.patchValue({
      audit: "",
      reference: ""
    });
    this.showResultTab = false;
    this.File = [];
    this.FileNames = [];
    this.fileInput.nativeElement.value = "";
    this.USIdCheckUploadResult = null;
    this.fileValidation();
    this.saveAsPDFButtonVisibility = false;
  }

  // Reset Object
  public resetResultObjects() {
    this.USIdCheckUploadResult = null;
    this.fileOneUrl = "";
    this.fileTwoUrl = "";
  }

}
