<div>
  <!-- Top navigation -->
  <section id="navigation">
    <div class="container-fluid">
      <nav class="top-nav">
        <div class="top-nav-logo">
          <a routerLink="/v3">
            <img class="headsup-logo-img" src="../../../../assets/Images/new/Background/HUT-elem-logo.svg" />
          </a>
        </div>
        <div class="top-nav-menu">
          <div class="settings mr-5">
            <a routerLink="/settings" class="settings-nav">
              <img src="../../../../assets/Images/new/Background/HUT-elem-settings-icon.png" />
              <span>Settings</span>
            </a>
          </div>
          <div class="logout mr-5">
            <div class="logout-a" (click)="appComponent.logout()">
              <img src="../../../../assets/Images/new/Background/HUT-elem-logout-icon.png" />
              <span>Logout</span>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </section>
  <!-- Search options -->
  <section id="search-nav">
    <div class="search-option-container">
      <div class="first-row">
        <div style="min-height: 310px;" class="row">

          <div class="col col-lg-3">
            <div class="user-welcome-message">
              <p>Welcome {{userDetails?.firstname ? userDetails?.firstname : '-' | titlecase}}.</p>
              <p>Select your <br />search area <br />and dive in!</p>
            </div>
          </div>

          <!-- Personal Search -->
          <div class="col col-lg-2 align-self-end">
            <div *ngIf="isPersonalAccess; else personalNoAccess">
              <a routerLink="/v3/personal" class="searchoption-a-positive">
                <img class="searchoption-img" [src]="personalImgSrc"
                     (mouseover)="personalImgSrc = landingImage.personalROImgSrc"
                     (mouseout)="personalImgSrc = landingImage.personalImgSrc" />
              </a>
            </div>
            <ng-template #personalNoAccess>
              <a class="searchoption-a-positive">
                <img class="searchoption-img" [src]="landingImage.personalGreyImgSrc" />
              </a>
            </ng-template>
          </div>

          <!-- HUT Screen Search -->
          <div class="col col-lg-2">
            <div *ngIf="isHUTScreenAccess; else hutScreenNoAccess">
              <a class="searchoption-a-negative" routerLink="/v3/screen">
                <img class="searchoption-img" [src]="hutScreenImgSrc"
                     (mouseover)="hutScreenImgSrc = landingImage.hutScreenROImgSrc"
                     (mouseout)="hutScreenImgSrc = landingImage.hutScreenImgSrc" />
              </a>
            </div>
            <ng-template #hutScreenNoAccess>
              <a class="searchoption-a-negative">
                <img class="searchoption-img" [src]="landingImage.hutScreenGreyImgSrc" />
              </a>
            </ng-template>
          </div>

          <!-- Business Search -->
          <div class="col col-lg-2 align-self-end">
            <div *ngIf="isBusinessAccess; else businessNoAccess">
              <a routerLink="/v3/business" class="searchoption-a-positive">
                <img class="searchoption-img" [src]="businessImgSrc"
                     (mouseover)="businessImgSrc = landingImage.businessROImgSrc"
                     (mouseout)="businessImgSrc = landingImage.businessImgSrc" />
              </a>
            </div>
            <ng-template #businessNoAccess>
              <a class="searchoption-a-positive">
                <img class="searchoption-img" [src]="landingImage.businessGreyImgSrc" />
              </a>
            </ng-template>
          </div>

          <div class="col col-lg-3">
            <img style="width: 350px; margin-left: -50px;" src="../../../../assets/Images/new/Background/HUT-elem-world-possibilities.svg" />
          </div>

        </div>
      </div>
      <div class="second-row">
        <div style="min-height: 310px; margin-top: -50px;" class="row">

          <!-- Mobile Search -->
          <div class="col col-lg-2 offset-lg-2">
            <div *ngIf="isMobileAccess; else mobileNoAccess">
              <a class="searchoption-a-negative" routerLink="/v3/mobile">
                <img class="searchoption-img" [src]="mobileImgSrc"
                     (mouseover)="mobileImgSrc = landingImage.mobileROImgSrc"
                     (mouseout)="mobileImgSrc = landingImage.mobileImgSrc" />
              </a>
            </div>
            <ng-template #mobileNoAccess>
              <a class="searchoption-a-negative">
                <img class="searchoption-img" [src]="landingImage.mobileGreyImgSrc" />
              </a>
            </ng-template>
          </div>

          <!-- Email Search -->
          <div class="col col-lg-2 align-self-end">
            <div *ngIf="isEmailAccess; else emailNoAccess">
              <a class="searchoption-a-positive" routerLink="/v3/email">
                <img class="searchoption-img" [src]="emailImgSrc"
                     (mouseover)="emailImgSrc = landingImage.emailROImgSrc"
                     (mouseout)="emailImgSrc = landingImage.emailImgSrc" />
              </a>
            </div>
            <ng-template #emailNoAccess>
              <a class="searchoption-a-positive">
                <img class="searchoption-img" [src]="landingImage.emailGreyImgSrc" />
              </a>
            </ng-template>
          </div>

          <!-- Motor Search -->
          <div class="col col-lg-2">
            <div *ngIf="isMotorAccess; else motorNoAccess">
              <a class="searchoption-a-negative" routerLink="/v3/motor">
                <img class="searchoption-img" [src]="motorImgSrc"
                     (mouseover)="motorImgSrc = landingImage.motorROImgSrc"
                     (mouseout)="motorImgSrc = landingImage.motorImgSrc" />
              </a>
            </div>
            <ng-template #motorNoAccess>
              <a class="searchoption-a-negative">
                <img class="searchoption-img" [src]="landingImage.motorGreyImgSrc" />
              </a>
            </ng-template>
          </div>

          <!-- Check Professional Search -->
          <div class="col col-lg-2 align-self-end">
            <div *ngIf="isCheckProfessionalAccess; else checkProfessionalNoAccess">
              <a class="searchoption-a-positive" routerLink="/v3/professional">
                <img class="searchoption-img" [src]="professionalImgSrc"
                     (mouseover)="professionalImgSrc = landingImage.professionalROImgSrc"
                     (mouseout)="professionalImgSrc = landingImage.professionalImgSrc" />
              </a>
            </div>
            <ng-template #checkProfessionalNoAccess>
              <a class="searchoption-a-positive">
                <img class="searchoption-img" [src]="landingImage.professionalGreyImgSrc" />
              </a>
            </ng-template>
          </div>

          <!-- Photo Analysis Search -->
          <div class="col col-lg-2">
            <div *ngIf="isPhotoAnalysisAccess; else photoAnalysisNoAccess">
              <a class="searchoption-a-negative" routerLink="/v3/photoanalysis">
                <img class="searchoption-img" [src]="photoAnalysisImgSrc"
                     (mouseover)="photoAnalysisImgSrc = landingImage.photoAnalysisROImgSrc"
                     (mouseout)="photoAnalysisImgSrc = landingImage.photoAnalysisImgSrc" />
              </a>
            </div>
            <ng-template #photoAnalysisNoAccess>
              <a class="searchoption-a-negative">
                <img class="searchoption-img" [src]="landingImage.photoAnalysisGreyImgSrc" />
              </a>
            </ng-template>
          </div>

        </div>
      </div>
      <div class="third-row">
        <div style="min-height: 310px; margin-top: -50px;" class="row">

          <!-- Document Validation Search -->
          <div class="col col-lg-2 offset-lg-5">
            <div *ngIf="isDocumentValidationAccess; else documentValidationNoAccess">
              <a class="searchoption-a-negative" routerLink="/v3/documentvalidation">
                <img class="searchoption-img" [src]="documentValidationImgSrc"
                     (mouseover)="documentValidationImgSrc = landingImage.documentValidationROImgSrc"
                     (mouseout)="documentValidationImgSrc = landingImage.documentValidationImgSrc" />
              </a>
            </div>
            <ng-template #documentValidationNoAccess>
              <a class="searchoption-a-negative">
                <img class="searchoption-img" [src]="landingImage.documentValidationGreyImgSrc" />
              </a>
            </ng-template>
          </div>

          <!-- Right To Work Search -->
          <div class="col col-lg-2 align-self-end">
            <div *ngIf="isRTWAccess; else rtwNoAccess">
              <a class="searchoption-a-positive" routerLink="/v3/righttowork">
                <img class="searchoption-img" [src]="rtwImgSrc"
                     (mouseover)="rtwImgSrc = landingImage.rtwROImgSrc"
                     (mouseout)="rtwImgSrc = landingImage.rtwImgSrc" />
              </a>
            </div>
            <ng-template #rtwNoAccess>
              <a class="searchoption-a-positive">
                <img class="searchoption-img" [src]="landingImage.rtwGreyImgSrc" />
              </a>
            </ng-template>
          </div>

          <!-- ID Check Search -->
          <div class="col col-lg-2">
            <div *ngIf="isIDCheckAccess; else idCheckAccess">
              <a class="searchoption-a-negative" routerLink="/v3/idcheck">
                <img class="searchoption-img" [src]="idCheckImgSrc"
                     (mouseover)="idCheckImgSrc = landingImage.idCheckROImgSrc"
                     (mouseout)="idCheckImgSrc = landingImage.idCheckImgSrc" />
              </a>
            </div>
            <ng-template #idCheckAccess>
              <a class="searchoption-a-negative">
                <img class="searchoption-img" [src]="landingImage.idCheckGreyImgSrc" />
              </a>
            </ng-template>
          </div>

        </div>
      </div>
    </div>
  </section>
  <section id="footer">
    <img class="footer" src="../../../../assets/Images/new/Background/HUT-elem-footer.png" />
  </section>
</div>

