import { Component, Input, OnInit } from '@angular/core';
import { VehicleIdResultModel } from '../../../../../models/VehicleIdResultModel';
import { MessagingService } from '../../../../../../services/messaging.service';

@Component({
  selector: 'app-au-risk-summary',
  templateUrl: './au-risk-summary.component.html',
  styleUrls: ['./au-risk-summary.component.css']
})
export class AuRiskSummaryComponent implements OnInit {

  @Input() vehicleId: VehicleIdResultModel = new VehicleIdResultModel();

  constructor(private messageService: MessagingService) { }

  ngOnInit(): void {
  }

  public getDateTimeFromString(date: string) {
    return Date.parse(date);
  }

  public checkObjectHasKeys(obj: any) {
    return Object.keys(obj).length > 0 ? 'True' : 'False';
  }

}
