  <div class="col-md-12" *ngIf="!accessData?.InsolvencyDetails"><i class="color-grey float-right">{{NoSearchSelect}}</i></div>
  <div class="col-md-12" *ngIf="accessData?.InsolvencyDetails && !insolvencyResultData?.errorMessage && (insolvencyResultData?.searchFail || insolvencyResultData?.CCJResult?.completeResponse?.Results?.Summary?.ResultText == 'FAIL')"><i class="color-grey float-right">{{SearchFail}}</i></div>
  <div class="col-md-12" *ngIf="accessData?.InsolvencyDetails && !insolvencyResultData?.searchFail && !insolvencyResultData?.errorMessage && (insolvencyResultData?.CCJResult?.insolvencyDetails == null || insolvencyResultData?.CCJResult?.insolvencyDetails.length == 0)"><i class="color-grey float-right">{{NoRelevantDataMessage}}</i></div>
  <div class="col-md-12" *ngIf="accessData?.InsolvencyDetails && !insolvencyResultData?.searchFail && insolvencyResultData?.errorMessage"><i class="color-grey float-right">{{OtherFieldRequired}}</i></div>
  
  <div *ngIf="accessData.InsolvencyDetails && insolvencyResultData && !(insolvencyResultData?.CCJResult?.insolvencyDetails == null || insolvencyResultData?.CCJResult?.insolvencyDetails?.length == 0)">
    <div class="row">
      <div class="row">
        <div class="col-md-6 border-bottom" *ngFor="let insolvency of insolvencyResultData?.CCJResult?.insolvencyDetails">
          <div class="shadow-sm bg-white m-2 p-4 h-100">
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade active show" id="aml" role="tabpanel" aria-labelledby="aml-tab">
                <div class="bg-white card addresses-item mb-4 border border-primary shadow">
                  <div class="gold-members pt-2 pl-2 pr-2">
                    <div class="media">
                      <div class="media-body">
                        <div class="border-bottom p-4">
                          <div>
                            <h5 *ngIf="insolvency?.name" class="mb-2">Name : {{insolvency?.name }}</h5>
                            <p *ngIf="insolvency?.dob" class="mb-1">DOB : {{insolvency?.dob | date: 'dd-MM-yyyy'}}</p>
                            <div *ngIf="insolvency?.address != null && (insolvency?.address?.address1 || insolvency?.address?.address2 || insolvency?.address?.address3 || insolvency?.address?.address4 || insolvency?.address?.address5 || insolvency?.address?.postcode || insolvency?.address?.dps)">
                              <h4>Address</h4>
                              <p *ngIf="insolvency?.address?.address1" class="mb-1 ml-1">Address 1 : {{insolvency?.address?.address1}}</p>
                              <p *ngIf="insolvency?.address?.address2" class="mb-1 ml-1">Address 2 : {{insolvency?.address?.address2}}</p>
                              <p *ngIf="insolvency?.address?.address3" class="mb-1 ml-1">Address 3 : {{insolvency?.address?.address3}}</p>
                              <p *ngIf="insolvency?.address?.address4" class="mb-1 ml-1">Address 4 : {{insolvency?.address?.address4}}</p>
                              <p *ngIf="insolvency?.address?.address5" class="mb-1 ml-1">Address 5 : {{insolvency?.address?.address5}}</p>
                              <p *ngIf="insolvency?.address?.postcode" class="mb-1 ml-1">Postcode : {{insolvency?.address?.postcode}}</p>
                              <p *ngIf="insolvency?.address?.dps" class="mb-1 ml-1">DPS : {{insolvency?.address?.dps}}</p>
                            </div>
                            <div *ngIf="insolvency?.previousAddress != null && (insolvency?.previousAddress?.address1 || insolvency?.previousAddress?.address2 || insolvency?.previousAddress?.address3 || insolvency?.previousAddress?.address4 || insolvency?.previousAddress?.address5 || insolvency?.previousAddress?.postcode || insolvency?.previousAddress?.dps)">
                              <h4>Previous Address</h4>
                              <p *ngIf="insolvency?.previousAddress?.address1" class="mb-1 ml-1">Address 1 : {{insolvency?.previousAddress?.address1}}</p>
                              <p *ngIf="insolvency?.previousAddress?.address2" class="mb-1 ml-1">Address 2 : {{insolvency?.previousAddress?.address2}}</p>
                              <p *ngIf="insolvency?.previousAddress?.address3" class="mb-1 ml-1">Address 3 : {{insolvency?.previousAddress?.address3}}</p>
                              <p *ngIf="insolvency?.previousAddress?.address4" class="mb-1 ml-1">Address 4 : {{insolvency?.previousAddress?.address4}}</p>
                              <p *ngIf="insolvency?.previousAddress?.address5" class="mb-1 ml-1">Address 5 : {{insolvency?.previousAddress?.address5}}</p>
                              <p *ngIf="insolvency?.previousAddress?.postcode" class="mb-1 ml-1">Postcode : {{insolvency?.previousAddress?.postcode}}</p>
                              <p *ngIf="insolvency?.previousAddress?.dps" class="mb-1 ml-1">DPS : {{insolvency?.previousAddress?.dps}}</p>
                            </div>
                            <p *ngIf="insolvency?.type" class="mb-1">Type : {{insolvency?.type}}</p>
                            <p *ngIf="insolvency?.caseNo" class="mb-1">Case No : {{insolvency?.caseNo}}</p>
                            <p *ngIf="insolvency?.caseType" class="mb-1">Case Type : {{insolvency?.caseType}}</p>
                            <p *ngIf="insolvency?.court" class="mb-1">Court Name : {{insolvency?.court}}</p>
                            <p *ngIf="insolvency?.startDate" class="mb-1">Start Date : {{insolvency?.startDate | date: 'dd-MM-yyyy'}}</p>
                            <p *ngIf="insolvency?.status" class="mb-1">Status : {{insolvency?.status}}</p>
                            <p *ngIf="insolvency?.occupation" class="mb-1">Occupation : {{insolvency?.occupation}}</p>
                            <p *ngIf="insolvency?.aliases" class="mb-1">Aliases : {{insolvency?.aliases}}</p>
                            <p *ngIf="insolvency?.description" class="mb-1">Description : {{insolvency?.description}}</p>
                            <p *ngIf="insolvency?.serviceOffice" class="mb-1">Service Office : {{insolvency?.serviceOffice}}</p>
                            <p *ngIf="insolvency?.telephoneNumber" class="mb-1">Telephone Number : {{insolvency?.telephoneNumber}}</p>
                            <p *ngIf="insolvency?.tradingNames" class="mb-1">Trading Names : {{insolvency?.tradingNames}}</p>
                            <p *ngIf="insolvency?.debtTotal" class="mb-1">Debt Total : {{insolvency?.debtTotal}}</p>
                            <p *ngIf="insolvency?.assetTotal" class="mb-1">Asset Total : {{insolvency?.assetTotal}}</p>
                            <p *ngIf="insolvency?.presentationDate" class="mb-1">Presentation Date : {{insolvency?.presentationDate | date: 'dd-MM-yyyy'}}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
