<app-search-top-nav></app-search-top-nav>
<section id="email-search" class="search">
  <div class="container">
    <div class="logo-div d-flex justify-content-center">
      <img class="logo" src="../../../../../assets/Images/new/HUT-icon.v3-email-RO.png" />
    </div>
    <form role="form" [formGroup]="emailForm">
      <div class="form-group row form-top-margin">
        <div class="col-md-8 offset-md-2 form-data-background form-borderdesign  shadow-lg p-3">
          <div class="row padding-form-design">
            <div class="col-md-12 z-index-formdata">
              <div class="row">
                <div class="input-group">
                  <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                    <b>Reference:</b>
                  </div>
                  <div class="col-md-8 pt-3">
                    <div class="form-group">
                      <input type="text" placeholder="" class="form-control" formControlName="reference" autocomplete="off" />
                      <p class="text-danger" *ngIf="emailForm.get('reference').errors?.required">
                        <strong><small>Reference is required</small></strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 z-index-formdata">
              <div class="row">
                <div class="input-group">
                  <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                    <b>Email:</b>
                  </div>
                  <div class="col-md-8 pt-3">
                    <div class="form-group">
                      <input type="text" placeholder="" class="form-control" formControlName="email" autocomplete="off" (input)="onEmailChange()" />
                      <p class="text-danger" *ngIf="emailForm.get('email').errors?.pattern">
                        <strong><small>Email is not valid.</small></strong>
                      </p>
                      <p class="text-danger" *ngIf="emailForm.get('email').errors?.required">
                        <strong><small>Email is required..</small></strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 padding-form-design pb-2">
              <div class="row">
                <div class="input-group">
                  <div class="col-md-12 offset-md-3">
                    <div class="form-group">
                      <button type="button" (click)="emailSearch()" class="btn colorRed mx-2">Search <i class="fa fa-search" aria-hidden="true"></i></button>
                      <button type="button" class="btn colorRed btn-pdf mx-2"
                              (click)="generatePDF()"
                              *ngIf="saveAsPDFButtonVisibility"
                              [disabled]="emailValidationResultModel?.isLoading || emailageResultModel?.isLoading || prospectResultModel?.isProspectHitLoading || prospectResultModel?.isProspectProfileLoading"
                              >Save as PDF</button>
                      <button type="button" class="btn colorRed reset-btn mx-2"
                              (click)="emailClearSearch()"
                              *ngIf="clearSearchButtonVisibility"
                              [disabled]="emailValidationResultModel?.isLoading || emailageResultModel?.isLoading || prospectResultModel?.isProspectHitLoading || prospectResultModel?.isProspectProfileLoading"
                              >Clear Search</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>

    <div class="container" *ngIf="showResultTab">
      <div class="mb-5">
        <div class="background-white result-display-padding form-borderdesign result-view-align" style="border: 1px solid rgba(0,0,0,.125);">
          <ul class="nav nav-tabs" id="searchResultTab" role="tablist">
            <li class="nav-item">
              <a class="nav-link active" id="email-search-tab" data-toggle="tab" href="#emailSearch" role="tab"
                 aria-controls="profile" aria-selected="false"><b>Email</b></a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="opensource-search-tab" data-toggle="tab" href="#opensourceSearch" role="tab"
                 aria-controls="profile" aria-selected="false"><b>Opensource</b></a>
            </li>
            <li class="nav-item" *ngIf="prospectResultModel.isProspectHitAccess && !regionAccess.isAURegion">
              <a class="nav-link" id="prospect-search-tab" data-toggle="tab" href="#prospectSearch" role="tab"
                 aria-controls="profile" aria-selected="false"><b>Prospect</b></a>
            </li>
          </ul>

          <div class="tab-content" id="searchResultTabContent">

            <!-- Email Validation & Emailage Result -->
            <div class="tab-pane fade show tab-height active" id="emailSearch" role="tabpanel" aria-labelledby="email-search-tab">
              <app-emailvalidation-result-email [emailValidation]="emailValidationResultModel"></app-emailvalidation-result-email>
              <br />
              <app-emailage-result-email [emailage]="emailageResultModel"></app-emailage-result-email>
            </div>

            <!-- Opensource Result -->
            <div class="tab-pane fade show tab-height" id="opensourceSearch" role="tabpanel" aria-labelledby="aml-search-tab">
              <div class="mt-4"></div>
              <app-opensource-result-mobile [opensource]="opensourceResult"></app-opensource-result-mobile>
            </div>

            <!-- Prospect Result -->
            <div class="tab-pane fade show tab-height" id="prospectSearch" role="tabpanel" aria-labelledby="prospect-search-tab">
              <app-prospect-result-personal [prospect]="prospectResultModel" (prospectProfileRequestEventEmitter)="prospectProfileRequest()"></app-prospect-result-personal>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pt-1"></div>
  </div>
</section>
<p-confirmDialog key="ProspectProfileRequest" [style]="{width: '50vw'}" [baseZIndex]="10000"></p-confirmDialog>

<!-- PDF -->
<div style="position: absolute; top: 0; right: 120%;">
  <kendo-pdf-export #pdf>
    <app-export-pdf-email [requestData]="requestDataModel"
                          [emailage]="emailageResultModel"
                          [emailValidation]="emailValidationResultModel"
                          [prospect]="prospectResultModel"
                          [emailageOrgId]="emailageOrgId"
                          [regionAccess]="regionAccess"
                          [opensource]="opensourceResult"
                           ></app-export-pdf-email>
  </kendo-pdf-export>
</div>
