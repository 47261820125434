import { Component, Input, OnInit } from '@angular/core';
import { MessagingService } from '../../../../../../services/messaging.service';
import { MobileResultModel } from '../../../../../models/MobileResultModel';
import { FinancialResultModel } from '../../../../../models/FinancialResultModel';
import { RequestData } from '../../../../../models/SearchRequestModel';

@Component({
  selector: 'app-mobile-new-result-personal',
  templateUrl: './mobile-new-result-personal.component.html',
  styleUrls: ['./mobile-new-result-personal.component.css', '../../../../shared/shared.searchpage.css']
})
export class MobileNewResultPersonalComponent implements OnInit {

  @Input() mobile: MobileResultModel = new MobileResultModel();
  @Input() financial: FinancialResultModel = new FinancialResultModel();
  @Input() requestData: RequestData = new RequestData();

  constructor(public messageService: MessagingService) { }

  ngOnInit(): void {
  }

}
