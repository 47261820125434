import { Component, Input, OnInit } from '@angular/core';
import { CounterFraudResponse } from '../../models/CounterFraudModel';
import { EmailCheckResponse } from '../../models/EmailCheckModel';
import { FinancialResponseModel } from '../../models/FinancialModel';
import { InputAccessData, InputData } from '../../models/InputDataModel';
import { MobileResponse } from '../../models/MobileResponseModel';

@Component({
  selector: 'app-result-top-card',
  templateUrl: './result-top-card.component.html',
  styleUrls: ['./result-top-card.component.css']
})
export class ResultTopCardComponent implements OnInit {

  @Input() public inputData: InputData = new InputData();
  @Input() public accessData: InputAccessData = new InputAccessData();
  @Input() public mobile: MobileResponse = new MobileResponse();
  @Input() public counterFraud: CounterFraudResponse = new CounterFraudResponse();
  @Input() public email: EmailCheckResponse = new EmailCheckResponse();
  @Input() public financial: FinancialResponseModel = new FinancialResponseModel();

  constructor() { }

  ngOnInit(): void {
  }

}
