<div class="subscription-wrapper my-4">
  <div class="container subscription-container">
    <div class="display-tenantName">
      <div *ngIf="displayTenantName">
        <h6>{{displayTenantName}}</h6>
      </div>
    </div>
    <div class="row">
      <!-- left side -->
      <div class="col-md-4 subscription-side-bar">
        <ul class="nav nav-pills mx-3 flex-column subscription-nav" id="pills-tab" role="tablist">
          <li class="nav-item">
            <a class="nav-link active d-flex align-items-center" id="pills-profile-tab" data-toggle="pill"
               href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="true">
              <i class="fa fa-id-card"></i>
              <div class="nav-item-container ml-3">
                <p class="m-0">Profile</p>
                <small class="text-muted">Manage your settings</small>
              </div>
            </a>
          </li>
          <li class="nav-item" *ngIf="isAdmin">
            <a class="nav-link d-flex align-items-center" id="pills-users-tab" data-toggle="pill" href="#pills-users"
               role="tab" aria-controls="pills-users" aria-selected="false" (click)="onUsersTabClick()">
              <i class="fa fa-users"></i>
              <div class="nav-item-container ml-3">
                <p class="m-0">Users</p>
                <small class="text-muted">Manage users</small>
              </div>
            </a>
          </li>
          <li class="nav-item" *ngIf="isAdmin">
            <a class="nav-link d-flex align-items-center" id="pills-searchTemplates-tab" data-toggle="pill"
               href="#pills-searchTemplates" role="tab" aria-controls="pills-searchTemplates" aria-selected="false" (click)="GetLicenceTemplateList()">
              <i class="fa fa-search"></i>
              <div class="nav-item-container ml-3">
                <p class="m-0">Search Templates</p>
                <small class="text-muted">Manage search templates</small>
              </div>
            </a>
          </li>
          <li class="nav-item"
              *ngIf="isAdmin" (click)="getAuditreport()">
            <a class="nav-link d-flex align-items-center"
               id="pills-reports-tab"
               data-toggle="pill"
               href="#pills-reports"
               role="tab"
               aria-controls="pills-reports"
               aria-selected="false">
              <i class="fa fa-flag"></i>
              <div class="nav-item-container ml-3">
                <p class="m-0">Reports</p>
                <small class="text-muted">Download reports</small>
              </div>
            </a>
          </li>
          <!-- Removed -->
          <li class="nav-item"
              *ngIf="false" (click)="getAllTenantEmailDomains()">
            <a class="nav-link d-flex align-items-center"
               id="pills-manage-emails-tab"
               data-toggle="pill"
               href="#pills-manage-emails"
               role="tab"
               aria-controls="pills-manage-emails"
               aria-selected="false">
              <i class="fa fa-globe"></i>
              <div class="nav-item-container ml-3">
                <p class="m-0">Domain Lists</p>
                <small class="text-muted">Manage Domain Lists</small>
              </div>
            </a>
          </li>
          <!-- Removed -->
          <li class="nav-item"
              *ngIf="false" (click)="GetTenantSettings()">
            <a class="nav-link d-flex align-items-center"
               id="pills-search-settings-tab"
               data-toggle="pill"
               href="#pills-search-settings"
               role="tab"
               aria-controls="pills-search-settings"
               aria-selected="false">
              <i class="fa fa-cogs"></i>
              <div class="nav-item-container ml-3">
                <p class="m-0">Search Settings</p>
                <small class="text-muted">Manage HeadsUp Search Settings</small>
              </div>
            </a>
          </li>
        </ul>
      </div>

      <!-- right side -->
      <div class="col-md-8">
        <div class="tab-content" id="pills-tabContent">
          <div class="tab-pane fade show active" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
            <div class="row">
              <div class="col-md-4">
                <h3 class="profile-heading">Profile</h3>
              </div>
              <div class="col-md-8" *ngIf="isAdmin && planExpiry">
                <div *ngIf="((planExpiry.subscriptionExpiry.days >= 0 && planExpiry.subscriptionExpiry.days <= 5) && planExpiry.subscriptionExpiry.hours >= 0 && planExpiry.subscriptionExpiry.minutes >= 0 &&  planExpiry.subscriptionExpiry.seconds > 0)">
                  <div class="alert alert-warning" role="alert">
                    <h6>Your subscription expire within {{planExpiry.subscriptionExpiry.days}} days.</h6>
                  </div>
                </div>
                <div *ngIf="planExpiry.subscriptionExpiry.days <= 0 && planExpiry.subscriptionExpiry.hours <= 0 && planExpiry.subscriptionExpiry.minutes <= 0 &&  planExpiry.subscriptionExpiry.seconds <= 0">
                  <div class="alert alert-danger" role="alert">
                    <h6>{{PlanExpiryMessage}}</h6>
                  </div>
                </div>
              </div>
            </div>


            <!-- profile form -->
            <form class="my-4 profile-form">
              <div class="form-group">
                <label for="displayName">Name</label>
                <input name="fullName" type="text" class="form-control my-2" id="displayName" placeholder="Toolagen"
                       [value]="user.firstName + '' + user.lastName | titlecase "
                       [(ngModel)]="user.firstName  + '  ' + user.lastName " [disabled]=true>
              </div>

              <div class="form-group">
                <label for="exampleInputEmail1">Email address</label>
                <input name="email" type="email" class="form-control profile-email-input my-2" id="exampleInputEmail1"
                       aria-describedby="emailHelp" [value]="user.email" [disabled]=true>
              </div>

              <div class="form-group">
                <div>
                  <button type="submit" class="btn btn-primary px-4 py-2"
                          (click)="openChangePasswordModal()">
                    Change Password
                  </button>
                </div>
              </div>
            </form>

            <!-- change password -->
            <div *ngIf="isChangePasswordModalOpen" class="pop-up-modal">
              <div class="pop-up-modal-header">
                <i class="fa fa-times" (click)="closeChangePasswordModal()"></i>
              </div>
              <div class="pop-up-modal-body py-4">
                <form>
                  <div class="row">
                    <div class="col-md-5">
                      <div class="form-group">
                        <label for="currentPassword">New Password</label>
                        <input type="password" class="form-control my-2" name="newPassword" placeholder="password"
                               [(ngModel)]="newPassword" (input)="changePasswordEvent()">
                      </div>
                    </div>
                    <div class="col-md-5">
                      <div class="form-group">
                        <label for="newPassword">Confirm Password</label>
                        <input type="password" class="form-control my-2" name="confirmPassword" placeholder="password"
                               [(ngModel)]="confirmPassword" (input)="changeConfirmPasswordEvent()">
                      </div>
                    </div>
                    <div class="col-md-2 d-flex align-items-end mb-3">
                      <button class="btn btn-primary px-4" (click)="changePassword()">Save</button>
                    </div>
                  </div>
                </form>
              </div>
              <div class="col-md-12">
                <p class="alert alert-danger" *ngIf="passwordValidationError">
                  <span><small>{{passwordValidationError}} <br /></small></span>
                </p>
              </div>

              <div class="col-md-12">
                <p class="alert alert-success" *ngIf="successMessage">
                  <span><small>{{successMessage}} <br /></small></span>
                </p>
              </div>

              <div class="col-md-12" *ngIf="DetailsError">
                <p class="alert alert-danger">
                  <span>
                    <small>
                      should contain minimum 8 characters<br />
                      <ul>
                        <li>Lowercase(a-z)</li>
                        <li>Uppercase(A-Z)</li>
                        <li>Number(0-9)</li>
                        <li>Special characters</li>
                        <li>White spaces not allowed</li>
                      </ul>
                    </small>
                  </span>
                </p>
              </div>

            </div>

          </div>
          <div class="tab-pane fade"
               id="pills-users"
               role="tabpanel"
               aria-labelledby="pills-users-tab">
            <h3 class="search-template-heading">Team Access</h3>
            <small *ngIf="Plan && Plan.userCount == 0" class="text-muted">Invite user limit is reached.</small>
            <small *ngIf="Plan && Plan.userCount>0" class="text-muted">You can add {{ Plan.userCount }} more team member(s).</small>
            <div class="team-wrapper d-flex align-items-center justify-content-between my-4">
              <button class="btn px-4 py-2 btn-primary" [hidden]="Plan && Plan.userCount==0" (click)="addNewMember()"><i class="fa fa-plus"></i>&nbsp; Add new member</button>
            </div>

            <!-- add user -->
            <div *ngIf="isAddUserModalOpen" class="pop-up-modal my-4">
              <div class="pop-up-modal-header">
                <p *ngIf="!updateMember">Add New Member</p>
                <p *ngIf="updateMember">Update Member</p>
                <i class="fa fa-times" (click)="closeAddUserMember()"></i>
              </div>
              <div class="pop-up-modal-body py-4">
                <form class="add-user-form">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="firstName">First Name</label>
                        <input type="text" class="form-control" name="firstName" placeholder="first name"
                               autocomplete="off" [(ngModel)]="register.firstName">
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="lastName">Last Name</label>
                        <input type="text" class="form-control" name="lastName" placeholder="last name" [(ngModel)]="register.lastName">
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="exampleInputEmail1">Email</label>
                        <input type="email" class="form-control" name="exampleInputEmail1" aria-describedby="emailHelp"
                               placeholder="email" autocomplete="off" [(ngModel)]="register.email" [disabled]="disableEmailField">
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="department">Department</label>
                        <input type="text" class="form-control" name="department" placeholder="department"
                               autocomplete="off" [(ngModel)]="register.department">
                      </div>
                    </div>
                    <div class="form-group col-md-6">
                      <label for="Licence Template">Template</label><br />
                      <p-dropdown name="licence" [options]="licenceList" [(ngModel)]="register.licenceId"
                                  optionLabel="label" optionValue="id" class="licence-drop-down"></p-dropdown>
                    </div>
                    <div class="col-md-6 align-self-end">
                      <div class="row col-md-12">
                        <div class="form-group col-md-6 form-check">
                          <input name="isActive" type="checkbox" class="form-check-input" id="isActiveCheck"
                                 [(ngModel)]="register.isActive">
                          <label class="form-check-label" for="isActiveCheck">isActive?</label>
                        </div>
                        <div class="form-group col-md-6 form-check">
                          <input name="isAdmin" type="checkbox" class="form-check-input" id="isAdminCheck"
                                 [(ngModel)]="register.isAdmin">
                          <label class="form-check-label" for="isAdminCheck">isAdmin?</label>
                        </div>
                        <div class="form-group col-md-6 form-check" *ngIf="!isShowAdduserbtn">
                          <input name="isDeleted" type="checkbox" class="form-check-input" id="isDeletedCheck"
                                 [(ngModel)]="register.isDeleted">
                          <label class="form-check-label" for="isDeletedCheck">isDeleted?</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="btn-wrapper mt-3" *ngIf="isShowAdduserbtn">
                    <button type="submit" class="btn btn-gray px-4 m-0"
                            (click)="isAddUserModalOpen = false;">
                      Cancel
                    </button>
                    <button type="submit" class="btn btn-primary px-4 m-0 ml-4" (click)="AddUser()">Add User</button>
                  </div>

                  <!--update password button-->
                  <div class="form-group mt-3" *ngIf="!isShowAdduserbtn">
                    <div class="row">
                      <div class="col-md-6">
                        <button type="submit" class="btn btn-primary px-4 py-2"
                                (click)="openUpdatePasswordModal()">
                          Set New Password
                        </button>
                      </div>
                      <div class="col-md-6 align-self-end">
                        <a class="py-2" (click)="resetPasswordLink(register.email)"><h6 class="hyperlink"><u>Send reset password link</u></h6></a>
                      </div>
                    </div>
                  </div>

                  <!-- update password model -->
                  <div *ngIf="isUpdatePasswordModalOpen" class="pop-up-modal">
                    <div class="pop-up-modal-header">
                      <i class="fa fa-times" (click)="closeUpdatePasswordModal()"></i>
                    </div>
                    <div class="pop-up-modal-body py-4">
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group px-0">
                              <label for="currentPassword">New Password</label>
                              <input type="password" class="form-control my-2" name="newUpdatePassword" placeholder="password"
                                     [(ngModel)]="newUpdatePassword" (input)="updatePasswordEvent()">
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label for="newPassword">Confirm Password</label>
                              <input type="password" class="form-control my-2" name="confirmUpdatePassword" placeholder="password"
                                     [(ngModel)]="confirmUpdatePassword" (input)="updateConfirmPasswordEvent()">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <p class="alert alert-danger" *ngIf="passwordValidationError">
                        <span><small>{{passwordValidationError}} <br /></small></span>
                      </p>
                    </div>

                    <div class="col-md-12">
                      <p class="alert alert-success" *ngIf="successMessage">
                        <span><small>{{successMessage}} <br /></small></span>
                      </p>
                    </div>

                    <div class="col-md-12" *ngIf="DetailsError">
                      <p class="alert alert-danger">
                        <span>
                          <small>
                            should contain minimum 8 characters<br />
                            <ul>
                              <li>Lowercase(a-z)</li>
                              <li>Uppercase(A-Z)</li>
                              <li>Number(0-9)</li>
                              <li>Special characters</li>
                              <li>White spaces not allowed</li>
                            </ul>
                          </small>
                        </span>
                      </p>
                    </div>

                  </div>
                  <div class="float-right my-2" *ngIf="!isShowAdduserbtn">
                    <button type="submit" class="btn btn-gray px-4 m-0"
                            (click)="closeSetPasswordModel();">
                      Cancel
                    </button>
                    <button class="btn btn-primary px-4 ml-4" (click)="updateUser();">Update</button>
                  </div>
                </form>

              </div>
              <div class="col-md-12 my-2">
                <p class="alert alert-danger" *ngIf="registrationError">
                  <span><small>{{registrationError}} <br /></small></span>
                </p>
              </div>
            </div>
            <div class="col-md-12 my-2">
              <p class="alert alert-success" *ngIf="registrationSuccess">
                <span><small> {{ registrationSuccess }} <br /></small></span>
              </p>
            </div>


            <p class="team-members">Team Members</p>
            <div class="d-flex justify-content-end">
              <span class="refresh-icon" (click)="LoadClients()">
                <i class="fa fa-refresh"
                   aria-hidden="true"></i>
              </span>
            </div>
            <div *ngIf="!clientrecordsfound">
              <p-table [value]="clients" dataKey="email" styleClass="p-datatable-users" [(selection)]="register" editMode="row" [rows]="50"
                       [showCurrentPageReport]="true" [paginator]="rows>=50?true:false"
                       currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">
                <ng-template pTemplate="header">
                  <tr>
                    <th>User Name</th>
                    <th>Template</th>
                    <th>Status</th>
                    <th>Email Verified</th>
                    <th class="align-heading"></th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-client let-editing="editing" let-ri="rowIndex">
                  <tr [pEditableRow]="client" [pSelectableRow]="client">
                    <td data-table-title="User Name">{{client.firstName +' ' + client.lastName | titlecase }}</td>
                    <td data-table-title="Licence Template">{{client.licenceName ? client.licenceName : "No Licence"}}</td>
                    <td data-table-title="Status">{{ client.isActive ? 'Active' : 'Inactive'}}</td>
                    <td class="text-lg-center text-md-right" data-table-title="Email Verified">
                      <div *ngIf="client.emailConfirmed"><i class="pi pi-check text-success"></i></div>
                      <div *ngIf="!client.emailConfirmed"><i class="pi pi-times text-danger"></i></div>
                    </td>
                    <td class="tr-edit py-3 align-table-data" data-table-title="Edit">
                      <button pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditInit(client)" class="p-button-rounded p-button-text"></button>
                      <i class="fa fa-repeat resend-confirm-email" aria-hidden="true" *ngIf="!client.emailConfirmed" (click)="reSendVerificationEmail(client.email)" pTooltip="Resend Verification Email"></i>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
            <div class="col-md-12">
              <p class="alert alert-danger" *ngIf="clientrecordsfound">
                <span><small>{{clientrecordsfound}} <br /></small></span>
              </p>
            </div>

            <div class="col-md-12">
              <p class="alert alert-danger" *ngIf="editError">
                <span><small>{{ editError }} <br /></small></span>
              </p>
            </div>

            <div class="col-md-12">
              <p class="alert alert-success" *ngIf="editSuccess">
                <span><small>{{ editSuccess }} <br /></small></span>
              </p>
            </div>

          </div>
          <div class="tab-pane fade" id="pills-searchTemplates" role="tabpanel"
               aria-labelledby="pills-searchTemplates-tab">
            <h3 class="search-template-heading">Template</h3>

            <div class="d-flex justify-content-end mt-2">
              <button class="btn px-4 py-2 mb-4 btn-primary" (click)="addNewLicence()">
                <i class="fa fa-plus"></i>&nbsp;
                Add
              </button>
            </div>

            <div *ngIf="isAddTemplateModelOpen" class="pop-up-modal mb-4">
              <div class="pop-up-modal-header">
                <i class="fa fa-times" (click)="selectCrossBtn()"></i>
              </div>
              <div class="pop-up-modal-body py-4">

                <div class="container">
                  <form class="add-licence-template" (ngSubmit)="createTemplate()" #templateForm="ngForm">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="Templatename">Name</label>
                          <input type="text" class="form-control" name="licenceName" placeholder="Name"
                                 autocomplete="off" [(ngModel)]="newLicence.licenceName">
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-floating">
                          <label for="floatingTextarea">Description</label>
                          <textarea class="form-control" name="description" placeholder="Description" id="floatingTextarea"
                                    autocomplete="off" [(ngModel)]="newLicence.description"></textarea>
                        </div>
                      </div>

                    </div>
                    <div class="row">
                      <div class="form-group col-6" *ngIf="editLicence">
                        <label for="Templatename">Status</label>
                        <p-dropdown name="status" [options]="statuses" [(ngModel)]="licencestatus" class="licence-drop-down"
                                    optionLabel="label" optionValue="value"></p-dropdown>
                      </div>
                    </div>
                  </form>
                  <ng-container *ngIf="Plan.allowedLicenseItems;else nullLicenceItems">
                    <div class="row" *ngIf="isAddTemplateModelOpen || editVisibility">
                      <div class="col-md-6">
                        <!-- toggle for Search -->
                        <div class="row my-4">
                          <p class="my-0 ml-3">Headsup Search</p>
                          <label class="switch ml-3">
                            <input type="checkbox" [(ngModel)]="searchToggle" [disabled]="searchToggleDisable">
                            <span class="slider round"></span>
                          </label>
                        </div>

                        <!-- Search-->
                        <p-table class="col-sm-12" *ngIf="searchToggle" [autoLayout]="true" [value]="tempLicenceSearch">
                          <ng-template pTemplate="header">
                            <tr>
                              <th>Risk Category</th>
                              <th>Enabled</th>
                              <th>Locked</th>
                            </tr>
                          </ng-template>
                          <ng-template pTemplate="body" let-item let-ri="rowIndex">
                            <tr [class]="item.disabled ? 'tr-disable': ''"
                                [pTooltip]="item.disabled?'Item not allowed':''">
                              <ng-container *ngIf="item.licenceType == 'Search'">
                                <td class="py-2" data-table-title="Risk Category">
                                  {{item.itemName}}
                                </td>
                                <td class="py-2" data-table-title="Enabled">
                                  <p-checkbox [(ngModel)]="item.defaultValue" binary="true" inputId="binary"
                                              [disabled]="item.disabled"></p-checkbox>
                                </td>
                                <td class="py-2" data-table-title="Locked">
                                  <p-checkbox [(ngModel)]="item.toggle" binary="true" inputId="binary"
                                              [disabled]="item.disabled"></p-checkbox>
                                </td>
                              </ng-container>
                            </tr>
                          </ng-template>
                        </p-table>
                      </div>

                      <div class="col-md-6">

                        <!-- Screen -->
                        <div>
                          <!-- toggle for Screen -->
                          <div class="row my-4">
                            <p class="my-0 ml-3">Headsup Screen</p>
                            <label class="switch ml-3">
                              <input type="checkbox" [(ngModel)]="screenToggle" [disabled]="screenToggleDisable">
                              <span class="slider round"></span>
                            </label>
                          </div>


                          <p-table class="col-sm-12" *ngIf="screenToggle"
                                   [autoLayout]="true" [value]="tempLicenceScreen">
                            <ng-template pTemplate="header">
                              <tr>
                                <th>Risk Category</th>
                                <th>Enabled</th>
                                <th>Locked</th>
                              </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-item>
                              <tr>
                                <ng-container *ngIf="item.licenceType == 'Screen'">
                                  <td class="py-2" data-table-title="Risk Category">
                                    {{item.itemName}}
                                  </td>
                                  <td class="py-2" data-table-title="Enabled">
                                    <p-checkbox [(ngModel)]="item.defaultValue" binary="true" inputId="binary"
                                                [disabled]="item.disabled"></p-checkbox>
                                  </td>
                                  <td class="py-2" data-table-title="Locked">
                                    <p-checkbox [(ngModel)]="item.toggle" binary="true" inputId="binary"
                                                [disabled]="true"></p-checkbox>
                                  </td>
                                </ng-container>
                              </tr>
                            </ng-template>
                          </p-table>
                        </div>

                        <!-- Search Availability -->
                        <div>
                          <!-- Toogle -->
                          <div class="row my-4">
                            <p class="my-0 ml-3">Search Availability</p>
                            <label class="switch ml-3">
                              <input type="checkbox" [(ngModel)]="searchAvailabilityToggle" [disabled]="searchAvailabilityToggleDisable">
                              <span class="slider round"></span>
                            </label>
                          </div>

                          <!-- Table -->
                          <p-table class="col-sm-12" *ngIf="searchAvailabilityToggle"
                                   [autoLayout]="true" [value]="tempLicenceSearchAvailability">
                            <ng-template pTemplate="header">
                              <tr>
                                <th>Risk  Category </th>
                                <th>Enabled</th>
                                <th>Locked</th>
                              </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-item>
                              <tr>
                                <ng-container *ngIf="item.licenceType == 'SearchAvailability'">
                                  <td class="py-2" data-table-title="Risk Category">
                                    {{item.itemName}}
                                  </td>
                                  <td class="py-2" data-table-title="Enabled">
                                    <p-checkbox [(ngModel)]="item.defaultValue" binary="true" inputId="binary"
                                                [disabled]="item.disabled"></p-checkbox>
                                  </td>
                                  <td class="py-2" data-table-title="Locked">
                                    <p-checkbox [(ngModel)]="item.toggle" binary="true" inputId="binary"
                                                [disabled]="true"></p-checkbox>
                                  </td>
                                </ng-container>
                              </tr>
                            </ng-template>
                          </p-table>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <ng-template #nullLicenceItems>
                    <p class="alert alert-warning my-2" role="alert">You do not have any allowed licence items to create template.</p>
                  </ng-template>

                  <div class="col-12" *ngIf="Plan.allowedLicenseItems">
                    <span class="alert alert-danger" *ngIf="licenceError"><small>{{ licenceError }}</small></span>
                    <button class="btn btn-primary mt-4 float-right" *ngIf="editVisibility" (click)="onSubmitTest()"
                            type="button">
                      Save
                    </button>
                  </div>

                </div>
              </div>
            </div>
            <div class="col-md-12">
              <p class="alert alert-success" *ngIf="licenceSuccess">
                <span><small>{{ licenceSuccess }} <br /></small></span>
              </p>
            </div>

            <!-- Table -->
            <p-table [value]="licenceTemplateList" dataKey="licenceId" styleClass="mb-4" editMode="row" [rows]="50"
                     [showCurrentPageReport]="true" [rowHover]="true" [paginator]="licenceTemplateList.length>50?true:false"
                     currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">
              <ng-template pTemplate="header">
                <tr>
                  <th>Name</th>
                  <th>Status</th>
                  <th class="align-heading"></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-list let-editing="editing" let-ri="rowIndex">
                <tr [pEditableRow]="list" (click)="getLicence(list)" class="table-row" *ngIf="!list.isDeleted">
                  <td class="py-2" data-table-title="Licence Name">{{list.licenceName}}</td>
                  <td class="py-2" data-table-title="Status">{{list.isActive === true ? 'Active' : 'Inactive'}}</td>

                  <td data-table-title="Edit" class="tr-edit py-1">
                    <button pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil"
                            (click)="onRowEditLicenceInit(list)" class="p-button-rounded p-button-text"></button>
                    <button pButton pRipple [label]="Delete" icon="pi pi-trash" class="p-button-rounded p-button-text p-button-danger" (click)="deleteSelectedTemplate(list)"></button>
                  </td>
                </tr>
              </ng-template>
            </p-table>
            <p-confirmDialog class="p-confirm-Dialog" [baseZIndex]="10000"></p-confirmDialog>
            <!-- add Template -->
            <div class="row mt-4"></div>
          </div>
          <div class="tab-pane fade"
               id="pills-reports"
               role="tabpanel"
               aria-labelledby="pills-reports-tab">

            <h3 class="search-template-heading">Reports</h3>
            <h5 class="search-template-heading my-4">List of searches</h5>
            <div class="row" *ngIf="reporterPermenant">
              <div class="col-md-12">
                <div class="table-header mt-3">
                  <span class="pr-3"><input type="radio" name="filter" value="3" [(ngModel)]="filterValue" (change)="filter()" /> Last 3 Months</span>
                  <span class="pr-3"><input type="radio" name="filter" value="6" [(ngModel)]="filterValue" (change)="filter()" /> Last 6 Months</span>
                  <span class="pr-3"><input type="radio" name="filter" value="12" [(ngModel)]="filterValue" (change)="filter()" /> Last 12 Months</span>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="reporterPermenant">
              <div class="col-md-8"></div>
              <div class="col-md-4">
                <div class="d-flex justify-content-end">
                  <button type="button" (click)="AuditReportDownload()" [disabled]="auditReportDisable || rowRecordNotFound" class="btn btn-primary float-right px-4"><i class="pi pi-download mr-2"></i>{{AuditReportDownloadText}}</button>
                </div>
                <div class="d-flex justify-content-end text-danger" *ngIf="rowRecordNotFound">No record found.</div>
              </div>
            </div>
            <p-table [value]="reporter" dataKey="Month" styleClass="mb-4 mt-1" [rows]="25"
                     [showCurrentPageReport]="true" [autoLayout]="true" [paginator]="reporter?.length >25 ?true :false"
                     currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">
              <ng-template pTemplate="header">
                <tr>
                  <th></th>
                  <th>Month</th>
                  <th>Search</th>
                  <th>Screen</th>
                  <th>API</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-report let-editing="editing" let-ri="rowIndex">
                <tr>
                  <td>
                    <input type="radio" name="downloadfilter" [value]="report" [(ngModel)]="selectedValue" (change)="monthWiseRadioChange()" />
                  </td>
                  <td data-table-title="Month">
                    {{report.startingDate | date:'MMM yyyy'}}
                  </td>
                  <td data-table-title="Search">
                    {{report.searchcount}}
                  </td>
                  <td data-table-title="Screen">
                    {{report.screencount}}
                  </td>
                  <td data-table-title="API">
                    {{report.api}}
                  </td>
                </tr>
              </ng-template>
            </p-table>

            <div *ngIf="!auditReportLoading && !reporterPermenant">
              <p class="alert alert-warning my-2 text-center" role="alert">No Record Found.</p>
            </div>
            <div *ngIf="auditReportLoading">
              <span class="text-center"> Loading...</span>
            </div>

            <div id="content" class="listofsearches" #content>
              <table class="table table-bordered">
                <thead class="thead-dark">
                  <tr>
                    <th>Reference</th>
                    <th>Search Date</th>
                    <th>User Name</th>
                    <th>Search Name</th>
                    <th>Search Type</th>
                    <th>VRN</th>
                    <th>Vechile Check</th>
                    <th>Is Valid Profile</th>
                  </tr>
                </thead>
                <tbody *ngIf="selectedValue">
                  <tr *ngFor="let audit of audits">
                    <td>{{audit.reference}}</td>
                    <td>{{audit.searchDate}}</td>
                    <td>{{audit.userName}}</td>
                    <td>{{audit.searchName}}</td>
                    <td>{{audit.searchType}}</td>
                    <td>{{audit.vrn}}</td>
                    <td *ngIf="audit.vrn">{{audit.vechileCheck}}</td>
                    <td *ngIf="audit.searchType == 'HeadsupAPI' && audit.apimi">{{audit.apimi.isValidProfile}}</td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>


          <div class="tab-pane fade"
               id="pills-manage-emails"
               role="tabpanel"
               aria-labelledby="pills-manage-emails-tab">
            <p class="team-members">Domain list</p>
            <small class="text-muted">Manage your email screening ignore list below. These domains will be ignored by HeadsUp while screening data</small>
            <div class="team-wrapper d-flex align-items-center justify-content-end my-2">
              <button class="btn px-4 py-2 btn-primary" [hidden]="Plan && Plan.userCount==0" (click)="addNewDomain()"><i class="fa fa-plus"></i>&nbsp; Add new Domain</button>
            </div>

            <!-- add domain -->
            <div *ngIf="isAddDomainModelOpen" class="pop-up-modal my-4">
              <div class="pop-up-modal-header">
                <p *ngIf="!updateDomain">Add New Domain</p>
                <p *ngIf="updateDomain">Update Domain</p>
                <i class="fa fa-times" (click)="closeAddDomain()"></i>
              </div>
              <div class="pop-up-modal-body py-4">
                <form class="add-user-form">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="domainName">Domain Name</label>
                        <input type="text" class="form-control" name="domainName" placeholder="domain name"
                               autocomplete="off" [(ngModel)]="registerDomain.domainName">
                      </div>
                    </div>
                    <div class="col-md-6 align-self-center">
                      <div class="form-group col-md-6 pt-5 form-check">
                        <input name="isActive" type="checkbox" class="form-check-input" id="isActiveCheck"
                               [(ngModel)]="registerDomain.isActive">
                        <label class="form-check-label" for="isActiveCheck">isActive?</label>
                      </div>
                    </div>
                  </div>
                  <div class="btn-wrapper mt-3" *ngIf="isShowAddDomainbtn">
                    <button type="submit" class="btn btn-gray px-4 m-0"
                            (click)="closeAddModel()">
                      Cancel
                    </button>
                    <button type="submit" class="btn btn-primary px-4 m-0 ml-4" (click)="AddDomain()">Add Domain</button>
                  </div>
                  <div class="float-right my-2" *ngIf="!isShowAddDomainbtn">
                    <button type="submit" class="btn btn-gray px-4 m-0"
                            (click)="closeUpdateModel()">
                      Cancel
                    </button>
                    <button class="btn btn-primary px-4 ml-4" (click)="UpdateDomain();">Update</button>
                  </div>
                </form>
              </div>
            </div>

            <div class="col-md-12 my-2">
              <p class="alert alert-danger" *ngIf="registrationDomainError">
                <span><small>{{registrationDomainError}} <br /></small></span>
              </p>
            </div>

            <div class="col-md-12 my-2">
              <p class="alert alert-success" *ngIf="registrationDomainSuccess">
                <span><small> {{ registrationDomainSuccess }} <br /></small></span>
              </p>
            </div>


            <div class="d-flex justify-content-end">
              <span class="refresh-icon" (click)="getAllTenantEmailDomains()">
                <i class="fa fa-refresh"
                   aria-hidden="true"></i>
              </span>
            </div>
            <div>

              <p-table [value]="Domains" dataKey="domainName" styleClass="p-datatable-domains" [(selection)]="registerDomain" editMode="row" [rows]="10"
                       [showCurrentPageReport]="true" [paginator]="Domains?.length >=10?true:false" [autoLayout]="true"
                       currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">
                <ng-template pTemplate="header">
                  <tr>
                    <th>Domain Name</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-domain let-editing="editing" let-ri="rowIndex">
                  <tr [pEditableRow]="domain" [pSelectableRow]="domain">
                    <td data-table-title="Domain Name">
                      {{domain.domainName}}
                    </td>
                    <td data-table-title="Status">
                      {{ domain.isActive ? 'Active' : 'Inactive'}}
                    </td>
                    <td class="tr-edit py-3 align-table-data" data-table-title="Edit">
                      <button pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditDomainInit(domain)" class="p-button-rounded p-button-text"></button>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
            <div class="col-md-12">
              <p class="alert alert-danger" *ngIf="domainrecordsfound">
                <span><small>{{domainrecordsfound}} <br /></small></span>
              </p>
            </div>
          </div>

          <div class="tab-pane fade"
               id="pills-search-settings"
               role="tabpanel"
               aria-labelledby="pills-search-settings-tab">
            <p class="team-members">Search Settings</p>

            <small class="text-muted">Manage your search settings.</small>

            <div class="mt-3">
              <p class="alert alert-success" *ngIf="userSettingsSuccessMessage">
                <span><small>{{userSettingsSuccessMessage}} <br /></small></span>
              </p>
            </div>

            <div class="mt-4" *ngIf="false">
              <label for="criminality-search-rank" class="mr-3">Criminality search aggressiveness: </label>
              <div class="row">
                <div class="col-md-6 range-slider">
                  <ngx-slider [(value)]="criminalitySearchOptionSelect" [options]="options" (mouseup)="criminalityrankchange()"></ngx-slider>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                  <span class="ml-3">{{criminalitySearchOptionSelectDescription}}</span>
                </div>
              </div>
            </div>
            <div class="mt-4">
              <label for="criminality-result-count">Max Criminality Results </label>
              <div>
                <p-inputNumber [(ngModel)]="criminalitySearchResultCount" mode="decimal" [showButtons]="true" inputId="crimesearchcount" [min]="1"></p-inputNumber>
              </div>
            </div>
            <div class="mt-3">
              <button class="btn btn-primary px-4" (click)="SaveTenantSettings()">Save</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <p-toast position="bottom-right" key="ConfirmEmail"></p-toast>
  <p-toast position="bottom-right" key="PlanExpiry"></p-toast>
</div>
