import { Component, ElementRef, OnInit } from '@angular/core';
import { SearchService } from '../../home/services/search.service';

@Component({
  selector: 'app-demo',
  templateUrl: './demo.component.html',
  styleUrls: ['./demo.component.css']
})
export class DemoComponent implements OnInit {

  public companyName: string;
  public croNumber: string;
  public mobileNumber: string;
  public search4LessResult: any;
  public phronesisResult: any;

  constructor(private elementRef: ElementRef, private searchservice: SearchService) { }

  ngAfterViewInit() {
    this.elementRef.nativeElement.ownerDocument.body.style.background = 'url(/assets/Images/Background.svg)';
  }

  ngOnInit(): void {
  }

  public SearchDataDemo() {
    if (this.croNumber && this.companyName) {
      this.search4LessResult = null;
      let companyNameTemp = encodeURIComponent(this.companyName);
      this.searchservice.GetSearch4Less(this.croNumber, companyNameTemp).subscribe(
        data => {
          this.search4LessResult = JSON.parse(data.data);
        }
      );
    }
    if (this.mobileNumber) {
      this.phronesisResult = null;
      this.searchservice.GetPhronesisBiometric(this.mobileNumber).subscribe(
        data => {
          this.phronesisResult = JSON.parse(data.data);
        }
      );
    }
  }

  public ClearButtonClick() {
    this.companyName = "";
    this.croNumber = "";
    this.mobileNumber = "";
    this.phronesisResult = "";
    this.search4LessResult = "";
  }

}
