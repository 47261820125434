import { Component, Input, OnInit } from '@angular/core';
import { RequestData } from '../../../../models/SearchRequestModel';
import { BusinessOpenSourceResultModel, BusinessResultModel } from '../../../../models/BusinessResultModel';
import { CounterFraudResultModel, ProspectResultModel } from '../../../../models/CounterFraudResultModel';
import { CompaniesHouseResultModel } from '../../../../models/CompaniesHouseResultModel';
import { EmailCheckResultModel, EmailageCheckResultModel } from '../../../../models/EmailResultModel';
import { MobileResultModel } from '../../../../models/MobileResultModel';
import { VATResultModel } from '../../../../models/VATResultModel';
import { AddressResultModel } from '../../../../models/AddressResultModel';
import { MessagingService } from '../../../../../services/messaging.service';
import { LandRegistryResultModel } from '../../../../models/LandRegistryResultModel';

@Component({
  selector: 'app-export-pdf-business',
  templateUrl: './export-pdf-business.component.html',
  styleUrls: ['./export-pdf-business.component.css', '../../../shared/shared.searchpage.css']
})
export class ExportPdfBusinessComponent implements OnInit {

  @Input() public requestData: RequestData = new RequestData();
  @Input() public business: BusinessResultModel = new BusinessResultModel();
  @Input() public prospect: ProspectResultModel = new ProspectResultModel();
  @Input() public companiesHouse: CompaniesHouseResultModel = new CompaniesHouseResultModel();
  @Input() public emailage: EmailageCheckResultModel = new EmailageCheckResultModel();
  @Input() public mobile: MobileResultModel = new MobileResultModel();
  @Input() public opensource: BusinessOpenSourceResultModel = new BusinessOpenSourceResultModel();
  @Input() public vatResult: VATResultModel = new VATResultModel();
  @Input() public emailValidation: EmailCheckResultModel = new EmailCheckResultModel();
  @Input() public address: AddressResultModel = new AddressResultModel();
  @Input() public counterFraud: CounterFraudResultModel = new CounterFraudResultModel();
  @Input() public landRegistry: LandRegistryResultModel = new LandRegistryResultModel();
  
  constructor(private messagingService: MessagingService) { }

  ngOnInit(): void {
  }

}
