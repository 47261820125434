<div>

  <div class="row padding-form-design Header-Font-Format pt-4">
    <div class="form-group col-md-6">
      <h2 class="color-white">You Tell Us</h2>
    </div>
  </div>
  <div class="row padding-form-design">
    <div class="col-md-8 z-index-formdata">
      <div class="row">
        <div class="input-group">
          <div class="col-md-3">
            <b>Reference:</b>
          </div>
          <div class="col-md-9">
            <div class="form-group">
              <input type="text" placeholder="" class="form-control" [(ngModel)]="inputData.referenceNumber" name="reference" autocomplete="off" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row padding-form-design">
    <div class="col-md-8 z-index-formdata">
      <div class="row">
        <div class="input-group">
          <div class="col-md-3">
            <b>Name:</b>
          </div>
          <div class="col-md-9">
            <div class="form-group">
              <input type="text" class="form-control btn-default" [(ngModel)]="inputData.searchName" name="searchtext" placeholder="" autocomplete="off" />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="input-group">
          <div class="col-md-3">
            <b>Post Code:</b>
          </div>
          <div class="col-md-9">
            <div class="form-group">
              <input type="text" placeholder="" class="form-control" [(ngModel)]="inputData.postCode" name="postalCode" autocomplete="off" />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="input-group">
          <div class="col-md-3">
            <b>Mobile:</b>
          </div>
          <div class="col-md-9">
            <div class="form-group">
              <input type="text" class="form-control" [(ngModel)]="inputData.phoneNumber" name="phone" autocomplete="off" placeholder="" />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="input-group">
          <div class="col-md-3">
            <b>Email:</b>
          </div>
          <div class="col-md-9">
            <div class="form-group">
              <input type="email" placeholder="" class="form-control" [(ngModel)]="inputData.email" name="email" autocomplete="off" />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="input-group">
          <div class="col-md-3">
            <b>VRN:</b>
          </div>
          <div class="col-md-9">
            <div class="form-group">
              <input type="text" placeholder="" class="form-control" [(ngModel)]="inputData.vrn" name="vRN" autocomplete="off" />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="input-group">
          <div class="col-md-3">
            <b>DOB:</b>
          </div>
          <div class="col-md-9">
            <div class="form-group">
              <input type="text" placeholder="" class="form-control datepicker" [(ngModel)]="inputData.dateOfBirth" name="dateOfBirth" autocomplete="off" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <img class="light-direction-align" src="assets/Images/icons/Lightdirection.svg" />
    </div>
  </div>
  <div class="row padding-form-design addition-data-alignment">
    <div class="col-md-2"></div>
    <div class="col-md-10">
      <div class="row">
        <div id="accordion1" class="accordion col-md-12">
          <div class="form-group">
            <div class="card mb-12">
              <div class="card-header" data-toggle="collapse" id="headingOne1" style="text-align: right" aria-expanded="true">
                <span style="float:left;">Additional Information</span>
                <span style="float:right;"><i class="fa fa-chevron-down" aria-hidden="true"></i></span>
              </div>
              <div class="card-body border-bottom collapse show" aria-labelledby="headingOne">
                <div class="row">
                  <div class="input-group">
                    <div class="col-md-3">
                      <span class="additional-information">House No/Name:</span>
                    </div>
                    <div class="col-md-9">
                      <div class="form-group">
                        <input type="text" class="form-control" [(ngModel)]="inputData.addressLine1" name="addressLine1" autocomplete="off" placeholder="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="input-group">
                    <div class="col-md-3">
                      <span class="additional-information">Street:</span>
                    </div>
                    <div class="col-md-9">
                      <div class="form-group">
                        <input type="text" class="form-control" [(ngModel)]="inputData.addressLine2" name="addressLine1" autocomplete="off" placeholder="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="input-group">
                    <div class="col-md-3">
                      <span class="additional-information">City:</span>
                    </div>
                    <div class="col-md-9">
                      <div class="form-group">
                        <input type="text" class="form-control" [(ngModel)]="inputData.city" name="city" autocomplete="off" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="input-group">
                    <div class="col-md-3">
                      <span class="additional-information">County:</span>
                    </div>
                    <div class="col-md-9">
                      <div class="form-group">
                        <input type="text" class="form-control" [(ngModel)]="inputData.state" name="state" autocomplete="off" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="input-group">
                    <div class="col-md-3">
                      <span class="additional-information">Country:</span>
                    </div>
                    <div class="col-md-9">
                      <div class="form-group">
                        <input type="text" class="form-control" [(ngModel)]="inputData.country" name="country" autocomplete="off" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="input-group">
                    <div class="col-md-3">
                      <span class="additional-information">Company:</span>
                    </div>
                    <div class="col-md-9">
                      <div class="form-group">
                        <input type="text" placeholder="" class="form-control" [(ngModel)]="inputData.companyName" name="companyName" autocomplete="off" #company="ngModel" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="input-group">
                    <div class="col-md-12">
                      <span class="additional-information">Associated Entities:</span>
                    </div>
                    <div class="col-md-3"></div>
                    <div class="col-md-9">
                      <div class="form-group">
                        <div class="row">
                          <div class="col-md-6" *ngFor="let user of inputData.associatedName; let i = index">
                            <div class="form-group">
                              <input [(ngModel)]="user.user" class="form-control" type="text" placeholder="" name="associatedEntries" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
