<div class="container">
  <span>HeadsUp Username: {{requestData.profileData.searchUsername ? requestData.profileData.searchUsername : '-'}}</span>
  <br />
  <span>Search Date &amp; Time: {{requestData.profileData.searchDate}} - {{requestData.profileData.searchTime}}</span>
</div>
<div>
  <app-pdf-search-form-motor [requestData]="requestData"></app-pdf-search-form-motor>
</div>
<br />
<br />
<div class="container">

  <!-- Experian Result -->
  <div *ngIf="isExperianTabShow && experian?.responseData" class="page-break"></div>
  <div *ngIf="isExperianTabShow" class="keep-together">
    <h3><b><u>HUT Motor</u></b></h3>
    <app-pdf-hutmotor-result-motor [experian]="experian"></app-pdf-hutmotor-result-motor>
  </div>

  <!-- DVLA Result -->
  <div class="page-break"></div>
  <div class="keep-together">
    <h3><b><u>DVLA</u></b></h3>
    <app-motor-result-motor [motor]="dvla"></app-motor-result-motor>
  </div>

  <!-- AVA Result -->
  <div *ngIf="ava.responseData" class="page-break"></div>
  <div class="keep-together">
    <h3><b><u>AVA</u></b></h3>
    <app-pdf-ava-result-motor [ava]="ava"></app-pdf-ava-result-motor>
  </div>

  <!-- Prospect Result -->
  <div *ngIf="prospect.isProspectHitAccess && !prospect.searchFail && !prospect.validation && prospect.prospectHitResponseData && !prospect.isProspectHitLoading && !(prospect.prospectHitResponseData?.hits == null || prospect.prospectHitResponseData?.hits.length == 0)" class="page-break"></div>
  <div *ngIf="prospect.isProspectHitAccess" class="keep-together">
    <h3><b><u>Prospect</u></b></h3>
    <app-prospect-result-personal [prospect]="prospect"></app-prospect-result-personal>
  </div>

  <!-- Mot History Result -->
  <div *ngIf="motHistory?.responseData" class="page-break"></div>
  <div class="keep-together">
    <h3><b><u>MOT History</u></b></h3>
    <app-pdf-mothistory-result-motor [motHistory]="motHistory"></app-pdf-mothistory-result-motor>
  </div>

</div>
