<div class="container">
  <span>HeadsUp Username: {{requestData.profileData.searchUsername ? requestData.profileData.searchUsername : '-'}}</span>
  <br />
  <span>Search Date &amp; Time: {{requestData.profileData.searchDate}} - {{requestData.profileData.searchTime}}</span>
</div>
<div class="container">
  <div class="logo-div d-flex justify-content-center">
    <img class="logo" src="../../../../../assets/Images/new/HUT-icon.v3-doc-validation-RO.png" />
  </div>
  <form role="form">
    <div class="form-group row form-top-margin">
      <div class="col-md-8 offset-md-2 form-data-background form-borderdesign  shadow-lg p-3">
        <div class="row padding-form-design">
          <div class="col-md-12 z-index-formdata">
            <div class="row">
              <div class="input-group">
                <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                  <b>Reference:</b>
                </div>
                <div class="col-md-8 pt-3">
                  <div class="form-group">
                    <input type="text" placeholder="" class="form-control" value="{{requestData.profileData.referenceNumber}}" autocomplete="off" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="row">
              <div class="offset-md-3 col-md-8">
                <div class="browse-File select-file">
                  <input type="file" class="custom-file-input" id="customFile" accept="image/*" />
                  <label class="custom-file-label" for="customFile">
                    <span *ngIf="FileNames.length == 0 || FileNames.length > 2">Choose File</span>
                    <span *ngIf="FileNames.length == 1">{{FileNames[0]}}</span>
                    <span *ngIf="FileNames.length == 2">{{FileNames[0]}}, {{FileNames[1]}}</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div class="container row mb-5">
    <div class="col-md-8 offset-md-2 mb-5">
      <div class="background-white result-display-padding form-borderdesign result-view-align bg-light px-4 py-2" style="border: 1px solid rgba(0,0,0,.125);">
        <div class="row">
          <div class="col-md-6">
            <div><span class="h5">Result: </span></div>
            <div *ngIf="USIdCheckUploadResult?.status == 200">
              <p *ngIf="USIdCheckUploadResult?.data">Document uploaded <i class="fa fa-check-circle color-green"></i></p>
              <p *ngIf="!USIdCheckUploadResult?.data">Document uploaded <i class="fa fa-ban color-red"></i></p>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="USIdCheckUploadResult?.status == 200">
          <div class="col-md-6">
            <div *ngIf="fileOneUrl">
              <div>{{File[0].name}}</div>
              <img class="document-image" [src]="fileOneUrl" />
            </div>
          </div>
          <div class="col-md-6">
            <div *ngIf="fileTwoUrl">
              <div>{{File[1].name}}</div>
              <img class="document-image" [src]="fileTwoUrl" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
