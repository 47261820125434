import { Component, Input, OnInit } from '@angular/core';
import { MotHistoryResultModel } from '../../../../../../models/DVLAResultModel';

@Component({
  selector: 'app-pdf-mothistory-result-motor',
  templateUrl: './pdf-mothistory-result-motor.component.html',
  styleUrls: ['./pdf-mothistory-result-motor.component.css']
})
export class PdfMothistoryResultMotorComponent implements OnInit {

  @Input() public motHistory: MotHistoryResultModel = new MotHistoryResultModel();

  constructor() { }

  ngOnInit(): void {
  }

}
