<div class="container mb-5">
  <div class="mb-5">
    <span class="loadingImg" *ngIf="motHistory.isLoading">
      <img src="{{messageService.loadingAnimation}}" />
    </span>
    <div>
      <table class="table table-striped table-hover">
        <tbody>
          <tr>
            <th scope="row">Registration</th>
            <td>{{motHistory?.responseData?.registration}}</td>
          </tr>
          <tr>
            <th scope="row">Make</th>
            <td>{{motHistory?.responseData?.make}}</td>
          </tr>
          <tr>
            <th scope="row">Model</th>
            <td>{{motHistory?.responseData?.model}}</td>
          </tr>
          <tr>
            <th scope="row">First Used</th>
            <td>{{motHistory?.responseData?.firstUsedDate}}</td>
          </tr>
          <tr>
            <th scope="row">Fuel</th>
            <td>{{motHistory?.responseData?.fuelType}}</td>
          </tr>
          <tr>
            <th scope="row">Colour</th>
            <td>{{motHistory?.responseData?.primaryColour}}</td>
          </tr>
        </tbody>
      </table>

      <p-table [value]="motHistory?.responseData?.motTests" dataKey="motTestNumber">
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 3rem"></th>
            <th pSortableColumn="completedDate">Completed <p-sortIcon field="completedDate"></p-sortIcon></th>
            <th pSortableColumn="testResult">Result <p-sortIcon field="testResult"></p-sortIcon></th>
            <th pSortableColumn="expiryDate">Expires <p-sortIcon field="expiryDate"></p-sortIcon></th>
            <th pSortableColumn="odometerValue">Odometer Value <p-sortIcon field="odometerValue"></p-sortIcon></th>
            <th pSortableColumn="odometerUnit">Odometer Unit <p-sortIcon field="odometerUnit"></p-sortIcon></th>
            <th pSortableColumn="motTestNumber">Test Number <p-sortIcon field="motTestNumber"></p-sortIcon></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-mot let-expanded="expanded">
          <tr>
            <td>
              <button type="button" pButton pRipple [pRowToggler]="mot" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
            </td>
            <td>{{mot?.completedDate}}</td>
            <td>{{mot?.testResult}}</td>
            <td>{{mot?.expiryDate}}</td>
            <td>{{mot?.odometerValue}}</td>
            <td>{{mot?.odometerUnit}}</td>
            <td>{{mot?.motTestNumber}}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-mot>
          <tr>
            <td colspan="7">
              <h5>Comments</h5>
              <div *ngIf="!mot?.rfrAndComments || mot?.rfrAndComments?.length == 0">No record found.</div>
              <table class="table table-striped table-hover" *ngIf="mot?.rfrAndComments && mot?.rfrAndComments?.length > 0">
                <thead>
                  <tr>
                    <th>Text</th>
                    <th>Type</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let comment of mot?.rfrAndComments">
                    <td>{{comment?.text}}</td>
                    <td>{{comment?.type}}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
