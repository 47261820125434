<app-search-top-nav></app-search-top-nav>
<section id="email-search" class="search">
  <div class="container">
    <div class="logo-div d-flex justify-content-center">
      <img class="logo" src="../../../../../assets/Images/new/HUT-icon.v3-id-check-RO.png" />
    </div>
    <form role="form" [formGroup]="idCheckForm">
      <div class="form-group row form-top-margin">
        <div class="col-md-8 offset-md-2 form-data-background form-borderdesign  shadow-lg p-3">
          <div class="row padding-form-design">
            <div class="col-md-12 z-index-formdata">
              <div class="row">
                <div class="input-group">
                  <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                    <b>Reference:</b>
                  </div>
                  <div class="col-md-8 pt-3">
                    <div class="form-group">
                      <input type="text" placeholder="" class="form-control" formControlName="reference" autocomplete="off" />
                      <p class="text-danger" *ngIf="idCheckForm.get('reference').errors?.required">
                        <strong><small>Reference is required</small></strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 z-index-formdata">
              <div class="row">
                <div class="input-group">
                  <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                    <b>Subject Name:</b>
                  </div>
                  <div class="col-md-8 pt-3">
                    <div class="form-group">
                      <input type="text" placeholder="" class="form-control" formControlName="name" autocomplete="off" />
                      <p class="text-danger" *ngIf="idCheckForm.get('name').errors?.required">
                        <strong><small>Name is required</small></strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 z-index-formdata">
              <div class="row">
                <div class="input-group">
                  <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                    <b>Subject Email:</b>
                  </div>
                  <div class="col-md-8 pt-3">
                    <div class="form-group">
                      <input type="text" placeholder="" class="form-control" formControlName="email" autocomplete="off" />
                      <p class="text-danger" *ngIf="idCheckForm.get('email').errors?.pattern">
                        <strong><small>Email is not valid.</small></strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 padding-form-design pb-2">
              <div class="row">
                <div class="input-group">
                  <div class="col-md-12 offset-md-3">
                    <div class="form-group">
                      <button type="submit" class="btn colorRed mx-2" (click)="idCheckSearch()">Send Link <i class="fa fa-send" aria-hidden="true"></i></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>

    <div class="container row mb-5" *ngIf="showResultTab">
      <div class="col-md-8 offset-md-2 mb-5">
        <div class="background-white result-display-padding form-borderdesign result-view-align bg-light px-4 py-2" style="border: 1px solid rgba(0,0,0,.125);">
          <div class="row">
            <div class="col-md-6">
              <div><span class="h5">Result: </span></div>
              <div *ngIf="idCheckResultModel.isLoading">
                <img src="{{messageService.loadingAnimation}}" />
              </div>
              <div *ngIf="!idCheckResultModel.isLoading">
                <div *ngIf="idCheckResultModel?.responseData && idCheckResultModel?.responseData?.status == 200">
                  <p *ngIf="idCheckResultModel?.responseData?.data"> Mail sent successfully <i class="fa fa-check-circle color-green"></i></p>
                  <p *ngIf="!idCheckResultModel?.responseData?.data"> Mail sent failed <i class="fa fa-ban color-red"></i></p>
                </div>
                <div *ngIf="!idCheckResultModel?.responseData">
                  <p *ngIf="!idCheckResultModel?.responseData?.data"> Mail sent failed <i class="fa fa-ban color-red"></i></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</section>
