<div class="row mb-3" id="contact-details">
  <div class="col-md-5 card">
    <app-mobile-enquiry [accessData]="accessData" [inputData]="inputData" [mobile]="mobile"></app-mobile-enquiry>
  </div>
  <div class="col-md-3 card">
    <app-counter-fraud [accessData]="accessData" [inputData]="inputData" [counterFraud]="counterFraud"></app-counter-fraud>
  </div>
  <div class="col-md-2 card">
    <app-email-check [accessData]="accessData" [inputData]="inputData" [email]="email"></app-email-check>
  </div>
  <div class="col-md-2 card">
    <app-financial-check [accessData]="accessData" [inputData]="inputData" [financial]="financial"></app-financial-check>
  </div>
</div>
