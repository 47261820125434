  <div class="col-md-12" *ngIf="!accessData?.CCJDetails"><i class="color-grey float-right">{{NoSearchSelect}}</i></div>
  <div class="col-md-12" *ngIf="accessData?.CCJDetails && !ccjResultData?.errorMessage && (ccjResultData?.searchFail || ccjResultData?.CCJResult?.Results?.Summary?.ResultText == 'FAIL')"><i class="color-grey float-right">{{SearchFail}}</i></div>
  <div class="col-md-12" *ngIf="accessData?.CCJDetails && !ccjResultData?.searchFail && !ccjResultData?.errorMessage && (ccjResultData?.CCJResult?.ccjDetails == null || ccjResultData?.CCJResult?.ccjDetails.length == 0)"><i class="color-grey float-right">{{NoRelevantDataMessage}}</i></div>
  <div class="col-md-12" *ngIf="accessData?.CCJDetails && !ccjResultData?.searchFail && ccjResultData?.errorMessage"><i class="color-grey float-right">{{OtherFieldRequired}}</i></div>
 
  <div *ngIf="accessData.CCJDetails && ccjResultData?.CCJResult && !(ccjResultData?.CCJResult?.ccjDetails == null || ccjResultData?.CCJResult?.ccjDetails.length == 0)">
    <div class="row">
      <div class="col-md-6 border-bottom" *ngFor="let ccj of ccjResultData?.CCJResult?.ccjDetails">
        <div class="shadow-sm bg-white p-4 h-100">
          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade active show" id="ccjdetail" role="tabpanel" aria-labelledby="ccjdetail-tab">
              <div class="bg-white card addresses-item mb-4 border border-primary shadow">
                <div class="gold-members pt-2 pl-2 pr-2">
                  <div class="media">
                    <div class="media-body">
                      <div class="border-bottom p-4">
                        <div>
                          <h6 *ngIf="ccj.courtName" class="mb-2">Court Name : {{ccj.courtName}}</h6>
                          <p *ngIf="ccj.caseNumber" class="mb-1">Case Number : {{ccj.caseNumber}}</p>
                          <p *ngIf="ccj.amount" class="mb-1">Amount : <span [innerHTML]="ccj.amount"></span></p>
                          <p *ngIf="ccj.judgementType" class="mb-1">Type : {{ccj.judgementType}}</p>
                          <p *ngIf="ccj.judgementDate" class="mb-1">Date : {{ccj.judgementDate | date: 'dd-MM-yyyy'}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
