<div class="card-body">
  <div>
    <span class="card-title Header-Font-Format d-flex justify-content-center mb-3"><b>Financial</b></span>
    <div>
      <div class="row mb-3">
        <div class="col-md-6 p-0 text-right">
          CCJ
        </div>
        <div class="col-md-6 p-0 text-left">
          <div>
            <span class="loadingImg" *ngIf="financial.isLoading">
              <img src="{{messageService.loadingAnimation}}" />
            </span>

            <div class="col-md-12" *ngIf="!financial.isLoading && financial.noSearchSelect"><i class="color-grey float-right">{{messageService.NoSearchSelectShort}}</i></div>
            <div class="col-md-12" *ngIf="!financial.isLoading && !financial.noSearchSelect && !financial.searchFail && (financial.noRelevantData || financial?.responseData?.data?.MortalityFlag == null) && !financial.validation"><i class="color-grey float-right">{{messageService.NoRecordsOfSubject}}</i></div>
            <div class="col-md-12" *ngIf="!financial.isLoading && !financial.noSearchSelect && financial.searchFail && !financial.validation"><i class="color-grey float-right">{{messageService.SearchFail}}</i></div>
            <div class="col-md-12" *ngIf="!financial.isLoading && !financial.noSearchSelect && financial.validation"><i class="color-grey float-right">{{messageService.PostCodeRequiredMessage}}</i></div>

            <div *ngIf="financial?.responseData?.data?.CcjFlag != null && !financial.isLoading">
              <span *ngIf="financial?.responseData?.data?.CcjFlag" class="ml-3 dot dot-red"></span>
              <span *ngIf="!financial?.responseData?.data?.CcjFlag" class="ml-3 dot dot-green"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 p-0 text-right">
        Insolvency
      </div>
      <div class="col-md-6 p-0 text-left">
        <div>
          <span class="loadingImg" *ngIf="financial.isLoading">
            <img src="{{messageService.loadingAnimation}}" />
          </span>

          <div class="col-md-12" *ngIf="!financial.isLoading && financial.noSearchSelect"><i class="color-grey float-right">{{messageService.NoSearchSelectShort}}</i></div>
          <div class="col-md-12" *ngIf="!financial.isLoading && !financial.noSearchSelect && !financial.searchFail && (financial.noRelevantData || financial?.responseData?.data?.MortalityFlag == null) && !financial.validation"><i class="color-grey float-right">{{messageService.NoRecordsOfSubject}}</i></div>
          <div class="col-md-12" *ngIf="!financial.isLoading && !financial.noSearchSelect && financial.searchFail && !financial.validation"><i class="color-grey float-right">{{messageService.SearchFail}}</i></div>
          <div class="col-md-12" *ngIf="!financial.isLoading && !financial.noSearchSelect && financial.validation"><i class="color-grey float-right">{{messageService.PostCodeRequiredMessage}}</i></div>

          <div *ngIf="financial?.responseData?.data?.InsolvencyFlag != null && !financial.isLoading">
            <span *ngIf="financial?.responseData?.data?.InsolvencyFlag" class="ml-3 dot dot-red"></span>
            <span *ngIf="!financial?.responseData?.data?.InsolvencyFlag" class="ml-3 dot dot-green"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
