<div class="loadingImg" *ngIf="vehicleId.isLoading">
  <img src="{{messageService.loadingAnimation}}" />
</div>
<div class="col-md-12" *ngIf="vehicleId.searchFail && !vehicleId.isLoading"><i class="color-grey float-right">{{messageService.SearchFail}}</i></div>
<div class="col-md-12" *ngIf="vehicleId.noRelevantData && !vehicleId.isLoading"><i class="color-grey float-right">{{messageService.NoRelevantDataMessage}}</i></div>

<div *ngIf="!vehicleId.isLoading && !vehicleId.searchFail && !vehicleId.noRelevantData">
  <div class="row">
    <div class="col-md-4">Plate</div>
    <div class="col-md-4">{{vehicleId?.responseData?.vehicleList[0]?.plateModule?.plate}}</div>
  </div>
  <div class="row">
    <div class="col-md-4">State</div>
    <div class="col-md-4">{{vehicleId?.responseData?.vehicleList[0]?.plateModule?.plateState}}</div>
  </div>
  <div class="row">
    <div class="col-md-4">Registration Status</div>
    <div class="col-md-4">{{vehicleId?.responseData?.vehicleList[0]?.registrationModule?.status}}</div>
  </div>
  <div class="row">
    <div class="col-md-4">Registration Expiry</div>
    <div class="col-md-4">{{getDateTimeFromString(vehicleId?.responseData?.vehicleList[0]?.registrationModule?.expiryDate) | date:'dd/MM/yyyy'}}</div>
  </div>
  <div class="row">
    <div class="col-md-4">Written Off?</div>
    <div class="col-md-4">{{checkObjectHasKeys(vehicleId?.responseData?.vehicleList[0]?.writtenOffInfoModule)}}</div>
  </div>
  <div class="row">
    <div class="col-md-4">Stolen?</div>
    <div class="col-md-4">{{checkObjectHasKeys(vehicleId?.responseData?.vehicleList[0]?.stolenInfoModule)}}</div>
  </div>
</div>
