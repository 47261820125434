import { ItemAccess } from './LicenceTemplate';
import { Item } from "../../home/models/AccessData";


export class SubscriptionPlan {
  public planId: Number;
  public planName: string;
  public screenCount: Number;
  public searchCount: Number;
  public allowedLicenseItems: ItemAccess[];
  public description: string;
  public userCount: number;
}

  
              
      
       
