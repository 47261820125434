import { ContactData } from "./ContactData";

export class AccessData {
  isHUTDatabaseAccess: boolean = false;
  isHUTDatabaseToggle: boolean = false;
  isMobileAccess: boolean = false;
  isMobileToggle: boolean = false;
  isEmailAccess: boolean = false;
  isEmailToggle: boolean = false;
  isIdentityAccess: boolean = false;
  isIdentityToggle: boolean = false;
  isFinancialAccess: boolean = false;
  isFinancialToggle: boolean = false;
  isAddressAccess: boolean = false;
  isAddressToggle: boolean = false;
  isCriminalityAccess: boolean = false;
  isCriminalityToggle: boolean = false;
  isSocialMediaAccess: boolean = false;
  isSocialMediaToggle: boolean = false;
  isCounterFraudAccess: boolean = false;
  isCounterFraudToggle: boolean = false;
  isSportsAccess: boolean = false;
  isSportsToggle: boolean = false;
  isAdvertsAccess: boolean = false;
  isAdvertsToggle: boolean = false;
  isCompaniesHouseAccess: boolean = false;
  isCompaniesHouseToggle: boolean = false;
  isAVAAccess: boolean = false;
  isAVAToggle: boolean = false;
  isAMLAccess: boolean = false;
  isAMLToggle: boolean = false;
  isCCJDetailAccess: boolean = false;
  isCCJDetailToggle: boolean = false;
  isInsolvencyDetailAccess: boolean = false;
  isInsolvencyDetailToggle: boolean = false;
  isRTWAccess: boolean = false;
  isRTWToggle: boolean = false;
  isRTWNoFormsAccess: boolean = false;
  isIDCheckAccess: boolean = false;
  isIDCheckToggle: boolean = false;
}

export class SecondAccess {
  public items: Item[];
}

export class Item {
  public key: string;
  public itemId: number;
  public itemName: string;
  public licenceType: string;
  public defaultValue: boolean;
  public toggle: boolean;
  public imageUrl: string;
  public ParentId: number;
}

export class RequestData {
  public profileData: ContactData;
  public accessData: AccessData;
}
