export class VATResultModel {
  public isLoading: boolean;
  public responseData: any;
  public noRelevantData: boolean;
  public searchFail: boolean;
  public validation: boolean;
}

class VATAPIResultModel {
  public name: string;
  public vatNumber: string;
  public address: VATAPIAddressResultModel;
}

class VATAPIAddressResultModel {
  public line1: string;
  public line2: string;
  public line3: string;
  public line4: string;
  public postcode: string;
  public countryCode: string;
}
