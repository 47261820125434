export class CSVRecords {
  constructor(
    public referenceNumber: string,
    //public claimNumber: string,
    public businessName: string,
    public limitedCompanyNumber: string,
    public businessPostCode: string,
    public businessMobile: string,
    public businessEmail: string,
    public ownerorDirectorName: string,
    public ownerPostCode: string,
    public ownerMobile: string,
    public ownerEmail: string,
    public ownerDOB: string,
    public eventDate: string) { }
}

export class ScreenInputData {
  constructor(
    public type: string,
    public data: CSVRecords[],
    public fileName: string
  ) { }
}
