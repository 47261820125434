import { Component, Input } from '@angular/core';
import { AVAResultData } from '../home/models/AVAModel/AVAResultData';
import { AddressResponseModel } from './models/AddressResponseModel';
import { AmlResponseModel } from './models/AmlResponseModel';
import { AvaResponseModel } from './models/AvaResponseModel';
import { CCJResponseModel } from './models/CCJResponseModel';
import { CounterFraudResponse } from './models/CounterFraudModel';
import { EmailCheckResponse } from './models/EmailCheckModel';
import { FinancialResponseModel } from './models/FinancialModel';
import { InputAccessData, InputData } from './models/InputDataModel';
import { MobileResponse } from './models/MobileResponseModel';
import { ProspectResponseModel } from './models/ProspectResponseModel';

@Component({
  selector: 'app-exportpdf',
  templateUrl: './exportpdf.component.html',
  styleUrls: ['./exportpdf.component.css']
})

export class ExportpdfComponent {

  @Input() public inputData: InputData = new InputData();
  @Input() public accessData: InputAccessData = new InputAccessData();
  @Input() public mobile: MobileResponse = new MobileResponse();
  @Input() public counterFraud: CounterFraudResponse = new CounterFraudResponse();
  @Input() public email: EmailCheckResponse = new EmailCheckResponse();
  @Input() public financial: FinancialResponseModel = new FinancialResponseModel();
  @Input() public address: AddressResponseModel = new AddressResponseModel();
  @Input() public avaResult: AvaResponseModel = new AvaResponseModel();
  @Input() public prospectData: ProspectResponseModel = new ProspectResponseModel();
  @Input() public amlResultData: AmlResponseModel = new AmlResponseModel();
  @Input() public ccjResultData: CCJResponseModel = new CCJResponseModel();
  @Input() public insolvencyData: CCJResponseModel = new CCJResponseModel();

  constructor() { }

}
