export class NewDomain {
    constructor(
        public domainName: string,
        public isActive: boolean,
        public id:number) { }
}

export class DomainDetails
{
  public id:number;
  public domainName:string;
  public isActive: boolean;
}
