<div>

  <div *ngIf="!emailValidation.isLoading">
    <div class="col-md-12" *ngIf="emailValidation.noSearchSelect"><i class="color-grey float-right">{{messageService.NoSearchSelect}}</i></div>
    <div class="col-md-12" *ngIf="emailValidation.validation"><i class="color-grey float-right">{{messageService.EmailRequiredMessage}}</i></div>
    <div class="col-md-12" *ngIf="emailValidation.searchFail"><i class="color-grey float-right">{{messageService.SearchFail}}</i></div>
    <div class="col-md-12" *ngIf="emailValidation.noRelevantData"><i class="color-grey float-right">{{messageService.NoRelevantDataMessage}}</i></div>
  </div>
  
  <div class="loadingImg" *ngIf="emailValidation.isLoading">
    <img src="{{messageService.loadingAnimation}}" />
  </div>
  
  <div class="keep-together" *ngIf="!emailValidation.isLoading && emailValidation.responseData && emailValidation.noSearchSelect == false && !emailValidation.validation && !emailValidation.searchFail && !emailValidation.noRelevantData">
    <div><b><u>Email Check:</u></b></div>
    <div class="row" *ngIf="emailValidation.responseData?.debounce?.email">
      <div class="col-md-4">Email:</div>
      <div class="col-md-8">{{emailValidation.responseData.debounce.email}}</div>
    </div>
    <div class="row" *ngIf="emailValidation.responseData?.debounce?.role">
      <div class="col-md-4">Role based:</div>
      <div class="col-md-8">{{emailValidation.responseData.debounce.role}}</div>
    </div>
    <div class="row" *ngIf="emailValidation.responseData?.debounce?.free_email">
      <div class="col-md-4">Free email:</div>
      <div class="col-md-8">{{emailValidation.responseData.debounce.free_email}}</div>
    </div>
    <div class="row" *ngIf="emailValidation.responseData?.debounce?.result">
      <div class="col-md-4">Search result:</div>
      <div class="col-md-8">{{emailValidation.responseData.debounce.result}}</div>
    </div>
    <div class="row" *ngIf="emailValidation.responseData?.debounce?.reason">
      <div class="col-md-4">Reason:</div>
      <div class="col-md-8">{{emailValidation.responseData.debounce.reason}}</div>
    </div>
    <div class="row" *ngIf="emailValidation.responseData?.debounce?.did_you_mean">
      <div class="col-md-4">Did you mean:</div>
      <div class="col-md-8">{{emailValidation.responseData.debounce.did_you_mean}}</div>
    </div>
  </div>
  
</div>
